import dispatcher from './dispatcher';

const wrapCallback = (callback) => {
  return () => {
    if (callback) callback();
    closeDialog();
  };
};

/*
createDialog
content = string or react component that is placed in DialogContent
title = dialog title
actions = array of buttons with their title, color and callback
error = sets dialog to be red
*/
export const createDialog = ({ content, title, actions, error, size, disableBackdropClick }) => {
  // wrapping actions so that we always close the dialog after we execute the callback
  let wrappedActions = [];
  let fullWidth = null;
  let maxWidth = null;
  if (Array.isArray(actions)) {
    wrappedActions = actions.map((action) => {
      action.callback = wrapCallback(action.callback);
      return action;
    });
  }
  if (size) {
    fullWidth = true;
    maxWidth = size;
  }
  dispatcher.dispatch({
    type: 'NEW_DIALOG',
    dialog: { open: true, content, title, actions: wrappedActions, error, maxWidth, fullWidth, disableBackdropClick: disableBackdropClick || false },
  });
};

export const closeDialog = () => {
  dispatcher.dispatch({
    type: 'CLOSE_DIALOG',
  });
};
