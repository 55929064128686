/* eslint react/prop-types:0 */
/* eslint no-param-reassign:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import QBReportWOs from './qb-reports';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import SalesSheet from './sales-sheet/index.js';
import WeeklyReport from './sales-weekly-report';
import CSPWeeklyReport from './csp-weekly-report';
import ShippingUploads from './shipping-uploads';
import Old from './old';
import PendingVendors from './pending-vendors/index.js';
import PendingCosts from './pending-vendors/pending-costs.js';

const Accounting = ({ get, post, homepage, user }) => {
  const history = useHistory();

  const locations = [
    {
      title: 'Sales Sheet',
      url: '/accounting/sales-sheet',
      image:
        'https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_2048/https://jonasflorinus.com/wp-content/uploads/2017/01/Sales-traction-and-stability.jpg',
    },
    {
      title: 'Sales Weekly Report',
      url: '/accounting/sales-weekly-report',
      image: 'https://www.lec4you.com/wp-content/uploads/2019/11/US-New-Home-Sales-See-an-Unexpected-Increase.jpg',
    },
    {
      title: 'CSP Weekly Report',
      url: '/accounting/csp-weekly-report',
      image: 'https://www.tellermate.com/us/wp-content/uploads/sites/8/2020/05/Cash-US.jpg',
    },
    {
      title: 'Invoice Lookerupper',
      url: '/accounting/pending-vendors',
      image: 'https://images.idgesg.net/images/article/2017/09/detective-with-magnifying-glass-100735058-large.jpg?auto=webp&quality=85,70',
    },
    {
      title: 'Shipping Uploads',
      url: '/accounting/shipping-uploads',
      image: 'https://image.cnbcfm.com/api/v1/image/106901063-1624393785486-gettyimages-1229879283-CYBER_MONDAY.jpeg?v=1637348696',
    },
    {
      title: 'Old Accounting Center',
      url: '/accounting/old',
      image: 'https://s.abcnews.com/images/Entertainment/disney-pixars-coco-ht-mem-171117_16x9_992.jpg',
    },
  ];

  return (
    <div>
      <title>MSAS Accounting Center</title>
      <Switch>
        <Route path="/accounting/sales-weekly-report" component={(props) => <WeeklyReport {...props} />} />
        <Route path="/accounting/pending-vendors/:id" component={(props) => <PendingCosts {...props} />} />
        <Route path="/accounting/pending-vendors" component={(props) => <PendingVendors {...props} />} />
        <Route path="/accounting/csp-weekly-report" component={(props) => <CSPWeeklyReport {...props} />} />
        <Route path="/accounting/shipping-uploads" component={(props) => <ShippingUploads {...props} />} />
        <Route path="/accounting/qb-reports/:id" component={(props) => <QBReportWOs {...props} />} />
        <Route path="/accounting/old">
          <Old get={get} post={post} homepage={homepage} user={user} />
        </Route>
        <Route path="/accounting">
          <div style={{ padding: '10px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h3'}>Accounting Center</Typography>
              </Grid>
              {locations.map(({ title, url, image }, key) => (
                <Grid item xs={12} sm={6} lg={4} key={key}>
                  <Card>
                    <CardActionArea onClick={() => history.push(url)}>
                      <CardHeader title={title} />
                      <CardContent style={{ height: '300px' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={image} />
                      </CardContent>
                      <CardActions>
                        <Button variant={'contained'}>View</Button>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Accounting;
