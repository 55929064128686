/* eslint no-lone-blocks:0 */
/* eslint react/no-unescaped-entities:0 */
/* eslint no-use-before-define:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, ButtonGroup, Card, CardContent, CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: '5px',
  },
}));

const TodaysPrints = ({ get, refresh }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState('Prints');
  const [currentData, setCurrentData] = useState(null);
  const [totalPrintData, setTotalPrintData] = useState(null);

  const fetchPrintTotals = () => {
    get('/getPrintTotalsToday')
      .then((res) => {
        setTotalPrintData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPrintTotals();
  }, [refresh]);

  useEffect(() => {
    if (totalPrintData) change(selected);
  }, [totalPrintData]);

  const change = (selectedData) => {
    setSelected(selectedData);
    if (selectedData === 'Prints') {
      setCurrentData(totalPrintData[0]);
    }
    if (selectedData === 'Heat Press') {
      setCurrentData(totalPrintData[1]);
    }
    if (selectedData === 'Fold & Poly') {
      setCurrentData(totalPrintData[2]);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
          TODAY'S PRINTS
        </Typography>
        {!totalPrintData ? (
          <>
            <Skeleton variant="rect" width="100%" height={20} style={{ marginBottom: '20px', marginTop: '20px' }} />
            <Skeleton variant="rect" width={100} height={15} style={{ marginBottom: '10px' }} />
            <Skeleton variant="rect" width="100%" height={5} />
          </>
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item>
                {totalPrintData && (
                  <ButtonGroup
                    size="small"
                    color="primary"
                    ariaLabel="outlined primary button group"
                    style={{ margin: '10px 0', marginRight: '10px' }}
                  >
                    <Button
                      variant={selected === 'Prints' ? 'contained' : 'outlined'}
                      onClick={() => change('Prints')}
                      style={{ lineHeight: '18px' }}
                    >
                      Prints
                    </Button>
                    <Button
                      variant={selected === 'Heat Press' ? 'contained' : 'outlined'}
                      onClick={() => change('Heat Press')}
                      style={{ lineHeight: '18px' }}
                    >
                      Heat Press
                    </Button>
                    <Button
                      variant={selected === 'Fold & Poly' ? 'contained' : 'outlined'}
                      onClick={() => change('Fold & Poly')}
                      style={{ lineHeight: '18px' }}
                    >
                      Fold & Poly
                    </Button>
                  </ButtonGroup>
                )}
              </Grid>
            </Grid>
            <Grid container justify="space-between" style={{ alignItems: 'flex-end', margin: '10px 0' }}>
              <Grid item xs={6}>
                {currentData && currentData.totalPrintsToday ? (
                  <Typography variant="h3">{currentData.totalPrintsToday}</Typography>
                ) : (
                  <Typography variant="body2">{`No ${selected} prints due today`}</Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: 'red', fontWeight: 500, textAlign: 'right' }}>
                  {currentData && currentData.totalOverDuePrints ? `OVERDUE: ${currentData.totalOverDuePrints}` : null}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">{currentData && currentData.progress ? `Progress: ${currentData.progress}%` : ''}</Typography>
            <LinearProgress
              className={classes.progress}
              value={currentData && currentData.progress ? parseFloat(currentData.progress) : 0}
              variant="determinate"
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TodaysPrints;

{
  /* <Grid item>
    <Avatar className={classes.avatar}>
      <InsertChartIcon className={classes.icon} />
    </Avatar>
    </Grid> */
}
