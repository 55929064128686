import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Home from './home';
import Jobs from './jobs';
import Permission from './permissions/permission';
import Permissions from './permissions';
import Group from './groups/group';
import Groups from './groups';
import User from './users/user';
import Users from './users';
import PubSubs from './pub-subs';
import DeveloperTabs from '../../Components/DeveloperTools/DeveloperTabs';

const DeveloperTools = () => {
  return (
    <>
      <title>Developer Tools</title>
      <DeveloperTabs />
      <Switch>
        <Route path="/developer-tools/permissions/new">
          <Permission isEdit={false} />
        </Route>
        <Route path="/developer-tools/permissions/:id">
          <Permission isEdit={true} />
        </Route>
        <Route path="/developer-tools/permissions">
          <Permissions />
        </Route>
        <Route path="/developer-tools/groups/new">
          <Group isEdit={false} />
        </Route>
        <Route path="/developer-tools/groups/:id">
          <Group isEdit={true} />
        </Route>
        <Route path="/developer-tools/groups">
          <Groups />
        </Route>
        <Route path="/developer-tools/users/:id">
          <User />
        </Route>
        <Route path="/developer-tools/users">
          <Users />
        </Route>
        <Route path="/developer-tools/jobs">
          <Jobs />
        </Route>
        <Route path="/developer-tools/pub-subs">
          <PubSubs />
        </Route>
        <Route path="/developer-tools">
          <Home />
        </Route>
      </Switch>
    </>
  );
};

export default DeveloperTools;
