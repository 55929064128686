/* eslint react/prop-types:0 */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { get, post, patch, remove } from '../../Functions/API';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';

const ArtCosts = ({ workOrder, currentArtist, availableVendors }) => {
  const [artCosts, setArtCosts] = useState([]);

  useEffect(() => {
    if (workOrder.id) {
      get('/art/costs', { workOrderID: workOrder.id }, 'obj')
        .then((results) => {
          setArtCosts(results);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [workOrder.id]);

  const columns = [
    {
      title: 'Vendor',
      field: 'vendorID',
      editComponent: (t) => {
        return (
          <Select value={t.rowData.vendorID} onChange={(e) => t.onChange(e.target.value)}>
            {availableVendors.map((availableVendor) => (
              <MenuItem key={availableVendor.vendorID} value={availableVendor.vendorID}>
                {availableVendor.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
      initialEditValue: currentArtist?.name,
      render: (rowData) => {
        let vendor = availableVendors.find((v) => v.vendorID === rowData.vendorID);
        return vendor ? vendor.name : '';
      },
    },
    { title: 'Description', field: 'notes' },
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric' },
    { title: 'Per Unit', field: 'perUnit', type: 'currency' },
    { title: 'Cost', field: 'totalEstimatedCost', type: 'currency', editable: 'never' },
  ];

  return (
    <div style={{ width: '100%' }}>
      <MaterialTable
        title={'Art Costs'}
        columns={columns}
        data={artCosts}
        editable={{
          onRowAdd: async (newData) => {
            newData.totalEstimatedCost = newData.perUnit * newData.totalQuantity;
            newData.workOrderID = workOrder.id;
            post('/art/cost', newData)
              .then((results) => {
                setArtCosts((artCosts) => artCosts.concat(results));
              })
              .catch((err) => {
                console.error(err);
              });
          },
          onRowUpdate: async (newData, oldData) => {
            console.log(newData);
            console.log(oldData);
            newData.totalEstimatedCost = newData.perUnit * newData.totalQuantity;
            newData.workOrderID = workOrder.id;
            patch('/art/cost', newData)
              .then((results) => {
                const ac = [...artCosts];
                const index = ac.findIndex((a) => a.id === oldData.id);
                ac[index] = results;
                setArtCosts(ac);
              })
              .catch((err) => {
                console.error(err);
              });
          },
          onRowDelete: async (oldData) => {
            remove('/art/cost', oldData)
              .then(() => {
                const ac = [...artCosts];
                const index = ac.findIndex((a) => a.id === oldData.id);
                ac.splice(index, 1);
                setArtCosts(ac);
              })
              .catch((err) => {
                console.error(err);
              });
          },
        }}
      />
    </div>
  );
};

export default ArtCosts;

ArtCosts.propTypes = {
  workOrder: PropTypes.object,
  availableVendors: PropTypes.array,
  currentArtist: PropTypes.object,
};
