/* eslint-disable react/display-name */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/no-children-prop:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
/* eslint react/sort-comp:0 */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ClockInAndOut from './ClockInAndOut';
import { get } from '../../../../../Functions/API';
import VendorAutocomplete from '../../../../Vendors/VendorAutocomplete';
import { Card, CardActionArea, CardContent, CardHeader, FormControlLabel, List, ListItem, ListItemText } from '@material-ui/core';

const Print = ({ workOrderID }) => {
  const [clockIn, setClockIn] = useState(false);
  const [lines, setLines] = useState([]);
  const [options, setOptions] = useState({});

  const clockInLine = (line, status) => {
    setClockIn(status);
  };

  useEffect(() => {
    const filter = {
      eager: {
        $where: {
          type: {
            $in: ['vendor', 'type'],
          },
        },
      },
    };
    get('/options', { filter }).then((results) => {
      const vendor = results.filter((result) => result.type === 'vendor');
      const type = results.filter((result) => result.type === 'type');
      setOptions({ vendor, type });
    });
    get('/getOrdersForOrder', [{ name: 'workOrderID', value: workOrderID }]).then((results) => {
      const c = [];
      for (const result of results) {
        result.clockIn = false;
        for (const chip of result.chips) {
          chip.clockIn = false;
          Object.keys(chip).forEach((key) => {
            let found = false;
            if (chip[key] !== '') {
              for (const column of c) {
                if (column.field === key) {
                  found = true;
                }
              }
              if (
                !found &&
                key !== 'url' &&
                key !== 'ink' &&
                key !== 'reversible' &&
                key !== 'type' &&
                key !== 'side' &&
                key !== 'clockIn' &&
                key !== 'specialInk' &&
                key !== 'underlay' &&
                key !== 'medWeed' &&
                key !== 'hardWeed' &&
                key !== 'numbersArr' &&
                key !== 'id' &&
                key !== 'workOrderID'
              ) {
                c.push({ field: key, title: key.toUpperCase() });
              } else if (!found && key === 'reversible') {
                c.push({
                  field: key,
                  title: key.toUpperCase(),
                  render: (rowData) => <Checkbox size={'small'} checked={Boolean(rowData.reversible)} value={Boolean(rowData.reversible)} />,
                });
              }
            }
          });
        }
      }
      setLines(results);
    });
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ position: '-webkit-sticky', top: '50px', left: '5px', zIndex: 40000 }}>
        <ClockInAndOut type="print" clockInLine={clockInLine} line={{ clockIn }} workOrderID={workOrderID} />
      </div>
      {lines &&
        options.type &&
        options.vendor &&
        lines.map(
          (line, key) =>
            line.chips.length > 0 && (
              <div style={{ marginBottom: '15px' }} key={key}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3} justify="center">
                      <Grid item>
                        <TextField
                          id="Type"
                          label="Type"
                          value={line.type}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ margin: '8px' }}
                          margin="normal"
                          autoComplete="off"
                          component="div"
                        />
                      </Grid>
                      <Grid item>
                        <FormControl style={{ margin: '8px', width: '300px' }} component="div">
                          {/*{options.vendor.find((v) => v.id === line.vendorID)?.name}*/}
                          <VendorAutocomplete callback={() => {}} initValue={line.vendorID} label={true} />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <TextField
                          id="youthStyle"
                          label="Youth Style"
                          value={line.youthStyle}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ margin: '8px' }}
                          margin="normal"
                          autoComplete="off"
                          component="div"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="adultStyle"
                          label="Adult Style"
                          value={line.adultStyle}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ margin: '8px' }}
                          margin="normal"
                          autoComplete="off"
                          component="div"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="color"
                          label="Color"
                          value={line.color}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ margin: '8px' }}
                          margin="normal"
                          autoComplete="off"
                          component="div"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="notes"
                          label="Notes"
                          value={line.notes}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ margin: '8px' }}
                          margin="normal"
                          autoComplete="off"
                          component="div"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginLeft: '2px' }} justify="center">
                      {line.sizes.map((size, key) => (
                        <Grid key={key} item>
                          <TextField
                            label={size.size.toUpperCase()}
                            value={size.quantity}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="normal"
                            style={{ width: '45px' }}
                            autoComplete="off"
                            component="div"
                          />
                        </Grid>
                      ))}
                      <Grid item />
                    </Grid>
                    <Grid container spacing={1} justify={'center'}>
                      {line.chips.map((chip, key) => (
                        <Grid item key={key}>
                          <Card variant={'outlined'}>
                            <CardActionArea onClick={() => window.open(chip.url, '_blank')}>
                              <CardHeader subheader={'Click anywhere to view art'} />
                              <CardContent>
                                <List>
                                  <ListItem>
                                    <ListItemText primary={chip.type} secondary={'Type'} />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText primary={chip.printType} secondary={'Print Type'} />
                                  </ListItem>
                                  {chip.underlay && (
                                    <ListItem>
                                      <FormControlLabel control={<Checkbox size={'small'} checked={chip.underlay} />} label={'Underlay'} />
                                    </ListItem>
                                  )}
                                  <ListItem>
                                    <ListItemText primary={chip.position} secondary={'Position'} />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText primary={chip.color} secondary={'Color'} />
                                  </ListItem>
                                  {chip.numbers && (
                                    <ListItem>
                                      <ListItemText primary={chip.numbers} secondary={'Numbers'} />
                                    </ListItem>
                                  )}
                                  {chip.reversible && (
                                    <ListItem>
                                      <FormControlLabel control={<Checkbox size={'small'} checked={chip.reversible} />} label={'Reversible'} />
                                    </ListItem>
                                  )}
                                  <ListItem>
                                    <ListItemText primary={chip.name} secondary={'Name'} />
                                  </ListItem>
                                </List>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            ),
        )}
    </div>
  );
};

export default Print;
