import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';

export const loadCustomers = () => {
  get('/customers')
    .then((customers) => {
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMERS',
        customers: customers,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const newCustomer = (newCustomer) => {
  return post('/customers', newCustomer)
    .then(async (customer) => {
      createSnack('Success', 'Created New Customer!');
      dispatcher.dispatch({
        type: 'NEW_CUSTOMER',
        customer,
      });
      return customer;
    })
    .catch((err) => {
      createSnack('Error', `${err.message}`);
      console.error(err);
    });
};

export const updateCustomer = (customer) => {
  patch(`/customers/${customer.id}`, customer)
    .then(async (customer) => {
      dispatcher.dispatch({
        type: 'UPDATE_CUSTOMER',
        customer,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const lockCustomer = (id) => {
  patch(`/customers/${id}`, { locked: 1 }).then((customer) => {
    dispatcher.dispatch({
      type: 'LOCK_CUSTOMER',
      customer,
    });
    createSnack('Warning', `This customer is now locked and you'll need to continue your repentance with accounting to make them another order.`);
  });
};
