/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { FormControlLabel, Switch, TextField, InputAdornment, IconButton, Select, MenuItem, Button } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import DateFnsUtils from '@date-io/date-fns';
import VendorAutocomplete from '../../../../Vendors/VendorAutocomplete';
import VendorNugget from '../../../../Vendors/VendorNugget';
import UserStore from '../../../../../Stores/User/Store';
import { get, patch } from '../../../../../Functions/API';
import { createBill, deleteBill } from '../../../../../Stores/QuickBooks/Actions/Bills';

const Costs = ({
  costs,
  setCosts,
  submitted,
  setSubmitted,
  newRow,
  updateRow,
  removeRow,
  workOrder,
  convertDateToSQLDate,
  removeAllCostingRows,
  actions,
  submit,
  cspVendor,
  resetTable,
  checkAccountingApproved,
  updateBill,
  getCosts,
}) => {
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const user = UserStore.getUser();
  const columns = [
    {
      title: 'Saved',
      render: (rowData) =>
        rowData && <Switch size={'small'} onClick={submit} checked={Boolean(rowData.id)} value={Boolean(rowData.id)} color="primary" />,
    },
    {
      title: 'Vendor',
      field: 'vendorID',
      editComponent: (t) => {
        const onChange = (newValue) => {
          t.onChange(newValue);
        };
        return <VendorAutocomplete callback={onChange} initValue={t.value} />;
      },
      render: (rowData) => {
        return <VendorNugget rowData={rowData} />;
      },
    },
    {
      title: 'Invoice Number',
      field: 'invoiceNumber',
      // TODO editCostingInvoiceNumber
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
    },
    {
      title: 'Invoice Date',
      field: 'invoiceDate',
      // TODO editCostingInvoiceDate
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
      render: (rowData) => {
        if (rowData && rowData.invoiceDate) {
          const date = new Date(rowData.invoiceDate);
          return <div>{date.toDateString()}</div>;
        }
        return <div />;
      },
    },
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric' },
    {
      title: 'per Unit',
      field: 'perUnit',
      type: 'numeric',
      render: (rowData) => (parseFloat(rowData.perUnit) ? parseFloat(rowData.perUnit).toFixed(2) : 'N/A'),
    },
    {
      title: 'Shipping Cost',
      field: 'shippingCost',
      type: 'numeric',
      // render: rowData => '$' + rowData.shippingCost ? rowData.shippingCost : 0
    },
    {
      title: 'Estimated Cost',
      field: 'totalEstimatedCost',
      type: 'numeric',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return (
            <div>${parseFloat(rowData.totalEstimatedCost) ? parseFloat(rowData.totalEstimatedCost).toFixed(2) : rowData.totalEstimatedCost}</div>
          );
        }
      },
    },
    {
      title: 'Actual Cost',
      field: 'totalActualCost',
      type: 'numeric',
      // TODO editCostingActualCost
      ...(user.group === 'Accounting Admin' || user.group === 'Admin' ? { editable: 'always' } : { editable: 'never' }),
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
    {
      title: 'Notes',
      field: 'notes',
      type: 'string',
    },
  ];

  if (user.group.includes('Accounting') || user.group === 'Admin') {
    columns.push({
      title: 'Bill Status',
      field: 'qbBillCreated',
      editable: 'never',
      render: (rowData) => {
        if (
          rowData && //todo: go back and rethink active customer
          rowData.invoiceNumber &&
          rowData.invoiceDate &&
          rowData.totalActualCost &&
          rowData.qbBillCreated === 0 &&
          (user.group.includes('Accounting') || user.group.match('Admin'))
        ) {
          {
            return (
              <div>
                {submitted && (
                  <Button size="small" color="secondary" variant="contained" onClick={() => createBill({ costId: rowData.id }).then(getCosts)}>
                    Create
                  </Button>
                )}
              </div>
            );
          }
        } else if (rowData && rowData.qbBillCreated === 1 && (user.group.includes('Accounting') || user.group.match('Admin'))) {
          {
            return (
              <div>
                {submitted && (
                  <Button size="small" color="secondary" variant="contained" onClick={() => deleteBill(rowData.id).then(getCosts)}>
                    Delete
                  </Button>
                )}
              </div>
            );
          }
        } else {
        }
      },
    });
  }

  if (user.group !== 'Accounting Admin') {
    columns.splice(1, 0, {
      title: 'Arrival Date',
      field: 'arrivalDate',
      type: 'date',
      editable: user.group === 'Accounting Admin' ? 'never' : 'always',
      render: (rowData) => {
        if (rowData && rowData.arrivalDate) {
          const date = new Date(rowData.arrivalDate);
          return <div>{date.toDateString()}</div>;
        }
        return <div />;
      },
    });
  }

  // TODO costingShowCostAssignment
  if (user.group.includes('Accounting') || (workOrder.misprint && user.group === 'Admin')) {
    columns.splice(1, 0, {
      title: 'Cost Assignment',
      field: 'costAssignment',
      editable: 'never',
      render: (rowData) => {
        return (
          <Select value={rowData?.costAssignment || ''} onChange={(event) => updateCost(rowData.id, { costAssignment: event.target.value })}>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="CSP">CSP</MenuItem>
            <MenuItem value="Sales Assistant">Sales Assistant</MenuItem>
          </Select>
        );
      },
    });
  }

  // TODO costingReprintSwitch
  if (
    workOrder.misprint &&
    (user.group === 'Accounting Admin' || user.group === 'Order Processor' || user.group === 'Admin' || user.group.includes('Sales'))
  ) {
    columns.splice(1, 0, {
      title: 'Reprint',
      field: 'misprint',
      editable: 'never',
      render: (rowData) => {
        return (
          <Switch
            checked={rowData.misprint}
            size={'small'}
            onChange={() => updateCost(rowData.id, { misprint: !rowData.misprint })}
            value={rowData.misprint}
            color="secondary"
          />
        );
      },
    });
  }
  // TODO costingGeneratedSwitch
  if (user.group === 'Admin') {
    columns.splice(1, 0, {
      title: 'Generated',
      editable: 'never',
      render: (rowData) =>
        rowData && <Switch size={'small'} checked={Boolean(rowData.type === 'generated')} value={Boolean(rowData.type === 'generated')} />,
    });
  }

  const updateCost = (id, body) => {
    patch(`/costing/${id}`, body)
      .then((results) => {
        const numOfLines = [...costs];
        const i = numOfLines.findIndex((line) => line.id === id);
        numOfLines[i] = results;
        createSnack('Success', 'Updated Cost');
        setCosts(numOfLines);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const buttonStyles = {
    margin: '8px',
    paddingBottom: '5px',
  };

  const bringOverMisprints = () => {
    get('/bringOverMisprints', [
      { name: 'workOrderID', value: workOrder.id },
      {
        name: 'uid',
        value: user.uid,
      },
    ])
      .then(({ rows, submitted }) => {
        const numOfLines = [...[...costs], ...rows];
        checkAccountingApproved();
        setCosts(numOfLines);
        setSubmitted(submitted);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable
        title={'Costs'}
        columns={columns}
        data={costs.filter((row) => row.vendorID !== cspVendor.id && row.type !== 'shipping' && row.type !== 'RA')}
        options={{
          search: false,
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ margin: '10px' }}>
              <MTableToolbar {...props} />
              <div style={{ padding: '0px 10px' }}>
                {
                  <Button style={buttonStyles} size="small" variant="contained" onClick={resetTable}>
                    Reset Table
                  </Button>
                }
                {Boolean(workOrder.misprint) && (
                  <Button style={buttonStyles} size="small" variant="contained" onClick={bringOverMisprints}>
                    Bring over Misprints
                  </Button>
                )}
                {/* TODO costingAccountingApprovedSwitch */}
                {(user.group === 'Accounting Admin' || user.group === 'Admin') && (
                  <FormControlLabel
                    control={
                      <Switch
                        value={Boolean(workOrder.accountingApproved)}
                        size={'small'}
                        onChange={() => {
                          if (!workOrder.accountingApproved) {
                            updateWorkOrder(workOrder.id, {
                              accountingApproved: !workOrder.accountingApproved,
                              closedDate: convertDateToSQLDate(new Date()),
                            });
                          } else {
                            updateWorkOrder(workOrder.id, { accountingApproved: !workOrder.accountingApproved, closedDate: null });
                          }
                        }}
                        checked={Boolean(workOrder.accountingApproved)}
                      />
                    }
                    label="Accounting Approved"
                    labelPlacement="top"
                  />
                )}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker label="Closed Date" value={workOrder.closedDate} />
                </MuiPickersUtilsProvider>
                <div style={{ float: 'right' }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Estimated Cost"
                    value={costs
                      .filter((row) => row.vendorID !== cspVendor.id && row.type !== 'shipping')
                      .reduce((total, row) => total + row.totalEstimatedCost, 0)
                      .toFixed(2)}
                  />
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Actual Cost"
                    value={costs
                      .filter((row) => row.vendorID !== cspVendor.id)
                      .reduce((total, row) => total + row.totalActualCost, 0)
                      .toFixed(2)}
                  />
                </div>
                {/* TODO costingDeleteAllRows */}
                {(user.group === 'Admin' || user.group === 'Order Processor') && (
                  <span style={{ float: 'right' }}>
                    <IconButton
                      onClick={() => setDeleteAllDisabled(!deleteAllDisabled)}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <Lock /> : <LockOpen />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={() => {
                        removeAllCostingRows('cost');
                        setDeleteAllDisabled(true);
                      }}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </span>
                )}
                {Boolean(true) && (
                  <span style={{ float: 'right' }}>
                    <Button
                      size="small"
                      onClick={() => {
                        let found = costs.find((c) => c.type === 'RA');
                        if (found) {
                          createSnack('Error', 'You must delete all RA costs before you can do that!');
                        } else {
                          updateWorkOrder(workOrder.id, { RA: !workOrder.RA });
                        }
                      }}
                      style={{
                        margin: '10px',
                        backgroundColor: 'yellow',
                        color: 'purple',
                        border: 'blue solid 2px',
                        // fontWeight: 'bold',
                      }}
                      color="secondary"
                      disabled={false}
                      variant="contained"
                    >
                      {workOrder.RA ? 'REMOVE RA' : 'ADD RA'}
                    </Button>
                  </span>
                )}
              </div>
            </div>
          ),
        }}
        actions={actions}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            updateRow(newData, oldData);
          },
          onRowAdd: async (newData) => {
            newRow(newData);
          },
          onRowDelete: async (oldData) => {
            removeRow(oldData);
          },
        }}
      />
    </div>
  );
};

export default Costs;
