/* eslint react/display-name:0 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API';
import Toolbar from '../../../Components/Accounting/QBReports/QBReportWOs/Toolbar';
import { CircularProgress } from '@material-ui/core';
import { getCurrency } from '../../../Functions';
import FirebaseMessagingStore from '../../../FirebaseMessaging/Store';

const QBReportWOs = ({ match }) => {
  const [qbReportWOs, setQBReportWOs] = useState([]);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  const columns = [
    { title: 'Work Order ID', field: 'viewableId' },
    {
      title: 'QB Invoice Total',
      field: 'qbInvoiceTotal',
      render: (rowData) => (rowData.qbInvoiceTotal === null ? <CircularProgress /> : getCurrency(rowData.qbInvoiceTotal)),
    },
    { title: 'MSAS Invoice Total', field: 'msasInvoiceTotal', type: 'currency', align: 'left' },
    { title: 'Match', field: 'matches', type: 'boolean' },
  ];

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    FirebaseMessagingStore.on(`/AccountingCenter/qbReports/${match?.params?.id}`, updateQBReport);
    return () => {
      FirebaseMessagingStore.removeListener(`/AccountingCenter/qbReports/${match?.params?.id}`, updateQBReport);
    };
  }, [qbReportWOs]);

  const updateQBReport = ({ qbReportWO }) => {
    console.log(qbReportWOs);
    const qbReportWOJSON = JSON.parse(qbReportWO);
    const qb = [...qbReportWOs];
    const index = qb.findIndex((r) => r.id === qbReportWOJSON.id);
    qb[index] = { ...qb[index], ...qbReportWOJSON };
    setQBReportWOs(qb);
  };

  const getReports = () => {
    return get(`/qbReports/${match?.params?.id}?orderBy[matches]=desc`).then(({ name, from, to, created_at, qbReportWOs: w }) => {
      setName(name);
      setFrom(from);
      setTo(to);
      setCreatedAt(created_at);
      setQBReportWOs(w);
      return w;
    });
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={qbReportWOs}
        components={{
          Toolbar: () => <Toolbar name={name} from={from} to={to} createdAt={createdAt} />,
        }}
        options={{ toolbar: Boolean(match?.params?.id), paging: !Boolean(match?.params?.id) }}
        onRowClick={(event, rowData) => {
          window.open(`/workorder/${rowData.workOrderId}`);
        }}
      />
    </div>
  );
};

QBReportWOs.propTypes = {
  id: PropTypes.number,
  WorkOrders: PropTypes.array,
  match: PropTypes.object,
};

export default QBReportWOs;
