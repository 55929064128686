/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { get } from '../../../Functions/API';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import LineItems from '../../../Components/Fulfillment/LineItems';
import { Button } from '@material-ui/core';
import { createDialog } from '../../../Stores/Dialog/Actions';
import CostingTable from '../../../Components/Fulfillment/Components/CreateGroup';
import FilterDrawer from '../../../Components/Fulfillment/LineItems/dateFilterDrawer';

const useQuery = () => queryString.parse(useLocation().search);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

// todo: replace filters with just queryParams (no need for another filter object)
// todo: Add filterable terms? (ability to change vendor or store from in here?) (not statusId!)

const Ordering = () => {
  const queryParams = useQuery();
  const history = useHistory();
  const [filter, setFilter] = useState();
  const [refreshing, setRefreshing] = useState(0);
  const { statusId, vendorId, storeId } = queryParams;
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const classes = useStyles();

  const getProductStepIds = async () => {
    const productSteps = await get('/fulfillment/products/steps', { statusId });
    return productSteps.map((step) => step.id);
  };

  const updateQueryParams = () => {
    const newParams = new URLSearchParams();

    newParams.append('statusId', statusId);
    newParams.append('vendorId', vendorId);
    newParams.append('storeId', storeId);

    for (const key in filters) {
      const value = filters[key];
      if (value) {
        newParams.append(key, filters[key]);
      }
    }

    const search = newParams.toString();
    history.push({ search });
  };

  useEffect(() => {
    updateQueryParams();
  }, [filters]);

  useEffect(() => {
    getProductStepIds().then((productStepIds) => {
      const f = {
        eager: {
          $where: {
            productStepId: {
              $in: productStepIds,
            },
            'order.createdAt': {},
            'order.storeId': storeId,
            'ybaSku.externalSku.vendorId': vendorId,
          },
          order: {},
          ybaSku: {
            externalSku: {
              $where: {
                vendorId,
              },
            },
            ybaProduct: {},
          },
        },
        order: 'id asc',
      };
      setFilter(f);
    });
  }, []);

  // todo: maybe add:
  //  customFilterAndSearch => This can be used for overriding filter and search algorithm
  //  customSort => This field can be used for overriding sort algorithm
  //  defaultFilter => Default Filter value for filtering column
  const columns = [
    { title: 'Created At', field: 'order.createdAt', type: 'date' },
    { title: 'Sku', field: 'ybaSku.sku' },
    { title: 'Product', field: 'ybaSku.ybaProduct.name' },
    { title: 'Description', field: 'ybaSku.externalSku.description' },
    { title: 'Brand', field: 'ybaSku.externalSku.brand' },
    { title: 'Color', field: 'ybaSku.externalSku.color' },
    { title: 'Size', field: 'ybaSku.externalSku.size' },
    { title: 'Quantity', field: 'quantity' },
  ];

  const openCostingDialog = (data, refreshing) => {
    createDialog({
      content: (
        <CostingTable
          storeId={queryParams.storeId}
          selected={data}
          refresh={() => setRefreshing(refreshing + 1)}
          type={'order'}
          statusId={statusId}
        />
      ),
      title: 'COSTS',
      size: 'xl',
      disableBackdropClick: true,
    });
  };

  const actions = [
    {
      icon: () => (
        <Button size={'small'} variant={'contained'}>
          Order All
        </Button>
      ), //
      onClick: async () => {
        const { results } = await get('/fulfillment/lineItems', { filter });
        console.log('Order All Res:', results);
        openCostingDialog(results, refreshing);
      },
      isFreeAction: true,
    },
    {
      icon: () => <FilterDrawer filters={filters} setFilters={setFilters} />,
      isFreeAction: true,
    },
    {
      tooltip: 'Order Selected',
      icon: () => (
        <Button size={'small'} variant={'contained'}>
          Order Selected
        </Button>
      ),
      onClick: (evt, data) => {
        openCostingDialog(data, refreshing);
      },
    },
  ];

  return (
    <div className={classes.root}>
      {/* todo: remove filters prop, instead watch queryParams in LineItems component */}
      {filter && <LineItems filter={filter} title={'Ordering'} columns={columns} actions={actions} filters={filters} refreshing={refreshing} />}
    </div>
  );
};

export default Ordering;

Ordering.propTypes = {};
