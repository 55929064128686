/* eslint react/forbid-prop-types:0 */
/* eslint no-alert:0 */
/* eslint no-nested-ternary:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-shadow:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint prefer-const:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
/* eslint consistent-return:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Button, Grid, Modal, Select, MenuItem, Tooltip, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProductPicker from './ProductPicker';
import QuoteArt from './QuoteArt';

export default class QuoteCosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceLists: [],
      printOptions: [],
      catalogId: '',
      categories: [],
      options: [],
      category: '',
      cost: '',
      styles: [],
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      this.setState(results);
    });
  }

  setup = async () => {
    let priceLists;
    let printOptions;
    let art;
    let categories;
    let category;
    let cost;
    let styles;
    let catalogId;
    let options;
    await this.props
      .get('/getPrintOptions', [])
      .then((results) => {
        priceLists = results.priceLists;
        printOptions = results.printOptions;
        art = JSON.stringify(this.props.art);
        art = JSON.parse(art);
      })
      .catch((err) => {
        console.log(err);
      });
    categories = await this.initializeCategories(this.props.product.catalogId);
    styles = await this.initializeStyles(this.props.product.catalogId, this.props.product.category);
    console.log(styles);
    options = await this.initializeOptions(this.props.product.styleId);
    cost = await this.initializeCost(styles, this.props.product.style);
    category = this.props.product.category;
    catalogId = this.props.product.catalogId;
    if (
      options !== undefined &&
      priceLists !== undefined &&
      printOptions !== undefined &&
      art !== undefined &&
      categories !== undefined &&
      category !== undefined &&
      cost !== undefined &&
      styles !== undefined
    ) {
      return {
        priceLists,
        printOptions,
        art,
        options,
        categories,
        category,
        cost,
        catalogId,
        styles,
      };
    }
    console.log({
      priceLists,
      printOptions,
      art,
      options,
      categories,
      category,
      cost,
      catalogId,
      styles,
    });
  };

  initializeCategories = async (id) => {
    return this.props
      .get('/getCategories', { id }, 'obj')
      .then((results) => {
        return results;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initializeStyles = async (id, category) => {
    return this.props
      .get('/getStyles', { id, category }, 'obj')
      .then((results) => {
        return results;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initializeOptions = async (styleId) => {
    return this.props
      .get('/getOptionsFromStyle', { styleId }, 'obj')
      .then((results) => {
        return results;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initializeCost = (styles, style) => {
    const selected = styles.find((row) => row.style === style);
    if (selected) {
      return selected.price;
    }
    return '';
  };

  updateCatalog = (event) => {
    this.props.get('/getCategories', [{ name: 'id', value: event.target.value }]).then((categories) => {
      this.setState({
        categories,
        styles: [],
        options: [],
        catalogId: event.target.value,
        cost: '',
      });
    });
  };

  updateStyles = (event) => {
    this.props
      .get('/getStyles', [
        { name: 'id', value: this.state.catalogId },
        {
          name: 'category',
          value: event.target.value,
        },
      ])
      .then((styles) => {
        this.setState({
          category: event.target.value,
          styles,
          options: [],
          cost: '',
        });
      });
  };

  updateOptions = (styleId) => {
    this.props.get('/getOptionsFromStyle', { styleId }, 'obj').then((options) => {
      this.setState({
        options,
      });
    });
  };

  updateOption = (value) => {
    const { options } = this.state;
    const selected = options.find((option) => option.id === value);
    const cost = selected.price;
    this.setState({
      cost,
    });
  };

  updateCost = (style) => {
    const { styles } = this.state;
    const selected = styles.find((row) => row.style === style);
    const cost = selected.price;
    const { options } = selected;
    this.setState({
      cost,
      options,
    });
  };

  updateStyle = async (styleId, description, catalogId, category, option) => {
    await this.props.get('/catalogOptions', { styleId }, 'obj').then((options) => {
      this.props.updateProduct(this.props.row, this.props.product, 'options', options);
      // if (options.length) {
      //     this.props.updateProduct(this.props.row, this.props.product, 'optionId', options[0].id);
      // }
    });
    this.props.updateProduct(this.props.row, this.props.product, 'styleId', styleId);
    this.props.updateProduct(this.props.row, this.props.product, 'catalogId', catalogId);
    this.props.updateProduct(this.props.row, this.props.product, 'category', category);
    // this.props.updateProduct(this.props.row, this.props.product, 'style', style);
    this.props.updateProduct(this.props.row, this.props.product, 'description', description);
    this.props.updateProduct(this.props.row, this.props.product, 'optionId', option);
    // this.props.updateProduct(this.props.row, this.props.product, 'price', this.state.cost);
  };

  render() {
    const {
      row,
      product,
      updateArt,
      addArt,
      deleteArt,
      editProduct,
      toggleEditProduct,
      editArt,
      toggleEditArt,
      get,
      post,
      toggleShowCosts,
      catalogs,
      addQuoteCost,
      updateQuoteCost,
      removeQuoteCost,
    } = this.props;
    const tableStyle = { marginBottom: '10px' };
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'rgb(234, 234, 234)',
          borderRadius: '6px',
          color: 'black',
          minWidth: '1000px',
          height: '100%',
          padding: '15px',
          outline: 'none',
        }}
      >
        <Modal open={editProduct} onClose={() => toggleEditProduct(row, product)} style={{ top: '25%', left: '10%' }}>
          <ProductPicker {...this.props} />
        </Modal>
        <Modal open={editArt} onClose={() => toggleEditArt(row, product)} style={{ top: '25%', left: '10%' }}>
          <QuoteArt art={product.art} get={get} post={post} row={row} product={product} updateArt={updateArt} addArt={addArt} close={toggleEditArt} />
        </Modal>
        <Grid container justify="flex-start">
          <Grid item style={{ flexGrow: 1 }}>
            <h2>Costs</h2>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={() => toggleShowCosts(row, product)}>
              Close
            </Button>
          </Grid>
        </Grid>
        <MaterialTable
          title="Product"
          style={tableStyle}
          columns={[
            {
              title: 'Catalog',
              field: 'catalogId',
              render: (rowData) =>
                catalogs.find((row) => row.id === rowData.catalogId) ? catalogs.find((row) => row.id === rowData.catalogId).name : '',
              editComponent: (t) => (
                <Select
                  value={t.value}
                  onChange={(e) => {
                    t.onChange(e.target.value);
                    this.updateCatalog(e);
                  }}
                >
                  {catalogs.map((catalog, key) => (
                    <MenuItem key={key} value={catalog.id}>
                      {catalog.name}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Category',
              field: 'category',
              editComponent: (t) => (
                <Select
                  value={t.value}
                  onChange={(e) => {
                    t.onChange(e.target.value);
                    this.updateStyles(e);
                  }}
                >
                  {this.state.categories.map((category, key) => (
                    <MenuItem key={key} value={category.category}>
                      {category.category}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Style',
              field: 'styleId',
              render: (rowData) => {
                const style = this.state.styles.find((row) => row.id === rowData.styleId);
                if (style) {
                  return (
                    <Grid container spacing={1} justify="flex-start">
                      <Grid item>{style.style}</Grid>
                      <Grid item>{style.description}</Grid>
                    </Grid>
                  );
                }
                return rowData.styleId;
              },
              editComponent: (t) => (
                <Autocomplete
                  options={this.state.styles}
                  getOptionLabel={(option) => `${option.style} | ${option.description}`}
                  disableClearable
                  getOptionSelected={(option, value) => value === option.id}
                  inputValue={
                    this.state.styles.find((row) => row.id === t.value)
                      ? `${this.state.styles.find((row) => row.id === t.value).style} | ${
                          this.state.styles.find((row) => row.id === t.value).description
                        }`
                      : t.value
                      ? t.value.toString()
                      : t.value
                  }
                  onChange={(e, value) => {
                    this.updateOptions(value.id);
                    return t.onChange(value.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="style"
                      label="Style"
                      style={{ margin: '10px', width: '95%' }}
                      onChange={(e) => t.onChange(e.target.value)}
                      margin="normal"
                      component="div"
                      variant="outlined"
                    />
                  )}
                />
              ),
            },
            {
              title: 'Option',
              field: 'optionId',
              render: (rowData) => {
                const option = this.state.options.find((row) => row.id === rowData.optionId);
                if (option) {
                  return (
                    <Grid container spacing={1} justify="flex-start">
                      <Grid item>{option.color}</Grid>
                      <Grid item>
                        <img src={option.swatch} style={{ width: '25px' }} />
                      </Grid>
                    </Grid>
                  );
                }
                return rowData.optionId;
              },
              editComponent: (t) => (
                <Select
                  value={t.value}
                  onChange={(e) => {
                    t.onChange(e.target.value);
                    this.updateOption(e.target.value);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {this.state.options.map((option, key) => (
                    <MenuItem value={option.id} key={key}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item>{option.color}</Grid>
                        <Grid item>
                          <img src={option.swatch} style={{ width: '25px' }} />
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Cost',
              field: 'price',
              editComponent: (t) => this.state.cost,
            },
          ]}
          data={[product]}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              console.log(newData);
              this.updateStyle(newData.styleId, newData.description, newData.catalogId, newData.category, newData.optionId);
            },
          }}
          options={{ search: false, paging: false }}
        />
        <MaterialTable
          title="Art"
          style={tableStyle}
          columns={[
            {
              title: 'Price List',
              field: 'type',
              editComponent: (t) => (
                <Select value={t.value} onChange={(e) => t.onChange(e.target.value)}>
                  {this.state.priceLists.map((priceList, key) => (
                    <MenuItem key={key} value={priceList.type}>
                      {priceList.type}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Print Type',
              field: 'printType',
              editComponent: (t) => (
                <Select value={t.value} onChange={(e) => t.onChange(e.target.value)}>
                  {this.state.printOptions
                    .filter((row) => row.type === t.rowData.type)
                    .map((priceList, key) => {
                      return (
                        <MenuItem key={key} value={priceList.printType}>
                          {priceList.printType}
                        </MenuItem>
                      );
                    })}
                </Select>
              ),
            },
            { title: 'Position', field: 'position' },
            {
              title: 'Quantity',
              field: 'quantity',
              editComponent: (t) => <div>{t.rowData.quantity ? t.rowData.quantity : product.quantity}</div>,
            },
            { title: 'Cost', field: 'cost', editable: 'never' },
          ]}
          data={product.art}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              post('/updateQuoteArt', { newData, product })
                .then((results) => {
                  updateArt(row, product, oldData, results);
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
            onRowAdd: async (newData) => {
              post('/addQuoteArt', { newData, product })
                .then(async (results) => {
                  await addArt(row, product, results);
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
            onRowDelete: async (art) => {
              post('/deleteQuoteArt', { art })
                .then(async (results) => {
                  await deleteArt(row, product, art);
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
          }}
          options={{ search: false, paging: false }}
        />
        <MaterialTable
          title="Other Costs"
          style={tableStyle}
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Cost', field: 'cost' },
          ]}
          data={product.costs}
          editable={{
            onRowAdd: async (newData) => {
              post('/newQuoteCost', { ...newData, quoteProductId: product.id }).then((results) => {
                addQuoteCost(row, product, results);
              });
            },
            onRowUpdate: async (newData, oldData) => {
              post('/updateQuoteCost', newData).then((results) => {
                updateQuoteCost(row, product, oldData, results);
              });
            },
            onRowDelete: async (oldData) => {
              post('/deleteQuoteCost', oldData).then((results) => {
                removeQuoteCost(row, product, oldData);
              });
            },
          }}
          options={{ search: false, paging: false }}
        />
      </div>
    );
  }
}

QuoteCosts.propTypes = {
  art: PropTypes.array.isRequired,
  catalogId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  costs: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  editArt: PropTypes.bool.isRequired,
  editProduct: PropTypes.bool.isRequired,
  toggleEditProduct: PropTypes.func.isRequired,
  toggleEditArt: PropTypes.func.isRequired,
  addQuoteCost: PropTypes.func.isRequired,
  updateQuoteCost: PropTypes.func.isRequired,
  removeQuoteCost: PropTypes.func.isRequired,
};
