/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import { get, patch, post } from '../../../Functions/API.js';

const RawMaterials = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({});
  const [filter, setFilter] = useState(false);
  const [rowsToLink, setRowsToLink] = useState([]);

  const [colors, setColors] = useState([]);

  const submit = () => {
    post('/fulfillment/raw-materials', { search, filter }).then((d) => {
      setData(d);
      const c = [];
      d.map(({ color }) => {
        const found = c.find((co) => co.id === color.id);
        if (!found) {
          c.push(color);
        }
      });
      setColors(c);
    });
  };

  const update = (type, value) => {
    const s = { ...search };
    if (value === '') {
      delete s[type];
    } else {
      s[type] = value;
    }
    setSearch(s);
  };
  const updateData = async (oldData, type, value) => {
    const update = {};
    if (type === 'color') {
      update.colorId = value.id;
    }
    await patch(`/fulfillment/raw-materials/${oldData.id}`, update);
    const d = [...data];
    const index = data.indexOf(oldData);
    d[index][type] = value;
    setData(d);
  };

  const combineBySize = async () => {
    const sizeIds = [...new Set(data.map(({ size }) => size.id))];
    const updates = sizeIds.map((sizeId) => {
      const rows = data.filter(({ size }) => size.id === sizeId);
      const newRawMaterialId = rows[0].id;
      rows.splice(0, 1);
      const oldRawMaterialIds = rows.map(({ id }) => id);
      return { newRawMaterialId, oldRawMaterialIds };
    });
    await post('/fulfillment/raw-materials/combine', updates);
    submit();
  };

  const updateAll = async (type, value) => {
    const d = [...data];
    if (type === 'color') {
      const newD = d.filter(({ colorId }) => colorId !== value.id);
      await Promise.all(newD.map((row) => updateData(row, 'color', value)));
    }
  };

  const columns = [
    { title: 'Brand', field: 'brand.brand' },
    { title: 'Style', field: 'style.style' },
    {
      title: 'Color',
      field: 'color.color',
      render: (rowData) => (
        <Select
          value={rowData.color.id}
          onChange={(e) =>
            updateData(
              rowData,
              'color',
              colors.find(({ id }) => id === e.target.value),
            )
          }
        >
          {colors.map((c, k) => (
            <MenuItem key={k} value={c.id}>
              {c.color}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: 'Material', field: 'material.material' },
    { title: 'Size', field: 'size.size' },
    {
      title: 'External Skus',
      render: (rowData) => (
        <div>
          {rowData.externalSkus.map((s, k) => {
            const vendor = s?.vendor?.name;
            const sku = s?.sku;
            return (
              <div key={k}>
                {vendor}: {sku}
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button onClick={combineBySize}>Combine By Size</Button>
      <Select
        onChange={(e) =>
          updateAll(
            'color',
            colors.find(({ id }) => id === e.target.value),
          )
        }
      >
        {colors.map((c, k) => (
          <MenuItem key={k} value={c.id}>
            {c.color}
          </MenuItem>
        ))}
      </Select>
      <Grid container spacing={1}>
        <Grid item>
          <TextField label={'Brand'} value={search?.brand} onChange={(e) => update('brand', e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label={'Style'} value={search?.style} onChange={(e) => update('style', e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label={'Color'} value={search?.color} onChange={(e) => update('color', e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label={'Size'} value={search?.size} onChange={(e) => update('size', e.target.value)} />
        </Grid>
        <Grid item>
          <Button onClick={submit}>Submit</Button>
        </Grid>
      </Grid>
      <MaterialTable
        columns={columns}
        data={data}
        options={{ pageSize: 50, padding: 'dense' }}
        onRowClick={(e, row) => {
          const r = [...rowsToLink];
          r.push(row);
          setRowsToLink(r);
        }}
      />
    </div>
  );
};

export default RawMaterials;
