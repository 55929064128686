import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { closeDialog } from './Actions';
import Store from './Store';

const SharedDialog = () => {
  const [dialog, setDialog] = useState(Store.getDialog());

  const getDialogFromStore = () => {
    setDialog(Store.getDialog());
  };

  useEffect(() => {
    Store.on('change', getDialogFromStore);
    return () => {
      Store.removeListener('change', getDialogFromStore);
    };
  }, []);

  return (
    <Dialog
      disableBackdropClick={dialog?.disableBackdropClick}
      onClose={closeDialog}
      aria-labelledby="shared-dialog"
      open={dialog?.open}
      fullWidth={dialog?.fullWidth}
      maxWidth={dialog?.maxWidth}
    >
      {Boolean(dialog?.title) && (
        <DialogTitle style={{ textTransform: 'capitalize' }} id="shared-dialog" onClose={closeDialog}>
          {dialog?.title}
        </DialogTitle>
      )}
      <DialogContent dividers>{dialog?.content}</DialogContent>
      {Boolean(dialog?.actions?.length) && (
        <DialogActions>
          {dialog.actions.map((action) => (
            <Button key={action.title} autoFocus onClick={action.callback} color={action.color} disabled={action.disabled}>
              {action.title}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SharedDialog;
