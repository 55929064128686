/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import WhatsNewFeatureDetails from './WhatsNewFeatureDetails';

const WhatsNewFeatures = ({ get, versionId, createSnack }) => {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);

  const getNewFeaturesByVersion = () => {
    if (versionId) {
      get('/getNewFeaturesByVersion', { versionId }, 'obj')
        .then((res) => {
          setFeatures(res);
          setLoading(false);
        })
        .catch((err) => {
          createSnack('Error', err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getNewFeaturesByVersion();
  }, []);

  return (
    <MaterialTable
      title="Features"
      columns={[
        { field: 'feature', title: 'Feature', type: 'text', headerStyle: { fontSize: '10' } },
        { field: 'description', title: 'Details', cellStyle: { width: '33%' }, headerStyle: { fontSize: '10', width: '33%' } },
      ]}
      data={features}
      options={{
        header: false,
        toolbar: false,
        maxBodyHeight: '80vh',
        paging: false,
        rowStyle: { backgroundColor: 'AliceBlue' },
      }}
      detailPanel={(row) => {
        return <WhatsNewFeatureDetails get={get} featureId={row.id} createSnack={createSnack} />;
      }}
      onRowClick={(event, rowData, togglePanel) => {
        togglePanel();
      }}
      isLoading={loading}
    />
  );
};

export default WhatsNewFeatures;
