/* eslint no-nested-ternary:0 */
/* eslint max-len:0 */
/* eslint no-console:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-plusplus:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Select,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel,
  FormControl,
  IconButton,
  CircularProgress,
  LinearProgress,
  InputLabel,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import palette from '../../../Utils/theme/palette';
import Store from './Store';
import { loadData, newData } from './Actions';
import { getCreatedTime } from '../../../../Functions/LocalStorage';
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';

const SalesComparison = () => {
  const [data, setData] = useState({});
  const date = new Date();
  const [open, setOpen] = useState(true);
  const [ytdTotal, setYTDTotal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [who, setWho] = useState('Both');
  const [year, setYear] = useState(new Date());
  const [stacked, setStacked] = useState(true);
  const [orientation, setOrientation] = useState('horizontal');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider,
          },
        },
      ],
    },
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      yAxes: [
        {
          stacked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    Store.on('change', getData);
    console.log(Store.getData());
    if (Store.getData()) {
      getData();
    } else {
      loadData(year, who, ytdTotal);
    }
    return () => {
      Store.removeListener('change', getData);
    };
  }, []);

  const getData = () => {
    setData({ ...Store.getData() });
  };

  return (
    <>
      <Card style={{ width: '100%' }}>
        <CardHeader
          title={
            <Grid container justify="flex-start" spacing={1}>
              <Grid item style={{ flexGrow: 1 }}>
                <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5">Yearly Profit by Salesman</Typography>
                  <IconButton size="small" onClick={() => setOpen(!open)}>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
              </Grid>
              {!ytdTotal && (
                <>
                  <Grid item>
                    <FormControl component="div">
                      <FormControlLabel
                        control={
                          <Switch
                            value={orientation === 'vertical'}
                            checked={orientation === 'vertical'}
                            size="small"
                            disabled={loading}
                            color="primary"
                            onChange={() => setOrientation(orientation === 'vertical' ? 'horizontal' : 'vertical')}
                          />
                        }
                        label={<span style={{ fontSize: 'smaller' }}>Vertical</span>}
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl component="div">
                      <FormControlLabel
                        control={
                          <Switch
                            value={stacked}
                            checked={stacked}
                            size="small"
                            disabled={loading}
                            color="secondary"
                            onChange={() => setStacked(!stacked)}
                          />
                        }
                        label={<span style={{ fontSize: 'smaller' }}>Stacked</span>}
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={['year']}
                    label="Year"
                    value={year}
                    onChange={(v) => {
                      setYear(v);
                      newData(v.getFullYear(), who, ytdTotal);
                    }}
                    disabled={loading}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <FormControl component="div">
                  <InputLabel shrink>Group</InputLabel>
                  <Select
                    value={who}
                    onChange={(e) => {
                      setWho(e.target.value);
                      newData(year, e.target.value, ytdTotal);
                    }}
                    disabled={loading}
                  >
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Leaders">Leadership</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="div">
                  <FormControlLabel
                    control={
                      <Switch
                        value={ytdTotal}
                        checked={ytdTotal}
                        size="small"
                        disabled={loading}
                        color="primary"
                        onChange={() => {
                          newData(year, who, !ytdTotal);
                          setYTDTotal(!ytdTotal);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 'smaller' }}>YTD</span>}
                    labelPlacement="top"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{ marginTop: 0 }}
                    readOnly
                    margin="normal"
                    id="time-picker"
                    label="Last Updated"
                    keyboardIcon={
                      <RefreshIcon
                        fontSize={'small'}
                        onClick={() => {
                          const date = new Date();
                          setYear(date.getFullYear());
                          loadData(date.getFullYear(), who, ytdTotal);
                        }}
                      />
                    }
                    value={getCreatedTime('salesComparison')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <>
            <CardContent style={{ paddingBottom: '16px' }}>
              <div
                style={{
                  height: 550,
                  position: 'relative',
                }}
              >
                {data && loading ? (
                  <LinearProgress />
                ) : ytdTotal ? (
                  <Line data={data} options={options} />
                ) : orientation === 'horizontal' ? (
                  <Bar data={data} options={{ ...barOptions, indexAxis: 'y' }} />
                ) : (
                  <Bar data={data} options={barOptions} />
                )}
              </div>
            </CardContent>
            <Divider />
          </>
        </Collapse>
      </Card>
    </>
  );
};

export default SalesComparison;
