/* eslint no-restricted-syntax:0 */
/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-useless-constructor:0 */
import React, { Component } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const NumberTable = (props) => {
  const exportCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    let headers = '';
    headers += props.columns.map((c) => c.title).join(',');
    csvContent += `${headers},Total\r\n`;
    for (const row of props.numbers) {
      csvContent += `${row.number},`;
      const content = props.columns
        .filter((c) => props.columns.indexOf(c) > 0)
        .map(({ field }) => {
          const size = row.sizes.find((s) => s.size === field);
          if (size) return size.quantity;
          return '';
        })
        .join(',');
      csvContent += `${content},${row.sizes.reduce((total, s) => total + s.quantity, 0)}\r\n`;
    }
    csvContent += `Total,`;
    const footer = props.columns
      .filter((c) => props.columns.indexOf(c) > 0)
      .map((c) => {
        return props.numbers.reduce((total, n) => {
          const size = n.sizes.find((s) => s.size === c.field);
          if (size) {
            return total + size.quantity;
          }
          return total;
        }, 0);
      })
      .join(',');
    csvContent += `${footer},${props.numbers.reduce((t, n) => t + n.sizes.reduce((total, s) => total + s.quantity, 0), 0)}\r\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${props.name}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div style={{ margin: '15px' }}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {props.columns.map((row, key) => (
              <TableCell key={key}>{row.title}</TableCell>
            ))}
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.numbers.map((row, key) => (
            <TableRow key={key}>
              {props.columns.map((column, key) => {
                if (column.field !== 'number') {
                  const found = row.sizes.find((size) => size.size === column.field);
                  if (found) {
                    return <TableCell key={key}>{found.quantity}</TableCell>;
                  }
                  return <TableCell key={key} />;
                }
                return (
                  <TableCell key={key}>
                    <b>{row.number}</b>
                  </TableCell>
                );
              })}
              <TableCell>{row.sizes.reduce((total, r) => r.quantity + total, 0)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            {props.columns.map((column, key) => {
              if (column.field !== 'number') {
                const total = props.numbers.reduce((total, row) => {
                  let tempTotal = 0;
                  for (const size of row.sizes) {
                    if (size.size === column.field) {
                      tempTotal += size.quantity;
                    }
                  }
                  return total + tempTotal;
                }, 0);
                if (total) {
                  return <TableCell key={key}>{total}</TableCell>;
                }
                return <TableCell key={key} />;
              }
              return <TableCell key={key}>Total</TableCell>;
            })}
            <TableCell>{props.numbers.reduce((total, row) => total + row.sizes.reduce((total, r) => r.quantity + total, 0), 0)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default NumberTable;
