/* eslint-disable react/display-name,react/prop-types */
import React, { useState, useEffect } from 'react';
import RestoreIcon from '@material-ui/icons/Restore';
import MaterialTable from 'material-table';
import VendorNugget from '../../../../Components/Vendors/VendorNugget';
import { get, patch, post, remove } from '../../../../Functions/API';
import { createSnack } from '../../../../Stores/Snack/Actions';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { getEstimatedCost } from '../../../../Functions/fulfillment';
import { makeStyles } from '@material-ui/core/styles';
import { ProductPathsStore } from '../../../../Stores';
import { rollbackLineItems } from '../../../../Functions/fulfillment';

const useStyles = makeStyles((theme) => ({
  // todo: change these or get rid of them if they are unused
  error: {
    color: 'red',
  },
}));

// todo: Work still needed to be done
//  * Use costGroup.statusId to group line items and base columns shown on this
//  * * note: can hard-code statusId reasoning but make note of which status they are
//  * Add "Roll-Back" Feature for LineItems
//  * * Make Items selectable
//  * * This must also remove them from the cost group
//  * * ?dont think we need to change the cost though.. just LOs
//  * ? Make the cost line editable ?
//  * ? Add a button to link to the cost in the associated workOrder (if its assigned to one)
//  * Add ability to delete a costGroup
//  * * deletes all associated costGroupItem(s) then deletes costGroup
//  * * what to do if the cost group is assigned a workOrder ?

const CostView = ({ costGroup }) => {
  const classes = useStyles();
  const { statusId } = costGroup;
  const [detailedView, setDetailedView] = useState(false);
  const [lineItemSummary, setLineItemSummary] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [costs, setCosts] = useState([]);
  const [summaryColumns, setSummaryColumns] = useState([]);

  // this gets the line item summary based on the type
  const getLineItemSummary = async () => {
    // todo: update type logic once we know which statusId we are going to use for shipping costs
    const type =
      statusId === 54 ? 'order' : statusId === 56 ? 'printCosts' : statusId === 57 ? 'pullCosts' : statusId === 'shipping' ? 'shipping' : null;
    post('/fulfillment/lineItems/group', { lineItems, type })
      .then((res) => {
        const { results } = res;
        setLineItemSummary(results);
      })
      .catch((err) => {
        console.log('err', err);
        createSnack('Error', 'Could not load');
      });
  };

  // todo: find columns that are consistent for all statuses and instead push more?
  const getSummaryColumns = () => {
    let newSummaryColumns = [];
    switch (statusId) {
      // 54 => Ordered
      case 54:
        const orderedColumns = [
          { title: 'brand', field: 'brand' },
          { title: 'color', field: 'color' },
          { title: 'style', field: 'style' },
          { title: 'sku', field: 'sku' },
          { title: 'description', field: 'description' },
          { title: 'size', field: 'size' },
          { title: 'Quantity', field: 'quantity' },
        ];
        newSummaryColumns = newSummaryColumns.concat(orderedColumns);
        break;
      // 56 => Added Print Cost
      case 56:
        newSummaryColumns = [
          // todo: decide on columns for Print costs
        ];
        break;
      // 57 => Added Pull Cost
      case 57:
        newSummaryColumns = [
          // todo: decide on columns for Pull costs
        ];
        break;
      case 'shippingCosts': // todo: change this to statusId of shipping (once we have it)
        newSummaryColumns = [
          // todo: decide on columns for Shipping costs
        ];
        break;
      default:
        newSummaryColumns = [
          {
            title: 'Cost group has an invalid statusId',
            align: 'center',
            headerStyle: {
              color: 'red',
            },
          },
        ];
        createSnack('Error', 'Cost group has an invalid statusId');
    }
    setSummaryColumns(newSummaryColumns);
  };

  const getData = async () => {
    get(`/fulfillment/costGroups/${costGroup.id}`)
      .then(({ costs, items }) => {
        setCosts(costs);
        setLineItems(items);
      })
      .catch((err) => {
        createSnack('Error', err?.message);
      });
  };

  useEffect(() => {
    getData();
    getSummaryColumns();
  }, []);

  useEffect(() => {
    if (lineItems.length > 0) {
      getLineItemSummary();
    }
  }, [lineItems]);

  const costColumns = [
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric', align: 'center' },
    {
      title: 'Vendor',
      field: 'vendorID',
      align: 'center',
      render: (rowData) => {
        return <VendorNugget rowData={rowData} />;
      },
    },
    {
      title: 'per Unit',
      field: 'perUnit',
      type: 'numeric',
      align: 'center',
      render: (rowData) => (parseFloat(rowData.perUnit) ? parseFloat(rowData.perUnit).toFixed(2) : 'N/A'),
    },
    {
      title: 'Shipping Cost',
      field: 'shippingCost',
      type: 'numeric',
      align: 'center',
      render: (rowData) => ('$' + rowData.shippingCost ? rowData.shippingCost : 0),
    },
    {
      title: 'Estimated Cost',
      field: 'totalEstimatedCost',
      type: 'currency',
      align: 'center',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(getEstimatedCost(rowData))}</div>;
        }
      },
    },
    {
      title: 'Actual Cost',
      field: 'totalActualCost',
      type: 'numeric',
      align: 'center',
      editable: 'always',
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
    {
      title: 'Notes',
      field: 'notes',
      align: 'center',
      type: 'string',
    },
  ];

  const detailedColumns = [
    { title: 'Id', field: 'id' },
    {
      title: 'Quantity',
      field: 'quantity',
      cellStyle: {
        width: '5%',
      },
      headerStyle: {
        width: '5%',
      },
    },
    {
      title: 'SKU',
      field: 'ybaSku.sku',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
    },
    { title: 'Name', field: 'name' },
    { title: 'Vendor', field: 'ybaSku.externalSku.vendor.name' },
    { title: 'Style', field: 'ybaSku.externalSku.style' },
    { title: 'Color', field: 'ybaSku.externalSku.color' },
    { title: 'Size', field: 'ybaSku.externalSku.size' },
    { title: 'Brand', field: 'ybaSku.externalSku.brand' },
    { title: 'Cost', field: 'ybaSku.externalSku.cost' },
  ];

  const viewAll = (
    <Grid container spacing={2}>
      <Grid item>Line Items</Grid>
      <Grid item>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          onClick={() => {
            setDetailedView(true);
          }}
        >
          Detailed View
        </Button>
      </Grid>
    </Grid>
  );

  const detailedLineItems = (
    <Grid container spacing={2}>
      <Grid item>All Line Items</Grid>
      <Grid item>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          onClick={() => {
            setDetailedView(false);
          }}
        >
          Normal View
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <div style={{ margin: '8px' }}>
      {detailedView ? (
        <div>
          <MaterialTable
            title={detailedLineItems}
            columns={detailedColumns}
            data={lineItems}
            options={{
              padding: 'dense',
              selection: true,
              showSelectAllCheckbox: false,
              selectionProps: (rowData) => ({
                disabled: rowData.statusId !== costGroup.statusId,
                color: 'primary',
              }),
            }}
            actions={[
              {
                tooltip: 'Rollback selected line items',
                icon: () => <RestoreIcon />,
                onClick: (event, data) => {
                  rollbackLineItems(data)
                    .then((results) => {
                      getData();
                      createSnack('Success', 'LineItems rolled back to ordering');
                    })
                    .catch((err) => {
                      createSnack('Error', err.message);
                    });
                },
              },
            ]}
          />
        </div>
      ) : (
        <div>
          <MaterialTable title={viewAll} columns={summaryColumns} data={lineItemSummary} />
          <MaterialTable title="Costs" columns={costColumns} data={costs} />
        </div>
      )}
    </div>
  );
};

export default CostView;

// todo: future ideas:
//  * Show loading spinner (show user that its thinking and not empty)
