import dispatcher from './dispatcher';
import { get, patch, post } from '../../../Functions/API';
import { createSnack } from '../../Snack/Actions';
import FiltersStore from '../../../Stores/Fulfillment/Filters/Store';
import { updateFilterCount } from '../Filters/Actions';
import { convertDateToSQLDate } from '../../../Components/Utils/SqlDate';

export const loadOrders = () => {
  // fix this route
  const filters = FiltersStore.getFilters();
  const filter = {
    eager: {
      lineItems: {
        ybaSku: {
          ybaProduct: {},
        },
      },
      $where: {
        createdAt: {},
        shipByDate: {},
        expectedArrivalDate: {},
      },
      status: {},
      $aggregations: [
        {
          type: 'count',
          alias: 'numberOfLineItems',
          relation: 'lineItems',
        },
      ],
      contact: {
        state: {},
        country: {},
      },
    },
    order: 'expectedArrivalDate desc',
  };
  if (filters.store?.id) {
    filter.eager.$where.storeId = filters.store.id;
  }
  if (filters.customer?.id) {
    filter.eager.$where['customer.id'] = filters.customer.id;
  }
  if (filters?.country?.id) {
    filter.eager.$where['contact.country.id'] = filters.country.id;
  }
  if (filters.startDate) {
    let sqlDate = convertDateToSQLDate(filters.startDate);
    filter.eager.$where.createdAt.$gte = sqlDate;
  }
  if (filters.endDate) {
    let sqlDate = convertDateToSQLDate(filters.endDate);
    filter.eager.$where.createdAt.$lte = sqlDate;
  }
  if (filters.shipByDateStart) {
    let sqlDate = convertDateToSQLDate(filters.shipByDateStart);
    filter.eager.$where.shipByDate.$gte = sqlDate;
  }
  if (filters.shipByDateEnd) {
    let sqlDate = convertDateToSQLDate(filters.shipByDateEnd);
    filter.eager.$where.shipByDate.$lte = sqlDate;
  }
  if (filters.expectedArrivalDateStart) {
    let sqlDate = convertDateToSQLDate(filters.expectedArrivalDateStart);
    filter.eager.$where.expectedArrivalDate.$gte = sqlDate;
  }
  if (filters.expectedArrivalDateEnd) {
    let sqlDate = convertDateToSQLDate(filters.expectedArrivalDateEnd);
    filter.eager.$where.expectedArrivalDate.$lte = sqlDate;
  }
  if (filters.statuses.length) {
    filter.eager.$where['statusId'] = {
      $in: filters.statuses.map((status) => status.id),
    };
  }
  if (filters.search) {
    const searchStr = `%${filters.search}%`;
    filter.eager.$where['$or'] = [
      { id: { $like: searchStr } },
      { shippingWorkOrderId: { $like: searchStr } },
      { billingWorkOrderId: { $like: searchStr } },
      { name: { $like: searchStr } },
    ];
  }
  const { page, rowsPerPage: pageSize } = filters;
  get('/fulfillment/orders', { filter, page, pageSize }, 'obj')
    .then(({ results, total: count }) => {
      dispatcher.dispatch({
        type: 'LOAD_ORDERS',
        results,
      });
      updateFilterCount({ count });
    })
    .catch((err) => {
      console.error('failed to load orders');
      createSnack('Error', err.message);
    });
};
