/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, TextField, Button, Select, MenuItem, Checkbox, Divider } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { get, post } from '../../../../../Functions/API';

const ErrorPopup = ({ type, size, updateQuantity, num, close }) => {
  const type2 = type === 'count' ? 'countNum' : 'packageNum';
  const [count, setCount] = useState(type === 'count' ? size.countNum : size.packageNum);
  const [errors, setErrors] = useState([]);
  const [errorOptions, setErrorOptions] = useState([]);
  const [resolutionOptions, setResolutionOptions] = useState([]);
  const [errorCount, setErrorCount] = useState(0);
  const [resolvedErrorCount, setResolvedErrorCount] = useState(0);
  const [extraCount, setExtraCount] = useState(0);

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    let errorCount = 0;
    let resolvedErrors = 0;
    let extraCount = 0;
    errors.forEach((error) => {
      if (error.cspErrorId === 5) {
        extraCount += error.quantity;
      } else {
        errorCount += Number(error.quantity);
      }
      if (error.cspResolutionId !== 1 && error.cspResolutionId !== 4) {
        resolvedErrors += Number(error.quantity);
      }
    });
    setErrorCount(errorCount);
    setResolvedErrorCount(resolvedErrors);
    setExtraCount(extraCount);
  }, [errors]);

  const setup = () => {
    try {
      get('/getSizesErrors', { sizesIDs: size.sizesIDs, type }, 'obj').then((results) => {
        setErrors(results);
      });
      get('/getErrorOptions').then((results) => {
        setErrorOptions(results);
      });
      get('/getResolutionOptions').then((results) => {
        setResolutionOptions(results);
      });
    } catch (error) {
      console.error(error);
      window.alert('err1');
    }
  };

  const submit = () => {
    const quantity = count;
    let errQuantity = size.quantity - count - errorCount + resolvedErrorCount;
    errQuantity = errQuantity < 0 ? (size.quantity - count) * -1 : errQuantity;
    errQuantity = count > size.quantity ? count - size.quantity - extraCount : errQuantity;
    console.log('errQuantity', errQuantity);
    if (errQuantity > 0) {
      post('/createSizeError', { size, errQuantity, quantity, type })
        .then(() => {
          console.log(count, size, num, type2);
          updateQuantity(count, size, num, type2);
          setup();
        })
        .catch((error) => {
          console.error(error);
          window.alert(error.message);
        });
    } else {
      post('/updateCount', { size, quantity, type })
        .then(() => {
          console.log('count', count);
          updateQuantity(count, size, num, type2);
          setup();
        })
        .catch((error) => console.log(error));
    }
  };

  const createNewErrorLine = () => {
    const sizeIds = size.sizesIDs;
    const quantity = count;
    const errQuantity = 0;
    post('/createSizeError', { sizeIds, errQuantity, quantity, type })
      .then(() => setup())
      .catch((error) => {
        console.error(error);
        window.alert(error.message);
      });
  };

  const eraseError = (row) => {
    const index = errors.indexOf(row);
    post('/eraseError', { error: row })
      .then((res) => {
        errors.splice(index, 1);
        setup();
      })
      .catch((error) => {
        console.error(error);
        window.alert(error.message);
      });
  };

  const updateError = (error, row) => {
    const index = errors.indexOf(row);
    const newError = errors[index];
    newError.cspResolutionId = error === 5 ? 4 : row.cspErrorId === 5 ? 1 : newError.cspResolutionId;
    newError.cspErrorId = error;
    sendError(newError);
  };

  const updateQuantity2 = (row, quantity) => {
    const newErrors = [...errors];
    const index = newErrors.indexOf(row);
    row.quantity = quantity;
    newErrors[index] = row;
    if (quantity === '') {
      setErrors(newErrors);
    } else {
      sendError(newErrors[index]);
    }
  };

  const updatePrinted = (row) => {
    const index = errors.indexOf(row);
    errors[index].printed = !errors[index].printed;
    sendError(errors[index]);
  };

  const updateResolution = (resolution, row) => {
    const index = errors.indexOf(row);
    errors[index].cspResolutionId = resolution;
    if (resolution !== 1) {
      errors[index].resolved = new Date();
    } else {
      errors[index].resolved = null;
    }
    sendError(errors[index]);
  };

  const sendError = (oldError) => {
    post('/updateError', { error: oldError })
      .then((error) => {
        const newErrors = [...errors];
        newErrors[errors.indexOf(oldError)] = error;
        setErrors(newErrors);
      })
      .catch((error) => {
        console.error(error);
        window.alert(error.message);
      });
  };

  const resolveNumber = (bool) => {
    let result = errorCount + size[type2] - size.quantity - resolvedErrorCount - extraCount;
    return bool ? result > 0 && !extraErrors(true) : result > 0 ? result : 0;
  };

  const moreErrors = (bool) => {
    let result = size.quantity - (errorCount + size[type2] - resolvedErrorCount);
    return bool ? result > 0 : result;
  };

  const extraErrors = (bool) => {
    let result = size[type2] - extraCount;
    return bool ? result > size.quantity : result - size.quantity;
  };

  const resolutionError = (rowData) => {
    let error =
      (resolveNumber(true) && rowData.cspResolutionId === 1) ||
      (rowData.cspErrorId === 5 && rowData.cspResolutionId !== 4) ||
      (rowData.cspErrorId !== 5 && rowData.cspResolutionId === 4);
    return error;
  };

  const tooManyExtras = () => {
    return extraCount && extraCount > size[type2] - size.quantity;
  };

  const closeDisabled = () => {
    let disabled =
      resolveNumber(true) ||
      moreErrors(true) ||
      extraErrors(true) ||
      tooManyExtras() ||
      errors.some((err) => err.cspErrorId === 1) ||
      errors.some((err) => err.cspErrorId === 5 && err.cspResolutionId !== 4) ||
      errors.some((err) => err.cspResolutionId === 4 && err.cspErrorId !== 5) ||
      Number(count) !== size[type2];
    return disabled;
  };

  return (
    <Paper
      style={{
        position: 'absolute',
        width: '100%',
        backgroundColor: '#ffffff',
        height: '100%',
        overflowX: 'none',
        overflowY: 'auto',
        outline: 'none',
      }}
    >
      <Grid container justify="flex-start" spacing={1} alignItems="center" style={{ margin: '10px', width: 'calc(100% - 20px)' }}>
        <Grid item>
          <TextField
            label={type === 'packaging' ? 'Package' : 'Count'}
            type="number"
            onChange={(e) => setCount(e.target.value)}
            helperText={`Expected ${size.quantity} ${size.size}`}
            value={count}
            error={Number(count) !== size[type2]}
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Button color="primary" variant="contained" size="small" onClick={submit}>
            Submit
          </Button>
          <Button color="secondary" variant="contained" size="small" onClick={createNewErrorLine}>
            Add Line
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={close} variant="contained" size="small" disabled={closeDisabled()}>
            Close
          </Button>
        </Grid>
      </Grid>
      <div>
        <MaterialTable
          title="Errors"
          components={{
            Toolbar: (props) => {
              return (
                <div>
                  <MTableToolbar {...props} />
                  {Number(count) !== size[type2] && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      {` Changes not saved! remember to click submit!! (Data reflecting ${size[type2]})`}
                    </div>
                  )}
                  {moreErrors(true) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      {moreErrors()} {moreErrors() > 1 ? 'Errors' : 'Error'} not accounted for!
                    </div>
                  )}
                  {extraErrors(true) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      {extraErrors()} {extraErrors() === 1 ? 'extra' : 'extras'} not accounted for! (remember to press submit)
                    </div>
                  )}
                  {resolveNumber(true) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      Please resolve {resolveNumber()} {resolveNumber() > 1 ? 'errors ' : 'error '}
                      that {resolveNumber() > 1 ? 'have ' : 'has '} now been solved
                    </div>
                  )}
                  {tooManyExtras() && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      <div>More Extras than accounted for!!</div>
                    </div>
                  )}
                  {errors.some((err) => err.cspErrorId === 1) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      <div>Please include an error for each line!</div>
                    </div>
                  )}
                  {errors.some((err) => err.cspErrorId === 5 && err.cspResolutionId !== 4) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      <div>One or more Extra errors has the wrong resolution</div>
                    </div>
                  )}
                  {errors.some((err) => err.cspResolutionId === 4 && err.cspErrorId !== 5) && (
                    <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>
                      <div>Extra is not a valid resolution for an error that is not Extra</div>
                    </div>
                  )}
                </div>
              );
            },
          }}
          columns={[
            {
              title: 'Error',
              field: 'cspErrorId',
              render: (rowData) => (
                <Select value={rowData.cspErrorId} onChange={(e) => updateError(e.target.value, rowData)} error={rowData.cspErrorId === 1}>
                  {errorOptions.map((error, key) => (
                    <MenuItem value={error.id} key={key}>
                      {error.error}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Quantity',
              field: 'quantity',
              render: (rowData) => (
                <TextField
                  type="number"
                  value={rowData.quantity}
                  onChange={(e) => {
                    updateQuantity2(rowData, e.target.value);
                  }}
                  error={rowData.quantity <= 0}
                />
              ),
            },
            {
              title: 'Printed',
              field: 'printed',
              render: (rowData) => <Checkbox checked={rowData.printed} value={rowData.printed} onChange={() => updatePrinted(rowData)} />,
            },
            {
              title: 'Resolution',
              field: 'cspResolutionId',
              render: (rowData) => (
                <Select value={rowData.cspResolutionId} onChange={(e) => updateResolution(e.target.value, rowData)} error={resolutionError(rowData)}>
                  {resolutionOptions.map((resolution, key) => (
                    <MenuItem value={resolution.id} key={key}>
                      {resolution.resolution}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Erase',
              field: 'erase',
              render: (rowData) => (
                <Button color="secondary" onClick={() => eraseError(rowData)}>
                  Erase
                </Button>
              ),
            },
          ]}
          data={errors}
          options={{ search: false, paging: false, maxBodyHeight: '600px' }}
        />
        <Divider style={{ marginTop: '15px' }} />
      </div>
    </Paper>
  );
};

ErrorPopup.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  num: PropTypes.number.isRequired,
};

export default ErrorPopup;
