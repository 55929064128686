import React, { useState, useEffect } from 'react';
import {
  AppBar,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  InputLabel,
  LinearProgress,
  SwipeableDrawer,
  Toolbar,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { post } from '../../../../../Functions/API';
import UserStore from '../../../../../Stores/User/Store';
import VendorsStore from '../../../../../Stores/Vendors/Store';
import { fetchVendors } from '../../../../../Stores/Vendors/Actions';
import { closeDialog, createDialog } from '../../../../../Stores/Dialog/Actions';
import NumberSummary from '../Production/Numbers/NumberSummary';
import { InventoryStore } from '../../../../../Stores';
import Pagination from '../../../../CustomComponents/Pagination';

const PageToolbar = ({
  updateFilter,
  clearFilters,
  setSizesModal,
  setImportModal,
  setPage,
  submit,
  setNumbersModal,
  setNumOfLines,
  deleteAll,
  addRowEnabled,
  filters,
  orderOptions,
  page,
  pageSize,
  total,
  loading,
  workOrder,
}) => {
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const [vendors, setVendors] = useState([]);
  const keys = Object.keys(filters);
  const width = window.innerWidth;
  const user = UserStore.getUser();
  const [skus, setSkus] = useState([]);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    InventoryStore.on('change', getInventory);
    getInventory();
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    } else {
      getVendors();
    }
    return () => {
      VendorsStore.removeListener('change', getVendors);
      InventoryStore.removeListener('change', getInventory);
    };
  }, []);

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  const getInventory = () => {
    const inventoryInfo = InventoryStore.getInventory();
    const s = inventoryInfo.map((i) => i.skus).flat();
    setSkus(s);
  };

  const addLine = () => {
    const defaultLine = {
      type: '',
      printType: '',
      vendorID: null,
      inventoryId: null,
      addSKU: null,
      removeSKU: null,
      league: '',
      team: '',
      printShop: '',
      adultStyle: '',
      youthStyle: '',
      color: '',
      notes: '',
      misprint: false,
      foldAndPolyBag: false,
      useInventory: false,
      groupAsSet: false,
      chips: [],
      sizes: [],
    };
    setNumOfLines((numOfLines) => numOfLines.concat(defaultLine));
  };

  const handleDeleteAll = () => {
    let c1 = window.confirm('Are you sure you know what you are doing???');
    let c2, c3;
    if (c1) {
      c2 = window.confirm('You are about to delete all product lines, is this really what you want to do????');
      if (c2) {
        c3 = window.confirm('You actually want to delete ALL of them??? this CAN NOT be undone!!! Proceed at your own risk.....');
      }
    }
    if (c1 && c2 && c3) {
      console.log('Affirmative capitain');
      post('/deleteAllOrders', { workOrderID: workOrder.id })
        .then((results) => {
          console.log('RESuLtSS:', results);
          setNumOfLines([]);
        })
        .catch((err) => {
          window.alert('Error', err.message);
        });
    }
    setDeleteAllDisabled(true);
  };

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen);
  };

  const getSkuName = (id) => {
    const found = skus.find((s) => s.id === id);
    if (found) {
      const { sku, name } = found;
      return `${sku} - ${name}`;
    }
    return 'Not Found';
  };

  return (
    <div>
      <SwipeableDrawer onClose={toggleFilterDrawer} onOpen={toggleFilterDrawer} open={filterDrawerOpen}>
        {!loading && (
          <Grid container spacing={1} justify={'center'} style={{ paddingTop: '15px' }}>
            {Object.keys(orderOptions).map((option, key) => (
              <Grid item xs={11} key={key}>
                <FormControl component="div" style={{ width: '100%' }}>
                  <InputLabel shrink>{option === 'vendorID' ? 'VENDOR' : option.toUpperCase()}</InputLabel>
                  <Select value={filters[option]} onChange={(event) => updateFilter(option, event.target.value)}>
                    <MenuItem value="" key={key}>
                      Nothing
                    </MenuItem>
                    {orderOptions[option].map((row, key) => (
                      <MenuItem value={row} key={key}>
                        {option === 'vendorID'
                          ? vendors.find((v) => v.id === row)?.name
                          : option === 'removeSKU'
                          ? getSkuName(row)
                          : option === 'addSKU'
                          ? getSkuName(row)
                          : row}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
            <Grid item xs={11}>
              <Button size="small" color={'secondary'} style={{ width: '100%' }} variant="contained" onClick={clearFilters}>
                Clear
              </Button>
            </Grid>
            <Grid item xs={11}>
              <Button size="small" style={{ width: '100%' }} variant="contained" onClick={toggleFilterDrawer}>
                Close
              </Button>
            </Grid>
          </Grid>
        )}
      </SwipeableDrawer>
      {width > 1500 ? (
        <AppBar position="fixed" style={{ top: 'auto', bottom: 0, backgroundColor: '#eaeaea' }}>
          <Toolbar>
            {!loading ? (
              <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item style={{ flexGrow: 1 }}>
                  <Button size={'small'} variant={'contained'} onClick={toggleFilterDrawer}>
                    Filters
                  </Button>
                </Grid>
                {setNumbersModal && (
                  <Grid item>
                    <Button
                      size="small"
                      onClick={() => {
                        createDialog({
                          title: 'Number Summaries',
                          content: <NumberSummary workOrder={workOrder} filters={filters} />,
                          size: 'xl',
                          actions: [{ title: 'Close', color: 'secondary', callback: closeDialog }],
                        });
                      }}
                      style={{
                        margin: '10px',
                        color: 'white',
                        backgroundColor: 'rgb(255,133,17)',
                      }}
                      variant="contained"
                    >
                      Number Sheet
                    </Button>
                  </Grid>
                )}
                {setSizesModal && (
                  <Grid item>
                    <Button
                      size="small"
                      onClick={() => setSizesModal(true)}
                      style={{
                        color: 'white',
                        backgroundColor: 'rgb(255, 160, 0)',
                      }}
                      variant="contained"
                    >
                      Totals
                    </Button>
                  </Grid>
                )}
                {user && (user.group === 'Admin' || user.group === 'Order Processor') && deleteAll && (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setDeleteAllDisabled(!deleteAllDisabled);
                      }}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={handleDeleteAll}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      classes={{ disabled: { backgroundColor: 'black' } }}
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </Grid>
                )}
                <Grid item style={{ color: 'black' }}>
                  <Pagination total={total} setPage={setPage} page={page} pageSize={pageSize} options={[]} />
                </Grid>
                {setImportModal && (
                  <Grid item>
                    <Button size="small" onClick={() => setImportModal(true)} style={{ margin: '10px' }} variant="contained" color="secondary">
                      Import
                    </Button>
                  </Grid>
                )}
                {addRowEnabled && (
                  <Grid item>
                    <Button size="small" onClick={addLine} style={{ margin: '10px' }} variant="contained" color="primary">
                      Add Row
                    </Button>
                  </Grid>
                )}
                {submit && (
                  <Grid item>
                    <Button size="small" onClick={submit} style={{ margin: '10px', backgroundColor: 'green' }} variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <LinearProgress style={{ width: '100%' }} />
            )}
          </Toolbar>
        </AppBar>
      ) : (
        <ExpansionPanel
          style={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1000,
            backgroundColor: '#eaeaea',
          }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            {!loading ? (
              <div>
                <Pagination total={total} setPage={setPage} page={page} pageSize={pageSize} options={[]} />
              </div>
            ) : (
              <LinearProgress style={{ width: '100%' }} />
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {!loading ? (
              <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item style={{ flexGrow: 1 }}>
                  <Button size={'small'} variant={'contained'} onClick={toggleFilterDrawer}>
                    Filters
                  </Button>
                </Grid>
                {setNumbersModal && (
                  <Grid item>
                    <Button
                      size="small"
                      onClick={() => {
                        createDialog({
                          title: 'Number Summaries',
                          content: <NumberSummary workOrder={workOrder} filters={filters} />,
                          size: 'xl',
                          actions: [{ title: 'Close', color: 'secondary', callback: closeDialog }],
                        });
                      }}
                      style={{
                        margin: '10px',
                        color: 'white',
                        backgroundColor: 'rgb(255,133,17)',
                      }}
                      variant="contained"
                    >
                      Number Sheet
                    </Button>
                  </Grid>
                )}
                {setSizesModal && (
                  <Grid item>
                    <Button
                      size="small"
                      onClick={() => setSizesModal(true)}
                      style={{
                        margin: '10px',
                        color: 'white',
                        backgroundColor: 'rgb(255, 160, 0)',
                      }}
                      variant="contained"
                    >
                      Totals
                    </Button>
                  </Grid>
                )}
                {user && user.group === 'Admin' && (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setDeleteAllDisabled(!deleteAllDisabled);
                      }}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={handleDeleteAll}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      classes={{ disabled: { backgroundColor: 'black' } }}
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </Grid>
                )}
                {setImportModal && (
                  <Grid item>
                    <Button size="small" onClick={() => setImportModal(true)} style={{ margin: '10px' }} variant="contained" color="secondary">
                      Import
                    </Button>
                  </Grid>
                )}
                {addRowEnabled && (
                  <Grid item>
                    <Button size="small" onClick={addLine} style={{ margin: '10px' }} variant="contained" color="primary">
                      Add Row
                    </Button>
                  </Grid>
                )}
                {submit && (
                  <Grid item>
                    <Button size="small" onClick={submit} style={{ margin: '10px', backgroundColor: 'green' }} variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <LinearProgress style={{ width: '100%' }} />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </div>
  );
};

PageToolbar.propTypes = {
  filters: PropTypes.object.isRequired,
  orderOptions: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  updateFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setSizesModal: PropTypes.func,
  firstPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  lastPage: PropTypes.func.isRequired,
  setImportModal: PropTypes.func,
  addLine: PropTypes.func,
  addRowEnabled: PropTypes.bool,
  submit: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  setNumbersModal: PropTypes.func.isRequired,
  workOrder: PropTypes.object.isRequired,
  setNumOfLines: PropTypes.func.isRequired,
  deleteAll: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default PageToolbar;
