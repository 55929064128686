import React from 'react';
import PropTypes from 'prop-types';
import WorkHistory from './WorkHistory';
import Timesheet from './Timesheet';

const Old = ({ workOrder, user }) => {
  return (
    <div>
      <div>
        <WorkHistory workOrder={workOrder} user={user} />
      </div>
      <div>
        <Timesheet workOrder={workOrder} user={user} />
      </div>
    </div>
  );
};

export default Old;

Old.propTypes = {
  workOrder: PropTypes.object,
  user: PropTypes.object,
};
