/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import './index.css';

function UnknownPage({ logout }) {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="h3">Looks like you're lost</Typography>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: '15px' }} container justify="center">
                <Grid item>
                  <Button href="/" color="primary" variant="contained">
                    Go Home
                  </Button>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: '15px' }} container justify="center">
                <Grid item>
                  <Button onClick={logout} color="secondary" variant="contained">
                    Log Out
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

UnknownPage.propTypes = {
  logout: PropTypes.func,
};

export default UnknownPage;
