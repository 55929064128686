/* eslint react/display-name:0 */
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Checkbox } from '@material-ui/core';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API';
import { updateWorkOrder } from '../../../Stores/WorkOrder/Actions';
import { workorder } from '../../../Constants/backendRoutes';

const InventoryPull = () => {
  const [data, setData] = useState([]);
  const columns = [
    { field: 'workOrderID', title: 'WO ID' },
    { field: 'customerName', title: 'Customer' },
    { field: 'dueDate', title: 'Due Date', type: 'date' },
    {
      field: 'productPulled',
      title: 'Product Pulled',
      render: (rowData) => (
        <Checkbox
          checked={rowData.productPulled}
          size={'small'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return updateWorkOrder(rowData.id, { productPulled: !rowData.productPulled }).then(() => {
              const d = [...data];
              const index = d.indexOf(rowData);
              d[index].productPulled = !rowData.productPulled;
              setData(d);
            });
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const filter = {
      eager: {
        $where: {
          productionComplete: false,
          costAndInvoiceComplete: true,
          salesApproved: true,
          'orders.removeSku': {
            $exists: true,
          },
        },
      },
      order: 'dueDate asc',
    };
    get(`${workorder.list}?filter=${JSON.stringify(filter)}`).then(({ results }) => {
      setData(results);
    });
  }, []);

  return (
    <div>
      <Card>
        <CardHeader title={'Pull Inventory'} />
        <CardContent>
          <MaterialTable
            columns={columns}
            data={data}
            options={{ toolbar: false, padding: 'dense', pageSizeOptions: [5, 10, 20, 50], pageSize: 5 }}
            onRowClick={(e, rowData) => {
              // frontend route
              window.open(`/workorder/${rowData.id}`);
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default InventoryPull;
