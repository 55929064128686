import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';

export const loadOptions = () => {
  const filter = {
    eager: {
      $where: {
        type: 'type',
      },
    },
  };
  get('/options', { filter })
    .then((results) => {
      dispatcher.dispatch({
        type: 'LOAD_OPTIONS',
        results: { type: results },
      });
    })
    .catch((err) => {
      console.log('ERROR', err);
      createSnack('Error', err.message);
    });
};

export const addOption = async (option) => {
  await post('/newOption', { type: 'type', name: option, value: option })
    .then(() => {
      dispatcher.dispatch({
        type: 'ADD_OPTION',
        option,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};
