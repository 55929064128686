import dispatcher from './dispatcher';
import { get } from '../../../../Functions/API';
import { months } from '../../../Utils/monthsOfTheYear';
import colors from '../../../Utils/MaterialColors';

const getData = (year, who, ytdTotal, where) => {
  get('/betterFinancialSummary', { year, who }, 'obj')
    .then((results) => {
      const datasets = [];
      for (let i = 0; i < results.length; i++) {
        const row = {};
        row.label = results[i].label;
        row.backgroundColor = 'rgba(255,255,255,0)';
        if (!ytdTotal) {
          row.backgroundColor = [];
        }
        row.borderColor = colors[i];
        if (!ytdTotal) {
          row.borderColor = [];
        }
        if (ytdTotal) {
          row.pointRadius = [];
          row.pointHoverRadius = 10;
          row.pointHitRadius = 10;
          row.pointStyle = [];
          row.pointBackgroundColor = colors[i];
        }
        row.data = [];
        for (const d of results[i].data) {
          if (ytdTotal) {
            row.data.push(d.ytdProfit.toFixed(2));
            row.pointRadius.push(5);
            row.pointStyle.push('circle');
          } else {
            row.backgroundColor.push(colors[i]);
            row.borderColor.push(colors[i]);
            row.data.push(d.profit.toFixed(2));
          }
        }
        datasets.push(row);
      }
      if (ytdTotal) {
        datasets.sort((a, b) => {
          return b.data[b.data.length - 1] - a.data[a.data.length - 1];
        });
      }
      console.log(datasets);
      dispatcher.dispatch({
        type: where,
        data: { labels: months, datasets },
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const loadData = (year, who, ytdTotal) => {
  getData(year, who, ytdTotal, 'LOAD_DATA');
};

export const newData = (year, who, ytdTotal) => {
  getData(year, who, ytdTotal, 'LOAD_NEW_DATA');
};
