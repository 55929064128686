import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './Pages/login';
import CreateUser from './Components/Authentication/CreateUser';
import NES from './Pages/nes/NES';
import QuotePDF from './Pages/quotes/quote/pdf';
// import Shopify from './Components/Integrations/Shopify';
import UnknownPage from './Pages/404';
import Shopify from './Pages/Shopify';
import { get, post } from './Functions/API';
import UnauthorizedPage from './Pages/unauthorized';
import NewHome from './Pages';
import Menu from './Components/Menu';
import ProtectedRoute from './ProtectedRoute';
import Admin from './Pages/admin';
import Customers from './Pages/OldSystem';
import CustomerManager from './Pages/customers';
import Inventory from './Pages/inventory';
import Quotes from './Pages/quotes';
import DeveloperTools from './Pages/developer-tools';
import Fulfillment from './Pages/fulfillment';
import ProductionRouter from './Pages/Production';
import Accounting from './Pages/accounting';
import Companies from './Pages/external-data';
import WorkOrder from './Pages/workorder';
import Notifications from './Pages/notifications';
import ArtWorkOrder from './Pages/art/workorder';
import Mistakes from './Pages/costs-by-order-processors';
import Inactive from './Pages/inactive';
import SharedDialog from './Stores/Dialog';
import DepositPhotosPage from './Pages/deposit-photos';
import CSP from './Pages/csp';
import ArtStudio from './Components/ArtStudio/ArtStudio';
import ShopifyInfoPlus from './Pages/shopify-infoPlus';
import Uploads from './Pages/uploads';
import Upload from './Pages/uploads/upload';
import Stripe from './Pages/stripe';
import InfoPlusArt from './Pages/info-plus-art/index';
import SalesSheet from './Pages/accounting/sales-sheet';
import InfoPlus from './Pages/info-plus/index.js';
import YbaProducts from './Pages/yba-products/index.js';
import RawMaterials from './Pages/yba-products/raw-materials/index.js';
// import Integrations from './Pages/integrations/index';

function Routes({ user, logout }) {
  return (
    <Router>
      <Switch>
        {/* PROTECTED NESTED */}
        <ProtectedRoute path="/admin" user={user} permission="employeeRoutes">
          <Menu />
          <Admin get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/customers" user={user} permission="employeeRoutes">
          <Menu />
          <CustomerManager get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/inventory" user={user} permission="employeeRoutes">
          <Menu />
          <Inventory />
        </ProtectedRoute>
        <ProtectedRoute path="/quotes" user={user} permission="employeeRoutes">
          <Menu />
          <Quotes get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/fulfillment" user={user} permission="employeeRoutes">
          <Menu />
          <Fulfillment />
        </ProtectedRoute>
        <ProtectedRoute path="/OldSystem" user={user} permission="employeeRoutes">
          <Menu />
          <Customers get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/developer-tools" user={user} permission="employeeRoutes">
          <Menu />
          <DeveloperTools />
        </ProtectedRoute>
        <ProtectedRoute path="/production" user={user} permission="employeeRoutes">
          <Menu />
          <ProductionRouter get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/accounting/sales-sheet" user={user} permission="viewSalesSheet">
          <Menu />
          <SalesSheet get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/accounting" user={user} permission="viewAccountingCenter">
          <Menu />
          <Accounting get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/external-data" user={user} permission="employeeRoutes">
          <Menu />
          <Companies get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/workorder/:WorkOrderId/:tab" user={user} permission="employeeRoutes">
          <Menu />
          <WorkOrder get={get} post={post} user={user} />
        </ProtectedRoute>
        <Redirect from={'/workorder/:WorkOrderId'} to={'/workorder/:WorkOrderId/art'} />
        {/* SINGLETON PROTECTED */}
        <ProtectedRoute exact path="/" user={user} permission="homePageRoute">
          {!user?.groups.find((g) => g.name.includes('Customer')) && <Menu />}
          <NewHome get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/notifications" user={user} permission="employeeRoutes">
          <Menu />
          <Notifications get={get} post={post} includeMenu user={user} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/art/workorder/:WorkOrderId" user={user} permission="employeeRoutes">
          <Menu />
          <ArtWorkOrder get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/costs-by-order-processor" user={user} permission="employeeRoutes">
          <Menu />
          <Mistakes get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/csp" user={user} permission="employeeRoutes">
          <Menu />
          <CSP get={get} post={post} user={user} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/art-requests" user={user} permission="artRequests">
          <Menu />
          <ArtStudio user={user} get={get} post={post} />
        </ProtectedRoute>
        {/*<ProtectedRoute path="/shopify-infoPlus" user={user} permission="shopifyInfoPlus">*/}
        {/*  <Menu />*/}
        {/*  <ShopifyInfoPlus user={user} get={get} post={post} />*/}
        {/*</ProtectedRoute>*/}
        <ProtectedRoute exact path="/uploads" user={user} permission="shopifyInfoPlus">
          <Menu />
          <Uploads />
        </ProtectedRoute>
        <ProtectedRoute exact path="/uploads/:id" user={user} permission="shopifyInfoPlus">
          <Menu />
          <Upload />
        </ProtectedRoute>
        <ProtectedRoute exact path="/info-plus-art" user={user} permission="infoPlusArt">
          <Menu />
          <InfoPlusArt />
        </ProtectedRoute>
        <ProtectedRoute path="/info-plus" user={user} permission="employeeRoutes">
          <Menu />
          <InfoPlus />
        </ProtectedRoute>
        <ProtectedRoute path="/yba-products/raw-materials" user={user} permission="employeeRoutes">
          <Menu />
          <RawMaterials />
        </ProtectedRoute>
        <ProtectedRoute path="/yba-products" user={user} permission="employeeRoutes">
          <Menu />
          <YbaProducts />
        </ProtectedRoute>
        {/* <ProtectedRoute path="/integrations" user={user} permission="employeeRoutes"> */}
        {/*   <Menu /> */}
        {/*   <Integrations /> */}
        {/* </ProtectedRoute> */}
        {/*<Route exact path="/csp" component={() => <Login isInternal={false} />} />*/}
        <Route
          exact
          path="/deposit-photos"
          component={() => (
            <div>
              <Menu />
              <DepositPhotosPage isInternal={false} />
            </div>
          )}
        />
        <Route exact path="/stripe/:customerId" component={(props) => <Stripe {...props} isInternal={false} />} />
        <Route exact path="/createUser" component={(props) => <CreateUser {...props} get={get} post={post} isInternal />} />
        <Route exact path="/csp/createUser" component={(props) => <CreateUser {...props} get={get} post={post} isInternal={false} />} />
        <Route exact path="/quote/:quoteId" component={(props) => <QuotePDF {...props} get={get} post={post} user={user} />} />
        {/*<Route exact path="/integrations/shopify" component={(props) => <Shopify {...props} />} />*/}
        <Route path="/shopify" component={(props) => <Shopify {...props} />} />
        <Route exact path="/nes/:workOrderID" component={(props) => <NES {...props} get={get} post={post} user={user} />} />
        <Route path="/unauthorized" component={() => <UnauthorizedPage logout={logout} />} />
        <Route path="/inactive" component={() => <Inactive logout={logout} />} />
        <Route path="/login" component={() => <Login isInternal />} />
        <Route path="/404" component={() => <UnknownPage logout={logout} />} />
        {!user ? <Redirect to="/login" /> : <UnknownPage logout={logout} />}
      </Switch>
      <SharedDialog />
    </Router>
  );
}

Routes.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
};

export default Routes;
