/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Button, DialogActions, Typography, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, patch } from '../../../Functions/API';
import { closeDialog } from '../../../Stores/Dialog/Actions';
import MaterialTable from 'material-table';
import CustomerStore from '../../../Stores/Customer/Store';
import differenceBy from 'lodash/differenceBy';
import { fetchCustomer } from '../../../Stores/Customer/Actions';
import { updateWorkOrder } from '../../../Stores/WorkOrder/Actions';

const WorkOrderContactsDialog = ({ workOrder, type }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [contacts, setContacts] = useState([]);
  const columns = [
    {
      title: 'On Workorder',
      field: 'checked',
      render: (rowData) => (
        <Checkbox checked={Boolean(rowData.checked)} value={Boolean(rowData.checked)} onClick={() => setChecked(rowData, !rowData.checked)} />
      ),
    },
    {
      title: 'CO',
      field: 'co',
      editable: 'never',
    },
    {
      title: 'Organization',
      field: 'organization',
      editable: 'never',
    },
    {
      title: 'Phone',
      field: 'phone',
      editable: 'never',
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'never',
    },
    {
      title: 'Address 1',
      field: 'address1',
      editable: 'never',
    },
    {
      title: 'Address 2',
      field: 'address2',
      editable: 'never',
    },
    {
      title: 'City',
      field: 'city',
      editable: 'never',
    },
    {
      title: 'Zip',
      field: 'zip',
      editable: 'never',
    },
    {
      title: 'State',
      field: 'state.name',
      editable: 'never',
    },
    {
      title: 'Country',
      field: 'country.code',
      editable: 'never',
    },
  ];

  if (!workOrder.splitInvoice) {
    columns.splice(1, 0, {
      title: 'Use Address*',
      field: 'useAddress',
      render: (rowData) => (
        <Checkbox
          checked={Boolean(rowData.useAddress)}
          value={Boolean(rowData.useAddress)}
          onChange={() => {
            const data = [...contacts];
            data.map((d) => (d.useAddress = false));
            const index = contacts.indexOf(rowData);
            data[index].useAddress = !data[index].useAddress;
            setContacts(data);
          }}
        />
      ),
    });
  }

  useEffect(() => {
    CustomerStore.on('change', getCustomer);

    getCustomer();

    return () => {
      CustomerStore.removeListener('change', getCustomer);
    };
  }, []);

  const getCustomer = () => {
    fetchCustomer(workOrder.customerID).then((customer) => {
      setCustomer(customer);
    });
  };

  const setChecked = ({ id, useAddress }, isChecked) => {
    const localContacts = [...contacts].map((contact) => {
      if (contact.id === id && contact.useAddress === useAddress) {
        return { ...contact, checked: isChecked };
      } else {
        return contact;
      }
    });
    setContacts(localContacts);
  };

  useEffect(() => {
    if (customer?.contacts && workOrder?.contacts) {
      // all workorder contacts are contacts in list with check true so they are listed
      const workOrderContacts = workOrder.contacts
        .filter((contact) => contact.type === type)
        .map((contact) => {
          contact.checked = true;
          return contact;
        });

      // customer contacts, we only want customer contacts not already included
      const customerContacts = differenceBy(
        customer.contacts
          .filter((contact) => contact.type === type)
          .map((contact) => {
            contact.checked = false;
            return contact;
          }),
        workOrderContacts,
        'id',
      );

      setContacts([...workOrderContacts, ...customerContacts]);
      setIsLoading(false);
    }
  }, [customer, workOrder]);

  const handleKeyDown = (event) => {
    if (event.keyCode && event.keyCode !== 13 && event.keyCode !== 32) return;
    save();
  };

  const save = async () => {
    const checkedContacts = contacts
      .filter((contact) => contact.checked)
      .map((contact) => {
        delete contact.checked;
        return contact;
      });
    const otherContacts = workOrder?.contacts?.filter((contact) => contact.type !== type);

    const updatedContacts = [...checkedContacts, ...otherContacts];
    // const updatedWorkOrder = await patch(`/workorder/${workOrder.id}`, { ...workOrder, contacts: updatedContacts });

    updateWorkOrder(workOrder.id, { contacts: updatedContacts });

    closeDialog();
  };

  const handleCancelKeyDown = (event) => {
    if (event.keyCode && event.keyCode !== 13 && event.keyCode !== 32) return;
    closeDialog();
  };

  return (
    <div>
      <MaterialTable isLoading={isLoading} options={{ toolbar: false }} columns={columns} data={contacts} readonly />
      <DialogActions>
        <Typography variant={'caption'} style={{ flexGrow: 1 }}>
          * Click use address when you need more than one email (emails are taken from billing contacts) on an invoice or are doing a split shipment
          but not a split invoice. The selected address will be on the invoice.
        </Typography>
        <Button onClick={closeDialog} onKeyDown={handleCancelKeyDown} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={save} onKeyDown={handleKeyDown} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

WorkOrderContactsDialog.propTypes = {
  workOrder: PropTypes.object,
  type: PropTypes.string,
};

WorkOrderContactsDialog.displayName = 'WorkOrderContactsDialog';

export default WorkOrderContactsDialog;
