/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { get } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import PropTypes from 'prop-types';
import InboundPackages from '../../../../Shared/InboundPackages/';

const InboundPackagesModal = ({ costingID, WorkOrderId }) => {
  const [couriers, setCouriers] = useState([]);

  useEffect(() => {
    getCouriers();
  }, []);

  const getCouriers = () => {
    get(`/couriers`)
      .then(setCouriers)
      .catch((err) => createSnack('Error', err.message));
  };

  return (
    <div style={{ backgroundColor: 'white', maxHeight: '600px' }}>
      <InboundPackages couriers={couriers} costingID={costingID} WorkOrderId={WorkOrderId} allowAdd={true} />
    </div>
  );
};

InboundPackagesModal.propTypes = {
  costingID: PropTypes.number,
  WorkOrderId: PropTypes.string,
};

InboundPackagesModal.displayName = 'InboundPackagesModal';

export default InboundPackagesModal;
