/* eslint no-alert:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import WhatsNewDetails from './WhatsNewDetails';

const WhatsNew = ({ get, post, versionId }) => {
  const [data, setData] = useState([]);

  const columns = [
    { field: 'feature', title: 'Feature', type: 'text', headerStyle: { fontSize: '10' } },
    { field: 'description', title: 'Details', headerStyle: { fontSize: '10' } },
  ];

  const getNewFeaturesByVersion = () => {
    if (versionId) {
      get('/getNewFeaturesByVersion', { versionId }, 'obj').then((res) => setData(res.reverse()));
    }
  };

  useEffect(() => {
    getNewFeaturesByVersion();
  }, []);

  const addFeature = (feature) => {
    const newData = { ...feature, versionId };
    if (!newData.date) {
      newData.date = new Date();
    }
    post('/addFeature', { newData }).then((results) => {
      newData.visible = Number(newData.visible);
      const id = results.insertId;
      const features = [...data];
      features.unshift({ ...newData, id });
      setData(features);
    });
  };

  const editFeature = (newData, oldData) => {
    post('/editFeature', { newData }).then(() => {
      const features = [...data];
      const index = features.indexOf(oldData);
      features[index] = newData;
      features[index].visible = Number(features[index].visible);
      setData(features);
    });
  };

  const deleteFeature = (oldData) => {
    const { id } = oldData;
    post('/deleteWhatsNewFeature', { id }).then(() => {
      const features = [...data];
      const index = features.indexOf(oldData);
      features.splice(index, 1);
      setData(features);
    });
  };

  return (
    <Paper>
      <MaterialTable
        title="Whats New To MSAS"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: async (newData) => {
            await addFeature(newData);
          },
          onRowUpdate: async (newData, oldData) => {
            await editFeature(newData, oldData);
          },
          onRowDelete: async (oldData) => {
            await deleteFeature(oldData);
          },
        }}
        detailPanel={(row) => {
          return (
            <div style={{ border: '2px solid black' }}>
              <WhatsNewDetails get={get} post={post} featureId={row.id} />
            </div>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
      />
    </Paper>
  );
};

export default WhatsNew;
