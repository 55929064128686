import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { closeDialog } from '../../../../../../Stores/Dialog/Actions';
import { patch } from '../../../../../../Functions/API';
import { SalesStore } from '../../../../../../Stores';
import { createSnack } from '../../../../../../Stores/Snack/Actions';

const AssignCommissions = ({ salesmen, sales, setAssignedCommissionSales }) => {
  const [assignedCSales, setAssignedCSales] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    SalesStore.on('change', update);
    update();
    return () => {
      SalesStore.removeListener('change', update);
    };
  }, []);

  const update = () => {
    const s = [...SalesStore.getSales()];
    setAssignedCSales(s);
    setValue(s.find((aCS) => aCS.assignedCommission)?.userID);
  };

  const handleChange = (e) => {
    const temp = [...assignedCSales];
    temp.map((s) => (s.assignedCommission = 0));
    const index = assignedCSales.findIndex((s) => s.userID === e.target.value);
    if (index > -1) {
      temp[index].assignedCommission = 1;
      setValue(temp[index].userID);
      setAssignedCommissionSales(temp);
    }
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Selected Salesman</FormLabel>
        <RadioGroup value={value} onChange={handleChange}>
          {salesmen
            .filter((salesman) => sales.find((s) => s === salesman.uid))
            .map((s, key) => (
              <FormControlLabel key={key} value={s.uid} control={<Radio />} label={`${s.firstName} ${s.lastName}`} />
            ))}
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Button size={'small'} color={'secondary'} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          size={'small'}
          color={'primary'}
          onClick={() =>
            patch('/sales', assignedCSales).then(() => {
              createSnack('Success', 'Successfully assigned commissions');
              closeDialog();
            })
          }
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
};

export default AssignCommissions;

AssignCommissions.propTypes = {
  salesmen: PropTypes.array,
  sales: PropTypes.array,
  setAssignedCommissionSales: PropTypes.func,
};
