/* eslint react/display-name:0 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API';
import { CircularProgress, Button, Typography } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import FirebaseMessagingStore from '../../../FirebaseMessaging/Store';

const Shipments = () => {
  const { jobId } = useParams();
  const [shipments, setShipments] = useState([]);
  const [fShipments, setFShipments] = useState([]);

  const columns = [
    { title: 'Shipment Id', field: 'entityId' },
    { title: 'Fulfillment Order Id', field: 'body.order.id' },
    {
      title: 'Shipment Label',
      field: 'body.shipment.url',
      render: (rowData) =>
        rowData?.body?.shipment?.url ? (
          <Button target="_blank" href={rowData?.body?.shipment.url}>
            Label
          </Button>
        ) : (
          <>{!rowData?.finished && <CircularProgress />}</>
        ),
    },
    {
      title: 'Finished',
      field: 'finished',
      render: (rowData) => (rowData?.finished ? <CheckBox /> : <CheckBoxOutlineBlank />),
    },
    {
      title: 'Success',
      field: 'success',
      render: (rowData) => (rowData?.success ? <CheckBox /> : <CheckBoxOutlineBlank />),
    },
    {
      title: 'Error',
      field: 'error',
      render: (rowData) => rowData?.error?.message && <Typography>{rowData?.error?.message}</Typography>,
    },
  ];

  useEffect(() => {
    // FirebaseMessagingStore.on(`/fulfillment/shipments/${jobId}`, updateShipment);
    getJobShipments(jobId);
    const interval = setInterval(() => {
      getJobShipments(jobId);
    }, 5 * 1000);
    return () => {
      // FirebaseMessagingStore.removeListener(`/fulfillment/shipments/${jobId}`, updateShipment);
      clearInterval(interval);
    };
  }, []);
  //
  // useEffect(() => {
  //   FirebaseMessagingStore.on(`/fulfillment/shipments/${jobId}`, updateShipment);
  //
  //   return () => {
  //     FirebaseMessagingStore.removeListener(`/fulfillment/shipments/${jobId}`, updateShipment);
  //   };
  // }, [shipments]);
  //
  // const updateShipment = ({ job }) => {
  //   const jobJson = JSON.parse(job);
  //   const shipmentJobs = [...shipments];
  //   const index = shipmentJobs.findIndex((r) => r.id === jobJson.jobId);
  //   if (index !== -1) {
  //     shipmentJobs[index] = { ...shipmentJobs[index], ...jobJson };
  //     setShipments(shipmentJobs);
  //   }
  // };

  const getJobShipments = (jobId) => {
    if (jobId) {
      return get(`/jobs/${jobId}`).then((results) => {
        if (results.children) {
          setShipments(
            results.children.map((child) => ({
              ...child,
              error: child.error ? JSON.parse(child.error) : child.error,
              body: JSON.parse(child.body),
            })),
          );
        }
      });
    }
  };

  return (
    <div>
      <MaterialTable options={{ pageSize: 20, pageSizeOptions: [20] }} title="Fulfillment Shipments" columns={columns} data={shipments} />
    </div>
  );
};

Shipments.displayName = 'Shipments';

export default Shipments;
