/* eslint-disable react/display-name */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Typography } from '@material-ui/core';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';

const AllSizes = ({ workOrderID, filters }) => {
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: 'Size', field: 'size' },
    { title: 'Quantity', field: 'quantity' },
  ]);

  useEffect(() => {
    post('/allSizes', { workOrderID, filters })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  }, []);

  useEffect(() => {
    let newTitle = '';
    const keys = Object.keys(filters);
    for (const key of keys) {
      if (filters[key].length) {
        if (!newTitle.length) {
          newTitle != 'With ';
        } else {
          newTitle += 'and ';
        }
        newTitle += `${key.toUpperCase()}: ${filters[key]} `;
      }
    }
    setTitle(newTitle);
  }, [filters]);

  return (
    <div
      style={{
        position: 'absolute',
        outline: 'none',
        width: '90%',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        height: '80%',
        overflowX: 'hidden',
        padding: '10px',
      }}
    >
      <MaterialTable
        title="Sizes"
        columns={columns}
        data={data}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Button
                color={'primary'}
                onClick={() => {
                  get(`/workorder/${workOrderID}/orders/color-summary`).then((url) => {
                    window.open(url);
                  });
                }}
              >
                Download Color Summary
              </Button>
              <Typography style={{ margin: '10px' }}>{title}</Typography>
            </div>
          ),
        }}
        options={{ paging: false, search: false }}
      />
      <div style={{ textAlign: 'right', margin: '10px' }}>Total: {data.reduce((total, row) => total + row.quantity, 0)}</div>
    </div>
  );
};

export default AllSizes;
