import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Mermaid from 'react-mermaid2';
import { get } from '../../../Functions/API';
import { createDialog } from '../../../Stores/Dialog/Actions.js';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const columns = [
    { field: 'type.name', title: 'Job Type' },
    { field: 'children.length', title: 'Number of children', type: 'numeric' },
    { field: 'entityType', title: 'Entity Type' },
    { field: 'entityId', title: 'Entity Id', type: 'numeric' },
    { field: 'finished', title: 'Finished', type: 'numeric' },
    { field: 'success', title: 'Success', type: 'numeric' },
    { field: 'createdAt', title: 'Created At', type: 'datetime' },
  ];

  useEffect(() => {
    console.log(jobs);
  }, [jobs]);

  const start = (job) => {
    const string = startChart(job);
    return 'graph LR;\n' + string;
  };

  const startChart = (job) => {
    let string = job.parent ? `${job.parent.type.name} --> ${job.type.name}\n` : ``;
    if (job.children?.length) {
      job.children.map((child) => (string += `${startChart(child)}`));
    }
    if (job.finished && !job.success) {
      const red = '#e0193e';
      string += `style ${job.type.name} fill:${red}\n`;
    } else if (job.success) {
      const green = '#32933F';
      string += `style ${job.type.name} fill:${green}\n`;
    } else {
      const yellow = '#f1d550';
      string += `style ${job.type.name} fill:${yellow}\n`;
    }
    return string;
  };

  const gatherErrors = (errors, job) => {
    if (job.error) errors.push({ id: job.id, job: job.type.name, body: job.body, error: JSON.parse(job.error) });
    if (job.children) job.children.map((child) => gatherErrors(errors, child));
    return errors;
  };

  useEffect(() => {
    get('/jobs').then(setJobs);
  }, []);
  return (
    <>
      <MaterialTable
        title="Jobs"
        columns={columns}
        data={jobs}
        onRowClick={async (event, rowData) => {
          const job = await get(`/jobs/${rowData.id}/chain`);
          const errors = gatherErrors([], job);
          console.log(errors);
          createDialog({
            title: 'Chain of Events',
            content: (
              <div>
                <Mermaid chart={start(job)} />
                {Boolean(errors.length) && (
                  <MaterialTable
                    columns={[
                      { title: 'Job', field: 'job' },
                      { title: 'Body', field: 'body' },
                      { title: 'Error', field: 'error' },
                    ]}
                    data={errors}
                    options={{ padding: 'dense', toolbar: false, paging: false, showTitle: false }}
                  />
                )}
              </div>
            ),
            size: 'xl',
          });
        }}
      />
    </>
  );
};

Jobs.displayName = 'Jobs';

export default Jobs;
