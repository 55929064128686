import React from 'react';
import Status from './Status';
import { Grid } from '@material-ui/core';

const Statuses = () => {
  const statuses = [
    {
      id: 54,
      name: 'Order',
      type: 'lineItems',
      storeDest: 'ordering?',
      viewAllDest: 'styles/vendorStoreSelect',
    },
    {
      id: 56,
      name: 'Print Costs',
      type: 'lineItems',
      storeDest: 'line-items?statusId=56&type=printCosts',
    },
    {
      id: 57,
      name: 'Pull Costs',
      type: 'lineItems',
      storeDest: 'line-items?statusId=57&type=pullCosts',
    },
    {
      id: 50,
      name: 'Pull',
      type: 'lineItems',
      storeDest: 'pull',
    },
    {
      id: 51,
      name: 'Count',
      type: 'lineItems',
      storeDest: 'art/count',
      viewAllDest: 'art/count',
    },
    {
      id: 52,
      name: 'Print',
      type: 'lineItems',
      storeDest: 'art/print',
      viewAllDest: 'art/print',
    },
    {
      id: 4,
      name: 'Ship',
      type: 'orders',
      statuses: [55],
      storeDest: 'orders?status=55',
      viewAllDest: 'orders?status=55',
    },
    {
      id: 53,
      name: 'Bill',
      storeDest: 'line-items?statusId=53&type=b',
      viewAllDest: 'line-items?statusId=53&type=b',
    },
  ];
  return (
    <Grid style={{ marginTop: '15px', padding: '15px' }} container spacing={1} justify={'center'}>
      {statuses.map((status, key) => (
        <Grid item key={key} xs={12} md={4} lg={3}>
          <Status status={status} statusFilter={1} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Statuses;
