/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardActions, CardActionArea } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 345,
  },
  media: {
    height: 200,
  },
}));

const getImageUrl = (name) => {
  switch (name) {
    case 'Beachbody, LLC':
      return 'https://www.beachbodygear.com/wp-content/uploads/2017/11/beachbody-expands-to-apparel-and-footwear.jpg';
    case 'Medicine Warrior':
      return 'https://cdn.shopify.com/s/files/1/0274/2973/2375/files/Medicine_Warrior_Logo_232a2f51-0559-40c1-a0a3-dc1f9efca0ef.png?v=1591467875';
    case 'Boom Media':
      return 'https://yt3.ggpht.com/ytc/AAUvwnjx6B5_8_3qAzk_a5QiyUMTRhIylsfqrNK6y6tt=s900-c-k-c0x00ffffff-no-rj';
    case 'Edify Collective':
      return 'https://cdn.shopify.com/s/files/1/0408/5258/6664/files/logo_black_transparent_4267x.png?v=1591813146';
    default:
      return 'https://www.nuveen.com/institutional/global/-/media/nuveen/default/no-image-selected.ashx';
  }
};

const Customer = ({ customer }) => {
  const classes = useStyles();

  const handleStoreClick = () => {
    updateFilters({ customer });
  };

  const handleOrdersClick = () => {
    updateFilters({ customer });
  };

  return (
    <Grid item xs={3}>
      <center>
        <Card className={classes.card}>
          {/* <CardActionArea> */}
          <CardMedia className={classes.media} image={getImageUrl(customer.name)} title="Image" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {customer.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Some description here
            </Typography>
          </CardContent>
          {/* </CardActionArea> */}
          <CardActions>
            <Button size="small" color="primary" onClick={handleStoreClick} component={Link} to={`/fulfillment/stores?customerId=${customer.id}`}>
              Stores
            </Button>
            <Button size="small" color="secondary" onClick={handleOrdersClick} component={Link} to={`/fulfillment/orders?customerId=${customer.id}`}>
              Orders
            </Button>
          </CardActions>
        </Card>
      </center>
    </Grid>
  );
};

export default Customer;
