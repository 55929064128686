/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import AssignedWorkOrders from './AssignedWorkOrders';

const PrinterHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container direction="column" spacing={1} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          <Grid item xs>
            <AssignedWorkOrders {...props} refresh={props.refresh} />
          </Grid>
          <Grid item xs>
            <LatestWorkOrders {...props} refresh={props.refresh} />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default PrinterHome;
