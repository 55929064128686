/* eslint no-param-reassign:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-alert:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-unused-vars:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint consistent-return:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import { Modal, Paper } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '@material-ui/core/Button';
import ReplacementModal from './ReplacementModal';
import VendorReplacementModal from './VendorReplacementModal';
import { openQuickBooksPopUp } from '../QuickBooks/functions';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfLines: [],
      submitted: false,
      customers: [],
      vendors: [],
      lockedCustomers: [],
      customerColumns: [
        {
          title: 'Customer',
          field: 'name',
          editable: 'always',
        },
        {
          title: 'Organization',
          field: 'organization',
          editable: 'always',
        },
        {
          title: 'CO',
          field: 'co',
          editable: 'always',
        },
        {
          title: 'Email',
          field: 'email',
          editable: 'always',
        },
        {
          title: 'Billing Street Address',
          field: 'billingStreet',
          editable: 'always',
        },
        {
          title: 'Billing City',
          field: 'billingCity',
          editable: 'always',
        },
        {
          title: 'Billing State',
          field: 'billingState',
          editable: 'always',
        },
        {
          title: 'Billing Zip Code',
          field: 'billingZip',
          editable: 'always',
        },
        {
          title: 'Shipping Street Address',
          field: 'shippingStreet',
          editable: 'always',
        },
        {
          title: 'Shipping City',
          field: 'shippingCity',
          editable: 'always',
        },
        {
          title: 'Shipping State',
          field: 'shippingState',
          editable: 'always',
        },
        {
          title: 'Shipping Zip Code',
          field: 'shippingZip',
          editable: 'always',
        },
        {
          title: 'Work Orders',
          field: 'workorderCount',
          editable: 'never',
        },
        // {
        //     title: 'Taxable'
        // }
      ],
      vendorColumns: [
        {
          title: 'Vendor',
          field: 'name',
          editable: 'always',
        },
        {
          title: 'Email',
          field: 'email',
          editable: 'always',
        },
        {
          title: 'Street Address',
          field: 'street',
          editable: 'always',
        },
        {
          title: 'City',
          field: 'city',
          editable: 'always',
        },
        {
          title: 'State',
          field: 'state',
          editable: 'always',
        },
        {
          title: 'Zip Code',
          field: 'zip',
          editable: 'always',
        },
        {
          title: 'Bill Term',
          field: 'qbTerm',
          editable: 'always',
          render: (rowData) => {
            if (rowData.qbTerm) {
              switch (rowData.qbTerm) {
                case 1:
                  return <span>Due on Receipt</span>;
                case 2:
                  return <span>Net 15</span>;
                case 3:
                  return <span>Net 30</span>;
                case 4:
                  return <span>Net 60</span>;
                case 5:
                  return <span>Net 10</span>;
                default:
              }
            } else {
              return <span>No Term Set</span>;
            }
          },
        },
      ],
      lockedColumns: [
        {
          title: 'Customer',
          field: 'name',
          editable: 'always',
        },
        {
          title: 'Email',
          field: 'email',
          editable: 'always',
        },
        {
          title: 'Street Address',
          field: 'billingStreet',
          editable: 'always',
        },
        {
          title: 'City',
          field: 'billingCity',
          editable: 'always',
        },
        {
          title: 'State',
          field: 'billingState',
          editable: 'always',
        },
        {
          title: 'Zip Code',
          field: 'billingZip',
          editable: 'always',
        },
        {
          title: 'Taxable',
        },
        {
          title: 'Open Balance',
          field: 'balance',
          type: 'currency',
          editable: 'never',
          render: (rowData) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            });
            return <span style={{ color: 'red' }}>{formatter.format(rowData.balance)}</span>;
          },
        },
      ],
      customersWithQuickBooksIds: [],
      replacementCustomer: {},
      newCustomer: {},
      showReplacementModal: false,
      vendorsWithQuickBooksIds: [],
      replacementVendor: {},
      newVendor: {},
      showVendorReplacementModal: false,
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState(results);
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  }

  setup = async () => {
    let customers;
    let vendors;
    let lockedCustomers;
    let error = '';
    await this.props
      .get('/getNewCustomers', [])
      .then((results) => {
        customers = results.customers;
      })
      .catch((err) => {
        console.log(err);
        error += 'Could not get new customers ';
      });
    await this.props
      .get('/getNewVendors', [])
      .then((results) => {
        vendors = results.vendors;
      })
      .catch((err) => {
        console.log(err);
        error += 'Could not get new vendors ';
      });
    await this.props
      .get('/getLockedCustomers', [])
      .then((results) => {
        lockedCustomers = results.lockedCustomers;
      })
      .catch((err) => {
        console.log(err);
        error += 'Could not get locked customers';
      });
    if (Array.isArray(customers) && Array.isArray(vendors) && Array.isArray(lockedCustomers)) {
      return {
        customers,
        vendors,
        lockedCustomers,
      };
    }
    console.log(customers, vendors, lockedCustomers);
    throw new Error(error);
  };

  getNewCustomers = () => {
    this.props
      .get('/getNewCustomers', [])
      .then((results) => {
        const { customers } = results;
        this.setState({
          customers,
        });
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  getNewVendors = () => {
    this.props
      .get('/getNewVendors', [])
      .then((results) => {
        const { vendors } = results;
        this.setState({
          vendors,
        });
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  getLockedCustomers = () => {
    this.props
      .get('/getLockedCustomers', [])
      .then((results) => {
        const { lockedCustomers } = results;
        this.setState({
          lockedCustomers,
        });
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  addQBCustomer = (customer) => {
    this.props
      .get('/qbCreateCustomer', [{ name: 'customerID', value: customer }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          this.props.createSnack('Success', 'Customer Created in Quickbooks.');
          this.setState({
            activeInvoice: true,
          });
          this.getNewCustomers();
        } else {
          this.props.createSnack('Error', 'Customer unable to be created.');
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  addQBVendor = (vendorID) => {
    this.props
      .get('/qbCreateVendor', [{ name: 'vendorID', value: vendorID }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          this.props.createSnack('Success', 'Vendor Created in Quickbooks.');
          this.setState({
            activeInvoice: true,
          });
          this.getNewVendors();
        } else {
          this.props.createSnack('Error', 'Vendor unable to be created.');
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  unlockCustomer = (customer) => {
    this.props
      .get('/unlockCustomer', [{ name: 'customerId', value: customer }])
      .then((results) => {
        this.getLockedCustomers();
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  getInactiveVendors = () => {
    this.props
      .get('/inactiveVendors', [])
      .then((vendors) => {
        let message = 'This will remove the following vendors:\n\n';
        for (const row of vendors) {
          message += row.DisplayName;
          message += '\n';
        }
        const c = window.confirm(message);
        if (c) {
          this.props
            .post('/deleteInactiveVendors', {})
            .then((results) => {
              this.props.createSnack('Success', results.join('\n'));
            })
            .catch((err) => {
              this.props.createSnack('Error', err.message);
            });
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  getInactiveCustomers = () => {
    this.props
      .get('/inactiveCustomers', [])
      .then((customers) => {
        console.log(customers);
        let message = 'This will remove the following Customers:\n\n';
        for (const row of customers) {
          message += row.DisplayName;
          message += '\n';
        }
        if (!customers.length) {
          this.props.createSnack('Success', 'All inactive customers have already been removed from MSAS');
        } else {
          const c = window.confirm(message);
          if (c) {
            this.props
              .post('/deleteInactiveCustomers', {})
              .then((results) => {
                console.log(results);
                this.props.createSnack('Success', 'View console for details on the deletion.');
              })
              .catch((err) => {
                this.props.createSnack('Error', err.message);
              });
          }
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  getQuickBooksCustomers = async (oldData) => {
    await this.props.get('/getCustomersWithQuickbooksIds', []).then((customersWithQuickBooksIds) => {
      this.setState({
        replacementCustomer: oldData,
        showReplacementModal: true,
        customersWithQuickBooksIds,
      });
    });
  };

  toggleReplacementModal = () => {
    this.setState({
      showReplacementModal: !this.state.showReplacementModal,
    });
  };

  updateReplacementCustomer = (event, value) => {
    this.setState({
      newCustomer: value,
    });
  };

  updateWorkOrdersWithNewCustomer = () => {
    const { replacementCustomer, newCustomer } = this.state;
    this.props
      .post('/updateWorkOrdersWithNewCustomer', { replacementCustomer, newCustomer })
      .then((results) => {
        const { customers } = results;
        this.setState(
          {
            customers,
            newCustomer: {},
            replacementCustomer: {},
          },
          () => {
            this.toggleReplacementModal();
            window.alert(`Changed ${results.results.affectedRows} work orders.`);
          },
        );
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  getQuickBooksVendors = async (oldData) => {
    await this.props.get('/getVendorsWithQuickbooksIds', []).then((vendorsWithQuickBooksIds) => {
      this.setState({
        replacementVendor: oldData,
        showVendorReplacementModal: true,
        vendorsWithQuickBooksIds,
      });
      console.log(vendorsWithQuickBooksIds);
    });
  };

  toggleVendorReplacementModal = () => {
    this.setState({
      showVendorReplacementModal: !this.state.showVendorReplacementModal,
    });
  };

  updateReplacementVendor = (event, value) => {
    this.setState({
      newVendor: value,
    });
  };

  // filter this out
  updateOrdersWithNewVendor = () => {
    const { replacementVendor, newVendor } = this.state;
    this.props
      .post('/updateOrdersWithNewVendor', { replacementVendor, newVendor })
      .then((results) => {
        const { vendors } = results;
        this.setState(
          {
            vendors,
            newVendor: {},
            replacementVendor: {},
          },
          () => {
            this.toggleVendorReplacementModal();
            window.alert(`Changed ${results.results.affectedRows} orders.`);
          },
        );
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  render() {
    const customerStyle = {
      // No styles currently added
    };

    const vendorStyle = {
      // No styles currently added
    };

    const lockedStyle = {
      // No styles currently added
    };

    return (
      <div>
        <Modal open={this.state.showReplacementModal} style={{ top: '25%', left: '10%' }} onClose={this.toggleReplacementModal}>
          <ReplacementModal
            replacementCustomer={this.state.replacementCustomer}
            updateReplacementCustomer={this.updateReplacementCustomer}
            updateWorkOrdersWithNewCustomer={this.updateWorkOrdersWithNewCustomer}
            customersWithQuickBooksIds={this.state.customersWithQuickBooksIds}
          />
        </Modal>
        <Modal open={this.state.showVendorReplacementModal} style={{ top: '25%', left: '10%' }} onClose={this.toggleVendorReplacementModal}>
          <VendorReplacementModal
            replacementVendor={this.state.replacementVendor}
            updateReplacementVendor={this.updateReplacementVendor}
            updateOrdersWithNewVendor={this.updateOrdersWithNewVendor}
            vendorsWithQuickBooksIds={this.state.vendorsWithQuickBooksIds}
          />
        </Modal>
        <div>
          <div style={customerStyle}>
            <Paper>
              <MaterialTable
                title="New Customers"
                columns={this.state.customerColumns}
                data={this.state.customers}
                options={{
                  pageSizeOptions: [10, 15, 25, 50],
                  pageSize: 5,
                }}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                      <Button style={{ margin: '8px' }} variant="contained" onClick={this.getInactiveCustomers}>
                        Delete Inactive Customers
                      </Button>
                    </div>
                  ),
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add to Quickbooks',
                    onClick: (event, rowData) => {
                      this.addQBCustomer(rowData.id);
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: async (newData, oldData) => {
                    await this.props
                      .post('/updateNewCustomer', newData)
                      .then(() => {
                        this.getNewCustomers();
                      })
                      .catch((err) => {
                        this.props.createSnack('Error', err.message);
                      });

                    // console.log(newData);
                    // await fetch(`${this.props.homepage}/updateNewCustomer`, {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify(newData)
                    // })
                    //     .then(async response => {
                    //         // console.log(response);
                    //         let customers = this.getNewCustomers();
                    //         // console.log(customers);
                    //         this.setState({
                    //             customers
                    //         })
                    //     })
                    //     .catch(err => {
                    //         console.log(err);
                    //     })
                  },
                  onRowDelete: async (oldData) => {
                    const c = window.confirm('Would you like to replace this with a quickbooks customer?');
                    if (c) {
                      await this.getQuickBooksCustomers(oldData);
                    } else {
                      await this.props
                        .post('/deleteNewCustomer', oldData)
                        .then(() => {
                          this.getNewCustomers();
                        })
                        .catch((err) => {
                          this.props.createSnack('Error', err.message);
                        });
                      // await fetch(`${this.props.homepage}/deleteNewCustomer`, {
                      //     method: 'POST',
                      //     headers: {
                      //         'Content-Type': 'application/json',
                      //     },
                      //     body: JSON.stringify(oldData)
                      // })
                      //     .then(async response => {
                      //         // console.log(response);
                      //         let customers = this.getNewCustomers();
                      //         // console.log(customers);
                      //         this.setState({
                      //             customers
                      //         })
                      //     })
                      //     .catch(err => {
                      //         console.log(err);
                      //     })
                    }
                  },
                }}
              />
            </Paper>
          </div>
          <div style={vendorStyle}>
            <Paper>
              <MaterialTable
                title="New Vendors"
                columns={this.state.vendorColumns}
                data={this.state.vendors}
                options={{
                  pageSizeOptions: [10, 15, 25, 50],
                  pageSize: 5,
                }}
                components={{
                  Toolbar: (props) => (
                    <div>
                      <MTableToolbar {...props} />
                      <Button style={{ margin: '8px' }} variant="contained" onClick={this.getInactiveVendors}>
                        Delete Inactive Vendors
                      </Button>
                    </div>
                  ),
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add to Quickbooks',
                    onClick: async (event, rowData) => {
                      await this.addQBVendor(rowData.id);
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: async (newData, oldData) => {
                    // console.log(newData);
                    switch (newData.qbTerm) {
                      case 'Due on Receipt':
                        newData.qbTerm = 1;
                        break;
                      case 'Net 15':
                        newData.qbTerm = 2;
                        break;
                      case 'Net 30':
                        newData.qbTerm = 3;
                        break;
                      case 'Net 60':
                        newData.qbTerm = 4;
                        break;
                      case 'Net 10':
                        newData.qbTerm = 5;
                        break;
                      default:
                    }
                    await this.props
                      .post('/updateNewVendor', newData)
                      .then(() => {
                        this.getNewVendors();
                      })
                      .catch((err) => {
                        this.props.createSnack('Error', err.message);
                      });
                    // await fetch(`${this.props.homepage}/updateNewVendor`, {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify(newData)
                    // })
                    //     .then(async response => {
                    //         // console.log(response);
                    //         let vendors = this.getNewVendors();
                    //         // console.log(vendors);
                    //         this.setState({
                    //             vendors
                    //         })
                    //     })
                    //     .catch(err => {
                    //         console.log(err);
                    //     })
                  },
                  onRowDelete: async (oldData) => {
                    const c = window.confirm('Would you like to replace this with a quickbooks vendor?');
                    if (c) {
                      await this.getQuickBooksVendors(oldData);
                    } else {
                      await this.props
                        .post('/deleteNewVendor', oldData)
                        .then(() => {
                          this.getNewVendors();
                        })
                        .catch((err) => {
                          this.props.createSnack('Error', err.message);
                        });
                    }
                  },
                }}
              />
            </Paper>
          </div>
        </div>
        <div>
          <div style={lockedStyle}>
            <Paper>
              <MaterialTable
                title="Locked Customers"
                columns={this.state.lockedColumns}
                data={this.state.lockedCustomers}
                options={{
                  pageSizeOptions: [10, 15, 25, 50],
                  pageSize: 5,
                }}
                actions={[
                  {
                    icon: 'lock_open',
                    tooltip: 'Unlock Customer',
                    onClick: (event, rowData) => {
                      this.unlockCustomer(rowData.id);
                      window.alert('This customer has been unlocked for one new work order.');
                    },
                  },
                ]}
              />
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
