/* eslint no-alert:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint no-console:0 */
/* eslint react/no-array-index-key:0 */
/* eslint consistent-return:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint radix:0 */
/* eslint no-nested-ternary:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Quote from './quote';
import { get, post } from '../../Functions/API';
import { getUsersByGroup } from '../../Stores/Users/Actions';

export default class Quotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      customers: [],
      salesmen: [],
      ready: true,
      columns: [
        { title: 'Name', field: 'name' },
        {
          title: 'Customer',
          field: 'customerId',
          render: (rowData) => {
            const customer = this.state.customers.find((row) => row.id === rowData.customerId);
            if (customer) {
              return customer.name;
            }
            return '';
          },
          editComponent: (t) => (
            <Autocomplete
              options={this.state.customers}
              disableClearable
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => value === option.id}
              inputValue={
                this.state.customers.find((row) => row.id === t.value)
                  ? this.state.customers.find((row) => row.id === t.value).name
                  : parseInt(t.value)
                  ? t.value.toString()
                  : t.value
              }
              onChange={(e, value) => t.onChange(value.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="customer"
                  label="Customer"
                  style={{ margin: '10px', width: '50%' }}
                  onChange={(e) => t.onChange(e.target.value)}
                  margin="normal"
                  component="div"
                  variant="outlined"
                />
              )}
            />
          ),
        },
        {
          title: 'Salesman',
          field: 'salesId',
          render: (rowData) => {
            const salesman = this.state.salesmen.find((row) => row.uid === rowData.salesId);
            if (salesman) {
              return `${salesman.firstName} ${salesman.lastName}`;
            }
          },
          editComponent: (t) => (
            <Select value={t.value} onChange={(e) => t.onChange(e.target.value)}>
              {this.state.salesmen.map((salesman, key) => (
                <MenuItem key={key} value={salesman.uid}>
                  {`${salesman.firstName} ${salesman.lastName}`}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      console.log(results);
      this.setState(results);
    });
  }

  setup = async () => {
    let quotes;
    let customers;
    await this.props
      .get('/quotes')
      .then((results) => {
        quotes = results;
      })
      .catch((err) => {
        console.error(err.message);
      });
    await this.props
      .get('/getCustomers')
      .then((results) => {
        customers = results.customers;
      })
      .catch((err) => {
        console.error(err.message);
      });
    const salesmen = await getUsersByGroup('Sales');
    return {
      quotes,
      customers,
      salesmen,
      ready: false,
    };
  };

  render() {
    const { columns, quotes } = this.state;
    return (
      <div>
        <Switch>
          <Route exact path="/quotes/:quoteId" component={(props) => <Quote {...props} get={get} post={post} />} />
          <Route exact path="/quotes">
            <MaterialTable
              title="Quotes"
              isLoading={this.state.ready}
              columns={columns}
              data={quotes}
              options={{
                pageSize: 20,
              }}
              editable={{
                onRowAdd: async (newData) => {
                  this.props
                    .post('/newQuote', newData)
                    .then((quotes) => {
                      this.setState({
                        quotes,
                      });
                    })
                    .catch((err) => {
                      console.error(err.message);
                    });
                },
                onRowUpdate: async (newData, oldData) => {
                  this.props
                    .post('/updateQuote', newData)
                    .then((quotes) => {
                      this.setState({
                        quotes,
                      });
                    })
                    .catch((err) => {
                      console.error(err.message);
                    });
                },
                onRowDelete: async (data) => {
                  window.alert('Deleting quotes... coming to a website near you.');
                },
              }}
              onRowClick={(event, rowData) => {
                window.open(`/quotes/${rowData.id}`, '_blank');
              }}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}
