import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { fetchStates } from '../../../Stores/States/Actions';
import PropTypes from 'prop-types';

function StatesSearch({ getValues, control, index, state }) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    fetchStates().then(setStates);
  }, []);

  return (
    <Controller
      name={`contacts[${index}].state`}
      control={control}
      defaultValue={getValues()['contacts'][index]?.state ?? state}
      onChange={([, data]) => data}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        // <TextField fullWidth label="City" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
        <Autocomplete
          options={states}
          value={value}
          onChange={(e, data) => onChange(data)}
          getOptionLabel={(option) => (option?.name ? option.name : '')}
          renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} label="State" />}
        />
      )}
    />
  );
}

StatesSearch.propTypes = {
  getValues: PropTypes.any,
  control: PropTypes.any,
  index: PropTypes.any,
  state: PropTypes.any,
};

export default StatesSearch;
