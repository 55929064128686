/* eslint react/no-array-index-key:0 */
/* eslint no-shadow:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-alert:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { ButtonGroup, Button, InputLabel, FormLabel, Popover, List, ListItem, ListItemText, LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NumberTable from './NumberTable';
import { get, post } from '../../../../../../Functions/API';
import { UserStore } from '../../../../../../Stores';
import { createSnack } from '../../../../../../Stores/Snack/Actions';
import WorkOrderStore from '../../../../../../Stores/WorkOrder/Store';

export default class NumberSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 'all',
      value: 0,
      options: [],
      sizes: [],
      numbers: [],
      numberColumns: [],
      user: UserStore.getUser(),
      downloadAnchorEl: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.getNumbers();
  }

  updateActiveButton = (activeButton) => {
    this.setState(
      {
        activeButton,
      },
      this.getOptions,
    );
  };

  updateValue = (value) => {
    this.setState(
      {
        value,
      },
      this.getNumbers,
    );
  };

  getOptions = () => {
    if (this.state.activeButton === 'all') {
      this.setState(
        {
          options: [],
        },
        this.getNumbers,
      );
    } else {
      get(
        '/numberSummaryOptions',
        {
          option: this.state.activeButton,
          workOrderID: this.props.workOrder.id,
        },
        'obj',
      )
        .then((results) => {
          this.setState({
            numbers: [],
            numberColumns: [],
            options: results,
          });
        })
        .catch((err) => {
          window.alert(err.message);
        });
    }
  };

  getNumbers = async () => {
    const possibleSizes = await get('/allPotentialSizes', [{ name: 'type', value: 'type' }]).catch((err) => {
      console.log('Error', err.message);
    });
    get(
      '/sizesAndNumbers',
      {
        option: this.state.activeButton,
        value: this.state.value,
        workOrderID: this.props.workOrder.id,
      },
      'obj',
    )
      .then((numbers) => {
        const numberColumns = [{ title: 'Number', field: 'number' }];
        const map = new Map();
        for (const item of numbers) {
          for (const size of item.sizes) {
            if (!map.has(size.size)) {
              map.set(size.size, true);
              numberColumns.push({ title: size.size.toUpperCase(), field: size.size });
            }
          }
        }
        numberColumns.sort((a, b) => {
          const potentialSizeA = possibleSizes.find((row) => row.size === a.field);
          const indexA = possibleSizes.indexOf(potentialSizeA);
          const potentialSizeB = possibleSizes.find((row) => row.size === b.field);
          const indexB = possibleSizes.indexOf(potentialSizeB);
          return indexA - indexB;
        });
        this.setState({
          numbers,
          numberColumns,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  generateNumbers = () => {
    post('/createNumbers', { workOrderID: this.props.workOrder.id }).then(() => {
      this.getNumbers();
      WorkOrderStore.updateWorkOrder('generatedNumbers', new Date());
    });
  };

  openDownloadList = (e) => {
    this.setState({
      downloadAnchorEl: e.currentTarget,
    });
  };

  closeDownloadList = () => {
    this.setState({
      downloadAnchorEl: null,
    });
  };

  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  downloadNumberSummary = (type) => {
    let url;
    switch (type) {
      case 'number': {
        url = `/workorder/${this.props.workOrder.id}/numbers`;
        break;
      }
      case 'color': {
        url = `/workorder/${this.props.workOrder.id}/numbers/color-summary`;
        break;
      }
      case 'team': {
        url = `/workorder/${this.props.workOrder.id}/numbers/team-summary`;
        break;
      }
      default: {
        return createSnack('Error', 'Could not download number summary');
      }
    }
    this.closeDownloadList();
    this.setLoading(true);
    get(url).then((results) => {
      this.setLoading(false);
      window.open(results);
    });
  };

  render() {
    const { activeButton, options, value, numbers, numberColumns, downloadAnchorEl } = this.state;
    const downloadListOpen = Boolean(downloadAnchorEl);
    return (
      <div>
        {this.state.loading && <LinearProgress />}
        <Grid container spacing={1}>
          <Grid item style={{ flexGrow: 1 }}>
            <ButtonGroup style={{ margin: '10px' }} size="small" color="primary">
              <Button variant={activeButton === 'all' ? 'contained' : 'outlined'} onClick={() => this.updateActiveButton('all')}>
                All
              </Button>
              <Button variant={activeButton === 'color' ? 'contained' : 'outlined'} onClick={() => this.updateActiveButton('color')}>
                Color
              </Button>
              <Button variant={activeButton === 'team' ? 'contained' : 'outlined'} onClick={() => this.updateActiveButton('team')}>
                Team
              </Button>
              <Button variant={activeButton === 'colorAndTeam' ? 'contained' : 'outlined'} onClick={() => this.updateActiveButton('colorAndTeam')}>
                Color & Team
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <Button size={'small'} variant={'contained'} color={'secondary'} onClick={this.openDownloadList}>
              Download
            </Button>
            <Popover
              open={downloadListOpen}
              anchorEl={downloadAnchorEl}
              onClose={this.closeDownloadList}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List dense>
                <ListItem button onClick={() => this.downloadNumberSummary('number')}>
                  <ListItemText primary={'Number Summary'} />
                </ListItem>
                <ListItem button onClick={() => this.downloadNumberSummary('color')}>
                  <ListItemText primary={'Color Summary'} />
                </ListItem>
                <ListItem button onClick={() => this.downloadNumberSummary('team')}>
                  <ListItemText primary={'Team Summary'} />
                </ListItem>
              </List>
            </Popover>
          </Grid>
          {/* TODO generateNumbersNumberSummaryPage */}
          {(this.state.user.group === 'Order Processor' ||
            this.state.user.group === 'Admin' ||
            this.state.user.group === 'Owner' ||
            this.state.user.group.includes('Sales')) && (
            <Grid item>
              <Button size={'small'} onClick={this.generateNumbers} variant="contained">
                Generate Numbers
              </Button>
            </Grid>
          )}
        </Grid>
        <div>
          <ButtonGroup style={{ margin: '10px' }} size="small">
            {this.state.options.map((option, key) => (
              <Button
                onClick={() => this.updateValue(Object.keys(option).reduce((total, key) => `${total}${option[key]},`, ''))}
                variant={value === Object.keys(option).reduce((total, key) => `${total}${option[key]},`, '') ? 'contained' : 'outlined'}
                key={key}
              >
                {Object.keys(option).reduce((total, key) => `${total}${option[key].toUpperCase()} `, '')}
              </Button>
            ))}
          </ButtonGroup>
          {Boolean(numbers.length) && (
            <NumberTable
              numbers={numbers}
              columns={numberColumns}
              name={`${this.props.workOrder.workOrderID}-${this.props.workOrder.customer}-${activeButton.toUpperCase()}${
                activeButton === 'all' ? '' : ` ${value}`
              } Number Summary`}
            />
          )}
        </div>
      </div>
    );
  }
}
