import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Toolbar = ({ name, from, to, createdAt }) => {
  return (
    <Grid container spacing={1} style={{ margin: '10px' }}>
      <Grid item>
        <TextField label={'Name'} value={name} />
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={from} label={'From'} />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={to} label={'To'} />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={createdAt} label={'Created At'} />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  name: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  createdAt: PropTypes.string,
};

Toolbar.displayName = 'QBReportWO Toolbar';

export default Toolbar;
