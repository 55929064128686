import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import WorkOrderStore from '../../../../../../Stores/WorkOrder/Store';
import { createDialog } from '../../../../../../Stores/Dialog/Actions';
import WorkOrderContactsDialog from '../../../../../Shared/WorkOrder/WorkOrderContactsDialog';
import ContactStepper from './Stepper';

const Contacts = ({ viewBillingAddress }) => {
  const [workOrder, setWorkOrder] = useState({});
  const [shippingContacts, setShippingContacts] = useState([]);
  const [billingContacts, setBillingContacts] = useState([]);

  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);

  const getWorkOrder = () => {
    const localWorkOrder = WorkOrderStore.getWorkOrder();
    setShippingContacts(localWorkOrder?.contacts?.filter((contact) => contact.type === 'shipping'));
    setBillingContacts(localWorkOrder?.contacts?.filter((contact) => contact.type === 'billing'));
    setWorkOrder({ ...localWorkOrder });
  };

  return (
    <Grid spacing={2} container>
      <Grid xs={12} lg={viewBillingAddress ? 6 : 12} item>
        <ContactStepper workOrder={workOrder} contacts={shippingContacts} type="shipping" />
      </Grid>
      {Boolean(viewBillingAddress) && (
        <Grid xs={12} lg={6} item>
          <ContactStepper workOrder={workOrder} contacts={billingContacts} type="billing" />
        </Grid>
      )}
    </Grid>
  );
};

Contacts.propTypes = {
  viewBillingAddress: PropTypes.bool,
};

Contacts.displayName = 'Contacts';

export default Contacts;
