import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.vendors = [];
  }

  getVendors() {
    return this.vendors;
  }

  getVendorById = (id) => {
    if (this.vendors.length) {
      return this.vendors.find((v) => v.id === id);
    } else {
      return null;
    }
  };

  getVendor = (name) => {
    if (this.vendors.length) {
      return this.vendors.find((v) => v.name === name);
    } else {
      return null;
    }
  };

  newVendor = (vendor) => {
    this.vendors.push(vendor);
    this.emit('change');
  };

  loadVendors = (vendors) => {
    this.vendors = vendors;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_VENDORS': {
        this.loadVendors(action.vendors);
        break;
      }
      case 'NEW_VENDOR': {
        this.newVendor(action.vendors);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
