/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-plusplus:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-console:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import React, { Component } from 'react';
import { FormControl, FormGroup, Grid } from '@material-ui/core';
import StatusFilterItem from './StatusFilterItem';

export default class StatusFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };
  }

  componentDidMount() {
    const { uid } = this.props.user;
    this.props
      .get('/getDashboardFilters', [{ name: 'uid', value: uid }])
      .then((filters) => {
        this.setState({
          filters,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setFilterDetails = (filter, details) => {
    const { filters } = this.state;
    const index = filters.indexOf(filter);
    filters[index] = { ...filter, ...details };
    this.setState({
      filters,
    });
  };

  match = (filter) => {
    const tempFilter = JSON.stringify(this.props.completedFilters);
    const tempFilter2 = JSON.stringify(this.props.uncompletedFilters);
    const userCompletedFilter = JSON.parse(tempFilter);
    const userUncompletedFilter = JSON.parse(tempFilter2);
    let completedFilterNum = filter.completedFilters.length;
    let uncompletedFilterNum = filter.uncompletedFilters.length;
    if (completedFilterNum === userCompletedFilter.length && uncompletedFilterNum === userUncompletedFilter.length) {
      for (const dbFilter of filter.completedFilters) {
        const found = userCompletedFilter.find((row) => row === dbFilter.filterValue);
        if (found) {
          completedFilterNum--;
          userCompletedFilter.splice(userCompletedFilter.indexOf(found), 1);
        }
      }
      for (const dbFilter of filter.uncompletedFilters) {
        const found = userUncompletedFilter.find((row) => row === dbFilter.filterValue);
        if (found) {
          uncompletedFilterNum--;
          userUncompletedFilter.splice(userCompletedFilter.indexOf(found), 1);
        }
      }
      return !completedFilterNum.length && !uncompletedFilterNum.length && !userCompletedFilter.length && !userUncompletedFilter.length;
    }
    return false;
  };

  render() {
    const {
      state: { filters },
      props: { get, loadDashBoardFilter },
    } = this;
    return (
      <div className="status-filter-container">
        <FormControl component="fieldset">
          <FormGroup>
            {filters
              .slice(0)
              .reverse()
              .map((filter, key) => {
                return (
                  <Grid
                    container
                    key={key}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <StatusFilterItem
                      match={this.match(filter)}
                      get={get}
                      loadDashBoardFilter={loadDashBoardFilter}
                      keyProp={key}
                      key={key}
                      filter={filter}
                      {...filter}
                      setFilterDetails={this.setFilterDetails}
                    />
                  </Grid>
                );
              })}
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}
