/* eslint no-nested-ternary:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prefer-stateless-function:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import GenerateOrder from './Components/Generate/GenerateOrder';
import NewGenerateOrder from './Components/Generate/NewGenerateOrder';
import Order from './Components/Order/Order';
import Production from '../../../Pages/Production/workorder';
import Costing from './Components/Costing';
import Billing from './Components/Billing';
import Timesheet from './Components/History/Timesheet';
import Art from '../../Art';
import WorkHistory from './Components/History/WorkHistory';
import UserStore from '../../../Stores/User/Store';
import { Link, Route, Switch } from 'react-router-dom';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import History from './Components/History';

const getLocationRoot = (pathname) => {
  return pathname.split('/').splice(0, 4).join('/');
};

const Footer = ({ workOrder }) => {
  const user = UserStore.getUser();
  const [active, handleChange] = useState(0);
  const { WorkOrderId } = useParams();
  const location = useLocation();
  return (
    <div>
      <AppBar position="sticky" color="default">
        <Tabs
          value={getLocationRoot(location.pathname)}
          onChange={(e, v) => handleChange(v)}
          variant={window.innerWidth < 1200 ? 'scrollable' : 'fullWidth'}
        >
          <Tab label="Art" value={`/workorder/${WorkOrderId}/art`} component={Link} to={`/workorder/${WorkOrderId}/art`} />
          <Tab label="Product" value={`/workorder/${WorkOrderId}/product`} component={Link} to={`/workorder/${WorkOrderId}/product`} />
          <Tab label="Order" value={`/workorder/${WorkOrderId}/order`} component={Link} to={`/workorder/${WorkOrderId}/order`} />
          <Tab label="Cost" value={`/workorder/${WorkOrderId}/cost`} component={Link} to={`/workorder/${WorkOrderId}/cost`} />
          {/* TODO viewCSPInvoiceTab have mckay help */}
          {user.group.includes('Production') && workOrder.orderType === 'csp' ? (
            <Tab label="Invoice" value={`/workorder/${WorkOrderId}/invoice`} component={Link} to={`/workorder/${WorkOrderId}/invoice`} />
          ) : !user.group.includes('Production') ||
            (workOrder.customer && workOrder.customer.includes('i9')) ||
            workOrder.orderType === 'fulfillment' ? (
            <Tab label="Invoice" value={`/workorder/${WorkOrderId}/invoice`} component={Link} to={`/workorder/${WorkOrderId}/invoice`} />
          ) : (
            <Tab label="Invoice" disabled value={`/workorder/${WorkOrderId}/invoice`} component={Link} to={`/workorder/${WorkOrderId}/invoice`} />
          )}
          <Tab label="Production" value={`/workorder/${WorkOrderId}/production`} component={Link} to={`/workorder/${WorkOrderId}/production`} />
          <Tab label="History" value={`/workorder/${WorkOrderId}/history`} component={Link} to={`/workorder/${WorkOrderId}/history`} />
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={`/workorder/:WorkOrderId/art`}>
          <Art workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/product`}>
          <NewGenerateOrder workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/order`}>
          <Order workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/cost`}>
          <Costing workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/invoice`}>
          <Billing workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/production`}>
          <Production workOrder={workOrder} />
        </Route>
        <Route path={`/workorder/:WorkOrderId/history`}>
          <History workOrder={workOrder} user={user} />
        </Route>
      </Switch>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  workOrder: PropTypes.object,
};
