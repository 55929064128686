/* eslint-disable react/display-name */
import React, { useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import '../../Utils/styles/LatestWorkOrders.css';
import { whichMonth } from '../../Utils/date';
import PropTypes from 'prop-types';
import { get } from '../../../Functions/API';
import { workorder } from '../../../Constants/backendRoutes';

const OverdueTable = ({ columns, refresh }) => {
  const getOverdueWorkOrders = async (query) => {
    const filter = {
      eager: {
        $aggregations: [
          {
            type: 'sum',
            field: 'quantity',
            alias: 'quantity',
            relation: 'orders.sizes',
          },
        ],
        $where: {
          productionComplete: false,
          salesApproved: true,
          canceled: false,
          $or: [
            {
              dueDate: {
                $ltenow: '',
              },
            },
            {
              shipMethod: {
                $in: ['JRShipping', '2DayAir', '3DayAir', 'nextDayAir'],
              },
            },
          ],
        },
      },
      order: 'dueDate asc',
    };
    const { results: data, total: totalCount } = await get(
      `${workorder.list}?filter=${JSON.stringify(filter)}&page=${query.page}&pageSize=${query.pageSize}`,
    );
    return { data, totalCount, page: query.page };
  };

  const getBackgroundColor = (shipMethod) => {
    if (shipMethod === 'nextDayAir' || shipMethod === '2DayAir' || shipMethod === '3DayAir' || shipMethod === 'J&RShipping') {
      return '#f40058';
    }
    return '#FFF';
  };

  const getTextColor = (shipMethod) => {
    if (shipMethod === 'nextDayAir' || shipMethod === '2DayAir' || shipMethod === '3DayAir' || shipMethod === 'J&RShipping') {
      return '#FFF';
    }
    return 'black';
  };

  useEffect(() => {
    () => tableRef.current && tableRef.current.onQueryChange();
  }, [refresh]);

  return (
    <MaterialTable
      tableRef={useRef('overdue-workorders')}
      columns={columns}
      data={getOverdueWorkOrders}
      options={{
        search: false,
        showTitle: false,
        toolbar: false,
        padding: 'dense',
        loadingType: 'linear',
        headerStyle: {
          padding: '6px 16px',
        },
        pageSize: 10,
        pageSizeOptions: [5, 10, 20],
        rowStyle: (rowData) => {
          return {
            fontSize: 14,
            backgroundColor: getBackgroundColor(rowData.shipMethod),
            color: getTextColor(rowData.shipMethod),
          };
        },
      }}
      onRowClick={(event, rowData) => {
        // frontend url
        window.open(`/workorder/${rowData.id}`, '_blank');
      }}
    />
  );
};

OverdueTable.propTypes = {
  columns: PropTypes.array,
  refresh: PropTypes.bool,
};

OverdueTable.defaultProps = {
  columns: [
    {
      title: 'ID',
      field: 'workOrderID',
    },
    {
      title: 'Due Date',
      field: 'dueDate',
      searchable: false,
      render: (rowData) => {
        return <>{whichMonth(rowData.dueDate)}</>;
      },
    },
    { title: 'Quantity', field: 'quantity' },
    { title: 'Customer', field: 'customerName' },
    { title: 'Ship Method', field: 'shipMethod' },
  ],
  numPages: 1,
};

export default OverdueTable;
