import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { addVendorToQB } from '../QuickBooks/functions';
import { fetchVendors } from '../../Stores/Vendors/Actions';
import VendorsStore from '../../Stores/Vendors/Store';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import UserStore from '../../Stores/User/Store';

const VendorNugget = ({ rowData }) => {
  const user = UserStore.getUser();
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState(null);
  // TODO addVendorToQuickbooksButton
  const permission = ['Admin', 'Accounting Admin'].indexOf(user.group) !== -1;

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    } else {
      getVendors();
    }
    return () => {
      VendorsStore.removeListener('change', getVendors);
    };
  }, []);

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  useEffect(() => {
    const newVendor = vendors.find((v) => v.id === rowData.vendorID);
    setVendor(newVendor ? newVendor : null);
  }, [rowData, vendors]);

  if (vendor) {
    return !vendor.quickbooksId ? (
      <div>
        {vendor.name}
        {permission && (
          <Tooltip title="Add Vendor to Quickbooks">
            <PersonAddIcon
              fontSize="small"
              variant="contained"
              style={{ color: 'red', padding: '5px', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addVendorToQB(vendor.id);
              }}
            ></PersonAddIcon>
          </Tooltip>
        )}
        {!permission && !vendor.quickbooksId && (
          <Tooltip title="Vendor not in QuickBooks">
            <ErrorIcon fontSize="small" style={{ color: 'red', padding: '5px' }} />
          </Tooltip>
        )}
      </div>
    ) : (
      <div>{vendor.name}</div>
    );
  }
  return (
    <div>
      {/* Not Found
      <Tooltip title="Vendor not found">
        <ErrorIcon fontSize="small" style={{ color: 'pink', padding: '5px' }} />
      </Tooltip> */}
    </div>
  );
};

export default VendorNugget;

VendorNugget.propTypes = {
  rowData: PropTypes.object,
};
