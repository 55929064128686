/* eslint no-console:0 */
/* eslint no-unused-vars:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfLines: [],
      submitted: false,
      customers: [],
      columns: [
        {
          title: 'Customer',
          field: 'DisplayName',
          editable: 'always',
        },
        {
          title: 'Email',
          field: 'PrimaryEmailAddr.Address',
          editable: 'always',
        },
        {
          title: 'Billing Street Address',
          field: 'BillAddr.Line1',
          editable: 'always',
        },
        {
          title: 'Billing City',
          field: 'BillAddr.City',
          editable: 'always',
        },
        {
          title: 'Billing State',
          field: 'BillAddr.CountrySubDivisionCode',
          editable: 'always',
        },
        {
          title: 'Billing Zip Code',
          field: 'BillAddr.PostalCode',
          editable: 'always',
        },
        {
          title: 'Shipping Street Address',
          field: 'ShipAddr.Line1',
          editable: 'always',
        },
        {
          title: 'Shipping City',
          field: 'ShipAddr.City',
          editable: 'always',
        },
        {
          title: 'Shipping State',
          field: 'ShipAddr.CountrySubDivisionCode',
          editable: 'always',
        },
        {
          title: 'Shipping Zip Code',
          field: 'ShipAddr.PostalCode',
          editable: 'always',
        },
        {
          title: 'Open Balance',
          field: 'Balance',
          editable: 'never',
          type: 'currency',
        },
      ],
    };
  }

  componentDidMount() {
    this.getQBCustomers();
  }

  getQBCustomers = () => {
    this.props
      .get('/getQBCustomers', [])
      .then((results) => {
        const { url, message } = results;
        const customers = message;
        if (url) {
          let parameters = 'location=1,width=800,height=650';
          parameters += `,left=${(window.screen.width - 800) / 2},top=${(window.screen.height - 650) / 2}`;
          window.open(url, 'connectPopup', parameters);
        } else {
          this.setState({
            customers,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.props.createSnack('Error', err?.message);
      });
  };

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Quickbooks Customers"
            columns={this.state.columns}
            data={this.state.customers}
            options={{
              pageSizeOptions: [10, 15, 25, 50],
              pageSize: 10,
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                console.log(newData);
                await this.props
                  .post('/updateQBCustomer', newData)
                  .then(() => {
                    this.props.createSnack('Success', 'Customer updated successfully!');
                  })
                  .catch((err) => {
                    this.props.createSnack('Error', err.message);
                  });
              },
            }}
          />
        </Paper>
      </div>
    );
  }
}

export default Customers;
