import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  List,
  CircularProgress,
  IconButton,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Send, CloudUpload } from '@material-ui/icons';
import DropZone from '../../../../Footer/Components/Generate/DropZone';
import Modal from '@material-ui/core/Modal';
import Notification from './Notification';
import * as NotificationsActions from '../../../../../../Stores/Notifications/Actions';
import { fetchUsers } from '../../../../../../Stores/Users/Actions';
import { WorkOrderStore, UsersStore, UserStore, NotificationsStore } from '../../../../../../Stores';

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [dropZoneModalOpen, setDropZoneModalOpen] = useState(false);
  const [type, setType] = useState('Info');
  const [url, setUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [progress, setProgress] = useState(0);
  const types = ['Info', 'Sales', 'Accounting', 'Art', 'Production', 'Fulfillment', 'Shipping', 'Error', 'File', 'Picture'];
  const [notifications, setNotifications] = useState([]);
  const [users, setUsers] = useState([]);
  const [daddy, setDaddy] = useState(React.createRef());
  const user = UserStore.getUser();

  const [workOrder, setWorkOrder] = useState({});
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    NotificationsStore.on('change', getNotifications);
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    fetchUsers().then(setUsers);

    return () => {
      NotificationsStore.removeListener('change', getNotifications);
      WorkOrderStore.removeListener('change', getNotifications);
    };
  }, []);

  useEffect(() => {
    if (workOrder.id) {
      setLoading(true);
      NotificationsActions.loadNotifications(workOrder.id);
    }
  }, [workOrder.id]);

  useEffect(() => {
    if (daddy.current) {
      daddy.current.scrollTop = daddy.current.scrollHeight;
    }
  }, [notifications, loading]);

  const getNotifications = () => {
    setLoading(false);
    setNotifications([...NotificationsStore.getAll()]);
  };

  const uploadFile = async (files) => {
    for (const file of files) {
      setFileName(file.fileName);
      setFileId(file.id);
      if (file.fileName.includes('gif') || file.fileName.includes('jpg') || file.fileName.includes('png') || file.fileName.includes('jpeg')) {
        setType('Picture');
      }
      setUrl(file.url);
    }
    setDropZoneModalOpen(false);
  };

  return workOrder.id ? (
    <div>
      <Modal open={dropZoneModalOpen} onClose={() => setDropZoneModalOpen(false)}>
        <div
          style={{
            position: 'relative',
            width: '70%',
            margin: 'auto',
            top: '10%',
            backgroundColor: 'white',
            padding: '15px',
            outline: 'none',
          }}
        >
          <DropZone filesLimit={1} type="Notification" autoUpload={true} handleDrop={uploadFile} workOrderID={workOrder.id} id="dropzone-modal" />
        </div>
      </Modal>
      <List ref={daddy} style={{ overflow: 'scroll', height: '375px' }} dense>
        {loading ? (
          <Grid style={{ height: '100%' }} container justify="center" alignItems="center">
            <CircularProgress size={100} />
          </Grid>
        ) : (
          <>
            {notifications.map((notification, key) => {
              const createdBy = users.find((user) => user.uid === notification.createdBy);
              return (
                <>
                  <Notification
                    key={key}
                    {...{
                      notification,
                      user,
                      createdBy,
                      users,
                      workOrder,
                    }}
                  />
                  {notification.children?.map((child, key) => {
                    const createdBy = users.find((user) => user.uid === child.createdBy);
                    return (
                      <Notification
                        key={key}
                        {...{
                          notification: child,
                          user,
                          createdBy,
                          users,
                          workOrder,
                        }}
                      />
                    );
                  })}
                </>
              );
            })}
          </>
        )}
      </List>
      <Grid container justify="flex-start" alignItems="flex-end">
        <Grid item style={{ marginRight: '10px' }}>
          <FormControl component="div">
            <InputLabel shrink>Type</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              {types.map((t, key) => (
                <MenuItem key={key} value={t}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {type === 'Picture' ? (
          <Grid item>
            {url ? (
              <img src={url} style={{ width: '100px' }} />
            ) : (
              <Button
                onClick={() => setDropZoneModalOpen(true)}
                style={{ marginRight: '8px' }}
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
              >
                Upload
              </Button>
            )}
          </Grid>
        ) : (
          type === 'File' && (
            <Grid item>
              {url ? (
                fileName
              ) : (
                <Button
                  onClick={() => setDropZoneModalOpen(true)}
                  style={{ marginRight: '8px' }}
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUpload />}
                >
                  Upload
                </Button>
              )}
            </Grid>
          )
        )}
        <Grid item style={{ flexGrow: 1 }}>
          <TextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                NotificationsActions.addNotification(type, message, url, user, workOrder, fileId);
                setFileName(null);
                setMessage('');
                setUrl(null);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      NotificationsActions.addNotification(type, message, url, user, workOrder, fileId);
                      setFileName(null);
                      setMessage('');
                      setUrl(null);
                    }}
                  >
                    <Send fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '95%' }}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div style={{ textAlign: 'center' }}>You&apos;ll be able to create notifications once the work order is saved.</div>
  );
};

Notifications.propTypes = {
  workOrder: PropTypes.object,
};

export default Notifications;
