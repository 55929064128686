import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get, post } from '../../../Functions/API';
import MaterialTable from 'material-table';
import { createDialog } from '../../../Stores/Dialog/Actions';
import QBReportWOs from '../../../Pages/accounting/qb-reports';
import { convertDateToSQLDate } from '../../Utils/SqlDate';

const QBReports = () => {
  const history = useHistory();
  const [reports, setReports] = useState([]);
  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'From', field: 'from', type: 'datetime', editable: 'onAdd' },
    { title: 'To', field: 'to', type: 'datetime', editable: 'onAdd' },
    { title: 'Created @', field: 'created_at', type: 'datetime', editable: 'never' },
  ];

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    get('/qbReports').then(({ results }) => {
      setReports(results);
    });
  };

  const newReport = ({ name, from, to }, qbReportWOs) => {
    post('/qbReports', { name, from, to, qbReportWOs }).then(() => {
      getReports();
    });
  };

  return (
    <div>
      <MaterialTable
        title={'QB Reports'}
        columns={columns}
        data={reports}
        editable={{
          onRowAdd: async (newData) => {
            const { name } = newData;
            const from = convertDateToSQLDate(newData.from);
            const to = convertDateToSQLDate(newData.to);
            get(`/workorder/qbReport?invoiceDate[gte]=${from}&invoiceDate[lte]=${to}&qbinvoiceCreated=1&sentToQuickBooks=1`).then(
              ({ results: WorkOrders }) => {
                createDialog({
                  content: <QBReportWOs WorkOrders={WorkOrders} />,
                  title: 'New QB Report',
                  actions: [
                    { title: 'Cancel', color: 'secondary', callback: () => {} },
                    {
                      title: 'Save',
                      color: 'primary',
                      callback: () =>
                        newReport(
                          { name, from, to },
                          WorkOrders.map(({ msasInvoiceTotal, workOrderId, viewableId }) => ({ msasInvoiceTotal, workOrderId, viewableId })),
                        ),
                    },
                  ],
                  size: 'xl',
                  disableBackdropClick: true,
                });
              },
            );
          },
          onRowUpdate: async (newData, oldData) => {},
          onRowDelete: async (data) => {},
        }}
        onRowClick={(event, rowData) => {
          history.push(`/accounting/qb-reports/${rowData.id}`);
        }}
      />
    </div>
  );
};

export default QBReports;
