/* eslint react/forbid-prop-types:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint react/no-unused-state:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import QuoteCosts from './QuoteCosts';

export default class ProductPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      styles: [],
    };
  }

  updateCatalog = (event) => {
    this.props.get('/getCategories', [{ name: 'id', value: event.target.value }]).then((categories) => {
      this.props.updateCatalogName(this.props.row, this.props.product, event.target.value);
      this.setState({
        categories,
      });
    });
  };

  updateStyles = (event) => {
    this.props
      .get('/getStyles', [
        { name: 'id', value: this.props.product.catalogId },
        {
          name: 'category',
          value: event.target.value,
        },
      ])
      .then((styles) => {
        this.props.updateProduct(this.props.row, this.props.product, 'category', event.target.value);
        this.setState({
          category: event.target.value,
          styles,
        });
      });
  };

  updateStyle = (event) => {
    this.props.updateProduct(this.props.row, this.props.product, 'styleId', event.target.value);
    const style = this.state.styles.find((row) => row.id === event.target.value);
    console.log(style);
    this.props.updateProduct(this.props.row, this.props.product, 'style', style.style);
    this.props.updateProduct(this.props.row, this.props.product, 'description', style.description);
    this.props.updateProduct(this.props.row, this.props.product, 'price', style.price);
    this.props.get('/catalogOptions', { styleId: style.id }, 'obj').then((options) => {
      this.props.updateProduct(this.props.row, this.props.product, 'options', options);
      if (options.length) {
        this.props.updateProduct(this.props.row, this.props.product, 'optionId', options[0].id);
      }
    });
  };

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          width: '90%',
          backgroundColor: '#ffffff',
          padding: '15px',
          outline: 'none',
          height: '50%',
          overflowX: 'auto',
        }}
      >
        <Grid container spacing={2} justify="space-around" style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel shrink>Catalog</InputLabel>
              <Select onChange={(event) => this.updateCatalog(event)} value={this.props.catalogId}>
                <MenuItem value="">None</MenuItem>
                {this.props.catalogs.map((catalog, key) => (
                  <MenuItem key={key} value={catalog.id}>
                    {catalog.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel shrink>Category</InputLabel>
              <Select onChange={(event) => this.updateStyles(event)} value={this.props.product.category}>
                {this.state.categories.map((category, key) => (
                  <MenuItem key={key} value={category.category}>
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel shrink>Style</InputLabel>
              <Select onChange={(event) => this.updateStyle(event)}>
                {this.state.styles.map((style, key) => (
                  <MenuItem key={key} value={style.id}>
                    {style.style} -{style.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ProductPicker.propTypes = {
  catalogId: PropTypes.number.isRequired,
  catalogs: PropTypes.array.isRequired,
};
