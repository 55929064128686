import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { get, patch } from '../../../../../Functions/API';
import IconButton from '@material-ui/core/IconButton';
import { Save } from '@material-ui/icons';

const SalesAssistants = ({ workOrder, disabled = false }) => {
  const [salesAssistantOptions, setSalesAssistantOptions] = useState([]);
  const [salesAssistants, setSalesAssistants] = useState([]);
  const [savedSalesAssistants, setSavedSalesAssistants] = useState([]);

  useEffect(() => {
    if (workOrder.id) {
      get('/salesAssistants', { workOrderID: workOrder.id }, 'obj')
        .then((results) => {
          setSalesAssistants(results.map((r) => r.uid));
          setSavedSalesAssistants(results.map((r) => r.uid));
        })
        .catch((err) => {
          console.error(err.message);
        });
      get('/user/group', { group: 'Order Processor' }, 'obj')
        .then((results) => {
          setSalesAssistantOptions(results);
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [workOrder.id]);

  const checkSalesAssistants = () => {
    if (salesAssistants === savedSalesAssistants) return true;
    if (salesAssistants == null || savedSalesAssistants == null) return false;
    if (salesAssistants.length !== savedSalesAssistants.length) return false;
    // salesAssistants.sort((a, b) => a - b);
    // savedSalesAssistants.sort((a, b) => a - b);
    for (let i = 0; i < salesAssistants.length; ++i) {
      if (!savedSalesAssistants.find((c) => c === salesAssistants[i])) return false;
    }
    return true;
  };

  const updateSalesAssistants = () => {
    patch(`/salesAssistants/${workOrder.id}`, salesAssistants).then((results) => {
      setSavedSalesAssistants(results.map((r) => r.uid));
    });
  };

  return (
    <FormControl style={{ maxWidth: '100%' }} component="div">
      <InputLabel shrink htmlFor="orderProcessors">
        SA
      </InputLabel>
      <Select
        onChange={(e) => setSalesAssistants(e.target.value)}
        multiple
        disabled={disabled}
        value={salesAssistants}
        renderValue={(selected) => {
          const returnValue = [];
          for (const uid of selected) {
            const sa = salesAssistantOptions.find((sa) => uid === sa.uid);
            if (sa) {
              returnValue.push(`${sa.firstName} ${sa.lastName}`);
            }
          }
          return returnValue.join(', ');
        }}
        input={
          <Input
            name="orderProcessors"
            id="orderProcessors"
            error={!checkSalesAssistants()}
            startAdornment={
              !checkSalesAssistants() && (
                <IconButton size={'small'} onClick={updateSalesAssistants}>
                  <Save fontSize={'small'} color={'secondary'} />
                </IconButton>
              )
            }
          />
        }
      >
        {salesAssistantOptions.map((sa, key) => (
          <MenuItem key={key} value={sa.uid}>
            <ListItemText primary={`${sa.firstName} ${sa.lastName}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SalesAssistants;

SalesAssistants.propTypes = {
  workOrder: PropTypes.object,
  disabled: PropTypes.bool,
};
