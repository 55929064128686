/* eslint-disable react/display-name */
/* eslint react/destructuring-assignment:0 */
/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import MaterialTable from 'material-table';
import { post } from '../../Functions/API';

const EditSkuDialog = ({ sku, ps }) => {
  const [data, setData] = useState([...sku.sizes]);
  const columns = [
    {
      title: 'Size',
      field: 'size',
      editComponent: ({ rowData, onChange }) => (
        <Select value={rowData.size} onChange={(e) => onChange(e.target.value)}>
          {ps.map(({ size }, key) => (
            <MenuItem key={key} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { title: 'Target Quantities', field: 'target' },
    {
      title: 'Cost',
      field: 'cost',
      type: 'numeric',
      render: (rowData) => `$${rowData.cost.toFixed(2)}`,
    },
    {
      title: 'Weight',
      field: 'weight',
      type: 'numeric',
      render: (rowData) => `${rowData.weight.toFixed(2)} lb`,
    },
  ];
  const updateSize = (newSize, oldSize) => {
    const newData = [...data];
    const index = data.indexOf(oldSize);
    post('/updateSize', newSize)
      .then((size) => {
        newData[index] = size;
        setData(newData);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            newData.cost = parseFloat(newData.cost);
            newData.weight = parseFloat(newData.weight);
            updateSize(newData, oldData);
          },
          onRowAdd: async (newSize) => {
            console.log(newSize);
            const json = { skuId: sku.id, quantity: 0, ...newSize };
            await post(`/sku/sizes`, json);
            setData([...data, json]);
          },
        }}
        options={{
          showTitle: false,
          search: false,
          paging: false,
        }}
      />
    </div>
  );
};

export default EditSkuDialog;
