/* eslint no-alert:0 */
/* eslint no-unused-vars:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      this.setState(results);
    });
    // fetch(`${this.props.homepage}/getAllOptions`)
    //     .then(async response => {
    //         let options = await response.json();
    //         this.setState({
    //             options
    //         })
    //     })
  }

  setup = async () => {
    let options;
    let error = '';
    await this.props
      .get('/getAllOptions', [])
      .then((results) => {
        options = results;
      })
      .catch((err) => {
        error += 'Could not get all options.';
      });
    if (options) {
      return {
        options,
      };
    }
    throw new Error(error);
  };

  addOption = (data) => {
    this.props.post('/newOption', data).then((results) => {
      const id = results.insertId;
      const { options } = this.state;
      options.push({ ...data, id });
      this.setState({
        options,
      });
    });
  };

  edit = (newData, oldData) => {
    this.props.post('/editOption', newData).then(() => {
      const { options } = this.state;
      const index = options.indexOf(oldData);
      options[index] = newData;
      this.setState({
        options,
      });
    });
  };

  render() {
    const columns = [
      { field: 'type', title: 'Type' },
      { field: 'name', title: 'Name' },
      { field: 'value', title: 'Value' },
    ];
    return (
      <Paper>
        <MaterialTable
          title="Options"
          columns={columns}
          data={this.state.options}
          editable={{
            onRowAdd: async (data) => {
              await this.addOption(data);
            },
            onRowUpdate: async (newData, oldData) => {
              await this.edit(newData, oldData);
            },
          }}
        />
      </Paper>
    );
  }
}

export default Options;
