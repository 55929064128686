/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  InputLabel,
  Switch,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  ListItemText,
  Button,
  Grid,
} from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import WorkOrderStore from '../../../../../Stores/WorkOrder/Store';
import SalesStore from '../../../../../Stores/Sales/Store';
import UserStore from '../../../../../Stores/User/Store';
import UsersStore from '../../../../../Stores/Users/Store';
import { patch, post } from '../../../../../Functions/API';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const CSPCosts = ({ newRow, updateRow, removeRow, costs, workOrder, removeAllCostingRows, actions, submit, cspVendor }) => {
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const [sales, setSales] = useState([]);
  const user = UserStore.getUser();
  const cspColumns = [
    {
      title: 'Saved',
      render: (rowData) =>
        rowData && <Switch onClick={submit} size={'small'} checked={Boolean(rowData.id)} value={Boolean(rowData.id)} color="primary" />,
    },
    { field: 'notes', title: 'Description' },
    { field: 'totalQuantity', title: 'Quantity' },
    { field: 'perUnit', title: 'Per Unit' },
    {
      field: 'totalEstimatedCost',
      title: 'Estimated Cost',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return (
            <div>${parseFloat(rowData.totalEstimatedCost) ? parseFloat(rowData.totalEstimatedCost).toFixed(2) : rowData.totalEstimatedCost}</div>
          );
        }
      },
    },
    {
      field: 'totalActualCost',
      title: 'Actual Cost',
      // TODO editCSPCostsActualCost
      ...(user.group === 'Accounting Admin' || user.group === 'Admin' ? { editable: 'always' } : { editable: 'never' }),
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
  ];

  if (user.group.includes('Accounting') || (workOrder.misprint && user.group === 'Admin')) {
    cspColumns.splice(1, 0, {
      title: 'Cost Assignment',
      field: 'costAssignment',
      editable: 'never',
      render: (rowData) => {
        return (
          <Select value={rowData?.costAssignment || ''} onChange={(event) => updateRow({ ...rowData, costAssignment: event.target.value }, rowData)}>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="CSP">CSP</MenuItem>
            <MenuItem value="Sales Assistant">Sales Assistant</MenuItem>
          </Select>
        );
      },
    });
  }

  if (
    workOrder.misprint &&
    (user.group === 'Accounting Admin' || user.group === 'Order Processor' || user.group === 'Admin' || user.group.includes('Sales'))
  ) {
    cspColumns.splice(1, 0, {
      title: 'Reprint',
      field: 'misprint',
      editable: 'never',
      render: (rowData) => {
        return (
          <Switch
            checked={rowData.misprint}
            size={'small'}
            onChange={() => updateRow({ ...rowData, misprint: !rowData.misprint }, rowData)}
            value={rowData.misprint}
            color="secondary"
          />
        );
      },
    });
  }

  useEffect(() => {
    SalesStore.on('change', getSales);
    if (workOrder.id && !workOrder.cspSendTo) {
      const cspSendTo = getCSPSendTo();
      if (cspSendTo) {
        updateWorkOrder(workOrder.id, { cspSendTo: getCSPSendTo() });
      }
    }
    return () => {
      SalesStore.removeListener('change', getSales);
    };
  }, [workOrder.id, sales]);

  const getSales = () => {
    setSales([...SalesStore.getSales()]);
  };

  // TODO ask Mckay how we can codify this
  const getCSPSendTo = () => {
    if ((workOrder?.cspSendTo === null || workOrder?.cspSendTo === '') && sales.length) {
      const user = UsersStore.getUser(sales[0].userID);
      if (user) {
        if (user.group.includes('Sales') && user.uid !== 'WJU8lxxoytVWHEVQGGdCCcEcXLb2') {
          return 'Contract Screen Printing';
        }
        if (user.group === 'Owner' || user.uid === 'WJU8lxxoytVWHEVQGGdCCcEcXLb2') {
          return 'Contract Screen Printing - 1';
        }
        return 'Contract Screen Printing - 2';
      }
      if (workOrder?.customer.includes('i9')) {
        return 'Contract Screen Printing - 3';
      }
    }
    if (workOrder?.cspSendTo) {
      return workOrder?.cspSendTo;
    }
    return null;
  };

  const createCSPBill = () => {
    post('/createCSPBill', {
      workOrderID: workOrder.id,
    })
      .then((results) => {
        WorkOrderStore.updateObj({ cspBillCreated: true, cspBillId: results.Id });
        createSnack('Success', 'CSP Bill Created');
      })
      .catch((err) => {
        console.log(err);
        createSnack('Error', err.message);
      });
  };

  const updateCSPBill = () => {
    post('/updateCSPBill', {
      workOrderID: workOrder.id,
      cspBillId: workOrder.cspBillId,
    })
      .then((results) => {
        WorkOrderStore.updateObj({ cspBillCreated: true, cspBillId: results.Id });
        createSnack('Success', 'Successfully Updated');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const deleteCSPBill = () => {
    post('/deleteCSPBill', {
      workOrderID: workOrder.id,
      cspBillId: workOrder.cspBillId,
    })
      .then(() => {
        WorkOrderStore.updateObj({ cspBillCreated: false, cspBillId: null });
        createSnack('Success', 'CSP Bill Deleted');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateCSPSendTo = async (event) => {
    await updateWorkOrder(workOrder.id, { cspSendTo: event.target.value });
  };

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable
        title={'CSP Costs'}
        columns={cspColumns}
        data={costs.filter((row) => row.vendorID === cspVendor.id && row.type !== 'shipping')}
        options={{
          rowStyle: {
            fontSize: 14,
          },
          search: false,
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Grid container style={{ width: '100%', margin: '10px' }} spacing={1}>
                {/* TODO showCSPCostsAccountingTools */}
                {(user.group === 'Admin' || user.group.includes('Accounting')) && (
                  <>
                    <Grid item style={{ flexGrow: 1 }}>
                      <FormControl component="div">
                        <InputLabel shrink htmlFor="cspSelect">
                          Send to
                        </InputLabel>
                        <Select value={workOrder.cspSendTo} onChange={updateCSPSendTo} input={<Input name="cspSelect" id="cspSelect" />}>
                          <MenuItem value="">
                            <ListItemText primary="Please select a CSP option" />
                          </MenuItem>
                          <MenuItem value="Contract Screen Printing">
                            <ListItemText primary="Contract Screen Printing" />
                          </MenuItem>
                          <MenuItem value="Contract Screen Printing - 1">
                            <ListItemText primary="Contract Screen Printing - Elite" />
                          </MenuItem>
                          <MenuItem value="Contract Screen Printing - 2">
                            <ListItemText primary="Contract Screen Printing - R&S" />
                          </MenuItem>
                          <MenuItem value="Contract Screen Printing - 3">
                            <ListItemText primary="Contract Screen Printing - SMTT" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {workOrder?.cspBillCreated ? (
                      <>
                        <Grid item>
                          <Button style={{ margin: '10px' }} variant="contained" color="secondary" onClick={updateCSPBill}>
                            Update CSP Bill
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button style={{ margin: '10px' }} variant="contained" onClick={deleteCSPBill}>
                            Delete CSP Bill
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Button style={{ margin: '10px' }} variant="contained" color="primary" onClick={createCSPBill}>
                          Create CSP Bill
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Estimated Cost"
                    value={costs
                      .filter((row) => row.vendorID === cspVendor.id)
                      .reduce((total, row) => total + row.totalEstimatedCost, 0)
                      .toFixed(2)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Actual Cost"
                    value={costs
                      .filter((row) => row.vendorID === cspVendor.id)
                      .reduce((total, row) => total + row.totalActualCost, 0)
                      .toFixed(2)}
                  />
                </Grid>
                {/* TODO showCSPCostsOrderProcessorTools */}
                {(user.group === 'Admin' || user.group === 'Order Processor') && (
                  <Grid item>
                    <IconButton
                      onClick={() => setDeleteAllDisabled(!deleteAllDisabled)}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <Lock /> : <LockOpen />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={() => {
                        removeAllCostingRows('CSP');
                        setDeleteAllDisabled(true);
                      }}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          ),
        }}
        actions={actions}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            updateRow(newData, oldData);
          },
          onRowAdd: async (newData) => {
            newData.vendorID = cspVendor.id;
            newRow(newData);
          },
          onRowDelete: async (oldData) => {
            removeRow(oldData);
          },
        }}
      />
    </div>
  );
};

export default CSPCosts;
