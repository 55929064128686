/* eslint no-console:0 */
/* eslint array-callback-return:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Select,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import palette from '../../Utils/theme/palette';
import DateFilter from '../Common/DateFilter';
import '../../Utils/styles/WorkOrderFinancials.css';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 550,
    position: 'relative',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

const WorkOrderFinancialGraph = ({ get, refresh }) => {
  const classes = useStyles();
  const [workOrderData, setWorkOrderData] = useState(null);
  const [data, setData] = useState(null);
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
  const [graphType, setGraphType] = useState('Line');
  const [selectedOption, setSelectedOption] = useState('Last 7 Days');
  const [open, setOpen] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider,
          },
        },
      ],
    },
  };

  const formatDataSet = (data, dates, type) => {
    const dataSet = [];

    dates.map((date) => {
      let found = false;
      data.map((x) => {
        const dueDate = moment(x.invoiceDate).format('D MMM');
        if (date === dueDate) {
          if (type === 'cost') dataSet.push(x.cost);
          if (type === 'revenue') dataSet.push(x.revenue);
          if (type === 'profit') dataSet.push(x.profit);
          found = true;
        }
      });
      if (!found) dataSet.push(0);
    });
    return dataSet;
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const formatWorkOrderData = () => {
    const date = moment(endDate);
    const dates = [];
    const data = {
      labels: [],
      datasets: [],
    };

    while (date >= moment(startDate)) {
      dates.push(date.format('D MMM'));
      data.labels.push(date.format('MMM D'));
      date.subtract(1, 'days');
    }

    data.labels.reverse();
    dates.reverse();

    data.datasets.push(
      {
        label: 'Cost',
        borderColor: '#ef5350',
        backgroundColor: '#ef5350',
        data: formatDataSet(workOrderData, dates, 'cost'),
        fill: false,
        pointRadius: 1,
        borderWidth: 2,
        lineTension: 0.2,
      },
      {
        label: 'Revenue',
        borderColor: '#ab47bc',
        backgroundColor: '#ab47bc',
        data: formatDataSet(workOrderData, dates, 'revenue'),
        fill: false,
        pointRadius: 1,
        borderWidth: 2,
        lineTension: 0.2,
      },
      {
        label: 'Profit',
        borderColor: '#5c6bc0',
        backgroundColor: '#5c6bc0',
        data: formatDataSet(workOrderData, dates, 'profit'),
        fill: false,
        pointRadius: 1,
        borderWidth: 2,
        lineTension: 0.2,
      },
    );

    setData(data);
  };

  const handleDatePickerChange = (date, start) => {
    if (start) {
      setStartDate(moment(date).format('YYYY/MM/DD'));
    } else {
      setEndDate(moment(date).format('YYYY/MM/DD'));
    }
  };

  const getWorkOrderData = async () => {
    get('/getWorkOrderFinancials', [
      {
        name: 'startDate',
        value: startDate,
      },
      {
        name: 'endDate',
        value: endDate,
      },
    ])
      .then((res) => {
        setWorkOrderData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDates = (e) => {
    const newSelection = e.target.value;
    setSelectedOption(newSelection);
    if (newSelection === 'Last 7 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Last 14 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(13, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Last 30 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Custom Range') {
      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    getWorkOrderData();
  }, [startDate, endDate, refresh]);

  useEffect(() => {
    if (workOrderData) formatWorkOrderData();
  }, [workOrderData]);

  return (
    <>
      <Modal open={showDatePicker} onClose={() => setShowDatePicker(false)}>
        <div className="date-picker-container">
          <DateFilter startDate={startDate} endDate={endDate} handleDatePickerChange={handleDatePickerChange} />
          <div className="date-picker-button-container">
            <Button style={{ margin: '8px 0' }} variant="contained" size="small" color="primary" onClick={() => setShowDatePicker(false)}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Card className={classes.root}>
        <CardHeader
          action={
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                <InfoIcon />
              </IconButton>
              <Popover
                open={infoOpen}
                anchorEl={anchorEl}
                onClose={openInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                  This graph shows you averages of cost, profit, and revenue for all work orders of a certain day.
                  <br />
                  <br />
                  Hover/Tap on the bar to see specific quanities. Also, you can limit your selection by clicking on the labels at the top to exclude
                  them from the graph.
                </Typography>
              </Popover>
              <FormControl>
                <Select
                  labelId="workOrderFinancialLabel"
                  id="workOrderFinDropDown"
                  value={selectedOption}
                  onChange={(e) => setDates(e)}
                  style={{ marginTop: '6px' }}
                  onOpen={() => setDropDownOpen(true)}
                  onClose={() => setDropDownOpen(false)}
                >
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>;<MenuItem value="Last 14 Days">Last 14 Days</MenuItem>;
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>;
                  <MenuItem value="Custom Range">
                    {selectedOption === 'Custom Range' && !dropDownOpen
                      ? `${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`
                      : 'Custom Range'}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          }
          title={
            <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Work Order Financials</Typography>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <>
            <CardContent style={{ paddingBottom: '16px' }}>
              <div className={classes.chartContainer}>
                {graphType === 'Line' ? data && <Line data={data} options={options} /> : data && <Bar data={data} options={options} />}
              </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <>
                <ButtonGroup size="small" color="primary" style={{ marginRight: '10px' }}>
                  <Button variant={graphType === 'Line' ? 'contained' : 'outlined'} onClick={() => setGraphType('Line')}>
                    Line
                  </Button>
                  <Button variant={graphType === 'Bar' ? 'contained' : 'outlined'} onClick={() => setGraphType('Bar')}>
                    Bar
                  </Button>
                </ButtonGroup>
              </>
            </CardActions>
          </>
        </Collapse>
      </Card>
    </>
  );
};

export default WorkOrderFinancialGraph;
