import dispatcher from './dispatcher';
import { get } from '../../Functions/API';
import CountriesStore from './Store';
import { createSnack } from '../Snack/Actions';

export const fetchCountries = async () => {
  const currentCountries = CountriesStore.getCountries();
  if (currentCountries?.length) {
    return currentCountries;
  } else if (CountriesStore.isFetching()) {
    const { countries } = await CountriesStore.getCountriesPromise();
    return countries;
  } else {
    try {
      const countriesPromise = get(`/contacts/countries`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        countriesPromise,
      });
      const { countries } = await countriesPromise;
      dispatcher.dispatch({
        type: 'SET_COUNTRIES',
        countries,
      });
      return countries;
    } catch (err) {
      createSnack('Error', 'Problem loading countries');
      return currentCountries;
    }
  }
};
