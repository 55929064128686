/* eslint-disable react/no-children-prop */
/* eslint react/no-this-in-sfc:0 */
/* eslint max-len:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-unused-vars:0 */
/* eslint no-console:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import React, { useState, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import FullScreen from '@material-ui/icons/Fullscreen';
import { CardActions } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';

const ArtRequestArt = forwardRef((props, ref) => {
  const { WorkOrderId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [modalChip, setModalChip] = useState({});

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        width: '70%',
        margin: 'auto',
        top: '10%',
        backgroundColor: 'white',
        padding: '15px',
        outline: 'none',
      }}
    >
      {/* {openModal && (
        <div style={{ padding: '15px', overflowX: 'hidden', backgroundColor: '#f5f5f5' }}> */}
      <Modal
        open={openModal}
        children="div"
        onClose={() => {
          setOpenModal(false);
          setModalChip({});
        }}
        style={{ overflowX: 'hidden', backgroundColor: '#f5f5f5 ' }}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <IconButton
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              backgroundColor: '#f3f2f7',
            }}
            onClick={() => {
              setOpenModal(false);
              setModalChip({});
            }}
            color="primary"
            href=""
          >
            <Cancel />
          </IconButton>
          <iframe src={modalChip.url} style={{ margin: 'auto', width: '100%', height: '100%' }} />
        </div>
      </Modal>
      {/* </div>
      )} */}
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container justify="center" spacing={3}>
        <Grid item xl={12} style={{ textAlign: 'center' }}>
          <h2>Add Art to Request</h2>
        </Grid>
        <Grid item xl={3}>
          <FormControl style={{ margin: '8px', width: '100%' }} component="div">
            <InputLabel shrink htmlFor="priceList">
              Price List
            </InputLabel>
            <NativeSelect
              onChange={(event) => props.updateArtRequestArtPriceList(event, 'type')}
              value={props.artRequestChip.type}
              input={<Input name="priceList" id="priceList" />}
            >
              <option>None</option>
              {props.options.type &&
                props.priceLists.map((priceList, key) => {
                  if (props.csp) {
                    if (priceList.type.includes('CSP')) {
                      return (
                        <option value={priceList.type} key={key}>
                          {priceList.type}
                        </option>
                      );
                    }
                    return false;
                  }
                  return (
                    <option value={priceList.type} key={key}>
                      {priceList.type}
                    </option>
                  );
                })}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xl={3}>
          <FormControl style={{ margin: '8px', width: '100%' }} component="div">
            <InputLabel shrink htmlFor="printType">
              Print Type
            </InputLabel>
            <NativeSelect
              onChange={(event) => props.updateArtRequestArtPrintType(event, 'printType')}
              value={props.artRequestChip.printType}
              input={<Input name="printType" id="printType" />}
            >
              <option>None</option>
              {props.options.type &&
                props.printOptions
                  .filter((option) => option.type === props.artRequestChip.type)
                  .map((printOption, key) => {
                    const stateOptions = props.options.type;
                    const index = stateOptions.findIndex((option) => option.value === printOption.type);
                    if (index > -1) {
                      console.log('found');
                      const type = stateOptions[index].name;
                      return (
                        <option key={key} value={printOption.printType}>
                          {printOption.printType}
                        </option>
                      );
                    }
                    return (
                      <option key={key} value={printOption.printType}>
                        {printOption.printType}
                      </option>
                    );
                  })}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xl={3}>
          <TextField
            id="position"
            type="text"
            label="Position"
            InputLabelProps={{ shrink: true }}
            value={props.artRequestArtPosition}
            style={{ ...props.descriptionFieldStyle, margin: '8px' }}
            onChange={props.updateArtRequestArtPosition}
          />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container spacing={1}>
        <Grid item xs={12}>
          <h3>Images</h3>
        </Grid>
        {props.artRequestArtImages.map((item, key) => (
          <Grid key={key} item>
            <Card style={{ width: '170px', position: 'relative', height: '300px' }}>
              <CardContent>
                {item?.name?.substr(item?.name?.length - 4, item?.name?.length) === 'xlsx' ||
                item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'xls' ||
                item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'csv' ? (
                  <a href={item.url}>{item?.name}</a>
                ) : !item.url ? (
                  <div
                    style={{
                      width: '100%',
                      height: '300px',
                    }}
                  >
                    <img
                      style={{ width: '100%', marginTop: '50px' }}
                      src="https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08"
                    />
                  </div>
                ) : item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'jpg' ||
                  item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'png' ? (
                  <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <img style={{ width: '100%', marginTop: '50px' }} src={item?.url} />
                  </div>
                ) : (
                  <div style={{ position: 'relative', height: '100%' }}>
                    <iframe
                      src={
                        item?.url ||
                        'https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08'
                      }
                      style={{
                        width: '100%',
                        height: '300px',
                      }}
                    />
                  </div>
                )}
                <CardActions style={{ position: 'absolute', right: 0, bottom: 0, fontSize: '5px' }}>
                  <Button variant="contained" size="small" onClick={(event) => props.deleteArtRequestArtImage(event, item, key)}>
                    Delete
                  </Button>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setOpenModal(true);
                      setModalChip(item);
                    }}
                  >
                    <FullScreen fontSize="small" />
                  </IconButton>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12}>
          <DropZone workOrderID={WorkOrderId} autoUpload={true} handleDrop={props.updateArtRequestArtImages} id="artFile3" />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container justify="flex-end" spacing={1}>
        <Grid item xs={3} lg={1}>
          <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={props.updateArtRequestArt}>
            Submit
          </Button>
        </Grid>
        <Grid item xs={3} lg={1}>
          <Button variant="contained" style={{ width: '100%' }} onClick={props.toggleArtRequestArt}>
            Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

ArtRequestArt.displayName = 'ArtRequestArtModal';

export default ArtRequestArt;
