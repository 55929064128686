import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import CustomersStore from '../../../Stores/Customers/Store';
import { loadCustomers } from '../../../Stores/Customers/Actions';

const CustomerAutocomplete = ({ callback }) => {
  const [value, setValue] = useState(null);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    CustomersStore.on('change', getCustomers);
    if (CustomersStore.getCustomers().length === 0) {
      loadCustomers();
    } else {
      getCustomers();
    }
    return () => {
      CustomersStore.removeListener('change', getCustomers);
    };
  }, []);

  const getCustomers = () => {
    setCustomers([...CustomersStore.getCustomers()]);
  };

  const handleValueChange = (e, v) => {
    callback(v);
    setValue(v);
  };

  return (
    <React.Fragment>
      <Autocomplete
        id="customerAutocomplete"
        value={value}
        options={customers}
        getOptionLabel={(customer) => customer.name}
        style={{ width: '100%' }}
        onChange={handleValueChange}
        renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
      />
    </React.Fragment>
  );
};

export default CustomerAutocomplete;

CustomerAutocomplete.propTypes = {
  value: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};
