import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.inventory = [];
    this.SKUs = [];
  }

  getInventory() {
    return this.inventory;
  }

  getSKUs() {
    return this.SKUs;
  }

  getInventoryById = (id) => {
    if (this.inventory.length) {
      return this.inventory.find((i) => i.id === id);
    } else {
      return null;
    }
  };

  getSKUById = (id) => {
    if (this.SKUs.length) {
      return this.SKUs.find((s) => s.id === id);
    } else {
      return null;
    }
  };

  loadInventory = (inventory) => {
    this.inventory = inventory;
    this.emit('change');
  };

  loadSKUs = (inventory) => {
    let newSKUs = [];
    inventory.forEach((i) => {
      newSKUs = newSKUs.concat(i.skus);
    });
    this.SKUs = newSKUs;
    this.emit('SKUChange');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_INVENTORY': {
        this.loadInventory(action.inventory);
        this.loadSKUs(action.inventory);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
