import React, { useState, useContext, useEffect } from 'react';
import { Card, CardContent, CardHeader, Divider, IconButton, Popover, Typography } from '@material-ui/core';
import OverdueTable from '../Common/OverdueTable';
import PropTypes from 'prop-types';

const OverdueWorkOrders = ({ refresh }) => {
  return (
    <Card style={{ height: open ? '100%' : 'auto' }}>
      <CardHeader
        title={
          <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">Overdue Workorders</Typography>
          </div>
        }
      />
      <Divider />
      <CardContent style={{ paddingBottom: '16px' }}>
        <OverdueTable refresh={refresh} />
      </CardContent>
    </Card>
  );
};

OverdueWorkOrders.propTypes = {
  refresh: PropTypes.bool,
};

// workorder id, ship method, customer, due date, quantity
export default OverdueWorkOrders;
