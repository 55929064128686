/* eslint-disable react/prop-types,react/display-name */
import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { get } from '../../Functions/API';
import Papa from 'papaparse';
import { Checkbox } from '@material-ui/core';

const CSP = () => {
  const [loading, setLoading] = useState(false);
  const columns = [
    { title: 'ID', field: 'workOrderID' },
    { title: 'Due Date', field: 'dueDate', type: 'date' },
    { title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
    { title: 'Type', field: 'type' },
    { title: 'Print Type', field: 'printType' },
    { title: 'Position', field: 'position' },
    { title: 'Underlay', field: 'underlay', render: ({ underlay }) => <Checkbox size={'small'} checked={underlay} /> },
    { title: 'Reversible', field: 'reversible', render: ({ reversible }) => <Checkbox size={'small'} checked={reversible} /> },
    { title: 'Quantity', field: 'quantity' },
    { title: 'Price', field: 'price', align: 'left', type: 'currency' },
    { title: 'Cost', field: 'cost', align: 'left', type: 'currency', sorting: false },
  ];

  const getData = async (page, pageSize, orderBy, orderDirection) => {
    setLoading(true);
    const params = { page, pageSize };
    if (orderBy) {
      const { field } = orderBy;
      params.orderBy = field;
      params.orderDirection = orderDirection;
    }
    const { results: data, total: totalCount } = await get('/prints', params);
    setLoading(false);
    return {
      data,
      totalCount,
      page,
    };
  };

  return (
    <MaterialTable
      title={'Prints'}
      columns={columns}
      isLoading={loading}
      options={{
        padding: 'dense',
        pageSizeOptions: [20],
        pageSize: 20,
        search: false,
        exportButton: !loading,
        exportCsv: async () => {
          const { data } = await getData();
          console.log(data);
          const csvContent = Papa.unparse(data, { escapeFormulae: true });
          console.log(csvContent);
          // const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
          const blob = new Blob([csvContent]);
          const link = document.createElement('a');
          link.setAttribute('href', URL.createObjectURL(blob, { type: 'text/plain' }));
          link.setAttribute('download', `Prints.csv`);
          document.body.appendChild(link);
          link.click();
        },
      }}
      data={({ page, pageSize, orderBy, orderDirection, ...other }) => {
        return getData(page, pageSize, orderBy, orderDirection);
      }}
      onRowClick={(event, { id }) => {
        window.open(`/workorder/${id}`);
      }}
    />
  );
};

export default CSP;
