import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.priceGroups = [];
    this.fetching = false;
    this.promise = null;
  }

  getPriceGroups() {
    return this.priceGroups;
  }

  getPromise() {
    return this.promise;
  }

  isFetching() {
    return this.fetching;
  }

  setFetching(promise) {
    this.fetching = true;
    this.promise = promise;
  }

  setPriceGroups = (data) => {
    this.priceGroups = data;
    this.fetching = false;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'SET_FETCHING': {
        this.setFetching(action.promise);
        break;
      }
      case 'SET_DATA': {
        this.setPriceGroups(action.data);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
