/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { get } from '../../../../../Functions/API';

class SizeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: [],
    };
  }

  componentDidMount() {
    get('/sizesByOrderIDs', [{ name: 'orderIDs', value: this.props.line.ordersIDs }])
      .then((sizes) => {
        console.log(sizes);
        this.setState({
          sizes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const columns = [
      { title: 'Size', field: 'size' },
      { title: 'Quantity', field: 'quantity' },
      { title: 'Cost', field: 'cost', type: 'currency', headerStyle: { textAlign: 'right' } },
      { title: 'Bill', field: 'bill', type: 'currency', headerStyle: { textAlign: 'right' } },
    ];
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          backgroundColor: '#ffffff',
          height: '100%',
          outline: 'none',
          overflowX: 'auto',
        }}
      >
        <MaterialTable title="Size Details" columns={columns} data={this.state.sizes} options={{ paging: false, grouping: true }} />
      </div>
    );
  }
}

export default SizeDetails;
