/* eslint no-shadow:0 */
/* eslint react/no-unescaped-entities:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-console:0 */
/* eslint no-unused-vars:0 */
/* eslint no-param-reassign:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-unused-expressions:0 */
/* eslint react/sort-comp:0 */
/* eslint camelcase:0 */
/* eslint consistent-return:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */

// add Orderby from users here
import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Button,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Popover,
  Snackbar,
  SnackbarContent,
  Typography,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import MaterialTable from 'material-table';
import moment from 'moment';
import Filters from './Filters';
import '../../Utils/styles/LatestWorkOrders.css';
import { get } from '../../../Functions/API';
import { updateUser } from '../../../Stores/User/Actions';
import { getUsersByGroup } from '../../../Stores/Users/Actions';
import UserStore from '../../../Stores/User/Store';

export default class LatestWorkOrders extends Component {
  constructor(props) {
    super(props);
    const user = UserStore.getUser();
    this.state = {
      user: user,
      salesPersons: null,
      open: true,
      startDate: moment().format('YYYY/MM/DD'),
      endDate: moment().format('YYYY/MM/DD'),
      filterByDate: false,
      dateFilterType: 'Due',
      infoOpen: false,
      anchorEl: null,
      anchorElFilter: null,
      orderProcessors: null,
      printDisabled: false,
      notificationStyles: {
        Accounting: '#43a047',
        Success: '#43a047',
        Sales: '#ffa000',
        Warning: '#ffa000',
        Error: '#d32f2f',
        Info: '#3f51b5',
        Production: '#979797',
        File: '#979797',
        Temp: '#c7c7c7',
      },
      tableRef: React.createRef(),
      isLoading: false,
      currentPage: user.currentPage,
      firstQuery: true,
      checkpointCheckedFilter: [],
      salesFilter: '',
      csrFilter: '',
      checkpointUncheckedFilter: [],
      advancedCheckBoxData: [],
      snackBar: { open: false, message: '', variant: '' },
      searchTerm: '',
      columns: [
        {
          title: 'ID',
          field: 'workOrderID',
          // type: 'numeric'
        },
        {
          title: 'Status',
          sorting: false,
          render: (rowData) => {
            const date = new Date();
            const dueDate = new Date(rowData.dueDate);
            date.setHours(0, 0, 0, 0);
            dueDate.setHours(0, 0, 0, 0);
            if (rowData.onHold) {
              return <div style={{ color: 'orange' }}>On Hold</div>;
            }
            if (rowData.canceled) {
              return <div>Canceled</div>;
            }
            if (rowData.misprint && !rowData.productionComplete) {
              return <div style={{ color: 'red' }}>REPRINT</div>;
            }
            if (date > dueDate && !rowData.productionComplete) {
              return <div style={{ color: 'red' }}>Overdue</div>;
            }
            if (date.getTime() === dueDate.getTime() && !rowData.productionComplete) {
              return <div>Due Today</div>;
            }
            if (rowData.productionComplete) {
              return <div>Production Complete</div>;
            }
          },
        },
        {
          title: 'Due Date',
          field: 'dueDate',
          searchable: false,
          render: (rowData) => {
            return <div>{this.whichMonth(rowData.dueDate)}</div>;
          },
        },
        { title: 'Customer', field: 'customerName' },
        { title: 'Description', field: 'description' },
        { title: 'Prints', field: 'quantity' },
        { title: 'Ship', field: 'shipMethod' },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.refresh !== this.props.refresh) {
      this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
    }
  }

  async componentDidMount() {
    UserStore.on('update', this.setUser);
    let searchTerm = '';
    if (this.props.location.search.length) {
      searchTerm = decodeURIComponent(this.props.location.search.substring(1));
      this.setState({
        searchTerm,
      });

      window.history.pushState({}, document.title, '/');
    }
    const userCustom = this.state.user;
    const salesPersons = await getUsersByGroup('Sales');
    console.log(salesPersons);
    const orderProcessors = await getUsersByGroup('Order Processor');
    this.setState({
      salesPersons,
      orderProcessors,
      csrFilter: userCustom.csrFilter,
      salesFilter: userCustom.salesFilter,
      checkpointCheckedFilter: userCustom.filter,
      checkpointUncheckedFilter: userCustom.uncheckedFilter,
      advancedCheckBoxData: [
        {
          id: 1,
          name: 'Heat Pressed',
          isChecked: !!userCustom.heatPressFilter,
        },
        {
          id: 2,
          name: 'Fold and Poly',
          isChecked: !!userCustom.foldAndPolyFilter,
        },
      ],
    });
  }

  setUser = () => {
    const newUser = UserStore.getUser();
    this.setState({ user: newUser });
    this.updateTable();
  };

  updateTable = () => {
    this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
  };

  changeDateFilterType = (dateType) => {
    this.setState(
      {
        dateFilterType: dateType,
      },
      () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange(),
    );
  };

  handleDatePickerChange = (date, start) => {
    if (start) {
      this.setState(
        { startDate: moment(date).format('YYYY/MM/DD') },
        () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange(),
      );
    } else {
      this.setState({ endDate: moment(date).format('YYYY/MM/DD') }, () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange());
    }
  };

  handleFilterByDateChange = () => {
    this.setState(
      {
        filterByDate: !this.state.filterByDate,
      },
      () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange(),
    );
  };

  openInfo = (event) => {
    const open = !this.state.infoOpen;
    const anchorElThing = open ? event.target : null;
    this.setState({
      infoOpen: open,
      anchorEl: anchorElThing,
    });
  };

  handleCheckBoxChange = async (id) => {
    let checkedID;
    let checked;
    const advancedCheckBoxData = this.state.advancedCheckBoxData.map((x) => {
      if (x.id === id) {
        x.isChecked = !x.isChecked;
        checked = x.isChecked;
        checkedID = x.id;
      }
      return x;
    });

    this.setState(
      {
        advancedCheckBoxData,
        searchTerm: '',
      },
      async () => {
        if (checkedID === 1) {
          await this.updateHeatPressFilter(checked);
        }
        if (checkedID === 2) {
          await this.updateFoldAndPolyFilter(checked);
        }
      },
    );
  };

  updateHeatPressFilter = async (filter) => {
    updateUser(this.state.user.uid, { heatPressFilter: filter });
  };

  updateFoldAndPolyFilter = async (filter) => {
    updateUser(this.state.user.uid, { foldAndPolyFilter: filter });
  };

  clearAllFilters = async () => {
    const advancedCheckBoxData = this.state.advancedCheckBoxData.map((x) => {
      x.isChecked = false;
      return x;
    });
    await this.setState({
      advancedCheckBoxData,
      searchTerm: '',
      csrFilter: '',
      salesFilter: '',
      filterByDate: false,
    });

    await this.filterBySalesPerson('');
    await this.filterByCSR('');
    await this.updateFoldAndPolyFilter(0);
    await this.updateHeatPressFilter(0);

    this.loadDashBoardFilter([], []);
  };

  filterBySalesPerson = (salesFilter) => {
    if (salesFilter === this.state.salesFilter) salesFilter = '';
    updateUser(this.state.user.uid, { salesFilter });
    this.setState({
      salesFilter: this.state.salesFilter === salesFilter ? '' : salesFilter,
      searchTerm: '',
    });
  };

  filterByCSR = (csrFilter) => {
    if (csrFilter === this.state.csrFilter) csrFilter = '';
    updateUser(this.state.user.uid, { csrFilter });
    this.setState({
      csrFilter: this.state.csrFilter === csrFilter ? '' : csrFilter,
      searchTerm: '',
    });
  };

  getAllWorkOrders = async (page, pageSize, orderBy, orderDirection) => {
    if (!orderBy) {
      if (this.state.user.orderBy) {
        orderBy = { field: this.state.user.orderBy };
        orderDirection = this.state.user.orderDirection;
      } else {
        orderBy = { field: 'dueDate' };
        orderDirection = 'asc';
      }
    }
    if (orderBy.field !== this.state.user.orderBy || orderDirection !== this.state.user.orderDirection) {
      updateUser(this.state.user.uid, { orderBy: orderBy.field, orderDirection });
    } else {
      const workOrderParams = [
        { name: 'uid', value: this.state.user.uid },
        { name: 'page', value: page },
        { name: 'pageSize', value: pageSize },
        { name: 'orderBy', value: orderBy.field },
        { name: 'orderDirection', value: orderDirection },
        { name: 'search', value: this.state.searchTerm },
        { name: 'filterByDate', value: this.state.filterByDate },
        { name: 'startDate', value: this.state.startDate },
        { name: 'endDate', value: this.state.endDate },
        { name: 'dateFilterType', value: this.state.dateFilterType },
      ];
      return await this.props.get('/getWorkOrdersNew', workOrderParams).then(({ results, total }) => {
        if (results && results.pageSize === 'reset') {
          this.setState({
            currentPage: 0,
          });
        }
        return { results, total };
      });
    }
  };

  createSnack = (variant, message) => {
    const snackBar = { open: true, variant, message };
    this.setState({
      snackBar,
    });
  };

  updateSearch = async (event) => {
    const searchTerm = event.target.value;
    await this.setState({
      searchTerm,
    });
  };

  clearSearch = () => {
    this.setState({ searchTerm: '' }, () => {
      this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
    });
  };

  handleClose = () => {
    const snackBar = { open: false, message: '', variant: '' };
    this.setState({
      snackBar,
    });
  };

  handleFilterClose = () => {
    this.setState({
      anchorElFilter: null,
    });
  };

  handleFilterClick = (e) => {
    this.setState({
      anchorElFilter: e.currentTarget,
    });
  };

  whichMonth = (rawDate) => {
    const date = new Date(rawDate);
    const index = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[index];
    return `${month} ${day}, ${year}`;
  };

  filterByUncheckedCheckpoint = async (event) => {
    let checkpointUncheckedFilter;
    if (!event) {
      checkpointUncheckedFilter = this.state.checkpointUncheckedFilter;
    } else {
      checkpointUncheckedFilter = event.target.value;
    }
    this.setState({
      checkpointUncheckedFilter,
    });
    const uncheckedFilter = JSON.stringify(checkpointUncheckedFilter);
    updateUser(this.state.user.uid, { uncheckedFilter });
  };

  filterByCheckpoint = async (event) => {
    let checkpointCheckedFilter;
    if (!event) {
      checkpointCheckedFilter = this.state.checkpointCheckedFilter;
    } else {
      checkpointCheckedFilter = event.target.value;
    }
    this.setState({
      checkpointCheckedFilter,
    });
    const filter = JSON.stringify(checkpointCheckedFilter);
    updateUser(this.state.user.uid, { filter });
  };

  loadDashBoardFilter = (completedFilter, uncompletedFilter) => {
    const checkpointUncheckedFilter = [];
    const checkpointCheckedFilter = [];
    for (const filter of completedFilter) {
      checkpointCheckedFilter.push(filter.filterValue);
    }
    for (const filter of uncompletedFilter) {
      checkpointUncheckedFilter.push(filter.filterValue);
    }
    this.setState(
      {
        checkpointCheckedFilter,
        checkpointUncheckedFilter,
        searchTerm: '',
      },
      async () => {
        await this.filterByCheckpoint();
        await this.filterByUncheckedCheckpoint();
        this.loadFromFilters();
      },
    );
  };

  updateCurrentPage = (currentPage) => {
    this.props.post('/updateCurrentPage', { uid: this.state.user.uid, currentPage }).catch((err) => {
      this.createSnack('Error', 'Could not update current page');
    });
  };

  loadFromFilters = () => {
    this.updateCurrentPage(0);
    this.setState(
      {
        searchTerm: '',
      },
      () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange(),
    );
  };

  render() {
    const {
      state: {
        notificationStyles,
        firstQuery,
        currentPage,
        searchTerm,
        snackBar,
        tableRef,
        columns,
        salesPersons,
        orderProcessors,
        salesFilter,
        csrFilter,
        advancedCheckBoxData,
        isLoading,
        open,
        infoOpen,
        anchorEl,
        startDate,
        endDate,
        filterByDate,
        dateFilterType,
        anchorElFilter,
      },
      props: { user },
      filterByCSR,
      filterBySalesPerson,
      openInfo,
      handleDatePickerChange,
      handleFilterByDateChange,
      changeDateFilterType,
    } = this;
    return (
      <>
        <Grid item xs={12} id="workOrders">
          <Card>
            <CardHeader
              title={
                <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5">Work Orders</Typography>
                  <IconButton size="small" onClick={() => this.setState({ open: !open })}>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
              }
              action={
                <>
                  {this.state.printDisabled && <LinearProgress variant={'indeterminate'} />}
                  <Button
                    style={{ marginTop: '8px', marginRight: '8px' }}
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                    disabled={this.state.printDisabled}
                    onClick={() => {
                      this.setState({
                        printDisabled: true,
                      });
                      get('/workorder/export')
                        .then(({ url }) => {
                          this.setState({
                            printDisabled: false,
                          });
                          window.open(url, '_blank');
                        })
                        .catch((err) => {
                          this.setState({
                            printDisabled: false,
                          });
                        });
                    }}
                  >
                    Print
                  </Button>
                  <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                    <InfoIcon />
                  </IconButton>
                  <Popover
                    open={infoOpen}
                    anchorEl={anchorEl}
                    onClose={openInfo}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                      This Card will allow you to view and filter Work Orders.
                      <br />
                      <br />
                      To search, enter your search term in the search field, then either press enter or tap/click the search icon.
                      <br />
                      <br />
                      You can also apply filters to limit your selection. Apply all filter thats you wish. When a filter is applied, the tab will be
                      green to indicate that you have a filter selected in that tab. Press 'Clear All Filters' if you wish to cancel all filters and
                      see all Work Orders again.
                    </Typography>
                  </Popover>
                  <FormControl>
                    <Input
                      className="search-work-orders-input"
                      style={{ marginTop: '6px' }}
                      value={searchTerm}
                      onChange={this.updateSearch}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
                        }
                      }}
                      startAdornment={
                        <InputAdornment>
                          <IconButton
                            onClick={() => {
                              this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
                            }}
                            size="small"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={this.clearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </>
              }
            />
            <Divider />
            <Collapse in={open} timeout="auto">
              <CardContent style={{ paddingBottom: '16px' }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4} className="filter-area-container">
                    <Filters
                      user={this.state.user}
                      completedFilters={this.state.checkpointCheckedFilter}
                      uncompletedFilters={this.state.checkpointUncheckedFilter}
                      loadDashBoardFilter={this.loadDashBoardFilter}
                      get={this.props.get}
                      post={this.props.post}
                      salesPersons={salesPersons}
                      orderProcessors={orderProcessors}
                      salesFilter={salesFilter}
                      salesOnClick={filterBySalesPerson}
                      csrOnClick={filterByCSR}
                      csrFilter={csrFilter}
                      advancedCheckBoxData={advancedCheckBoxData}
                      handleCheckBoxChange={this.handleCheckBoxChange}
                      clearAllFilters={this.clearAllFilters}
                      isLoading={isLoading}
                      startDate={startDate}
                      endDate={endDate}
                      handleDatePickerChange={handleDatePickerChange}
                      filterByDate={filterByDate}
                      handleFilterByDateChange={handleFilterByDateChange}
                      dateFilterType={dateFilterType}
                      changeDateFilterType={changeDateFilterType}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className="filter-area-container-mobile" style={{ paddingBottom: '0' }}>
                    <Button color="primary" size="small" onClick={this.handleFilterClick}>
                      Filters <ExpandMoreIcon />
                    </Button>
                    <Popover
                      open={Boolean(anchorElFilter)}
                      anchorEl={anchorElFilter}
                      onClose={this.handleFilterClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className="filter-popover"
                    >
                      <Filters
                        user={this.state.user}
                        completedFilters={this.state.checkpointCheckedFilter}
                        uncompletedFilters={this.state.checkpointUncheckedFilter}
                        loadDashBoardFilter={this.loadDashBoardFilter}
                        get={this.props.get}
                        post={this.props.post}
                        salesPersons={salesPersons}
                        orderProcessors={orderProcessors}
                        salesFilter={salesFilter}
                        salesOnClick={filterBySalesPerson}
                        csrOnClick={filterByCSR}
                        csrFilter={csrFilter}
                        advancedCheckBoxData={advancedCheckBoxData}
                        handleCheckBoxChange={this.handleCheckBoxChange}
                        clearAllFilters={this.clearAllFilters}
                        isLoading={isLoading}
                        startDate={startDate}
                        endDate={endDate}
                        handleDatePickerChange={handleDatePickerChange}
                        filterByDate={filterByDate}
                        handleFilterByDateChange={handleFilterByDateChange}
                        dateFilterType={dateFilterType}
                        changeDateFilterType={changeDateFilterType}
                      />
                    </Popover>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <div className="mini-work-orders-container">
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={snackBar.open}
                        autoHideDuration={3000}
                        onClose={this.handleClose}
                      >
                        <SnackbarContent
                          onClose={this.handleClose}
                          style={{
                            backgroundColor: notificationStyles[snackBar.variant],
                          }}
                          message={snackBar.message}
                        />
                      </Snackbar>
                      {user && (
                        <>
                          <MaterialTable
                            tableRef={tableRef}
                            onChangePage={(currentPage) => {
                              this.updateCurrentPage(currentPage);
                            }}
                            components={{
                              Container: (props) => <div style={{ background: 'white' }}>{props.children}</div>,
                            }}
                            columns={columns}
                            data={async (query) => {
                              let { page, pageSize, orderBy, orderDirection } = query;
                              this.setState({ isLoading: true });
                              if (firstQuery) {
                                page = currentPage;
                                this.setState({
                                  firstQuery: false,
                                });
                              }
                              let workOrders = [];
                              workOrders = await this.getAllWorkOrders(page, pageSize, orderBy, orderDirection);
                              if (page > workOrders.results / pageSize) {
                                page = 0;
                                workOrders = await this.getAllWorkOrders(page, pageSize, orderBy, orderDirection);
                              }
                              this.setState({ isLoading: false });
                              return {
                                data: workOrders.results,
                                page,
                                totalCount: workOrders.total,
                              };
                            }}
                            options={{
                              search: false,
                              showTitle: false,
                              toolbar: false,
                              padding: 'dense',
                              pageSizeOptions: [15],
                              pageSize: 15,
                              rowStyle: {
                                fontSize: 14,
                              },
                              loadingType: 'linear',
                              headerStyle: {
                                padding: '6px 16px',
                              },
                            }}
                            // TODO viewProductionWorkOrder viewArtWorkOrder viewWorkOrder
                            onRowClick={(event, rowData) => {
                              user.group.includes('Production') &&
                              !user.group.includes('Fulfillment') &&
                              !user.group.includes('Admin') &&
                              !user.group.includes('Art')
                                ? window.open(`/production/workorder/${rowData.id}`, '_blank')
                                : user.group === 'Production - Art'
                                ? window.open(`/art/workorder/${rowData.id}`)
                                : window.open(`/workorder/${rowData.id}`, '_blank');
                            }}
                          />
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </>
    );
  }
}
