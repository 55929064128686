/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
import React from 'react';
import { Grid, Card, CardContent, CardActions, IconButton } from '@material-ui/core';
import { Delete, Fullscreen } from '@material-ui/icons';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { post } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';

const MockUpDetails = ({ mockUps, setMockUps, openModal }) => {
  const { WorkOrderId } = useParams();
  const addMockup = async (files) => {
    for (const file of files) {
      console.log(file);
      const newMockUp = {
        workOrderID: WorkOrderId,
        url: file.url,
      };
      await post('/newMockUp', newMockUp)
        .then((results) => {
          const localMockups = [...mockUps];
          localMockups.push({ ...newMockUp, id: results.insertId });
          setMockUps(localMockups);
          createSnack('Success', 'Artwork Uploaded');
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const deleteMockUp = (mockUp) => {
    const localMockUps = [...mockUps];
    const index = localMockUps.indexOf(mockUp);
    const { id } = mockUp;
    post('/deleteMockUp', { id })
      .then((results) => {
        if (results.affectedRows) {
          localMockUps.splice(index, 1);
          setMockUps(localMockUps);
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <div>
      <DropZone autoUpload={true} type={'Work Order Art'} handleDrop={addMockup} id="mockup-dropzone" workOrderID={WorkOrderId} />
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Grid container spacing={1} justify="center">
            {mockUps.map((mockUp, key) => (
              <Grid item key={key}>
                <Card
                  style={{
                    height: '850px',
                  }}
                >
                  <CardContent>
                    <iframe
                      src={mockUp.url}
                      style={{
                        width: '700px',
                        height: '750px',
                      }}
                    />
                    <CardActions style={{ float: 'right' }}>
                      <IconButton color="secondary" onClick={() => openModal({ url: mockUp.url })}>
                        <Fullscreen />
                      </IconButton>
                      <IconButton onClick={() => deleteMockUp(mockUp)}>
                        <Delete />
                      </IconButton>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MockUpDetails.propTypes = {
  mockUps: PropTypes.array,
  setMockUps: PropTypes.func,
  openModal: PropTypes.func,
};

MockUpDetails.displayName = 'MockUpDetails';

export default MockUpDetails;
