import dispatcher from './dispatcher';
import { get, patch, post } from '../../../Functions/API';
import { createSnack } from '../../Snack/Actions';
import StoresStore from './Store';

export const fetchStores = async () => {
  const currentStores = StoresStore.getStores();
  if (currentStores?.length) {
    return currentStores;
  } else if (StoresStore.isFetching()) {
    const results = await StoresStore.getPromise();
    return results;
  } else {
    try {
      const promise = get(`/stores`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        promise,
      });
      const { results } = await promise;
      dispatcher.dispatch({
        type: 'LOAD_STORES',
        results,
      });
      return results;
    } catch (err) {
      console.error(err);
      createSnack('Error', 'Problem loading stores');
      return currentStores;
    }
  }
};

export const loadStores = async () => {
  try {
    const { results } = await get('/stores');
    dispatcher.dispatch({
      type: 'LOAD_STORES',
      results,
    });
  } catch (err) {
    console.log(err);
    createSnack('Error', err ? err.message : 'Error, check logs for details');
  }
};

export const addStore = (name, customerId, url, secretKey, apiKey, salesmanId) => {
  post(`/stores`, { name, customerId, url, secretKey, apiKey, salesmanId, storeTypeId: 1 })
    .then(loadStores)
    .catch((err) => {
      createSnack('Error', err.message);
      console.error(err);
    });
};

export const updateStore = async (storeId, updateObj) => {
  await patch(`/stores/${storeId}`, updateObj)
    .then((results) => {
      dispatcher.dispatch({
        type: 'STORE_UPDATE',
        updatedStore: results,
      });
    })
    .catch((err) => {
      console.error(err);
      createSnack('Error', err ? err?.message : 'An Error Occurred');
    });
};
