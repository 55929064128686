/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Grid, Button } from '@material-ui/core';

export default class CatalogFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
    };
  }

  componentDidMount() {
    this.props
      .get('/catalogFeatures', { styleId: this.props.product.styleId }, 'obj')
      .then((features) => {
        this.setState({
          features,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { features } = this.state;
    const { row, product, close } = this.props;
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'rgb(234, 234, 234)',
          borderRadius: '6px',
          color: 'black',
          minWidth: '600px',
          padding: '15px',
        }}
      >
        <div>
          <Grid container justify="flex-start">
            <Grid item style={{ flexGrow: 1 }}>
              <h2>Features from Catalog</h2>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => close(row, product)}>
                Close
              </Button>
            </Grid>
          </Grid>
        </div>
        <MaterialTable
          actions={[
            {
              icon: 'add',
              tooltip: 'Add',
              onClick: (e, rowData) => {
                this.props.newFeature(row, product, rowData.feature);
              },
            },
          ]}
          columns={[{ title: 'Feature', field: 'feature' }]}
          data={features}
          options={{ search: false, paging: false, toolbar: false }}
        />
      </div>
    );
  }
}
