import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    (this.orderInfo = {
      data: [],
      options: {
        type: [
          { name: 'T Shirt', value: 'tshirt' },
          { name: 'Shorts', value: 'shorts' },
          { name: 'Socks', value: 'socks' },
          { name: 'Jersey', value: 'jersey' },
          { name: 'Reversible', value: 'reversible' },
        ],
      },
      count: null,
    }),
      (this.numOfLines = []);
  }

  getOrders() {
    return this.orderInfo ? this.orderInfo.data : null;
  }

  getCount() {
    return this.orderInfo ? this.orderInfo.count : null;
  }

  getOptions() {
    return this.orderInfo ? this.orderInfo.options : null;
  }

  getInfo() {
    return this.orderInfo;
  }

  getNumOfLines() {
    return this.numOfLines;
  }

  loadNumOfLines = (numOfLines) => {
    this.numOfLines = numOfLines;
    this.emit('numOfLinesChange');
  };

  updateNumOfLines = (index, newLine) => {
    this.numOfLines[index] = newLine;
    this.emit('numOfLinesChange');
  };

  loadOrders = (results) => {
    this.orderInfo = results;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_ORDERS': {
        this.loadOrders(action.results);
        this.loadNumOfLines(action.results.data);
        break;
      }
      case 'UPDATE_NUMOFLINES': {
        this.updateNumOfLines(action.indes, action.newLine);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
