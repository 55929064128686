import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { get, patch } from '../../../../../Functions/API';
import IconButton from '@material-ui/core/IconButton';
import { Save } from '@material-ui/icons';

const Printers = ({ workOrder }) => {
  const [availablePrinters, setAvailablePrinters] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [savedPrinters, setSavedPrinters] = useState([]);

  useEffect(() => {
    if (workOrder.id) {
      get('/printers', { workOrderID: workOrder.id }, 'obj')
        .then((results) => {
          setPrinters(results.map((p) => p.uid));
          setSavedPrinters(results.map((p) => p.uid));
        })
        .catch((err) => {
          console.error(err.message);
        });
      get('/user/group', { group: 'Production - Printer' }, 'obj')
        .then((results) => {
          setAvailablePrinters(results);
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [workOrder.id]);

  const checkPrinters = (a = printers, b = savedPrinters) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (!b.find((c) => c === a[i])) return false;
    }
    return true;
  };

  const updatePrinters = () => {
    patch(`/printers/${workOrder.id}`, printers).then((results) => {
      setSavedPrinters(results.map((p) => p.uid));
    });
  };

  return (
    <FormControl style={{ maxWidth: '100%' }} component="div">
      <InputLabel shrink htmlFor="printers">
        Printers
      </InputLabel>
      <Select
        onChange={(e) => setPrinters(e.target.value)}
        multiple
        value={printers}
        renderValue={(selected) => {
          const returnValue = [];
          for (const uid of selected) {
            const printer = availablePrinters.find((printer) => uid === printer.uid);
            if (printer) {
              returnValue.push(`${printer.firstName} ${printer.lastName}`);
            }
          }
          return returnValue.join(', ');
        }}
        input={
          <Input
            name="printers"
            id="printers"
            error={!checkPrinters()}
            startAdornment={
              !checkPrinters() && (
                <IconButton size={'small'} onClick={updatePrinters}>
                  <Save fontSize={'small'} color={'secondary'} />
                </IconButton>
              )
            }
          />
        }
      >
        {availablePrinters.map((printer, key) => (
          <MenuItem key={key} value={printer.uid}>
            <ListItemText primary={`${printer.firstName} ${printer.lastName}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Printers;

Printers.propTypes = {
  workOrder: PropTypes.object,
};
