import React, { useEffect, useState } from 'react';
import { get } from '../../../Functions/API';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { Add, Check, Error } from '@material-ui/icons';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { ProductPathsStore } from '../../../Stores';
import { updateLineItem } from '../../../Stores/Fulfillment/LineItems/Actions';
import { createSnack } from '../../../Stores/Snack/Actions';
import { createDialog } from '../../../Stores/Dialog/Actions';
import { fetchStatuses } from '../../../Stores/Statuses/Actions';
import { loadPaths } from '../../../Stores/ProductPaths/Actions';
import ErrorDialog from '../../../Components/Fulfillment/LineItems/ErrorDialog';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Art = () => {
  const [art, setArt] = useState([]);
  const { step } = useParams();
  const query = useQuery();
  const [paths, setPaths] = useState([]);
  const [steps, setSteps] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    ProductPathsStore.on('change', getPaths);
    loadPaths();
    fetchStatuses().then((results) => {
      setStatuses(results);
    });
    loadArt();
    return () => {
      ProductPathsStore.removeListener('change', getPaths);
    };
  }, []);

  const getPaths = () => {
    setPaths([...ProductPathsStore.getPaths()]);
  };

  const loadArt = () => {
    const storeId = query.get('storeId');
    let statusId;
    if (step === 'count') statusId = 51;
    if (step === 'print') statusId = 52;
    getStatusIds(statusId).then((steps) => {
      setSteps(steps);
      const stepIds = steps.map((step) => step.id);
      const params = { stepIds: JSON.stringify(stepIds) };
      if (storeId) params.storeId = storeId;
      get('/workOrderArt/openLineItems', params).then(({ results }) => {
        setArt(results);
      });
    });
  };

  const getStatusIds = (statusId) => {
    return get('/fulfillment/products/steps', { statusId });
  };

  const updateLine = (line) => {
    updateLineItem(line).then((results) => {
      loadArt();
      createSnack('Success', 'Successfully updated line item');
    });
  };

  const updateLines = async (lines) => {
    lines.map((line) => updateLineItem(line));
    loadArt();
    createSnack('Success', 'Successfully updated line items');
  };

  const errorLineItem = (line, lines) => {
    let currentStep = ProductPathsStore.findStep(line.productStepId);
    const currentSteps = [];
    let looping = true;
    while (looping) {
      const previousStep = ProductPathsStore.findPreviousStep(currentStep.id);
      if (previousStep) {
        currentSteps.push(previousStep);
        currentStep = previousStep;
      } else {
        looping = false;
      }
    }
    if (lines) line = null;
    createDialog({
      title: 'Error',
      content: <ErrorDialog statuses={statuses} line={line} lines={lines} steps={currentSteps} reload={loadArt} />,
      size: 'xl',
    });
  };

  const stepButtons = (line, all) => {
    let errorFunction, updateFunction;
    if (all) {
      errorFunction = () => errorLineItem(selected[0], selected);
      updateFunction = () => updateLines(selected);
    } else {
      errorFunction = () => errorLineItem(line);
      updateFunction = () => updateLine(line);
    }
    return (
      <>
        {step === 'print' && (
          <>
            <IconButton onClick={errorFunction}>
              <Error fontSize={'small'} />
            </IconButton>
            <IconButton onClick={updateFunction}>
              <PrintIcon fontSize={'small'} />
            </IconButton>
          </>
        )}
        {step === 'count' && (
          <>
            <IconButton onClick={errorFunction}>
              <Error fontSize={'small'} />
            </IconButton>
            <IconButton onClick={updateFunction}>
              <Check fontSize={'small'} />
            </IconButton>
          </>
        )}
      </>
    );
  };

  const select = (e, line) => {
    if (e.target.checked) {
      setSelected((s) => [...s, line]);
    } else {
      const s = [...selected];
      const index = selected.indexOf(line);
      s.splice(index, 1);
      setSelected(s);
    }
  };

  const selectAll = (e, art) => {
    if (e.target.checked) {
      const lineItems = art.ybaSkus.map((sku) => sku.lineItems.map((lI) => lI)).flat();
      setSelected(lineItems);
    } else {
      setSelected([]);
    }
  };

  return (
    <Grid style={{ padding: '10px' }} container spacing={1}>
      {art.map((a, key) => (
        <Grid item key={key} xs={12} sm={6} md={4} lg={3} xl={2}>
          <Card style={{ height: '100%' }}>
            <CardHeader title={a.name} />
            <CardMedia component="img" image={a.url} />
            <CardContent>
              <List dense>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <Checkbox size={'small'} onClick={(e) => selectAll(e, a)} />
                  </ListItemIcon>
                  <ListItemText primary={'Select All'} />
                  <ListItemSecondaryAction>{stepButtons(null, true)}</ListItemSecondaryAction>
                </ListItem>
                <Divider />
                {a.ybaSkus.map((sku) =>
                  sku.lineItems.map((line, key) => (
                    <ListItem key={key} style={{ paddingLeft: 0 }}>
                      <ListItemIcon>
                        <Checkbox size={'small'} onClick={(e) => select(e, line)} checked={selected.indexOf(line) > -1} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${sku.externalSku.color} ${sku.externalSku.style} ${sku.externalSku.size}`}
                        secondary={`${sku.externalSku.brand} - ${moment(line.order.createdAt).fromNow()}`}
                      />
                      <ListItemSecondaryAction>{stepButtons(line)}</ListItemSecondaryAction>
                    </ListItem>
                  )),
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Art;

Art.propTypes = {};
