import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.sales = [];
  }

  getSales() {
    return this.sales;
  }

  loadSales = (sales) => {
    this.sales = sales;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_SALES': {
        this.loadSales(action.sales);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
