/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, post } from '../../Functions/API.js';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import DropZone from '../../Components/WorkOrder/Footer/Components/Generate/DropZone.js';
import { createSnack } from '../../Stores/Snack/Actions.js';
import Papa from 'papaparse';
import MaterialTable from 'material-table';

const ShopifyInfoPlus = ({ uploadId }) => {
  const [lobs, setLobs] = useState([]);
  const [lob, setLob] = useState();

  useEffect(() => {
    get('/integrations/info-plus/lobs').then(setLobs);
  }, []);

  const dropFiles = async (files) => {
    const [file] = files;
    const csv = await new Promise((res, rej) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => res(results.data),
        error: (err) => rej(err),
      });
    });
    post(`/integrations/info-plus/lobs/${lob.id}/shopify`, {
      csv,
      uploadId,
      lobId: lob.id,
      warehouseId: 2174,
    }).then(() => {
      window.location.href = `/uploads/${uploadId}`;
    });
  };

  return (
    <div>
      <Autocomplete
        renderInput={(params) => (
          <TextField {...params} label="Customer" style={{ margin: '10px', width: '50%' }} margin="normal" component="div" variant="outlined" />
        )}
        onChange={(e, v) => setLob(v)}
        getOptionLabel={(o) => o.label}
        options={lobs}
      />
      {lob && (
        <>
          <DropZone handleDrop={dropFiles} filesLimit={1} />
        </>
      )}
    </div>
  );
};
export default ShopifyInfoPlus;

ShopifyInfoPlus.propTypes = {
  uploadId: PropTypes.number,
};
