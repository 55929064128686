exports.statuses = [
  {
    name: 'Active',
    id: 1,
    fulfillment: false,
  },
  {
    name: 'Disabled',
    id: 2,
    fulfillment: false,
  },
  {
    name: 'Pending',
    id: 3,
    fulfillment: true,
  },
  {
    name: 'Shipped',
    id: 4,
    fulfillment: true,
  },
  {
    name: 'Delivered',
    id: 5,
    fulfillment: true,
  },
  {
    name: 'Cancelled',
    id: 6,
    fulfillment: false,
  },
  {
    name: 'Approved',
    id: 7,
    fulfillment: true,
  },
  {
    name: 'Completed',
    id: 8,
    fulfillment: false,
  },
  {
    name: 'Unread',
    id: 9,
    fulfillment: false,
  },
  {
    name: 'Read',
    id: 10,
    fulfillment: false,
  },
  {
    name: 'Accepted',
    id: 11,
    fulfillment: true,
  },
  {
    name: 'Sent',
    id: 12,
    fulfillment: false,
  },
  {
    name: 'Picked up by the customer',
    id: 13,
    fulfillment: false,
  },
  {
    name: 'Sign by customer',
    id: 14,
    fulfillment: false,
  },
  {
    name: 'Available for pickup',
    id: 15,
    fulfillment: false,
  },
  {
    name: 'Exception',
    id: 16,
    fulfillment: false,
  },
  {
    name: 'Customer moved',
    id: 17,
    fulfillment: false,
  },
  {
    name: 'Customer refused delivery',
    id: 18,
    fulfillment: false,
  },
  {
    name: 'Delayed (Customs clearance)',
    id: 19,
    fulfillment: false,
  },
  {
    name: 'Delayed (External factors)',
    id: 20,
    fulfillment: false,
  },
  {
    name: 'Held for payment',
    id: 21,
    fulfillment: false,
  },
  {
    name: 'Incorrect Address',
    id: 22,
    fulfillment: false,
  },
  {
    name: 'Pick up missed',
    id: 23,
    fulfillment: false,
  },
  {
    name: 'Rejected by carrier',
    id: 24,
    fulfillment: false,
  },
  {
    name: 'Returning to sender',
    id: 25,
    fulfillment: false,
  },
  {
    name: 'Returned to sender',
    id: 26,
    fulfillment: false,
  },
  {
    name: 'Shipment damaged',
    id: 27,
    fulfillment: false,
  },
  {
    name: 'Shipment lost',
    id: 28,
    fulfillment: false,
  },
  {
    name: 'Failed Attempt',
    id: 29,
    fulfillment: false,
  },
  {
    name: 'Addressee not available',
    id: 30,
    fulfillment: false,
  },
  {
    name: 'Business Closed',
    id: 31,
    fulfillment: false,
  },
  {
    name: 'In Transit',
    id: 32,
    fulfillment: false,
  },
  {
    name: 'Acceptance scan',
    id: 33,
    fulfillment: false,
  },
  {
    name: 'Arrival scan',
    id: 34,
    fulfillment: false,
  },
  {
    name: 'Arrived at the destination country',
    id: 35,
    fulfillment: false,
  },
  {
    name: 'Customs clearance completed',
    id: 36,
    fulfillment: false,
  },
  {
    name: 'Customs clearance started',
    id: 37,
    fulfillment: false,
  },
  {
    name: 'Departure Scan',
    id: 38,
    fulfillment: false,
  },
  {
    name: 'Problem resolved',
    id: 39,
    fulfillment: false,
  },
  {
    name: 'Forwarded to a different delivery address',
    id: 40,
    fulfillment: false,
  },
  {
    name: 'Info Received',
    id: 41,
    fulfillment: false,
  },
  {
    name: 'Out for Delivery',
    id: 42,
    fulfillment: false,
  },
  {
    name: 'Customer contacted',
    id: 43,
    fulfillment: false,
  },
  {
    name: 'Delivery appointment scheduled',
    id: 44,
    fulfillment: false,
  },
  {
    name: 'Expired',
    id: 45,
    fulfillment: false,
  },
  {
    name: 'Deleted',
    id: 46,
    fulfillment: false,
  },
  {
    name: 'Error',
    id: 47,
    fulfillment: false,
  },
  {
    name: 'In Production',
    id: 48,
    fulfillment: false,
  },
  {
    name: 'In Checking',
    id: 49,
    fulfillment: false,
  },
  {
    name: 'Pulled',
    id: 50,
    fulfillment: true,
  },
  {
    name: 'Counted',
    id: 51,
    fulfillment: true,
  },
  {
    name: 'Printed',
    id: 52,
    fulfillment: true,
  },
  {
    name: 'Billed',
    id: 53,
    fulfillment: true,
  },
  {
    name: 'Ordered',
    id: 54,
    fulfillment: true,
  },
  {
    name: 'Ready to Ship',
    id: 55,
    fulfillment: true,
  },
];
