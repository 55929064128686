/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Paper, TableHead } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/CheckBox';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CheckBox from '@material-ui/core/Checkbox';
import Menu from '../../../Components/Menu';
import UserTimesheet from '../../../Components/WorkOrder/Footer/Components/History/UserTimesheet';
import UserWorkHistory from '../../../Components/WorkOrder/Footer/Components/History/UserWorkHistory';
import Summary from '../../../Components/Accounting/Summary';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      allUsers: [],
      user: {},
    };
  }

  componentDidMount() {
    this.props.get('/getUser', [{ name: 'uid', value: this.props.match.params.id }]).then((usersGet) => {
      this.setState({
        id: this.props.match.params.id,
        user: usersGet,
      });
    });
  }

  render() {
    console.log(this.state.user.uid);
    return (
      <div>
        {this.state.user.firstName && (
          <div>
            <div>
              <UserWorkHistory
                id={this.props.id}
                userID={this.state.user.uid}
                workOrder={this.props.workOrder}
                currentUser={this.state.user}
                homepage={this.props.homepage}
                get={this.props.get}
                post={this.props.post}
                updateCostComplete={this.props.updateCostComplete}
                user={this.props.user}
                updateAccountingApproved={this.props.updateAccountingApproved}
                createSnack={this.props.createSnack}
                incrementActive={this.props.incrementActive}
              />
            </div>
            <div>
              <UserTimesheet
                id={this.props.id}
                userID={this.state.user.uid}
                workOrder={this.props.workOrder}
                currentUser={this.state.user}
                homepage={this.props.homepage}
                get={this.props.get}
                post={this.props.post}
                updateCostComplete={this.props.updateCostComplete}
                user={this.props.user}
                updateAccountingApproved={this.props.updateAccountingApproved}
                createSnack={this.props.createSnack}
                incrementActive={this.props.incrementActive}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default User;
