import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
  }

  forwardNotification = (data) => {
    this.emit('notification', data);
  };

  forwardData = (data) => {
    console.log('forwarding data message', data);
    this.emit(data.route, data);
  };

  handleActions(action) {
    switch (action.type) {
      case 'NOTIFICATION': {
        this.forwardNotification(action.data);
        break;
      }
      case 'DATA': {
        this.forwardData(action.data);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
