/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import steve from '../../../images/steve.png';
import '../../Utils/styles/AccountingReport.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  title: {
    fontWeight: 700,
  },
}));

const Totaldata = ({ get, startPage, refresh }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
      getAccountingReport();
    }
  };

  const getAccountingReport = () => {
    get('/runStevesReport', [])
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAccountingReport();
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    };
  }, [refresh]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <div className="accounting-report-header">
          <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
            ACCOUNTING REPORT
          </Typography>
          <img src={steve} className="accounting-report-steve" />
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxHeight: '144px',
            overflow: 'auto',
          }}
        >
          {data === null ? (
            <>
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
              <Skeleton variant="rect" width={60} height={30} style={{ margin: '10px' }} />
            </>
          ) : (
            <>
              {data.length ? (
                data.map((x) => {
                  return (
                    <Button key={x.id} style={{ margin: '6px' }} href={`/workorder/${x.id}`} target="_blank" variant="contained" color="primary">
                      {x.workOrderID}
                    </Button>
                  );
                })
              ) : (
                <>
                  <Typography variant="h4">All Tasks Complete!</Typography>
                </>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default Totaldata;
