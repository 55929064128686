import { get } from '../../../Functions/API';

export const checkQBURL = (err) => {
  if (err?.type === 'QB Redirect') {
    window.open(err.detail, '_blank');
    throw err;
  }
  throw err;
};

export const checkQBAuth = () => {
  return get('/quickbooks/auth').catch(checkQBURL);
};
