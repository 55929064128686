/* eslint react/no-array-index-key:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-alert:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Button } from '@material-ui/core';
import { get } from '../../../../../Functions/API';

export default class ErrorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      errorOptions: [],
      resolutionOptions: [],
      allError: '',
      allResolution: '',
      allPrinted: false,
    };
  }

  componentDidMount() {
    get('/getSizesErrors', { sizesIDs: this.props.size.sizesIDs }, 'obj')
      .then((errors) => {
        console.log(errors);
        this.setState({
          errors,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
    get('/getErrorOptions')
      .then((errorOptions) => {
        this.setState({
          errorOptions,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
    get('/getResolutionOptions')
      .then((resolutionOptions) => {
        this.setState({
          resolutionOptions,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%', backgroundColor: 'white', outline: 'none' }}>
        <MaterialTable
          title="Errors"
          components={{
            Toolbar: (props) => {
              return (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ margin: '10px' }}>
                    <Grid container justify="flex-start">
                      <Grid item style={{ flexGrow: 1 }}>
                        Expected {this.props.size.quantity} got {this.props.size.packageNum}
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={this.props.close}>
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  {this.props.size.quantity - this.props.size.packageNum !== this.state.errors.filter((row) => row.cspResolutionId === 1).length &&
                    this.props.showEdit && (
                      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <div>
                          Please resolve{' '}
                          {-1 *
                            (this.props.size.quantity -
                              this.props.size.packageNum -
                              this.state.errors.filter((row) => row.cspResolutionId === 1).length)}{' '}
                          {this.props.size.quantity -
                            this.props.size.packageNum -
                            this.state.errors.filter((row) => row.cspResolutionId === 1).length <
                          0
                            ? 'errors '
                            : 'error '}
                          that{' '}
                          {this.props.size.quantity -
                            this.props.size.packageNum -
                            this.state.errors.filter((row) => row.cspResolutionId === 1).length <
                          0
                            ? 'have '
                            : 'has '}{' '}
                          now been solved
                        </div>
                      </div>
                    )}
                </div>
              );
            },
          }}
          columns={[
            // {title: 'ID', field: 'id'},
            {
              title: 'Error',
              field: 'cspErrorId',
              render: (rowData) => (
                <Select value={rowData.cspErrorId}>
                  {this.state.errorOptions.map((error, key) => (
                    <MenuItem value={error.id} key={key}>
                      {error.error}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
            {
              title: 'Printed',
              field: 'printed',
              render: (rowData) => <Checkbox checked={rowData.printed} value={rowData.printed} />,
            },
            {
              title: 'Resolution',
              field: 'cspResolutionId',
              render: (rowData) => (
                <Select value={rowData.cspResolutionId}>
                  {this.state.resolutionOptions.map((resolution, key) => (
                    <MenuItem value={resolution.id} key={key}>
                      {resolution.resolution}
                    </MenuItem>
                  ))}
                </Select>
              ),
            },
          ]}
          data={this.state.errors}
          options={{ search: false, paging: false, maxBodyHeight: '600px' }}
        />
      </div>
    );
  }
}
