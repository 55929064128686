/* eslint no-alert:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';

const WhatsNewDetail = ({ get, post, featureId }) => {
  const [data, setData] = useState([]);

  const columns = [{ field: 'detail', title: 'Detail', type: 'text', cellStyle: { width: '25%' }, headerStyle: { fontSize: '10' } }];

  const getNewDetailsByFeature = () => {
    if (featureId) {
      get('/getNewDetailsByFeature', { featureId }, 'obj').then((res) => setData(res));
    }
  };

  useEffect(() => {
    getNewDetailsByFeature();
  }, []);

  const addDetail = (detail) => {
    const newData = { ...detail, featureId };
    post('/addFeatureDetail', { newData }).then((results) => {
      const id = results.insertId;
      const details = [...data];
      details.push({ ...newData, id });
      setData(details);
    });
  };

  const editDetail = (newData, oldData) => {
    post('/editFeatureDetail', { newData }).then(() => {
      const details = [...data];
      const index = details.indexOf(oldData);
      details[index] = newData;
      setData(details);
    });
  };

  const deleteDetail = (oldData) => {
    const { id } = oldData;
    post('/deleteWhatsNewDetail', { id }).then(() => {
      const details = [...data];
      const index = details.indexOf(oldData);
      details.splice(index, 1);
      setData(details);
    });
  };

  return (
    <Paper>
      <MaterialTable
        title="Details"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: async (newData) => {
            await addDetail(newData);
          },
          onRowUpdate: async (newData, oldData) => {
            await editDetail(newData, oldData);
          },
          onRowDelete: async (oldData) => {
            await deleteDetail(oldData);
          },
        }}
        options={{
          paging: false,
        }}
      />
    </Paper>
  );
};

export default WhatsNewDetail;
