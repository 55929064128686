/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { get } from '../../../Functions/API';
import MaterialTable from 'material-table';
import { convertDateToSQLDate } from '../../Utils/SqlDate';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

// todo: Work still needed to be done:

// todo: Use this file everywhere that the billing/index (old line item page) is being used (print, pull, ship costs)

// todo: Add ability to search (within the getLineItems function)

// todo: Fix orderBy for nested fields (getLineItems func)

const LineItems = ({ filter, columns, title, actions, filters, refreshing }) => {
  const tableRef = useRef();
  const classes = useStyles();

  const refresh = () => {
    tableRef.current?.onQueryChange();
  };

  const getLineItems = async (query) => {
    const { orderBy, orderDirection, page, pageSize, search } = query;
    if (orderBy) {
      // todo: this (orderBy) doesnt work with nested fields, figure out how to fix.
      const { field } = orderBy;
      filter.order = `${field} ${orderDirection}`;
    }
    if (filters.startDate) {
      const newDate = new Date(filters.startDate);
      console.log(newDate);
      filter.eager.$where['order.createdAt'].$gte = convertDateToSQLDate(newDate);
    } else {
      delete filter.eager.$where['order.createdAt'].$gte;
    }
    if (filters.endDate) {
      const newDate = new Date(filters.endDate);
      console.log(newDate);
      filter.eager.$where['order.createdAt'].$lte = convertDateToSQLDate(newDate);
    } else {
      delete filter.eager.$where['order.createdAt'].$lte;
    }

    // todo: Search feature for the backend
    //  * Which fields are searchable?
    //  * Different searchable columns for different pages?
    // if (search) {
    //   const searchStr = `%${filters.search}%`;
    //   filter.eager.$where['$or'] = [
    //     { id: { $like: searchStr } },
    //     { name: { $like: searchStr } },
    //   ];
    // }
    console.log('/fulfillment/lineItems:', filter);
    return get('/fulfillment/lineItems', { filter, page, pageSize }, 'obj');
  };

  const getData = async (query) => {
    const { page } = query;
    const res = await getLineItems(query);
    const { results, total: count } = res;
    return {
      data: results,
      page,
      totalCount: count,
    };
  };

  useEffect(() => {
    console.log('Refreshing!', filters);
    refresh();
  }, [filters, refreshing]);

  return (
    <div className={classes.root}>
      <MaterialTable
        title={title}
        columns={columns}
        tableRef={tableRef}
        data={getData}
        options={{
          // todo: make search available when it is set up on the back end
          search: false,
          padding: 'dense',
          selection: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          showFirstLastPageButtons: true,
        }}
        actions={actions}
      />
    </div>
  );
};

export default LineItems;

LineItems.propTypes = {
  filter: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  filters: PropTypes.object,
  refreshing: PropTypes.number,
  actions: PropTypes.array,
};

// todo: future ideas:
//  * If returned data in material-table is shorter than pageSize then change pageSize data length
