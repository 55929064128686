/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PrintShopModal from './PrintShopModal';
import Sizes from './Sizes';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import Typography from '@material-ui/core/Typography';
import SizeOptions from '../../../../Modals/SizeOptions';
import { get, post } from '../../../../../Functions/API';
import VendorAutocomplete from './Components/Vendor';
import TypeAutocomplete from './Components/Type';
import InventoryAutocomplete from './Components/InventoryAutocomplete';
import ContactAutocomplete from '../../../../Contacts/ContactAutocomplete';
import CustomTextField from './Components/CustomTextField';
import SKU from './Components/SKU';
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Slide,
  Switch,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import InventoryStore from '../../../../../Stores/Inventory/Store';
import VendorsStore from '../../../../../Stores/Vendors/Store';
import OptionsStore from '../../../../../Stores/Options/Store';
import { Assignment } from '@material-ui/icons';
import { closeDialog, createDialog } from '../../../../../Stores/Dialog/Actions';
import MiniSkuUpdates from './Components/MiniSkuUpdates';

const GenerateLine = ({ load, num, workOrder, viewRoster, potentialSizes, filters, fetchOrders, setNumOfLines, numOfLines, chips, user }) => {
  const workOrderID = workOrder.id;
  const workOrderMisprint = workOrder.misprint;
  let youthSizes = ['YXS', 'YS', 'YM', 'YL', 'YXL'].map((size) => {
    return { size: size, quantity: '', cost: '', bill: '', countNum: 0, packageNum: 0, errors: [] };
  });
  const adultSizes = ['AXS', 'AS', 'AM', 'AL', 'AXL', 'A2XL'].map((size) => {
    return { size: size, quantity: null, cost: null, bill: null, countNum: 0, packageNum: 0, errors: [] };
  });
  const [modal, setModal] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);
  const [inventoryWithSKUs, setInventoryWithSKUs] = useState([]);
  const [SKUs, setSKUs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    InventoryStore.on('change', getInventory);
    InventoryStore.on('SKUChange', getSKUs);
    OptionsStore.on('change', getOptions);
    VendorsStore.on('change', getVendors);
    getInventory();
    getSKUs();
    getVendors();
    getOptions();
    return () => {
      InventoryStore.removeListener('change', getInventory);
      InventoryStore.removeListener('SKUChange', getSKUs);
      OptionsStore.removeListener('change', getOptions);
      VendorsStore.removeListener('change', getVendors);
    };
  }, []);

  const getInventory = () => {
    setInventoryWithSKUs([...InventoryStore.getInventory()]);
  };

  const getSKUs = () => {
    setSKUs([...InventoryStore.getSKUs()]);
  };

  const getOptions = () => {
    setOptions([...OptionsStore.getOptions()]);
  };

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  const newUpdateLine = async (newVals) => {
    if (workOrder.orderType === 'inventory' && workOrder.productionComplete) {
      createSnack('Error', 'This work order should no longer be edited.');
    } else {
      const newLines = [...numOfLines];
      let line = newLines[num];
      for (const key in newVals) {
        line[key] = newVals[key];
      }
      line.saved = false;
      setNumOfLines(newLines);
    }
  };

  const newUpdateVendor = (vendor) => {
    if (vendor || vendor === null) {
      newUpdateLine({ vendorID: vendor?.id });
    }
  };

  const newUpdateContact = async (contact) => {
    const newLines = [...numOfLines];
    let line = newLines[num];
    line.contactId = contact?.id;
    line.saved = false;
    setNumOfLines(newLines);
  };

  const newUpdateInventory = (inventory) => {
    if (inventory || inventory === null) {
      newUpdateLine({ inventoryId: inventory?.id, removeSKU: null });
    }
  };

  const newUpdateType = (type) => {
    if (type || type === null) {
      newUpdateLine({ type });
    }
  };

  const newUpdateAddSKU = async (sku) => {
    if (sku || sku === null) {
      newUpdateLine({ addSKU: sku?.id });
    }
  };

  const newUpdateRemoveSKU = async (sku) => {
    if (sku || sku === null) {
      newUpdateLine({ removeSKU: sku?.id });
    }
  };

  const copyLine = (line) => {
    const newSizes = [];
    for (const size of line.sizes) {
      const newSize = {
        size: size.size,
        quantity: size.quantity,
        cost: size.cost,
        bill: size.bill,
        errors: [],
      };
      newSizes.push(newSize);
    }
    const newLine = {
      type: line.type,
      printType: line.printType,
      // get rid of vendor
      vendor: line.vendor,
      vendorID: line.vendorID,
      inventoryId: line.inventoryId,
      addSKU: line.addSKU,
      removeSKU: line.removeSKU,
      adultStyle: line.adultStyle,
      youthStyle: line.youthStyle,
      printShop: line.printShop,
      foldAndPolyBag: line.foldAndPolyBag,
      groupAsSet: line.groupAsSet,
      useInventory: line.useInventory,
      chips: [],
      color: line.color,
      notes: line.notes,
      sizes: newSizes,
    };
    setNumOfLines((numOfLines) => numOfLines.concat(newLine));
  };

  const removeLine = (line) => {
    let message = '';
    let found = false;
    for (const size of line.sizes) {
      if (size.cost > 0 || size.bill > 0) {
        found = true;
      }
    }
    if (found) {
      message += 'There are costs and/or billing info already on this row.\n';
    }
    message += 'Are you sure you want to delete this line?';
    const c = window.confirm(message);
    if (c) {
      const newLines = [...numOfLines];
      if (line.ordersID) {
        post('/deleteOrder', { ordersID: line.ordersID })
          .then((results) => {
            newLines.splice(num, 1);
            setNumOfLines(newLines);
            createSnack('Success', results.message);
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
      } else {
        newLines.splice(num, 1);
        setNumOfLines(newLines);
      }
    }
  };

  const pullSizes = (sku) => {
    if (workOrder.productionComplete) {
      createSnack('Error', 'This work order should no longer be edited.');
    } else {
      const newLines = [...numOfLines];
      const line = newLines[num];
      const skuSizes = sku.sizes;
      const newSizes = [];
      for (const size of skuSizes) {
        const foundSize = line.sizes.find((row) => row.size.toLowerCase() === size.size.toLowerCase());
        if (foundSize) {
          foundSize.availableQuantities = size.quantity;
          newSizes.push(foundSize);
        } else {
          const s = {};
          s.size = size.size;
          s.availableQuantities = size.quantity;
          s.quantity = 0;
          s.countNum = 0;
          s.packageNum = 0;
          s.errors = [];
          newSizes.push(s);
        }
      }
      line.sizes = newSizes;
      setNumOfLines(newLines);
    }
  };

  const sortSizes = (sizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.size);
      const indexA = potentialSizes.indexOf(potentialSizeA);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.size);
      const indexB = potentialSizes.indexOf(potentialSizeB);
      return indexA - indexB;
    });
  };

  const addSizes = (type) => {
    if (workOrder.productionComplete) {
      createSnack('Error', 'This work order should no longer be edited.');
    } else {
      const newLines = [...numOfLines];
      const line = newLines[num];
      const search = type === 'adult' ? adultSizes : youthSizes;
      const sizesToAdd = [];
      search.forEach((size) => {
        let foundSize = line.sizes.find((row) => row.size.toLowerCase() === size.size.toLowerCase());
        if (!foundSize) {
          sizesToAdd.push(size);
        }
      });
      line.sizes = sortSizes([...sizesToAdd, ...line.sizes]);
      setNumOfLines(newLines);
    }
  };

  const addSizesButton = (type) => {
    return (
      <InputAdornment position="end">
        <IconButton style={{ marginRight: '-25px' }} onClick={() => addSizes(type)} href="">
          <AddIcon cursor="pointer" color="primary" />
        </IconButton>
      </InputAdornment>
    );
  };

  const toggleUseInventory = () => {
    const newLines = [...numOfLines];
    const line = newLines[num];
    line.useInventory = !line.useInventory;
    post('/toggleUseInventory', { orderID: line.ordersID, useInventory: line.useInventory })
      .then(() => setNumOfLines(newLines))
      .catch((err) => createSnack('Error', err.message));
  };

  const toggleMisprint = (line, misprint) => {
    const newLines = [...numOfLines];
    newLines[num].misprint = !misprint;
    post('/toggleMisprint', { orderID: line.ordersID, misprint: !misprint })
      .then(() => setNumOfLines(newLines))
      .catch((err) => createSnack('Error', err.message));
  };

  const toggleFoldAndPB = (line, foldAndPolyBag) => {
    const newLines = [...numOfLines];
    newLines[num].foldAndPolyBag = !foldAndPolyBag;
    post('/toggleFoldAndPB', { orderID: line.ordersID, foldAndPolyBag: !foldAndPolyBag })
      .then(() => setNumOfLines(newLines))
      .catch((err) => createSnack('Error', err.message));
  };

  const updateFinished = (type, line) => {
    line.ordersIDs = `${line.ordersID}`;
    return post('/updateFinished', { type, line }, 'obj').then((newLine) => {
      const newLines = [...numOfLines];
      const finishedType = type === 'count' ? 'countFinished' : 'packagingFinished';
      newLines[num][finishedType] = !newLines[num][finishedType];
      setNumOfLines(newLines);
    });
  };

  const moveDaInventory = (type, line) => {
    return post(`/orders/${type === 'count' ? 'remove-from-inventory' : 'add-to-inventory'}/${line.ordersID}`);
  };

  const addSize = (event, position, size) => {
    const newLines = [...numOfLines];
    const { sizes } = newLines[position];
    newLines[position].saved = false;
    sizes.push({
      size,
      quantity: '',
      cost: '',
      bill: '',
      countNum: 0,
      packageNum: 0,
      errors: [],
    });
    sortSizes(sizes);
    setNumOfLines(newLines);
  };

  const removeSize = (size) => {
    const newLines = [...numOfLines];
    const { sizes } = newLines[num];
    if (size.id) {
      post('/deleteSize', size)
        .then(() => {
          sizes.splice(sizes.indexOf(size), 1);
          newLines[num].saved = false;
          setNumOfLines(newLines);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    } else {
      sizes.splice(sizes.indexOf(size), 1);
      newLines[num].saved = false;
      setNumOfLines(newLines);
    }
  };

  const toggleButtons = (event, line) => {
    const newLines = [...numOfLines];
    newLines[num].groupAsSet = !newLines[num].groupAsSet;
    newLines[num].saved = false;
    setNumOfLines(newLines);
  };

  const updateQuantity = (event, size, position) => {
    if (event.target.value !== '') {
      const newLines = [...numOfLines];
      newLines[position].saved = false;
      const { sizes } = newLines[position];
      sizes[sizes.indexOf(size)].quantity = parseInt(event.target.value);
      setNumOfLines(newLines);
    }
  };

  const generateSKUTitle = (sku) => {
    return `${sku?.sku} - ${sku?.name} History`;
  };

  return (
    <Card style={Boolean(load.ordersID) && load.saved ? { border: '2px solid rgba(0,0,0,0)' } : { border: '2px solid red' }}>
      <Modal
        open={modal}
        children={
          <Slide direction="up">
            <PrintShopModal
              chips={chips}
              workOrderID={workOrderID}
              load={load}
              close={() => setModal(false)}
              num={num}
              setModal={setModal}
              fetchOrders={fetchOrders}
              numOfLines={numOfLines}
              setNumOfLines={setNumOfLines}
              filters={filters}
            />
          </Slide>
        }
        onClose={() => setModal(false)}
        style={{ top: '10%' }}
      />
      <Modal style={{ top: '10%' }} open={sizeModal} onClose={() => setSizeModal(false)}>
        <SizeOptions num={num} product={load} addSize={addSize} removeSize={removeSize} close={() => setSizeModal(false)} />
      </Modal>
      <CardContent>
        <Grid container spacing={3} justify="center">
          {((!load.useInventory && workOrder.orderType !== 'inventory' && workOrder.orderType !== 'fulfillment') ||
            (!load.useInventory && workOrder.orderType === 'fulfillment')) && (
            <Grid item xs={12} md="auto" style={{ minWidth: '300px' }}>
              <TypeAutocomplete options={options} option={options.find((o) => o.name === load.type)} line={load} callback={newUpdateType} />
            </Grid>
          )}
          <Grid item xs={12} md="auto" style={{ minWidth: '250px' }}>
            {load.useInventory ? (
              <InventoryAutocomplete
                options={inventoryWithSKUs}
                inventory={inventoryWithSKUs.find((i) => i.id === load.inventoryId)}
                callback={newUpdateInventory}
              />
            ) : (
              <VendorAutocomplete vendors={vendors} vendor={vendors.find((v) => v.id === load.vendorID)} line={load} callback={newUpdateVendor} />
            )}
          </Grid>
          {Boolean(load.useInventory) && (
            <>
              <Grid item xs={12} md="auto">
                <SKU
                  callback={newUpdateRemoveSKU}
                  pullSizes={pullSizes}
                  SKU={SKUs.find((sku) => sku.id === load.removeSKU)}
                  SKUs={SKUs.filter((sku) => sku.inventoryId === load.inventoryId)}
                  inventoryId={load.inventoryId}
                  title={'Remove SKU'}
                  disabled={!load.inventoryId}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <Badge
                  color={load.removeInventoryUpdates?.length > 1 ? 'secondary' : 'primary'}
                  variant="dot"
                  invisible={!load.removeInventoryUpdates?.length}
                >
                  <IconButton
                    size={'small'}
                    onClick={() =>
                      createDialog({
                        title: generateSKUTitle(SKUs.find((sku) => sku.id === load.removeSKU)),
                        content: (
                          <MiniSkuUpdates
                            order={load}
                            updateFinished={updateFinished}
                            sku={SKUs.find((sku) => sku.id === load.removeSKU)}
                            addRemove={'removeInventoryUpdates'}
                            user={user}
                            actions={[
                              {
                                title: 'Remove From Inventory',
                                callback: async () => {
                                  await moveDaInventory('count', load);
                                  closeDialog();
                                  fetchOrders();
                                },
                                color: 'primary',
                              },
                            ]}
                          />
                        ),
                      })
                    }
                  >
                    <Assignment fontSize={'small'} />
                  </IconButton>
                </Badge>
              </Grid>
            </>
          )}
          {workOrder.orderType === 'inventory' && (
            <>
              <Grid item xs={12} md="auto">
                <SKU
                  callback={newUpdateAddSKU}
                  pullSizes={pullSizes}
                  SKU={SKUs.find((sku) => sku.id === load.addSKU)}
                  SKUs={SKUs.filter((sku) => sku.inventoryId === workOrder.inventoryId)}
                  inventoryId={workOrder.inventoryId}
                  title={'Add SKU'}
                  disabled={!workOrder.inventoryId}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <Badge
                  color={load.addInventoryUpdates?.length > 1 ? 'secondary' : 'primary'}
                  variant="dot"
                  invisible={!load.addInventoryUpdates?.length}
                >
                  <IconButton
                    size={'small'}
                    onClick={() =>
                      createDialog({
                        title: generateSKUTitle(SKUs.find((sku) => sku.id === load.addSKU)),
                        content: (
                          <MiniSkuUpdates
                            order={load}
                            updateFinished={updateFinished}
                            sku={SKUs.find((sku) => sku.id === load.addSKU)}
                            addRemove={'addInventoryUpdates'}
                            user={user}
                            actions={[
                              {
                                title: 'Add To Inventory',
                                callback: async () => {
                                  await moveDaInventory('packaging', load);
                                  closeDialog();
                                  fetchOrders();
                                },
                                color: 'primary',
                              },
                            ]}
                          />
                        ),
                      })
                    }
                  >
                    <Assignment fontSize={'small'} />
                  </IconButton>
                </Badge>
              </Grid>
            </>
          )}
          {Boolean(!load.useInventory) && workOrder.orderType !== 'inventory' && (
            <>
              <Grid item xs={12} md="auto">
                <TextField
                  id={'youthStyle'}
                  label={'Youth Style'}
                  value={load.youthStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px' }}
                  margin="normal"
                  onChange={(e) => newUpdateLine({ youthStyle: e.target.value })}
                  autoComplete="off"
                  component="div"
                  InputProps={{
                    endAdornment: addSizesButton('youth'),
                  }}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <TextField
                  id={'adultStyle'}
                  label={'Adult Style'}
                  value={load.adultStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px' }}
                  margin="normal"
                  onChange={(e) => newUpdateLine({ adultStyle: e.target.value })}
                  autoComplete="off"
                  component="div"
                  InputProps={{
                    endAdornment: addSizesButton('adult'),
                  }}
                />
              </Grid>
              <CustomTextField id={'color'} label={'Color'} value={load.color} callback={(e) => newUpdateLine({ color: e.target.value })} />
            </>
          )}
          {load.type === 'reversible' && <CustomTextField key={'revColor'} label={'Reverse Color'} value={laod.revColor} />}
          {workOrder.orderType === 'team' && (
            <CustomTextField id={'team'} label={'Team Name'} value={load.team} callback={(e) => newUpdateLine({ team: e.target.value })} />
          )}
          <CustomTextField
            id={'notes'}
            multiline={true}
            label={'Notes'}
            value={load.notes}
            callback={(e) => newUpdateLine({ notes: e.target.value })}
            variant={'outlined'}
          />
        </Grid>
        <Sizes
          num={num}
          order={load}
          orderType={workOrder.orderType}
          addSize={addSize}
          setSizeModal={setSizeModal}
          removeSize={removeSize}
          updateQuantity={updateQuantity}
          get={get}
          post={post}
        />
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item>
                {workOrder.orderType !== 'inventory' && (
                  <FormControlLabel
                    control={
                      <Switch
                        id="groupAsSet"
                        size="small"
                        checked={Boolean(load.groupAsSet)}
                        onChange={(event) => toggleButtons(event, load)}
                        value={Boolean(load.groupAsSet)}
                        color="primary"
                      />
                    }
                    labelPlacement="top"
                    label={<Typography style={{ fontSize: 'smaller' }}>Group As Set</Typography>}
                  />
                )}
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      id="useInventory"
                      size="small"
                      checked={Boolean(load.useInventory)}
                      onChange={() => toggleUseInventory(load, load.useInventory)}
                      value={Boolean(load.useInventory)}
                      color="primary"
                    />
                  }
                  labelPlacement="top"
                  label={<Typography style={{ fontSize: 'smaller' }}>Use Inventory</Typography>}
                />
                <FormControlLabel
                  control={
                    <Switch
                      id="foldAndPolyBag"
                      size="small"
                      checked={Boolean(load.foldAndPolyBag)}
                      onChange={() => toggleFoldAndPB(load, load.foldAndPolyBag)}
                      value={Boolean(load.foldAndPolyBag)}
                      color="primary"
                    />
                  }
                  labelPlacement="top"
                  label={<Typography style={{ fontSize: 'smaller' }}>Fold & Poly Bag</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      id="replacementItem"
                      size="small"
                      checked={Boolean(load.misprint)}
                      onChange={() => toggleMisprint(load, load.misprint)}
                      value={Boolean(load.misprint)}
                      color="secondary"
                    />
                  }
                  labelPlacement="top"
                  label={<Typography style={{ fontSize: 'smaller' }}>Replacement Item</Typography>}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
              {!!(workOrder.contacts.filter((c) => c.type === 'shipping').length > 1) && (
                <Grid item xs={3}>
                  <ContactAutocomplete
                    callback={newUpdateContact}
                    contacts={workOrder.contacts.filter((c) => c.type === 'shipping')}
                    contact={workOrder.contacts.find((c) => c.id === load.contactId)}
                  />
                </Grid>
              )}
              {workOrder.orderType !== 'webOrder' ? (
                load.ordersID && (
                  <Grid item>
                    <Badge badgeContent={load.chips.length} color="secondary">
                      <Button size="small" href="" color="primary" variant="contained" onClick={() => setModal(true)}>
                        Attach Art
                      </Button>
                    </Badge>
                  </Grid>
                )
              ) : (
                <Grid item>
                  <Button onClick={viewRoster} variant="contained" color="primary">
                    View Roster
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button size="small" onClick={() => copyLine(load)} href="" color="secondary" variant="contained">
                  Copy Line
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => removeLine(load)}
                  color="default"
                  variant="contained"
                  disabled={
                    load.sizes.reduce((total, size) => total + size.countNum, 0) !== 0 ||
                    load.sizes.reduce((total, size) => total + size.packageNum, 0) !== 0
                  }
                >
                  Delete Line
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default GenerateLine;
