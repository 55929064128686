import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SingleInventory from '../../Components/Inventory/Inventory';
import Home from '../../Components/Inventory/Home';
import SKU from '../../Components/Inventory/SKU';
import AtTime from '../../Components/Inventory/AtTime';

const Inventory = () => {
  return (
    <div>
      <Switch>
        <Route path="/inventory/atTime/:inventoryId" component={(props) => <AtTime {...props} />} />
        <Route path={'/inventory/:id/skus/:skuId'}>
          <SKU />
        </Route>
        <Route path={'/inventory/:id'}>
          <SingleInventory />
        </Route>
        <Route path={'/inventory'}>
          <Home />
        </Route>
      </Switch>
    </div>
  );
};

export default Inventory;

Inventory.propTypes = {};
