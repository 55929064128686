import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import { ProductPathsStore } from '../index';

class Store extends EventEmitter {
  constructor() {
    super();
    this.paths = [];
  }

  getPaths = () => {
    return this.paths;
  };

  loadPaths = (paths) => {
    this.paths = paths;
    this.emit('change');
  };

  findStep = (stepId) => {
    const [step] = this.paths.map((p) => p.steps.find((s) => s.id === stepId)).filter((r) => r);
    return step;
  };

  findNextStep = (stepId) => {
    const step = this.findStep(stepId);
    return this.findStep(step.nextStep);
  };

  findPreviousStep = (stepId) => {
    const step = this.findStep(stepId);
    return this.findStep(step.previousStep);
  };

  handleActions({ type, results }) {
    switch (type) {
      case 'LOAD_PATHS': {
        this.loadPaths(results);
        break;
      }
      default: {
        console.log('error', { type, results });
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
