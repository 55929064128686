import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';

const imgSrcOne = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/1.jpeg';
const imgSrcTwo = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/2.jpeg';
const imgSrcThree = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/3.jpeg';
const imgSrcFour = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/4.jpeg';
const imgSrcFive = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/5.jpeg';
const imgSrcSix = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/6.jpeg';
const imgSrcSeven = 'https://storage.googleapis.com/yba-shirts.appspot.com/Shopify%20App/Images/7.jpeg';

export default function Home() {
  return (
    <Box>
      <ImageList variant="masonry" cols={3} gap={10}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
  return (
    <ImageList cols={2}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img src={`${item.img}?&fit=crop&auto=format`} srcSet={`${item.img}?&fit=crop&auto=format&dpr=2 2x`} alt={item.title} loading="lazy" />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: imgSrcOne,
    title: 'Warehouse',
  },
  {
    img: imgSrcTwo,
    title: 'Shipping',
  },
  {
    img: imgSrcThree,
    title: 'inAction',
  },
  {
    img: imgSrcFour,
    title: 'Inventory',
  },
  {
    img: imgSrcFive,
    title: 'Machines',
  },
  {
    img: imgSrcSix,
    title: 'ScreenPrint',
  },
  {
    img: imgSrcSeven,
    title: 'Warehouse',
  },
  {
    img: imgSrcOne,
    title: 'Shipping',
  },
  {
    img: imgSrcTwo,
    title: 'inAction',
  },
  {
    img: imgSrcThree,
    title: 'Inventory',
  },
  {
    img: imgSrcFour,
    title: 'Machines',
  },
  {
    img: imgSrcFive,
    title: 'ScreenPrint',
  },
  {
    img: imgSrcSix,
    title: 'Product',
  },
];
