/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { get, patch } from '../../../Functions/API.js';
import { Badge, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import { closeDialog, createDialog } from '../../../Stores/Dialog/Actions';
import OrdersWithErrors from './OrdersWithErrors.js';

const Upload = () => {
  const startCount = 10;

  const { id } = useParams();
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [countDown, setCountDown] = useState(startCount);
  const [watch, setWatch] = useState(null);

  let count = startCount;
  const columns = [
    {
      title: 'Customer Order No',
      field: 'customerOrderNo',
      render: (rowData) => (
        <>
          <Badge
            badgeContent={rowData.errors.filter(({ acknowledged }) => !acknowledged).length || rowData.infoPlusOrderNo}
            color={rowData.infoPlusOrderNo ? 'primary' : 'secondary'}
            variant={'dot'}
          >
            {rowData.customerOrderNo}
          </Badge>
        </>
      ),
    },
    {
      title: 'Customer No',
      field: 'customerNo',
      render: (rowData) => (
        <>
          <Badge badgeContent={rowData.customer.errors.filter(({ acknowledged }) => !acknowledged).length} variant={'dot'}>
            {rowData.customerNo}
          </Badge>
        </>
      ),
    },
    { title: 'Order Date', field: 'orderDate', type: 'datetime' },
    {
      title: 'Shipping',
      render: (rowData) => {
        const {
          shipToAttention,
          shipToCompany,
          shipToStreet,
          shipToStreet2,
          shipToStreet3,
          shipToCity,
          shipToState,
          shipToZip,
          shipToCountry,
          shipToPhone,
          shipToEmail,
        } = rowData;
        return (
          <div>
            <div>{shipToAttention}</div>
            <div>{shipToCompany}</div>
            <div>{shipToStreet}</div>
            <div>{shipToStreet2}</div>
            <div>{shipToStreet3}</div>
            <div>{shipToCity}</div>
            <div>{shipToState}</div>
            <div>{shipToZip}</div>
            <div>{shipToCountry}</div>
            <div>{shipToPhone}</div>
            <div>{shipToEmail}</div>
          </div>
        );
      },
    },
    {
      title: 'Billing',
      render: (rowData) => {
        const {
          billToAttention,
          billToCompany,
          billToStreet,
          billToStreet2,
          billToStreet3,
          billToCity,
          billToState,
          billToZip,
          billToCountry,
          billToPhone,
          billToEmail,
        } = rowData;
        return (
          <div>
            <div>{billToAttention}</div>
            <div>{billToCompany}</div>
            <div>{billToStreet}</div>
            <div>{billToStreet2}</div>
            <div>{billToStreet3}</div>
            <div>{billToCity}</div>
            <div>{billToState}</div>
            <div>{billToZip}</div>
            <div>{billToCountry}</div>
            <div>{billToPhone}</div>
            <div>{billToEmail}</div>
          </div>
        );
      },
    },
    { title: 'Line Items', sorting: false, render: (rowData) => rowData.lineItems.length },
    { title: 'Errors', sorting: false, render: (rowData) => rowData.errors.length },
  ];

  useEffect(() => {
    load();
    startWatch();
  }, [id]);

  const load = () => {
    get('/uploads/info-plus/orders', {
      filter: {
        eager: {
          $where: { uploadId: id },
          customer: { errors: { $where: { entityType: 'infoPlus_customer' } } },
          lineItems: {},
          errors: { $where: { entityType: 'infoPlus_order' } },
        },
      },
    }).then(setOrders);
  };

  const startWatch = () => {
    if (!watch) {
      const interval = setInterval(() => {
        console.log(count);
        let newCount = count - 1;
        if (newCount < 0) {
          load();
          newCount = startCount;
        }
        setCountDown(newCount);
        count = newCount;
      }, 1000);
      setWatch(interval);
    }
  };

  const stopWatch = () => {
    clearInterval(watch);
    count = startCount;
    setCountDown(startCount);
    setWatch(null);
  };

  const ackAll = () => {
    patch(`/uploads/${id}/errors`, { acknowledged: true }).then(load);
  };

  return (
    <div style={{ padding: '10px' }}>
      <Grid container spacing={1} style={{ paddingBottom: '10px' }}>
        <Grid item>
          <Card style={{ height: '100%', minWidth: '150px' }}>
            <CardHeader title={'Processed'} />
            <CardContent style={{ textAlign: 'center' }}>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant="determinate"
                  size={100}
                  thickness={5}
                  value={(orders.filter(({ infoPlusOrderNo }) => infoPlusOrderNo).length / orders.length) * 100}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {`${orders.filter(({ infoPlusOrderNo }) => infoPlusOrderNo).length}/${orders.length}`}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ height: '100%', minWidth: '150px' }}>
            <CardHeader title={'Errors'} />
            <CardContent style={{ textAlign: 'center' }}>
              <Typography variant="h3" component="div" color="secondary">
                {`${
                  orders.filter(
                    ({ customer, errors, infoPlusOrderNo }) =>
                      (customer.errors.filter(({ acknowledged }) => !acknowledged).length ||
                        errors.filter(({ acknowledged }) => !acknowledged).length) &&
                      !infoPlusOrderNo,
                  ).length
                }`}
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container spacing={1} justify={'space-around'}>
                <Grid item>
                  <Link
                    onClick={() =>
                      createDialog({
                        size: 'xl',
                        content: <OrdersWithErrors id={id} columns={columns} orders={orders} />,
                        actions: [{ title: 'Close', callback: closeDialog }],
                      })
                    }
                  >
                    View
                  </Link>
                </Grid>
                <Grid item>
                  <Link color={'secondary'} onClick={ackAll}>
                    Ack All
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ height: '100%', minWidth: '150px' }}>
            <CardHeader title={'Watching'} />
            <CardContent>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" size={100} thickness={5} value={((startCount - countDown) / startCount) * 100} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h5" component="div" color="text.secondary">
                    {countDown}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            <CardActions>
              <Button size={'small'} onClick={watch ? stopWatch : startWatch}>
                {watch ? 'Stop Watch' : 'Start Watch'}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <MaterialTable
        title={'Orders'}
        columns={columns}
        data={orders}
        onRowClick={(event, rowData) => history.push(`/shopify-infoPlus/orders/${rowData.id}`)}
      />
    </div>
  );
};

export default Upload;
