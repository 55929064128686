import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API.js';
import { useHistory } from 'react-router-dom';
import { closeDialog } from '../../../Stores/Dialog/Actions.js';

const OrdersWithErrors = ({ id, columns, orders = [] }) => {
  const history = useHistory();

  const [data, setData] = useState(orders.filter((o) => !o.infoPlusOrderNo && (o.errors.length || o.customer.errors.length)));

  return (
    <MaterialTable
      title={'Orders with Errors'}
      columns={columns}
      data={data}
      onRowClick={(event, rowData) => {
        history.push(`/shopify-infoPlus/orders/${rowData.id}`);
        closeDialog();
      }}
    />
  );
};

export default OrdersWithErrors;

OrdersWithErrors.propTypes = {
  id: PropTypes.number,
  columns: PropTypes.array,
  orders: PropTypes.array,
};
