/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/jsx-props-no-spreading:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import WorkOrderTotals from '../Common/WorkOrderTotals';
import WaitingOrders from '../Common/WaitingOrders';
import MiniWorkOrderStatusGraph from '../Common/MiniWorkOrderStatusGraph';
import SalesGoalsIndividual from './SalesGoals/SalesGoalsIndividual';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import SalesGoalsTeam from './SalesGoals/SalesGoalsOverall';
import SalesGoalsOverall from './SalesGoals/SalesGoalsTeam';
import SalesGoalsByMonthIndividual from './SalesGoals/SalesGoalsByMonthIndividual';
import BetterFinancialGraph from '../Accounting/BetterFinancialGraph';
import CustomerComparison from '../Accounting/CustomerComparison';
import FinancialGraphBySalesType from '../Accounting/FinancialGraphBySalesType';

const SalesHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          <LatestWorkOrders {...props} />
          <Grid item md={6} xs={12}>
            <WorkOrderTotals get={props.get} margin={{ marginTop: '20px' }} refresh={props.refresh} />
          </Grid>
          <Grid item md={6} xs={12}>
            <MiniWorkOrderStatusGraph get={props.get} user={props.user} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <WaitingOrders get={props.get} user={props.user} type="Sales" startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <SalesGoalsIndividual get={props.get} post={props.post} user={props.user} refresh={props.refresh} />
          </Grid>
          <Grid item xs={12}>
            <SalesGoalsByMonthIndividual user={props.user} get={props.get} />
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <CustomerComparison get={props.get} post={props.post} user={props.user} />*/}
          {/*</Grid>*/}
          {props.user.uid === 'WJU8lxxoytVWHEVQGGdCCcEcXLb2' && (
            <>
              <Grid item xs={12}>
                <BetterFinancialGraph get={props.get} refresh={props.refresh} />
              </Grid>
              <Grid item xs={12}>
                <FinancialGraphBySalesType get={props.get} post={props.post} user={props.user} />
              </Grid>
              <Grid item xs={12} md={6}>
                <SalesGoalsTeam get={props.get} refresh={props.refresh} />
              </Grid>
              <Grid item xs={12} md={6}>
                <SalesGoalsOverall get={props.get} refresh={props.refresh} />
              </Grid>
            </>
          )}
          {/* Space for graphs when needed */}
          {/* <Grid item xl={9} md={8} xs={12}>
                    </Grid>
                    <Grid item md={4} xl={3} xs={12}>
                    </Grid> */}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default SalesHome;
