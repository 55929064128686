/* eslint react/no-array-index-key:0 */
/* eslint no-shadow:0 */
/* eslint radix:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, CardHeader, Divider, Collapse, Button, ButtonGroup, Popover } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import { months } from '../../../Utils/monthsOfTheYear';
import SalesGoalsReportOverall from './SalesGoalsReportOverall';

const SalesGoalsOverall = ({ refresh, get }) => {
  const [open, setOpen] = useState(true);
  const [goalYear, setGoalYear] = useState(moment());
  const [salesData, setSalesData] = useState([]);
  const [salesDataLastYear, setSalesDataLastYear] = useState([]);
  const [selected, setSelected] = useState('All');
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const getSalesGoalsAll = () => {
    get('/salesgoal/all', [
      {
        name: 'year',
        value: goalYear.format('YYYY'),
      },
      {
        name: 'filter',
        value: selected,
      },
    ])
      .then((res) => {
        setSalesData(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
    get('/salesgoal/all', [
      {
        name: 'year',
        value: parseInt(goalYear.format('YYYY')) - 1,
      },
      {
        name: 'filter',
        value: selected,
      },
    ])
      .then((res) => {
        setSalesDataLastYear(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const handleGoalDateChange = (date) => {
    const newDate = moment(date);
    setGoalYear(newDate);
  };

  useEffect(() => {
    if (salesData.length) setSalesData([]);
    getSalesGoalsAll();
  }, [refresh, goalYear, selected]);

  return (
    <>
      <Card style={{ height: open ? '100%' : 'auto' }}>
        <CardHeader
          action={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                <InfoIcon id="infoIcon" />
              </IconButton>
              <Popover
                open={infoOpen}
                anchorEl={anchorEl}
                onClose={openInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                  This table shows last years GP, the current years goals, and the actual GP so far.
                  <br />
                  <br />
                  Everyone will need to enter in a goal for each month on their own home page for it to be included in this goal count.
                </Typography>
              </Popover>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginTop: '4px', width: '100px' }}
                  variant="inline"
                  views={['year']}
                  minDate="2010/12/29"
                  maxDate="2030/12/29"
                  label="Year"
                  value={goalYear}
                  onChange={(date) => handleGoalDateChange(date)}
                />
              </MuiPickersUtilsProvider>
            </div>
          }
          title={
            <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">YBA Goals</Typography>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <CardContent style={{ paddingBottom: '16px', height: '525px', overflow: 'auto' }}>
            {salesData.length ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{ marginBottom: '10px' }}>
                    <Button variant={selected === 'All' ? 'contained' : 'outlined'} onClick={() => setSelected('All')} style={{ lineHeight: '18px' }}>
                      All
                    </Button>
                    <Button
                      variant={selected === 'Sales' ? 'contained' : 'outlined'}
                      onClick={() => setSelected('Sales')}
                      style={{ lineHeight: '18px' }}
                    >
                      Sales
                    </Button>
                    <Button
                      variant={selected === 'Leaders' ? 'contained' : 'outlined'}
                      onClick={() => setSelected('Leaders')}
                      style={{ lineHeight: '18px' }}
                    >
                      Leaders
                    </Button>
                  </ButtonGroup>
                </div>
                <SalesGoalsReportOverall reportData={salesData} reportDataLastYear={salesDataLastYear} goalYear={goalYear.format('YYYY')} />
              </>
            ) : (
              months.map((x, k) => {
                return <Skeleton key={k} variant="rect" width="100%" height={30} style={{ margin: '6px 0' }} />;
              })
            )}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default SalesGoalsOverall;
