import dispatcher from './dispatcher';
import { get, patch, post } from '../../../Functions/API';
import { createSnack } from '../../Snack/Actions';
import CustomersStore from './Store';

export const fetchCustomers = async () => {
  const currentCustomers = CustomersStore.getCustomers();
  if (currentCustomers?.length) {
    return currentCustomers;
  } else if (CustomersStore.isFetching()) {
    const customers = await CustomersStore.getPromise();
    return customers;
  } else {
    try {
      const filter = JSON.stringify({
        eager: {
          $where: {
            fulfillment: 1,
          },
        },
      });
      const promise = get(`/customers`, { filter }, 'obj');
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        promise,
      });
      const customers = await promise;
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMERS',
        customers,
      });
      return customers;
    } catch (err) {
      console.error(err);
      createSnack('Error', 'Problem loading customers');
      return currentCustomers;
    }
  }
};

export const loadCustomers = async () => {
  const filter = JSON.stringify({
    eager: {
      $where: {
        fulfillment: 1,
      },
    },
  });
  await get(`/customers`, { filter })
    .then((customers) => {
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMERS',
        customers,
      });
    })
    .catch((err) => {
      console.log(err);
      createSnack('Error', err ? err.message : 'Error, check logs for details');
    });
};

export const updateCustomerFulfillment = (customer) => {
  patch(`/customers/${customer.id}`, { ...customer, fulfillment: 1 })
    .then(loadCustomers)
    .catch((err) => {
      createSnack('Error', err.message);
      console.error(err);
    });
};
