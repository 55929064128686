import { EventEmitter } from 'events';
import { updateSelected } from './Actions';
import dispatcher from './dispatcher';
import { TablePagination } from '@material-ui/core';
import React from 'react';

class Store extends EventEmitter {
  constructor() {
    super();
    this.stores = [];
    this.fetching = false;
    this.promise = null;
  }

  setFetching(promise) {
    this.fetching = true;
    this.promise = promise;
  }

  getPromise() {
    return this.promise;
  }

  isFetching() {
    return this.fetching;
  }

  getStores() {
    return this.stores;
  }

  loadStores = (stores) => {
    this.stores = stores;
    this.emit('change');
  };

  updateStore = (newStore) => {
    const oldStore = this.stores.find((s) => s.id === newStore.id);
    const index = this.stores.indexOf(oldStore);
    this.stores[index] = newStore;
    this.selected.store = newStore;
    this.emit('storesChange');
    this.emit('selectedChange');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_STORES': {
        this.loadStores(action.results);
        break;
      }
      case 'SET_FETCHING': {
        this.setFetching(action.promise);
        break;
      }
      case 'STORE_UPDATE': {
        this.updateStore(action.updatedStore);
        break;
      }
      default: {
        console.log('unknown action', action);
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
