import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.countries = [];
    this.fetching = false;
    this.countriesPromise = null;
  }

  getCountries() {
    return this.countries;
  }

  getCountriesPromise() {
    return this.countriesPromise;
  }

  isFetching() {
    return this.fetching;
  }

  setFetching(countriesPromise) {
    this.fetching = true;
    this.countriesPromise = countriesPromise;
  }

  setCountries = (countries) => {
    this.countries = countries;
    this.fetching = false;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'SET_FETCHING': {
        this.setFetching(action.countriesPromise);
        break;
      }
      case 'SET_COUNTRIES': {
        this.setCountries(action.countries);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
