/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import Statuses from '../../../Components/Fulfillment/Home/Statuses';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

const Home = () => {
  const classes = useStyles();

  useEffect(() => {
    updateFilters({ store: null, customer: null, storeDisabled: true, customerDisabled: true, datesDisabled: true, count: 0 });
  }, []);

  return (
    <div className={classes.root}>
      <Statuses />
    </div>
  );
};

export default Home;
