import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.invoice = [];
  }

  getInvoice() {
    return this.invoice;
  }

  updateInvoice = (invoice) => {
    this.invoice = invoice;
    this.emit('change');
  };

  newInvoiceLine = (invoice) => {
    this.invoice.push(invoice);
    this.emit('change');
  };

  deleteInvoiceLine = (id) => {
    const index = this.invoice.findIndex((i) => i.id === parseInt(id));
    console.log(id, index);
    this.invoice.splice(index, 1);
    this.emit('change');
  };

  deleteAll = () => {
    this.invoice = [];
    this.emit('change');
  };

  updateInvoiceLine = (line) => {
    this.invoice[this.invoice.findIndex((i) => i.id === line.id)] = line;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'GET_INVOICE': {
        this.getWorkOrder();
        break;
      }
      case 'UPDATE_INVOICE': {
        this.updateInvoice(action.invoice);
        break;
      }
      case 'UPDATE_INVOICE_LINE': {
        this.updateInvoiceLine(action.invoice);
        break;
      }
      case 'NEW_INVOICE_LINE': {
        this.newInvoiceLine(action.invoice);
        break;
      }
      case 'DELETE_INVOICE_LINE': {
        this.deleteInvoiceLine(action.id);
        break;
      }
      case 'DELETE_ALL': {
        this.deleteAll();
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
