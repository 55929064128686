import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid, InputAdornment, TextField } from '@material-ui/core';
import WeekSelector from './WeekSelector';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getWeek, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { get } from '../../../Functions/API';
import { convertDateToSQLDate, sqlDateString } from '../../Utils/SqlDate';
import commaNumber from 'comma-number';

const CSPIncome = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  const monthDate = new Date();
  monthDate.setMonth(monthDate.getMonth() - 1);
  const [week, setWeek] = useState(date);
  const [month, setMonth] = useState(monthDate);
  const [weekData, setWeekData] = useState(0);
  const [monthData, setMonthData] = useState(0);

  const getWeekData = () => {
    get(`/costing/csp`, { from: sqlDateString(startOfWeek(week)), to: sqlDateString(endOfWeek(week)) }, 'obj').then(({ cost }) => {
      setWeekData(cost);
    });
  };

  const getMonthData = () => {
    get(`/costing/csp`, { from: sqlDateString(startOfMonth(month)), to: sqlDateString(endOfMonth(month)) }, 'obj').then(({ actualCost }) => {
      setMonthData(actualCost);
    });
  };

  useEffect(() => {
    getWeekData();
  }, [week]);

  useEffect(() => {
    getMonthData();
  }, [month]);

  return (
    <div style={{ height: '100%' }}>
      <Card style={{ height: '100%' }}>
        <CardHeader title={'CSP Income'} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <WeekSelector week={week} setWeek={setWeek} />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label={'Week Estimated Income'}
                value={commaNumber(weekData)}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker variant="inline" openTo="month" views={['year', 'month']} label="Month" value={month} onChange={setMonth} />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label={'Month Actual Income'}
                value={commaNumber(monthData)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CSPIncome;
