/* eslint consistent-return:0 */
/* eslint radix:0 */
/* eslint no-plusplus:0 */
/* eslint array-callback-return:0 */
/* eslint no-loop-func:0 */
/* eslint react/prop-types:0 */
import React from 'react';
import { TableContainer, Table, TableRow, TableHead, TableCell, TableBody, Grid, Typography } from '@material-ui/core';
import commaNumber from 'comma-number';
import moment from 'moment';
import { monthsWithNumber } from '../../../Utils/monthsOfTheYear';

const SalesGoalsReportOverall = ({ reportData, reportDataLastYear, goalYear }) => {
  let lastYearRes = 0;
  let currentYearGoal = 0;
  let currentYearRes = 0;

  const computeGoalVsActual = () => {
    let diff = 0;
    const month = moment().format('M');
    let currMonth = 1;
    while (currMonth <= month) {
      reportData.map((data) => {
        if (data.month === currMonth) {
          diff += data.profit - data.goalAmount;
        }
      });
      currMonth++;
    }
    if (diff < 0) {
      diff = `(${commaNumber(Math.abs(diff).toFixed(2))})`;
    } else {
      diff = commaNumber(diff.toFixed(2));
    }
    return diff;
  };

  return (
    <div className="sales-goal-overall-container">
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              border: '2px solid #f5f5f5',
              borderBottom: 'none',
              borderRadius: '4px',
            }}
          >
            <TableContainer>
              <Table size="small" ariaLabel="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>{parseInt(goalYear) - 1} GP Results</TableCell>
                    <TableCell align="left">{goalYear} GP Goal</TableCell>
                    <TableCell align="right">{goalYear} GP Results</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monthsWithNumber.map((month) => {
                    let foundDataForMonth = false;
                    return (
                      <TableRow key={month[1]}>
                        <TableCell>{month[0]}</TableCell>
                        {reportDataLastYear.map((data) => {
                          if (month[1] === data.month) {
                            foundDataForMonth = true;
                            lastYearRes += data.profit ? data.profit : 0;
                            return <TableCell align="left">{data.profit !== null ? `$${commaNumber(data.profit)}` : 'No Data'}</TableCell>;
                          }
                        })}
                        {!foundDataForMonth && (
                          <>
                            <TableCell align="left">-</TableCell>
                          </>
                        )}
                        {(foundDataForMonth = false)}
                        {reportData.map((data) => {
                          if (month[1] === data.month) {
                            foundDataForMonth = true;
                            currentYearGoal += data.goalAmount ? data.goalAmount : 0;
                            currentYearRes += data.profit ? data.profit : 0;
                            return (
                              <>
                                <TableCell align="left">{data.goalAmount !== null ? `$${commaNumber(data.goalAmount)}` : 'No Data'}</TableCell>
                                <TableCell align="right">{data.profit !== null ? `$${commaNumber(data.profit)}` : 'No Data'}</TableCell>
                              </>
                            );
                          }
                        })}
                        {!foundDataForMonth && (
                          <>
                            <TableCell align="left">-</TableCell>
                            <TableCell align="right">-</TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                  <TableRow key="Total">
                    <TableCell align="left">
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>${commaNumber(lastYearRes.toFixed(2))}</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>${commaNumber(currentYearGoal.toFixed(2))}</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>${commaNumber(currentYearRes.toFixed(2))}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="actual-vs-goal" style={{ paddingTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="body1" style={{ paddingRight: '8px' }}>
              <strong>{goalYear} Actual vs. Goal</strong>
            </Typography>
            <Typography variant="body1">{computeGoalVsActual()}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesGoalsReportOverall;
