/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint consistent-return:0 */
/* eslint no-alert:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Button, Card, CardContent, FormControlLabel, Grid, MenuItem, Paper, Select, Checkbox, Switch, Typography } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import { Bar } from 'react-chartjs-2';
import Modal from '@material-ui/core/Modal';
import Menu from '../../../Components/Menu/index.js';
import MonthCollectedDetails from '../../../Components/Modals/MonthCollectedDetails.js';
import { getUsersByGroup } from '../../../Stores/Users/Actions.js';
import Papa from 'papaparse';
import { UsersStore } from '../../../Stores/index.js';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Breadcrumbs, Link } from '@mui/material';

export default class SalesSheet extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      data: [],
      dataByMonth: [],
      dataBySalesType: [],
      dataByLeadType: [],
      dataByYear: [{ invoice: 0, profit: 0, profitMargin: 0 }],
      user: '',
      salesType: '',
      leadType: '',
      showGraphs: false,
      ready: true,
      users: [],
      month: '',
      year: date,
      salesDetailsModal: false,
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      console.log(results);
      this.setState(results);
    });
  }

  setup = async () => {
    let uid = this.state.user;
    let error = '';
    let data;
    let user = '';
    let dataByMonth;
    let dataByYear;
    let dataByLeadType;
    let dataBySalesType;
    let users;
    const ready = false;
    // TODO viewPersonalSalesSheet
    if (this.props.user.group.includes('Sales') || this.props.user.group === 'Owner') {
      uid = this.props.user.uid;
      user = this.props.user.uid;
    }
    const props = [
      { name: 'uid', value: uid },
      { name: 'month', value: this.state.month },
      { name: 'year', value: this.state.year.getFullYear() },
    ];
    await this.props
      .get('/salesSheet', props)
      .then((results) => {
        data = results;
      })
      .catch((err) => {
        error += err.message;
      });
    await this.props
      .get('/salesSheetTotalsByMonth', props)
      .then((results) => {
        dataByMonth = results;
      })
      .catch((err) => {
        error += err.message;
      });
    await this.props
      .get('/salesSheetTotalsByYear', props)
      .then((results) => {
        dataByYear = results;
      })
      .catch((err) => {
        error += err.message;
      });
    await this.props
      .get('/salesSheetTotalsByLeadType', props)
      .then((results) => {
        dataByLeadType = results;
      })
      .catch((err) => {
        error += err.message;
      });
    await this.props
      .get('/salesSheetTotalsBySalesType', props)
      .then((results) => {
        dataBySalesType = results;
      })
      .catch((err) => {
        error += err.message;
      });
    await getUsersByGroup('Sales')
      .then((results) => {
        users = results;
        users.push({ firstName: 'Sales', lastName: 'Team', uid: 'salesGroup' });
        users.push({ firstName: 'Leadership', lastName: 'Team', uid: 'leadershipGroup' });
      })
      .catch((err) => {
        error += err.message;
      });
    if (
      data !== undefined &&
      user !== undefined &&
      dataByMonth !== undefined &&
      dataByYear !== undefined &&
      dataByLeadType !== undefined &&
      dataBySalesType !== undefined &&
      users !== undefined
    ) {
      return {
        data,
        user,
        dataByMonth,
        dataByYear,
        dataByLeadType,
        dataBySalesType,
        users,
        ready,
      };
    }
    throw new Error(error);
  };

  getData = async () => {
    this.setState({
      ready: true,
    });
    let data;
    let dataByMonth;
    let dataByYear;
    let dataByLeadType;
    let dataBySalesType;
    const ready = false;
    const props = [
      { name: 'uid', value: this.state.user },
      { name: 'month', value: this.state.month },
      { name: 'year', value: this.state.year.getFullYear() },
      { name: 'leadType', value: this.state.leadType },
      { name: 'salesType', value: this.state.salesType },
    ];
    console.time('1');
    await this.props
      .get('/salesSheet', props)
      .then((results) => {
        data = results;
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.timeEnd('1');
    console.time('2');
    await this.props
      .get('/salesSheetTotalsByMonth', props)
      .then((results) => {
        dataByMonth = results;
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.timeEnd('2');
    console.time('3');
    await this.props
      .get('/salesSheetTotalsByYear', props)
      .then((results) => {
        dataByYear = results;
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.timeEnd('3');
    console.time('4');
    await this.props
      .get('/salesSheetTotalsByLeadType', props)
      .then((results) => {
        dataByLeadType = results;
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.timeEnd('4');
    console.time('5');
    await this.props
      .get('/salesSheetTotalsBySalesType', props)
      .then((results) => {
        dataBySalesType = results;
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.timeEnd('5');
    this.setState({
      data,
      dataByMonth,
      dataByYear,
      dataByLeadType,
      dataBySalesType,
      ready,
    });
  };

  update = (prop, value) => {
    this.setState(
      {
        [prop]: value,
      },
      this.getData,
    );
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  filterBySalesType = (salesType) => {
    const props = [
      { name: 'uid', value: this.state.user },
      { name: 'month', value: this.state.month },
      { name: 'year', value: this.state.year.getFullYear() },
      { name: 'salesType', value: salesType },
    ];
    this.props
      .get('/salesSheet', props)
      .then((data) => {
        this.setState({
          data,
          salesType,
          leadType: '',
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  filterByLeadType = (leadType) => {
    const props = [
      { name: 'uid', value: this.state.user },
      { name: 'month', value: this.state.month },
      { name: 'year', value: this.state.year.getFullYear() },
      { name: 'leadType', value: leadType },
    ];
    this.props
      .get('/salesSheet', props)
      .then((data) => {
        this.setState({
          data,
          leadType,
          salesType: '',
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  filterByMonth = (month) => {
    const props = [
      { name: 'uid', value: this.state.user },
      { name: 'month', value: month },
      { name: 'year', value: this.state.year.getFullYear() },
    ];
    this.props
      .get('/salesSheet', props)
      .then((data) => {
        this.setState({
          data,
          month,
          leadType: '',
          salesType: '',
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  toggleGraphs = () => {
    this.setState({
      showGraphs: !this.state.showGraphs,
    });
  };

  getSummary = (data) => {
    let invoice = 0;
    let cost = 0;
    for (const row of data) {
      invoice += row.invoice;
      cost += row.cost;
    }
    const profit = invoice - cost;
    const margin = (profit / invoice) * 100;
    window.alert(
      `Total Invoice: $${this.numberWithCommas(invoice.toFixed(2))}\nTotal cost: $${this.numberWithCommas(
        cost.toFixed(2),
      )}\nProfit: $${this.numberWithCommas(profit.toFixed(2))}\nMargin: ${this.numberWithCommas(margin.toFixed(2))}%`,
    );
  };

  openSalesDetailsModal = (button, month, year) => {
    this.setState({
      button,
      month,
      year,
      salesDetailsModal: !this.state.salesDetailsModal,
    });
  };

  closeSalesDetailsModal = () => {
    this.setState({
      salesDetailsModal: !this.state.salesDetailsModal,
    });
  };

  render() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const columns = [
      { title: 'ID', field: 'workOrderID' },
      { title: 'Customer', field: 'customerName' },
      { title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
      {
        title: 'Invoice',
        field: 'invoice',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.invoice)) {
            return `$${this.numberWithCommas(rowData.invoice.toFixed(2))}`;
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Cost',
        field: 'cost',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.cost)) {
            return `$${this.numberWithCommas(rowData.cost.toFixed(2))}`;
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Profit',
        field: 'profit',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.profit)) {
            if (rowData.profit < 0) {
              return <div style={{ color: 'red' }}>{`$${this.numberWithCommas(rowData.profit.toFixed(2))}`}</div>;
            }
            return `$${this.numberWithCommas(rowData.profit.toFixed(2))}`;
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Margin',
        field: 'profitMargin',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.profitMargin)) {
            if (rowData.profitMargin < 0) {
              return <div style={{ color: 'red' }}>{`${this.numberWithCommas(rowData.profitMargin.toFixed(2))}%`}</div>;
            }
            return `${this.numberWithCommas(rowData.profitMargin.toFixed(2))}%`;
          }
          return <div style={{ color: 'red' }}>0.00%</div>;
        },
      },
      {
        title: 'Closed',
        field: 'accountingApproved',
        render: (rowData) => {
          return <Checkbox size="small" color="primary" checked={Boolean(rowData.accountingApproved)} value={Boolean(rowData.accountingApproved)} />;
        },
      },
      { title: 'Closed Date', field: 'closedDate', type: 'date' },
      {
        title: 'Paid',
        field: 'paidDate',
        render: (rowData) => {
          return rowData.writtenOff ? (
            'Written Off'
          ) : (
            <Checkbox size="small" color="secondary" checked={Boolean(rowData.paidDate)} value={Boolean(rowData.paidDate)} />
          );
        },
      },
      { title: 'Paid Date', field: 'paidDate', type: 'date' },
    ];
    if (!this.state.user || this.state.user === 'salesGroup' || this.state.user === 'leadershipGroup') {
      columns.splice(2, 0, {
        title: 'Salesman',
        field: 'userID',
        export: false,
        render: (rowData) => {
          const user = this.state.users.find((row) => row.uid === rowData.userID);
          if (user) {
            return `${user.firstName} ${user.lastName}`;
          }
          return 'No Salesman';
        },
      });
    }
    const leadColumns = [
      {
        title: 'Lead Type',
        field: 'leadType',
        render: (rowData) => {
          if (this.state.leadType === rowData.leadType) {
            return <div style={{ color: '#f50057' }}>{rowData.leadType}</div>;
          }
          return rowData.leadType;
        },
      },
      {
        title: 'Total',
        field: 'invoice',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.invoice)) {
            return `$${this.numberWithCommas(rowData.invoice.toFixed(2))}`;
          }
        },
      },
      {
        title: '% of Total',
        render: (rowData) => `${this.numberWithCommas(((rowData.invoice / rowData.total) * 100).toFixed(2))}%`,
      },
    ];
    const salesColumns = [
      {
        title: 'Sales Type',
        field: 'salesType',
        render: (rowData) => {
          if (this.state.salesType === rowData.salesType) {
            return <div style={{ color: '#f50057' }}>{rowData.salesType}</div>;
          }
          return rowData.salesType;
        },
      },
      {
        title: 'Total',
        field: 'invoice',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.invoice)) {
            return `$${this.numberWithCommas(rowData.invoice.toFixed(2))}`;
          }
        },
      },
      {
        title: '% of Total',
        render: (rowData) => `${this.numberWithCommas(((rowData.invoice / rowData.total) * 100).toFixed(2))}%`,
      },
    ];
    const totalsColumns = [
      {
        title: 'Month',
        field: 'month',
        render: (rowData) => {
          if (this.state.month === rowData.month) {
            return <div style={{ color: '#f50057' }}>{months[rowData.month - 1]}</div>;
          }
          return months[rowData.month - 1];
        },
      },
      {
        title: 'Invoice',
        field: 'invoice',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.invoice)) {
            return `$${this.numberWithCommas(rowData.invoice.toFixed(2))}`;
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Profit',
        field: 'profit',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.profit)) {
            return `$${this.numberWithCommas(rowData.profit.toFixed(2))}`;
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Profit %',
        field: 'profitMargin',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.profitMargin)) {
            return `${this.numberWithCommas(rowData.profitMargin.toFixed(2))}%`;
          }
          return <div style={{ color: 'red' }}>0.00%</div>;
        },
      },
      {
        title: 'Collected',
        field: 'commissionEligible',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.commissionEligible)) {
            return (
              <Button size="small" color="primary" onClick={() => this.openSalesDetailsModal('collected', rowData.month, this.state.year)}>
                {`$${this.numberWithCommas(rowData.commissionEligible.toFixed(2))}`}
              </Button>
            );
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Month Collected',
        field: 'monthCollected',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.monthCollected)) {
            return (
              <Button size="small" color="primary" onClick={() => this.openSalesDetailsModal('monthCollected', rowData.month, this.state.year)}>
                {`$${this.numberWithCommas(rowData.monthCollected.toFixed(2))}`}
              </Button>
            );
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
      {
        title: 'Month Collected (Previous Year)',
        field: 'monthCollected',
        render: (rowData) => {
          if (rowData && parseFloat(rowData.monthCollectedPreviousYear)) {
            return (
              <Button
                size="small"
                color="primary"
                onClick={() => this.openSalesDetailsModal('monthCollectedPrevYear', rowData.month, this.state.year)}
              >
                {`$${this.numberWithCommas(rowData.monthCollectedPreviousYear.toFixed(2))}`}
              </Button>
            );
          }
          return <div style={{ color: 'red' }}>$0.00</div>;
        },
      },
    ];
    const smallTableOptions = {
      rowStyle: {
        fontSize: 14,
      },
      search: false,
      padding: 'dense',
      maxBodyHeight: '457px',
      pageSize: 12,
      toolbar: false,
    };
    const smallTableStyle = {
      margin: '10px',
    };
    const colors = [
      '#68072B',
      '#B6312B',
      '#CE611E',
      '#DBDC96',
      '#0D6D3B',
      '#3B3E4C',
      '#AB5639',
      '#CEBF4A',
      '#41C55C',
      '#667A8D',
      '#BB8D88',
      '#564B51',
    ];
    const salesTypeData = {
      labels: this.state.dataBySalesType.map((row) => row.salesType),
      datasets: [
        {
          data: this.state.dataBySalesType.map((row) => row.invoice),
          backgroundColor: '#3f51b5',
        },
      ],
    };
    const salesTypeOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return `$${this.numberWithCommas(tooltipItem.yLabel.toFixed(2))}`;
          },
        },
      },
    };
    const leadTypeData = {
      labels: this.state.dataByLeadType.map((row) => row.leadType),
      datasets: [
        {
          data: this.state.dataByLeadType.map((row) => row.invoice),
          backgroundColor: '#3f51b5',
        },
      ],
    };
    const leadTypeOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return `$${this.numberWithCommas(tooltipItem.yLabel.toFixed(2))}`;
          },
        },
      },
    };
    const totalByMonthData = {
      labels: this.state.dataByMonth.map((row) => months[row.month - 1]),
      datasets: [
        // {
        //     label: 'Invoice',
        //     data: this.state.dataByMonth.map(row => row.invoice),
        //     backgroundColor: colors[0]
        // },
        {
          label: 'Profit',
          data: this.state.dataByMonth.map((row) => row.profit),
          backgroundColor: colors[0],
        },
        {
          label: 'Commission Eligible',
          data: this.state.dataByMonth.map((row) => row.commissionEligible),
          backgroundColor: colors[1],
        },
        {
          label: 'Month Collected',
          data: this.state.dataByMonth.map((row) => row.monthCollected),
          backgroundColor: colors[2],
        },
        {
          label: 'Month Collected (Previous Year)',
          data: this.state.dataByMonth.map((row) => row.monthCollectedPreviousYear),
          backgroundColor: colors[3],
        },
      ],
    };
    const totalByMonthOptions = {
      legend: {
        position: 'bottom',
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return `$${this.numberWithCommas(tooltipItem.yLabel.toFixed(2))}`;
          },
        },
      },
    };

    return (
      <div>
        <title>Sales Sheet</title>
        <Modal open={this.state.salesDetailsModal} onClose={this.closeSalesDetailsModal} style={{ top: '10%' }}>
          <MonthCollectedDetails
            close={this.closeSalesDetailsModal}
            button={this.state.button}
            uid={this.state.user}
            get={this.props.get}
            month={this.state.month}
            year={this.state.year.getFullYear()}
          />
        </Modal>
        <Paper style={{ backgroundColor: '#f5f5f5', padding: '10px' }}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link underline={'hover'} href={`/accounting`}>
                  Accounting Home
                </Link>
                <Typography color="text.primary">Sales Sheet</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="space-around" spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <Card style={{ width: '100%' }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        Total {this.state.year.getFullYear()} Revenue
                      </Typography>
                      <Typography component="h5" variant="h5">
                        $
                        {this.state.dataByYear.length && this.state.dataByYear[0].invoice
                          ? this.numberWithCommas(this.state.dataByYear[0].invoice.toFixed(2))
                          : 'Not Found'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card style={{ width: '100%' }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        Total {this.state.year.getFullYear()} Profit
                      </Typography>
                      <Typography component="h5" variant="h5">
                        $
                        {this.state.dataByYear.length && this.state.dataByYear[0].profit
                          ? this.numberWithCommas(this.state.dataByYear[0].profit.toFixed(2))
                          : 'Not Found'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card style={{ width: '100%' }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        Total {this.state.year.getFullYear()} Profit %
                      </Typography>
                      <Typography component="h5" variant="h5">
                        {this.state.dataByYear.length && this.state.dataByYear[0].profitMargin
                          ? this.numberWithCommas(this.state.dataByYear[0].profitMargin.toFixed(2))
                          : 'Not Found'}
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card style={{ width: '100%' }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="textSecondary">
                        {this.state.year.getFullYear()} Commission Eligible
                      </Typography>
                      <Typography component="h5" variant="h5">
                        $
                        {this.state.dataByYear.length && this.state.dataByYear[0].commissionEligible
                          ? this.numberWithCommas(this.state.dataByYear[0].commissionEligible.toFixed(2))
                          : 'Not Found'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <MaterialTable
                title="Sales Sheet"
                columns={columns}
                data={this.state.data}
                isLoading={this.state.ready}
                actions={[
                  {
                    tooltip: 'Get Home',
                    icon: () => {
                      return (
                        <Button variant="contained" color="secondary">
                          Get Summary
                        </Button>
                      );
                    },
                    onClick: (event, data) => this.getSummary(data),
                  },
                ]}
                components={{
                  Toolbar: (props) => (
                    <div style={{ margin: '10px', padding: '10px' }}>
                      <MTableToolbar {...props} />
                      <Grid container spacing={3}>
                        {/* TODO viewAllPersonalSalesSheet */}
                        {!this.props.user.group.includes('Sales') && this.props.user.group !== 'Owner' && (
                          <Grid item lg={3}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <InputLabel shrink>User</InputLabel>
                              <Select value={this.state.user} displayEmpty onChange={(event) => this.update('user', event.target.value)}>
                                <MenuItem value="">Summary</MenuItem>
                                {this.state.users.map((user, key) => (
                                  <MenuItem key={key} value={user.uid}>
                                    {user.firstName} {user.lastName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item lg={2}>
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <InputLabel shrink>Month</InputLabel>
                            <Select value={this.state.month} displayEmpty onChange={(event) => this.update('month', event.target.value)}>
                              <MenuItem value="">All</MenuItem>
                              {months.map((month, key) => (
                                <MenuItem key={key} value={key + 1}>
                                  {month}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker views={['year']} label="Year" value={this.state.year} onChange={(v) => this.update('year', v)} />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg={2}>
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <InputLabel shrink>Lead Type</InputLabel>
                            <Select value={this.state.leadType} displayEmpty onChange={(event) => this.update('leadType', event.target.value)}>
                              <MenuItem value="">None</MenuItem>
                              {this.state.dataByLeadType.map((lead, key) => (
                                <MenuItem key={key} value={lead.leadType}>
                                  {lead.leadType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={2}>
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <InputLabel shrink>Sales Type</InputLabel>
                            <Select value={this.state.salesType} displayEmpty onChange={(event) => this.update('salesType', event.target.value)}>
                              <MenuItem value="">None</MenuItem>
                              {this.state.dataBySalesType.map((sale, key) => (
                                <MenuItem key={key} value={sale.salesType}>
                                  {sale.salesType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item lg={1}> */}
                        {/*    <Button variant={'contained'} color={'primary'} */}
                        {/*            onClick={this.getData}>Update</Button> */}
                        {/* </Grid> */}
                        <Grid item lg={1}>
                          <FormControlLabel
                            checked={this.state.showGraphs}
                            labelPlacement="top"
                            control={<Switch />}
                            label="Graphs"
                            onChange={this.toggleGraphs}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ),
                }}
                options={{
                  rowStyle: {
                    fontSize: 14,
                  },
                  exportButton: true,
                  selection: true,
                  padding: 'dense',
                  maxBodyHeight: '788px',
                  paging: true,
                  pageSizeOptions: [50, 100, 200],
                  pageSize: 50,
                  exportCsv: (columns, data) => {
                    const newData = data.map((d) => {
                      const row = {};
                      columns.map((c) => {
                        if (c.title === 'Paid') return (row[c.title] = d.writtenOff ? 'Written Off' : Boolean(d.paidDate));
                        if (c.title === 'Closed') return (row[c.title] = Boolean(d[c.field]));
                        if (c.title === 'Salesman') {
                          const { firstName, lastName } = UsersStore.getUser(d[c.field]);
                          if (firstName) {
                            return (row[c.title] = `${firstName} ${lastName}`);
                          }
                          return (row[c.title] = 'No Salesman');
                        }
                        return (row[c.title] = d[c.field]);
                      });
                      return row;
                    });
                    const csvContent = Papa.unparse(newData, { download: true });
                    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    const user = this.state.users.find((row) => row.uid === this.state.user);
                    link.setAttribute(
                      'download',
                      `${user ? `${user.firstName} ${user.lastName}'s ` : 'Home of '}${this.state.month ? `${this.state.month} ` : ''}${
                        this.state.year ? `${this.state.year} ` : ''
                      }Sales Sheet.csv`,
                    );
                    document.body.appendChild(link);
                    link.click();
                  },
                }}
                style={{
                  marginTop: '10px',
                }}
                onRowClick={(event, rowData) => {
                  // TODO viewWorkOrder
                  if (!this.props.user.group.includes('Production') && !this.props.smtt && !this.props.art) {
                    window.open(`/workorder/${rowData.id}`, '_blank');
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {this.state.showGraphs ? (
                    <Paper style={{ marginTop: '10px', padding: '15px' }}>
                      <Typography variant="h5" component="h5" style={{ margin: '8px' }}>
                        Lead Type
                      </Typography>
                      <Bar data={leadTypeData} options={leadTypeOptions} />
                    </Paper>
                  ) : (
                    <MaterialTable
                      style={smallTableStyle}
                      options={smallTableOptions}
                      columns={leadColumns}
                      isLoading={this.state.ready}
                      onRowClick={(event, rowData) => {
                        this.update('leadType', rowData.leadType);
                      }}
                      data={this.state.dataByLeadType}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {this.state.showGraphs ? (
                    <Paper style={{ marginTop: '10px', padding: '15px' }}>
                      <Typography variant="h5" component="h5" style={{ margin: '8px' }}>
                        Sales Type
                      </Typography>
                      <Bar data={salesTypeData} options={salesTypeOptions} />
                    </Paper>
                  ) : (
                    <MaterialTable
                      style={smallTableStyle}
                      options={smallTableOptions}
                      columns={salesColumns}
                      isLoading={this.state.ready}
                      onRowClick={(event, rowData) => {
                        this.update('salesType', rowData.salesType);
                      }}
                      data={this.state.dataBySalesType}
                    />
                  )}
                </Grid>
              </Grid>
              {this.state.showGraphs ? (
                <Paper style={{ marginTop: '10px', padding: '15px' }}>
                  <Typography variant="h5" component="h5" style={{ margin: '8px' }}>
                    Data by Month
                  </Typography>
                  <Bar data={totalByMonthData} options={totalByMonthOptions} />
                </Paper>
              ) : (
                <MaterialTable
                  style={smallTableStyle}
                  options={smallTableOptions}
                  columns={totalsColumns}
                  isLoading={this.state.ready}
                  // onRowClick={(event, rowData) => {
                  //     this.filterByMonth(rowData.month);
                  // }}
                  data={this.state.dataByMonth}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}
