/* eslint array-callback-return:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, IconButton, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DoneIcon from '@material-ui/icons/Done';
import DateFnsUtils from '@date-io/date-fns';
import { Skeleton } from '@material-ui/lab';
import commaNumber from 'comma-number';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

const SalesGoalsIndividual = ({ refresh, user, get, post }) => {
  const [revenueGoal, setRevenueGoal] = useState(null);
  const [revenueActual, setRevenueActual] = useState(0);
  const [revenueField, setRevenueField] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [goalDate, setGoalDate] = useState(moment());
  const [updateGoal, setUpdateGoal] = useState(false);

  const getSalesGoals = async () => {
    setIsLoading(true);
    await get('/salesgoal', [
      {
        name: 'uid',
        value: user.uid,
      },
      {
        name: 'goalDate',
        value: goalDate.format('YYYY-MM-DD'),
      },
      {
        name: 'month',
        value: goalDate.month() + 1,
      },
      {
        name: 'year',
        value: goalDate.year(),
      },
    ])
      .then((res) => {
        if (res.length) setRevenueGoal(res[0].goalAmount);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setIsLoading(false);
  };

  const getSalesRevenue = async () => {
    await get('/getWorkOrderFinancialsBySalesPerson', [
      {
        name: 'startDate',
        value: goalDate.startOf('month').format('YYYY-MM-DD'),
      },
      {
        name: 'endDate',
        value: goalDate.endOf('month').format('YYYY-MM-DD'),
      },
      {
        name: 'month',
        value: goalDate.month() + 1,
      },
      {
        name: 'year',
        value: goalDate.year(),
      },
    ])
      .then((res) => {
        res.map((salesPerson) => {
          if (salesPerson.userID === user.uid) {
            setRevenueActual(salesPerson.profit ? salesPerson.profit : 0);
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const setSalesGoals = async () => {
    setIsLoading(true);
    await post('/salesgoal/add', {
      uid: user.uid,
      revenueField,
      goalDate: goalDate.format('YYYY-MM-DD'),
    })
      .then(async () => {
        await getSalesGoals();
        setRevenueField(0);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const updateSalesGoals = async () => {
    setIsLoading(true);
    await post('/salesgoal/update', {
      uid: user.uid,
      revenueField,
      goalDate: goalDate.format('YYYY-MM-DD'),
    })
      .then(async () => {
        await getSalesGoals();
        setRevenueField(0);
        setUpdateGoal(false);
      })
      .catch((err) => {
        console.log(err);
        setUpdateGoal(false);
        setIsLoading(false);
      });
  };

  const editOnClick = () => {
    setRevenueField(revenueGoal);
    setUpdateGoal(true);
  };

  const cancelOnClick = () => {
    setRevenueField(0);
    setUpdateGoal(false);
  };

  const handleGoalDateChange = (date) => {
    const newDate = moment(date);
    setGoalDate(newDate);
  };

  useEffect(() => {
    if (revenueActual || revenueGoal) {
      setRevenueGoal(null);
      setRevenueActual(0);
    }
    getSalesGoals();
    getSalesRevenue();
  }, [refresh, goalDate]);
  return (
    <Card style={{ height: '100%' }}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <div className="sales-goal-header">
          <Typography style={{ fontWeight: 700 }} color="textSecondary" gutterBottom variant="body2">
            GROSS PROFIT GOAL
          </Typography>
        </div>
        {isLoading ? (
          <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height="50px" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width="100%" height="50px" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width="100%" height="50px" />
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={4} style={{ padding: '20px 0', margin: '0 auto', textAlign: 'center' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                  views={['month', 'year']}
                  label="Goal Month"
                  value={goalDate}
                  onChange={(date) => handleGoalDateChange(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={4} style={{ textAlign: 'center' }}>
              {revenueGoal && !updateGoal ? (
                <>
                  <Typography variant="h5">Goal</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h3">${commaNumber(revenueGoal)}</Typography>
                    <Tooltip title="Edit Goal" placement="top">
                      <IconButton size="small" style={{ marginLeft: '4px' }} onClick={editOnClick}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={revenueField}
                    type="number"
                    onChange={(e) => setRevenueField(e.target.value)}
                    name="revenueGoal"
                    label="Enter Goal"
                    id="revenueGoal"
                    style={{ margin: 0 }}
                  />
                  {revenueField > 0 && (
                    <Tooltip title="Submit Goal" placement="top">
                      <IconButton size="small" style={{ marginLeft: '4px' }} onClick={updateGoal ? updateSalesGoals : setSalesGoals}>
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {updateGoal && (
                    <Tooltip title="Cancel Update" placement="top">
                      <IconButton size="small" style={{ marginLeft: '4px' }} onClick={cancelOnClick}>
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={6} md={4} style={{ textAlign: 'center' }}>
              <Typography variant="h5">Actual</Typography>
              <Typography variant="h3">${commaNumber(revenueActual)}</Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default SalesGoalsIndividual;
