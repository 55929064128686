import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../Functions/API.js';
import MaterialTable from 'material-table';

const WorkOrdersByWeek = ({ week, year, uids }) => {
  const [workOrders, setWorkOrders] = useState([]);

  useEffect(() => {
    get('/workorder/invoice-date-week', { week, year, uids }).then(setWorkOrders);
  }, []);

  const columns = [
    { title: 'WO', field: 'workOrderID' },
    { title: 'Customer', field: 'customerName' },
    { title: 'Salesmen', field: 'salesmen' },
    { title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
    { title: 'Invoices', field: 'invoice', type: 'currency' },
    {
      title: 'Costs',
      field: 'cost',
      type: 'currency',
    },
    {
      title: 'Profit',
      field: 'profit',
      type: 'currency',
    },
    {
      title: 'PM',
      field: 'profitMargin',
      type: 'numeric',
    },
  ];

  return (
    <div>
      <MaterialTable
        title={`Work Orders For Week ${week}`}
        columns={columns}
        data={workOrders}
        options={{ showTitle: false, padding: 'dense', paging: false, maxBodyHeight: '500px', exportButton: true }}
        onRowClick={(e, { id }) => window.open(`/workorder/${id}`)}
      />
    </div>
  );
};

WorkOrdersByWeek.propTypes = {
  week: PropTypes.number,
  year: PropTypes.number,
  uids: PropTypes.array,
};

export default WorkOrdersByWeek;
