/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
import React from 'react';
import { Grid, Button, Card, CardContent, CardHeader, LinearProgress } from '@material-ui/core';

const Grouping = ({ groupBy, toggleSelected, update, loading }) => {
  return (
    <Card>
      <CardHeader title="Group By" />
      <CardContent>
        <Grid container spacing={1} justify="flex-start">
          {groupBy.map((group, key) => (
            <Grid item key={key} style={key === groupBy.length - 1 ? { flexGrow: 1 } : {}}>
              <Button size={'small'} variant="contained" onClick={() => toggleSelected(group)} color={group.selected ? 'primary' : 'default'}>
                {group.title}
              </Button>
            </Grid>
          ))}
          <Grid item>
            <Button size={'small'} variant="contained" color="secondary" onClick={update}>
              Update
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      {loading && <LinearProgress />}
    </Card>
  );
};

export default Grouping;
