/* eslint react/no-unescaped-entities:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import YBA from '../../images/yba-logo.png';

const QuoteHeader = (props) => {
  return (
    <Paper style={{ backgroundColor: 'rgb(234, 234, 234)', paddingTop: '10px' }}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <img src={YBA} style={{ width: '200px' }} />
        </Grid>
        <Grid item>
          <div style={{ width: '100px' }} />
        </Grid>
        <Grid item>
          <h2 style={{ marginBottom: '5px' }}>YBA Shirts</h2>
          <p style={{ fontSize: 'large', lineHeight: 0 }}>"We make it EASY for you!"</p>
          <div>
            <Button href="https://ybashirts.com">www.ybashirts.com</Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteHeader;
