/* eslint-disable react/display-name */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint react/no-unescaped-entities:0 */
/* eslint react/button-has-type:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-alert:0 */
/* eslint no-return-await:0 */
/* eslint consistent-return:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-vars:0 */
/* eslint no-console:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-plusplus:0 */
/* eslint radix:0 */
/* eslint react/no-access-state-in-setstate:0 */

import React, { Component, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import { Done, Error, DoneAll } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ErrorPopup from './ErrorPopup';
import { auth } from '../../../../../fire';
import { get, post, getHomePage } from '../../../../../Functions/API';
import UserStore from '../../../../../Stores/User/Store';
import VendorsStore from '../../../../../Stores/Vendors/Store';
import InventoryStore from '../../../../../Stores/Inventory/Store';
import { createSnack } from '../../../../../Stores/Snack/Actions';

const ClockInAndOut = ({
  returnedId,
  line,
  clockInLine,
  type,
  num,
  workOrderID,
  updateFinishedInDB,
  resetCount,
  resetPackaging,
  updateQuantity,
  workOrder,
}) => {
  const [clockInTitle, setClockinTitle] = useState('');
  const [returnedId2, setReturnedId] = useState(returnedId);
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorSize, setErrorSize] = useState(null);
  const [errorNum, setErrorNum] = useState(null);
  const user = UserStore.getUser();
  const homepage = getHomePage();
  const vendor = VendorsStore.getVendorById(line.vendorID);
  const inventory = InventoryStore.getInventoryById(line.inventoryId);
  const addSKU = InventoryStore.getSKUById(line.addSKU);
  const removeSKU = InventoryStore.getSKUById(line.removeSKU);
  const button = { margin: '15px' };
  const startButton = { ...button, backgroundColor: 'MediumSeaGreen' };
  const finishButton = { ...button, color: 'white', backgroundColor: 'red' };

  useEffect(() => {
    if (returnedId) {
      setClockinTitle(`Clocked in at: ${line.clockInTime}`);
    }
  }, []);

  const clockIn = (workOrderID, type, homepage, line) => {
    const send = {
      workOrderID,
      userID: user.uid,
      ...line,
      type,
    };
    post('/clockIn', send)
      .then((responseJSON) => {
        clockInLine(line, true);
        const date = new Date();
        const hour = date.getHours();
        const minutes = date.getMinutes();
        setClockinTitle(`Clocked in at: ${hour > 12 ? hour - 12 : hour}:${minutes}`);
        setModal(true);
        setReturnedId(responseJSON.returnedId);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const clockOut = (returnedId, workOrderID, type, homepage, line) => {
    const send = {
      returnedId,
      workOrderID,
      line,
      userID: user.uid,
      type,
    };
    post('/clockOut', send)
      .then((responseJSON) => {
        clockInLine(line, false);
        setClockinTitle('');
        setModal(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const finished = (returnedId, workOrderID, type, homepage, line) => {
    const c = window.confirm("Are you sure you're finished? Please double check that this is completely done");
    if (c) {
      const missing = line.sizes.some((size) => size.packageNum < size.quantity);
      const send = {
        returnedId,
        workOrderID,
        line,
        finished: true,
        userID: user.uid,
        type,
        missing,
      };
      post('/clockOut', send)
        .then(() => {
          clockInLine(line, false, true);
          setClockinTitle('');
          setModal(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const toggleErrorModal = (errorSize, errorNum) => {
    setErrorNum(errorNum);
    setErrorSize(errorSize);
    setErrorModal(!errorModal);
  };

  const matchCount = (rowData) => {
    const type2 = type === 'count' ? 'countNum' : 'packageNum';
    const fixed = rowData[type2] > 0 && rowData[type2] !== rowData.quantity;
    post('/updateCount', { size: rowData, quantity: rowData.quantity, type })
      .then(() => {
        updateQuantity(rowData.quantity, rowData, num, type2);
        if (fixed) {
          toggleErrorModal(rowData, num);
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
        console.error(err);
      });
  };

  const CustomTextField = (title, value) => {
    return <TextField label={title} value={value} style={{ margin: '5px' }} margin="normal" component="div" />;
  };

  return (
    <div>
      <div>{clockInTitle}</div>
      {line && !line.clockIn && (
        <Button
          variant="contained"
          style={startButton}
          href=""
          disabled={type === 'count' ? line.countFinished || line.countTransferPending : line.packagingFinished || line.packagingTransferPending}
          onClick={() => clockIn(workOrderID, type, homepage, line)}
        >
          Clock In
        </Button>
      )}
      {Boolean(type === 'count' && line.countTransferPending) && <div>Removal from inventory is pending</div>}
      {Boolean(type === 'packaging' && line.packagingTransferPending) && <div>Adding to inventory is pending</div>}
      {line && line.clockIn && (
        <Button variant="contained" style={finishButton} onClick={() => setModal(!modal)}>
          Open
        </Button>
      )}
      {user.hasPermission('updateFinished') && (
        <Switch
          onChange={() => updateFinishedInDB(line, type)}
          color="primary"
          value={type === 'count' ? line.countFinished : line.packagingFinished}
          checked={type === 'count' ? line.countFinished : line.packagingFinished}
        />
      )}
      <Modal open={modal} style={{ top: '10%' }}>
        <Slide direction="up" in={modal}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              backgroundColor: '#ffffff',
              height: '100%',
              overflowX: 'auto',
              outline: 'none',
            }}
          >
            <MaterialTable
              title={type === 'count' ? 'Count' : 'Package'}
              components={{
                Toolbar: (tempProps) => (
                  <div>
                    <MTableToolbar {...tempProps} />
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: '15px',
                      }}
                    >
                      <div>
                        <>{clockInTitle}</>
                        {line && line.clockIn && (
                          <>
                            <Button
                              variant="contained"
                              style={finishButton}
                              href=""
                              onClick={() => clockOut(returnedId2, workOrderID, type, homepage, line)}
                            >
                              Clock Out
                            </Button>
                            <Button
                              variant="contained"
                              style={finishButton}
                              href=""
                              onClick={() => finished(returnedId2, workOrderID, type, homepage, line)}
                            >
                              Finished
                            </Button>
                          </>
                        )}
                      </div>
                      {Boolean(line.misprint) && <h2 style={{ color: 'red' }}>Replacement Item</h2>}
                      {!line.useInventory && (
                        <div>
                          {workOrder.orderType === 'inventory' && (
                            <>
                              {CustomTextField('Vendor', vendor?.name)}
                              {CustomTextField('Add SKU', `${addSKU.sku} - ${addSKU.name}`)}
                            </>
                          )}
                          {workOrder.orderType !== 'inventory' && (
                            <>
                              {CustomTextField('Type', line.type)}
                              {CustomTextField('Vendor', vendor?.name)}
                              {CustomTextField('Adult Style', line.adultStyle)}
                              {CustomTextField('Youth Style', line.youthStyle)}
                              {CustomTextField('Color', line.color)}
                              {line.team ? CustomTextField('Team', line.team) : null}
                            </>
                          )}
                          {CustomTextField('Notes', line.notes)}
                        </div>
                      )}
                      {Boolean(line.useInventory) && (
                        <div>
                          {CustomTextField('Inventory', inventory.name)}
                          {CustomTextField('Remove SKU', `${removeSKU?.sku} - ${removeSKU?.name}`)}
                          {workOrder.orderType === 'inventory' && <>{CustomTextField('Add SKU', `${addSKU?.sku} - ${addSKU?.name}`)}</>}
                          {CustomTextField('Notes', line.notes)}
                        </div>
                      )}
                      {type === 'count' ? (
                        <Button style={{ margin: '8px' }} variant="contained" color="secondary" onClick={() => resetCount(line)}>
                          Reset Counts
                        </Button>
                      ) : (
                        <Button style={{ margin: '8px' }} variant="contained" color="secondary" onClick={() => resetPackaging(line)}>
                          Reset Packaging
                        </Button>
                      )}
                      <Grid container spacing={1} justify="center">
                        {line.artIDs &&
                          line.art.map((chip, key) => (
                            <Grid item key={key}>
                              <Button href={chip.url} target="_blank" color="primary" variant="contained">
                                {`${chip.color} ${chip.printType} ${chip.position}`}
                              </Button>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </div>
                ),
              }}
              columns={[
                { field: 'size', title: 'Size', render: (rowData) => rowData.size.toUpperCase() },
                { field: 'quantity', title: 'Expected' },
                type === 'count'
                  ? {
                      field: 'countNum',
                      title: 'Count',
                    }
                  : { field: 'packageNum', title: 'Package' },
                {
                  title: 'Match',
                  render: (rowData) => (
                    <div>
                      <IconButton onClick={() => matchCount(rowData)}>
                        {rowData.quantity === (type === 'count' ? rowData.countNum : rowData.packageNum) ? (
                          <DoneAll fontSize="small" style={{ color: 'green' }} />
                        ) : (
                          <Done fontSize="small" />
                        )}
                      </IconButton>
                    </div>
                  ),
                },
                {
                  title: 'Error',
                  render: (rowData) => (
                    <div>
                      <IconButton onClick={() => toggleErrorModal(rowData, type)}>
                        <Error
                          fontSize="small"
                          style={rowData.quantity === (type === 'count' ? rowData.countNum : rowData.packageNum) ? {} : { color: 'red' }}
                        />
                      </IconButton>
                    </div>
                  ),
                },
              ]}
              data={line.sizes}
              options={{
                paging: false,
                search: false,
                fixedColumns: {
                  left: 1,
                },
              }}
            />
          </div>
        </Slide>
      </Modal>
      <Modal open={errorModal} style={{ top: '10%' }}>
        <ErrorPopup type={type} size={errorSize} num={num} updateQuantity={updateQuantity} close={() => toggleErrorModal(errorSize, type)} />
      </Modal>
    </div>
  );
};

export default ClockInAndOut;
