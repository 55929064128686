/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useState, forwardRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TableBody, TableRow, TableHead, TableCell, TableSortLabel, Table, Grid, Button, TableContainer } from '@material-ui/core';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="1" direction="vertical">
        {(provided) => {
          return (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const InvoiceDragModal = forwardRef(({ invoices, headers, close, save }, ref) => {
  const [height] = useState(null);
  const [localInvoices, setLocalInvoices] = useState(invoices);
  const headerKeys = headers.map((header) => header.field).filter((headerKey) => headerKey);
  const [activeColumn, setactiveColumn] = useState(null);
  const [activeSort, setactiveSort] = useState('desc');

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedInvoices = reorder(localInvoices, result.source.index, result.destination.index);
    setLocalInvoices(reorderedInvoices);
  };

  const sort = (a, b, rowField, sortModifier) => {
    let val = 0;
    if (typeof a[rowField] === 'string' && typeof b[rowField] === 'string') {
      val = a[rowField].localeCompare(b[rowField]);
    } else {
      let aVal = 0;
      let bVal = 0;
      if (Array.isArray(a[rowField]) && Array.isArray(b[rowField])) {
        aVal = a[rowField].length;
        bVal = b[rowField].length;
      } else if (typeof a[rowField] === 'number' && typeof b[rowField] === 'number') {
        aVal = a[rowField];
        bVal = b[rowField];
      } else {
        console.error('comparing incompatible types!');
      }
      if (aVal < bVal) {
        val = -1;
      }
      if (aVal > bVal) {
        val = 1;
      }
    }
    return val * sortModifier;
  };

  const sortTable = (rowField) => {
    setactiveColumn(rowField);
    const sortedInvoices = [...localInvoices];
    // sort data
    const sortModifier = activeSort === 'asc' ? 1 : -1;

    sortedInvoices.sort((a, b) => sort(a, b, rowField, sortModifier));
    setLocalInvoices(sortedInvoices);

    if (activeSort === 'desc') {
      setactiveSort('asc');
    } else {
      setactiveSort('desc');
    }
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        width: '100%',
        backgroundColor: '#ffffff',
        padding: '15px',
        height: '100%',
        overflowX: 'auto',
        outline: 'none',
      }}
    >
      <Grid container justify="flex-end">
        <Grid item xs={10}>
          Drag invoices to order them
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <Button variant="contained" style={{ marginRight: '25px' }} onClick={() => save(localInvoices)}>
            Save
          </Button>
          <Button variant="contained" style={{ marginRight: '25px' }} onClick={close}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      <TableContainer style={{ maxHeight: `${height}px` }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((row, index) => {
                if (headerKeys.includes(row.field)) {
                  return (
                    <TableCell key={index} onClick={() => sortTable(row.field)}>
                      {row.field === activeColumn && (
                        <TableSortLabel active direction={activeSort}>
                          {row.title}
                        </TableSortLabel>
                      )}
                      {row.field !== activeColumn && <TableSortLabel>{row.title}</TableSortLabel>}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody component={DroppableComponent(onDragEnd)}>
            {localInvoices.map((invoice, index) => (
              <TableRow component={DraggableComponent(invoice.id, index)} key={invoice.id}>
                {headerKeys.map((key) => {
                  if (Array.isArray(invoice[headers.find((header) => header.field === key).field])) {
                    return (
                      <TableCell key={`${key}-${invoice.id}`}>
                        {invoice.sizes.map(
                          (size, sizeKey) => `${size.quantity} ${size.size.toUpperCase()}${sizeKey < invoice.sizes.length - 1 ? ', ' : ' '}`,
                        )}
                      </TableCell>
                    );
                  }
                  return <TableCell key={`${key}-${invoice.id}`}>{invoice[headers.find((header) => header.field === key).field]}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default InvoiceDragModal;
