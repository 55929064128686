import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { get } from '../../../../../Functions/API';

const Inventory = ({ workOrder }) => {
  const [inventories, setInventories] = useState([]);

  useEffect(() => {
    get('/getInventories', []).then((results) => {
      setInventories(results);
    });
  }, []);

  return (
    <FormControl style={{ margin: '8px', width: '100%' }} component="div">
      <InputLabel shrink>Inventory</InputLabel>
      <Select
        value={workOrder.inventoryId}
        onChange={(e) => {
          updateWorkOrder(workOrder.id, { inventoryId: e.target.value });
        }}
      >
        {inventories.map((inventory, key) => (
          <MenuItem key={key} value={inventory.id}>
            {inventory.name}
          </MenuItem>
        ))}
        <MenuItem value="add">Add New Inventory</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Inventory;

Inventory.propTypes = {
  workOrder: PropTypes.object,
};
