/* eslint-disable react/display-name */
/* eslint react/no-array-index-key:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState, forwardRef } from 'react';
import MaterialTable, { MTableToolbar, MTableGroupbar } from 'material-table';
import { Grid } from '@material-ui/core';
import VendorsStore from '../../Stores/Vendors/Store';
import { fetchVendors } from '../../Stores/Vendors/Actions';

const OrderArtSummary = forwardRef(({ orders, art }, ref) => {
  const divRef = React.createRef();
  const [height, setHeight] = useState(0);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    } else {
      getVendors();
    }
    return () => VendorsStore.removeListener('change', getVendors);
  }, []);

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  useEffect(() => {
    const height = divRef.current.clientHeight - 125;
    setHeight(height);
  }, []);

  const columns = [
    { title: 'Type', field: 'type' },
    {
      title: 'Vendor',
      field: 'vendorID',
      render: (rowData) => {
        const vendor = vendors.find((v) => v.id === rowData.vendorID);
        return <div>{vendor?.name}</div>;
      },
    },
    { title: 'Adult Style', field: 'adultStyle' },
    { title: 'Youth Style', field: 'youthStyle' },
    { title: 'Color', field: 'color' },
    { title: 'Team', field: 'team' },
    {
      title: 'Sizes',
      render: (rowData) => (
        <div>
          {rowData.sizes.map((size, key) => (
            <p style={{ fontSize: 'smaller', lineHeight: 0.3 }} key={key}>
              {size.size.toUpperCase()}:{size.quantity}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: 'Total Quantity',
      render: (rowData) => <div>{rowData.sizes.reduce((total, size) => total + size.quantity, 0)}</div>,
    },
    { title: 'Notes', field: 'notes' },
  ];
  return (
    <div
      style={{
        position: 'absolute',
        outline: 'none',
        width: '100%',
        backgroundColor: '#ffffff',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
      }}
      ref={divRef}
    >
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12} md={6}>
          <iframe src={art.url} style={{ width: '100%', height: '550px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title={`Orders with ${art?.printType?.name} ${art.color} ${art.position}`}
            columns={columns}
            data={orders}
            options={{
              maxBodyHeight: `${height}px`,
              paging: false,
              grouping: true,
              exportButton: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
});

OrderArtSummary.displayName = 'OrderArtSummary';

export default OrderArtSummary;
