/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Checkbox, Paper } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      availableFilters: [],
      availableGroups: [],
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState(results);
      })
      .catch((err) => {
        window.alert(err);
      });
  }

  setup = async () => {
    let filters;
    let availableFilters;
    let availableGroups;
    let error = '';
    await this.props
      .get('/getAllDashboardFilters', [])
      .then((results) => {
        filters = results;
      })
      .catch((err) => {
        error += 'Could not get dashboard filters. ';
      });
    await this.props
      .get('/availableFilters', [])
      .then((results) => {
        availableFilters = results;
      })
      .catch((err) => {
        error += 'Could not get available filters. ';
      });
    await this.props
      .get('/availableGroups', [])
      .then((results) => {
        availableGroups = results;
      })
      .catch((err) => {
        error += 'Could not get available groups.';
      });
    if (filters && availableFilters && availableGroups) {
      return {
        filters,
        availableFilters,
        availableGroups,
      };
    }
    throw new Error(error);
  };

  addFilter = (newData) => {
    this.props
      .post('/addDashboardFilter', newData)
      .then((filters) => {
        this.setState({
          filters,
        });
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  editFilter = (newData, oldData) => {
    const { filters } = this.state;
    const index = filters.indexOf(oldData);
    filters[index] = newData;
    this.props
      .post('/updateFilterTitle', newData)
      .then(() => {
        this.setState({
          filters,
        });
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  updateGroupsAndFilters = (newData) => {
    this.props
      .post('/updateGroupsAndFilters', newData)
      .then((filters) => {
        this.setState({
          filters,
        });
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  edit = (groups, rowData, edit, id, available) => {
    groups = this.getObjects(groups, available, id);
    const { filters } = this.state;
    const index = this.state.filters.indexOf(rowData);
    filters[index][edit] = groups;
    this.setState({
      filters,
    });
  };

  getValues = (groups, id) => {
    const values = [];
    for (const value of groups) {
      values.push(value[id]);
    }
    return values;
  };

  getObjects = (groups, available, id) => {
    const values = [];
    for (const value of groups) {
      values.push(this.state[available].find((row) => row[id] === value));
    }
    return values;
  };

  render() {
    const columns = [
      { field: 'title', title: 'Title' },
      { field: 'sort', title: 'Sort', type: 'numeric' },
      {
        field: 'groups',
        title: 'Groups',
        render: (rowData) => (
          <Select
            value={this.getValues(rowData.groups, 'groupID')}
            multiple
            style={{ maxWidth: '250px' }}
            onChange={(e) => this.edit(e.target.value, rowData, 'groups', 'groupID', 'availableGroups')}
            renderValue={(selected) => {
              const values = [];
              for (const value of selected) {
                values.push(this.state.availableGroups.find((row) => row.groupID === value).name);
              }
              return values.join(', ');
            }}
          >
            {this.state.availableGroups.map((option, key) => (
              <MenuItem key={key} value={option.groupID}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        ),
        editComponent: (t) => <div />,
      },
      {
        field: 'completedFilters',
        title: 'Completed Filters',
        render: (rowData) => (
          <Select
            value={this.getValues(rowData.completedFilters, 'filterID')}
            multiple
            style={{ maxWidth: '250px' }}
            onChange={(e) => this.edit(e.target.value, rowData, 'completedFilters', 'filterID', 'availableFilters')}
            renderValue={(selected) => {
              const values = [];
              for (const value of selected) {
                values.push(this.state.availableFilters.find((row) => row.filterID === value).filterName);
              }
              return values.join(', ');
            }}
          >
            {this.state.availableFilters.map((option, key) => (
              <MenuItem key={key} value={option.filterID}>
                {option.filterName}
              </MenuItem>
            ))}
          </Select>
        ),
        editComponent: (t) => <div />,
      },
      {
        field: 'uncompletedFilters',
        title: 'Uncompleted Filters',
        render: (rowData) => (
          <Select
            value={this.getValues(rowData.uncompletedFilters, 'filterID')}
            multiple
            style={{ maxWidth: '250px' }}
            onChange={(e) => this.edit(e.target.value, rowData, 'uncompletedFilters', 'filterID', 'availableFilters')}
            renderValue={(selected) => {
              const values = [];
              for (const value of selected) {
                values.push(this.state.availableFilters.find((row) => row.filterID === value).filterName);
              }
              return values.join(', ');
            }}
          >
            {this.state.availableFilters.map((option, key) => (
              <MenuItem key={key} value={option.filterID}>
                {option.filterName}
              </MenuItem>
            ))}
          </Select>
        ),
        editComponent: (t) => <div />,
      },
      {
        title: 'Submit',
        render: (rowData) => (
          <Button variant="contained" onClick={() => this.updateGroupsAndFilters(rowData)}>
            Submit
          </Button>
        ),
      },
    ];

    return (
      <Paper>
        <MaterialTable
          title="Filters"
          columns={columns}
          data={this.state.filters}
          editable={{
            onRowAdd: async (newData) => {
              await this.addFilter(newData);
            },
            onRowUpdate: async (newData, oldData) => {
              await this.editFilter(newData, oldData);
            },
            onRowDelete: async (oldData) => {},
          }}
        />
      </Paper>
    );
  }
}

export default Filters;
