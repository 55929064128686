import { useHistory } from 'react-router-dom';
import dispatcher from './dispatcher';
import { get, patch, post } from '../../../Functions/API';
import { createSnack } from '../../Snack/Actions';
import { fetchStatuses } from '../../../Stores/Statuses/Actions';
import { fetchCustomers } from '../../../Stores/Fulfillment/Customers/Actions';
import { fetchCountries } from '../../../Stores/Countries/Actions';
import { fetchStores } from '../../../Stores/Fulfillment/Stores/Actions';
import Store from './Store';

export const loadQueryFilters = async (query) => {
  let newFilters = { ...Store.getFilters() };
  const statuses = await fetchStatuses();
  const customers = await fetchCustomers();
  const stores = await fetchStores();
  const countries = await fetchCountries();
  if ('countryId' in query) {
    const country = countries.find((country) => country.id === parseInt(query.countryId));
    newFilters['country'] = country;
  }
  if ('status' in query) {
    if (Array.isArray(query.status)) {
      const integerStatuses = query.status.map((status) => parseInt(status));
      const newStatusFilters = statuses.filter((status) => integerStatuses.includes(status.id));
      newFilters['statuses'] = newStatusFilters;
    } else {
      const newStatusFilters = statuses.filter((status) => status.id === parseInt(query.status));
      newFilters['statuses'] = newStatusFilters;
    }
  }
  if ('customer' in query) {
    const customer = customers.find((customer) => customer.id === parseInt(query.customer));
    if (customer) {
      newFilters['customer'] = customer;
    }
  }
  if ('store' in query) {
    const store = stores.find((store) => store.id === parseInt(query.store));
    if (store) {
      newFilters['store'] = store;
    }
  }
  if ('storeId' in query) {
    newFilters.storeId = query.storeId;
  }
  if ('startDate' in query) {
    const [month, day, year] = query.startDate.split('-');
    const startDate = new Date(year, month - 1, day); // -1 month because index
    newFilters['startDate'] = startDate;
  }
  if ('endDate' in query) {
    const [month, day, year] = query.endDate.split('-');
    const endDate = new Date(year, month - 1, day); // -1 month because index
    newFilters['endDate'] = endDate;
  }
  if ('page' in query) {
    newFilters['page'] = parseInt(query.page);
  }
  if ('rowsPerPage' in query) {
    newFilters['rowsPerPage'] = parseInt(query.rowsPerPage);
  }
  return dispatcher.dispatch({
    type: 'UPDATE_FILTERS',
    newFilters,
  });
};

export const updateFilters = (filters) => {
  let newFilters = { ...Store.getFilters() };
  for (let key in filters) {
    newFilters[key] = filters[key];
  }
  dispatcher.dispatch({
    type: 'UPDATE_FILTERS',
    newFilters,
  });
};

export const updateFilterCount = (data) => {
  let newFilters = { ...Store.getFilters() };
  for (let key in data) {
    newFilters[key] = data[key];
  }
  dispatcher.dispatch({
    type: 'UPDATE_COUNT',
    newFilters,
  });
};
