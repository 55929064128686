import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { get } from '../../../../../Functions/API.js';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const Skus = () => {
  const [skus, setSkus] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const { productId, lobId } = useParams();

  useEffect(() => {
    load(productId, page, pageSize);
  }, [productId, page, pageSize]);

  const load = async (productId, page, pageSize) => {
    setLoading(true);
    const { results, total } = await get('/fulfillment/ybaSkus', {
      filter: { eager: { rawMaterial: { brand: {}, style: {}, color: {}, size: {} }, $where: { ybaProductId: productId } } },
      page,
      pageSize,
    });
    setSkus(results);
    setRowCount(total);
    setLoading(false);
  };

  const columns = [
    { field: 'sku', headerName: 'Sku', width: 200 },
    { field: 'brand', headerName: 'Brand', width: 200, valueGetter: ({ row }) => row.rawMaterial?.brand?.brand },
    { field: 'style', headerName: 'Style', width: 200, valueGetter: ({ row }) => row.rawMaterial?.style?.style },
    { field: 'color', headerName: 'Color', width: 200, valueGetter: ({ row }) => row.rawMaterial?.color?.color },
    { field: 'size', headerName: 'Size', width: 200, valueGetter: ({ row }) => row.rawMaterial?.size?.size },
  ];

  return (
    <div>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Link href={'/info-plus/lobs'}>Lines of Business</Link>
        <Link href={`/info-plus/lobs/${lobId}/products`}>Products</Link>
        <Typography>Skus</Typography>
      </Breadcrumbs>
      <DataGrid
        autoHeight
        columns={columns}
        rows={skus}
        paginationMode={'server'}
        rowCount={rowCountState}
        components={{ Toolbar: GridToolbar }}
        page={page}
        density={'compact'}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        loading={loading}
      />
    </div>
  );
};

export default Skus;
