/* eslint react/forbid-prop-types:0 */
/* eslint no-unused-vars:0 */
/* eslint react/no-array-index-key:0 */
/* eslint radix:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CardActions,
  Slide,
} from '@material-ui/core';
import { Info, Delete, FileCopy, Save, Refresh, Add, Edit } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CustomTooltip from './CustomTooltip';
import QuoteCosts from './QuoteCosts';
import CatalogFeatures from './CatalogFeatures';

const QuoteProduct = (props) => {
  return (
    <Card
      style={{
        border: props.product.saved ? '2px solid rgb(234, 234, 234)' : '2px solid red',
        height: '100%',
      }}
    >
      <Modal
        open={props.showCosts}
        onClose={() => props.toggleShowCosts(props.row, props.product)}
        style={{ width: '100%', height: '100%', marginTop: '10%' }}
      >
        <Slide direction="up" in={props.showCosts}>
          <QuoteCosts {...props} />
        </Slide>
      </Modal>
      <CardHeader
        title={
          <TextField
            label="Title"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => props.updateProduct(props.row, props.product, 'title', event.target.value)}
            value={props.title}
            style={{ width: '100%' }}
          />
        }
        style={{ backgroundColor: '#eaeaea', textAlign: 'center' }}
        subheader={<div>{props.art.map((art) => `- ${art.printType} ${art.position} `)}-</div>}
      />
      <CardHeader
        title={
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 'xx-large' }}>${props.bill}</span>
            <span
              style={{
                fontSize: 'small',
                color: 'rgba(0, 0, 0, 0.54)',
              }}
            >
              /piece
            </span>
          </div>
        }
        subheader={<div style={{ textAlign: 'center' }}>@{props.quantity} total pieces</div>}
      />
      <Grid container spacing={1} justify="space-around">
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <img
            src={props.options.find((row) => row.id === props.optionId) ? props.options.find((row) => row.id === props.optionId).image : ''}
            alt=""
            style={{
              // height: '317px',
              maxWidth: '100%',
            }}
          />
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={6}>
            <Badge
              style={{ width: '100%' }}
              badgeContent={
                <IconButton size="small" onClick={() => props.updateArtCosts(props.row, props.product)}>
                  <Refresh fontSize="small" color="primary" />
                </IconButton>
              }
            >
              <TextField
                type="number"
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                label="Quantity"
                onChange={(event) => props.updateProduct(props.row, props.product, 'quantity', event.target.value)}
                value={props.quantity}
              />
            </Badge>
          </Grid>
          <Grid item xs={6}>
            {/* <CustomTooltip */}
            {/*    PopperProps={{ */}
            {/*        disablePortal: true, */}
            {/*    }} */}
            {/*    placement={'left'} */}
            {/*    style={{width: '100%'}} */}
            {/*    interactive */}
            {/*    open={props.showCosts} */}
            {/*    onClose={() => props.toggleShowCosts(props.row, props.product)} */}
            {/*    disableFocusListener */}
            {/*    disableHoverListener */}
            {/*    disableTouchListener */}
            {/*    title={ */}
            {/*        <QuoteCosts {...props}/> */}
            {/*    } */}
            {/* > */}
            <Badge
              badgeContent={
                <IconButton size="small" onClick={() => props.toggleShowCosts(props.row, props.product)}>
                  <Info fontSize="small" color={props.showCosts ? 'secondary' : 'primary'} />
                </IconButton>
              }
              style={{ width: '100%' }}
            >
              <TextField
                label="Cost"
                style={{ width: '100%' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={(
                  props.price +
                  props.art.reduce((total, art) => total + art.cost, 0) +
                  props.costs.reduce((total, cost) => total + cost.cost, 0)
                ).toFixed(2)}
              />
            </Badge>
            {/* </CustomTooltip> */}
          </Grid>
        </Grid>
        <CustomTooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement="right"
          interactive
          open={props.showFeatures}
          onClose={() => props.toggleShowFeatures(props.row, props.product)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <CatalogFeatures
              get={props.get}
              close={props.toggleShowFeatures}
              newFeature={props.newFeatureWithFeature}
              row={props.row}
              product={props.product}
            />
          }
        >
          <List>
            <ListItem>
              <ListItemText
                primary="Available Colors"
                secondary={
                  <>
                    {props.options.map(
                      (swatch, key) =>
                        swatch.swatch !== '' && (
                          <img
                            src={swatch.swatch}
                            onClick={(event) => props.updateProduct(props.row, props.product, 'optionId', parseInt(event.target.id))}
                            style={{
                              maxWidth: '30px',
                              border:
                                props.options.indexOf(props.options.find((row) => row.id === props.optionId)) !== key
                                  ? '2px solid #f5f5f5'
                                  : '2px solid #f50057',
                            }}
                            id={swatch.id}
                            key={key}
                            alt=""
                          />
                        ),
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Features" secondary={!props.product.id && 'Save this product to add features'} />
              <ListItemSecondaryAction>
                <IconButton size="small" disabled={!props.product.id} onClick={() => props.newFeature(props.row, props.product)}>
                  <Add fontSize="small" color={props.product.id && 'primary'} />
                </IconButton>
                <IconButton size="small" disabled={!props.product.id} onClick={() => props.toggleShowFeatures(props.row, props.product)}>
                  <ListIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            {props.features.map((feature, key) => (
              <ListItem key={key}>
                <ListItemText>
                  {feature.editing ? (
                    <TextField
                      value={feature.feature}
                      multiline
                      color="secondary"
                      style={{ width: '90%' }}
                      onChange={(event) => props.updateFeature(props.row, props.product, feature, event.target.value)}
                    />
                  ) : (
                    feature.feature
                  )}
                </ListItemText>
                <ListItemSecondaryAction>
                  {feature.editing ? (
                    <IconButton size="small" onClick={() => props.saveFeature(props.row, props.product, feature)}>
                      <Save fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton size="small" onClick={() => props.editFeature(props.row, props.product, feature)}>
                      <Edit fontSize="small" color="primary" />
                    </IconButton>
                  )}
                  <IconButton size="small" onClick={() => props.deleteFeature(props.row, props.product, feature)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CustomTooltip>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={4}>
            <TextField
              label="Bill"
              style={{ width: '100%' }}
              type="number"
              onChange={(event) => props.updateProduct(props.row, props.product, 'bill', event.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={props.bill}
            />
          </Grid>
          <Grid item xs={4}>
            <Badge
              badgeContent={
                <IconButton size="small" onClick={(event) => props.updateProduct(props.row, props.product, 'marginSubmit', props.product.margin)}>
                  <Refresh
                    fontSize="small"
                    color={
                      (
                        ((props.bill -
                          props.art.reduce((total, art) => total + art.cost, 0) -
                          props.costs.reduce((total, cost) => total + cost.cost, 0) -
                          props.price) /
                          props.bill) *
                        100
                      ).toFixed(2) === props.product.margin
                        ? 'primary'
                        : 'secondary'
                    }
                  />
                </IconButton>
              }
            >
              <TextField
                label="Margin"
                type="number"
                style={{ width: '100%' }}
                onChange={(event) => props.updateProduct(props.row, props.product, 'margin', event.target.value)}
                error={((props.bill - props.art.reduce((total, art) => total + art.cost, 0) - props.price) / props.bill) * 100 < 35}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={props.product.margin}
              />
            </Badge>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Profit"
              style={{ width: '100%' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={(
                props.quantity *
                (props.bill -
                  props.art.reduce((total, art) => total + art.cost, 0) -
                  props.costs.reduce((total, cost) => total + cost.cost, 0) -
                  props.price)
              ).toFixed(2)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <IconButton size="small" onClick={() => props.deleteProduct(props.row, props.product)}>
          <Delete fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => props.copyProduct(props.row, props.product)}>
          <FileCopy color="secondary" fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => props.saveProduct(props.row, props.product)}>
          <Save color="primary" fontSize="small" />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default QuoteProduct;

QuoteProduct.propTypes = {
  style: PropTypes.string.isRequired,
  catalogs: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  styles: PropTypes.array.isRequired,
  art: PropTypes.array.isRequired,
  bill: PropTypes.any.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  saveProduct: PropTypes.func.isRequired,
  copyProduct: PropTypes.func.isRequired,
  updateArtCosts: PropTypes.func.isRequired,
  updateFeature: PropTypes.func.isRequired,
  editFeature: PropTypes.func.isRequired,
  saveFeature: PropTypes.func.isRequired,
  newFeature: PropTypes.func.isRequired,
  toggleShowFeatures: PropTypes.func.isRequired,
  deleteFeature: PropTypes.func.isRequired,
  showFeatures: PropTypes.bool.isRequired,
};
