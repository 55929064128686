import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.statuses = [];
    this.fetching = false;
    this.statusesPromise = null;
  }

  getStatuses() {
    return this.statuses;
  }

  getStatusesPromise() {
    return this.statusesPromise;
  }

  isFetching() {
    return this.fetching;
  }

  setFetching(statusesPromise) {
    this.fetching = true;
    this.statusesPromise = statusesPromise;
  }

  setStatuses = (statuses) => {
    this.statuses = statuses;
    this.fetching = false;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'SET_FETCHING': {
        this.setFetching(action.statusesPromise);
        break;
      }
      case 'SET_STATUSES': {
        this.setStatuses(action.statuses);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
