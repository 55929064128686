/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import WorkOrderTotals from '../Common/WorkOrderTotals';
import WaitingOrders from '../Common/WaitingOrders';
import MiniWorkOrderStatusGraph from '../Common/MiniWorkOrderStatusGraph';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import FuturePrints from '../Production/FuturePrints';
import Upcoming from '../Art/Upcoming';
import OverDueWorkOrders from '../Fulfillment/OverdueWorkOrders';
import InventoryChecks from '../Production/InventoryChecks/index.js';

const OrderProcessorHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          {/*<Grid item md={4} xs={12}>*/}
          {/*  <FuturePrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          <Grid item md={4} xs={12}>
            <WaitingOrders get={props.get} user={props.user} type="Order Processor" startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item md={4} xs={12}>
            <WorkOrderTotals get={props.get} margin={{ marginTop: '20px' }} refresh={props.refresh} />
          </Grid>
          <Grid item md={4} xs={12}>
            <MiniWorkOrderStatusGraph get={props.get} user={props.user} refresh={props.refresh} />
          </Grid>
          <LatestWorkOrders {...props} />
          <Grid item xs={6}>
            <Upcoming />
          </Grid>
          <Grid item xs={6}>
            <OverDueWorkOrders refresh={props.refresh} />
          </Grid>
          <Grid item xs={12}>
            <InventoryChecks />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default OrderProcessorHome;
