import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { get } from '../../../Functions/API';
import { Button, Grid } from '@material-ui/core';

const Groups = () => {
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const columns = [
    { field: 'id', title: 'Id' },
    { field: 'name', title: 'Name' },
  ];

  useEffect(() => {
    get('/groups').then(setGroups);
  }, []);

  const handleRowClick = (e, data) => {
    history.push(`/developer-tools/groups/${data.id}`);
  };

  return (
    <>
      <MaterialTable title="Groups" columns={columns} data={groups} onRowClick={handleRowClick} />
      <Grid container justify="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" href="/developer-tools/groups/new">
            New
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Groups;
