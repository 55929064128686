import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, NativeSelect } from '@material-ui/core';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const LeadType = ({ workOrder }) => {
  const [leadTypes, setLeadTypes] = useState([
    { name: 'Cold Call', value: 'Cold Call' },
    { name: 'Email Campaign', value: 'Email Campaign' },
    { name: 'Web Lead', value: 'Web Lead' },
    { name: 'BYU', value: 'BYU' },
    { name: 'Social Media', value: 'Social Media' },
    { name: 'Referral', value: 'Referral' },
    { name: 'Tradeshow', value: 'Tradeshow' },
    { name: 'Njogu Lead', value: 'Njogu Lead' },
    { name: 'InfoUSA', value: 'InfoUSA' },
  ]);
  return (
    <FormControl style={{ margin: '10px', width: '100%' }} component="div">
      <InputLabel shrink htmlFor="leadType">
        Lead Type
      </InputLabel>
      <NativeSelect
        value={workOrder.leadType || ''}
        onChange={(e) => updateWorkOrder(workOrder.id, { leadType: e.target.value })}
        input={<Input name="leadType" id="leadType" />}
      >
        <option value="">None</option>
        {leadTypes.map((method, key) => (
          <option key={key} value={method.value}>
            {method.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default LeadType;

LeadType.propTypes = {
  workOrder: PropTypes.object,
};
