import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import HistoryOption from './HistoryOption';
import Home from './Home';
import Old from './Old';
import WorkOrderChildren from './WorkOrderChildren';

const History = ({ workOrder, user }) => {
  return (
    <div style={{ padding: '15px' }}>
      <Switch>
        <Route path={'/workorder/:WorkOrderId/history/:option/:entityId'}>
          <HistoryOption />
        </Route>
        <Route path={'/workorder/:WorkOrderId/history/old'}>
          <Old workOrder={workOrder} user={user} />
        </Route>
        <Route path={'/workorder/:WorkOrderId/history/WorkOrder'}>
          <HistoryOption optionOverride={'WorkOrder'} />
        </Route>
        <Route path={'/workorder/:WorkOrderId/history/:option'}>
          <WorkOrderChildren />
        </Route>
        <Route path={'/workorder/:WorkOrderId/history/'}>
          <Home />
        </Route>
      </Switch>
    </div>
  );
};

export default History;

History.propTypes = {
  workOrder: PropTypes.object,
  user: PropTypes.object,
};
