/* eslint-disable react/display-name */
/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint prefer-destructuring:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import { Button } from '@material-ui/core';
import { createDialog } from '../../../Stores/Dialog/Actions';
import BillingTable from '../../../Components/Fulfillment/Components/BillingTable';
import CostingTable from '../../../Components/Fulfillment/Components/CreateGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
  },
  cell: {
    width: '10%',
  },
}));

function useQuery() {
  return queryString.parse(useLocation().search);
}

const Billing = () => {
  const queryParams = useQuery();
  const { statusId, type } = queryParams;
  const history = useHistory();
  const classes = useStyles();
  const [reload, refresh] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    loadLineItems();
  }, [reload]);

  const getStepIds = async () => {
    const steps = await get('/fulfillment/products/steps', { statusId });
    return steps.map((step) => step.id);
  };

  const loadLineItems = async () => {
    const stepIds = await getStepIds();
    const filter = {
      eager: {
        status: {},
        ybaSku: {
          ybaProduct: {},
        },
        $where: {
          productStepId: {
            $in: stepIds,
          },
          'order.storeId': queryParams.storeId,
        },
      },
    };

    get('/fulfillment/lineItems', { filter })
      .then(({ results }) => {
        setLineItems(results);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const actions =
    type === 'b'
      ? [
          {
            icon: () => (
              <Button size={'small'} variant={'contained'}>
                Generate Invoice
              </Button>
            ),
            onClick: (evt, data) => {
              if (!data.length > 0) {
                createSnack('Error', 'You must select items to order');
                return;
              }
              createDialog({
                content: (
                  <BillingTable
                    queryParams={queryParams}
                    selected={data.map((row) => {
                      let result = {};
                      for (let key in row) {
                        if (key !== 'tableData') {
                          result[key] = row[key];
                        }
                      }
                      return result;
                    })}
                    refresh={refresh}
                  />
                ),
                title: 'Generate Invoice',
                size: 'xl',
                disableBackdropClick: true,
              });
            },
          },
        ]
      : type === 'printCosts'
      ? [
          {
            icon: () => (
              <Button size={'small'} variant={'contained'}>
                Generate Print Costs
              </Button>
            ),
            onClick: (evt, data) => {
              if (!data.length > 0) {
                createSnack('Error', 'You must select items to order');
                return;
              }
              createDialog({
                content: (
                  <CostingTable
                    storeId={queryParams.storeId}
                    type={type}
                    lineItemColumns={[
                      { title: 'Name', field: 'name' },
                      { title: 'Color', field: 'color' },
                      { title: 'Size', field: 'size' },
                      { title: 'Quantity', field: 'quantity' },
                    ]}
                    selected={data.map((row) => {
                      let result = {};
                      for (let key in row) {
                        if (key !== 'tableData') {
                          result[key] = row[key];
                        }
                      }
                      return result;
                    })}
                    refresh={refresh}
                  />
                ),
                title: 'Print Costs',
                size: 'xl',
                disableBackdropClick: true,
              });
            },
          },
        ]
      : type === 'pullCosts'
      ? [
          {
            icon: () => (
              <Button size={'small'} variant={'contained'}>
                Generate Pull Costs
              </Button>
            ),
            onClick: (evt, data) => {
              if (!data.length > 0) {
                createSnack('Error', 'You must select items to order');
                return;
              }
              createDialog({
                content: (
                  <CostingTable
                    storeId={queryParams.storeId}
                    type={type}
                    selected={data.map((row) => {
                      let result = {};
                      for (let key in row) {
                        if (key !== 'tableData') {
                          result[key] = row[key];
                        }
                      }
                      return result;
                    })}
                    refresh={refresh}
                  />
                ),
                title: 'Pull Costs',
                size: 'xl',
                disableBackdropClick: true,
              });
            },
          },
        ]
      : [];

  return (
    <div className={classes.root}>
      <MaterialTable
        title="Line Items"
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Status', field: 'status.name' },
          { title: 'ybaSKU', field: 'ybaSku.sku' },
          { title: 'Product', field: 'ybaSku.ybaProduct.name' },
          { title: 'Quantity', field: 'quantity' },
        ]}
        data={lineItems}
        actions={actions}
        options={{ search: false, paging: false, selection: true, padding: 'dense' }}
      />
    </div>
  );
};

Billing.displayName = 'Billing';

export default Billing;
