import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, NativeSelect } from '@material-ui/core';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const OrderType = ({ workOrder, editable = true }) => {
  return (
    <FormControl
      style={{ margin: '8px', width: '100%' }}
      component="div"
      disabled={(workOrder.orderType === 'inventory' && workOrder.productionComplete) || !editable}
    >
      <InputLabel shrink htmlFor="orderType">
        Order Type
      </InputLabel>
      <NativeSelect
        value={workOrder.orderType ? workOrder.orderType : ''}
        onChange={(e) => updateWorkOrder(workOrder.id, { orderType: e.target.value })}
        input={<Input name="orderType" id="orderType" />}
      >
        <option value="">None</option>
        <option value="standard">Standard</option>
        <option value="csp">CSP</option>
        <option value="fulfillment">Fulfillment</option>
        <option value="inventory">Inventory</option>
        <option value="embroidery">Embroidery</option>
        <option value="sublimation">Sublimation</option>
        <option value="team">Team</option>
        <option value="individualFulfillment">Individual Fulfillment</option>
        <option value="webOrder">Web Order</option>
      </NativeSelect>
    </FormControl>
  );
};

export default OrderType;

OrderType.propTypes = {
  workOrder: PropTypes.object,
  editable: PropTypes.bool,
};
