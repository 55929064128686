import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { get } from '../../../../Functions/API';
import { useHistory } from 'react-router-dom';

const AddRemove = ({ url, dest, title }) => {
  const history = useHistory();
  return (
    <MaterialTable
      title={title}
      columns={[
        { title: 'ID', field: 'workOrderID' },
        { title: 'Inventory', field: 'name' },
        { title: 'Due Date', field: 'dueDate', type: 'date' },
        { title: 'Quantity', field: 'quantity' },
      ]}
      data={async (query) => {
        let { page, pageSize, orderBy, orderDirection } = query;
        console.log(query);
        const params = { page, pageSize };
        if (orderBy) {
          params.orderBy = orderBy.field;
          params.orderDirection = orderDirection;
        }
        const { results: data, total: totalCount } = await get(`/${url}`, params);
        return {
          data,
          page,
          totalCount,
        };
      }}
      onRowClick={(event, rowData) => {
        const url = dest.replace('workOrderId', rowData.id);
        history.push(url);
      }}
      options={{ padding: 'dense', search: false, pageSizeOptions: [5, 10, 20], pageSize: 10 }}
    />
  );
};

export default AddRemove;

AddRemove.propTypes = {
  url: PropTypes.string,
  dest: PropTypes.string,
  title: PropTypes.string,
};
