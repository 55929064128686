/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import WorkOrderTotals from '../Common/WorkOrderTotals';
import TotalProfit from '../Common/TotalProfit';
import WorkOrderFinancialGraph from './WorkOrderFinancialGraph';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import SalesFinancialChart from './SalesFinancialChart';
import SalesGoalsTeam from '../Sales/SalesGoals/SalesGoalsOverall';
import SalesGoalsOverall from '../Sales/SalesGoals/SalesGoalsTeam';
import AccountingReport from './AccountingReport';

const AccountingHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          <Grid item md={4} sm={4} xs={12}>
            <AccountingReport get={props.get} startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <WorkOrderTotals get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TotalProfit get={props.get} refresh={props.refresh} />
          </Grid>
          <LatestWorkOrders {...props} refresh={props.refresh} />
          <Grid item xs={12} md={6}>
            <SalesGoalsTeam get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SalesGoalsOverall get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item xl={9} md={8} xs={12}>
            <WorkOrderFinancialGraph get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item md={4} xl={3} xs={12}>
            <SalesFinancialChart get={props.get} refresh={props.refresh} />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default AccountingHome;
