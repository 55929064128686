/* eslint-disable react/display-name,react/prop-types */
import { closeDialog } from '../../../Stores/Dialog/Actions';
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { AppBar, Button, Checkbox, DialogActions, Grid, TextField, Toolbar } from '@material-ui/core';
import { createSnack } from '../../../Stores/Snack/Actions';
import { post } from '../../../Functions/API';
import { updateLineItems } from '../../../Stores/Fulfillment/LineItems/Actions';

const BillingTable = ({ queryParams, selected, refresh }) => {
  const [invoices, setInvoices] = useState([]);

  const getGroupData = () => {
    post('/fulfillment/lineItems/billGroup', { lineItems: selected, type: 'externalSku' })
      .then((res) => {
        console.log('billGroupData:', res);
        setInvoices(res);
      })
      .catch((err) => {
        createSnack('Error', 'Could not load ');
      });
  };

  useEffect(() => {
    getGroupData();
  }, []);

  const billingColumns = [
    {
      title: 'Name',
      // maybe change the field in the database to type (this is how it shows up in the invoice table)
      field: 'name',
      sorting: false,
    },
    {
      title: 'Quantity',
      field: 'quantity',
      sorting: false,
      editComponent: (props) => (
        <>
          {Boolean(props?.rowData?.orderId) ? (
            <>{props?.rowData?.quantity}</>
          ) : (
            <TextField
              type="number"
              label="Quantity"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value ? parseInt(e.target.value) : 0)}
              variant="filled"
            />
          )}
        </>
      ),
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      sorting: false,
      // render: (dataRow) => <>{formatter.format(dataRow.unitPrice)}</>,
      editComponent: (props) => (
        <>
          {Boolean(props?.rowData?.orderId) ? (
            <>{props?.rowData?.unitPrice}</>
          ) : (
            <TextField
              label="Unit Price"
              value={props.value}
              type="number"
              onChange={(e) => props.onChange(e.target.value ? e.target.value : 0)}
              variant="filled"
            />
          )}
        </>
      ),
    },
    {
      title: 'Bill',
      field: 'bill',
      editable: 'never',
      sorting: false,
    },
    // {
    //   title: 'Product',
    //   field: 'orderId',
    //   editable: 'never',
    //   sorting: false,
    //   render: (dataRow) => <Checkbox disabled={true} checked={Boolean(dataRow.orderId)} value={Boolean(dataRow.orderId)} />,
    // },
  ];

  const lineItemColumns = [
    { title: 'Id', field: 'id' },
    { title: 'Status', field: 'status.name' },
    { title: 'SKU', field: 'ybaSku.sku' },
    { title: 'Product', field: 'ybaSku.ybaProduct.name' },
    { title: 'Quantity', field: 'quantity' },
  ];

  const addToGroup = () => {
    // const c = window.confirm('These invoices will be added and line items moved to next step. Are you sure you want to proceed?');
    const invoiceGroupName = window.prompt('What would you like to name this Invoice Group?');
    if (!invoiceGroupName) return;
    const invoiceCopy = [...invoices];
    const body = {
      items: selected.map((lineItem) => {
        return {
          entityType: 'FulfillmentOrderLineItem',
          entityId: lineItem.id,
        };
      }),
      name: invoiceGroupName,
      storeId: queryParams.storeId,
      invoices: invoiceCopy.map((invoice) => {
        const { bill, quantity, unitPrice, name } = invoice;
        return {
          bill,
          quantity,
          unitPrice,
          type: name,
        };
      }),
    };
    console.log('body', body);
    post('/fulfillment/invoiceGroups', body)
      .then((res) => {
        console.log('GROUP RES:', res);
        updateLineItems(selected).then(() => refresh(1));
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    closeDialog();
  };

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable
        title="Billing"
        columns={billingColumns}
        data={invoices}
        editable={{
          onRowAdd: async (newData) => {
            const { quantity, unitPrice } = newData;
            if (quantity && unitPrice) {
              newData.bill = quantity * unitPrice;
            } else {
              newData.bill = null;
            }
            setInvoices([...invoices, newData]);
          },
          onRowUpdate: async (newData, oldData) => {
            const { quantity, unitPrice } = newData;
            if (quantity && unitPrice) {
              newData.bill = quantity * unitPrice;
            } else {
              newData.bill = null;
            }
            const invoiceCopy = [...invoices];
            const index = invoices.indexOf(oldData);
            invoiceCopy[index] = newData;
            setInvoices(invoiceCopy);
          },
          onRowDelete: async (oldData) => {
            const index = invoices.indexOf(oldData);
            const invoiceCopy = [...invoices];
            invoiceCopy.splice(index, 1);
            setInvoices(invoiceCopy);
          },
        }}
      />
      <MaterialTable title="Line Item Summary" columns={lineItemColumns} data={selected} />
      <br />
      <AppBar position={'sticky'} style={{ backgroundColor: '#eaeaea', bottom: 0 }}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <DialogActions>
                <Button autoFocus onClick={closeDialog} color={'secondary'}>
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
            <Grid item>
              <DialogActions>
                <Button autoFocus onClick={addToGroup} color={'primary'}>
                  Add
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default BillingTable;
