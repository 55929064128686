// import './styles.css';
import React, { useState, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'Jan',
    sales: 5000,
    cost: 2400,
    profit: 2400,
  },
  {
    name: 'Feb',
    sales: 3000,
    cost: 1398,
    profit: 2210,
  },
  {
    name: 'Mar',
    sales: 2000,
    cost: 9800,
    profit: 2290,
  },
  {
    name: 'Apr',
    sales: 2780,
    cost: 3908,
    profit: 2000,
  },
  {
    name: 'May',
    sales: 1890,
    cost: 4800,
    profit: 2181,
  },
  {
    name: 'Jun',
    sales: 2390,
    cost: 3800,
    profit: 2500,
  },
  {
    name: 'July',
    sales: 3490,
    cost: 4300,
    profit: 2100,
  },
];

const Graph = () => {
  return (
    <div>
      <LineChart
        width={300}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="sales" strokeOpacity={1} stroke="#3389FF" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="cost" strokeOpacity={1} stroke="#FF5733" />
        <Line type="monotone" dataKey="profit" strokeOpacity={1} stroke="#82ca9d" />
      </LineChart>
    </div>
  );
};

// const Graph = () => {
//   return (
//     <AreaChart
//       width={300}
//       height={150}
//       data={data}
//       margin={{
//         top: 10,
//         right: 30,
//         left: 0,
//         bottom: 0,
//       }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Area type="monotone" dataKey="sales" stroke="#8884d8" fill="#8884d8" />
//     </AreaChart>
//   );
// };

export default Graph;
