/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { get } from '../../../../Functions/API.js';
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { Breadcrumbs, Button, Link, Tooltip, Typography } from '@mui/material';
import { Person, Brush, DirectionsBike } from '@material-ui/icons';
import { useQuery } from '../../../../Functions';
import { useLocation, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { createSnack } from '../../../../Stores/Snack/Actions.js';

const Items = () => {
  const query = useQuery(useLocation);
  const { lobId } = useParams();
  const category = query.get('category');

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(11);
  const [rowCountState, setRowCountState] = React.useState(rowCount);
  const [foundLimit, setFoundLimit] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    load(lobId, category);
  }, [page, pageSize, lobId, category]);

  const load = async (lobId, category) => {
    setLoading(true);
    if (!foundLimit) {
      const newPageSize = (page + 1) * pageSize + 1;
      setRowCount(newPageSize);
    }
    const [iCategory] = await get('/integrations/info-plus/item-categories', { filter: `lobId eq ${lobId} and name eq ${category}` });
    if (!iCategory) {
      setLoading(false);
      return createSnack('Error', `No Category found with lobId ${lobId} and category name of ${category}`);
    }
    const results = await get('/integrations/info-plus/items', {
      filter: `lobId eq ${lobId} and majorGroupId eq ${iCategory.internalId}`,
      page: page + 1,
      limit: pageSize,
    });
    if (results.length < pageSize) {
      setFoundLimit(true);
      setRowCount(page * pageSize + results.length);
    }
    setItems(results);
    setLoading(false);
  };

  const columns = [
    { field: 'sku', headerName: 'Sku', flex: 0.3 },
    { field: 'itemDescription', headerName: 'Description', flex: 0.3 },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Link href={'/info-plus/lobs'}>Lines of Business</Link>
        <Typography>Items</Typography>
      </Breadcrumbs>
      <DataGrid
        autoHeight
        columns={columns}
        rows={items}
        loading={loading}
        page={page}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginationMode={'server'}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowCount={rowCountState}
      />
    </div>
  );
};

export default Items;
