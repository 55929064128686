import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CustomTextField from '../CustomComponents/CustomTextField';
import PropTypes from 'prop-types';
import * as VendorActions from '../../Stores/Vendors/Actions';

const NewVendorDialog = ({ open, toggleOpen, initialValue = '', callback }) => {
  const [dialogValue, setDialogValue] = useState({
    name: initialValue,
    street: null,
    city: null,
    state: null,
    zip: null,
    email: null,
    phone: null,
  });

  useEffect(() => {
    setDialogValue({
      ...dialogValue,
      name: initialValue,
    });
  }, [initialValue]);

  const handleClose = () => {
    const newObj = {};
    for (const key in dialogValue) {
      newObj[key] = null;
    }
    setDialogValue(newObj);
    toggleOpen(false);
  };

  const handleVendorSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    VendorActions.newVendor(dialogValue)
      .then((newVendor) => {
        callback(newVendor);
      })
      .finally(() => {
        handleClose();
      });
  };

  const textFieldAtts = ['name', 'street', 'city', 'state', 'zip', 'email', 'phone'];

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleVendorSubmit}>
        <DialogTitle id="form-dialog-title">Add a new Vendor</DialogTitle>
        <DialogContent>
          {textFieldAtts.map((att) => (
            <div key={att}>
              <CustomTextField att={att} dialogValue={dialogValue} setDialogValue={setDialogValue} />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewVendorDialog;

NewVendorDialog.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
  initialValue: PropTypes.string,
  callback: PropTypes.func,
};
