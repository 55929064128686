/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { MTableToolbar } from 'material-table';
import { Button, TextField, Grid, ButtonGroup } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { invoiceAlphabet, newInvoiceLines } from '../../../../../Stores/Billing/Actions';
import { InputAdornment, IconButton, FormControlLabel, Switch, Drawer, Tooltip } from '@material-ui/core';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import UserStore from '../../../../../Stores/User/Store';
import WorkOrderStore from '../../../../../Stores/WorkOrder/Store';
import { updateWorkOrder, updateInvoiceDate } from '../../../../../Stores/WorkOrder/Actions';
import ProductToBilling from './ProductToBilling';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { convertDateToSQLDate } from '../../../../Utils/SqlDate';
import SalesStore from '../../../../../Stores/Sales/Store';
import { createInvoice, deleteInvoice } from '../../../../../Stores/QuickBooks/Actions/Invoices';
import UploadButton from '../../../../Upload';

const createInvoiceButtonStates = {
  valid: {
    tooltipText: 'Create invoice in quickbooks',
    disabled: false,
  },
  noContact: {
    tooltipText: 'No billing contact set',
    disabled: true,
  },
  needSalesman: {
    tooltipText: 'Need a salesman for the Invoice',
    disabled: true,
  },
};

const createSplitInvoiceButtonStates = {
  valid: {
    tooltipText: 'Create split invoices',
    disabled: false,
  },
  notEnoughContacts: {
    tooltipText: 'Must have at least two billing contacts to do split invoice',
    disabled: true,
  },
  needSalesman: {
    tooltipText: 'Need a salesman for the Invoice',
    disabled: true,
  },
};

const InvoiceHeader = ({
  needUpdate,
  invoicePDF,
  deleteAllRows,
  workOrder,
  toggleSteve,
  waitInvoice,
  lockInvoice,
  submitAutomaticSplitInvoice,
  createMSASCreditMemo,
  creditMemoId,
  activeInvoice,
  activeCustomer,
  splitInvoice,
  props,
  setActiveInvoice,
  setShowDragModal,
  setInvoicePDF,
  splitInvoiceDisabled,
}) => {
  const [customerPO, setCustomerPO] = useState(workOrder.customerPO);
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const workOrderID = workOrder.id;
  const user = UserStore.getUser();
  const { group } = user;
  const [productDrawer, setProductDrawer] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [invoiceButtonState, setInvoiceButtonState] = useState(createInvoiceButtonStates.noContact);
  const [splitInvoiceButtonState, setSplitInvoiceButtonState] = useState(createSplitInvoiceButtonStates.notEnoughContacts);

  const manuallyInvoice = () => {
    const p = prompt('What is the id of the invoice you want to manually add?');
    if (p) {
      post('/manuallyUpdateWorkOrderInvoiceID', {
        workOrderID,
        invoiceID: p,
      })
        .then(() => {
          setActiveInvoice(true);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  useEffect(() => {
    setCustomerPO(workOrder.customerPO);
  }, [workOrder.customerPO]);

  useEffect(() => {
    const billingContacts = workOrder?.contacts?.filter((contact) => contact.type === 'billing');
    if (!workOrder.splitInvoice) {
      if (!billingContacts?.length) {
        setInvoiceButtonState('noContact');
      } else {
        setInvoiceButtonState('valid');
      }
    }
    if (!SalesStore.getSales()?.length) {
      setInvoiceButtonState('needSalesman');
      setSplitInvoiceButtonState('needSalesman');
    }
    // else {
    //   if (billingContacts?.length > 1) {
    //     setSplitInvoiceButtonState('valid');
    //   } else {
    //     setSplitInvoiceButtonState('notEnoughContacts');
    //   }
    // }
  }, [workOrder.splitInvoice, workOrder.contacts]);

  const previewInvoice = () => {
    window.open(invoicePDF.url, '_blank');
  };

  const generateInvoicePreview = () => {
    post('/invoicePDF', { workOrderID }).then((results) => {
      setActiveInvoice(true);
      setInvoicePDF(results);
    });
  };

  const updateWorkOrderFromQB = () => {
    post('/updateWorkOrderFromQB', { workOrderID: workOrder.id })
      .then((results) => {
        const { balance, paidDate } = results;
        WorkOrderStore.updateObj({ balance, paidDate });
        // updateWorkOrder(workOrderID, { balance, paidDate });
        createSnack('Success', 'Successfully updated the Work Order with QuickBooks info');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const downloadSplitInvoices = () => {
    setDownloadDisabled(true);
    createSnack('', 'Please Wait, Your File will download shortly');
    get('/getSplitInvoicePDFS', [{ name: 'workOrderID', value: workOrder.id }])
      .then((results) => {
        let folderName = '';
        var zip = new JSZip();
        for (let result of results) {
          let nameArr = result.fileName.split('/');
          folderName = folderName ? folderName : `${workOrder.workOrderID}-${nameArr[1]}`;
          zip.file(`${nameArr[2]}.pdf`, result.buffer.data);
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${folderName}.zip`);
        });
        console.log(results);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      })
      .finally(() => {
        setDownloadDisabled(false);
      });
  };

  const CreateInvoiceButton = ({ onClick }) => {
    return (
      <Tooltip title={createInvoiceButtonStates[invoiceButtonState]?.tooltipText}>
        <span>
          <Button color="primary" disabled={createInvoiceButtonStates[invoiceButtonState]?.disabled} onClick={onClick}>
            Create Invoice
          </Button>
        </span>
      </Tooltip>
    );
  };

  const CreateSplitInvoiceButton = ({ onClick }) => {
    return (
      <Tooltip title={createSplitInvoiceButtonStates[splitInvoiceButtonState]?.tooltipText}>
        <span>
          <Button
            disabled={createSplitInvoiceButtonStates[splitInvoiceButtonState]?.disabled}
            onClick={onClick}
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Create Split Invoices
          </Button>
        </span>
      </Tooltip>
    );
  };

  const uploadTypeOptions = [
    // TODO: double check which types we actually want
    {
      name: 'Shopify Payments',
      columns: [
        { field: 'Transaction Date', title: 'Transaction Date', type: 'datetime' },
        { field: 'Type', title: 'Type' },
        { field: 'Order', title: 'Order' },
        { field: 'Card Brand', title: 'Card Brand' },
        { field: 'Card Source', title: 'Card Source' },
        { field: 'Payout Status', title: 'Payout Status' },
        { field: 'Payout Date', title: 'Payout Date' },
        { field: 'Available On', title: 'Available On' },
        { field: 'Amount', title: 'Amount' },
        { field: 'Fee', title: 'Fee' },
        { field: 'Net', title: 'Net' },
        { field: 'Checkout', title: 'Checkout' },
        { field: 'Payment Method Name', title: 'Payment Method Name' },
        { field: 'Presentment Amount', title: 'Presentment Amount' },
        { field: 'Presentment Currency', title: 'Presentment Currency' },
        { field: 'Currency', title: 'Currency' },
      ],
      template: {
        fields: [
          'Transaction Date',
          'Type',
          'Order',
          'Card Brand',
          'Card Source',
          'Payout Status',
          'Payout Date',
          'Available On',
          'Amount',
          'Fee',
          'Net',
          'Checkout',
          'Payment Method Name',
          'Presentment Amount',
          'Presentment Currency',
          'Currency',
        ],
        data: [['']],
      },
      callback: async (invoiceLines) => {
        const newLines = [];
        for (const line of invoiceLines) {
          const newLine = {};
          newLine.workOrderID = workOrder.id;
          newLine.bill = line.Amount;
          newLine.type = line.Order;
          newLine.unitPrice = line.Amount;
          newLine.quantity = 1;
          newLines.push(newLine);
        }
        const totalFee = 0 - invoiceLines.reduce((total, line) => total + Number(line.Fee), 0);
        newLines.push({
          workOrderID: workOrder.id,
          quantity: 1,
          type: 'Shopify Fees',
          bill: totalFee,
          unitPrice: totalFee,
        });
        await newInvoiceLines(newLines)
          .then(() => {
            return 'Success!';
          })
          .catch((err) => {
            throw err;
          });
      },
    },
  ];

  return (
    <div>
      <MTableToolbar {...props} />
      <Drawer anchor={'bottom'} open={productDrawer} onClose={() => setProductDrawer(false)}>
        <ProductToBilling workOrderID={workOrder.id} close={() => setProductDrawer(false)} />
      </Drawer>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <ButtonGroup color={'primary'} variant={'text'} style={{ marginLeft: '10px' }}>
            <Button onClick={() => setProductDrawer(true)}>Get from Product</Button>
            <Button onClick={() => setShowDragModal(true)}>Arrange Table</Button>
            {Boolean(splitInvoice) && (
              <Button
                onClick={() => {
                  let c = window.confirm('This will change the Invoice number for all lines, are you sure?');
                  if (c) {
                    invoiceAlphabet(workOrder.id);
                  }
                }}
              >
                Create Doc Numbers
              </Button>
            )}
            {Boolean(!splitInvoice) && Boolean(activeInvoice) && Boolean(invoicePDF) && Boolean(!needUpdate) && (
              <Button onClick={previewInvoice}>Preview Invoice</Button>
            )}
            {Boolean(splitInvoice) && splitInvoiceDisabled && (
              <Button onClick={downloadSplitInvoices} disabled={downloadDisabled || true}>
                Download Split Invoices
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        {/* TODO quickbooksInvoiceButtons */}
        {(user.group.includes('Accounting') || user.group === 'Admin') && !workOrder.lockInvoice && (
          <Grid item>
            <ButtonGroup color={'primary'} variant={'text'}>
              {Boolean(!splitInvoice) && Boolean(!activeInvoice) && Boolean(activeCustomer && activeCustomer.quickbooksId) && (
                <CreateInvoiceButton
                  onClick={() => createInvoice({ workOrderID: workOrder.id }).then(({ invoicePDF }) => setInvoicePDF(invoicePDF))}
                />
              )}
              {Boolean(!splitInvoice) && Boolean(!activeInvoice) && Boolean(activeCustomer && activeCustomer.quickbooksId) && (
                <Button onClick={manuallyInvoice}>Manually Invoice</Button>
              )}
              {/*{Boolean(!splitInvoice) && Boolean(activeInvoice) && Boolean(needUpdate) && <Button onClick={updateInvoice}>Update Invoice</Button>}*/}
              {Boolean(!splitInvoice) && Boolean(!invoicePDF) && Boolean(activeInvoice) && Boolean(!needUpdate) && (
                <Button onClick={generateInvoicePreview}>Generate Invoice Preview</Button>
              )}
              {/*{Boolean(!splitInvoice) && Boolean(activeInvoice) && Boolean(!needUpdate) && <Button onClick={sendInvoice}>Send Invoice</Button>}*/}

              {Boolean(!splitInvoice) && Boolean(activeInvoice) && Boolean(!needUpdate) && (
                <Button
                  onClick={() =>
                    deleteInvoice(workOrder.qbInvoiceId).then(() => {
                      setInvoicePDF(null);
                    })
                  }
                >
                  Delete Invoice
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        )}
        {/* this opens an upload dialog */}
        <UploadButton uploadTypeOptions={uploadTypeOptions} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={Boolean(workOrder.sentToQuickBooks)}
                size="small"
                onChange={() => {
                  if (!workOrder.sentToQuickBooks) {
                    toggleSteve();
                  }
                  updateWorkOrder(workOrderID, { sentToQuickBooks: !workOrder.sentToQuickBooks });
                }}
                checked={Boolean(workOrder.sentToQuickBooks)}
              />
            }
            label={<span style={{ fontSize: 'smaller' }}>Sent to QuickBooks</span>}
            labelPlacement="top"
          />
        </Grid>
        <Grid item>
          <Tooltip title={activeInvoice ? 'Delete invoice in order to toggle' : 'Switches between original sizes and packaged sizes'}>
            <FormControlLabel
              control={
                <Switch
                  disabled={Boolean(activeInvoice)}
                  value={Boolean(workOrder.usePackagedSizes)}
                  size="small"
                  onChange={() => {
                    updateWorkOrder(workOrderID, { usePackagedSizes: !workOrder.usePackagedSizes });
                  }}
                  checked={Boolean(workOrder.usePackagedSizes)}
                />
              }
              label={<span style={{ fontSize: 'smaller' }}>Use Packaged Sizes</span>}
              labelPlacement="top"
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={Boolean(splitInvoice)}
                size="small"
                checked={Boolean(splitInvoice)}
                onChange={() => {
                  updateWorkOrder(workOrderID, { splitInvoice: !workOrder.splitInvoice });
                }}
                disabled={splitInvoiceDisabled}
              />
            }
            label={<span style={{ fontSize: 'smaller' }}>Split Invoice</span>}
            labelPlacement="top"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={Boolean(waitInvoice)}
                size="small"
                checked={Boolean(waitInvoice)}
                onChange={() => {
                  updateWorkOrder(workOrderID, { waitInvoice: !workOrder.waitInvoice });
                }}
              />
            }
            label={<span style={{ fontSize: 'smaller' }}>Wait To Invoice</span>}
            labelPlacement="top"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={Boolean(lockInvoice)}
                size="small"
                disabled={!user.hasPermission('lockInvoice')}
                checked={Boolean(lockInvoice)}
                onChange={() => updateWorkOrder(workOrderID, { lockInvoice: !workOrder.lockInvoice })}
              />
            }
            label={<span style={{ fontSize: 'smaller' }}>Lock Invoice</span>}
            labelPlacement="top"
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Invoice Date"
              value={workOrder.invoiceDate}
              onChange={(invoiceDate) => updateWorkOrder(workOrder.id, { invoiceDate: convertDateToSQLDate(invoiceDate) })}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {Boolean(!splitInvoice) && (
          <Grid item>
            <TextField
              label="PO"
              key="customer-po"
              id="customerPO"
              error={Boolean(workOrder.customerPO !== customerPO)}
              value={customerPO}
              multiline
              onChange={(e) => (e.target.value.length < 32 ? setCustomerPO(e.target.value) : window.alert('PO must be less than 32 characters'))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateWorkOrder(workOrder.id, { customerPO });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Boolean(workOrder.customerPO !== customerPO) && (
                      <IconButton size="small" color="primary" onClick={() => updateWorkOrder(workOrder.id, { customerPO })}>
                        <Save fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: '200px', justifyContent: 'end' }}
            />
          </Grid>
        )}
        {Boolean(splitInvoice) && ['Admin', 'Accounting Admin'].includes(group) && (
          <Grid item>
            <CreateSplitInvoiceButton onClick={submitAutomaticSplitInvoice} />
          </Grid>
        )}
        {/* TODO deleteAllInvoiceLinesButtons */}
        {(user.group === 'Admin' || user.group === 'Order Processor') && (
          <>
            <Grid item>
              <IconButton
                onClick={() => {
                  setDeleteAllDisabled(!deleteAllDisabled);
                }}
                style={{
                  color: deleteAllDisabled ? 'green' : 'red',
                }}
                href=""
              >
                {deleteAllDisabled ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
            </Grid>
            <Grid>
              <Button
                size="small"
                onClick={deleteAllRows}
                style={{
                  backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                  color: deleteAllDisabled ? 'grey' : 'black',
                  border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                  fontWeight: 'bold',
                }}
                color="secondary"
                disabled={deleteAllDisabled}
                variant="contained"
              >
                DELETE ALL!
              </Button>
            </Grid>
          </>
        )}
        {!creditMemoId && (
          <Grid item>
            <Button variant="contained" color="primary" size="small" onClick={createMSASCreditMemo}>
              Create Credit Memo
            </Button>
          </Grid>
        )}
        {['Accounting Admin', 'Admin'].includes(group) && (
          <>
            <Grid item>
              <Button size="small" variant="contained" onClick={updateWorkOrderFromQB}>
                Refresh From QB
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    onClick={() => {
                      updateWorkOrder(workOrderID, { writtenOff: !workOrder.writtenOff });
                    }}
                    value={Boolean(workOrder.writtenOff)}
                    checked={Boolean(workOrder.writtenOff)}
                  />
                }
                label={<span style={{ fontSize: 'small' }}>Written Off</span>}
                labelPlacement="top"
              />
            </Grid>
          </>
        )}
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                value={Boolean(workOrder.balance === 0)}
                checked={Boolean(workOrder.balance === 0)}
                onClick={() => {
                  if (workOrder.balance === 0) {
                    updateWorkOrder(workOrder.id, { balance: 10, paidDate: null }).then(() => {
                      createSnack(
                        'Success',
                        "This work order now has a temporary balance of $10 and will update from quickbooks with the correct balance later. The balance doesn't affect the sales sheet.",
                      );
                    });
                  } else {
                    updateWorkOrder(workOrder.id, { balance: 0, paidDate: convertDateToSQLDate(new Date()) }).then(() => {
                      createSnack('Success', 'This work order has been manually marked as paid. It will no longer get updates from QuickBooks.');
                    });
                  }
                }}
              />
            }
            label={<span style={{ fontSize: 'small' }}>Paid</span>}
            labelPlacement="top"
          />
        </Grid>
        {workOrder.balance > 0 && (
          <Grid item>
            <TextField
              style={{ marginRight: '8px' }}
              value={workOrder.balance.toFixed(2)}
              label="Remaining Balance"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        )}
        <Grid item>
          <div style={{ padding: '0px 10px', display: 'inline', float: 'right' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker label="Paid Date" value={workOrder.paidDate} />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceHeader;
