/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';

class PriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceList: [],
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState(results);
      })
      .catch((err) => {
        window.alert(err);
      });
    // fetch(`${this.props.homepage}/allPrintCosts`)
    //     .then(async results => {
    //         let priceList = await results.json();
    //         this.setState({
    //             priceList
    //         })
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
  }

  setup = async () => {
    let priceList;
    let error = '';
    await this.props
      .get('/allPrintCosts', [])
      .then((results) => {
        priceList = results;
      })
      .catch((err) => {
        error += 'Could not get print costs';
      });
    if (priceList) {
      return { priceList };
    }
    throw new Error(error);
  };

  edit = (event, price) => {
    // console.log(price);
    const printType = prompt('Print Type', price.printType);
    const quantity = prompt('Quantity', price.quantity);
    const newPrice = prompt('Price', price.price);
    const type = prompt('Type', price.type);
    const { priceList } = this.state;
    const index = priceList.indexOf(price);
    priceList[index] = {
      printType,
      quantity,
      price: newPrice,
      type,
    };
    this.setState({
      priceList,
    });
  };

  render() {
    const columns = [
      { field: 'printType', title: 'Print Type' },
      { field: 'quantity', title: 'Quantity' },
      { field: 'price', title: 'Price' },
      { field: 'type', title: 'Type' },
    ];
    return (
      <Paper>
        <MaterialTable
          title="Price List"
          columns={columns}
          data={this.state.priceList}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              const { priceList } = this.state;
              const index = priceList.indexOf(oldData);
              priceList[index] = newData;
              this.props
                .post('/updatePrintCost', newData)
                .then(() => {
                  this.setState({
                    priceList,
                  });
                })
                .catch((err) => {
                  window.alert(err);
                });
              // fetch(`${this.props.homepage}/updatePrintCost`, {
              //     method: 'POST',
              //     headers: {
              //         'Content-Type': 'application/json',
              //     },
              //     body: JSON.stringify(newData)
              // })
              //     .then(() => {
              //         this.setState({
              //             priceList
              //         })
              //     })
            },
          }}
        />
      </Paper>
    );
  }
}

export default PriceList;
