/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-shadow:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PrintIcon from '@material-ui/icons/Print';
import commaNumber from 'comma-number';
import theme from '../../Utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const FuturePrints = ({ get, refresh }) => {
  const classes = useStyles();
  const [pastPrints, setPastPrints] = useState(null);
  const [futurePrints, setFuturePrints] = useState(null);
  const [difference, setDifference] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    get('/getTwoWeekComparision')
      .then((res) => {
        const [future] = res.future;
        const [past] = res.past;
        setFuturePrints(future.quantity ? future.quantity : 0);
        setPastPrints(past.quantity ? past.quantity : 0);
        setDifference((((res.future[0].quantity - res.past[0].quantity) / res.past[0].quantity) * 100).toFixed(1));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
              NEXT TWO WEEKS PRINTS
            </Typography>
            {isLoading ? <Skeleton variant="rect" width={50} height={20} /> : <Typography variant="h3">{commaNumber(futurePrints)}</Typography>}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PrintIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <Tooltip title={`Last two weeks: ${commaNumber(pastPrints)}`}>
          <div className={classes.difference}>
            {difference >= 0 ? (
              <ArrowUpwardIcon style={{ color: theme.palette.success.dark }} />
            ) : (
              <ArrowDownwardIcon style={{ color: theme.palette.error.dark }} />
            )}
            {isLoading ? (
              <Skeleton variant="rect" width={210} height={15} />
            ) : (
              <>
                <Typography
                  style={{
                    color: difference >= 0 ? theme.palette.success.dark : theme.palette.error.dark,
                    marginRight: theme.spacing(1),
                  }}
                  variant="body2"
                >
                  {Math.abs(difference)}%
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  Since last two weeks
                </Typography>
              </>
            )}
          </div>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default FuturePrints;
