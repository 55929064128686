import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, Grid, IconButton, TextField } from '@mui/material';
import { get, post } from '../../../../Functions/API.js';
import { Add } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { closeDialog } from '../../../../Stores/Dialog/Actions.js';
import { createSnack } from '../../../../Stores/Snack/Actions.js';

const AddProduct = ({ customerId }) => {
  const [name, setName] = useState('');
  const [brands, setBrands] = useState([]);
  const [styles, setStyles] = useState([]);
  const [artOptions, setArtOptions] = useState([]);
  const [brand, setBrand] = useState();
  const [style, setStyle] = useState();
  const [art, setArt] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    getArt(customerId);
  }, [customerId]);

  useEffect(() => {
    if (brand) {
      getStyles(brand);
    }
  }, [brand]);

  const getBrands = async () => {
    const { results } = await get('/fulfillment/externalBrands');
    setBrands(results);
  };

  const getStyles = async (brand) => {
    const results = await get('/fulfillment/externalStyles/addingProduct', { brandId: brand.id });
    setStyles(results);
  };
  const getArt = async (customerId) => {
    const results = await get('/workOrderArt', { filter: { eager: { $where: { customerId: customerId } } } });
    setArtOptions(results);
  };

  const addToSelectedProducts = () => {
    const { id: brandId } = brand;
    const { id: styleId } = style;
    const newProduct = { id: randomId(), name, brandId, styleId, brand: brand.brand, style: style.style };
    const products = [...selectedProducts];
    products.push(newProduct);
    setSelectedProducts(products);
  };

  const submit = async () => {
    await post('/pub-subs/crons', { type: 'createProducts', data: { customerId, artRows: art, products: selectedProducts } });
    createSnack('Success', 'Created Job for products');
    closeDialog();
  };

  const close = () => {
    const c = confirm('Are you sure you want to quit adding this product?');
    if (c) closeDialog();
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            disableCloseOnSelect
            multiple
            value={art}
            onChange={(event, value) => {
              setArt(value);
            }}
            getOptionLabel={(row) => `${row.id}-${row.name}`}
            renderInput={(params) => <TextField {...params} label="Art" />}
            options={artOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField fullWidth label={'Name'} value={name} onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                value={brand}
                onChange={(event, value) => {
                  setBrand(value);
                }}
                getOptionLabel={(row) => row.brand}
                renderInput={(params) => <TextField {...params} label="Brand" />}
                options={brands}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                fullWidth
                value={style}
                onChange={(event, value) => {
                  setStyle(value);
                }}
                getOptionLabel={(row) => row.style}
                renderInput={(params) => <TextField {...params} label="Style" />}
                options={styles}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={addToSelectedProducts}>
                <Add />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            columns={[
              { field: 'name', headerName: 'Name', flex: 0.33 },
              { field: 'brand', headerName: 'Brand', flex: 0.33 },
              { field: 'style', headerName: 'Style', flex: 0.33 },
            ]}
            rows={selectedProducts}
          />
        </Grid>
      </Grid>
      <div style={{ paddingTop: '10px' }}>
        <Grid container justifyContent={'flex-end'} alignItems={'flex-end'}>
          <Grid item>
            <Button onClick={submit}>Submit</Button>
          </Grid>
          <Grid item>
            <Button color={'secondary'} onClick={close}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AddProduct.propTypes = {
  customerId: PropTypes.number,
  load: PropTypes.func,
};

export default AddProduct;
