/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { MobileStepper, Button } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import PropTypes from 'prop-types';

const ImageCarousel = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(null);

  useEffect(() => {
    if (images && images.length > 0) {
      setImageIndex(0);
    }
  }, []);

  const handleNext = () => {
    setImageIndex(imageIndex + 1);
  };
  const handleBack = () => {
    setImageIndex(imageIndex - 1);
  };

  return (
    <>
      {images[imageIndex]?.url && (
        <>
          {images[imageIndex]?.url?.includes('.pdf') ? (
            <iframe src={images[imageIndex]?.url} style={{ margin: 'auto', width: '100%', height: '100%' }} />
          ) : (
            <img style={{ width: '100%' }} src={images[imageIndex]?.url} alt="Images" />
          )}

        </>
      )}
      <MobileStepper
        steps={images.length}
        position="static"
        variant="dots"
        activeStep={imageIndex}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={imageIndex === images.length - 1}>
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={!imageIndex}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </>
  );
};

ImageCarousel.displayName = 'ImageCarousel';

ImageCarousel.propTypes = {
  images: PropTypes.any,
};

export default ImageCarousel;
