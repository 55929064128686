import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import { save, retrieve } from '../../Functions/LocalStorage';

class Store extends EventEmitter {
  constructor() {
    super();
    this.users = retrieve('users');
    this.fetching = false;
    this.promise = null;
  }

  getUsers() {
    return this.users;
  }

  getUser = (uid) => {
    if (this.users) {
      return this.users.find((u) => u.uid === uid);
    } else {
      return null;
    }
  };

  isFetching = () => {
    return this.fetching;
  };

  setFetching(promise) {
    this.fetching = true;
    this.promise = promise;
  }

  getPromise = () => {
    return this.promise;
  };

  loadUsers = (users) => {
    this.users = users;
    save('users', users);
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_USERS': {
        this.loadUsers(action.users);
        break;
      }
      case 'SET_FETCHING': {
        this.setFetching(action.promise);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
