import { get, post } from '../../Functions/API';
import dispatcher from './dispatcher';
import { createSnack } from '../../Stores/Snack/Actions';

export const fetchVendors = () => {
  get('/vendors')
    .then((vendors) => {
      dispatcher.dispatch({
        type: 'LOAD_VENDORS',
        vendors,
      });
    })
    .catch((err) => {
      createSnack('Error', err?.message);
    });
};

// async?
export const newVendor = async (newVendor) => {
  return post('/vendors', newVendor)
    .then(async (data) => {
      const { newVendor, vendors } = data;
      dispatcher.dispatch({
        type: 'LOAD_VENDORS',
        vendors,
      });
      createSnack('Success', 'Created New Vendor!');
      return newVendor;
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};
