/* eslint-disable react/display-name */

import React, { useState, useEffect, forwardRef } from 'react';
import { TextField, Button, IconButton, DialogActions, Grid, Card, Select, MenuItem, InputLabel, FormControl, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, patch } from '../../../../../../Functions/API';
import { closeDialog } from '../../../../../../Stores/Dialog/Actions';
import { Add, Delete } from '@material-ui/icons';
import { loadInvoice } from '../../../../../../Stores/Billing/Actions';

const SizesModal = forwardRef(({ origSizes, invoiceId, workOrderID }, ref) => {
  let initialSizes = [];
  if (origSizes && Array.isArray(origSizes)) {
    initialSizes = origSizes.map((origSize) => {
      if (!('sizesIDs' in origSize)) origSize.sizesIDs = `${new Date()}${Math.random()}`;
      return origSize;
    });
  }
  const [sizes, setSizes] = useState(initialSizes);
  const [newSize, setNewSize] = useState({ size: '', quantity: '' });
  const [potentialSizes, setPotentialSizes] = useState([]);

  useEffect(() => {
    get('/allPotentialSizes').then(setPotentialSizes);
  }, []);

  const sortSizes = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.size);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.size);
      if (potentialSizeA && potentialSizeB) {
        const indexA = potentialSizes.indexOf(potentialSizeA);
        const indexB = potentialSizes.indexOf(potentialSizeB);
        return indexA - indexB;
      }
      return 1;
    });
  };

  const submit = async () => {
    const oldSizes = sortSizes(sizes, potentialSizes);
    patch(`/invoice/${invoiceId}`, { oldSizes }).then(() => {
      closeDialog();
      loadInvoice(workOrderID);
    });
  };

  const update = (e, k, size) => {
    const s = [...sizes];
    const index = sizes.indexOf(size);
    s[index][k] = e.target.value;
    setSizes(s);
  };

  const deleteSize = (size) => {
    const s = [...sizes];
    const index = sizes.indexOf(size);
    s.splice(index, 1);
    setSizes(s);
  };

  return (
    <div>
      {sizes.map((size, key) => {
        return (
          <Card key={key} style={{ backgroundColor: '#eaeaea', marginBottom: '10px', padding: '10px' }}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>Size</InputLabel>
                  <Select value={size.size} fullWidth>
                    {potentialSizes.map((size, key) => (
                      <MenuItem value={size.size} key={key}>
                        {size.size}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField value={size.quantity} label={'Quantity'} onChange={(e) => update(e, 'quantity', size)} />
              </Grid>
              <Grid item xs={2}>
                <IconButton size={'small'} onClick={() => deleteSize(size)}>
                  <Delete fontSize={'small'} />
                </IconButton>
              </Grid>
            </Grid>
          </Card>
        );
      })}
      <Divider style={{ marginBottom: '10px' }} />
      <Card style={{ marginBottom: '10px', padding: '10px' }}>
        <Grid container spacing={1} alignItems={'center'} justify={'space-between'}>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Size</InputLabel>
              <Select fullWidth value={newSize.size} onChange={(e) => setNewSize((s) => ({ ...s, size: e?.target?.value }))}>
                {potentialSizes.map((size, key) => (
                  <MenuItem value={size.size} key={key}>
                    {size.size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={newSize.quantity}
              label={`Quantity`}
              type={'number'}
              onChange={(e) => setNewSize((s) => ({ ...s, quantity: e?.target?.value }))}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              size={'small'}
              color={'secondary'}
              onClick={() => {
                const s = [...sizes];
                s.push({ ...newSize });
                setNewSize({ size: '', quantity: '' });
                setSizes(s);
              }}
            >
              <Add fontSize={'small'} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
      <DialogActions>
        <Button size={'small'} color={'primary'} onClick={submit}>
          Submit
        </Button>
      </DialogActions>
    </div>
  );
});

export default SizesModal;

SizesModal.propTypes = {
  origSizes: PropTypes.array,
  invoiceId: PropTypes.number,
  workOrderID: PropTypes.number,
};
