/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import ClockInAndOut from './ClockInAndOut';
import Inventory from './LineHeaders/inventory';
import Normal from './LineHeaders/normal';

const CountLine = (props) => {
  return (
    <div style={{ borderBottom: '5px solid grey' }}>
      <div style={{ maxWidth: '800px', margin: 'auto' }}>
        <div style={{ marginTop: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            {Boolean(props.line.misprint) && <h2 style={{ color: 'red' }}>Replacement Item</h2>}
            {props.workOrder.orderType === 'inventory' || props.workOrder.orderType === 'fulfillment' ? (
              <Inventory groupBy={props.groupBy} line={props.line} workOrder={props.workOrder} />
            ) : (
              <Normal groupBy={props.groupBy} line={props.line} workOrder={props.workOrder} />
            )}
            <Badge
              badgeContent={props.line.artIDs ? props.line.art.length : '0'}
              color="secondary"
              style={{ marginTop: '10px', marginRight: '10px' }}
            >
              {props.line.showArt ? (
                <Button variant="contained" color="primary" href="" onClick={() => props.toggleArt(props.line)}>
                  Hide Art
                </Button>
              ) : (
                <Button variant="contained" color="primary" href="" onClick={() => props.toggleArt(props.line)}>
                  View Art
                </Button>
              )}
            </Badge>
          </div>
        </div>
        {props.line.showArt && (
          <div style={{ textAlign: 'center' }}>
            {props.line.artIDs &&
              props.line.art.map((chip, key) => (
                <div key={key} style={{ margin: '8px' }}>
                  <Button href={chip.url} target="_blank" color="primary" variant="contained">
                    {chip.name}
                  </Button>
                </div>
              ))}
          </div>
        )}
        {
          <div style={{ textAlign: 'center' }}>
            <ClockInAndOut
              type="count"
              updateFinishedInDB={props.updateFinishedInDB}
              // passes updatInventory to clockinAndOut then never uses it
              updateInventory={props.updateInventory}
              resetCount={props.resetCount}
              workOrder={props.workOrder}
              num={props.num}
              updateQuantity={props.updateQuantity}
              returnedId={props.line.returnedId}
              workOrderID={props.workOrder.id}
              clockInLine={props.clockInLine}
              line={props.line}
            />
          </div>
        }
        <Grid container spacing={1} justify="center">
          {props.line.sizes.map((size, key) => (
            <Grid item xs={3} md={2} key={key}>
              <TextField
                label={`${size.quantity} x ${size.size?.toUpperCase()}`}
                error={size.quantity !== size.countNum}
                variant="outlined"
                helperText={
                  size.quantity > size.countNum
                    ? `Missing ${size.quantity - size.countNum}`
                    : size.quantity < size.countNum && `Extra ${size.countNum - size.quantity}`
                }
                value={size.countNum}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </Grid>
          ))}
          <Grid item xs={3} md={2}>
            <TextField
              label={`Total: ${props.line.sizes.reduce((total, size) => total + size.quantity, 0)}`}
              error={
                props.line.sizes.reduce((total, size) => total + size.quantity, 0) !==
                props.line.sizes.reduce((total, size) => total + size.countNum, 0)
              }
              variant="outlined"
              value={props.line.sizes.reduce((total, size) => total + size.countNum, 0)}
              // helperText={props.line.sizes.reduce((total, size) => total + size.quantity, 0) !== props.line.sizes.reduce((total, size) => total + size.countNum, 0) && `Missing ${props.line.sizes.reduce((total, size) => total + size.quantity, 0) - props.line.sizes.reduce((total, size) => total + size.countNum, 0)}`}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              autoComplete="off"
              component="div"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CountLine;
