import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, IconButton, Button, Tabs, Tab, Paper, AppBar, Toolbar } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useLocation, Link, Switch, Route } from 'react-router-dom';

const FulfillmentTabs = () => {
  const location = useLocation();

  return (
    <AppBar position={'relative'} style={{ backgroundColor: '#eaeaea' }}>
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          <Grid item style={{ flexGrow: 1 }}>
            <IconButton size={'small'} component={RouterLink} to="/fulfillment">
              <HomeIcon fontSize={'small'} />
            </IconButton>
          </Grid>
          <Grid item>
            <Button size={'small'} variant={'outlined'} component={RouterLink} to="/fulfillment/customers">
              Customers
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default FulfillmentTabs;
