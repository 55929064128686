/* eslint-disable react/prop-types,react/display-name */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IndividualArtCard from '../../../../Components/Art/IndividualArtCard.js';
import { Grid } from '@material-ui/core';
import { get } from '../../../../Functions/API.js';
import { createSnack } from '../../../../Stores/Snack/Actions.js';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { createDialog } from '../../../../Stores/Dialog/Actions.js';
import { Breadcrumbs, Button, Link, Switch, Tooltip, Typography } from '@mui/material';
import Brush from '@material-ui/icons/Brush';
import { Add } from '@material-ui/icons';
import AddProduct from '../products/addProduct.js';
import AddArt from './addArt.js';

const Art = () => {
  const [art, setArt] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  const history = useHistory();

  const { lobId } = useParams();

  useEffect(() => {
    load(lobId, page, pageSize);
  }, [lobId, page, pageSize]);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const load = async (lobId, page, pageSize) => {
    setLoading(true);
    const [c] = await get('/customers', { filter: { eager: { $where: { lobId } } } });
    if (!c) return createSnack('Error', 'No Customer Found');
    const { results: a, total } = await get('/workOrderArt', { filter: { eager: { $where: { customerId: c.id } } }, page, pageSize });
    setArt(a);
    setRowCount(total);
    setCustomer(c);
    setLoading(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name', width: 250 },
    {
      field: 'url',
      headerName: 'Image',
      width: 200,
      renderCell: ({ value }) => <img style={{ maxHeight: '67px', maxWidth: '200px' }} alt={value} src={value} />,
    },
    { field: 'position', headerName: 'Position' },
    { field: 'color', headerName: 'Color' },
    // { field: 'numbers', headerName: 'Numbers' },
    { field: 'underlay', headerName: 'Underlay', renderCell: ({ value }) => <Switch checked={value} value={value} /> },
    { field: 'medWeed', headerName: 'Med Weed', renderCell: ({ value }) => <Switch checked={value} value={value} /> },
    { field: 'hardWeed', headerName: 'Hard Weed', renderCell: ({ value }) => <Switch checked={value} value={value} /> },
    // {
    //   type: 'actions',
    //   headerName: 'Actions',
    //   getActions: ({ row }) => [
    //     <Tooltip key={0} title={'View Art'}>
    //       <GridActionsCellItem
    //         icon={<Brush />}
    //         onClick={() => {
    //           console.log(row);
    //           createDialog({
    //             title: 'Image',
    //             size: 'xl',
    //             content: (
    //               <div style={{ backgroundColor: '#eaeaea', textAlign: 'center' }}>
    //                 <img style={{ maxHeight: '400px' }} src={row.url} alt={row.url} />
    //               </div>
    //             ),
    //           });
    //         }}
    //         label={'View Art'}
    //       />
    //     </Tooltip>,
    //   ],
    // },
  ];

  const addArt = () => {
    createDialog({
      title: 'Create Art',
      size: 'xl',
      content: <AddArt customerId={customer.id} />,
      disableBackdropClick: true,
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button size={'small'} disabled={loading} onClick={addArt}>
          <Add fontSize={'small'} />
          Add Art
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Link href={'/info-plus/lobs'}>Lines of Business</Link>
        <Typography>Art</Typography>
      </Breadcrumbs>
      <DataGrid
        autoHeight
        initialState={{ columns: { columnVisibilityModel: { url: false } } }}
        columns={columns}
        rows={art}
        onCellClick={({ field, value }, event, details) => {
          if (field === 'url')
            createDialog({
              title: 'Image',
              size: 'xl',
              content: (
                <div style={{ backgroundColor: '#eaeaea', textAlign: 'center' }}>
                  <img style={{ maxHeight: '400px' }} src={value} alt={value} />
                </div>
              ),
            });
        }}
        paginationMode={'server'}
        rowsPerPageOptions={[10, 20, 50, 100]}
        components={{ Toolbar: CustomToolbar }}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowCount={rowCountState}
        loading={loading}
      />
      {/* <Grid container> */}
      {/*   {art.map((a, key) => ( */}
      {/*     <Grid key={key} item> */}
      {/*       <IndividualArtCard */}
      {/*         key={key} */}
      {/*         individualArt={a} */}
      {/*         // updateIndividualArt={updateIndividualArt} */}
      {/*         // updateArt={updateArt} */}
      {/*         // updatingChip={updatingChip} */}
      {/*         // csp={csp} */}
      {/*         // toggleWeed={toggleWeed} */}
      {/*         // toggleUnderLay={toggleUnderLay} */}
      {/*         // openModal={openModal} */}
      {/*         // toggleReversible={toggleReversible} */}
      {/*         // deleteArt={deleteArt} */}
      {/*         // updateFile={updateFile} */}
      {/*         // copyArt={copyArt} */}
      {/*         // viewOrders={viewOrders} */}
      {/*         // toggleArtToUpdate={toggleArtToUpdate} */}
      {/*         // WorkOrderId={WorkOrderId} */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*   ))} */}
      {/* </Grid> */}
    </div>
  );
};

export default Art;
