/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Menu from '../Menu';
import Summary from '../Accounting/Summary';

class OldWorkOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'P.O. Number', field: 'po_number' },
        { title: 'First Name', field: 'first' },
        { title: 'Last Name', field: 'last' },
        { title: 'Due Date', field: 'due_date' },
        { title: 'Shipping Method', field: 'shipping_method' },
        {
          title: 'Ship On',
          field: 'ship_on',
          render: (rowData) => {
            const date = new Date(rowData.ship_on);
            return <div>{date.toDateString()}</div>;
          },
        },
      ],
      data: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.customerId;
    this.props.get('/getOldWorkOrders', [{ name: 'id', value: id }]).then((data) => {
      this.setState({
        id,
        data,
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.id && (
          <MaterialTable
            columns={this.state.columns}
            data={this.state.data}
            title="Old Work Orders"
            options={{ paging: false }}
            onRowClick={(event, rowData) => {
              console.log(rowData.id);
              // this.props.setInvoice(rowData.id, rowData.po_number);
              window.location.href = `/oldSystem/Invoice/${rowData.id}/`;
            }}
          />
        )}
      </div>
    );
  }
}

export default OldWorkOrders;
