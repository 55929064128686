import React, { useEffect, useState } from 'react';
import { get } from '../../../Functions/API.js';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Breadcrumbs, Link, Tooltip, Typography } from '@mui/material';
import { Person, Brush, DirectionsBike } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const Lobs = () => {
  const [lobs, setLobs] = useState([]);

  const history = useHistory();

  useEffect(() => {
    get('/customers', { filter: { eager: { $where: { lobId: { $exists: true } } } } }).then(setLobs);
  }, []);

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'lobId', headerName: 'Lob ID' },
    {
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      getActions: ({ row }) => [
        <Tooltip key={0} title={'YBA Products'}>
          <GridActionsCellItem
            icon={<div>Y</div>}
            onClick={() => {
              history.push(`/info-plus/lobs/${row.lobId}/products`);
            }}
            label={'YBA Products'}
          />
        </Tooltip>,
        <Tooltip key={0} title={'YBA Art'}>
          <GridActionsCellItem
            icon={<div>A</div>}
            onClick={() => {
              history.push(`/info-plus/lobs/${row.lobId}/art`);
            }}
            label={'YBA Art'}
          />
        </Tooltip>,
        <Tooltip key={0} title={'Art'}>
          <GridActionsCellItem
            icon={<Brush />}
            onClick={() => {
              history.push(`/info-plus/lobs/${row.lobId}/items?category=Art`);
            }}
            label={'Art'}
          />
        </Tooltip>,
        <Tooltip key={1} title={'Blanks'}>
          <GridActionsCellItem
            icon={<Person />}
            onClick={() => {
              history.push(`/info-plus/lobs/${row.lobId}/items?category=Blank`);
            }}
            label="Blanks"
          />
        </Tooltip>,
        <Tooltip key={2} title={'Finished'}>
          <GridActionsCellItem
            icon={<DirectionsBike />}
            onClick={() => {
              history.push(`/info-plus/lobs/${row.lobId}/items?category=Finished`);
            }}
            label="Finished"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Typography>Lines of Business</Typography>
      </Breadcrumbs>
      <DataGrid autoHeight checkboxSelection components={{ Toolbar: GridToolbar }} columns={columns} rows={lobs} />
    </div>
  );
};

export default Lobs;
