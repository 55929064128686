/* eslint max-len:0 */
/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Paper, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';

const Numbers = ({ columns, numbers }) => {
  return (
    <div>
      <Paper
        style={{
          position: 'absolute',
          width: '90%',
          padding: '15px',
          height: '95%',
          overflowX: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((row, key) => (
                <TableCell key={key}>{row.title}</TableCell>
              ))}
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {numbers.map((row, key) => (
              <TableRow key={key}>
                {
                  columns.map((column, key) => {
                    if (column.field !== 'number') {
                      const found = row.sizes.find((size) => size.size === column.field);
                      if (found) {
                        return <TableCell key={key}>{found.quantity}</TableCell>;
                      }
                      return <TableCell key={key} />;
                    }
                    return (
                      <TableCell key={key}>
                        <b>{row.number}</b>
                      </TableCell>
                    );
                  })
                  // row.sizes.map((size, key2) =>
                  //     <TableCell key={key2}>{row.sizes.find(row => row.size === props.columns[key2].field).quantity}</TableCell>
                  // )
                }
                <TableCell>{row.sizes.reduce((total, size) => total + size.quantity, 0)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              {columns.map((column, key) => {
                if (column.field !== 'number') {
                  const total = numbers.reduce(
                    (total, row) => total + row.sizes.reduce((total2, size) => (size.size === column.field ? total2 + size.quantity : total2), 0),
                    0,
                  );
                  if (total) {
                    return <TableCell key={key}>{total}</TableCell>;
                  }
                  return <TableCell key={key} />;
                }
                return <TableCell key={key}>Total</TableCell>;
              })}
              <TableCell>{numbers.reduce((total, row) => total + row.sizes.reduce((total, size) => total + size.quantity, 0), 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default Numbers;
