import dispatcher from './dispatcher';
import { get } from '../../Functions/API';
import StatusesStore from './Store';
import { createSnack } from '../Snack/Actions';

export const fetchStatuses = async () => {
  const currentStatuses = StatusesStore.getStatuses();
  if (currentStatuses?.length) {
    return currentStatuses;
  } else if (StatusesStore.isFetching()) {
    const statuses = await StatusesStore.getStatusesPromise();
    return statuses;
  } else {
    try {
      const statusesPromise = get(`/statuses`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        statusesPromise,
      });
      const statuses = await statusesPromise;
      dispatcher.dispatch({
        type: 'SET_STATUSES',
        statuses,
      });
      return statuses;
    } catch (err) {
      console.error('error');
      console.error(err);
      createSnack('Error', 'Problem loading statuses');
      return currentStatuses;
    }
  }
};
