import { get } from '../../Functions/API';
import dispatcher from './dispatcher';
import { createSnack } from '../../Stores/Snack/Actions';
import UsersStore from './Store';

export const getUsersByGroup = async (groupName) => {
  const users = await fetchUsers();
  return users.filter((user) => {
    const group = user?.groups?.find((group) => group.name === groupName);
    return Boolean(group);
  });
};

export const fetchUsers = async () => {
  const currentUsers = UsersStore.getUsers();
  if (currentUsers?.length) {
    return currentUsers;
  } else if (UsersStore.isFetching()) {
    const users = await UsersStore.getPromise();
    return users;
  } else {
    try {
      const promise = get(`/user`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        promise,
      });
      const users = await promise;
      dispatcher.dispatch({
        type: 'LOAD_USERS',
        users,
      });
      return users;
    } catch (err) {
      createSnack('Error', err.message);
      return currentUsers;
    }
  }
};
