/* eslint-disable no-restricted-syntax */
/* eslint react/no-children-prop:0 */
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint no-use-before-define:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint max-len:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import {
  Badge,
  Drawer,
  InputBase,
  ListItemIcon,
  ListItemText,
  Slide,
  Snackbar,
  SnackbarContent,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Divider,
  Popover,
  Modal,
} from '@material-ui/core';
import { AppBar } from '@mui/material';

import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import BugReportIcon from '@material-ui/icons/BugReport';
import HotTubIcon from '@material-ui/icons/HotTub';
import BuildIcon from '@material-ui/icons/Build';
import Shipping from '@material-ui/icons/LocalShipping';
import NotificationIcon from '@material-ui/icons/Notifications';
import CheckIn from '@material-ui/icons/CheckBox';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon2 from '@material-ui/icons/Mail';
import MenuBook from '@material-ui/icons/MenuBook';
import PublishIcon from '@material-ui/icons/Publish';
import ColorLens from '@material-ui/icons/ColorLens';
import WebOrders from '@material-ui/icons/CardGiftcardTwoTone';
import { AttachMoney, ListAlt, ImportExport, ShoppingCart, SupervisorAccount, Store, Print, AccountBalance } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import steve2 from '../../images/steve2.png';
import mckay2 from '../../images/mckay2.png';
import BlankBubble from '../../images/BlankBubble.png';
import WhatsNew from './WhatsNew/WhatsNew';

import Notifications from '../../Pages/notifications';
import { auth } from '../../fire';
import FirebaseMessagingStore from '../../FirebaseMessaging/Store';
import { get, post } from '../../Functions/API';
import UserStore from '../../Stores/User/Store';
import { loadUser } from '../../Stores/User/Actions';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { convertDateToSQLDate } from '../Utils/SqlDate';
import { WorkOrderStore } from '../../Stores';
import { createDialog } from '../../Stores/Dialog/Actions';
import BugRequest from './Bugs/BugRequest';

const Menu = ({ searchDB }) => {
  const [drawer, setDrawer] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [snackBar, setSnackBar] = useState({ open: false, message: '', variant: '' });
  const [mckay, setMckay] = useState(false);
  const [mckayNope, setMckayNope] = useState(false);
  const [mckayNopeDirection, setMckayNopeDirection] = useState('down');
  const [mckayDirection, setMckayDirection] = useState('down');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [anchorExcelEl, setAnchorExcelEl] = useState(null);
  const [showExcelUpload, setShowExcelUpload] = useState(false);
  const [width, setWidth] = useState(0);
  const [searchRef, setSearchRef] = useState(React.createRef());
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [whatsNewModalOpen, setWhatsNewModalOpen] = useState(false);
  const [whatsNewViews, setWhatsNewViews] = useState(0);
  const user = UserStore.getUser();
  const history = useHistory();
  const location = useLocation();

  const goTo = (path) => {
    setDrawer(false);
    history.push(path);
  };

  const getWhatsNewViews = () => {
    get('/getWhatsNewViews').then((results) => {
      setWhatsNewViews(results.length);
    });
  };

  const reloadUser = () => {
    if (user.uid) {
      loadUser(user.uid);
    }
  };

  useEffect(() => {
    getWhatsNewViews();
    FirebaseMessagingStore.on('notification', (notification) => loadNotification(notification));
    setWidth(window.innerWidth);
    getNotificationCount();
  }, []);

  const loadNotification = (notification) => {
    const { type, message } = notification;

    createSnack(type, message);
    setNotificationCount((notificationCount) => notificationCount + 1);
  };

  const toggleMcKay = () => {
    setMckay(true);
    setTimeout(() => {
      setMckay(false);
    }, 1000);
  };

  const toggleMcKayNope = () => {
    setMckayNope(true);
    setTimeout(() => {
      setMckayNope(false);
    }, 1000);
  };

  const submitBug = () => {
    const bug = prompt('Please explain your bug.');
    if (bug) {
      toggleMcKay();
      const json = {
        uid: user.uid,
        bug,
        url: window.location.href,
      };
      post('/submitBug', json)
        .then(() => {
          createSnack('Success', 'Your bug has been submitted. Thank you.');
        })
        .catch((err) => {
          console.log(err);
          createSnack('Error', "I'm sorry your bug could not be submitted.");
        });
    } else {
      toggleMcKayNope();
    }
  };

  const getNotificationCount = () => {
    const { uid } = user;
    get('/getUserNotificationsCount', [{ name: 'uid', value: uid }]).then((results) => {
      setNotificationCount(results.count);
    });
  };

  const logout = () => {
    auth.signOut().then(() => {
      localStorage.clear();
      window.location.href = '/';
    });
  };

  const searchBar = (event) => {
    const search = event.target.value;
    if (event.keyCode === 13) {
      get('/checkId', [{ name: 'id', value: search }])
        .then((response) => {
          if (response.workOrderID) {
            const { pathname } = location;
            if (pathname.toLowerCase().includes('workorder')) {
              const options = ['art', 'product', 'order', 'cost', 'invoice', 'production', 'history'];
              const { pathname } = location;
              const splitOptions = pathname.split('/');
              try {
                const workOrder = WorkOrderStore.getWorkOrder();
                const woIndex = splitOptions.findIndex((o) => o === `${workOrder.id}`);
                const routes = splitOptions.slice(woIndex + 1, splitOptions.length).join('/');
                if (routes) {
                  const path = `/workorder/${response.id}/${routes}`;
                  history.push(path);
                }
              } catch (err) {
                history.push(`/workorder/${response.id}`);
              }
            } else {
              history.push(`/workorder/${response.id}`);
            }
          } else {
            post('/setSearch', { search, uid: user.uid }).then(() => {
              history.push(`/?${search}`);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const search = async (event) => {
    event.stopPropagation();
    const term = event.target.value;
    if (term.length > 2 || term.toLowerCase() === 'i9') {
      await get('/searchTwoPointOh', { term }, 'obj')
        .then((results) => {
          setShowSearchResults(true);
          setSearchResults(results);
          setSearchTerm(term);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    } else {
      setSearchTerm(term);
    }
  };

  const toggleDrawer = (event) => {
    setDrawer(!drawer);
  };

  const toggleNotifications = (event) => {
    setAnchorEl(event.target ? event.target : null);
    setShowNotifications(!showNotifications);
  };

  const toggleExcelUpload = (event) => {
    setAnchorExcelEl(event.target ? event.target : null);
    setShowExcelUpload(!showExcelUpload);
  };

  const handleClose = () => {
    setSnackBar({ open: false, message: '', variant: '' });
  };

  const createSnack = (variant, message) => {
    setSnackBar({ open: true, variant, message });
  };

  const toggleShowSearchResults = () => {
    setShowSearchResults(!showSearchResults);
  };

  const notificationStyles = {
    Accounting: '#43a047',
    Success: '#43a047',
    Sales: '#ffa000',
    Warning: '#ffa000',
    Error: '#d32f2f',
    Info: '#3f51b5',
    Art: '#ff5e00',
    Fulfillment: '#785589',
    Production: '#979797',
    File: '#979797',
    Temp: '#c7c7c7',
  };

  return (
    <div>
      <Popover
        open={showNotifications}
        onClose={toggleNotifications}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Notifications
          notificationCount={notificationCount}
          get={get}
          post={post}
          close={toggleNotifications}
          setNotificationCount={setNotificationCount}
          user={user}
        />
      </Popover>
      <AppBar
        position="static"
        //Jeff wants purple lol
        color={
          user.uid === 'ggfv6CBBWURHvvMyzEVGbzLBKsm1' || process.env.NODE_ENV === 'development' || process.env.REACT_APP_LOCATION === 'beta'
            ? 'secondary'
            : 'primary'
        }
      >
        <Toolbar>
          <IconButton href="" onClick={toggleDrawer}>
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ cursor: 'pointer' }} onClick={() => goTo('/')}>
            {/* TODO showCSPPortalMenu */}
            {user.group !== 'Customer' ? 'YBA Portal' : 'CSP Portal'}
          </Typography>
          {/* TODO newWorkOrderMenuButton */}
          {width > 1200 && user.group !== 'Customer' && (
            <Button
              style={{ marginLeft: '2%' }}
              variant="contained"
              color="secondary"
              onClick={() => {
                // write a funtion here that creates a blank work order then redirects you to that work order.
                const dueDate = new Date();
                dueDate.setDate(dueDate.getDate() + 14);
                if (dueDate.getDay() === 6) {
                  dueDate.setDate(dueDate.getDate() + 1);
                }
                if (dueDate.getDay() === 0) {
                  dueDate.setDate(dueDate.getDate() + 1);
                }
                post('/newWorkOrderBlank', { uid: user.uid, dueDate: convertDateToSQLDate(dueDate) })
                  .then(async (response) => {
                    window.location.href = `/workorder/${response.id}`;
                  })
                  .catch((err) => {
                    createSnack('Error', err.message);
                  });
              }}
            >
              New
            </Button>
          )}
          {/* TODO viewNotificationsMenuButton */}
          {user.group !== 'Customer' &&
            user.group !== 'Production - Front End' &&
            user.group !== 'Production - Printer' &&
            user.group !== 'Production - Back End' && (
              <IconButton style={{ marginLeft: '2%' }} onClick={toggleNotifications}>
                <Badge overlap="circle" children="div" badgeContent={notificationCount} color="secondary">
                  <MailIcon2 style={{ color: 'white', fontSize: 30 }} />
                </Badge>
              </IconButton>
            )}
          <Tooltip title="Whats New To MSAS">
            <Badge overlap="circle" children="div" badgeContent="New!" color="error" invisible={!(whatsNewViews > 0)}>
              <IconButton
                onClick={() => {
                  setWhatsNewModalOpen(true);
                }}
              >
                <ChildFriendlyIcon style={{ color: 'white' }} />
              </IconButton>
            </Badge>
          </Tooltip>

          {/* TODO viewSearchMenu */}
          {user.group !== 'Customer' &&
            user.group !== 'Production - Front End' &&
            user.group !== 'Production - Printer' &&
            user.group !== 'Production - Back End' &&
            width > 1200 && (
              <>
                <div
                  style={{
                    color: 'white',
                    width: 'auto',
                    position: 'absolute',
                    right: '300px',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    borderRadius: '4px',
                  }}
                >
                  {!searchDB && (
                    <div>
                      <div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          marginLeft: '8px',
                          marginTop: '2px',
                        }}
                      >
                        <SearchIcon />
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                        ref={searchRef}
                      >
                        <InputBase
                          placeholder="Search"
                          style={{ color: 'white', padding: '6px', width: '250px' }}
                          inputProps={{ 'aria-label': 'search' }}
                          // onChange={search}
                          onKeyUp={searchBar}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          {width > 1200 && (
            <Button style={{ position: 'absolute', right: '8px' }} href="" variant="contained" color="secondary" onClick={logout}>
              Logout
            </Button>
          )}
          {width <= 1200 ? (
            <Typography variant="h6" style={{ position: 'absolute', right: '10px' }}>
              {user.firstName} {user.lastName}
            </Typography>
          ) : (
            <Typography variant="h6" style={{ position: 'absolute', right: '150px' }}>
              {user.firstName} {user.lastName}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer onClose={toggleDrawer} open={drawer}>
        <List component="div">
          <ListItem button onClick={() => goTo('/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          {/* TODO viewNotificationsMenuButton */}
          {width <= 1200 && user.group !== 'Customer' && (
            <>
              <ListItem button onClick={() => goTo('/notifications')}>
                <ListItemIcon>
                  <NotificationIcon />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
            </>
          )}
          <ListItem
            button
            component="div"
            onClick={() =>
              createDialog({
                content: <BugRequest />,
                title: 'Submit a Bug',
                size: 'md',
              })
            }
          >
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Submit a Bug" />
          </ListItem>
          <ListItem
            button
            onClick={() => goTo('/quotes')}
            style={{
              background: 'linear-gradient(90deg, rgba(170,241,255,1) 50%, rgba(0,212,255,1) 100%)',
            }}
          >
            <ListItemIcon>
              <MenuBook style={{ color: '#6762c7' }} />
            </ListItemIcon>
            <ListItemText primary="Quotes (Beta)" />
          </ListItem>
          <ListItem
            button
            onClick={() => goTo('/customers')}
            style={{
              background: 'radial-gradient(circle, rgba(255,212,170,1) 50%, rgba(255,126,0,1) 100%)',
            }}
          >
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText primary="Customer Manager" />
          </ListItem>
          <Divider />
          {/* TODO adminConsoleMenuButton */}
          {user.group.includes('Admin') && (
            <ListItem button onClick={() => goTo('/admin')}>
              <ListItemIcon>
                <HotTubIcon />
              </ListItemIcon>
              <ListItemText primary="Admin Console" />
            </ListItem>
          )}
          {/* TODO costsByOrderProcessorMenuButton */}
          {!user.group.includes('Production') && (
            <ListItem button onClick={() => goTo('/costs-by-order-processor')}>
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText primary="Costs by Order Processor" />
            </ListItem>
          )}
          {/* TODO salesSheetMenuButton */}
          {!user.hasPermission('viewAccountingCenter') && user.hasPermission('viewSalesSheet') && (
            <ListItem button onClick={() => goTo('/accounting/sales-sheet')}>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Sales Sheet" />
            </ListItem>
          )}
          {/* TODO accountingMenuButtons */}
          {user.hasPermission('viewAccountingCenter') && (
            <>
              <ListItem button onClick={() => goTo('/accounting')}>
                <ListItemIcon>
                  <img style={{ marginLeft: '-12px', width: '48px', height: '48px' }} src={steve2} />
                </ListItemIcon>
                <ListItemText primary="Accounting Center" />
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem button onClick={() => goTo('/info-plus')}>
            <ListItemIcon>
              <Shipping />
            </ListItemIcon>
            <ListItemText primary="InfoPlus" />
          </ListItem>
          <ListItem button onClick={() => goTo('/production/check-ins')}>
            <ListItemIcon>
              <CheckIn />
            </ListItemIcon>
            <ListItemText primary="Check Ins" />
          </ListItem>
          <ListItem button onClick={() => goTo('/yba-products')}>
            <ListItemIcon>
              {/* <CallSplitIcon /> */}
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="YBA Products" />
          </ListItem>
          <ListItem button onClick={() => goTo('/fulfillment')}>
            <ListItemIcon>
              {/* <CallSplitIcon /> */}
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Fulfillment" />
          </ListItem>
          {user.hasPermission('artRequests') && (
            <ListItem button onClick={() => goTo('/art-requests')}>
              <ListItemIcon>
                {/* <CallSplitIcon /> */}
                <ColorLens />
              </ListItemIcon>
              <ListItemText primary="Art Requests" />
            </ListItem>
          )}

          <Divider />
          {/* TODO defaultMenuButtons */}
          {user.group !== 'Customer' && (
            <>
              <ListItem button onClick={() => goTo('/Production/Calendar')}>
                <ListItemIcon>
                  <EventNoteIcon />
                </ListItemIcon>
                <ListItemText primary="Production Calendar" />
              </ListItem>
              <ListItem button onClick={() => goTo('/inventory')}>
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary="Inventory" />
              </ListItem>
              <ListItem button onClick={() => goTo('/csp')}>
                <ListItemIcon>
                  <Print />
                </ListItemIcon>
                <ListItemText primary="CSP" />
              </ListItem>
              <ListItem button onClick={() => goTo('/external-data')}>
                <ListItemIcon>
                  <ImportExport />
                </ListItemIcon>
                <ListItemText primary="External Data" />
              </ListItem>
            </>
          )}
          {/* TODO webOrdersMenuButton */}
          {/*{user.group !== 'Customer' && !user.group.includes('Front') && !user.group.includes('Back') && (*/}
          {/*  <ListItem button onClick={() => goTo('/webOrders')}>*/}
          {/*    <ListItemIcon>*/}
          {/*      <WebOrders />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary="Web Orders" />*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          {/* TODO olderSystemMenuButton */}
          {user.group !== 'Customer' && !user.group.includes('Front') && !user.group.includes('Back') && (
            <ListItem button onClick={() => goTo('/OldSystem')}>
              <ListItemIcon>
                <TrendingDownIcon />
              </ListItemIcon>
              <ListItemText primary="Older System" />
            </ListItem>
          )}
          {/* TODO developerToolsMenuButton */}
          {user.group === 'Admin' && (
            <ListItem button onClick={() => goTo('/developer-tools')}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Developer Tools" />
            </ListItem>
          )}
          <ListItem button onClick={reloadUser}>
            <ListItemIcon>
              <RefreshIcon />
            </ListItemIcon>
            <ListItemText primary="Reload User" />
          </ListItem>
          {width <= 1200 && (
            <>
              <ListItem
                button
                onClick={() => {
                  const dueDate = new Date();
                  dueDate.setDate(dueDate.getDate() + 14);
                  if (dueDate.getDay() === 6) {
                    dueDate.setDate(dueDate.getDate() + 1);
                  }
                  if (dueDate.getDay() === 0) {
                    dueDate.setDate(dueDate.getDate() + 1);
                  }
                  post('/newWorkOrderBlank', { uid: user.uid, dueDate: convertDateToSQLDate(dueDate) }).then((response) => {
                    window.location.href = `/workorder/${response.id}`;
                  });
                }}
              >
                <ListItemText primary="New Work Order" />
              </ListItem>
              <ListItem button onClick={logout} component="div">
                <ListItemText primary="Logout" />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <Modal open={mckay} hideBackdrop style={{ top: '10%', left: '5%' }}>
        <Slide direction={mckayDirection} in={mckay}>
          <div
            style={{
              position: 'absolute',
              outline: 'none',
            }}
          >
            <img
              style={{
                position: 'relative',
                height: '300px',
                zIndex: 3,
                width: '300px',
                padding: '10px',
              }}
              src={mckay}
            />
            <img
              style={{
                position: 'relative',
                zIndex: 1,
                left: width > 1200 ? '-100px' : '220px',
                top: width > 1200 ? '-100px' : '-300px',
                height: '150px',
                width: '200px',
                padding: '10px',
              }}
              src={BlankBubble}
            />
            <div
              style={{
                position: 'relative',
                fontSize: '30px',
                textAlign: 'center',
                zIndex: 2,
                left: '262px',
                top: width > 1200 ? '-220px' : '-420px',
                maxWidth: '155px',
                maxHeight: '100px',
              }}
            >
              Another one??
            </div>
          </div>
        </Slide>
      </Modal>
      <Modal open={mckayNope} hideBackdrop style={{ top: '10%', left: '5%' }}>
        <Slide direction={mckayNopeDirection} in={mckayNope}>
          <div
            style={{
              position: 'absolute',
              outline: 'none',
            }}
          >
            <img
              style={{
                position: 'relative',
                height: '300px',
                zIndex: 3,
                width: '300px',
                padding: '10px',
              }}
              src={mckay2}
            />
            <img
              style={{
                position: 'relative',
                zIndex: 1,
                left: width > 1200 ? '-100px' : '220px',
                top: width > 1200 ? '-100px' : '-300px',
                height: '150px',
                width: '200px',
                padding: '10px',
              }}
              src={BlankBubble}
            />
            <div
              style={{
                position: 'relative',
                fontSize: '15px',
                textAlign: 'center',
                zIndex: 2,
                left: '262px',
                top: width > 1200 ? '-220px' : '-420px',
                maxWidth: '155px',
                maxHeight: '100px',
              }}
            >
              No bugs here!
            </div>
          </div>
        </Slide>
      </Modal>
      <Modal open={whatsNewModalOpen} onClose={() => setWhatsNewModalOpen(false)}>
        <WhatsNew get={get} post={post} getViews={getWhatsNewViews} createSnack={createSnack} />
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent onClose={handleClose} style={{ backgroundColor: notificationStyles[snackBar.variant] }} message={snackBar.message} />
      </Snackbar>
    </div>
  );
};

export default Menu;
