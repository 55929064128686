import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import NewVendorDialog from './NewVendorDialog';
import VendorsStore from '../../Stores/Vendors/Store';
import { fetchVendors } from '../../Stores/Vendors/Actions';

const filter = createFilterOptions();

const VendorAutocomplete = ({ callback, initValue, label }) => {
  const [value, setValue] = React.useState({});
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = useState(null);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    } else {
      getVendors();
    }
    return () => {
      VendorsStore.removeListener('change', getVendors);
    };
  }, []);

  const getVendors = () => {
    const newVendors = [...VendorsStore.getVendors()];
    setVendors(newVendors);
    setValue(newVendors.find((v) => v.id === initValue));
  };

  // make changes here
  const handleValueChange = (newValue) => {
    setValue(newValue ? newValue : {});
    callback(newValue ? newValue.id : null);
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue(newValue.name);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue(newValue.inputValue);
          } else {
            handleValueChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={vendors}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        // style={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={label ? 'Vendor' : false} />}
      />
      <NewVendorDialog open={open} toggleOpen={toggleOpen} callback={handleValueChange} initialValue={dialogValue} />
    </React.Fragment>
  );
};

export default VendorAutocomplete;

VendorAutocomplete.propTypes = {
  callback: PropTypes.func.isRequired,
  initValue: PropTypes.string,
  label: PropTypes.bool,
};
