import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid, Card, CardMedia, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, patch, remove, post } from '../../../../Functions/API';
import { createSnack } from '../../../../Stores/Snack/Actions';
import { fetchUsers } from '../../../../Stores/Users/Actions';

const Group = ({ isEdit }) => {
  const { id } = useParams();
  const history = useHistory();
  const [group, setGroup] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [users, setUsers] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isEdit) {
      get(`/groups/${id}`).then(setGroup);
    } else {
      setGroup({});
    }
    fetchUsers().then(setUsers);
    get('/permissions').then(setPermissions);
  }, []);

  useEffect(() => {
    if (permissions?.length && group && users?.length) {
      setLoaded(true);
    }
  }, [group, users, permissions]);

  const usersChangeHandler = (e, values) => {
    setGroup({ ...group, users: values });
  };

  const permissionsChangeHandler = (e, values) => {
    setGroup({ ...group, permissions: values });
  };

  const saveGroup = () => {
    patch(`/groups/${id}`, group).then(() => {
      createSnack('Success', 'Saved group');
    });
  };

  const createGroup = () => {
    post(`/groups`, group).then(() => {
      history.push('/developer-tools/groups');
    });
  };

  const removeGroup = () => {
    remove(`/groups/${id}`, group).then(() => {
      history.push('/developer-tools/groups');
    });
  };

  const updateGroupName = (e) => {
    setGroup({ ...group, name: e.target.value });
  };

  return (
    <Grid style={{ marginTop: '10px', padding: '10px' }} container justify="center">
      <Grid item xs={12} sm={6}>
        <Card>
          <CardMedia style={{ padding: '10px' }}>
            {isEdit ? (
              <Typography gutterBottom variant="h5" component="h2">
                {group?.name}
              </Typography>
            ) : (
              <TextField id="group-name" onChange={updateGroupName} style={{ width: '100%' }} label="Name" value={group?.name} />
            )}
            {loaded && (
              <>
                <Autocomplete
                  multiple
                  id="users"
                  options={users}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  onChange={usersChangeHandler}
                  value={group?.users}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Index" placeholder="Index" />}
                />
                <Autocomplete
                  multiple
                  id="permissions"
                  options={permissions}
                  getOptionLabel={(option) => option.label}
                  onChange={permissionsChangeHandler}
                  value={group?.permissions}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Index" placeholder="Index" />}
                />
                <Grid style={{ marginTop: '20px' }} container justify="center" spacing={1}>
                  {isEdit ? (
                    <>
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={saveGroup}>
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="secondary" onClick={removeGroup}>
                          Delete
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={createGroup}>
                        Create
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

Group.propTypes = {
  isEdit: PropTypes.bool,
};

Group.displayName = 'Group';

export default Group;
