import React, { useEffect, useState } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import notificationStyles from '../../Components/Utils/NotificationStyles';
import { closeSnack } from './Actions';
import Store from './Store';
import { useSnackbar } from 'notistack';

const Snack = () => {
  const [snackBar, setSnackBar] = useState(Store.getSnack());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Store.on('change', setSnack);
    return () => {
      Store.removeListener('change', setSnack);
    };
  }, []);

  const setSnack = () => {
    enqueueSnackbar(Store.getSnack().message, { ...Store.getSnack() });
  };

  return null;
};

export default Snack;
