import React, { useEffect, useState } from 'react';
import { Badge, Button, Checkbox, DialogActions, FormControlLabel, Grid, IconButton, TextField } from '@material-ui/core';
import mckay from '../../../images/mckay.png';
import { get, post } from '../../../Functions/API';
import WorkOrderStore from '../../../Stores/WorkOrder/Store';
import SalesAssistants from '../../WorkOrder/Header/Components/Left/SalesAssistants';
import { Refresh } from '@material-ui/icons';
import { createSnack } from '../../../Stores/Snack/Actions';
import { closeDialog } from '../../../Stores/Dialog/Actions';
import { workorder } from '../../../Constants/backendRoutes';

const BugRequest = () => {
  const [subject, setSubject] = useState('');
  const [workOrderId, setWorkOrderId] = useState('');
  const [urgent, setUrgent] = useState(false);
  const [description, setDescription] = useState('');
  const [workOrder, setWorkOrder] = useState({});
  const [orderProcessors, setOrderProcessors] = useState([]);

  useEffect(() => {
    const workorder = WorkOrderStore.getWorkOrder();
    if (workorder?.id) {
      setWorkOrder(workorder);
      setWorkOrderId(workorder.workOrderID);
    }
  }, []);

  const textStyle = {
    width: '100%',
  };
  const inputStyle = { shrink: true };

  const submitBug = () => {
    post('/bugs', { subject, workOrderId, urgent, description }).then((results) => {
      closeDialog();
      createSnack('Success', 'Bug Submitted');
    });
  };

  const getWorkOrder = () => {
    if (workOrderId) {
      const filter = {
        eager: {
          $where: {
            workOrderID: workOrderId,
          },
        },
      };
      get(`${workorder.list}`, { filter }, 'obj').then(({ results }) => {
        console.log(results);
        const [wo] = results;
        setWorkOrder(wo);
      });
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Badge
            badgeContent={<img alt={'McKay will not be happy'} src={mckay} style={{ height: '35px', width: '35px', transform: 'rotate(20deg)' }} />}
            style={textStyle}
          >
            <TextField
              style={textStyle}
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              InputLabelProps={inputStyle}
              label={'Subject'}
            />
          </Badge>
        </Grid>
        <Grid item xs={4}>
          <Badge
            style={{ width: '100%' }}
            badgeContent={
              <IconButton size={'small'} color={'primary'} onClick={getWorkOrder}>
                <Refresh fontSize={'small'} />
              </IconButton>
            }
          >
            <TextField
              style={textStyle}
              onChange={(e) => setWorkOrderId(e.target.value)}
              value={workOrderId}
              onBlur={getWorkOrder}
              InputLabelProps={inputStyle}
              label={'Work Order #'}
            />
          </Badge>
        </Grid>
        <Grid item xs={4}>
          {/*<TextField style={textStyle} InputLabelProps={inputStyle} label={'Work Order #'} />*/}
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <FormControlLabel control={<Checkbox onChange={(e) => setUrgent(e.target.checked)} checked={urgent} />} label={'Urgent'} />
        </Grid>
        {workOrder.id && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField label={'Customer'} value={workOrder.customerName} disabled fullWidth InputLabelProps={inputStyle} />
              </Grid>
              <Grid item xs={4}>
                <TextField label={'Description'} value={workOrder.description} disabled fullWidth InputLabelProps={inputStyle} />
              </Grid>
              <Grid item xs={4}>
                <SalesAssistants workOrder={workOrder} disabled={true} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            style={textStyle}
            variant={'outlined'}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            InputLabelProps={inputStyle}
            multiline
            rows={4}
            label={'Description'}
            helperText={'Steps to Reproduce'}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button size={'small'} variant={'contained'} onClick={closeDialog}>
          Cancel
        </Button>
        <Button size={'small'} variant={'contained'} color={'primary'} onClick={submitBug}>
          Submit
        </Button>
      </DialogActions>
    </div>
  );
};

BugRequest.displayName = 'BugRequest';

export default BugRequest;
