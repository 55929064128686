/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate } from '../../../Functions/fulfillment';

const useStyles = makeStyles({
  list: {
    width: '300px',
  },
  title: {
    fontSize: 'large',
    textDecoration: 'underline',
  },
});

export default function TemporaryDrawer({ filters, setFilters }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const clearFilters = () => {
    const newFilters = {};
    for (const key in filters) {
      newFilters[key] = null;
    }
    setFilters(newFilters);
  };

  const updateFilter = (update) => {
    const [k, v] = update;
    const newFilters = {};
    for (const key in filters) {
      if (key === k) {
        newFilters[key] = v;
      } else {
        newFilters[key] = filters[key];
      }
    }
    setFilters(newFilters);
  };

  const DateFilter = ({ label, value, filter }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id={label}
        label={label}
        size={'small'}
        value={value}
        onChange={(newDate) => {
          updateFilter([filter, formatDate(newDate)]);
        }}
        style={{ width: '250px' }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );

  return (
    <div>
      <Button size={'small'} onClick={toggleDrawer} variant={'contained'}>
        Filters
      </Button>
      <Drawer anchor={'right'} open={open} onClose={toggleDrawer}>
        <div role="presentation" className={classes.list}>
          <Grid container spacing={1} alignItems="center" justify="space-evenly" direction="column">
            <Grid item xs={12}>
              <h1>Filters</h1>
            </Grid>
            <Grid item>
              <text className={classes.title}>Date Range</text>
            </Grid>
            <Grid item>
              <DateFilter label="Order Placed Start" value={filters.startDate || null} filter="startDate" />
            </Grid>
            <Grid item>
              {/* todo: ¿should this be by end of day endDate, or before endDate? */}
              <DateFilter label="Order Placed End" value={filters.endDate || null} filter="endDate" />
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
}

// todo: random thoughts/ future ideas:
//  * add more filterable terms? (ability to change vendor or store from in here?) (not statusId!)
