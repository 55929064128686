/* eslint-disable react/display-name */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint radix:0 */
/* eslint react/no-array-index-key:0 */
/* eslint max-len:0 */
/* eslint no-unused-vars:0 */
/* eslint no-plusplus:0 */
/* eslint no-console:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Papa from 'papaparse';
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { get, post } from '../../../Functions/API';
import DropZone from '../../WorkOrder/Footer/Components/Generate/DropZone';
import { createDialog } from '../../../Stores/Dialog/Actions';
import VendorAutocomplete from '../../Vendors/VendorAutocomplete';
import TypeAutocomplete from '../../WorkOrder/Footer/Components/Generate/Components/Type';
import VendorNugget from '../../Vendors/VendorNugget';
import OptionsStore from '../../../Stores/Options/Store';
import { loadOptions } from '../../../Stores/Options/Actions';
import { createSnack } from '../../../Stores/Snack/Actions';
import SkuSelector from './SkuSelector';
import SkuTableSelector from './SkuTableSelector';

const CSVTable = ({ workOrderID, getOrders, close }) => {
  const options = ['type', 'vendor', 'color', 'team', 'division', 'notes', 'cost', 'bill'];
  const other = ['adultStyle', 'youthStyle', 'removeSKU', 'addSKU'];
  const [data, setData] = useState([]);
  const [types, setTypes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    OptionsStore.on('change', getOptions);
    if (OptionsStore.getOptions.length === 0) {
      loadOptions();
    } else {
      getOptions();
    }
    get('/allPotentialSizes')
      .then((newSizes) => {
        setSizes(newSizes.sort((a, b) => (a.size > b.size ? 1 : -1)));
      })
      .catch((err) => createSnack(err.message));
    return () => {
      OptionsStore.removeListener('change', getOptions);
    };
  }, []);

  const getOptions = () => {
    setTypes([...OptionsStore.getOptions()]);
  };

  const CustomSelect = ({ onChange }) => {
    const items = ['Division', 'Color', 'Team', 'Type', 'Vendor', 'Notes', 'Cost', 'Bill'];
    return (
      <Select style={{ width: '100%' }} onChange={onChange}>
        <MenuItem value="">Nothing</MenuItem>
        <MenuItem value="youthStyle">Youth Style</MenuItem>
        <MenuItem value="adultStyle">Adult Style</MenuItem>
        <MenuItem value="removeSKU">Remove SKU</MenuItem>
        <MenuItem value="addSKU">Add SKU</MenuItem>
        {items.concat(sizes.map((s) => s.size)).map((i) => (
          <MenuItem key={i} value={i.toLowerCase()}>
            {i}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const parseData = async ({ data: rows }) => {
    let newData = [];
    let newColumns = [];
    let vendorFixes = {};
    let typeFixes = {};
    let headers = rows.shift();
    let count = {};

    // check that headers match options and counts their occurrences
    for (let header of headers) {
      const index = headers.indexOf(header);
      // checks to see if the header is a size
      const size = sizes.find((s) => s.size.toUpperCase() === header.toUpperCase());
      // adds to the count object for the header
      const found =
        options.find((o) => o.toLowerCase() === header.toLowerCase()) ||
        other.find((o) => o === header) ||
        sizes.find((r) => r.size.toUpperCase() === header.toUpperCase());
      let newHeader;
      if (!found) {
        await new Promise((resolve, reject) => {
          createDialog({
            title: `${header} does not match one of the available options. Please select a new one`,
            content: <CustomSelect onChange={(e) => (newHeader = e.target.value)} />,
            disableBackdropClick: true,
            actions: [
              {
                title: 'Save',
                color: 'primary',
                callback: () => {
                  header = newHeader;
                  headers[index] = newHeader ? newHeader : '';
                  resolve();
                },
              },
              {
                title: 'Ignore',
                color: 'secondary',
                callback: () => {
                  headers[index] = '';
                  resolve();
                },
              },
            ],
          });
        });
      }
      if (count[header.toLowerCase()] || count[size]) {
        count[size ? size.size : header.toLowerCase()] += 1;
      } else if (header !== '') {
        count[size ? size.size : header.toLowerCase()] = 1;
      }
    }

    // handle errors for missing or duplicate headers
    // const sizeFound = Object.keys(count).some((k) => sizes.find((s) => s.size.toUpperCase() === k.toUpperCase()));
    // if (!count.vendor || !count.type || !sizeFound) {
    //   createSnack('Error', 'vendor, type, and at least 1 size must be a column header in your file!');
    //   return;
    // } else
    if (Object.keys(count).some((k) => count[k] > 1)) {
      const duplicate = Object.keys(count).find((k) => count[k] > 1);
      createSnack('Error', `${duplicate} is a duplicate column`);
      return;
    }

    // loops through rows pushing objects into data
    // dialogs to correct vendors or types that did not match
    const allSkus = await get(`/sku`, { filter: { eager: { inventory: {} } } });
    for (let row of rows) {
      const lineObj = {};
      for (let i = 0; i < row.length; i++) {
        let cell = row[i];
        let header = headers[i];
        if (header === '' || (!cell && cell !== 0)) continue;
        if (header === 'removeSKU') {
          const skus = allSkus.filter((s) => s.sku === cell);
          if (!skus.length) {
            const { id, inventoryId } = await new Promise((res, rej) => {
              let id, inventoryId;
              const callback = ({ selected, inventory }) => {
                console.log(selected, inventory);
                id = selected;
                inventoryId = inventory?.id;
              };
              createDialog({
                title: 'Select Remove Sku',
                content: <SkuTableSelector allSkus={allSkus} callback={callback} cell={cell} />,
                size: 'lg',
                actions: [
                  {
                    title: 'Save',
                    color: 'primary',
                    callback: () => {
                      if (id && inventoryId) {
                        res({ id, inventoryId });
                      } else {
                        createSnack('Error', 'You must select a SKU');
                        rej();
                      }
                    },
                  },
                  { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                ],
              });
            });
            lineObj.removeSKU = id;
            lineObj.inventoryId = inventoryId;
            lineObj.useInventory = true;
          } else if (skus.length === 1) {
            console.log(skus);
            const { id, inventory } = skus[0];
            lineObj.removeSKU = id;
            lineObj.inventoryId = inventory.id;
            lineObj.useInventory = true;
          } else {
            const { id, inventoryId } = await new Promise((res, rej) => {
              let id, inventoryId;
              const callback = ({ selected, inventory }) => {
                id = selected;
                inventoryId = inventory.id;
              };
              createDialog({
                title: 'Select Remove Sku',
                content: <SkuSelector skus={skus} callback={callback} />,
                actions: [
                  {
                    title: 'Save',
                    color: 'primary',
                    callback: () => {
                      console.log(id, inventoryId);
                      if (id && inventoryId) {
                        res({ id, inventoryId });
                      } else {
                        createSnack('Error', 'You must select a SKU');
                        rej();
                      }
                    },
                  },
                  { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                ],
              });
            });
            lineObj.removeSKU = id;
            lineObj.inventoryId = inventoryId;
            lineObj.useInventory = true;
          }
        } else if (header === 'addSKU') {
          const skus = allSkus.filter((s) => s.sku === cell);
          if (!skus.length) {
            const { id } = await new Promise((res, rej) => {
              let id;
              const callback = ({ selected }) => {
                id = selected;
              };
              createDialog({
                title: 'Select Add Sku',
                content: <SkuTableSelector allSkus={allSkus} callback={callback} cell={cell} />,
                size: 'lg',
                actions: [
                  {
                    title: 'Save',
                    color: 'primary',
                    callback: () => {
                      if (id) {
                        res({ id });
                      } else {
                        createSnack('Error', 'You must select a SKU');
                        rej();
                      }
                    },
                  },
                  { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                ],
              });
            });
            lineObj.addSKU = id;
          } else if (skus.length === 1) {
            const { id } = skus[0];
            lineObj.addSKU = id;
          } else {
            const { id } = await new Promise((res, rej) => {
              let id;
              const callback = ({ selected }) => {
                id = selected;
              };
              createDialog({
                title: 'Select Add Sku',
                content: <SkuSelector skus={skus} callback={callback} />,
                actions: [
                  {
                    title: 'Save',
                    color: 'primary',
                    callback: () => {
                      if (id) {
                        res({ id });
                      } else {
                        createSnack('Error', 'You must select a SKU');
                        rej();
                      }
                    },
                  },
                  { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                ],
              });
            });
            lineObj.addSKU = id;
          }
        } else if (header.toLowerCase() === 'vendor') {
          const vendor = await get(`/vendors/getByName/${cell}`);
          if (!vendor) {
            if (vendorFixes[cell]) {
              lineObj.vendorID = vendorFixes[cell];
            } else {
              await new Promise((resolve, reject) => {
                let newVendorId;
                createDialog({
                  content: (
                    <VendorAutocomplete
                      callback={(val) => {
                        newVendorId = val;
                      }}
                    />
                  ),
                  disableBackdropClick: true,
                  title: `${cell} did not match any vendor in the system, please select the correct vendor`,
                  actions: [
                    {
                      title: 'Save',
                      color: 'primary',
                      callback: () => {
                        if (newVendorId) {
                          vendorFixes[cell] = newVendorId;
                          lineObj.vendorID = newVendorId;
                          resolve();
                        } else {
                          createSnack('Error', 'You must select a Vendor');
                          reject();
                        }
                      },
                    },
                    { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                  ],
                });
              });
            }
          } else {
            lineObj.vendorID = vendor.id;
          }
        } else if (header.toLowerCase() === 'type') {
          const type = types.find((t) => t.name.toLowerCase() === cell.toLowerCase());
          if (!type) {
            if (typeFixes[cell]) {
              lineObj.type = typeFixes[cell];
            } else {
              await new Promise((resolve, reject) => {
                let newType;
                createDialog({
                  content: (
                    <TypeAutocomplete
                      options={types}
                      callback={(val) => {
                        newType = val;
                      }}
                    />
                  ),
                  disableBackdropClick: true,
                  title: `${cell} did not match any type in the system, please select the correct type`,
                  actions: [
                    {
                      title: 'Save',
                      color: 'primary',
                      callback: () => {
                        if (newType) {
                          typeFixes[cell] = newType;
                          lineObj.type = newType;
                          resolve();
                        } else {
                          createSnack('Error', 'You must select a Type');
                          reject();
                        }
                      },
                    },
                    { title: 'Cancel', color: 'secondary', callback: () => createSnack('Error', 'Not Saved! \n Cancelled!') },
                  ],
                });
              });
            }
          } else {
            lineObj.type = type.name;
          }
        } else if (header === 'youthStyle' || header === 'adultStyle') {
          lineObj[header] = cell;
        } else if (['cost', 'bill'].includes(header.toLowerCase())) {
          lineObj[header.toLowerCase()] = cell.replace('$', '').trim();
        } else if (sizes.find((s) => s.size.toUpperCase() === header.toUpperCase())) {
          if (parseInt(cell, 10) && parseInt(cell, 10) > 0) {
            lineObj[header.toUpperCase()] = parseInt(cell, 10);
          }
        } else {
          lineObj[header.toLowerCase()] = cell;
        }
      }
      if (Object.keys(lineObj).length) {
        newData.push(lineObj);
      }
    }
    // loops through headers, adding columns appropriately
    for (let header of headers) {
      let option = '';
      if (options.find((o) => o === header.toLowerCase()) || other.find((o) => o === header)) {
        if (!other.find((o) => o === header)) {
          option = header.toLowerCase();
        } else {
          option = header;
        }
      }
      if (sizes.find((r) => r.size.toUpperCase() === header.toUpperCase())) {
        newColumns.push({ title: header.toUpperCase(), field: header.toUpperCase(), option: 'size' });
      } else if (header.toLowerCase() === 'vendor') {
        newColumns.push({
          title: 'Vendor',
          field: 'vendorID',
          editComponent: (t) => {
            const onChange = (newValue) => {
              t.onChange(newValue);
            };
            return <VendorAutocomplete callback={onChange} initValue={t.value} />;
          },
          render: (rowData) => {
            return <VendorNugget rowData={rowData} />;
          },
          option: 'vendor',
        });
      } else if (other.includes(header)) {
        newColumns.push({ title: header, field: header, option });
      } else if (header) {
        newColumns.push({ title: header, field: header.toLowerCase(), option });
      }
    }
    setColumns(newColumns);
    setData(newData);
  };

  const handleDrop = async (files) => {
    for (const file of files) {
      if (!file) continue;
      Papa.parse(file, { complete: parseData, skipEmptyLines: true });
    }
  };

  const Instructions = () => {
    const avatars = [1, 2, 3, 4];
    const titles = ['Upload a CSV', 'Match the columns to MSAS info', 'Double Check', 'Submit'];
    const content = [
      'You will need to export an Excel file as a CSV. Keep it as simple as possible. No merged rows, no additional titles throughout, no side by side products. Just the headers and info of what you want to import. Not the customer information. Ask Breg if you have any questions.',
      'Possible column headers are: Vendor, Type, Color, Team, youthStyle, adultStyle, notes, division, cost, bill, removeSKU, addSKU, or a size (ex. "xs", "am")',
      'Scroll through the rows and make sure everything looks right. Delete the row or fix things as necessary.',
      'Enjoy not having put this in manually!',
    ];
    return avatars.map((a) => (
      <ListItem key={a}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#3f51b5', color: 'white' }}>{a}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={titles[a - 1]} secondary={content[a - 1]} />
      </ListItem>
    ));
  };

  const submit = () => {
    post('/importCSV', {
      columns,
      data,
      workOrderID,
    })
      .then(() => {
        close();
        getOrders(0);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      style={{
        position: 'absolute',
        outline: 'none',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        height: '100%',
        overflowX: 'hidden',
      }}
    >
      <Typography style={{ margin: '10px' }} variant="h4" component="h4">
        MSAS Importer
      </Typography>
      <List style={{ width: '50%' }}>
        <Instructions />
      </List>
      <DropZone handleDrop={handleDrop} id="csv-table-dropzone" />
      <MaterialTable
        title="Imported Data"
        components={{
          Header: (props) => {
            return (
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  {props.columns.map((column, key) => (
                    <TableCell key={key}>{column.title}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell />
                  {props.columns.map((column, key) => (
                    <TableCell key={key} style={{ fontWeight: '100' }}>
                      {column.option === 'size' &&
                        data.reduce((total, row) => {
                          return parseInt(row[column.field]) ? parseInt(row[column.field]) + total : total;
                        }, 0)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            );
          },
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <Button style={{ margin: '10px' }} variant="contained" color="primary" onClick={submit}>
                  Submit
                </Button>
              </div>
            );
          },
        }}
        editable={{
          onRowDelete: async (oldData) => {
            const newData = [...data];
            const index = data.indexOf(oldData);
            newData.splice(index, 1);
            setData(newData);
          },
          onRowUpdate: async (newData, oldData) => {
            const newData2 = [...data];
            const index = data.indexOf(oldData);
            newData2[index] = newData;
            setData(newData2);
          },
        }}
        options={{ maxBodyHeight: '800px', paging: false }}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default CSVTable;
