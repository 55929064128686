/* eslint no-param-reassign:0 */
/* eslint radix:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-console:0 */
/* eslint no-shadow:0 */
/* eslint consistent-return:0 */
/* eslint react/prop-types:0 */
/* eslint eqeqeq:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Paper } from '@material-ui/core';
import { get, post } from '../../../../../Functions/API';
import UserStore from '../../../../../Stores/User/Store';

class Timesheet extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.state = {
      workOrderID: 0,
      numOfLines: [],
      arrivalDateModal: false,
      submitted: false,
      countTotal: date,
      mixInkTotal: date,
      burnScreensTotal: date,
      printTotal: date,
      packagingTotal: date,
      totalTime: 0,
      users: [],
      options: [],
      // todo: add notes and estimated cost and actual cost the actual cost column should only be edited by steve or jordana
      columns: [
        {
          title: 'Employee',
          field: 'userID',
          editable: 'never',
          render: (dataRow) => {
            let employee = [];
            this.state.users.forEach((user) => {
              if (user.uid == dataRow.userID) {
                employee = user;
              }
            });

            return (
              <div>
                {employee.firstName} {employee.lastName}
              </div>
            );
          },
        },
        {
          title: 'Type',
          field: 'type',
          editable: this.props.user.group === 'Accounting Admin' || this.props.user.group === 'Admin' ? 'always' : 'never',
          render: (rowData) => {
            switch (rowData.type) {
              case 'count':
                return <div>Count</div>;
              case 'mixInk':
                return <div>Mix Ink</div>;
              case 'burnScreens':
                return <div>Burn Screens</div>;
              case 'print':
                return <div>Print</div>;
              case 'packaging':
                return <div>Packaging</div>;
              default:
            }
          },
        },
        {
          title: 'Clock In',
          field: 'clockIn',
          type: 'datetime',
          render: (rowData) => {
            const date = new Date(rowData.clockIn);
            return `${date.toDateString()} ${date.getHours().toString().length > 1 ? date.getHours() : `0${date.getHours()}`}:${
              date.getMinutes().toString().length > 1 ? date.getMinutes() : `0${date.getMinutes()}`
            }:${date.getSeconds().toString().length > 1 ? date.getSeconds() : `0${date.getSeconds()}`}`;
          },
        },
        {
          title: 'Clock Out',
          field: 'clockOut',
          type: 'datetime',
          render: (rowData) => {
            const date = new Date(rowData.clockOut);
            if (rowData.clockOut) {
              return `${date.toDateString()} ${date.getHours().toString().length > 1 ? date.getHours() : `0${date.getHours()}`}:${
                date.getMinutes().toString().length > 1 ? date.getMinutes() : `0${date.getMinutes()}`
              }:${date.getSeconds().toString().length > 1 ? date.getSeconds() : `0${date.getSeconds()}`}`;
            }
            return 'Missing clock out';
          },
        },
        {
          title: 'Elapsed Time',
          type: 'string',
          render: (rowData) => {
            if (rowData && rowData.clockIn && rowData.clockOut) {
              let seconds = 0;
              let minutes = 0;
              let hours = 0;
              const time = 0;
              if (rowData.clockIn && rowData.clockOut) {
                const clockIn = new Date(rowData.clockIn);
                const clockOut = new Date(rowData.clockOut);
                const elapsedTime = clockOut - clockIn;
                const date = new Date();
                date.setHours(0, 0, 0, elapsedTime);
                hours = date.getHours();
                minutes = date.getMinutes();
                seconds = date.getSeconds();
              }
              return (
                <div>
                  {hours} Hours
                  {minutes} Minutes
                  {seconds} Seconds
                </div>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    const filter = {
      eager: {
        $where: {
          type: 'type',
        },
      },
    };
    get('/options', { filter })
      .then((results) => {
        this.setState({
          options: { type: results },
        });
      })
      .then(() => {
        this.getTimesheet();
      })
      .then(() => {
        get('/allUsers', []).then((usersGet) => {
          this.setState({
            users: usersGet,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  getTimesheet = () => {
    get('/getTimesheet', [{ name: 'workOrderID', value: this.props.workOrder.id }])
      .then((results) => {
        const numOfLines = results.rows;
        const { submitted } = results;

        this.setState({
          workOrderID: this.props.workOrder.id,
          numOfLines,
          submitted,
        });
        this.getTotals(numOfLines);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTotals = (data) => {
    const resetDate = new Date();
    resetDate.setHours(0, 0, 0, 0);
    this.setState({
      countTotal: resetDate,
      mixInkTotal: resetDate,
      burnScreensTotal: resetDate,
      printTotal: resetDate,
      packagingTotal: resetDate,
    });
    data.forEach((item) => {
      const date = new Date();
      let newDate = new Date();
      let milliseconds = 0;
      let seconds = 0;
      let minutes = 0;
      let hours = 0;
      let time = 0;
      if (item.clockIn && item.clockOut) {
        const cinSeconds = parseInt(item.clockIn.slice(-2));
        const cinMinutes = parseInt(item.clockIn.slice(-5, -3));
        const cinHours = parseInt(item.clockIn.slice(-8, -6));
        const coutSeconds = parseInt(item.clockOut.slice(-2));
        const coutMinutes = parseInt(item.clockOut.slice(-5, -3));
        const coutHours = parseInt(item.clockOut.slice(-8, -6));

        const rawSeconds = coutSeconds - cinSeconds;
        const rawMinutes = coutMinutes - cinMinutes;
        const rawHours = coutHours - cinHours;

        if (rawSeconds < 0) {
          seconds = 60 + rawSeconds;
          minutes = -1;
        } else {
          seconds = rawSeconds;
        }

        if (rawMinutes < 0) {
          minutes += 60 + rawMinutes;
          hours = -1;
        } else {
          minutes += rawMinutes;
        }

        if (rawHours < 0) {
          hours += 24 + rawHours;
        } else {
          hours += rawHours;
        }

        time = seconds + minutes * 60 + hours * 60 * 60;
        const clockIn = new Date(item.clockIn);
        const clockOut = new Date(item.clockOut);
        const elapsedTime = clockOut - clockIn;

        date.setHours(0, 0, 0, elapsedTime);
      }
      if (item.clockIn && item.clockOut) {
        switch (item.type) {
          case 'count':
            hours = date.getHours() + this.state.countTotal.getHours();
            minutes = date.getMinutes() + this.state.countTotal.getMinutes();
            seconds = date.getSeconds() + this.state.countTotal.getSeconds();
            milliseconds = date.getMilliseconds() + this.state.countTotal.getMilliseconds();
            newDate = new Date();
            newDate.setHours(hours, minutes, seconds, milliseconds);
            this.setState({
              countTotal: newDate,
            });
            break;
          case 'mixInk':
            hours = date.getHours() + this.state.mixInkTotal.getHours();
            minutes = date.getMinutes() + this.state.mixInkTotal.getMinutes();
            seconds = date.getSeconds() + this.state.mixInkTotal.getSeconds();
            milliseconds = date.getMilliseconds() + this.state.mixInkTotal.getMilliseconds();
            newDate = new Date();
            newDate.setHours(hours, minutes, seconds, milliseconds);
            this.setState({
              mixInkTotal: newDate,
            });
            break;
          case 'burnScreens':
            hours = date.getHours() + this.state.burnScreensTotal.getHours();
            minutes = date.getMinutes() + this.state.burnScreensTotal.getMinutes();
            seconds = date.getSeconds() + this.state.burnScreensTotal.getSeconds();
            milliseconds = date.getMilliseconds() + this.state.burnScreensTotal.getMilliseconds();
            newDate = new Date();
            newDate.setHours(hours, minutes, seconds, milliseconds);
            this.setState({
              burnScreensTotal: newDate,
            });
            break;
          case 'print':
            hours = date.getHours() + this.state.printTotal.getHours();
            minutes = date.getMinutes() + this.state.printTotal.getMinutes();
            seconds = date.getSeconds() + this.state.printTotal.getSeconds();
            milliseconds = date.getMilliseconds() + this.state.printTotal.getMilliseconds();
            newDate = new Date();
            newDate.setHours(hours, minutes, seconds, milliseconds);
            this.setState({
              printTotal: newDate,
            });
            break;
          case 'packaging':
            hours = date.getHours() + this.state.packagingTotal.getHours();
            minutes = date.getMinutes() + this.state.packagingTotal.getMinutes();
            seconds = date.getSeconds() + this.state.packagingTotal.getSeconds();
            milliseconds = date.getMilliseconds() + this.state.packagingTotal.getMilliseconds();
            newDate = new Date();
            newDate.setHours(hours, minutes, seconds, milliseconds);
            this.setState({
              packagingTotal: newDate,
            });
            break;
          default:
        }
      }
      const newTotalTime = this.state.totalTime + time;
      this.setState({
        totalTime: newTotalTime,
      });
    });
  };

  removeRow = (id) => {
    post('/removeClock', { id })
      .then(() => {
        this.getTimesheet();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Timesheet History"
            columns={this.state.columns}
            data={this.state.numOfLines}
            options={{
              rowStyle: {
                fontSize: 14,
              },
              pageSizeOptions: [10, 15, 25, 50],
              pageSize: 5,
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                console.log(newData);
                if (newData.clockIn instanceof Date) {
                  newData.clockIn = newData.clockIn.toUTCString();
                }
                if (newData.clockOut instanceof Date) {
                  newData.clockOut = newData.clockOut.toUTCString();
                }
                console.log(newData);
                post('/updateClock', {
                  numOfLines: newData,
                  workOrderID: this.state.workOrderID,
                  user: this.props.user,
                })
                  .then(() => {
                    this.getTimesheet();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              },
              onRowDelete: async (oldData) => {
                if (oldData.id) {
                  this.removeRow(oldData.id);
                }
              },
            }}
          />
        </Paper>
        <div
          style={{
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            fontSize: 'x-large',
          }}
        >
          Total Time Spent
        </div>
        <div
          style={{
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            fontSize: 'large',
          }}
        >
          <strong>
            Count:
            {this.state.countTotal.getHours()}
          </strong>{' '}
          Hours <strong>{this.state.countTotal.getMinutes()}</strong> Minutes <strong>{this.state.countTotal.getSeconds()}</strong> Seconds
          <br />
          <strong>
            Mix Ink:
            {this.state.mixInkTotal.getHours()}
          </strong>{' '}
          Hours <strong>{this.state.mixInkTotal.getMinutes()}</strong> Minutes <strong>{this.state.mixInkTotal.getSeconds()}</strong> Seconds
          <br />
          <strong>
            Burn Screens:
            {this.state.burnScreensTotal.getHours()}
          </strong>{' '}
          Hours <strong>{this.state.burnScreensTotal.getMinutes()}</strong> Minutes <strong>{this.state.burnScreensTotal.getSeconds()}</strong>{' '}
          Seconds
          <br />
          <strong>
            Print:
            {this.state.printTotal.getHours()}
          </strong>{' '}
          Hours <strong>{this.state.printTotal.getMinutes()}</strong> Minutes <strong>{this.state.printTotal.getSeconds()}</strong> Seconds
          <br />
          <strong>
            Packaging:
            {this.state.packagingTotal.getHours()}
          </strong>{' '}
          Hours <strong>{this.state.packagingTotal.getMinutes()}</strong> Minutes <strong>{this.state.packagingTotal.getSeconds()}</strong> Seconds
        </div>
        <div
          style={{
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            fontSize: 'x-large',
          }}
        >
          <strong>
            Total:{' '}
            {this.state.countTotal.getHours() +
              this.state.mixInkTotal.getHours() +
              this.state.burnScreensTotal.getHours() +
              this.state.printTotal.getHours() +
              this.state.packagingTotal.getHours()}
          </strong>{' '}
          Hours{' '}
          <strong>
            {this.state.countTotal.getMinutes() +
              this.state.mixInkTotal.getMinutes() +
              this.state.burnScreensTotal.getMinutes() +
              this.state.printTotal.getMinutes() +
              this.state.packagingTotal.getMinutes()}
          </strong>{' '}
          Minutes{' '}
          <strong>
            {this.state.countTotal.getSeconds() +
              this.state.mixInkTotal.getSeconds() +
              this.state.burnScreensTotal.getSeconds() +
              this.state.printTotal.getSeconds() +
              this.state.packagingTotal.getSeconds()}
          </strong>{' '}
          Seconds
        </div>
      </div>
    );
  }
}

export default Timesheet;
