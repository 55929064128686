/* eslint-disable react/display-name */
/* eslint react/no-unescaped-entities:0 */
/* eslint no-use-before-define:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-shadow:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, CardHeader, Divider, Collapse, Popover, Tooltip, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import MaterialTable from 'material-table';
import Modal from '@material-ui/core/Modal';
import SalesGoalsReportTeam from './SalesGoalsReportTeam';
import { monthsWithNumber } from '../../../Utils/monthsOfTheYear';

const SalesGoalsOverall = ({ refresh, get }) => {
  const [open, setOpen] = useState(true);
  const [goalYear, setGoalYear] = useState(moment());
  const [salesGoalData, setSalesGoalData] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [salesDetails, setSalesDetails] = useState([]);
  const [salesTotals, setSalesTotals] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const getSalesGoalsAll = () => {
    get('/salesgoal/team', [
      {
        name: 'year',
        value: goalYear.format('YYYY'),
      },
    ])
      .then((res) => {
        setSalesGoalData(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getDetails = (uid, month, year) => {
    get('/salesDetailsThatProbablyExist', { uid, month, year }, 'obj')
      .then((returned) => {
        const { results, totals } = returned;
        setSalesDetails(results);
        setSalesTotals(totals);
        setUser(uid);
        setUser(month);
        setUser(year);
        toggleShowDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const handleGoalDateChange = (date) => {
    const newDate = moment(date);
    setGoalYear(newDate);
  };

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    if (salesGoalData) setSalesGoalData(null);
    getSalesGoalsAll();
  }, [refresh, goalYear]);

  return (
    <>
      <Modal style={{ top: '10%' }} open={showDetails} onClose={toggleShowDetails}>
        <div
          style={{
            width: '100%',
            height: '100%',
            outline: 'none',
            backgroundColor: 'white',
            overflowY: 'scroll',
          }}
        >
          <MaterialTable
            title={`${
              salesDetails.length && salesDetails[0] && salesDetails[0].firstName
                ? `${salesDetails[0].firstName}'s ${monthsWithNumber[salesDetails[0].month - 1][0]} Sales Details`
                : 'Sales Details'
            }`}
            columns={[
              { title: 'ID', field: 'workOrderID' },
              { title: 'Customer', field: 'customerName' },
              {
                title: 'Sales',
                field: 'bill',
                render: (rowData) => `$${rowData.bill.toFixed(2) ? rowData.bill.toFixed(2) : rowData.bill}`,
              },
              {
                title: 'Costs',
                field: 'cost',
                render: (rowData) => `$${rowData.cost.toFixed(2) ? rowData.cost.toFixed(2) : rowData.cost}`,
              },
              {
                title: 'Profit',
                field: 'profit',
                render: (rowData) => `$${rowData.profit.toFixed(2) ? rowData.profit.toFixed(2) : rowData.profit}`,
              },
              {
                title: 'Closed',
                field: 'accountingApproved',
                render: (rowData) => (
                  <Checkbox size="small" color="primary" value={Boolean(rowData.accountingApproved)} checked={Boolean(rowData.accountingApproved)} />
                ),
              },
              { title: 'Closed Date', field: 'closedDate', type: 'date' },
              {
                title: 'Paid',
                field: 'paidDate',
                render: (rowData) => (
                  <Checkbox size="small" color="secondary" checked={Boolean(rowData.paidDate)} value={Boolean(rowData.paidDate)} />
                ),
              },
              { title: 'Paid Date', field: 'paidDate', type: 'date' },
              {
                title: 'Written Off',
                field: 'writtenOff',
                render: (rowData) => (
                  <Checkbox size="small" color="secondary" checked={Boolean(rowData.writtenOff)} value={Boolean(rowData.writtenOff)} />
                ),
              },
            ]}
            data={salesDetails}
            onRowClick={(event, rowData) => {
              window.open(`/workorder/${rowData.id}`, '_blank');
            }}
            options={{
              search: false,
              paging: false,
              padding: 'dense',
              exportButton: true,
            }}
          />
        </div>
      </Modal>
      <Card style={{ height: open ? '100%' : 'auto' }}>
        <CardHeader
          action={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                <InfoIcon id="infoIcon" />
              </IconButton>
              <Popover
                open={infoOpen}
                anchorEl={anchorEl}
                onClose={openInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                  This table is designed to keep track of Salesmens data. At the bottom of each month, it adds everything up into Totals. (Or and
                  average for %GP)
                  <br />
                  If a Salesmen has no profit, revenue, or cost for a particular month recorded in the system, they won't show up.
                  <br />
                </Typography>
              </Popover>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginTop: '4px', width: '100px' }}
                  variant="inline"
                  views={['year']}
                  minDate="2010/12/29"
                  maxDate="2030/12/29"
                  label="Year"
                  value={goalYear}
                  onChange={(date) => handleGoalDateChange(date)}
                />
              </MuiPickersUtilsProvider>
            </div>
          }
          title={
            <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Sales Goals</Typography>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <CardContent style={{ paddingBottom: '16px', height: '525px', overflow: 'auto' }}>
            {salesGoalData ? (
              <SalesGoalsReportTeam salesGoalData={salesGoalData} getDetails={getDetails} goalYear={goalYear} />
            ) : (
              <>
                <Skeleton variant="rect" width="100%" height={200} style={{ margin: '20px 0' }} />
                <Skeleton variant="rect" width="100%" height={200} style={{ margin: '20px 0' }} />
                <Skeleton variant="rect" width="100%" height={200} style={{ margin: '20px 0' }} />
              </>
            )}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default SalesGoalsOverall;
