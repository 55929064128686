import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  CardActions,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from '@material-ui/core';
import { FileCopy, Save, Delete, ZoomIn, Edit, Fullscreen as FullScreen } from '@material-ui/icons';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';
import { fetchPrintGroups } from '../../Stores/PrintGroups/Actions';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

function IndividualArtCard({
  openModal,
  individualArt,
  updateArt,
  updateIndividualArt,
  updatingChip,
  csp,
  key,
  toggleReversible,
  toggleWeed,
  toggleArtToUpdate,
  toggleUnderLay,
  deleteArt,
  updateFile,
  copyArt,
  viewOrders,
  WorkOrderId,
}) {
  const [printGroups, setPrintGroups] = useState([]);

  useEffect(() => {
    fetchPrintGroups().then(setPrintGroups);
  });

  return (
    <Card
      style={{
        width: '345px',
        height: '800px',
        position: 'relative',
        ...(individualArt.saved ? { border: '2px solid rgba(0,0,0,0)' } : { border: '2px solid red' }),
      }}
    >
      <CardHeader
        title={
          <>
            {printGroups && (
              <Autocomplete
                id="print-group"
                style={{ paddingBottom: '8px' }}
                value={individualArt?.printGroup}
                options={printGroups}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updateIndividualArt(v, individualArt, 'printGroup');
                }}
                renderInput={(params) => <TextField {...params} label="Price List" variant="outlined" />}
              />
            )}
          </>
        }
        subheader={
          <>
            {individualArt?.printGroup?.printTypes && (
              <Autocomplete
                id="print-type"
                value={individualArt?.printType}
                options={individualArt?.printGroup?.printTypes}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updateIndividualArt(v, individualArt, 'printType');
                }}
                renderInput={(params) => <TextField {...params} label="Print Type" variant="outlined" />}
              />
            )}
          </>
        }
      />

      <CardContent>
        {updatingChip !== individualArt ? (
          individualArt?.name?.substr(individualArt?.name?.length - 4, individualArt?.name?.length) === 'xlsx' ||
          individualArt?.name?.substr(individualArt?.name?.length - 3, individualArt?.name?.length) === 'xls' ||
          individualArt?.name?.substr(individualArt?.name?.length - 3, individualArt?.name?.length) === 'csv' ? (
            <a href={individualArt?.url}>{individualArt?.name}</a>
          ) : !individualArt?.url ? (
            <div
              style={{
                width: '100%',
                height: '350px',
              }}
            >
              <img
                style={{
                  width: '100%',
                  marginTop: '50px',
                }}
                src="https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08"
              />
            </div>
          ) : individualArt?.url?.includes('.jpg') || individualArt?.url?.includes('.png') ? (
            <Grid
              style={{
                height: '300px',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
              container
              justify="center"
            >
              <Grid item>
                <img
                  style={{
                    backgroundColor: '#eaeaea',
                    maxHeight: '300px',
                    verticalAlign: 'middle',
                    maxWidth: '300px',
                  }}
                  src={individualArt.url}
                />
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                position: 'relative',
                height: '100%',
              }}
            >
              <iframe
                src={
                  individualArt.url ||
                  'https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08'
                }
                style={{
                  width: '100%',
                  height: '300px',
                }}
              />
            </div>
          )
        ) : (
          <DropZone autoUpload={true} type={'Work Order Art'} handleDrop={updateFile} id={`updateArt${key}`} workOrderID={WorkOrderId} />
        )}
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <IconButton size="small" onClick={() => toggleArtToUpdate(individualArt)}>
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton size="small" color="secondary" onClick={() => openModal(individualArt)}>
              <FullScreen fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%' }}
              label="Name"
              onChange={(event) => updateIndividualArt(event.target.value, individualArt, 'name')}
              value={individualArt.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%' }}
              label="Position"
              onChange={(event) => updateIndividualArt(event.target.value, individualArt, 'position')}
              value={individualArt.position ? individualArt.position : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: '100%' }}
              label="Ink Color"
              onChange={(event) => updateIndividualArt(event.target.value, individualArt, 'color')}
              value={individualArt.color ? individualArt.color : ''}
            />
          </Grid>
          {Boolean(individualArt?.printType?.number) && (
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                label="1-15 or 1,2,3"
                onChange={(event) => updateIndividualArt(event.target.value, individualArt, 'numbers')}
                value={individualArt.numbers ? individualArt.numbers : ''}
              />
            </Grid>
          )}
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="reversible"
                  size="small"
                  checked={Boolean(individualArt.reversible)}
                  onChange={(event) => toggleReversible(event, individualArt)}
                  value={Boolean(individualArt.reversible)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>Reversible</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
          {Boolean(individualArt?.printGroup?.type === 'screenPrint') && (
            <Grid individualArt xs={4} style={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    id="underlay"
                    size="small"
                    checked={Boolean(individualArt.underlay)}
                    onChange={(event) => toggleUnderLay(event, individualArt)}
                    value={Boolean(individualArt.underlay)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: 'smaller' }}>Underlay</Typography>}
                labelPlacement="bottom"
              />
            </Grid>
          )}
          {Boolean(individualArt?.printGroup?.type === 'heatPress') && (
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    id="medWeed"
                    size="small"
                    checked={Boolean(individualArt.medWeed)}
                    onChange={(event) => toggleWeed(event, individualArt, 'medWeed')}
                    value={Boolean(individualArt.medWeed)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: 'smaller' }}>M-Weed</Typography>}
                labelPlacement="bottom"
              />
            </Grid>
          )}
          {Boolean(individualArt?.printGroup?.type === 'heatPress') && (
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    id="hardWeed"
                    size="small"
                    checked={Boolean(individualArt.hardWeed)}
                    onChange={(event) => toggleWeed(event, individualArt, 'hardWeed')}
                    value={Boolean(individualArt.hardWeed)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: 'smaller' }}>H-Weed</Typography>}
                labelPlacement="bottom"
              />
            </Grid>
          )}
        </Grid>
        <CardActions style={{ position: 'absolute', right: 0, bottom: 0 }}>
          <Tooltip title="View Orders">
            <IconButton size="small" onClick={() => viewOrders(individualArt)}>
              <ZoomIn fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" disabled={csp && workOrder.costAndInvoiceComplete} onClick={() => copyArt(individualArt)}>
              <FileCopy fontSize="small" color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save">
            <IconButton size="small" onClick={() => updateArt(individualArt)} disabled={csp && workOrder.costAndInvoiceComplete}>
              <Save fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" onClick={(event) => deleteArt(event, individualArt)} disabled={csp && workOrder.costAndInvoiceComplete}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </CardActions>
      </CardContent>
    </Card>
  );
}

IndividualArtCard.propTypes = {
  openModal: PropTypes.func,
  individualArt: PropTypes.object,
  updateArt: PropTypes.func,
  updateIndividualArt: PropTypes.func,
  updatingChip: PropTypes.object,
  csp: PropTypes.any,
  key: PropTypes.any,
  toggleArtToUpdate: PropTypes.func,
  toggleWeed: PropTypes.func,
  toggleUnderLay: PropTypes.func,
  toggleReversible: PropTypes.func,
  deleteArt: PropTypes.func,
  updateFile: PropTypes.func,
  copyArt: PropTypes.func,
  viewOrders: PropTypes.func,
  WorkOrderId: PropTypes.any,
};

export default IndividualArtCard;
