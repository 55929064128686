import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams, useRouteMatch, Link } from 'react-router-dom';
import { Button, Card, CardActions, CardHeader, Grid } from '@material-ui/core';
import { get } from '../../../Functions/API';
import UserStore from '../../../Stores/User/Store';
import InventoryView from '../InventoryView';

const Home = () => {
  const { path, url } = useRouteMatch();
  console.log(path, url);
  const user = UserStore.getUser();
  const { userId } = useParams();
  const [inventories, setInventories] = useState([]);

  useEffect(() => {
    get(`/inventory/user/${user.id}`).then((results) => {
      setInventories(results);
    });
  }, []);

  return (
    <div style={{ margin: '10px' }}>
      <Grid container spacing={1}>
        {inventories.map((inventory, key) => (
          <Grid item key={key}>
            <Card>
              <CardHeader title={inventory.name} />
              <CardActions>
                <Link to={`/inventory/${inventory.id}`}>
                  <Button variant={'contained'}>View</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
