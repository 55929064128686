/* eslint-disable */
import React from 'react';
import { FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types'

const SizeImageAssignment = ({ sizeGroup, printReadyArt, productImages, updateRawMaterial }) => {
  return (
    <div key={sizeGroup.id} style={{ width: '100%' }}>
      <Typography variant="subtitle1">
        <strong>Size</strong> {sizeGroup.size}
      </Typography>
      <FormGroup row>
        <FormControlLabel
          label="Manual Sku"
          control={
            <Switch
              checked={sizeGroup?.rawMaterials[0]?.manualSku || false}
              name="manualSku"
              onChange={(e) => updateRawMaterial(sizeGroup, 'manualSku', e.target.checked)}
            />
          }
        />
      </FormGroup>
      {sizeGroup?.rawMaterials[0]?.manualSku && (
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label={'Sku'}
          placeholder="Must be unique!"
          value={sizeGroup?.rawMaterials[0]?.ybaSku || ''}
          onChange={(e) => updateRawMaterial(sizeGroup, 'ybaSku', e.target.value)}
        />
      )}
      <Autocomplete
        multiple
        value={sizeGroup?.rawMaterials[0]?.productImages || []}
        onChange={(e, v) => updateRawMaterial(sizeGroup, 'productImages', v)}
        getOptionLabel={(o) => o?.file?.name}
        renderOption={(o) => {
          const url = URL.createObjectURL(o?.file);
          return (
            <div>
              <Grid>{o?.file?.name}</Grid>
              <Grid>
                <img height="200px" id={`${sizeGroup.id}-${o?.file?.name}`} src={url} />
              </Grid>
            </div>
          );
        }}
        options={productImages}
        renderInput={(params) => (
          <TextField {...params} fullWidth style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Product Images" />
        )}
      />
      <Autocomplete
        multiple
        value={sizeGroup?.rawMaterials[0]?.printReadyArt || []}
        onChange={(e, v) => updateRawMaterial(sizeGroup, 'printReadyArt', v)}
        getOptionLabel={(o) => o?.name ? o?.name : o?.file?.name}
        renderOption={(o) => {
          const url = URL.createObjectURL(o.file);
          return (
            <div>
              <Grid>{o?.name ? o.name : o?.file?.name}</Grid>
              {o?.file?.type !== 'application/pdf' && (
                <Grid>
                  <img height="200px" id={`${sizeGroup.id}-${o?.file?.name}`} src={url} />
                </Grid>
              )}
            </div>
          );
        }}
        options={printReadyArt}
        renderInput={(params) => (
          <TextField {...params} fullWidth style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Print Ready Art" />
        )}
      />
    </div>
  );
};

SizeImageAssignment.displayName = 'SizeImageAssignment';

SizeImageAssignment.propTypes = {
  sizeGroup: PropTypes.object,
  printReadyArt: PropTypes.array,
  productImages: PropTypes.array,
  updateRawMaterial: PropTypes.func,
};

export default SizeImageAssignment;
