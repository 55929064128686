/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, patch, remove } from '../../../../Functions/API';
import { setLoading } from '../../../../Stores/Loading/Actions';
import MaterialTable from 'material-table';
import { Add, Delete } from '@material-ui/icons'
import { Card, Grid, TextField, Typography, Select, InputLabel, MenuItem, FormControl, MobileStepper, IconButton } from '@material-ui/core';
import { createSnack } from '../../../../Stores/Snack/Actions';
import LoadingWrapper from '../../../../Components/Shared/LoadingWrapper';
import ImageCarousel from '../../../../Components/Shared/ImageCarousel';
import { createDialog } from '../../../../Stores/Dialog/Actions';
import ProductVariantsModal from '../../../../Components/Fulfillment/Components/ProductVariantsModal'
import ProductImagesModal from '../../../../Components/Fulfillment/Components/ProductImagesModal'
import PrintReadyArtModal from '../../../../Components/Fulfillment/Components/PrintReadyArtModal'
import ArtCard from '../../../../Components/Fulfillment/Components/ArtCard'

const inputStyle = {
  padding: '10px',
};

const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    loadProduct();
  }, []);

  const loadProduct = async () => {
    try {
      setLoading(true);
      const product = await get(`/fulfillment/products/${id}`);
      setProduct(product);
    } catch (err) {
      createSnack('Error', err?.message || 'Failed to load product');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (e) => {
    patch(`/fulfillment/products/${id}`, {
      statusId: e.target.value,
    }).then(loadProduct);
  };

  const handleProductImageOrientationChange = (pImage, orientation) => {
    patch(`/product-images/${pImage.id}`, {orientation}).then(loadProduct)
  }

  const removeYBASku = async (id) => {
    await remove(`/fulfillment/ybaSkus/${id}`)
    return loadProduct();
  }

  const removeArt = async (id) => {
    await remove(`/workOrderArt/${id}`)
    return loadProduct();
  }

  const openAddModal = () => {
    let currentStyle = {}
    if (product?.ybaSkus?.length) {
      currentStyle = product?.ybaSkus[0]?.rawMaterial?.style
    }

    createDialog({
      title: 'Add New Product Variants',
      content: <ProductVariantsModal loadProduct={loadProduct} productId={id} activeStyle={currentStyle} externalMaterial={product?.ybaSkus[0]?.rawMaterial?.material} productImages={product?.productImages} printReadyArt={product?.workOrderArt} />,
      size: 'xl',
    })
  }

  const openPrintReadyArtModal = () => {
    createDialog({
      title: 'Add New Print Ready Art',
      content: <PrintReadyArtModal ybaProductId={product?.id} loadProduct={loadProduct}/>,
      size: 'md'
    })
  }

  const removeProductImage = (productImageId) => {
    remove(`/product-images/${productImageId}`).then(loadProduct)
  }

  const openProductImagesModal = () => {
    createDialog({
      title: 'Add More Product Images',
      content: <ProductImagesModal ybaProductId={product?.id} loadProduct={loadProduct}/>,
      size: 'md'
    })
  }

  const updatePrintReadyArt = (id, key, value) => {
    setProduct({...product, workOrderArt: product.workOrderArt.map((w) => {
      if (w.id === id) {
        const copy = {...w};
        copy[key] = value
        return copy
      } else {
        return w
      }
      })})
  }

  const saveArt = (id) => {
    const workOrderArt = product.workOrderArt.find((art) => art.id === id)
    if (workOrderArt.printGroup) {
      workOrderArt.printGroupId = workOrderArt.printGroup.id
    }
    if (workOrderArt.printType) {
      workOrderArt.printTypeId = workOrderArt.printType.id
    }
    patch(`/workOrderArt/${id}`, workOrderArt).then(loadProduct)
  }

  return (
    <div>
      <LoadingWrapper>
        <div style={{ margin: '10px', paddingBottom: '60px' }}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8}>
              <Card>
                <div style={{ backgroundColor: '#3f51b5' }}>
                  <Grid container justify="space-between">
                    <Grid style={{ color: 'white', padding: '10px' }} item>
                      <Typography variant="h4">{product?.name}</Typography>
                    </Grid>
                    <Grid style={{ color: 'white', padding: '10px' }} item>
                      <FormControl style={{ color: 'white', width: '100px' }}>
                        <InputLabel style={{ color: 'white' }} id="status-select-label">
                          Status
                        </InputLabel>
                        <Select
                          style={{ color: 'white' }}
                          id="status-select"
                          labelId="status-select-label"
                          value={product?.statusId}
                          onChange={handleStatusChange}
                        >
                          <MenuItem key={1} value={1}>
                            Active
                          </MenuItem>
                          <MenuItem key={59} value={59}>
                            Draft
                          </MenuItem>
                          <MenuItem key={58} value={58}>
                            Archived
                          </MenuItem>
                          <MenuItem key={46} value={46}>
                            Deleted
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Grid container style={inputStyle}>
                  <TextField
                    fullWidth
                    disabled
                    InputLabelProps={{ shrink: true }}
                    label={'Description'}
                    multiline
                    rows={4}
                    value={product?.description}
                  />
                </Grid>
                <Grid container style={inputStyle}>
                  <TextField InputLabelProps={{ shrink: true }} fullWidth disabled value={product?.type?.name} label="Type" variant="standard" />
                </Grid>
                <Grid container style={inputStyle}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    disabled
                    value={product?.path?.name}
                    label="Product Path"
                    variant="standard"
                  />
                </Grid>
                <Grid container style={inputStyle} justify="space-between">
                  <Grid item>
                    <Typography variant="h5">
                      Retail Images (Shown to fulfillment customers)
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton style={{ margin: '8px' }} variant="contained" onClick={openProductImagesModal}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container style={inputStyle}>
                  {product?.productImages?.map((pImage) => {
                    return (
                      <Card style={{padding: '8px', margin: '8px'}} key={`${pImage.id}`}>
                        <Grid container style={{width: '100%'}} justify="flex-end">
                          <Grid item>
                            <IconButton size="small" color="secondary" onClick={() => removeProductImage(pImage.id)}>
                              <Delete fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid>
                          <FormControl fullWidth>
                            <InputLabel id="orientation-select-label">Orientation</InputLabel>
                            <Select
                              fullWidth
                              labelId="orientation-select-label"
                              id="orientation-select"
                              value={pImage?.orientation}
                              onChange={(e) => handleProductImageOrientationChange(pImage, e.target.value)}
                            >
                              <MenuItem value="Front">Front</MenuItem>
                              <MenuItem value="Back">Back</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid>
                          <img height="200px" id={`${pImage.id}`} src={pImage.url} />
                        </Grid>
                      </Card>
                    );
                  })}
                </Grid>
                <Grid container style={inputStyle} justify="space-between">
                  <Grid item>
                    <Typography variant="h5">
                      Print Ready Art (To be printed onto product)
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton style={{ margin: '8px' }} variant="contained" onClick={openPrintReadyArtModal}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container style={inputStyle}>
                  {product?.workOrderArt?.map((printReadyArt) => {
                    return (
                      <ArtCard
                        key={printReadyArt.id}
                        deleteArt={removeArt}
                        saveArt={saveArt}
                        printReadyArt={printReadyArt}
                        updatePrintReadyArt={updatePrintReadyArt}
                      />
                    );
                  })}
                </Grid>
                <Grid container style={inputStyle}>
                  <MaterialTable
                    title="Variants"
                    editable={{
                      onRowDelete: (r) => removeYBASku(r.id),
                    }}
                    components={{
                      Toolbar: (props) => (
                      <Grid container justify="space-between">
                        <Grid item style={{marginLeft: '8px', marginTop: '8px' }}>
                          <Typography variant="h4" >
                            Variants
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton style={{ margin: '8px' }} variant="contained" onClick={openAddModal}>
                            <Add />
                          </IconButton>
                        </Grid>
                      </Grid>
                      )
                    }}
                    style={{ width: '100%' }}
                    options={{ search: false }}
                    columns={[
                      {
                        title: 'Retail Images',
                        render: (r) => {
                          return <ImageCarousel images={r.productImages} />;
                        },
                      },
                      {
                        title: 'Art',
                        render: (r) => {
                          return <ImageCarousel images={r.workOrderArt} />;
                        },
                      },
                      {
                        title: 'Sku',
                        field: 'sku',
                      },
                      {
                        title: 'Color',
                        field: 'rawMaterial.color.color',
                      },
                      {
                        title: 'Size',
                        field: 'rawMaterial.size.size',
                      },
                      {
                        title: 'Style',
                        field: 'rawMaterial.style.style',
                      },
                      {
                        title: 'Material',
                        field: 'rawMaterial.material.name',
                      },
                      {
                        title: 'Brand',
                        field: 'rawMaterial.brand.brand',
                      },
                      {
                        title: 'Wholesale Price',
                        field: 'wholesalePrice',
                      },
                      {
                        title: 'Retail Price',
                        field: 'retailPrice',
                      },
                    ]}
                    data={product?.ybaSkus}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </LoadingWrapper>
    </div>
  );
};

ProductPage.displayName = 'ProductPage';

export default ProductPage;
