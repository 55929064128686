/* eslint-disable react/no-direct-mutation-state */
/* eslint react/no-array-index-key:0 */
/* eslint no-alert:0 */
/* eslint prefer-const:0 */
/* eslint no-param-reassign:0 */
/* eslint no-shadow:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
/* eslint max-len:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Shipments from '../../../Components/WorkOrder/Footer/Components/Production/Shipments';
import Count from '../../../Components/WorkOrder/Footer/Components/Production/Count';
import Print from '../../../Components/WorkOrder/Footer/Components/Production/Print';
import Packaging from '../../../Components/WorkOrder/Footer/Components/Production/Packaging';
import { get, post } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import UserStore from '../../../Stores/User/Store';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Description from '../../../Components/WorkOrder/Header/Components/Left/Description';
import Right from '../../../Components/WorkOrder/Header/Components/Right';
import { loadWorkOrder } from '../../../Stores/WorkOrder/Actions';
import NumberSummary from '../../../Components/WorkOrder/Footer/Components/Production/Numbers/NumberSummary';
import Modal from '@material-ui/core/Modal';
import Receiving from '../../../Components/WorkOrder/Footer/Components/Production/Receiving';
import InventoryStore from '../../../Stores/Inventory/Store';
import { loadInventory } from '../../../Stores/Inventory/Actions';
import VendorsStore from '../../../Stores/Vendors/Store';
import { fetchVendors } from '../../../Stores/Vendors/Actions';
import ShipMethod from '../../../Components/WorkOrder/Header/Components/Middle/ShipMethod';
import ContactStepper from '../../../Components/WorkOrder/Header/Components/Middle/Contacts/Stepper';
import { Route, Switch, useParams, useLocation, Link } from 'react-router-dom';
import { WorkOrderStore } from '../../../Stores';
import Customer from '../../../Components/WorkOrder/Header/Components/Customers';
import Sales from '../../../Components/WorkOrder/Header/Components/Left/Sales';
import SalesAssistants from '../../../Components/WorkOrder/Header/Components/Left/SalesAssistants';

const Production = () => {
  const { WorkOrderId } = useParams();
  const location = useLocation();
  const user = UserStore.getUser();
  const [numbersModal, setNumbersModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [inventoryWithSKUs, setInventoryWithSKUs] = useState([]);
  const [SKUs, setSKUs] = useState([]);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState(['Receiving', 'Count', 'Print', 'Packaging', 'Shipping']);
  const [path, setPath] = useState('');
  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    if (!WorkOrderStore.getWorkOrder().id) {
      loadWorkOrder(WorkOrderId);
    } else {
      getWorkOrder();
    }
    let index = location.pathname.toLowerCase().indexOf('production/workorder');
    let newPath = index !== -1 ? `/production/workorder/${WorkOrderId}` : `/workorder/${WorkOrderId}/production`;
    console.log(newPath);
    setPath(newPath);
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, [WorkOrderId]);

  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    InventoryStore.on('change', getInventory);
    InventoryStore.on('SKUChange', getSKUs);
    VendorsStore.on('change', getVendors);
    if (InventoryStore.getInventory().length === 0 || InventoryStore.getSKUs().length === 0) {
      loadInventory();
    } else {
      getInventory();
      getSKUs();
    }
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    } else {
      getVendors();
    }
    setStep(user.group === 'Production - Printer' ? 2 : user.group === 'Production - Back End' ? 3 : 1);
    return () => {
      InventoryStore.removeListener('change', getInventory);
      InventoryStore.removeListener('SKUChange', getSKUs);
      VendorsStore.removeListener('change', getVendors);
    };
  }, [workOrder.id]);

  const getInventory = () => {
    setInventoryWithSKUs([...InventoryStore.getInventory()]);
  };

  const getSKUs = () => {
    setSKUs([...InventoryStore.getSKUs()]);
  };

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  const handleStep = (e, step) => {
    setStep(step);
  };

  const autoFill = (type) => {
    const c = window.confirm(`This will set all ${type}s to the expected amounts`);
    if (c) {
      setStep(null);
      post('/updateSizes', { workOrderID: workOrder.id, type }).then(() => {
        setStep(type === 'count' ? 1 : 3);
      });
    }
  };

  const manuallyAddToInventory = () => {
    const c = window.confirm(
      'This will remove the current count numbers from their inventories and all the packaging numbers into the inventory selected as the customer in the work order and lock the count and package rows. Would you like to proceed?',
    );
    if (c) {
      if (workOrder.orderType === 'fulfillment') {
        post('/manuallyAddToInventoryFulfillment', { workOrderID: workOrder.id })
          .then((results) => {
            createSnack('Success', `Successfully transferred inventory to ${workOrder.customer}`);
            setStep(0);
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
      } else {
        post('/manuallyAddToInventory', { workOrderID: workOrder.id })
          .then((results) => {
            createSnack('Success', `Successfully transferred inventory to ${workOrder.customer}`);
            setStep(0);
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
      }
    }
  };

  const toggleNumbersModal = () => {
    setNumbersModal(!numbersModal);
  };

  return (
    <div>
      {location.pathname.includes('/production/workorder') && (
        <div style={{ margin: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Card style={{ minHeight: '550px' }}>
                <CardHeader title={workOrder.workOrderID} subheader={'Work Order ID'} />
                <CardContent>
                  <Customer workOrder={workOrder} editable={false} />
                  <Description workOrderID={workOrder.id} description={workOrder.description} editable={false} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <ShipMethod workOrder={workOrder} editable={false} />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: 'center', margin: '10px auto' }}>
                      <Sales workOrder={workOrder} editable={false} />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: 'center', margin: '10px auto' }}>
                      <SalesAssistants workOrder={workOrder} disabled={true} />
                    </Grid>
                  </Grid>
                  <ContactStepper
                    workOrder={workOrder}
                    contacts={workOrder?.contacts?.filter((contact) => contact.type === 'shipping')}
                    editable={false}
                    type="shipping"
                  />
                  {/* <Shipping editable={false} /> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Right workOrder={workOrder} />
            </Grid>
          </Grid>
        </div>
      )}
      {user.hasPermission('viewAutofills') && (
        <div style={{ margin: '12px' }}>
          <h2>Admin Controls</h2>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => autoFill('count')}>
                Autofill Count
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => autoFill('package')}>
                Autofill Packaging
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={manuallyAddToInventory}>
                Finish Everything
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <AppBar position="static" color="default">
        <Tabs value={location.pathname} variant="fullWidth" onChange={handleStep}>
          {steps.map((step, key2) => (
            <Tab label={step} key={key2} value={`${path}/${step.toLowerCase()}`} component={Link} to={`${path}/${step.toLowerCase()}`} />
          ))}
        </Tabs>
      </AppBar>
      {workOrder.id && (
        <Switch>
          <Route path={`${path}/receiving`}>
            <Receiving workOrder={workOrder} />
          </Route>
          <Route path={`${path}/count`}>
            <Count workOrder={workOrder} inventoryStyles={inventoryWithSKUs} numbersModal={toggleNumbersModal} SKUs={SKUs} vendors={vendors} />
          </Route>
          <Route path={`${path}/print`}>
            <Print workOrderID={workOrder.id} numbersModal={toggleNumbersModal} />
          </Route>
          <Route path={`${path}/packaging`}>
            <Packaging
              inventoryStyles={inventoryWithSKUs}
              shipMethod={workOrder.shipMethod}
              workOrder={workOrder}
              numbersModal={toggleNumbersModal}
              SKUs={SKUs}
              vendors={vendors}
            />
          </Route>
          <Route path={`${path}/shipping`}>
            <Shipments workOrder={workOrder} />
          </Route>
        </Switch>
      )}
      <Modal open={numbersModal} onClose={toggleNumbersModal} style={{ top: '10%' }}>
        <NumberSummary workOrder={workOrder} post={post} get={get} user={user} />
      </Modal>
    </div>
  );
};

export default Production;
