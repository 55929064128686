/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { get, post, patch } from '../../../../Functions/API';
import Graph2 from '../../../../Components/Fulfillment/Components/Graph2';
import MaterialTable from 'material-table';
import { createSnack } from '../../../../Stores/Snack/Actions';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { createDialog } from '../../../../Stores/Dialog/Actions';
import CostView from './costView';
import { deleteCostGroup } from '../../../../Functions/fulfillment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {},
  grid: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '30px',
  },
  subHeading: {
    fontWeight: '400',
    fontSize: '20px',
  },
}));

const Index = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [store, setStore] = useState({});
  const [update, refresh] = useState(0);
  const [costs, setCosts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [allIntegrations, setAllIntegrations] = useState([]);
  let selectedCosts = [];
  let selectedInvoices = [];

  useEffect(() => {
    getStore();
    getAllIntegrations();
    getStoreLocations();
  }, []);

  useEffect(() => {
    loadGroups();
    selectedCosts = [];
    selectedInvoices = [];
  }, [store, update]);

  const loadGroups = () => {
    getStoreCostGroups();
    getStoreInvoiceGroups();
  };

  const getStore = () => {
    get(`/stores/${id}`)
      .then((store) => {
        setStore(store);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const getStoreLocations = () => {
    get(`/stores/${id}/locations`).then(setLocations);
  };

  const getAllIntegrations = () => {
    get('/integrations/shopify/all')
      .then((integrations) => {
        setAllIntegrations(integrations);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateStore = (update) => {
    patch(`/stores/${id}`, update).then(getStore);
  };

  const getStoreCostGroups = () => {
    if (store.id) {
      const filter = {
        eager: {
          workOrder: {},
          $where: {
            storeId: store.id,
          },
        },
      };
      get('/fulfillment/costGroups', { filter })
        .then((res) => {
          console.log('CostRes:', res);
          selectedCosts = [];
          setCosts(res);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const getStoreInvoiceGroups = () => {
    if (store.id) {
      const filter = {
        eager: {
          workOrder: {},
          $where: {
            storeId: store.id,
          },
        },
      };
      get('/fulfillment/invoiceGroups', { filter })
        .then((res) => {
          console.log('InvoiceRes:', res);
          selectedInvoices = [];
          setInvoices(res);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const createWorkOrderWithGroups = () => {
    const body = {
      orderType: 'fulfillment',
      description: 'Financials Only',
      costGroups: selectedCosts.map((costGroup) => {
        return { id: costGroup.id };
      }),
      invoiceGroups: selectedInvoices.map((invoiceGroup) => {
        return { id: invoiceGroup.id };
      }),
    };
    post('/workorder', body)
      .then((res) => {
        console.log('res', res);
        refresh(update + 1);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  // add dates and the total cost of a group to the cost and invoice tables
  // look at the due date component to format the date

  const costGroupColumns = [
    { title: 'Name', field: 'name' },
    { title: 'Date', field: 'createdAt', type: 'date' },
    {
      title: 'Work Order',
      field: 'workOrderId',
      render: (rowData) => (
        <Button
          disabled={!rowData.workOrderId}
          color={'primary'}
          onClick={() => {
            window.open(`/workorder/${rowData.workOrderId}/cost`);
          }}
        >
          {rowData.workOrder?.workOrderID}
        </Button>
      ),
    },
    {
      title: 'View',
      render: (rowData) => (
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          onClick={() => {
            createDialog({
              content: <CostView costGroup={rowData} />,
              actions: [
                {
                  title: 'Delete Group',
                  color: 'secondary',
                  callback: () => {
                    const c = window.confirm(
                      'This will permanently delete all associated costs and rollback linked line items, are you sure you want to proceed??',
                    );
                    console.log(c);
                    if (c) {
                      deleteCostGroup(rowData.id)
                        .then((results) => {
                          loadGroups();
                          createSnack('Success', `Cost Group ${rowData.name} Deleted`);
                        })
                        .catch((err) => {
                          createSnack('Error', err.message);
                        });
                    } else {
                      createSnack('', 'CostGroup not deleted');
                    }
                  },
                },
              ],
              title: `Cost Group: ${rowData.name}`,
              size: 'xl',
            });
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const invoiceGroupColumns = [
    { title: 'Name', field: 'name' },
    { title: 'Date', field: 'createdAt', type: 'date' },
    {
      title: 'Work Order',
      field: 'workOrderId',
      render: (rowData) => (
        <Button
          disabled={!rowData.workOrderId}
          color={'primary'}
          onClick={() => {
            window.open(`/workorder/${rowData.workOrderId}/cost`);
          }}
        >
          {rowData.workOrder?.workOrderID}
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.grid}>
        {/* left side */}
        <Grid container xs={6} spacing={2} justify={'center'}>
          <Grid item xs={12}>
            <text className={classes.heading}>{store?.name}</text>
          </Grid>
          <Grid item sm={12}>
            <br />
            <Graph2 height={500} width={900} />
          </Grid>
          <Grid item sm={11}>
            <Autocomplete
              options={allIntegrations}
              getOptionLabel={(option) => option?.url}
              value={allIntegrations.find((i) => i.id === store.integrationId) || null}
              onChange={(e, v) => {
                if (v) {
                  updateStore({ integrationId: v.id });
                }
              }}
              renderInput={(params) => <TextField {...params} label="Integration" variant="outlined" size="small" />}
            />
          </Grid>
          <Grid item xs={11}>
            {locations && Boolean(locations.length) && (
              <Autocomplete
                options={locations}
                getOptionLabel={(option) => `${option?.name} (${option?.legacy ? 'Fulfillment Service' : 'Non Fulfillment'})`}
                value={locations.find((location) => location.id === store.locationId)}
                onChange={(e, v) => {
                  if (v) {
                    updateStore({ locationId: v?.id });
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Location" variant="outlined" size="small" />}
              />
            )}
          </Grid>
        </Grid>
        {/* right side */}
        <Grid item xs={6}>
          <Grid container direction={'column'}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color={'secondary'}
                onClick={() => {
                  console.log('Selected Costs', selectedCosts);
                  console.log('Selected Invoices', selectedInvoices);
                  createWorkOrderWithGroups();
                }}
              >
                Create WorkOrder
              </Button>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <h3>Cost Groups:</h3>
                <MaterialTable
                  columns={costGroupColumns}
                  data={costs}
                  options={{
                    rowStyle: {
                      fontSize: 'small',
                    },
                    toolbar: false,
                    paging: false,
                    selection: true,
                    selectionProps: (rowData) => ({
                      disabled: rowData.workOrderId,
                      color: 'primary',
                    }),
                  }}
                  onSelectionChange={(rows) => {
                    rows = rows.filter((r) => !r.workOrderId);
                    selectedCosts = rows;
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <h3>Invoice Groups:</h3>
                <MaterialTable
                  columns={invoiceGroupColumns}
                  data={invoices}
                  options={{
                    rowStyle: {
                      fontSize: 'small',
                    },
                    toolbar: false,
                    paging: false,
                    selection: true,
                    selectionProps: (rowData) => ({
                      disabled: rowData.workOrderId,
                      color: 'primary',
                    }),
                  }}
                  onSelectionChange={(rows) => {
                    rows = rows.filter((r) => !r.workOrderId);
                    selectedInvoices = rows;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

Index.displayName = 'IndividualStore';

export default Index;
