/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core';
import { newFile, updateFile } from '../../../../../Functions/Files';
import { storage } from '../../../../../fire';

class DragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drag: false,
    };
    this.dropRef = React.createRef();
  }

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    console.log(e.dataTransfer);
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: true });
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
  };

  uploadToGoogle = async (file) => {
    const bFile = await newFile(this.props.type, '', '', this.props.workOrder.id);
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref(`${this.props.workOrder.id}/${bFile.id}_${file.name}`);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        function error(err) {
          reject(err);
        },
        function complete() {
          task.snapshot.ref.getDownloadURL().then(async (results) => {
            const newFile = await updateFile(bFile.id, { url: results, fileName: `${this.props.workOrder.id}/${bFile.id}_${file.name}` });
            resolve(newFile);
          });
        }.bind(this),
      );
    });
  };

  handleDrop = async (f) => {
    this.setState({ drag: false });
    if (f) {
      const files = Array.from(f);
      const newFiles = await Promise.all(files.map((file) => this.uploadToGoogle(file)));
      console.log(newFiles);
      this.props.handleDrop(newFiles);
      f.dataTransfer.clearData();
    }
  };

  render() {
    return (
      <div ref={this.dropRef}>
        <div
          style={{
            backgroundColor: '#ebebeb',
            paddingRight: '10px',
            ...(!this.props.small && {
              border: this.state.drag ? '3px dashed black' : '3px solid #ebebeb',
            }),
            ...(!this.props.small && { paddingTop: '50px' }),
            borderRadius: '4px',
            ...(!this.props.small && { height: '100px' }),
          }}
        >
          <Button style={{ margin: '10px' }} variant="outlined" size={this.props.small ? 'small' : 'medium'} component="label" color="primary">
            Upload Files
            <input
              id={this.props.id}
              type="file"
              style={{ display: 'none' }}
              multiple={!this.props.edit}
              onChange={(event) => this.handleDrop(event.target.files)}
            />
          </Button>
          or drag your stuff here
        </div>
        {Boolean(this.props.progress) && <LinearProgress value={this.props.progress} variant="determinate" />}
      </div>
    );
  }
}

DragAndDrop.defaultProps = {
  edit: false,
};

export default DragAndDrop;
