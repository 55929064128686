/* eslint-disable */
import {
  Grid,
  Card,
  Typography,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { get, post } from '../../../../Functions/API';
import { storage } from '../../../../fire';
import { createSnack } from '../../../../Stores/Snack/Actions';
import VAutocomplete from '../../../../Components/Shared/VAutocomplete';
import FulfillmentDropZone from '../../../../Components/Shared/Fulfillment/FulfillmentDropZone';
import PrintReadyArtCard from '../../../../Components/Fulfillment/Products/Product/AddProduct/PrintReadyArtCard';
import SizeImageAssignment from '../../../../Components/Fulfillment/Products/Product/AddProduct/SizeImageAssignment'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const inputStyle = {
  padding: '10px',
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const NewProduct = () => {
  const query = useQuery();
  const history = useHistory();
  const [productPaths, setProductPaths] = useState([]);
  const [productPath, setProductPath] = useState(null);
  const [creating, setCreating] = useState(false);
  const [storeId] = useState(query.get('storeId'));
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [retailImages, setRetailImages] = useState([]);
  const [printReadyImages, setPrintReadyImages] = useState([]);
  const [printReadyArt, setPrintReadyArt] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState({});
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = useState(null);
  const [colors, setColors] = useState([]);
  const [chosenColors, setChosenColors] = useState([]);
  const [externalMaterial, setExternalMaterial] = useState(null)
  const [externalMaterials, setExternalMaterials] = useState([])
  const [rawMaterials, setRawMaterials] = useState([])
  const [extFulfillmentVendor, setExtFulfillmentVendor] = useState(null)

  useEffect(() => {
    if (style) {
      const filter = {
        eager: {
          rawMaterials: { $where: { 'styleId': style.id }, size: {}, externalSkus: { vendor: {} } },
          $where: { 'rawMaterials.styleId': style.id },
        },
      };
      if (externalMaterial) {
        filter.eager.$where['rawMaterials.materialId'] = externalMaterial.id;
        filter.eager.rawMaterials.$where.materialId = externalMaterial.id;
      }
      get('/fulfillment/externalColors', { filter: JSON.stringify(filter) }, 'obj').then(setColors);
    }
  }, [style, externalMaterial]);

  useEffect(() => {
    get('/fulfillment/externalMaterials').then(setExternalMaterials)
    get('/fulfillment/products/paths').then((results) => setProductPaths(results));
    get('/types').then(({ results }) => setTypes(results));
    get('/fulfillment/externalStyles').then(({ results }) => {
      results.map((r) => (r.selected = false));
      setStyles(results);
    });
  }, []);

  useEffect(() => {
    if (retailImages) {
      setProductImages(
        retailImages.map((rImage) => {
          const foundImage = productImages.find((pImage) => pImage.file.size === rImage.size && pImage.file.lastModified === rImage.lastModified);
          if (foundImage) {
            return foundImage;
          } else {
            return {
              uid: uid(),
              file: rImage,
            };
          }
        }),
      );
    }
  }, [retailImages]);

  const getSizeLabel = (option) => {
    const distinctVendors = option.rawMaterials?.map(rm => {
      return rm?.externalSkus?.map((eSku) => eSku.vendor.name)
    }).flat().filter(onlyUnique)
    return `${option.size} (${distinctVendors.join(',')})`;
  };

  const getColorLabel = (option) => {
    const distinctVendors = option?.rawMaterials?.map((rm) => {
      return rm?.externalSkus?.map((eSku) => eSku?.vendor?.name);
    }).flat()
      .filter(onlyUnique)


    return `${option?.color} (${distinctVendors.join(',')})`;
  };

  // sizes depend on colors
  const getSizesOptions = (chosenColor) => {
    const groupedSizeOptions = [];
    for (const rm of chosenColor.rawMaterials) {
      const foundSize = groupedSizeOptions.find((gs) => gs.id === rm.sizeId);
      if (foundSize) {
        foundSize.rawMaterials.push(rm);
      } else {
        // we group rawMaterials by size like this
        groupedSizeOptions.push({
          id: rm.size.id,
          size: rm.size.size,
          groupId: rm.size.groupId,
          sort: rm.size.sort,
          rawMaterials: [rm],
        });
      }
    }
    return groupedSizeOptions;
  };

  // to see what sizes we have chosen we need to filter by color from our rawMaterials array
  const getSizesValues = (chosenColor) => {
    const colorRawMaterials = rawMaterials.filter((rm) => rm?.colorId === chosenColor?.id);

    const groupedSizeOptions = [];
    for (const rm of colorRawMaterials) {
      const foundSize = groupedSizeOptions.find((gs) => gs.id === rm.size.id);
      if (foundSize) {
        foundSize.rawMaterials.push(rm);
      } else {
        groupedSizeOptions.push({
          id: rm.size.id,
          size: rm.size.size,
          groupId: rm.size.groupId,
          sort: rm.size.sort,
          rawMaterials: [rm],
        });
      }
    }
    return groupedSizeOptions;
  };

  const updatePrintReadyArt = (index, key, value) => {
    const copyPrintReadyArt = [...printReadyArt];
    const copyArt = { ...copyPrintReadyArt[index] };
    copyArt[key] = value;
    copyPrintReadyArt[index] = copyArt;
    setPrintReadyArt(copyPrintReadyArt);
  };

  const uploadPrintReadyArt = async (printReadyArt) => {
    return Promise.all(printReadyArt.map(async (prArt) => {
      const storageRef = storage.ref(`fulfillment/${new Date().getTime()}-${prArt.file.name}`);
      await storageRef.put(prArt.file);
      const url = await storageRef.getDownloadURL();
      const copyArt = { ...prArt };
      delete copyArt.uid;
      delete copyArt.file;
      const art = await post('/workOrderArt', {
        ...copyArt,
        url,
      });
      return { ...copyArt, ...art, uid: prArt.uid };
    }));
  }

  const uploadProductImages = async (productImages) => {
    return Promise.all(productImages.map(async (pImage) => {
      const storageRef = storage.ref(`fulfillment/${new Date().getTime()}-${pImage.file.name}`);
      await storageRef.put(pImage.file);
      const url = await storageRef.getDownloadURL();
      const image = await post('/product-images', {
        url,
        orientation: pImage.orientation,
      });
      return { ...image, uid: pImage.uid };
    }))
  }

  const createProduct = async () => {
    setCreating(true);
    try {
      const printReadyResults = await uploadPrintReadyArt(printReadyArt);
      const productResults = await uploadProductImages(productImages);

      // organize ybaSkus for creating product
      const ybaSkus = rawMaterials.filter(rm => {
        if (externalMaterial) {
          return rm.materialId === externalMaterial.id;
        } else {
          return true
        }
      }).map((rm) => ({
        manualSku: rm?.manualSku || null,
        rawMaterialId: rm.id,
        productImages: rm?.productImages?.map((pImage) => {
          // this array entry contains the url from the saved image
          const productResult = productResults.find((pResult) => pResult.uid === pImage.uid);
          return {
            id: productResult.id,
            orientation: pImage.orientation,
            url: productResult.url,
          };
        }),
        workOrderArt: rm?.printReadyArt?.map((pReadyArt) => {
          const printResult = printReadyResults.find((pResult) => pResult.uid === pReadyArt.uid);
          const workOrderArt = { ...pReadyArt, url: printResult?.url, id: printResult?.id };
          if (workOrderArt.file) delete workOrderArt.file;
          if (workOrderArt.uid) delete workOrderArt.uid;
          return workOrderArt;
        }),
      }));

      await post(`/fulfillment/products`, {
        name,
        ybaSkus,
        description,
        typeId: type?.id,
        storeId: storeId,
        productPathId: productPath?.id,
        extFulfillmentVendorId: extFulfillmentVendor?.id || null,
        productImages: productResults.map((p) => ({id: p.id, url: p.url})),
        workOrderArt: printReadyResults.map((p) => ({id: p.id, url: p.url})),
      });
      createSnack('Success', 'Product created successfully');
      history.push(`/fulfillment/products?storeId=${storeId}`);
    } catch (err) {
      createSnack('Error', err?.message || 'Error creating product');
    } finally {
      setCreating(false);
    }
  };

  const updateRawMaterial = (sizeGroup, key, value) => {
    const copyRMs = rawMaterials.map((es) => {
      const ces = { ...es };
      if (sizeGroup.rawMaterials.find((rm) => rm.id === es.id)) {
        ces[key] = value;
      }
      return ces;
    });
    setRawMaterials(copyRMs);
  };

  const deleteArt = (uid) => {
    setPrintReadyArt(printReadyArt.filter((pArt) => pArt.uid !== uid));
  };

  const uid = function () {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  const addPrintReadyArt = () => {
    setPrintReadyArt([{ uid: uid() }, ...printReadyArt]);
  };

  const updateProductImage = (productImage, orientation) => {
    const copyProduct = { ...productImage, orientation };
    const copyProducts = [...productImages];
    const productIndex = copyProducts.findIndex(
      (product) => productImage.file.size === product.file.size && productImage.file.lastModified === product.file.lastModified,
    );
    copyProducts[productIndex] = copyProduct;
    setProductImages(copyProducts);
  };

  // updates all raw materials of a given color
  const updateAllRawMaterials = (color, key, value) => {
    setRawMaterials([
      ...rawMaterials.filter((rm) => rm.colorId !== color.id),
      ...rawMaterials
        .filter((rm) => rm.colorId === color.id)
        .map((rm) => {
          const copyRM = { ...rm };
          copyRM[key] = value;
          return copyRM;
        }),
    ]);
  };

  return (
    <div>
      <div style={{ margin: '10px', paddingBottom: '60px' }}>
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <Card>
              <div style={{ backgroundColor: '#3f51b5' }}>
                <Grid container justify="center">
                  <Grid style={{ color: 'white', padding: '10px' }} item>
                    <Typography variant="h4">New Product</Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container style={inputStyle}>
                <TextField fullWidth InputLabelProps={{ shrink: true }} label={'Name'} value={name} onChange={(e) => setName(e.target.value)} />
              </Grid>
              <Grid container style={inputStyle}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={'Description'}
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid container style={inputStyle}>
                <Autocomplete
                  fullWidth
                  style={{ width: '100%' }}
                  value={type}
                  onChange={(e, v) => {
                    setType(v);
                  }}
                  options={types}
                  getOptionLabel={(o) => o.name}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Type" />
                  )}
                />
              </Grid>
              <Grid container style={inputStyle}>
                <Autocomplete
                  fullWidth
                  style={{ width: '100%' }}
                  value={productPath}
                  onChange={(e, v) => {
                    setProductPath(v);
                  }}
                  options={productPaths}
                  getOptionLabel={(o) => o.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label="Product Path"
                    />
                  )}
                />
              </Grid>
              <Grid container style={inputStyle}>
                <Autocomplete
                  fullWidth
                  style={{ width: '100%' }}
                  value={extFulfillmentVendor}
                  onChange={(e, v) => {
                    setExtFulfillmentVendor(v);
                  }}
                  options={[
                    { name: 'Delta Apparel', id: 154 },
                    { name: 'Teamwork', id: 474 },
                    { name: 'Vividly', id: 827 },
                  ]}
                  getOptionLabel={(o) => o.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ width: '100%' }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      label="External Fulfillment Vendor (Delta/Teamworks)"
                    />
                  )}
                />
              </Grid>
              <Grid container style={inputStyle}>
                <FulfillmentDropZone label="Retail Images (Shown to fulfillment customers)" handleDrop={setRetailImages} id="retail-art" />
              </Grid>
              <Grid container style={inputStyle}>
                {productImages.map((pImage) => {
                  const url = URL.createObjectURL(pImage.file);
                  return (
                    <Card key={`${pImage.file.lastModified}-${pImage.file.size}`}>
                      <Grid>{pImage.file.name}</Grid>
                      <Grid>
                        <FormControl fullWidth>
                          <InputLabel id="orientation-select-label">Orientation</InputLabel>
                          <Select
                            fullWidth
                            labelId="orientation-select-label"
                            id="orientation-select"
                            value={pImage?.orientation}
                            onChange={(e) => updateProductImage(pImage, e.target.value)}
                          >
                            <MenuItem value="Front">Front</MenuItem>
                            <MenuItem value="Back">Back</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid>
                        <img height="200px" id={`${pImage.file.lastModified}-${pImage.file.size}`} src={url} />
                      </Grid>
                    </Card>
                  );
                })}
              </Grid>
              <Grid container style={inputStyle}>
                <FulfillmentDropZone
                  acceptedFiles={['image/*', 'application/pdf', 'pdf']}
                  label="Print Ready Images (To be printed onto product)"
                  handleDrop={setPrintReadyImages}
                  id="print-ready-art"
                />
              </Grid>
              <Grid container style={inputStyle} justify="center">
                <Button fullWidth color="primary" variant="contained" onClick={addPrintReadyArt}>
                  Add
                </Button>
              </Grid>
              <Grid container style={inputStyle}>
                {printReadyArt?.map((printReadyArt, index) => {
                  return (
                    <PrintReadyArtCard
                      printReadyImages={printReadyImages}
                      key={printReadyArt.uid}
                      index={index}
                      deleteArt={deleteArt}
                      printReadyArt={printReadyArt}
                      updatePrintReadyArt={updatePrintReadyArt}
                    />
                  );
                })}
              </Grid>
              <div style={{ backgroundColor: '#757de8' }}>
                <Grid container justify="center">
                  <Grid style={{ color: 'white', padding: '10px' }} item>
                    <Typography variant="h5">Product Variants</Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container style={inputStyle}>
                <VAutocomplete value={style} att="style" label="Style" options={styles.filter((style) => style.style)} onChange={(e, v) => {
                  setStyle(v);
                }} />
              </Grid>
              {Boolean((style && colors?.length && colors[0]?.rawMaterials[0]?.externalSkus[0]?.vendorId === 474) || externalMaterial)  && (
                <Grid container style={inputStyle}>
                  <Autocomplete
                    fullWidth
                    style={{ width: '100%' }}
                    value={externalMaterial}
                    onChange={(e, v) => {
                      setExternalMaterial(v);
                    }}
                    options={externalMaterials}
                    getOptionLabel={(o) => o?.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        label="Material"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid container style={inputStyle}>
                  <>
                    {Boolean(colors.length) && (
                      <Autocomplete
                        fullWidth
                        style={{ width: '100%' }}
                        multiple
                        value={chosenColors}
                        onChange={(e, v) => {
                          setChosenColors(v);
                        }}
                        options={colors}
                        getOptionLabel={getColorLabel}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            label="Colors"
                          />
                        )}
                      />
                    )}
                  </>
              </Grid>
              {chosenColors.map((chosenColor) => {
                return (
                  <Grid container key={chosenColor.id} style={inputStyle}>
                    <Autocomplete
                      fullWidth
                      style={{ width: '100%' }}
                      multiple
                      value={getSizesValues(chosenColor)}
                      onChange={(e, v, reason) => {
                        const newRawMaterials = v.map((s) => s.rawMaterials).flat();
                        const copyRawMaterials = rawMaterials.filter((rm) => rm?.colorId !== chosenColor?.id);
                        setRawMaterials([...copyRawMaterials, ...newRawMaterials].filter(onlyUnique));
                      }}
                      options={getSizesOptions(chosenColor)}
                      getOptionLabel={getSizeLabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          style={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                          label={`${chosenColor.color} Sizes`}
                        />
                      )}
                    />
                  </Grid>
                );
              })}
              <div style={{ backgroundColor: '#ff5982' }}>
                <Grid container justify="center">
                  <Grid style={{ color: 'white', padding: '10px' }} item>
                    <Typography variant="h5">Assign Images</Typography>
                  </Grid>
                </Grid>
              </div>
              {chosenColors.map((chosenColor) => {
                return (
                  <Grid key={chosenColor.id} container style={inputStyle}>
                    <Card style={{ width: '100%', padding: '10px' }}>
                      <Typography variant="h6">{chosenColor.color}</Typography>
                      <Autocomplete
                        multiple
                        onChange={(e, v) => updateAllRawMaterials(chosenColor, 'productImages', v)}
                        getOptionLabel={(o) => o?.file?.name}
                        renderOption={(o) => {
                          const url = URL.createObjectURL(o?.file);
                          return (
                            <div>
                              <Grid>{o?.file?.name}</Grid>
                              <Grid>
                                <img height="200px" id={`${chosenColor.color}-${o?.uid}`} src={url} />
                              </Grid>
                            </div>
                          );
                        }}
                        options={productImages}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            label="Product Images (Assign to all sizes)"
                          />
                        )}
                      />
                      <Autocomplete
                        multiple
                        onChange={(e, v) => updateAllRawMaterials(chosenColor, 'printReadyArt', v)}
                        getOptionLabel={(o) => (o?.name ? o.name : o?.file?.name)}
                        renderOption={(o) => {
                          const url = URL.createObjectURL(o?.file);
                          return (
                            <div>
                              <Grid>{o?.name ? o.name : o?.file?.name}</Grid>
                              {o?.file?.type !== 'application/pdf' && (
                                <Grid>
                                  <img height="200px" id={`${chosenColor.color}-${o?.uid}`} src={url} />
                                </Grid>
                              )}
                            </div>
                          );
                        }}
                        options={printReadyArt}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            label="Print Ready Art (Assign to all sizes)"
                          />
                        )}
                      />
                      {Boolean(rawMaterials.filter((rm) => rm.colorId === chosenColor.id).length) &&
                        rawMaterials
                          .filter((rm) => rm.colorId === chosenColor.id)
                          .reduce((newArr, rm) => {
                            const arrCopy = newArr ? [...newArr] : [];
                            const foundSize = arrCopy.find((gs) => gs.id === rm.size.id);
                            if (foundSize) {
                              foundSize.rawMaterials.push(rm);
                            } else {
                              arrCopy.push({
                                id: rm.size.id,
                                size: rm.size.size,
                                groupId: rm.size.groupId,
                                sort: rm.size.sort,
                                rawMaterials: [rm],
                              });
                            }
                            return arrCopy;
                          }, [])
                          .map((sizeGroup) => {
                            return (
                             <SizeImageAssignment sizeGroup={sizeGroup} productImages={productImages} printReadyArt={printReadyArt} updateRawMaterial={updateRawMaterial} />
                            );
                          })}
                    </Card>
                  </Grid>
                );
              })}
              <Grid container style={inputStyle} justify="center">
                <Grid item>
                  {creating ? (
                    <CircularProgress />
                  ) : (
                    <Button color="primary" variant="contained" onClick={createProduct}>
                      Create
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NewProduct;
