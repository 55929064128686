import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Card, CardContent, CardHeader } from '@material-ui/core';
import Menu from '../../../Components/Menu';
import Notifications from '../../../Components/WorkOrder/Header/Components/Right/Notifications';
import Art from '../../../Components/Art';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { get } from '../../../Functions/API';
import { useParams } from 'react-router-dom';
import { loadWorkOrder } from '../../../Stores/WorkOrder/Actions';
import Store from '../../../Stores/WorkOrder/Store';
import CustomerStore from '../../../Stores/Customer/Store';
import DueDate from '../../../Components/WorkOrder/Header/Components/Left/DueDate';
import UsersStore from '../../../Stores/Users/Store';
import { fetchUsers } from '../../../Stores/Users/Actions';
import Sales from '../../../Components/WorkOrder/Header/Components/Left/Sales';
import OrderType from '../../../Components/WorkOrder/Header/Components/Left/OrderType';

const ArtWorkOrder = ({ user }) => {
  const { WorkOrderId } = useParams();
  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    Store.on('change', getWorkOrder);
    loadWorkOrder(WorkOrderId);
    fetchUsers();
    return () => {
      Store.removeListener('change', getWorkOrder);
    };
  }, [WorkOrderId]);

  const getWorkOrder = () => {
    setWorkOrder({ ...Store.getWorkOrder() });
  };

  return (
    <div style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
      <Grid container style={{ padding: '10px', height: '550px', maxHeight: '550px', marginBottom: '15px' }} spacing={1}>
        <Grid item xs={6}>
          <Card style={{ height: '100%' }}>
            <CardHeader title={workOrder.workOrderID} subheader={'Work Order ID'} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant={'outlined'}
                    label={'Customer'}
                    value={workOrder.customerName}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DueDate workOrder={workOrder} editable={false} />
                </Grid>
                <Grid item xs={12}>
                  <OrderType workOrder={workOrder} editable={false} />
                </Grid>
                <Grid item xs={12}>
                  <Sales workOrder={workOrder} editable={false} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant={'outlined'}
                    label={'Description'}
                    multiline
                    value={workOrder.description}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title={'Notifications'} />
            <CardContent>
              <Notifications
                {...{
                  user,
                  workOrder,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Art {...{ workOrder }} />
    </div>
  );
};

export default ArtWorkOrder;

ArtWorkOrder.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  get: PropTypes.func,
  post: PropTypes.func,
};
