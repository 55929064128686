import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.snack = { open: false, message: '', variant: '' };
  }

  getSnack = () => {
    return this.snack;
  };

  newSnack = (snack) => {
    this.snack = snack;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'NEW_SNACK': {
        this.newSnack(action.snack);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
