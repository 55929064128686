/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import blocked from '../../images/blocked_meme.jpeg';
import { get, post } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserStore } from '../../Stores';
import { loadUser } from '../../Stores/User/Actions';
import queryString from 'query-string';

function useQuery() {
  return queryString.parse(useLocation().search);
}

function Inactive({ logout }) {
  const queryParams = useQuery();
  const history = useHistory();
  const [requested, setRequested] = useState(0);

  useEffect(() => {
    UserStore.on('change', forward);
    return () => {
      UserStore.removeListener('change', forward);
    };
  }, []);

  const forward = () => {
    if (queryParams?.next && queryParams?.next !== '/') {
      history.push(queryParams.next);
    } else {
      history.push('/');
    }
  };

  const requestActivation = () => {
    const subject = 'I need to be activated please!';
    post('/bugs', { subject }).then(() => {
      setRequested(1);
      createSnack('Success', "You've requested activation");
    });
  };

  const checkActivation = () => {
    const { uid } = UserStore.getUser();
    loadUser(uid);
  };

  return (
    <>
      <Grid container spacing={1} direction={'column'} alignItems="center" justify="center">
        <Grid item>
          <img src={blocked} alt="Blocked" />
        </Grid>
        <Grid item>
          <Typography variant="h3">You need some access to MSAS</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button color="primary" variant="contained" onClick={requestActivation} disabled={requested > 0}>
                Request Activation
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" onClick={checkActivation}>
                Check Activation
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={logout} color="secondary" variant="contained">
            Log Out
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

Inactive.propTypes = { logout: PropTypes.func };

export default Inactive;
