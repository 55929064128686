import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from '@material-ui/core';
import { get } from '../../../../../Functions/API';
import { Link } from 'react-router-dom';
import VendorSelectPopup from './vendorSelectPopup';
import { createDialog, closeDialog } from '../../../../../Stores/Dialog/Actions';

const Status = ({ status, statusFilter }) => {
  const [stores, setStores] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(0);

  const date = new Date();
  date.setDate(date.getDate() + 14);

  useEffect(() => {
    getStepIds().then((stepIds) => {
      let filter = {
        eager: {
          lineItems: {
            $where: {
              productStepId: {
                $in: stepIds,
              },
            },
          },
          $aggregations: [
            {
              type: 'sum',
              alias: 'numberOpenLineItems',
              field: 'quantity',
              relation: 'lineItems',
              $where: {
                productStepId: {
                  $in: stepIds,
                },
              },
            },
          ],
          $where: {
            numberOpenLineItems: {
              $gt: 0,
            },
          },
        },
        order: 'numberOpenLineItems desc',
      };
      if (status.type === 'orders') {
        filter = {
          eager: {
            $aggregations: [
              {
                type: 'count',
                alias: 'numberOpenLineItems',
                relation: 'fulfillmentOrders',
                $where: {
                  statusId: {
                    $in: status.statuses,
                  },
                },
              },
            ],
            $where: {
              numberOpenLineItems: {
                $gt: 0,
              },
            },
          },
          order: 'numberOpenLineItems desc',
        };
      }

      get('/stores', { filter, paging: true, page }).then(({ results, total }) => {
        setStores(results);
        setTotal(total);
      });
    });
  }, [page]);

  const getStepIds = async () => {
    const steps = await get('/fulfillment/products/steps', { statusId: status.id });
    return steps.map((step) => step.id);
  };

  // {
  //   id: 54,
  //     name: 'Order',
  //   type: 'lineItems',
  //   storeDest: 'lineItems',
  //   viewAllDest: 'styles/vendorStoreSelect',
  // },

  return (
    <Card style={{ position: 'relative', height: '455px' }}>
      <CardHeader title={status.name} subheader={`Pending: ${stores.reduce((total, store) => total + store.numberOpenLineItems, 0)}`} />
      {status.name === 'Order' ? (
        <CardContent>
          {stores.map((store, key) => (
            <div key={key}>
              <List dense>
                <ListItem
                  button
                  // component={Link}
                  style={{ color: 'black' }}
                  onClick={() => {
                    createDialog({
                      title: `Select Vendor`,
                      content: <VendorSelectPopup status={status} store={store} closeDialog={closeDialog} />,
                      disableBackdropClick: true,
                      actions: [
                        {
                          title: 'Cancel',
                          color: 'secondary',
                          callback: closeDialog,
                        },
                      ],
                    });
                  }}
                >
                  <Badge
                    color={'primary'}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={store.numberOpenLineItems}
                    showZero
                  >
                    <ListItemText style={{ paddingLeft: '15px' }} primary={store.name} />
                  </Badge>
                </ListItem>
              </List>
              <Divider />
            </div>
          ))}
        </CardContent>
      ) : (
        <CardContent>
          {stores.map((store, key) => (
            <div key={key}>
              <List dense>
                <ListItem
                  button
                  component={Link}
                  style={{ color: 'black' }}
                  // `/fulfillment/styles/order?companyId=${company.id}&storeId=${store.id}`
                  // this needs to be changed
                  to={`/fulfillment/${status.storeDest}${status.storeDest?.includes('?') ? '&' : '?'}${`companyId=154`}&storeId=${store.id}`}
                >
                  <Badge
                    color={'primary'}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={store.numberOpenLineItems}
                    showZero
                  >
                    <ListItemText style={{ paddingLeft: '15px' }} primary={store.name} />
                  </Badge>
                </ListItem>
              </List>
              <Divider />
            </div>
          ))}
        </CardContent>
      )}
      <CardActions style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <TablePagination
          style={{ width: '100%' }}
          count={total}
          page={page}
          rowsPerPage={5}
          rowsPerPageOptions={false}
          onChangePage={(e, p) => setPage(p)}
        />
        {/*<Grid container alignItems={'flex-end'}>*/}
        {/*  <Grid items style={{ flexGrow: 1 }} spacing={1}>*/}
        {/*    <Button variant={'contained'} size={'small'} component={Link} to={`/fulfillment/${status.viewAllDest}`}>*/}
        {/*      View All*/}
        {/*    </Button>*/}
        {/*  </Grid>*/}
        {/*  <Grid>*/}
        {/*    <TablePagination count={total} page={page} rowsPerPage={5} rowsPerPageOptions={false} onChangePage={(e, p) => setPage(p)} />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </CardActions>
    </Card>
  );
};

export default Status;

Status.propTypes = { status: PropTypes.object, statusFilter: PropTypes.number };
