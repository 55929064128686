import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import PropTypes, { number } from 'prop-types';

const OrderSizes = (props) => {
  const [editAllBill, setEditAllBill] = useState('');
  const [editAllCost, setEditAllCost] = useState('');

  useEffect(() => {
    setEditAllBill('');
    setEditAllCost('');
  }, [props.page, props.filters]);

  const flexContainer = {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.025)',
    justifyContent: 'center',
  };
  const flexItem = {
    width: '110px',
    margin: '10px',
    textAlign: 'center',
  };
  const largerTextSize =
    props.width > 1200
      ? { color: 'rgb(67, 160, 71)', fontSize: 'x-large', transformOriginY: '-20px' }
      : { color: 'rgb(67, 160, 71)', fontSize: 'medium', transformOriginY: '-20px' };
  return (
    <div style={flexContainer}>
      <div style={flexItem}>
        <div style={{ padding: '9px' }}>
          <h3>Cost:</h3>
        </div>
        <div style={{ padding: '9px' }}>
          <h3>Bill:</h3>
        </div>
      </div>
      <div style={flexItem}>
        <TextField
          label="Edit All"
          variant="outlined"
          type="number"
          value={editAllCost}
          onChange={(event) => {
            props.changeAll(event, props.num, 'cost');
            setEditAllCost(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          margin="normal"
          autoComplete="off"
          component="div"
        />
        <TextField
          label="Edit All"
          variant="outlined"
          type="number"
          value={editAllBill}
          onChange={(event) => {
            props.changeAll(event, props.num, 'bill');
            setEditAllBill(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          margin="normal"
          autoComplete="off"
          component="div"
        />
      </div>
      {props.order.sizes.map((size, key) => (
        <div key={key} style={flexItem}>
          <TextField
            label={`${size?.quantity} ${size?.size?.toUpperCase()}`}
            variant="outlined"
            value={size.cost}
            onChange={(event) => props.updatePrice(event, size, props.num, 'cost')}
            type="number"
            InputLabelProps={{
              shrink: true,
              style: largerTextSize,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            margin="normal"
            autoComplete="off"
            component="div"
          />
          <TextField
            id="standard-number"
            variant="outlined"
            value={size.bill}
            onChange={(event) => props.updatePrice(event, size, props.num, 'bill')}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            margin="normal"
            autoComplete="off"
            component="div"
          />
        </div>
      ))}
      <div style={flexItem}>
        <TextField
          id="standard-number"
          label={`${props.order.sizes.reduce((total, size) => total + size.quantity, 0)} Total`}
          value={props.order.sizes.reduce((total, size) => total + size.cost * size.quantity, 0).toFixed(2)}
          InputLabelProps={{
            shrink: true,
            style: largerTextSize,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
          margin="normal"
          autoComplete="off"
          component="div"
        />
        <TextField
          id="standard-number"
          value={props.order.sizes.reduce((total, size) => total + size.bill * size.quantity, 0).toFixed(2)}
          InputLabelProps={{
            shrink: true,
            style: largerTextSize,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
          margin="normal"
          autoComplete="off"
          component="div"
        />
      </div>
    </div>
  );
};

export default OrderSizes;

OrderSizes.propTypes = {
  order: PropTypes.object.isRequired,
  updatePrice: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  changeAll: PropTypes.func.isRequired,
  num: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  filters: PropTypes.object,
};
