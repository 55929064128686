/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import OrderSizes from './OrderSizes';
import PropTypes from 'prop-types';
import Inventory from '../Production/LineHeaders/inventory';
import Normal from '../Production/LineHeaders/normal';

const OrderLine = ({ toggleSizeDetailModal, updatePrice, changeAll, misprint, groupBy, width, load, num, workOrder, page, filters }) => {
  const lineStyle = {
    margin: 'auto',
    marginBottom: '10px',
  };
  const flexContainer = {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.025)',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const flexItem = {
    margin: '10px',
    textAlign: 'center',
  };

  return (
    <div style={lineStyle}>
      <div style={flexContainer}>
        {workOrder.orderType === 'inventory' ? (
          <Inventory groupBy={groupBy} line={load} workOrder={workOrder} />
        ) : (
          <Normal groupBy={groupBy} line={load} workOrder={workOrder} />
        )}
        {Boolean(misprint) && (
          <div style={flexItem}>
            <FormControlLabel
              control={<Switch value={Boolean(load.misprint)} checked={Boolean(load.misprint)} />}
              labelPlacement="top"
              label="Replacement Item"
            />
          </div>
        )}
        <div style={flexItem}>
          <Button variant="contained" onClick={() => toggleSizeDetailModal(load)}>
            Missing Info?
          </Button>
        </div>
      </div>
      {load.sizes && Array.isArray(load.sizes) && (
        <OrderSizes filters={filters} page={page} num={num} order={load} changeAll={changeAll} updatePrice={updatePrice} width={width} />
      )}
    </div>
  );
};

export default OrderLine;

OrderLine.propTypes = {
  toggleSizeDetailModal: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
  changeAll: PropTypes.func.isRequired,
  misprint: PropTypes.bool.isRequired,
  groupBy: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  load: PropTypes.object.isRequired,
  num: PropTypes.number.isRequired,
  vendors: PropTypes.array.isRequired,
  filters: PropTypes.object,
};
