import React, { useState, useRef, useMemo, useEffect } from 'react';
import { TextField, InputAdornment, Input, FormControl, InputLabel, IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const placesKey = `AIzaSyB4uRjbyaKQyUbJFR7tTKfViqvPMRwaDfo`;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function PlacesSearch({ loadSearchAddress }) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [details, setDetails] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${placesKey}&libraries=places`, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const clearInput = () => {
    setValue(null);
    setDetails(null);
    setInputValue('');
    loadSearchAddress({
      address1: '',
      address2: '',
      city: '',
      zip: '',
      state: '',
      country: '',
    });
  };

  const getDetails = (val) => {
    if (val && val?.place_id) {
      placesService.current.getDetails({ placeId: val?.place_id, fields: ['address_components', 'adr_address'] }, (result) => {
        setDetails(result);
        parseAndSendResults(result?.address_components);
      });
    }
  };

  const parseAndSendResults = (addressDetails) => {
    const ShouldBeComponent = {
      home: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: ['locality', 'sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
      country: ['country'],
    };

    const address = {
      home: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: '',
    };

    addressDetails.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    const formattedAddress = {
      address1: address.home ? `${address.home} ${address.street}` : address.street,
      address2: '',
      city: address.city,
      zip: address.postal_code,
      state: address.region,
      country: address.country,
    };

    loadSearchAddress(formattedAddress);
  };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['address'], componentRestrictions: { country: ['us', 'ca', 'au'] } }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      variant="standard"
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        getDetails(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <FormControl fullWidth>
            <InputLabel htmlFor="place-search">Address Search</InputLabel>
            <Input
              id="place-search"
              inputProps={{ ...params.inputProps, autoComplete: 'new-password', type: 'text' }}
              style={{ color: Boolean(details) ? '#B5B5B5' : 'inherit' }}
              readOnly={Boolean(details)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={clearInput}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        // <TextField {...params} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} label="Address Search" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

PlacesSearch.propTypes = {
  loadSearchAddress: PropTypes.func,
};

export default PlacesSearch;
