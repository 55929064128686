/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint react/no-unescaped-entities:0 */
/* eslint react/button-has-type:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-alert:0 */
/* eslint no-return-await:0 */
/* eslint consistent-return:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-vars:0 */
/* eslint no-console:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Cancel';
import Done from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import Cached from '@material-ui/icons/Cached';
import ThumbsDown from '@material-ui/icons/ThumbDown';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const Sizes = ({ num, order, orderType, addSize, setSizeModal, removeSize, updateQuantity, get, post }) => {
  const ErrorRow = ({ error }) => {
    const color = error.cspResolutionId === 1 ? 'LightCoral' : 'LightBlue';
    const resolutions = ['None', 'Found', 'Fixed', 'Extra'];
    const errors = ['None', 'Missing', 'Misprint', 'Wrong Number', 'Extra'];
    const type = error.type === 'count' ? 'Count:' : 'Package:';
    return (
      <tr style={{ color }}>
        <td style={{ color: 'white' }}>{type}</td>
        <td>{errors[error.cspErrorId - 1]}</td>
        <td>{error.quantity}</td>
        <td>{resolutions[error.cspResolutionId - 1]}</td>
      </tr>
    );
  };

  const FindErrors = (size) => {
    for (const sizeWithError of order.sizes) {
      if (sizeWithError && sizeWithError.errors.length && size.id === sizeWithError.id) {
        return sizeWithError.errors;
      }
    }
    return null;
  };

  const Errors = ({ size }) => {
    const errors = FindErrors(size);
    return errors && errors.length > 0 ? (
      <div>
        <table style={{ fontSize: '15px', textAlign: 'center', padding: '5px' }}>
          <tr style={{ color: 'Linen' }}>
            <th>
              <u>Type</u>
            </th>
            <th>
              <u>Error</u>
            </th>
            <th>
              <u>Quantity</u>
            </th>
            <th>
              <u>Fix</u>
            </th>
          </tr>
          {errors.map((error) => (
            <ErrorRow error={error} />
          ))}
        </table>
      </div>
    ) : (
      <div>No Errors</div>
    );
  };

  return (
    <Grid container spacing={1} justify="center">
      {order.sizes.map((size, key) => {
        let iconButton;
        if (size.countNum === 0 && size.packageNum === 0 && size.errors.length === 0) {
          iconButton = (
            <IconButton href="" tabIndex={-1} onClick={(event) => removeSize(size)} style={{ position: 'absolute', top: 2, right: -13 }} size="small">
              <Close />
            </IconButton>
          );
        } else if (size.countNum > 0 && size.countNum !== size.quantity && size.packageNum === 0) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#ffbb32',
                }}
                size="small"
              >
                <Cached />
              </IconButton>
            </Tooltip>
          );
        } else if (size.countNum === size.quantity && size.packageNum === 0) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#ffbb32',
                }}
                size="small"
              >
                <Done />
              </IconButton>
            </Tooltip>
          );
        } else if (size.countNum === size.quantity && size.packageNum > 0 && size.packageNum !== size.quantity) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#149000',
                }}
                size="small"
              >
                <Cached />
              </IconButton>
            </Tooltip>
          );
        } else if (size.countNum > 0 && size.countNum !== size.quantity && size.packageNum > 0 && size.packageNum !== size.quantity) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#c70110',
                }}
                size="small"
              >
                <ThumbsDown />
              </IconButton>
            </Tooltip>
          );
        } else if (size.countNum === 0 && size.packageNum > 0) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#c70110',
                }}
                size="small"
              >
                <ThumbsDown />
              </IconButton>
            </Tooltip>
          );
        } else if (size.countNum === size.quantity && size.packageNum === size.quantity) {
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color: '#149000',
                }}
                size="small"
              >
                <DoneAll />
              </IconButton>
            </Tooltip>
          );
        } else if (FindErrors(size)) {
          const color = size.errors.some((err) => err.cspResolutionId === 1) ? 'red' : 'LightBlue';
          iconButton = (
            <Tooltip
              key={key}
              style={{ width: '100px' }}
              title={
                <div style={{ padding: '5px' }}>
                  <div style={{ fontSize: 'medium' }}>
                    Count: {size.countNum} Packaging: {size.packageNum}
                  </div>
                  <Errors size={size} />
                </div>
              }
            >
              <IconButton
                href=""
                tabIndex={-1}
                style={{
                  position: 'absolute',
                  top: 2,
                  right: -13,
                  color,
                }}
                size="small"
              >
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          );
        } else {
          iconButton = null;
        }
        return (
          <Grid item xs={3} sm={2} md={1} style={{ position: 'relative', margin: '5px', maxWidth: '100px' }} key={key} id={key}>
            {iconButton}
            <TextField
              label={size.size.toUpperCase()}
              variant="outlined"
              value={size.quantity}
              onChange={(event) => updateQuantity(event, size, num)}
              helperText={(size.availableQuantities || size.availableQuantities === 0) && `${size.availableQuantities}`}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
              margin="normal"
              autoComplete="off"
              component="div"
            />
          </Grid>
        );
      })}
      <IconButton style={{ width: '48px', height: '48px', margin: '5px', transform: 'translateY(-15px)' }} onClick={() => setSizeModal(true)} href="">
        <AddIcon color="secondary" />
      </IconButton>
      <Grid item xs={3} sm={2} md={1} style={{ margin: '5px', maxWidth: '100px' }}>
        <TextField
          label="Total"
          variant="outlined"
          value={order.sizes.reduce((total, size) => {
            let tempQuantity = 0;
            // eslint-disable-next-line radix
            if (parseInt(size.quantity)) {
              tempQuantity = size.quantity;
            }
            return total + tempQuantity;
          }, 0)}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '100%' }}
          margin="normal"
          autoComplete="off"
          component="div"
        />
      </Grid>
    </Grid>
  );
};

export default Sizes;
