import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { get, post } from '../../../../../../Functions/API';
import { createSnack } from '../../../../../../Stores/Snack/Actions';
import { Button, DialogActions, FormControl, FormControlLabel, Switch } from '@material-ui/core';

const MiniSkuUpdates = ({ order, sku, addRemove, user, updateFinished, actions }) => {
  const [columns, setColumns] = useState([]);
  const [packagingFinished, setPackagingFinished] = useState(order.packagingFinished);
  const [countFinished, setCountFinished] = useState(order.countFinished);
  const [packagingTransferPending, setPackagingTransferPending] = useState(order.packagingTransferPending);
  const [countTransferPending, setCountTransferPending] = useState(order.countTransferPending);
  const [data, setData] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const sizes = await get('/allPotentialSizes');
    const c = [{ title: 'ID', field: 'id' }, ...sizes.map((s) => ({ title: s.size, field: s.size }))];
    const data = order[addRemove]?.map(({ id, skuId, history }) => {
      const data = { id, skuId };
      history.map(({ size, quantity }) => {
        data[size] = quantity;
      });
      return data;
    });
    setData(data);
    setColumns(c);
  };

  const deleteRow = (row) => {
    if (!user.hasPermission('deleteSkuHistory')) {
      createSnack('Error', "You don't have permission to delete sku history");
      return;
    }
    return post('/deleteSKUHistory', row)
      .then((results) => {
        createSnack('Success', 'Please Refresh to see the changes');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <>
      {addRemove === 'addInventoryUpdates' && (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                onClick={() => {
                  updateFinished('packaging', order).then(() => {
                    setPackagingFinished(!packagingFinished);
                  });
                }}
                checked={packagingFinished}
                disabled={!user.hasPermission('deleteSkuHistory') || packagingTransferPending}
                value={packagingFinished}
              />
            }
            label={`Add to Inventory Locked ${packagingTransferPending ? '(Add Pending)' : ''}`}
          />
        </FormControl>
      )}
      {addRemove === 'removeInventoryUpdates' && (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                onClick={() => {
                  updateFinished('count', order).then(() => {
                    setCountFinished(!countFinished);
                  });
                }}
                checked={countFinished}
                disabled={!user.hasPermission('deleteSkuHistory') || countTransferPending}
                value={countFinished}
              />
            }
            label={`Remove from Inventory Locked ${countTransferPending ? '(Removal Pending)' : ''}`}
          />
        </FormControl>
      )}
      <MaterialTable
        columns={columns}
        data={data}
        editable={{ onRowDelete: async (data) => deleteRow(data) }}
        options={{ toolbar: false, paging: false }}
      />
      <DialogActions>
        {actions.map((action) => (
          <Button
            key={action.title}
            autoFocus
            onClick={action.callback}
            color={action.color}
            disabled={
              !user.hasPermission('deleteSkuHistory') ||
              (addRemove === 'removeInventoryUpdates' ? countFinished || countTransferPending : packagingFinished || packagingTransferPending)
            }
          >
            {action.title}
          </Button>
        ))}
      </DialogActions>
    </>
  );
};

export default MiniSkuUpdates;

MiniSkuUpdates.propTypes = {
  order: PropTypes.object,
  sku: PropTypes.object,
  addRemove: PropTypes.string,
  user: PropTypes.object,
  updateFinished: PropTypes.func,
  actions: PropTypes.array,
};
