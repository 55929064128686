import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.states = [];
    this.fetching = false;
    this.statesPromise = null;
  }

  getStates() {
    return this.states;
  }

  getStatesPromise() {
    return this.statesPromise;
  }

  isFetching() {
    return this.fetching;
  }

  setFetching(statesPromise) {
    this.fetching = true;
    this.statesPromise = statesPromise;
  }

  setStates = (states) => {
    this.states = states;
    this.fetching = false;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'SET_FETCHING': {
        this.setFetching(action.statesPromise);
        break;
      }
      case 'SET_STATES': {
        this.setStates(action.states);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
