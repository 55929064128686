import dispatcher from './dispatcher';
import { get, post, remove, patch } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';
import NotificationsStore from './Store';

//TODO: Change workorder to workorderid
export const addNotification = (type, message, url, user, workOrder, fileId) => {
  const date = new Date();
  const newNotification = {
    workOrderID: workOrder.id,
    type,
    message,
    readArr: [],
    createdBy: user.uid,
    fileId,
    date,
  };
  if (url) {
    newNotification.url = url;
  }
  post('/messages/notifications', newNotification).then((newNotification) => {
    dispatcher.dispatch({
      type: 'ADD_NOTIFICATION',
      newNotification,
    });
  });
};

export const editNotification = (notification, workOrder, user) => {
  const { id } = workOrder;
  const newText = window.prompt('Edit Notification', notification.message);
  if (newText) {
    notification.message = newText;
    if (notification.id) {
      patch(`/messages/notifications/${notification.id}`, { ...notification, workOrderID: id, uid: user.uid }).then((newNotification) => {
        dispatcher.dispatch({
          type: 'EDIT_NOTIFICATION',
          newNotification,
        });
      });
    }
  }
};

export const deleteNotification = (notification, user, workOrder) => {
  const c = window.confirm('Are you sure you want to delete this notification?');
  if (c) {
    if (notification.id) {
      remove(`/messages/notifications/${notification.id}`, { ...notification, workOrderID: workOrder.id, uid: user.uid })
        .then(() => {
          createSnack('Success', 'Deleted notification');
          dispatcher.dispatch({ type: 'DELETE_NOTIFICATION', notification });
        })
        .catch((err) => {
          window.alert(err.message);
        });
      if (notification.fileId) {
        remove(`/files/${notification.fileId}`).then((results) => {
          createSnack('Success', 'Removed file attached to notification');
        });
      }
    }
  }
};

export const addReplyNotification = (notification, user, workOrder) => {
  let { type, parentId } = notification;
  const message = prompt('Reply:');
  if (message) {
    if (!parentId) {
      parentId = notification.id;
    }
    const date = new Date();
    const newNotification = {
      workOrderID: workOrder.id,
      type,
      message,
      createdBy: user.uid,
      date,
      parentId,
    };
    post('/messages/notifications', newNotification).then((notification) => {
      loadNotifications(workOrder.id);
    });
  }
};

export const loadNotifications = (workOrderID) => {
  const filter = {
    eager: {
      $where: {
        parentId: {
          $exists: false,
        },
        workOrderID,
      },
      children: {
        deliverTo: {},
      },
      deliverTo: {},
    },
  };
  get('/messages/notifications', { filter }).then((notifications) => {
    dispatcher.dispatch({ type: 'RECEIVE_NOTIFICATIONS', notifications });
  });
};
