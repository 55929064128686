import dispatcher from './dispatcher';

// {
//   // map tokens to messages
//   notification: {
//     title: `${type} Alert`,
//       body: message,
//   },
//   data: {
//     workOrderID: `${currentWorkOrder.id}`,
//       message,
//       type,
//   },
//   webpush: {
//     fcm_options: {
//       image: 'https://www.ybashirts.com/wp-content/uploads/2018/10/YBA-LOGO-Blue-Gray.png',
//         link: `https://portal.ybashirts.com/WorkOrder/${currentWorkOrder.id}`,
//     },
//   },
//   token,
// }
//
// {
//   "data": {
//   "score": "850",
//     "time": "2: 45"
// },
//   "token": "fPCsFFlgL6BgQtNXA4rNqT:APA91bF1Whm86EL65kUY_4R--Xv-J1Mmnfz7wXazwehMkTULUgdl-ckM904tYzo7YaIQYfTMD_muvYUdkC3pbqz856yK7SawWTBhHqoqNNF-vspA6x29mZ9UXqR81cGIKuXiaE3_ZdRV"
// }

export const forwardMessage = (payload) => {
  const type = payload.notification ? 'NOTIFICATION' : 'DATA';
  dispatcher.dispatch({
    type,
    data: payload.data,
  });
};
