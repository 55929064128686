import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Card, CardMedia, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, patch } from '../../../../Functions/API';
import { createSnack } from '../../../../Stores/Snack/Actions';

const User = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    get(`/user/${id}?combinePermissions=false`).then(setUser);
    get('/permissions').then(setPermissions);
    get('/groups').then(setGroups);
  }, []);

  useEffect(() => {
    if (groups?.length && user && permissions?.length) {
      setLoaded(true);
    }
  }, [groups, user, permissions]);

  const permissionsChangeHandler = (e, values) => {
    setUser({ ...user, permissions: values });
  };

  const groupsChangeHandler = (e, values) => {
    setUser({ ...user, groups: values });
  };

  const saveUser = () => {
    patch(`/user/${id}`, user).then(() => {
      createSnack('Success', 'Updated user');
    });
  };

  return (
    <Grid style={{ marginTop: '10px', padding: '10px' }} container justify="center">
      <Grid item xs={12} sm={6}>
        <Card>
          <CardMedia style={{ padding: '10px' }}>
            <Typography gutterBottom variant="h5" component="h2">
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
            {loaded && (
              <>
                <Autocomplete
                  multiple
                  id="permissions"
                  options={permissions}
                  getOptionLabel={(option) => option.label}
                  onChange={permissionsChangeHandler}
                  value={user?.permissions}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Permissions" placeholder="Permissions" />}
                />
                <Autocomplete
                  multiple
                  id="groups"
                  options={groups}
                  getOptionLabel={(option) => option.name}
                  onChange={groupsChangeHandler}
                  value={user?.groups}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Groups" placeholder="Groups" />}
                />
                <Grid style={{ marginTop: '20px' }} container justify="center">
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={saveUser}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

export default User;
