/* eslint-disable react/display-name */
/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint prefer-destructuring:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { loadQueryFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import FiltersStore from '../../../Stores/Fulfillment/Filters/Store';
import MaterialTable from 'material-table';
import OrdersStore from '../../../Stores/Fulfillment/Orders/Store';
import { loadOrders } from '../../../Stores/Fulfillment/Orders/Actions';
import { Button } from '@material-ui/core';
import Header from '../../../Components/Fulfillment/Orders/Header';
import Order from './order';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  customer: {
    color: 'blue',
    fontSize: 'small',
  },
  store: {
    color: 'Coral',
    fontSize: 'small',
  },
  dateTime: {
    color: 'green',
  },
  cell: {
    width: '10%',
  },
  filters: {
    width: '100%',
    backgroundColor: 'white',
  },
}));

function useQuery() {
  return queryString.parse(useLocation().search);
}

const Orders = () => {
  const queryParams = useQuery();
  const [selected, setSelected] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({ ...FiltersStore.getFilters() });
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    // todo:I dont think we ever want to load from storage in order to use query loading
    // Should we keep this in a store?
    loadQueryFilters(queryParams).then(() => {
      loadOrders();
    });
    FiltersStore.on('change', handleFilterChange);
    OrdersStore.on('change', getOrders);
    return () => {
      FiltersStore.removeListener('change', handleFilterChange);
      OrdersStore.removeListener('change', getOrders);
    };
  }, []);

  const getOrders = () => {
    setOrders([...OrdersStore.getOrders()]);
  };
  const handleFilterChange = () => {
    setFilters({ ...FiltersStore.getFilters() });
  };

  const selectionChangeHandler = (values) => {
    setSelected(values);
  };

  //todo:do we still need this?
  useEffect(() => {
    loadOrders();
  }, [filters]);

  return (
    <div className={classes.root}>
      <Switch>
        <Route path={`/fulfillment/orders/:orderId`}>
          <Order />
        </Route>
        <Route path={`/fulfillment/orders`}>
          <>
            <Header selected={selected} />
            <MaterialTable
              columns={[
                { title: 'ID', field: 'externalId' },
                { title: 'Order Placed', field: 'createdAt', type: 'date' },
                { title: 'Status', field: 'status.name' },
                {
                  title: 'Name',
                  render: (rowData) => <div>{`${rowData.firstName} ${rowData.lastName}`}</div>,
                },
                { title: 'Items', field: 'numberOfLineItems' },
                { title: 'Ship By Date', field: 'shipByDate', type: 'date' },
                { title: 'Ship To Country', field: 'contact.country.name', type: 'string' },
                { title: 'Ex. Arrival Date', field: 'expectedArrivalDate', type: 'date' },
                { title: 'S&P WO', field: 'shippingWorkOrderId' },
                { title: 'Billed WO', field: 'billingWorkOrderId' },
              ]}
              data={orders}
              actions={[
                {
                  icon: () => (
                    <Button size={'small'} variant={'contained'}>
                      Save
                    </Button>
                  ),
                  onClick: (e, r) => {
                    console.log(r);
                  },
                },
              ]}
              options={{ showTitle: false, search: false, paging: false, selection: true, padding: 'dense', toolbar: false }}
              onRowClick={(event, rowData) => history.push(`/fulfillment/orders/${rowData.id}`)}
              onSelectionChange={selectionChangeHandler}
            />
          </>
        </Route>
      </Switch>
    </div>
  );
};

Orders.displayName = 'Orders';

export default Orders;
