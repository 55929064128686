import { Redirect, Route, Switch } from 'react-router-dom';

import React from 'react';
import Privacy from './Privacy';
import Home from './Home';

const Shopify = () => {
  return (
    <div style={{ height: '100%' }}>
      <title>Shopify</title>
      <Switch>
        <Route path={`/shopify/privacy`}>
          <Privacy />
        </Route>
        <Route path={`/shopify/home`}>
          <Home />
        </Route>
        <Route path={`/`}>
          <Redirect to={`/shopify/home`} />
        </Route>
      </Switch>
    </div>
  );
};

export default Shopify;
