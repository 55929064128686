/* eslint-disable react/jsx-key */
/* eslint no-alert:0 */
/* eslint no-param-reassign:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import { get } from '../../../../../Functions/API';

class DetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
    };
  }

  componentDidMount() {
    const id = this.props.detailID;
    const data = [
      {
        name: 'id',
        value: id,
      },
    ];
    get('/getHistoryDetail', data)
      .then((response) => {
        console.log(response);
        if (response.length === 0) {
          response = [{ details: 'No details provided' }];
        }
        this.setState({
          details: response,
        });
      })
      .catch((err) => {
        window.alert(err);
      });
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          marginTop: '5%',
          backgroundColor: 'white',
          padding: '15px',
          outline: 'none',
        }}
      >
        <h1>History Details</h1>
        {this.state.details.map((detail) => (
          <h4>{detail.details}</h4>
        ))}
      </div>
    );
  }
}

export default DetailModal;
