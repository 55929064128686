import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, post } from '../../../Functions/API';
import VAutocomplete from '../../Shared/VAutocomplete';
import { closeDialog } from '../../../Stores/Dialog/Actions';
import { createSnack } from '../../../Stores/Snack/Actions';
import { Button, Card, CircularProgress, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const ProductVariantsModal = ({ loadProduct, productId, activeStyle, externalMaterial, productImages, printReadyArt }) => {
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = useState(activeStyle);
  const [colors, setColors] = useState([]);
  const [chosenColors, setChosenColors] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [creating, setCreating] = useState(false);

  const updateAllRawMaterials = (color, key, value) => {
    setRawMaterials([
      ...rawMaterials.filter((eSku) => eSku.colorId !== color.id),
      ...rawMaterials
        .filter((eSku) => eSku.colorId === color.id)
        .map((eSku) => {
          const copySku = { ...eSku };
          copySku[key] = value;
          return copySku;
        }),
    ]);
  };

  const createProductVariants = async () => {
    setCreating(true);
    try {
      await Promise.all(
        rawMaterials.map((rm) => {
          const copyRM = { ...rm };
          if (copyRM?.manualSku) {
            delete copyRM.manualSku;
          }
          if (copyRM?.ybaSku) {
            delete copyRM.ybaSku;
          }
          const rmColor = chosenColors.find((cc) => cc.id === rm.colorId);

          const ybaSku = {
            rawMaterialId: rm.id,
            rawMaterial: {
              id: rm.id,
              sizeId: rm.sizeId,
              brandId: rm.brandId,
              colorId: rm.colorId,
              materialId: rm.materialId,
              styleId: rm.styleId,
              size: rm.size,
              color: {
                color: rmColor.color,
                id: rmColor.id,
              },
            },
            productImages: rm.productImages,
            workOrderArt: rm.printReadyArt,
          };
          if (rm?.manualSku) {
            ybaSku.sku = rm.ybaSku;
          }
          post(`/fulfillment/products/${productId}/ybaSkus`, ybaSku);
        }),
      );
    } catch (err) {
      console.error(err);
      createSnack(err.message);
    } finally {
      setCreating(false);
      closeDialog();
    }
    console.log('hello');
  };

  const updateExternalSku = (sizeGroup, key, value) => {
    const copySkus = rawMaterials.map((es) => {
      const ces = { ...es };
      if (sizeGroup.rawMaterials.find((eSku) => eSku.id === es.id)) {
        ces[key] = value;
      }
      return ces;
    });
    setRawMaterials(copySkus);
  };

  const loadStyles = () => {
    get('/fulfillment/externalStyles').then(({ results }) => {
      results.map((r) => (r.selected = false));
      setStyles(results);
    });
  };

  useEffect(() => {
    loadStyles();
  }, []);

  useEffect(() => {
    if (style) {
      const filter = {
        eager: {
          rawMaterials: { $where: { styleId: style.id }, size: {}, externalSkus: { vendor: {} } },
          $where: { 'rawMaterials.styleId': style.id },
        },
      };
      if (externalMaterial) {
        filter.eager.$where['rawMaterials.materialId'] = externalMaterial.id;
        filter.eager.rawMaterials.$where.materialId = externalMaterial.id;
      }
      get('/fulfillment/externalColors', { filter: JSON.stringify(filter) }, 'obj').then(setColors);
    }
  }, [style]);

  const getColorLabel = (option) => {
    const distinctVendors = option?.rawMaterials?.map((rm) => rm?.externalSkus[0]?.vendor?.name).filter(onlyUnique);

    return `${option?.color} (${distinctVendors.join(',')})`;
  };

  const getSizeLabel = (option) => {
    const distinctVendors = option.rawMaterials
      ?.map((rm) => {
        return rm?.externalSkus?.map((eSku) => eSku.vendor.name);
      })
      .flat()
      .filter(onlyUnique);
    if (distinctVendors) {
      return `${option.size} (${distinctVendors.join(',')})`;
    }
    return option.size;
  };

  const getSizesOptions = (chosenColor) => {
    const groupedSizeOptions = [];
    for (const rm of chosenColor.rawMaterials) {
      const foundSize = groupedSizeOptions.find((gs) => gs.id === rm.sizeId);
      if (foundSize) {
        foundSize.rawMaterials.push(rm);
      } else {
        groupedSizeOptions.push({
          id: rm.size.id,
          size: rm.size.size,
          groupId: rm.size.groupId,
          sort: rm.size.sort,
          rawMaterials: [rm],
        });
      }
    }
    console.log('groupedSizeOptions', groupedSizeOptions);
    return groupedSizeOptions;
  };

  const getSizesValues = (chosenColor) => {
    const colorRawMaterials = rawMaterials.filter((rm) => rm?.colorId === chosenColor?.id);

    const groupedSizeOptions = [];
    for (const rm of colorRawMaterials) {
      const foundSize = groupedSizeOptions.find((gs) => gs.id === rm.size.id);
      if (foundSize) {
        foundSize.rawMaterials.push(rm);
      } else {
        groupedSizeOptions.push({
          id: rm.size.id,
          size: rm.size.size,
          groupId: rm.size.groupId,
          sort: rm.size.sort,
          rawMaterials: [rm],
        });
      }
    }
    return groupedSizeOptions;
  };

  return (
    <>
      <VAutocomplete
        value={activeStyle}
        disabled={Boolean(style?.id)}
        att="style"
        label="Style"
        options={styles.filter((style) => style.style)}
        onChange={(e, v) => {
          setStyle(v);
        }}
      />
      {externalMaterial?.id && (
        <Autocomplete
          disabled
          fullWidth
          style={{ width: '100%' }}
          value={externalMaterial}
          onChange={(e, v) => {
            console.log(v);
          }}
          options={[]}
          getOptionLabel={(o) => o?.name}
          renderInput={(params) => (
            <TextField {...params} fullWidth style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Material" />
          )}
        />
      )}
      {Boolean(colors.length) && (
        <Autocomplete
          fullWidth
          style={{ width: '100%' }}
          multiple
          value={chosenColors}
          onChange={(e, v) => {
            setChosenColors(v);
          }}
          options={colors}
          getOptionLabel={getColorLabel}
          renderInput={(params) => (
            <TextField {...params} fullWidth style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Colors" />
          )}
        />
      )}
      {chosenColors.map((chosenColor) => {
        return (
          <Grid container key={chosenColor.id}>
            <Autocomplete
              fullWidth
              multiple
              style={{ width: '100%' }}
              value={getSizesValues(chosenColor)}
              onChange={(e, v, reason) => {
                const newRawMaterials = v.map((s) => s.rawMaterials).flat();
                const copyRawMaterials = rawMaterials.filter((rm) => rm?.colorId !== chosenColor?.id);
                setRawMaterials([...copyRawMaterials, ...newRawMaterials].filter(onlyUnique));
              }}
              options={getSizesOptions(chosenColor)}
              getOptionLabel={getSizeLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={`${chosenColor.color} Sizes`}
                />
              )}
            />
          </Grid>
        );
      })}
      {chosenColors.map((chosenColor) => {
        return (
          <Grid key={chosenColor.id} container>
            <Card style={{ width: '100%', padding: '10px' }}>
              <Typography variant="h6">{chosenColor.color}</Typography>
              <Autocomplete
                multiple
                onChange={(e, v) => updateAllRawMaterials(chosenColor, 'productImages', v)}
                getOptionLabel={(o) => o?.url}
                renderOption={(o) => {
                  return (
                    <div>
                      <Grid>
                        <img height="200px" id={`${chosenColor.color}-${o?.id}`} src={o?.url} />
                      </Grid>
                    </div>
                  );
                }}
                options={productImages}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label="Product Images (Assign to all sizes)"
                  />
                )}
              />
              <Autocomplete
                multiple
                onChange={(e, v) => updateAllRawMaterials(chosenColor, 'printReadyArt', v)}
                getOptionLabel={(o) => o?.url}
                renderOption={(o) => {
                  return (
                    <div>
                      {!o?.url.includes('pdf') ? (
                        <Grid>
                          <img height="200px" id={`${chosenColor.color}-${o?.id}`} src={o?.url} />
                        </Grid>
                      ) : (
                        <Grid>
                          <iframe height="200px" src={o?.url} />
                        </Grid>
                      )}
                    </div>
                  );
                }}
                options={printReadyArt}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label="Print Ready Art (Assign to all sizes)"
                  />
                )}
              />
              {Boolean(rawMaterials.filter((eSku) => eSku.colorId === chosenColor.id).length) &&
                rawMaterials
                  .filter((rm) => rm.colorId === chosenColor.id)
                  .reduce((newArr, rm) => {
                    const arrCopy = newArr ? [...newArr] : [];
                    const foundSize = arrCopy.find((gs) => gs.id === rm.size.id);
                    if (foundSize) {
                      foundSize.rawMaterials.push(rm);
                    } else {
                      arrCopy.push({
                        id: rm.size.id,
                        size: rm.size.size,
                        groupId: rm.size.groupId,
                        sort: rm.size.sort,
                        rawMaterials: [rm],
                      });
                    }
                    return arrCopy;
                  }, [])
                  .map((sizeGroup) => {
                    return (
                      <div key={sizeGroup.id} style={{ width: '100%' }}>
                        <Typography variant="subtitle1">
                          <strong>Size</strong> {sizeGroup.size}
                        </Typography>
                        <FormGroup row>
                          <FormControlLabel
                            label="Manual Sku"
                            control={
                              <Switch
                                checked={sizeGroup?.rawMaterials[0]?.manualSku || false}
                                name="manualSku"
                                onChange={(e) => updateExternalSku(sizeGroup, 'manualSku', e.target.checked)}
                              />
                            }
                          />
                        </FormGroup>
                        {sizeGroup?.rawMaterials[0]?.manualSku && (
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={'Sku'}
                            placeholder="Must be unique!"
                            value={sizeGroup?.rawMaterials[0]?.ybaSku || ''}
                            onChange={(e) => updateExternalSku(sizeGroup, 'ybaSku', e.target.value)}
                          />
                        )}
                        <Autocomplete
                          multiple
                          value={sizeGroup?.rawMaterials[0]?.productImages || []}
                          onChange={(e, v) => updateExternalSku(sizeGroup, 'productImages', v)}
                          getOptionLabel={(o) => o?.url}
                          renderOption={(o) => {
                            return (
                              <div>
                                <Grid>{o?.url}</Grid>
                                <Grid>
                                  <img height="200px" id={`${sizeGroup.id}-${o?.id}`} src={o?.url} />
                                </Grid>
                              </div>
                            );
                          }}
                          options={productImages}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              style={{ width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                              label="Product Images"
                            />
                          )}
                        />
                        <Autocomplete
                          multiple
                          value={sizeGroup?.rawMaterials[0]?.printReadyArt || []}
                          onChange={(e, v) => updateExternalSku(sizeGroup, 'printReadyArt', v)}
                          getOptionLabel={(o) => o?.name}
                          renderOption={(o) => {
                            return (
                              <div>
                                <Grid>{o?.name}</Grid>
                                {o?.url.includes('pdf') ? (
                                  <Grid>
                                    <iframe height="200px" src={o?.url} />
                                  </Grid>
                                ) : (
                                  <Grid>
                                    <img height="200px" id={`${sizeGroup.id}-${o?.file?.name}`} src={url} />
                                  </Grid>
                                )}
                              </div>
                            );
                          }}
                          options={printReadyArt}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              style={{ width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                              label="Print Ready Art"
                            />
                          )}
                        />
                      </div>
                    );
                  })}
            </Card>
          </Grid>
        );
      })}
      <Grid container justify="center">
        <Grid item>
          {creating ? (
            <CircularProgress />
          ) : (
            <Button color="primary" variant="contained" onClick={createProductVariants}>
              Create
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

ProductVariantsModal.displayName = 'ProductVariantsModal';

ProductVariantsModal.propTypes = {
  productId: PropTypes.any,
  loadProduct: PropTypes.any,
  activeStyle: PropTypes.any,
  productImages: PropTypes.array,
  printReadyArt: PropTypes.array,
  externalMaterial: PropTypes.any,
};

export default ProductVariantsModal;
