import React, { useState, useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { FormLabel } from '@material-ui/core';
import { post } from '../../../Functions/API';
import PropTypes from 'prop-types';
import { storage } from '../../../fire';
import { closeDialog } from '../../../Stores/Dialog/Actions';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10 MB

const PrintReadyArtModal = ({ ybaProductId, loadProduct }) => {
  const handleChange = async (files) => {
    if (files.length) {
      console.log(files);
      const storageRef = storage.ref(`fulfillment/${new Date().getTime()}-${files[0].name}`);
      await storageRef.put(files[0]);
      const url = await storageRef.getDownloadURL();

      await post('/workOrderArt', {
        ybaProductId,
        url,
      });
      loadProduct();
      closeDialog();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <FormLabel>Product Images (1 at a time)</FormLabel>
      <DropzoneArea
        acceptedFiles={['image/*', 'application/pdf', 'pdf']}
        filesLimit={1}
        id="print-ready-art-modal-uploader"
        onChange={handleChange}
        showAlerts={['error']}
        maxFileSize={MAX_FILE_SIZE}
      />
    </div>
  );
};

PrintReadyArtModal.displayName = 'PrintReadyArtModal';

PrintReadyArtModal.propTypes = {
  ybaProductId: PropTypes.number,
  loadProduct: PropTypes.func,
};

export default PrintReadyArtModal;
