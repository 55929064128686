/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Paper, Snackbar, SnackbarContent } from '@material-ui/core';
import MaterialTable from 'material-table';
import Menu from '../Menu';
import { get } from '../../Functions/API';
const ArtStudio = ({ menu }) => {
  const [artRequests, setArtRequests] = useState([]);
  const columns = [
    {
      title: 'Work Order',
      field: 'viewableId',
      editable: 'never',
    },
    {
      title: 'Original Submission Time',
      field: 'firstTime',
      editable: 'never',
    },
    {
      title: 'Most Recent Submission Time',
      field: 'time',
      editable: 'never',
    },
    {
      title: 'Number of Submissions',
      field: 'submissionAttempts',
      editable: 'never',
    },
    {
      title: 'Most Recent Comment',
      field: 'comments',
      editable: 'never',
      render: (rowData) => {
        return (
          rowData.comments.length &&
          `${rowData.comments[rowData.comments.length - 1].comment} - ${rowData.comments[rowData.comments.length - 1].user}`
        );
      },
    },
  ];
  const history = useHistory();

  useEffect(() => {
    getArtRequests();
  }, []);

  const getArtRequests = () => {
    get('/getArtRequests', []).then((results) => {
      setArtRequests(results);
    });
  };

  return (
    <div>
      <Paper>
        <MaterialTable
          title="Art Requests"
          columns={columns}
          data={artRequests}
          options={{
            rowStyle: {
              fontSize: 14,
            },
            pageSizeOptions: [10, 15, 25, 50],
            pageSize: 10,
          }}
          onRowClick={(event, rowData) => {
            history.push(`/art/workorder/${rowData.workOrderID}`);
          }}
        />
      </Paper>
    </div>
  );
};

export default ArtStudio;

ArtStudio.propTypes = {
  menu: PropTypes.bool,
};
