/* eslint-disable react/jsx-key */
/* eslint no-return-await:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-useless-escape:0 */
/* eslint no-unused-vars:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint react/state-in-constructor:0 */
import React, { Component } from 'react';
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ybaLogo from '../../images/yba-logo.png';
import cspLogo from '../../images/CSP-logo.png';
import './styles/index.css';
import stateAbbreviations from '../Utils/stateAbbrev';
import { auth } from '../../fire';
import { post } from '../../Functions/API';

export default class CreateUserNew extends Component {
  state = {
    isLoading: false,
    submitted: false,
    firstName: null,
    firstNameErr: null,
    lastName: null,
    lastNameErr: null,
    businessName: null,
    businessNameErr: null,
    streetAddress: null,
    streetAddressErr: null,
    city: null,
    zipCode: null,
    zipCodeErr: null,
    state: '',
    stateErr: null,
    email: null,
    emailErr: null,
    password: null,
    passwordErr: null,
    password2: null,
    password2Err: null,
    trackingNumber: null,
    trackingNumberErr: null,
    group: '',
    groupErr: null,
    snackBarOpen: false,
    groups: [
      {
        id: 1,
        name: 'Admin',
      },
      {
        id: 2,
        name: 'Order Processor',
      },
      {
        id: 3,
        name: 'Nothing',
      },
      {
        id: 4,
        name: 'Owner',
      },
      {
        id: 5,
        name: 'Sales Admin',
      },
      {
        id: 6,
        name: 'Accounting',
      },
      {
        id: 7,
        name: 'Production - Art',
      },
      {
        id: 8,
        name: 'Production Admin',
      },
      {
        id: 9,
        name: 'Accounting Admin',
      },
      {
        id: 10,
        name: 'Sales',
      },
      {
        id: 11,
        name: 'Production - Front End',
      },
      {
        id: 12,
        name: 'Production - Fulfillment',
      },
      {
        id: 13,
        name: 'Production - Printer',
      },
      {
        id: 14,
        name: 'Production - Back End',
      },
      {
        id: 15,
        name: 'Customer',
      },
      {
        id: 16,
        name: 'Customer - Inventory',
      },
      {
        id: 17,
        name: 'Marketing',
      },
    ],
  };

  change = (event, field) => {
    this.setState(
      {
        [field]: event.target.value,
      },
      () => {
        if (this.state.submitted) {
          this.validate();
        }
      },
    );
  };

  handleClose = () => {
    const { snackBarOpen } = this.state;
    this.setState({
      snackBarOpen: !snackBarOpen,
    });
  };

  validate = () => {
    let validationPassed = true;
    const { firstName, lastName } = this.state;
    const { email, password, password2 } = this.state;
    const { group, state, businessName, upsAccountNumber, streetAddress, city, zipCode } = this.state;

    this.setState({
      firstNameErr: null,
      lastNameErr: null,
      emailErr: null,
      passwordErr: null,
      password2Err: null,
      groupErr: null,
      businessNameErr: null,
      streetAddressErr: null,
      cityErr: null,
      stateErr: null,
      zipCodeErr: null,
    });

    if (!firstName) {
      validationPassed = false;
      this.setState({ firstNameErr: 'Please enter your first name.' });
    }
    if (!lastName) {
      validationPassed = false;
      this.setState({ lastNameErr: 'Please enter your last name.' });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      validationPassed = false;
      this.setState({ emailErr: 'Not a valid email' });
    }
    if (!password) {
      validationPassed = false;
      this.setState({ passwordErr: 'Please enter a password' });
    } else if (password.length < 8) {
      validationPassed = false;
      this.setState({
        passwordErr: 'Please enter a password atleast 8 characters long',
      });
    }
    if (!password2) {
      validationPassed = false;
      this.setState({ password2Err: 'Please confirm your password' });
    } else if (password2.length < 8) {
      validationPassed = false;
      this.setState({
        password2Err: 'Please enter a password atleast 8 characters long',
      });
    }
    if (this.props.isInternal) {
      if (!group) {
        validationPassed = false;
        this.setState({ groupErr: 'Please select a group.' });
      }
    } else {
      if (!businessName) {
        validationPassed = false;
        this.setState({ businessNameErr: 'Please enter your business name.' });
      }
      if (!streetAddress) {
        validationPassed = false;
        this.setState({ streetAddressErr: 'Please enter your address.' });
      }
      if (!city) {
        validationPassed = false;
        this.setState({ cityErr: 'Please enter your city.' });
      }
      if (!state) {
        validationPassed = false;
        this.setState({ stateErr: 'Please enter your state.' });
      }
      if (!zipCode) {
        validationPassed = false;
        this.setState({ zipCodeErr: 'Please enter your zip code.' });
      }
    }
    return validationPassed;
  };

  onSumbit = async (event) => {
    const { email, password } = this.state;
    event.preventDefault();
    this.setState({ submitted: true });

    if (this.validate()) {
      this.setState({ isLoading: true });

      await auth
        .createUserWithEmailAndPassword(email, password)
        .then(async (cred) => {
          const token = await cred.user.getIdToken(true);
          const { uid } = cred.user;
          let custID;

          if (!this.props.isInternal) {
            custID = await this.createCustomer(token);
            await this.createUser(token, uid, custID);
            await this.updateActive(token, uid);
            window.location.href = '/csp';
          } else {
            await this.createUser(token, uid, null);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ snackBarOpen: true });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  updateActive = async (token, uid) => {
    await post('updateActive', { uid, active: true });
  };

  createUser = async (token, uid, custID) => {
    const { email, group, firstName, lastName } = this.state;
    let params;
    if (this.props.isInternal) {
      params = { email, group, firstName, lastName };
    } else {
      params = { email, group: 'Customer', firstName, lastName };
    }
    post('/createUser', { ...params, userID: uid, custID }).then(() => {
      window.location.href = '/';
    });
  };

  createCustomer = async () => {
    const { businessName, email, streetAddress, city, state, zipCode, upsAccountNumber } = this.state;
    let custID = null;
    await post('/addCustomer', {
      name: businessName,
      organization: null,
      co: null,
      email,
      billingStreet: streetAddress,
      billingCity: city,
      billingState: state,
      billingZip: zipCode,
      shippingStreet: null,
      shippingCity: null,
      shippingState: null,
      shippingZip: null,
      upsAccountNumber,
    })
      .then((res) => {
        custID = res.customer;
      })
      .catch((err) => {
        throw err;
      });

    return custID;
  };

  render() {
    const {
      isLoading,
      firstNameErr,
      lastNameErr,
      emailErr,
      password,
      passwordErr,
      password2,
      password2Err,
      group,
      groupErr,
      snackBarOpen,
      state,
      stateErr,
      businessNameErr,
      streetAddressErr,
      cityErr,
      zipCodeErr,
      groups,
    } = this.state;
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            onClose={this.handleClose}
            style={{
              backgroundColor: 'red',
            }}
            message="Account not created successfully. That email might already have an account!"
          />
        </Snackbar>
        {isLoading && <LinearProgress style={{ height: '5px' }} />}
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div
            className="paper-container"
            style={{
              margin: '40px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={this.props.isInternal ? ybaLogo : cspLogo} className={this.props.isInternal ? 'yba-logo' : 'csp-logo'} />
            <Avatar
              style={{
                margin: '16px',
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {this.props.isInternal ? 'Create your MSAS account!' : 'Create CSP Account'}
            </Typography>
            <form
              style={{
                width: '100%',
                marginTop: '10px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="name given-name"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    error={!!firstNameErr}
                    autoFocus
                    onChange={(e) => this.change(e, 'firstName')}
                    helperText={firstNameErr}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="name family-name"
                    onChange={(e) => this.change(e, 'lastName')}
                    helperText={lastNameErr}
                    error={!!lastNameErr}
                  />
                </Grid>
                {!this.props.isInternal && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="businessName"
                        label="Business/Comapny Title"
                        name="businessName"
                        autoComplete="organization-title"
                        onChange={(e) => this.change(e, 'businessName')}
                        helperText={businessNameErr}
                        error={!!businessNameErr}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="streetAddress"
                        label="Street Address"
                        name="streetAddress"
                        autoComplete="street-address"
                        onChange={(e) => this.change(e, 'streetAddress')}
                        helperText={streetAddressErr}
                        error={!!streetAddressErr}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="city"
                        label="City"
                        name="city"
                        autoComplete="city"
                        onChange={(e) => this.change(e, 'city')}
                        helperText={cityErr}
                        error={!!cityErr}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="zipCode"
                        label="Zip Code"
                        name="zipCode"
                        autoComplete="postal-code"
                        onChange={(e) => this.change(e, 'zipCode')}
                        helperText={zipCodeErr}
                        error={!!zipCodeErr}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth error={!!stateErr}>
                        <InputLabel>State</InputLabel>
                        <Select labelId="state-label" id="state" value={state} onChange={(e) => this.change(e, 'state')}>
                          {stateAbbreviations.map((x) => {
                            return <MenuItem value={x}>{x}</MenuItem>;
                          })}
                        </Select>
                        {stateErr && <FormHelperText>{stateErr}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => this.change(e, 'email')}
                    helperText={emailErr}
                    error={!!emailErr}
                  />
                </Grid>
                {!this.props.isInternal && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="upsAccountNumber"
                      label="UPS Account Number"
                      name="upsAccountNumber"
                      helperText="* Optional"
                      onChange={(e) => this.change(e, 'upsAccountNumber')}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    helperText={passwordErr || 'Password must be atleat 8 characters'}
                    error={!!passwordErr}
                    onChange={(e) => this.change(e, 'password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password2"
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    autoComplete="new-password"
                    error={password !== password2 || !!password2Err}
                    onChange={(e) => this.change(e, 'password2')}
                    helperText={password !== password2 ? 'Passwords do not match.' : password2Err}
                  />
                </Grid>
                {this.props.isInternal && (
                  <Grid item xs={12}>
                    <FormControl fullWidth error={!!groupErr}>
                      <InputLabel>Group</InputLabel>
                      <Select labelId="group-label" id="group" value={group} onChange={(e) => this.change(e, 'group')}>
                        {groups.map((group, key) => (
                          <MenuItem key={key} value={group.name}>
                            {group.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {groupErr && <FormHelperText>{groupErr}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                style={{
                  margin: '16px 0',
                }}
                disabled={isLoading}
                onClick={this.onSumbit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href={`/${this.props.isInternal ? '' : 'csp'}`} variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </>
    );
  }
}
