/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { CardHeader, TextField, Card, Grid, FormControlLabel, Switch, Typography, IconButton, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { fetchPrintGroups } from '../../../Stores/PrintGroups/Actions';
import { Delete, Save } from '@material-ui/icons';

const inputStyle = {
  padding: '10px',
};

const ArtCard = ({ printReadyArt, updatePrintReadyArt, deleteArt, saveArt }) => {
  const [printGroups, setPrintGroups] = useState([]);
  const [displayUrl, setDisplayUrl] = useState(null);

  useEffect(() => {
    fetchPrintGroups().then(setPrintGroups);
  }, []);

  useEffect(() => {
    if (printReadyArt?.file) {
      setDisplayUrl(URL.createObjectURL(printReadyArt.file));
    }
  }, [printReadyArt]);

  return (
    <Card
      style={{
        width: '345px',
        position: 'relative',
        border: '2px solid rgba(0,0,0,0)',
      }}
    >
      <Grid container style={{ width: '100%' }} justify="flex-end">
        <Grid item>
          <Tooltip title="Save">
            <IconButton color="primary" size="small" onClick={() => saveArt(printReadyArt.id)}>
              <Save fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="secondary" size="small" onClick={() => deleteArt(printReadyArt.id)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CardHeader
        title={
          <>
            {printGroups && (
              <Autocomplete
                id="print-group"
                style={{ paddingBottom: '8px' }}
                value={printReadyArt?.printGroup}
                options={printGroups}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updatePrintReadyArt(printReadyArt.id, 'printGroup', v);
                }}
                renderInput={(params) => <TextField {...params} label="Price List" variant="outlined" />}
              />
            )}
          </>
        }
        subheader={
          <>
            {printReadyArt?.printGroup?.printTypes && (
              <Autocomplete
                id="print-type"
                value={printReadyArt?.printType}
                options={printReadyArt?.printGroup?.printTypes}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updatePrintReadyArt(printReadyArt.id, 'printType', v);
                }}
                renderInput={(params) => <TextField {...params} label="Print Type" variant="outlined" />}
              />
            )}
          </>
        }
      />

      {!printReadyArt?.url?.includes('pdf') ? (
        <Grid
          style={{
            height: '300px',
            display: 'inline-block',
            textAlign: 'center',
            verticalAlign: 'middle',
          }}
          container
          justify="center"
        >
          <Grid item>
            <img
              style={{
                maxHeight: '300px',
                verticalAlign: 'middle',
                maxWidth: '300px',
              }}
              src={printReadyArt?.url}
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            width: '100%',
            height: '350px',
          }}
        >
          <iframe src={printReadyArt.url} />
        </div>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Name"
            onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'name', event.target.value)}
            value={printReadyArt.name}
          />
        </Grid>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Position"
            onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'position', event.target.value)}
            value={printReadyArt?.position ? printReadyArt?.position : ''}
          />
        </Grid>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Ink Color"
            onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'color', event.target.value)}
            value={printReadyArt?.color ? printReadyArt?.color : ''}
          />
        </Grid>
        {Boolean(printReadyArt?.printType?.number) && (
          <Grid item xs={12} style={inputStyle}>
            <TextField
              style={{ width: '100%' }}
              label="1-15 or 1,2,3"
              onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'numbers', event.target.value)}
              value={printReadyArt?.numbers ? printReadyArt?.numbers : ''}
            />
          </Grid>
        )}
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                id="reversible"
                size="small"
                checked={Boolean(printReadyArt?.reversible)}
                onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'reversible', !printReadyArt?.reversible)}
                value={Boolean(printReadyArt?.reversible)}
                color="primary"
              />
            }
            label={<Typography style={{ fontSize: 'smaller' }}>Reversible</Typography>}
            labelPlacement="bottom"
          />
        </Grid>
        {Boolean(printReadyArt?.printGroup?.type === 'screenPrint') && (
          <Grid individualArt xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="underlay"
                  size="small"
                  checked={Boolean(printReadyArt?.underlay)}
                  onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'underlay', !printReadyArt?.underlay)}
                  value={Boolean(printReadyArt?.underlay)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>Underlay</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
        {Boolean(printReadyArt?.printGroup?.type === 'heatPress') && (
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="medWeed"
                  size="small"
                  checked={Boolean(printReadyArt?.medWeed)}
                  onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'medWeed', !printReadyArt?.medWeed)}
                  value={Boolean(printReadyArt?.medWeed)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>M-Weed</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
        {Boolean(printReadyArt?.printGroup?.type === 'heatPress') && (
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="hardWeed"
                  size="small"
                  checked={Boolean(printReadyArt?.hardWeed)}
                  onChange={(event) => updatePrintReadyArt(printReadyArt?.id, 'hardWeed', !printReadyArt?.hardWeed)}
                  value={Boolean(printReadyArt?.hardWeed)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>H-Weed</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

PropTypes.propTypes = {
  printReadyArt: PropTypes.object,
  updatePrintReadyArt: PropTypes.func,
  saveArt: PropTypes.func,
  deleteArt: PropTypes.func,
};

export default ArtCard;
