import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingStore from '../../Stores/Loading/Store';

export default function LoadingWrapper({ children }) {
  const [loading, setLoading] = useState(false);

  const getLoading = () => {
    setLoading(LoadingStore.getLoading());
  };

  useEffect(() => {
    LoadingStore.on('change', getLoading);
    return () => {
      LoadingStore.removeListener('change', getLoading);
    };
  }, []);

  return <>{!loading && children}</>;
}

LoadingWrapper.propTypes = {
  children: PropTypes.any,
};
