import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { get } from '../../../../Functions/API.js';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { createSnack } from '../../../../Stores/Snack/Actions.js';
import { Breadcrumbs, Button, Link, Tooltip, Typography } from '@mui/material';
import { Add } from '@material-ui/icons';
import { createDialog } from '../../../../Stores/Dialog/Actions';
import AddProduct from './addProduct';

const YbaProducts = () => {
  const [products, setProducts] = useState([]);
  const [customer, setCustomer] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { lobId } = useParams();

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    load(lobId, page, pageSize);
  }, [lobId, page, pageSize]);

  const load = async (lobId, page, pageSize) => {
    setLoading(true);
    const [c] = await get('/customers', { filter: { eager: { $where: { lobId } } } });
    if (!c) return createSnack('Error', 'No Customer Found');
    setCustomer(c);
    const { results: p, total } = await get('/fulfillment/products', { filter: { eager: { $where: { customerId: c.id } } }, page, pageSize });
    setProducts(p);
    setRowCount(total);
    setLoading(false);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ row }) => [
        <Tooltip key={0} title={'Skus'}>
          <GridActionsCellItem
            icon={<div>S</div>}
            onClick={() => {
              history.push(`/info-plus/lobs/${lobId}/products/${row.id}/skus`);
            }}
            label={'Skus'}
          />
        </Tooltip>,
        <Tooltip key={0} title={'Create InfoPlus Products'}>
          <GridActionsCellItem
            icon={<div>+</div>}
            onClick={() => {
              createSnack('Success', 'This should send a whole lot of skus to info plus, but it does not yet');
            }}
            label={'Create InfoPlus Products'}
          />
        </Tooltip>,
      ],
    },
  ];

  const addProduct = () => {
    createDialog({
      title: 'Create Product',
      size: 'xl',
      content: <AddProduct customerId={customer.id} />,
      disableBackdropClick: true,
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button size={'small'} disabled={loading} onClick={addProduct}>
          <Add fontSize={'small'} />
          Add Product
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Link href={'/info-plus/lobs'}>Lines of Business</Link>
        <Typography>{`${customer?.name} Products`}</Typography>
      </Breadcrumbs>
      <DataGrid
        autoHeight
        columns={columns}
        rows={products}
        rowCount={rowCountState}
        paginationMode={'server'}
        density={'compact'}
        rowsPerPageOptions={[10, 20, 50, 100]}
        components={{ Toolbar: CustomToolbar }}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        loading={loading}
      />
    </div>
  );
};

export default YbaProducts;
