/* eslint react/prop-types:0 */
import React from 'react';
import { Checkbox, CircularProgress, FormControlLabel, Grid } from '@material-ui/core';

const StatusFilterItem = ({ title, count, match, keyProp, completedFilters, uncompletedFilters, loadDashBoardFilter }) => {
  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox checked={match} onChange={() => loadDashBoardFilter(completedFilters, uncompletedFilters)} value={title} color="primary" />
          }
          label={title}
          key={keyProp}
        />
      </Grid>
      <Grid item style={{ paddingLeft: '30px' }}>
        {count !== null ? count : <CircularProgress size={20} />}
      </Grid>
    </>
  );
};

export default StatusFilterItem;
