/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { AppBar, Menu, MenuItem, IconButton, Button, Grid, TablePagination, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import FiltersStore from '../../../Stores/Fulfillment/Filters/Store';
import ActionsMenu from './ActionsMenu';
import FilterDrawer from './FilterDrawer';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import CustomersStore from '../../../Stores/Fulfillment/Customers/Store';
import StoresStore from '../../../Stores/Fulfillment/Stores/Store';
import { loadCustomers } from '../../../Stores/Fulfillment/Customers/Actions';
import { loadStores } from '../../../Stores/Fulfillment/Stores/Actions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
  },
}));

function useQuery() {
  return queryString.parse(useLocation().search);
}

const PageToolbar = ({ selected }) => {
  const [customers, setCustomers] = useState([]);
  const [stores, setStores] = useState([]);
  const [filters, setFilters] = useState({ ...FiltersStore.getFilters() });
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQuery();

  const getCustomers = () => {
    setCustomers([...CustomersStore.getCustomers()]);
  };
  const getStores = () => {
    setStores([...StoresStore.getStores()]);
  };
  // todo: maybe get rid of these stores and just use a one time get call?
  useEffect(() => {
    CustomersStore.on('change', getCustomers);
    StoresStore.on('change', getStores);
    if (CustomersStore.getCustomers().length) {
      getCustomers();
    } else {
      loadCustomers();
    }
    if (StoresStore.getStores().length) {
      getStores();
    } else {
      loadStores();
    }
    return () => {
      CustomersStore.removeListener('change', getCustomers);
      StoresStore.removeListener('change', getStores);
    };
  }, []);

  useEffect(() => {
    FiltersStore.on('change', handleFilterChange);
    FiltersStore.on('countUpdated', handleFilterChange);
    return () => {
      FiltersStore.removeListener('change', handleFilterChange);
      FiltersStore.removeListener('countUpdated', handleFilterChange);
    };
  }, []);

  const updateSearch = (e) => {
    updateFilters({ search: e.target.value });
  };

  const handleFilterChange = () => {
    const newFilters = { ...FiltersStore.getFilters() };
    setFilters(newFilters);
  };

  const updateQueryParams = (label, value) => {
    const params = new URLSearchParams();
    console.log(queryParams);
    if (Object.keys(queryParams).length) {
      for (const key of Object.keys(queryParams)) {
        if (key !== label) {
          params.append(key, queryParams[key]);
        }
      }
    }
    params.append(label, value);
    history.push({ search: params.toString() });
  };

  const handlePageChange = (e, page) => {
    updateQueryParams('page', page);
    updateFilters({ page });
  };

  const handlePerPageChange = (e) => {
    updateQueryParams('rowsPerPage', e.target.value);
    updateFilters({ rowsPerPage: e.target.value });
  };

  return (
    <div>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item xs={4} alignItems="center" justify="flex-start">
              <h3 style={{ color: 'black' }}> Orders </h3>
            </Grid>
            <Grid item xs={8} container alignItems="center" justify="flex-end">
              <Grid item>
                <TextField
                  id="search"
                  size="small"
                  value={filters.search}
                  onChange={updateSearch}
                  label="Search"
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={() => {
                    updateFilters({
                      search: null,
                    });
                  }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component={'div'}
                  count={filters.count}
                  page={filters.page}
                  rowsPerPage={filters.rowsPerPage}
                  labelRowsPerPage={'Items/pg'}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerPageChange}
                />
              </Grid>
              <Grid item>
                <FilterDrawer customers={customers} stores={stores} />
              </Grid>
              <Grid item>
                <ActionsMenu selected={selected} />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PageToolbar;
