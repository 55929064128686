import React from 'react';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createDialog } from '../../../Stores/Dialog/Actions';
import Shipping from './Shipping';

const ActionsMenu = ({ selected }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openShipDialog = () => {
    handleClose();
    createDialog({
      content: <Shipping orders={selected} history={history} />,
      title: 'Ship fulfillment orders',
      size: 'sm',
    });
  };

  return (
    <>
      {selected?.length && (
        <>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Actions
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={openShipDialog}>Ship</MenuItem>
            <MenuItem onClick={handleClose}>Add to WorkOrder</MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

ActionsMenu.propTypes = {
  selected: PropTypes.array,
};

ActionsMenu.displayName = 'ActionsMenu';

export default ActionsMenu;
