import dispatcher from './dispatcher';
import { get, patch, post } from '../../../Functions/API';
import { createSnack } from '../../Snack/Actions';
import LineItemFiltersStore from '../../../Stores/Fulfillment/LineItemFilters/Store';
import { updateFilterCount } from '../LineItemFilters/Actions';
import { convertDateToSQLDate } from '../../../Components/Utils/SqlDate';
import { ProductPathsStore } from '../../index';

export const loadLineItems = () => {
  console.log('RAN LOAD LINE ITEMS');
  const filters = LineItemFiltersStore.getFilters();
  const filter = {
    eager: {
      status: {},
      ybaSku: {
        ybaProduct: {},
      },
      $where: {
        statusId: {
          $in: [3, 11, 4],
        },
        // 'order.storeId': 1,
      },
    },
  };

  if (filters.status) {
    filter.eager.$where['statusId'] = filters.status.id;
  }
  console.log('FILTERS', filters);
  if (filters.search) {
    const searchStr = `%${filters.search}%`;
    filter.eager.$where['$or'] = [
      { id: { $like: searchStr } },
      // { name: { $like: searchStr } },
    ];
  }
  const { page, rowsPerPage: pageSize } = filters;
  console.log('filter', filter);
  get('/fulfillment/lineItems', { filter, page, pageSize })
    .then(({ results, total }) => {
      console.log('Line item results', total);
      dispatcher.dispatch({
        type: 'LOAD_LINE_ITEMS',
        results,
      });
      updateFilterCount({ count: total });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateLines = (lineItems) => {
  return patch('/fulfillment/lineItems', { lineItems });
};

export const updateLineItem = (line) => {
  const { id: productStepId, statusId } = ProductPathsStore.findNextStep(line.productStepId);
  const { id } = line;
  return updateLines([{ id, productStepId, statusId }]);
};

export const rewindLineItem = (line, step) => {
  console.log(step);
  const { id: productStepId, statusId } = step;
  const { id } = line;
  return updateLines([{ id, productStepId, statusId }]);
};

export const updateLineItems = (lines) => {
  const newLines = lines.map((line) => {
    const { id: productStepId, statusId } = ProductPathsStore.findNextStep(line.productStepId);
    const { id } = line;
    return { id, productStepId, statusId };
  });
  return updateLines(newLines);
};
