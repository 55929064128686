import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.customer = {};
  }

  // customersArray
  getCustomer() {
    return this.customer;
  }

  loadCustomer = (customer) => {
    this.customer = customer;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_CUSTOMER': {
        this.loadCustomer(action.customer);
        break;
      }
      case 'GET_CUSTOMER': {
        this.getCustomer();
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
