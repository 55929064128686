/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import FuturePrints from './FuturePrints';
import TodaysPrints from './TodaysPrints';
import PrintsGraph from './PrintsGraph';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import PrintTypeChart from './PrintTypeChart';
import CurrentlyClockedIn from './CurrentlyClockedIn';
import CSPIncome from './CSPIncome';
import InventoryChecks from './InventoryChecks';

const ProductionHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <FuturePrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <TodaysPrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          <LatestWorkOrders {...props} refresh={props.refresh} />
          {/* TODO viewCSPIncomePage */}
          {props.user.group.includes('Admin') && (
            <Grid item xs={12}>
              <InventoryChecks />
            </Grid>
          )}
          {props.user.group.includes('Admin') && (
            <Grid item xs={12} md={6}>
              <div style={{ marginBottom: '10px' }}>
                <CSPIncome />
              </div>
              <CurrentlyClockedIn get={props.get} refresh={props.refresh} />
            </Grid>
          )}
          {/*<Grid item xs={12} md={6}>*/}
          {/*  <PrintTypeChart get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <PrintsGraph get={props.get} post={props.post} refresh={props.refresh} />*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default ProductionHome;
