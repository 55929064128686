/* eslint max-len:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unescaped-entities:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import { Card, CardContent, CardHeader, Grid, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import YBA from '../../images/yba-logo.png';

const QuoteFooter = (props) => {
  const cardStyle = {
    width: '350px',
    height: '200px',
    backgroundColor: 'rgb(234, 234, 234)',
    textAlign: 'center',
    boxShadow: 'none',
  };
  const itemStyle = {
    paddingTop: 0,
    paddingBottom: 0,
  };

  return (
    <Paper style={{ backgroundColor: 'rgb(234, 234, 234)', marginTop: '15px' }}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item style={itemStyle}>
          <Card style={cardStyle}>
            <CardHeader title="YBA Shirts" />
            <img src={YBA} alt="yba" style={{ width: '100px' }} />
            <CardContent>
              <div>"We make it EASY for you!"</div>
              <div>
                <Button href="https://ybashirts.com">www.ybashirts.com</Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item style={itemStyle}>
          <Card style={cardStyle}>
            <CardHeader
              title="Contact Info"
              subheader={
                props.salesman && (
                  <div>
                    <div>
                      {props.salesman.firstName} {props.salesman.lastName}
                    </div>
                    <div>
                      <div>1184 N Industrial Park Rd</div>
                      <div>Orem, UT 84057</div>
                      <div>1 (800) 825-4625</div>
                      <div>{props.salesman.email}</div>
                    </div>
                  </div>
                )
              }
            />
          </Card>
        </Grid>
        <Grid item style={itemStyle}>
          <Card style={cardStyle}>
            <CardHeader
              title="About Us"
              subheader="We specialize in manufacturing, printing, & customizing uniforms & company apparel for non-profit, recreational, private, jr. professional leagues, schools, and corporations across the country."
            />
          </Card>
        </Grid>
        <Grid item style={itemStyle}>
          <Card style={cardStyle}>
            <CardHeader
              title="Testimonials"
              subheader={
                <div>
                  <div>
                    “YBA Shirts has been our preferred vendor for years. They have the best prices around and always go the extra mile in fulfilling
                    our expectations!”
                  </div>
                  <div style={{ float: 'right' }}>-B. Dalton,YMCA</div>
                </div>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteFooter;
