import dispatcher from './dispatcher';
import { get } from '../../../../Functions/API';

const getData = (customerIds, year, ytdTotal, where) => {
  get(
    '/betterFinancialSummaryByCustomer',
    {
      year: year.getFullYear(),
      customerIds: JSON.stringify(customerIds.map((customer) => customer.id)),
    },
    'obj',
  ).then((results) => {
    dispatcher.dispatch({
      type: where,
      data: results,
    });
  });
};

export const loadData = (customerIds, year, ytdTotal) => {
  getData(customerIds, year, ytdTotal, 'LOAD_DATA');
};

export const newData = (customerIds, year, ytdTotal) => {
  console.log('new data');
  getData(customerIds, year, ytdTotal, 'LOAD_NEW_DATA');
};
