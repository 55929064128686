import dispatcher from './dispatcher';
import { get } from '../../Functions/API';
import Store from './Store';
import { createSnack } from '../Snack/Actions';

export const fetchPrintGroups = async () => {
  const currentData = Store.getPriceGroups();
  if (currentData?.length) {
    return currentData;
  } else if (Store.isFetching()) {
    const data = await Store.getPromise();
    return data;
  } else {
    try {
      const promise = get(`/print-groups`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        promise,
      });
      const data = await promise;
      dispatcher.dispatch({
        type: 'SET_DATA',
        data,
      });
      return data;
    } catch (err) {
      console.error('error');
      console.error(err);
      createSnack('Error', 'Problem loading price groups');
      return currentData;
    }
  }
};
