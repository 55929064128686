import React from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import Home from './Home';
import InventoryView from './InventoryView';
import Menu from './Menu';
import SKU from '../Inventory/SKU';

const CustomerInventory = () => {
  return (
    <div>
      <Menu />
      <Router>
        <Switch>
          <Route exact path={'/'}>
            <Home />
          </Route>
          <Route exact path={'/inventory/:inventoryId/skus/:skuId'}>
            <SKU />
          </Route>
          <Route path={'/inventory/:inventoryId'}>
            <InventoryView />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default CustomerInventory;
