/* eslint-disable react/display-name */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import MaterialTable from 'material-table';
import Switch from '@material-ui/core/Switch';
import WhatsNewFeatures from './WhatsNewFeatures';

const WhatsNew = forwardRef(({ get, post, getViews, createSnack }, ref) => {
  const [versions, setVersions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);

  const getNewFeatureVersions = () => {
    get('/getVisibleNewFeatureVersions')
      .then((results) => {
        let data = [...results.versions];
        for (const version of data) {
          version.viewed = 0;
          if (results.views.indexOf(version.id) !== -1) {
            version.viewed = 1;
          }
        }
        data = data.reverse();
        setVersions(data);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getNewFeatureVersions();
    getViews();
  }, []);

  const updateViewed = (event, rowData) => {
    event.preventDefault();
    event.stopPropagation();
    const dataCopy = [...versions];
    const index = dataCopy.indexOf(rowData);
    post('/updateViewed', { rowData })
      .then(() => {
        getViews();
        dataCopy[index].viewed = !rowData.viewed;
        setVersions(dataCopy);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const columns = [
    { field: 'version', title: 'Version', headerStyle: { textAlign: 'center', fontSize: '10' }, cellStyle: { textAlign: 'center' } },
    { field: 'date', type: 'date', title: 'Date', headerStyle: { fontSize: '10' } },
    { field: 'description', title: 'Description', headerStyle: { fontSize: '10' } },
    {
      field: 'viewed',
      title: 'Viewed',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center', fontSize: '10' },
      render: (rowData) => {
        return <Switch checked={Boolean(rowData.viewed)} value={Boolean(rowData.viewed)} onClick={(event) => updateViewed(event, rowData)} />;
      },
    },
  ];

  const getColor = (rowData) => {
    const selected = selectedRow === rowData.tableData.id;
    if (!rowData.viewed) {
      if (selected) {
        return '#66cc82';
      }
      return '#a6f2a6';
    }
    if (selected) {
      return '#EEE';
    }
    return '#FFF';
  };

  return (
    <div
      ref={ref}
      style={{
        margin: 'auto',
        marginTop: '4%',
        width: '75%',
        maxHeight: '75%',
        position: 'center',
        borderRadius: '4px',
        alignItems: 'center',
        paddingBottom: '1px',
        backgroundColor: 'white',
      }}
    >
      <MaterialTable
        title="Whats New To MSAS"
        columns={columns}
        data={versions}
        detailPanel={(row) => {
          return <WhatsNewFeatures versionId={row.id} get={get} createSnack={createSnack} />;
        }}
        options={{
          maxBodyHeight: '80vh',
          paging: false,
          rowStyle: (rowData) => ({
            backgroundColor: getColor(rowData),
          }),
          headerStyle: {
            backgroundColor: 'DimGray',
            color: 'White',
          },
        }}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
          setSelectedRow(rowData.tableData.id);
        }}
        isLoading={loading}
      />
    </div>
  );
});

export default WhatsNew;
