import { ProductPathsStore } from '../Stores';
import { get, patch, remove } from './API';

export const getEstimatedCost = (rowData) => {
  let cost = null;
  const { totalQuantity, shippingCost, perUnit } = rowData;
  if (totalQuantity && perUnit) {
    cost = totalQuantity * perUnit;
    if (shippingCost) cost = cost + shippingCost;
  }
  if (cost) {
    cost = cost.toFixed(2);
  }
  return cost;
};

export const formatDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

// todo: see which of these functions are needed vs which can be added into the $afterDelete for the costGroup

export const moveLineItemsToPreviousStep = async (lineItems) => {
  console.log('I Ran');
  const [firstItem] = lineItems;
  const { productStepId } = firstItem;
  // const sameProductStepId = lineItems.every((lineItem) => lineItem.productStepId === productStepId);
  const previousProductStep = ProductPathsStore.findPreviousStep(productStepId);
  const lineItemChanges = lineItems.map((item) => {
    return { id: item.id, productStepId: previousProductStep.id, statusId: previousProductStep.statusId };
  });
  await patch('/fulfillment/lineItems', { lineItems: lineItemChanges })
    .then((results) => {
      console.log('rollback results', results);
      return results;
    })
    .catch((err) => {
      console.log('rollback error', err);
      throw err;
    });
};

export const rollbackLineItems = async (lineItems) => {
  try {
    await moveLineItemsToPreviousStep(lineItems);
    await deleteCostGroupItemsByLineItems(lineItems);
  } catch (err) {
    throw err;
  }
};

export const deleteCostGroup = async (id) => {
  try {
    const costGroup = await get(`/fulfillment/costGroups/${id}`);
    const { items: lineItems } = costGroup;
    await rollbackLineItems(lineItems);
    await remove(`/fulfillment/costGroups/${id}`);
    return { success: true };
  } catch (err) {
    throw err;
  }
};

export const deleteCostGroupItemsByLineItems = async (lineItems) => {
  await remove('/fulfillment/costGroupItems', { items: lineItems })
    .then((results) => {
      console.log('deleteCostGroupItemsByLineItems results', results);
      return results;
    })
    .catch((err) => {
      console.log('deleteCostGroupItemsByLineItems results', err);
      throw err;
    });
};
