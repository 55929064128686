/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { get } from '../../Functions/API.js';
import MaterialTable from 'material-table';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded.js';
import { createDialog } from '../../Stores/Dialog/Actions.js';
import ShopifyInfoPlus from '../uploads/shopify-infoPlus.js';

const InfoPlusArt = () => {
  const [art, setArt] = useState([]);
  useEffect(() => {
    get('/integrations/info-plus/jobs', { filter: 'status eq Started and custom.type eq Art' }).then(setArt);
  }, []);

  useEffect(() => {
    console.log(art);
  }, [art]);

  return (
    <MaterialTable
      actions={[
        {
          icon: () => <WorkOutlineRoundedIcon />,
          tooltip: `Download File`,
          onClick: (event, rowData) => {
            let link = document.createElement('a');
            link.download = name;
            link.href = rowData.customFields.printUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          },
        },
      ]}
      columns={[{ title: 'URL', field: 'customFields.printUrl' }]}
      data={art}
    />
  );
};

export default InfoPlusArt;
