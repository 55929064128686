import React from 'react';
import PropTypes from 'prop-types';
import { get, post } from '../../../Functions/API';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import Grid from '@material-ui/core/Grid';
import ArtStudio from '../../ArtStudio/ArtStudio';
import theme from '../../Utils/theme';
import { ThemeProvider } from '@material-ui/styles';

const ArtHome = ({ user, location }) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <div className="dashboard-container">
          <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
            <Grid item xs={12}>
              <ArtStudio user={user} get={get} post={post} />
            </Grid>
            <Grid item xs={12}>
              <LatestWorkOrders user={user} location={location} get={get} post={post} />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ArtHome;

ArtHome.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};
