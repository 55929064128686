/* eslint no-alert:0 */
/* eslint no-unused-vars:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import CheckBox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      this.setState(results);
    });
  }

  setup = async () => {
    let users;
    let error = '';
    await this.props
      .get('/allUsers', [])
      .then((results) => {
        users = results;
      })
      .catch((err) => {
        error += 'Could not get all users';
      });
    if (users) {
      return {
        users,
      };
    }
    throw new Error(error);
  };

  updateActive = (event, user) => {
    const { users } = this.state;
    const index = users.indexOf(user);
    users[index].active = !users[index].active;
    this.props.post('/updateActive', { uid: users[index].uid, active: users[index].active }).then(() => {
      this.setState({
        users,
      });
    });
  };

  changeGroup = (event, uid) => {
    const { users } = this.state;
    const group = event.target.value;
    const user = users.find((u) => u.uid === uid);
    user.group = group;
    this.props.post('/updateGroup', { uid, group }).then(() => {
      this.setState({
        users,
      });
    });
  };

  render() {
    const columns = [
      {
        field: 'active',
        title: 'Active',
        render: (rowData) => (
          <CheckBox
            checked={Boolean(rowData.active)}
            value={rowData.active}
            onChange={(event) => this.updateActive(event, rowData)}
            color="primary"
          />
        ),
      },
      { field: 'firstName', title: 'First Name' },
      { field: 'lastName', title: 'Last Name' },
      {
        field: 'group',
        title: 'Group',
        render: (rowData) => (
          <select value={rowData.group} onChange={(event) => this.changeGroup(event, rowData.uid)}>
            <option>Admin</option>
            <option>Sales</option>
            <option>Sales Admin</option>
            <option>Owner</option>
            <option>Production Admin</option>
            <option>Production - Art</option>
            <option>Production - Front End</option>
            <option>Production - Fulfillment</option>
            <option>Production - Shipping</option>
            <option>Production - Printer</option>
            <option>Production - Back End</option>
            <option>Order Processor</option>
            <option>Accounting</option>
            <option>Accounting Admin</option>
          </select>
        ),
      },
      { field: 'email', title: 'Email' },
      {
        title: 'View',
        render: (rowData) => (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = `/admin/user/${rowData.uid}`;
            }}
            color="primary"
          >
            View
          </Button>
        ),
      },
    ];
    return (
      <Paper>
        <MaterialTable title="Users" columns={columns} data={this.state.users} />
      </Paper>
    );
  }
}

export default Users;
