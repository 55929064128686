import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Left from './Components/Left';
import Middle from './Components/Middle';
import Right from './Components/Right';

const Header = ({ workOrder }) => {
  return (
    <div style={{ zIndex: 10, padding: '10px', overflow: 'hidden' }}>
      <input id="file-input" type="file" name="name" style={{ display: 'none' }} />
      <Grid container spacing={3} justify="space-around">
        <Grid item md={4}>
          <Left workOrder={workOrder} />
        </Grid>
        <Grid item md={4}>
          <Middle workOrder={workOrder} />
        </Grid>
        <Grid item md={4}>
          <Right workOrder={workOrder} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;

Header.propTypes = {
  workOrder: PropTypes.object,
};
