import React, { useEffect } from 'react';
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import AddRemove from './AddRemove';

const InventoryChecks = () => {
  return (
    <Card>
      <CardHeader title={'Inventory Check'} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <AddRemove title={'Missing Add To'} url={'workorder/incompleteAddToInventory'} dest={'production/workorder/workOrderId/packaging'} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AddRemove
              title={'Missing Remove From'}
              url={'workorder/incompleteRemoveFromInventory'}
              dest={'production/workorder/workOrderId/count'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InventoryChecks;
