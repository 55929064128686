/* eslint react/display-name:0 */
/* eslint react/prop-types:0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { get, post, remove, patch } from '../../../../../Functions/API';
import { openQuickBooksPopUp } from '../../../../QuickBooks/functions';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import UserStore from '../../../../../Stores/User/Store';
import { convertDateToSQLDate } from '../../../../Utils/SqlDate';
import Costs from './Costs';
import CSPCosts from './CSPCosts';
import ShippingCosts from './ShippingCosts';
import RACosts from './RACosts';
import { createDialog } from '../../../../../Stores/Dialog/Actions';
import { useParams } from 'react-router-dom';
import InboundPackagesModal from './InboundPackagesModal';
import { checkQBAuth } from '../../../../../Stores/QuickBooks/Actions';
import { WorkOrderStore } from '../../../../../Stores';

const Costing = () => {
  const [costs, setCosts] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [cspVendor, setCspVendor] = useState({});

  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    get('/vendors/getByName/CSP')
      .then((results) => {
        setCspVendor(results);
      })
      .catch((err) => console.error(err));
  }, []);

  const user = UserStore.getUser();

  let actions = [];

  const estimatedToActual = (rowData) => {
    const data = [...costs];
    if (rowData.vendorID === cspVendor.id) {
      data[data.indexOf(rowData)].invoiceNumber = workOrder.workOrderID;
      data[data.indexOf(rowData)].invoiceDate = new Date();
    }
    checkAccountingApproved();
    patch(`/costing/${rowData.id}`, { totalActualCost: rowData.totalEstimatedCost })
      .then((results) => {
        data[data.indexOf(rowData)] = results;
        setCosts(data);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    //todo: save
  };

  const checkActualCosts = (rowData, func) => {
    if (user.hasPermission('editActualCosts')) {
      func(rowData);
    } else if (rowData.invoiceNumber || rowData.totalActualCost || rowData.invoiceDate) {
      createSnack('Error', 'Only Steve Can Edit This Row');
    } else {
      func(rowData);
    }
  };

  const splitBill = (rowData) => {
    let newCost = prompt('What is the new cost?');
    newCost = parseFloat(newCost);
    const row = { ...rowData };
    row.totalQuantity = null;
    row.perUnit = null;
    row.arrivalDate = null;
    row.invoiceNumber = null;
    row.totalActualCost = newCost.toFixed(2);
    row.totalEstimatedCost = 0;
    row.shippingCost = null;
    checkAccountingApproved();
    delete row.tableData;
    delete row.id;
    newRow(row);
  };

  if (user.group === 'Accounting Admin' || user.group === 'Admin') {
    actions = [
      {
        icon: 'file_copy',
        tooltip: 'Estimated to Actual',
        onClick: (event, rowData) => {
          checkActualCosts(rowData, estimatedToActual);
        },
      },
      {
        icon: 'call_split',
        tooltip: 'Split Bill',
        onClick: (event, rowData) => {
          checkActualCosts(rowData, splitBill);
        },
      },
    ];
  }

  if (user.group === 'Order Processor' || user.group === 'Admin') {
    actions.push({
      icon: 'local_shipping',
      tooltip: 'Shipment Information',
      onClick: (event, rowData) => {
        createDialog({
          content: <InboundPackagesModal WorkOrderId={workOrder.id} costingID={rowData.id} />,
          title: 'Inbound Packages',
          actions: [{ title: 'close', color: 'secondary', callback: null }],
          size: 'md',
        });
      },
    });
  }

  useEffect(() => {
    if (workOrder.id) {
      get('/getCosting', [{ name: 'workOrderID', value: workOrder.id }])
        .then(({ rows, submitted }) => {
          console.log(rows);
          setCosts(rows);
          setSubmitted(submitted);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
      if (user.group === 'Admin' || user.group.includes('Accounting')) {
        checkQBAuth().then(() => {
          createSnack('Success', 'Successfully linked to QB');
        });
      }
    }
  }, [workOrder.id]);

  const submit = () => {
    const numOfLines = [...costs].map((cost) => {
      if (cost.arrivalDate) cost.arrivalDate = convertDateToSQLDate(new Date(cost.arrivalDate));
      return cost;
    });
    post('/submitCosts', {
      numOfLines,
      workOrderID: workOrder.id,
      uid: user.uid,
    }).then(({ rows, submitted }) => {
      setCosts(rows);
      setSubmitted(submitted);
      createSnack('Success', 'Costs Updated');
    });
  };

  const checkAccountingApproved = () => {
    if (workOrder.accountingApproved) {
      // Taking this off temporarily to see if Steve wants to add it back
      // addNotification('Accounting', 'Costs have been edited after Accounting Approved was checked.', null, user, workOrder);
      // updateWorkOrder(workOrder.id, { closedDate: null, accountingApproved: !workOrder.accountingApproved });
    }
  };

  const removeRowWithPermissions = (oldData) => {
    const data = [...costs];
    if (oldData.id) {
      remove(`/costing/${oldData.id}`, { workOrderID: workOrder.id })
        .then(() => {
          data.splice(data.indexOf(oldData), 1);
          setCosts(data);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    } else {
      data.splice(data.indexOf(oldData), 1);
      setCosts(data);
    }
    checkAccountingApproved();
  };

  const removeRow = (oldData) => {
    checkActualCosts(oldData, removeRowWithPermissions);
  };

  const updateRowDetails = (newData, oldData) => {
    const data = [...costs];
    if (!newData.perUnit && newData.totalEstimatedCost) {
      newData.totalQuantity = 1;
      newData.perUnit = newData.totalEstimatedCost;
    }
    if (newData.perUnit !== null && newData.totalQuantity !== null) {
      newData.totalEstimatedCost = newData.perUnit * newData.totalQuantity;
    } else if (!newData.totalQuantity) {
      newData.totalEstimatedCost = 0;
    }
    if (newData.shippingCost || newData.shippingCost === 0) {
      newData.totalEstimatedCost += parseFloat(newData.shippingCost);
    }
    if (newData.totalActualCost) {
      newData.totalActualCost = parseFloat(newData.totalActualCost);
    } else {
      newData.totalActualCost = null;
    }
    if (newData.arrivalDate) newData.arrivalDate = convertDateToSQLDate(new Date(newData.arrivalDate));
    checkAccountingApproved();
    patch(`/costing/${newData.id}`, newData)
      .then((results) => {
        createSnack('Success', 'Updated Cost');
        data[data.indexOf(oldData)] = results;
        setCosts(data);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateRowWithPermissions = (newData, oldData) => {
    if (newData.id) {
      if (!newData.qbBillCreated) {
        updateRowDetails(newData, oldData);
      } else if (newData.qbBillCreated === 1) {
        if (user.group.includes('Accounting') || user.group.match('Admin')) {
          updateRowDetails(newData, oldData);
          updateBill(newData.id);
        } else {
          updateRowDetails(newData, oldData);
          addNotification('Accounting', 'A row with a bill in QuickBooks has been edited', null, user, workOrder);
        }
      }
    } else {
      newRow(newData);
    }
  };

  const updateRow = (newData, oldData) => {
    if (user.hasPermission('editActualCosts')) {
      updateRowWithPermissions(newData, oldData);
    } else if (oldData.invoiceNumber || oldData.totalActualCost || oldData.invoiceDate) {
      createSnack('Error', 'Only Steve Can Edit This Row');
    } else {
      updateRowWithPermissions(newData, oldData);
    }
  };

  const newRow = (newData) => {
    if (newData.perUnit !== null && newData.totalQuantity !== null) {
      newData.totalEstimatedCost = newData.perUnit * newData.totalQuantity;
    }
    if (newData.shippingCost) {
      newData.totalEstimatedCost += parseFloat(newData.shippingCost);
    }
    newData.totalEstimatedCost = parseFloat(newData.totalEstimatedCost);
    newData.workOrderID = workOrder.id;
    if (newData.arrivalDate) newData.arrivalDate = convertDateToSQLDate(new Date(newData.arrivalDate));
    post('/costing', [newData])
      .then((results) => {
        checkAccountingApproved();
        setCosts(results);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const resetTable = () => {
    get('/resetCosting', [
      { name: 'workOrderID', value: workOrder.id },
      {
        name: 'uid',
        value: user.uid,
      },
    ])
      .then(({ rows, submitted }) => {
        checkAccountingApproved();
        setCosts(rows);
        setSubmitted(submitted);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const removeAllCostingRows = (type) => {
    let c1, c2, c3;
    c1 = window.confirm('Are you sure you know what you are doing??');
    if (c1) {
      c2 = window.confirm(`You are about to delete ALL rows in the ${type} section are you sure you want to proceed???`);
      if (c2) {
        c3 = window.confirm('You actually want to delete ALL of them??? this CAN NOT be undone!!! Proceed at your own risk.....');
      }
    }
    if (c1 && c2 && c3) {
      post('/deleteAllCostForWorkOrder', { workOrderID: workOrder.id, type: type, uid: user.uid })
        .then((results) => {
          createSnack('Success', results.message);
          checkAccountingApproved();
        })
        .then(() => {
          resetTable();
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const getCosts = () => {
    get('/getCosting', [{ name: 'workOrderID', value: workOrder.id }])
      .then(({ rows, submitted }) => {
        setCosts(rows);
        setSubmitted(submitted);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const createBill = async (costId) => {
    let vendor;
    let url;
    let message;
    await get('/getVendorbyCost', [{ name: 'costId', value: costId }])
      .then((results) => {
        vendor = results.vendor;
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    if (vendor) {
      const { quickbooksId } = vendor;
      if (quickbooksId) {
        await get('/qbCheckDuplicateBill', [{ name: 'costId', value: costId }])
          .then((results) => {
            url = results.url;
            message = results.message;
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
        if (url) {
          openQuickBooksPopUp(url);
        } else {
          if (message === 'duplicate') {
            createDialog({
              content: 'A different bill already has this invoice number. Do you still want to create this bill?',
              title: 'Invoice Number Conflict',
              actions: [
                { title: 'no', color: 'secondary', callback: null },
                { title: 'yes', color: 'primary', callback: () => qbCreateBill(costId) },
              ],
            });
          } else {
            qbCreateBill(costId);
          }
        }
      }
    } else {
      createSnack('Error', 'Vendor Incorrect');
    }
  };

  const qbCreateBill = (costId) => {
    get('/qbCreateBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url } = results;
        const { message } = results;

        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'Bill Created in Quickbooks.');
          getCosts();
        } else {
          createSnack('Error', message.message);
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const deleteBill = (costId) => {
    get('/qbDeleteBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'The bill has been deleted from Quickbooks.');
          getCosts();
        } else if (message === 'paid') {
          createSnack('Warning', "This bill has a payment connected to it and can't be deleted.");
        } else {
          createSnack('Error', 'Error. The bill was not deleted from Quickbooks.');
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateBill = (costId) => {
    console.log('Update Bills');
    get('/qbUpdateBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'The bill has been updated in Quickbooks successfully!');
        } else {
          createSnack('Error', 'Error. The bill was not updated in Quickbooks.');
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <div>
      <Paper style={{ backgroundColor: '#f5f5f5' }}>
        <Costs
          costs={costs}
          setCosts={setCosts}
          checkAccountingApproved={checkAccountingApproved}
          submitted={submitted}
          setSubmitted={setSubmitted}
          updateRow={updateRow}
          newRow={newRow}
          removeRow={removeRow}
          workOrder={workOrder}
          convertDateToSQLDate={convertDateToSQLDate}
          removeAllCostingRows={removeAllCostingRows}
          actions={actions}
          submit={submit}
          cspVendor={cspVendor}
          resetTable={resetTable}
          getCosts={getCosts}
          createBill={createBill}
          updateBill={updateBill}
          deleteBill={deleteBill}
        />
        <CSPCosts
          costs={costs}
          workOrder={workOrder}
          updateRow={updateRow}
          newRow={newRow}
          removeRow={removeRow}
          user={user}
          removeAllCostingRows={removeAllCostingRows}
          actions={actions}
          submitted={submitted}
          submit={submit}
          cspVendor={cspVendor}
        />
        <ShippingCosts
          costs={costs}
          updateRow={updateRow}
          newRow={newRow}
          createBill={createBill}
          updateBill={updateBill}
          deleteBill={deleteBill}
          removeRow={removeRow}
          workOrder={workOrder}
          user={user}
          removeAllCostingRows={removeAllCostingRows}
          actions={actions}
          submitted={submitted}
          submit={submit}
        />
        {Boolean(workOrder.RA) && (
          <RACosts
            costs={costs}
            setCosts={setCosts}
            checkAccountingApproved={checkAccountingApproved}
            submitted={submitted}
            setSubmitted={setSubmitted}
            updateRow={updateRow}
            newRow={newRow}
            removeRow={removeRow}
            workOrder={workOrder}
            convertDateToSQLDate={convertDateToSQLDate}
            removeAllCostingRows={removeAllCostingRows}
            actions={actions}
            submit={submit}
            cspVendor={cspVendor}
            resetTable={resetTable}
          />
        )}
        <div
          style={{
            textAlign: 'right',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Estimated Costs: ${costs.reduce((total, line) => total + line.totalEstimatedCost, 0).toFixed(2)}
        </div>
        <div
          style={{
            textAlign: 'right',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Actual Sales Costs: $
          {costs
            .reduce((total, line) => {
              if (line.costAssignment === 'Sales') {
                // console.log(line);
                return total + (!line.totalActualCost || line.totalActualCost === '' ? 0 : parseFloat(line.totalActualCost));
              }
              return total;
            }, 0)
            .toFixed(2)}
        </div>
        {Boolean(workOrder.misprint) && (
          <div>
            <div
              style={{
                textAlign: 'right',
                paddingTop: '25px',
                paddingBottom: '10px',
                paddingRight: '10px',
              }}
            >
              Actual CSP Costs: $
              {costs
                .reduce((total, line) => {
                  if (line.costAssignment === 'CSP') {
                    return total + (!line.totalActualCost || line.totalActualCost === '' ? 0 : parseFloat(line.totalActualCost));
                  }
                  return total;
                }, 0)
                .toFixed(2)}
            </div>
            <div
              style={{
                textAlign: 'right',
                paddingTop: '25px',
                paddingBottom: '10px',
                paddingRight: '10px',
              }}
            >
              Actual Sales Assistant Costs: $
              {costs
                .reduce((total, line) => {
                  if (line.costAssignment === 'Sales Assistant') {
                    console.log(line);
                    return total + (!line.totalActualCost || line.totalActualCost === '' ? 0 : parseFloat(line.totalActualCost));
                  }
                  return total;
                }, 0)
                .toFixed(2)}
            </div>
          </div>
        )}
        <div
          style={{
            textAlign: 'right',
            color: 'red',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Difference: $
          {(
            costs.reduce((total, line) => total + (!line.totalActualCost || line.totalActualCost === '' ? 0 : parseFloat(line.totalActualCost)), 0) -
            costs.reduce(
              (total, line) => total + (!line.totalEstimatedCost || line.totalEstimatedCost === '' ? 0 : parseFloat(line.totalEstimatedCost)),
              0,
            )
          ).toFixed(2)}
        </div>
      </Paper>
    </div>
  );
};

export default Costing;

Costing.propTypes = {
  workOrder: PropTypes.object,
};
