import dispatcher from './dispatcher';
import FiltersStore from '../Filters/Store';
import { get } from '../../../Functions/API';
import { updateFilterCount } from '../Filters/Actions';

export const loadProducts = () => {
  const filters = FiltersStore.getFilters();
  const queryParams = { page: filters.page, pageSize: filters.rowsPerPage };
  const filter = {
    eager: {
      $where: {},
      ybaSkus: {
        productImages: {},
      },
    },
  };
  if (filters.storeId) {
    filter.eager.$where['store.id'] = filters.storeId;
  }
  queryParams.filter = filter;
  get('/fulfillment/products', queryParams, 'obj').then(({ results, total: count }) => {
    dispatcher.dispatch({
      type: 'LOAD_PRODUCTS',
      results,
    });
    updateFilterCount({ count });
  });
};
