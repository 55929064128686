/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-unused-vars:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

class ReplacementModal extends Component {
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          width: '90%',
          backgroundColor: '#ffffff',
          padding: '15px',
          height: '50%',
          overflowX: 'auto',
        }}
      >
        <Typography>Choose a replacement customer for: {this.props.replacementCustomer.name}</Typography>
        <Autocomplete
          options={this.props.customersWithQuickBooksIds}
          getOptionLabel={(option, value) => {
            return option.name;
          }}
          onChange={(event, value) => this.props.updateReplacementCustomer(event, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Replacement Customer"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: '8px', width: '300px' }}
              margin="normal"
              component="div"
            />
          )}
        />
        <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={this.props.updateWorkOrdersWithNewCustomer}>
          Submit
        </Button>
      </div>
    );
  }
}

export default ReplacementModal;
