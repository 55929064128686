/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Select, MenuItem, TextField, InputLabel, Input, ListItemText, FormControl } from '@material-ui/core';
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import BetterFinancialGraphByCustomer from './BetterFinancialGraphByCustomer';
import { newData, loadData } from './Actions';
import { getUsersByGroup } from '../../../../Stores/Users/Actions';
import { getCreatedTime } from '../../../../Functions/LocalStorage';
import RefreshIcon from '@material-ui/icons/Refresh';

const CustomerComparison = ({ get, user }) => {
  const [customers, setCustomers] = useState([]);
  const [year, setYear] = useState(new Date());
  const [customerIds, setCustomerIds] = useState([]);
  const [salesmen, setSalesmen] = useState([]);
  const [sales, setSales] = useState('');
  const [createdTime, setCreatedTime] = useState(getCreatedTime('customerComparison'));
  const [ytdTotal, setYtdTotal] = useState(true);

  useEffect(() => {
    get('/getCustomers').then((results) => {
      setCustomers(results.customers);
    });
    getUsersByGroup('Sales').then((results) => {
      results.push({ uid: 'salesGroup', firstName: 'Sales', lastName: 'Team' });
      results.push({ uid: 'leadershipGroup', firstName: 'Leadership', lastName: 'Team' });
      setSalesmen(results);
    });
  }, []);

  useEffect(() => {
    // TODO seeTopFiveCustomersOverall
    if (user.group === 'Admin') {
      get('/getTop5Customers', { year: year.getFullYear() }, 'obj').then((results) => {
        setCustomerIds(results);
      });
    } else {
      const { uid } = user;
      get('/getTop5CustomersBySalesman', { uid, year: year.getFullYear() }, 'obj').then((results) => {
        setCustomerIds(results);
      });
    }
  }, [year]);

  const getData = (year) => {
    // TODO seeTopFiveCustomersOverall
    if (user.group === 'Admin') {
      get('/getTop5Customers', { year: year.getFullYear() }, 'obj').then((results) => {
        newData(results, year, ytdTotal);
        setCustomerIds(results);
      });
    } else {
      const { uid } = user;
      get('/getTop5CustomersBySalesman', { uid, year: year.getFullYear() }, 'obj').then((results) => {
        newData(results, year, ytdTotal);
        setCustomerIds(results);
      });
    }
  };

  const refresh = () => {
    const date = new Date();
    setYear(date);
    setCreatedTime(date.getTime());
    // TODO seeTopFiveCustomersOverall
    if (user.group === 'Admin') {
      get('/getTop5Customers', { year: date.getFullYear() }, 'obj').then((results) => {
        loadData(results, date, ytdTotal);
        setCustomerIds(results);
      });
    } else {
      const { uid } = user;
      get('/getTop5CustomersBySalesman', { uid, year: date.getFullYear() }, 'obj').then((results) => {
        loadData(results, date, ytdTotal);
        setCustomerIds(results);
      });
    }
  };

  const updateCustomerIds = (value) => {
    setCustomerIds(value);
  };

  const updateSales = (value) => {
    setSales(value);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Grid container spacing={1} style={{ width: '100%' }}>
            <Grid item style={{ flexGrow: 1 }}>
              <div>Customer Comparison</div>
            </Grid>
            {/* TODO customerComparisonOver10KSalesmanPicker */}
            {(user.group === 'Admin' || user.uid === 'WJU8lxxoytVWHEVQGGdCCcEcXLb2') && (
              <Grid item>
                <FormControl style={{ width: '100%' }} component="div">
                  <InputLabel shrink htmlFor="sales">
                    Sales
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      updateSales(e.target.value);
                      getData(e.target.value, year);
                    }}
                    value={sales}
                    displayEmpty
                    input={<Input name="sales" id="sales" />}
                  >
                    <MenuItem value="">Over $10,000 GP</MenuItem>
                    {salesmen.map((salesman, key) => (
                      <MenuItem key={key} value={salesman.uid}>
                        <ListItemText primary={`${salesman.firstName} ${salesman.lastName}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year']}
                  label="Year"
                  value={year}
                  onChange={(y) => {
                    setYear(y);
                    getData(y);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  style={{ marginTop: 0 }}
                  readOnly
                  margin="normal"
                  id="time-picker"
                  label="Last Updated"
                  keyboardIcon={<RefreshIcon fontSize={'small'} onClick={refresh} />}
                  value={createdTime}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Autocomplete
          multiple
          style={{ width: '100%' }}
          id="tags-standard"
          options={customers}
          value={customerIds}
          onChange={(e, value) => {
            updateCustomerIds(value);
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} style={{ width: '100%' }} InputLabelProps={{ shrink: true }} variant="standard" label="Customers" />
          )}
        />
        {Boolean(customerIds.length) && (
          <BetterFinancialGraphByCustomer get={get} customerIds={customerIds} year={year} ytdTotal={ytdTotal} setYtdTotal={setYtdTotal} />
        )}
      </CardContent>
    </Card>
  );
};

export default CustomerComparison;
