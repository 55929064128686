import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../Functions/API.js';
import MaterialTable from 'material-table';

const CSPWorkOrdersByWeek = ({ week, year }) => {
  const [workOrders, setWorkOrders] = useState([]);

  useEffect(() => {
    get('/workorder/csp-reviewed-date-week', { week, year }).then(setWorkOrders);
  }, []);

  const columns = [
    { title: 'WO', field: 'workOrderID' },
    { title: 'Customer', field: 'customerName' },
    { title: 'Salesmen', field: 'salesmen' },
    { title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
    { title: 'CSP Cost Reviewed Date', field: 'cspCostReviewedDate', type: 'date' },
    {
      title: 'CSP Costs',
      field: 'cspCosts',
      type: 'currency',
    },
  ];

  return (
    <div>
      <MaterialTable
        title={`Work Orders For Week ${week}`}
        columns={columns}
        data={workOrders}
        options={{ showTitle: false, padding: 'dense', paging: false, maxBodyHeight: '500px', exportButton: true }}
        onRowClick={(e, { id }) => window.open(`/workorder/${id}`)}
      />
    </div>
  );
};

CSPWorkOrdersByWeek.propTypes = {
  week: PropTypes.number,
  year: PropTypes.number,
};

export default CSPWorkOrdersByWeek;
