import React from 'react';
import PropTypes from 'prop-types';

function SizesCell({ dataRow, usePackagedSizes }) {
  const oldSizes = (os) => {
    try {
      const sizes = JSON.parse(os);
      return <div>{sizes.map(({ size, quantity }) => `${quantity} ${size.toUpperCase()}`).join(', ')}</div>;
    } catch (e) {
      return <div>{os}</div>;
    }
  };

  return (
    <>
      {!dataRow && <div />}
      {dataRow?.oldSizes
        ? oldSizes(dataRow.oldSizes)
        : Array.isArray(dataRow?.sizes) && (
            <div>
              {dataRow?.sizes.map((size) => `${Boolean(usePackagedSizes) ? size.packageNum : size.quantity} ${size.size.toUpperCase()}`).join(', ')}
            </div>
          )}
      {!Array.isArray(dataRow?.sizes) && typeof dataRow?.sizes === 'string' && <div>{dataRow?.sizes}</div>}
    </>
  );
}

SizesCell.propTypes = {
  dataRow: PropTypes.any,
  usePackagedSizes: PropTypes.any,
};

export default SizesCell;
