import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AppBar, Button, Grid, TablePagination, Toolbar } from '@material-ui/core';
import Papa from 'papaparse';

import Product from '../../../Components/Fulfillment/Products/Product';
import NewProduct from './new';
import ProductPage from './product';
import FiltersStore from '../../../Stores/Fulfillment/Filters/Store';
import ProductsStore from '../../../Stores/Fulfillment/Products/Store';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import { loadProducts } from '../../../Stores/Fulfillment/Products/Actions';
import { post, remove } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import { createDialog } from '../../../Stores/Dialog/Actions.js';
import DropZone from '../../../Components/WorkOrder/Footer/Components/Generate/DropZone.js';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({ ...FiltersStore.getFilters() });
  const [count, setCount] = useState(0);
  const query = useQuery();
  const [storeId] = useState(query.get('storeId'));

  useEffect(() => {
    updateFilters({ storeDisabled: false, customerDisabled: false, page: 0, storeId });
    FiltersStore.on('change', handleFilterChange);
    FiltersStore.on('countUpdated', handleCountChange);
    ProductsStore.on('change', getProducts);
    if (!ProductsStore.getProducts().length) {
      loadProducts();
    } else {
      getProducts();
    }
    return () => {
      FiltersStore.removeListener('change', handleFilterChange);
      FiltersStore.removeListener('countUpdated', handleCountChange);
      ProductsStore.removeListener('change', getProducts);
    };
  }, []);

  const handleFilterChange = () => {
    const newFilters = { ...FiltersStore.getFilters() };
    setFilters(newFilters);
  };

  const handleCountChange = () => {
    const { count } = FiltersStore.getFilters();
    setCount(count);
  };

  const adjustPageForProduct = () => {
    const { page, rowsPerPage } = filters;
    if (count + 1 > (page + 1) * rowsPerPage - 1) {
      updateFilters({ page: page + 1 });
    } else {
      loadProducts();
    }
  };

  const removeProduct = (productId) => {
    remove(`/fulfillment/products/${productId}`)
      .then(() => {
        loadProducts();
      })
      .catch((err) => {
        createSnack('Error', 'Error removing product');
      });
  };

  useEffect(() => {
    loadProducts();
  }, [filters]);

  const getProducts = () => {
    setProducts([...ProductsStore.getProducts()]);
  };

  return (
    <div>
      <Switch>
        <Route path={`/fulfillment/products/new`}>
          <NewProduct />
        </Route>
        <Route path={`/fulfillment/products/:id`}>
          <ProductPage />
        </Route>
        <Route path="/fulfillment/products">
          <div style={{ margin: '10px', paddingBottom: '60px' }}>
            <Grid container spacing={1} justify={'space-evenly'}>
              {products.map((product, key) => (
                <Grid item key={key}>
                  <Product product={product} adjustPageForProduct={adjustPageForProduct} removeProduct={removeProduct} />
                </Grid>
              ))}
            </Grid>
          </div>
          <AppBar position={'fixed'} color={'white'} style={{ bottom: 0, top: 'auto' }}>
            <Toolbar>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <Button size={'small'} disabled={!storeId} variant={'contained'} href={`/fulfillment/products/new?storeId=${storeId}`}>
                    Add Product
                  </Button>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    size={'small'}
                    disabled={!storeId}
                    variant={'contained'}
                    onClick={() =>
                      createDialog({
                        content: (
                          <div>
                            <DropZone
                              handleDrop={(files) => {
                                const [file] = files;
                                Papa.parse(file, {
                                  header: true,
                                  download: true,
                                  skipEmptyLines: true,
                                  complete: async ({ data }) => {
                                    try {
                                      const { success, errors, duplicateErrors } = await post('/fulfillment/externalSku', data);
                                      // console.log(results);
                                      // const { success, errors, duplicateErrors } = results;
                                      createSnack(
                                        'Success',
                                        `Created ${success.length} skus. ${errors.length} had errors. ${duplicateErrors.length} were just duplicates.`,
                                      );
                                    } catch (e) {
                                      createSnack('Error', "Couldn't make it work");
                                    }
                                  },
                                });
                              }}
                            />
                          </div>
                        ),
                      })
                    }
                  >
                    Import Blanks
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    count={count}
                    onChangePage={(e, page) => updateFilters({ page })}
                    page={filters.page}
                    rowsPerPage={filters.rowsPerPage}
                    onChangeRowsPerPage={(e) => updateFilters({ rowsPerPage: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Route>
      </Switch>
    </div>
  );
};

export default Products;
