/* eslint react/jsx-no-duplicate-props:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-alert:0 */
/* eslint no-console:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-shadow:0 */
/* eslint no-restricted-syntax:0 */
/* eslint prefer-const:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  IconButton,
  AppBar,
  Tooltip,
  TableContainer,
} from '@material-ui/core';
import { Assignment, Delete, Edit } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';
import EditNotes from './EditNotes';
import { closeDialog, createDialog } from '../../Stores/Dialog/Actions';
import { useParams } from 'react-router-dom';
import { UsersStore, UserStore } from '../../Stores';
import EditSkuDialog from './EditSkuDialog';
import Pending from './Pending';
import EditSizes from './EditSizes';
import Papa from 'papaparse';
const { tableToExcel } = require('../../Functions');

const SKU = () => {
  const { skuId } = useParams();
  const [sku, setSku] = useState({});
  const [data, setData] = useState([]);
  const [sizeColumns, setSizeColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [costModal, setCostModal] = useState(false);
  const [pendingSizes, setPendingSizes] = useState([]);
  const [removePending, setRemovePending] = useState([]);
  const [addPending, setAddPending] = useState([]);
  const [potentialSizes, setPotentialSizes] = useState([]);

  const smallCell = {
    fontSize: 'smaller',
  };
  const user = UserStore.getUser();

  useEffect(() => {
    setup();
  }, []);

  const getSku = async () => {
    const s = await get(`/sku/${skuId}`);
    setSku(s);
    return s;
  };

  const getPending = async () => {
    const filter = {
      eager: {
        sizes: {},
        workOrder: {},
        $where: {
          'workOrder.canceled': false,
          'workOrder.salesApproved': true,
          removeSKU: skuId,
          countFinished: 0,
        },
      },
    };
    const addFilter = {
      eager: {
        sizes: {},
        workOrder: {},
        $where: {
          'workOrder.canceled': false,
          'workOrder.salesApproved': true,
          addSKU: skuId,
          packagingFinished: 0,
        },
      },
    };
    const combinedSizes = [];
    const removePending = await get(`/orders`, { filter });
    removePending.map(({ sizes }) =>
      sizes.map(({ size, quantity }) => {
        const found = combinedSizes.find((s) => s.size === size);
        if (found) {
          found.quantity -= quantity;
        } else {
          combinedSizes.push({ size, quantity: quantity * -1 });
        }
      }),
    );
    const addPending = await get(`/orders`, { filter: addFilter });
    addPending.map(({ sizes }) =>
      sizes.map(({ size, quantity }) => {
        const found = combinedSizes.find((s) => s.size === size);
        if (found) {
          found.quantity += quantity;
        } else {
          combinedSizes.push({ size, quantity });
        }
      }),
    );
    return {
      combinedSizes,
      addPending,
      removePending,
    };
  };

  const sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.size);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.size);
      if (potentialSizeA && potentialSizeB) {
        const indexA = potentialSizes.indexOf(potentialSizeA);
        const indexB = potentialSizes.indexOf(potentialSizeB);
        return indexA - indexB;
      }
      return 1;
    });
  };

  const setup = async () => {
    const { sizes } = await getSku();
    const potentialSizes = await get('/allPotentialSizes', [{ name: 'type', value: 'type' }]);
    setPotentialSizes(potentialSizes);
    const { results } = await get('/skuHistory', {
      skuId,
    }).then((results) => {
      const newSizeColumns = [];
      for (const row of results) {
        for (const size of row.sizes) {
          const found = newSizeColumns.find((r) => r.size === size.size);
          if (!found) {
            newSizeColumns.push(size);
          }
        }
        row.date = new Date(row.date);
      }
      return { results, sizes };
    });
    const { combinedSizes, addPending, removePending } = await getPending();
    setPendingSizes(combinedSizes);
    setRemovePending(removePending);
    setAddPending(addPending);
    let allSizes = [...sizes.map(({ size }) => size)];
    removePending.map((r) => {
      allSizes = [...allSizes, ...r.sizes.map(({ size }) => size)];
    });
    addPending.map((r) => {
      allSizes = [...allSizes, ...r.sizes.map(({ size }) => size)];
    });
    const sizeColumnNames = new Set([...allSizes]);
    let sizeColumns = [...sizeColumnNames].map((size) => ({
      title: size,
      size,
      render: (rowData) => rowData.sizes?.find((s) => s.size === size)?.quantity,
    }));
    sizeColumns = sortSizesStart(sizeColumns, potentialSizes);
    setSizeColumns(sizeColumns);
    setData(results);
    setUsers([...UsersStore.getUsers()]);
  };

  const getUser = (uid) => {
    const newUser = users.find((u) => u.uid === uid);
    if (newUser) {
      return `${newUser.firstName} ${newUser.lastName}`;
    }
    return '';
  };

  const deleteRow = (row) => {
    const c = window.confirm('Are you sure you want to delete this update?');
    if (c) {
      post('/deleteSKUHistory', row)
        .then((results) => {
          createSnack('Success', 'Please Refresh to see the changes');
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const createCSV = () => {
    const currentQuantities = {
      history: '',
      category: 'Current Quantities',
      total: 0,
    };
    const pending = {
      category: 'Pending',
      total: 0,
    };
    const availableQuantities = {
      category: 'Available Quantities',
      total: 0,
    };
    const targetQuantities = {
      category: 'Target Quantities',
      total: 0,
    };
    const difference = {
      category: 'Difference',
      total: 0,
    };
    const costRow = {
      category: 'Cost',
    };
    const weightRow = {
      category: 'Weight',
    };

    sku.sizes.map(({ size, quantity, target, cost, weight }) => {
      currentQuantities[size] = quantity;
      currentQuantities.total += quantity;
      targetQuantities[size] = target;
      targetQuantities.total += target;
      difference[size] = quantity - target;
      difference.total += difference[size];
      costRow[size] = cost;
      weightRow[size] = weight;
    });

    pendingSizes.map(({ size, quantity }) => (pending[size] = quantity));

    sku.sizes.map(({ size, quantity }) => {
      const found = pendingSizes.find(({ size: s }) => size === s);
      if (found) {
        availableQuantities[size] = quantity + found.quantity;
      } else {
        availableQuantities[size] = quantity;
      }
    });

    const history = data.map(({ sizes, notes, date, viewableId }) => {
      const row = { category: date.toLocaleDateString(), notes, history: viewableId, total: 0 };
      sizes.map(({ size, quantity }) => {
        row[size] = quantity;
        row.total += quantity;
      });
      return row;
    });
    currentQuantities.notes = '';
    const exportData = [currentQuantities, pending, availableQuantities, targetQuantities, difference, costRow, weightRow, ...history];
    const csvContent = Papa.unparse(exportData, { header: true });
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${sku?.name}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" noWrap style={{ marginRight: '10px', flexGrow: 1 }}>
            {sku.name} - {sku.sku}
          </Typography>
          <Button
            variant="contained"
            size={'small'}
            onClick={createCSV}
            // onClick={() => tableToExcel(document.querySelector('#skuTable'), `${sku.name} ${sku.sku} History`, `${sku.name} ${sku.sku} History.xls`)}
          >
            Download
          </Button>
        </Toolbar>
      </AppBar>
      <Paper>
        <TableContainer style={{ maxHeight: '100%' }}>
          <Table stickyHeader id={'skuTable'}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '8%' }}>Action</TableCell>
                <TableCell style={{ width: '9%' }}>Category</TableCell>
                {sizeColumns.map((size, key) => (
                  <TableCell key={key}>{size.size}</TableCell>
                ))}
                <TableCell>Total</TableCell>
                <TableCell style={{ width: '15%' }}>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {!user.groups.find(({ name }) => name.includes('Customer')) && (
                    <IconButton
                      size={'small'}
                      onClick={() =>
                        createDialog({
                          title: `Update Quantities for ${sku.sku}`,
                          content: <EditSizes rowData={sku} updateRow={setup} index={0} />,
                        })
                      }
                    >
                      <Edit fontSize={'small'} />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell style={smallCell}>Current Quantities</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = sku.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={smallCell}>
                          {found.quantity}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key}>0</TableCell>;
                  })}
                <TableCell style={smallCell}>{sku && sku.sizes && sku.sizes.reduce((total, size) => total + size.quantity, 0)}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IconButton
                    size={'small'}
                    onClick={() =>
                      createDialog({
                        title: 'Pending Updates',
                        content: (
                          <Pending
                            addPending={addPending}
                            removePending={removePending}
                            sizes={sizeColumns}
                            ps={potentialSizes}
                            sortSizes={sortSizesStart}
                          />
                        ),
                        size: 'xl',
                        actions: [{ title: 'Close', callback: closeDialog }],
                      })
                    }
                  >
                    <Assignment fontSize={'small'} />
                  </IconButton>
                </TableCell>
                <TableCell style={smallCell}>Pending</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = pendingSizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={smallCell}>
                          {found.quantity}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key}>0</TableCell>;
                  })}
                <TableCell style={smallCell}>{pendingSizes?.reduce((total, size) => total + size.quantity, 0) ?? 0}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontSize: 'medium' }}>Available Quantities</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const skuSize = sku.sizes.find((size) => size.size === column.size);
                    const found = pendingSizes.find((size) => size.size === column.size);
                    if (skuSize && found) {
                      return (
                        <TableCell key={key} style={{ fontSize: 'medium', color: skuSize.quantity + found.quantity < 0 ? 'red' : 'black' }}>
                          {skuSize.quantity + found.quantity}
                        </TableCell>
                      );
                    }
                    if (found) {
                      return (
                        <TableCell key={key} style={{ fontSize: 'medium', color: found.quantity < 0 ? 'red' : 'black' }}>
                          {found.quantity}
                        </TableCell>
                      );
                    }
                    if (skuSize) {
                      return (
                        <TableCell key={key} style={{ fontSize: 'medium', color: skuSize.quantity < 0 ? 'red' : 'black' }}>
                          {skuSize.quantity}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={key} style={{ fontSize: 'medium' }}>
                        0
                      </TableCell>
                    );
                  })}
                <TableCell
                  style={{
                    fontSize: 'medium',
                    color:
                      sku?.sizes?.reduce((total, size) => total + size.quantity, 0) +
                        pendingSizes?.reduce((total, size) => total + size.quantity, 0) <
                      0
                        ? 'red'
                        : 'black',
                  }}
                >
                  {sku?.sizes?.reduce((total, size) => total + size.quantity, 0) + pendingSizes?.reduce((total, size) => total + size.quantity, 0)}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      console.log('clicked');
                      createDialog({
                        title: 'Edit Sizes',
                        content: <EditSkuDialog sku={sku} ps={potentialSizes} />,
                        disableBackdropClick: true,
                        size: 'lg',
                        actions: [
                          {
                            title: 'Close',
                            callback: () => {
                              closeDialog();
                              setup();
                            },
                          },
                        ],
                      });
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell style={smallCell}>Target Quantities</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = sku.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={smallCell}>
                          {found.target}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key} />;
                  })}
                <TableCell>{sku && sku.sizes && sku.sizes.reduce((total, size) => total + size.target, 0)}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell style={smallCell}>Difference</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = sku.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell
                          key={key}
                          style={{
                            ...smallCell,
                            ...(found.quantity - found.target < 0
                              ? {
                                  color: 'red',
                                }
                              : {}),
                          }}
                        >
                          {found.quantity - found.target}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key} />;
                  })}
                <TableCell
                  style={{
                    ...smallCell,
                    ...(sku?.sizes?.reduce((total, size) => total + size.quantity - size.target, 0) < 0
                      ? {
                          color: 'red',
                        }
                      : {}),
                  }}
                >
                  {sku?.sizes?.reduce((total, size) => total + size.quantity - size.target, 0)}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <IconButton />
                </TableCell>
                <TableCell style={smallCell}>Cost</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = sku.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={smallCell}>
                          ${found.cost.toFixed(2)}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key} />;
                  })}
                <TableCell />
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell style={smallCell}>Weight</TableCell>
                {sku &&
                  sku.sizes &&
                  sizeColumns.map((column, key) => {
                    const found = sku.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={smallCell}>
                          {found.weight.toFixed(2)} lb
                        </TableCell>
                      );
                    }
                    return <TableCell key={key} />;
                  })}
                <TableCell />
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: '500' }}>History</TableCell>
                {sizeColumns.map((column, key) => {
                  return <TableCell key={key} />;
                })}
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
              {data.map((row, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <IconButton
                          size={'small'}
                          onClick={() => {
                            createDialog({
                              content: <EditNotes row={row} setup={setup} />,
                              size: 'lg',
                            });
                          }}
                        >
                          <Edit fontSize={'small'} />
                        </IconButton>
                      </Grid>
                      {/* TODO deleteInventorySku */}
                      {user.hasPermission('deleteSkuHistory') && (
                        <Grid item>
                          <IconButton size="small" onClick={() => deleteRow(row)}>
                            <Delete fontSize="small" />
                          </IconButton>
                        </Grid>
                      )}
                      {/* TODO viewWorkOrderLinkInventorySkuPage */}
                      <Grid item>
                        {row.workOrderID ? (
                          !user.group.includes('Customer') ? (
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => window.open(`/workorder/${row.workOrderID}`, '_blank')}
                              variant="contained"
                            >
                              {row.viewableId}
                            </Button>
                          ) : (
                            <div>{row.viewableId}</div>
                          )
                        ) : (
                          <Tooltip
                            title={
                              <div>
                                <div>{getUser(row.uid)}</div>
                                <div>{row.notes}</div>
                              </div>
                            }
                          >
                            <Button size="small" color="primary" variant="contained">
                              Physical Count
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ width: '13%' }}>
                    {`${row.date.toDateString()} ${row.date.getHours() > 12 ? row.date.getHours() - 12 : row.date.getHours()}:${
                      row.date.getMinutes().toString().length < 2 ? `0${row.date.getMinutes()}` : row.date.getMinutes()
                    }`}
                  </TableCell>
                  {sizeColumns.map((column, key) => {
                    const found = row.sizes.find((size) => size.size === column.size);
                    if (found) {
                      return (
                        <TableCell key={key} style={row.misprint || row.spoilage ? { color: 'red' } : {}}>
                          {found.quantity}
                        </TableCell>
                      );
                    }
                    return <TableCell key={key} />;
                  })}
                  <TableCell style={row.misprint || row.spoilage ? { color: 'red' } : {}}>
                    {row.sizes.reduce((total, size) => total + size.quantity, 0)}
                  </TableCell>
                  <TableCell style={{ width: '12%', color: row.misprint || row.spoilage ? 'red' : 'black' }}>{row.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default SKU;
