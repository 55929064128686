import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const filter = createFilterOptions();

const InventoryAutocomplete = ({ options, callback, inventory }) => {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(null);

  const handleValueChange = (newInventory) => {
    callback(newInventory);
  };

  useEffect(() => {
    setValue(inventory);
  }, [inventory]);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue(newValue.inputValue);
          } else {
            handleValueChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Inventory"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '8px', width: '100%' }}
          />
        )}
      />
      {/* some Dialog to create inventory */}
    </React.Fragment>
  );
};

export default InventoryAutocomplete;

InventoryAutocomplete.propTypes = {
  callback: PropTypes.func.isRequired,
  initValue: PropTypes.string,
  num: PropTypes.number,
  line: PropTypes.object,
  inventory: PropTypes.object,
  setNumOfLines: PropTypes.func,
  options: PropTypes.array.isRequired,
};
