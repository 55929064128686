import React, { useEffect, useState } from 'react';
import { get } from '../../Functions/API';
import { Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Home = () => {
  const [companies, setCompanies] = useState([]);
  const history = useHistory();

  useEffect(() => {
    get('/getInventoryCompanies').then(setCompanies);
  }, []);

  return (
    <div>
      <Grid container spacing={1}>
        {companies.map((company, key) => (
          <Grid item key={key} xl={3}>
            <Card style={{ height: '100%' }}>
              <CardHeader title={company.name} />
              <CardContent>
                <List>
                  {company.inventories.map((inventory, key) => (
                    <ListItem key={key} button onClick={() => history.push(`/inventory/${inventory.id}`)}>
                      <ListItemText primary={inventory.name} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
