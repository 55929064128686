import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  getDefaultDialog = () => ({
    open: false,
    content: '',
    title: '',
    actions: [],
    error: false,
    fullWidth: null,
    maxWidth: null,
    disableBackdropClick: false,
  });

  constructor() {
    super();
    this.dialog = this.getDefaultDialog();
  }

  getDialog = () => {
    return this.dialog;
  };

  newDialog = (dialog) => {
    this.dialog = dialog;
    this.emit('change');
  };

  closeDialog = () => {
    this.dialog = this.getDefaultDialog();
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'NEW_DIALOG': {
        this.newDialog(action.dialog);
        break;
      }
      case 'CLOSE_DIALOG': {
        this.closeDialog();
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
