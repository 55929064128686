import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import { save, retrieve } from '../../Functions/LocalStorage';

class Store extends EventEmitter {
  constructor() {
    super();
    this.user = retrieve('user');
  }

  getUser() {
    if (this?.user) {
      this.user.hasPermission = this.hasPermission;
      this.user.isInGroup = this.isInGroup;
    }
    return this.user;
  }

  hasPermission = (label) => {
    const foundUserPermission = this.user.permissions.find((permission) => permission.label === label);
    if (foundUserPermission) return Boolean(foundUserPermission);

    for (const group of this.user.groups) {
      for (const permission of group.permissions) {
        if (permission.label === label) return true;
      }
    }
  };

  isInGroup = (groupName) => {
    const group = this?.user?.groups?.find((group) => group.name === groupName);
    return Boolean(group);
  };

  safeParse = (obj, key) => {
    try {
      const value = JSON.parse(obj[key]);
      return value;
    } catch (err) {
      console.log(key);
      console.error(err);
      return [];
    }
  };

  loadUser = (user, emit) => {
    if (typeof user === 'object') {
      user.artFilter = this.safeParse(user, 'artFilter');
      user.filter = this.safeParse(user, 'filter');
      user.uncheckedFilter = this.safeParse(user, 'uncheckedFilter');
      this.user = user;
      if (user.active) {
        save('user', user);
      }
    }
    this.emit(emit);
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_USER': {
        this.loadUser(action.user, 'change');
        break;
      }
      case 'UPDATE_USER': {
        this.loadUser(action.user, 'update');
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
