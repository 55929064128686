import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Button, DialogActions, TextField, Toolbar } from '@material-ui/core';
import { patch } from '../../Functions/API';
import { closeDialog } from '../../Stores/Dialog/Actions';

const EditNotes = ({ row, setup }) => {
  const [notes, setNotes] = useState(row.notes);
  const submitNotes = () => {
    const { id } = row;
    patch(`/sku/update/${id}`, { notes }).then(() => {
      setup();
      closeDialog();
    });
  };
  return (
    <div>
      <TextField style={{ width: '100%' }} label={'Notes'} multiline value={notes} onChange={(e) => setNotes(e.target.value)} />
      <DialogActions>
        <Button size={'small'} variant={'contained'} onClick={closeDialog}>
          Cancel
        </Button>
        <Button size={'small'} variant={'contained'} color={'primary'} onClick={submitNotes}>
          Submit
        </Button>
      </DialogActions>
    </div>
  );
};

export default EditNotes;

EditNotes.propTypes = {
  row: PropTypes.object,
  setup: PropTypes.func,
};
