import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const Description = ({ workOrderID, description, editable }) => {
  const [newDescription, setNewDescription] = useState('');

  useEffect(() => {
    setNewDescription(description);
  }, [description]);

  const save = () => {
    updateWorkOrder(workOrderID, { description: newDescription });
  };

  return (
    <TextField
      id="description"
      label="Description"
      value={newDescription ? newDescription : ''}
      multiline
      style={{ margin: '8px', width: '100%' }}
      onChange={(e) => setNewDescription(e.target.value)}
      margin="normal"
      component="div"
      InputLabelProps={{
        shrink: true,
      }}
      disabled={!editable}
      error={description !== newDescription}
      helperText={description !== newDescription && 'Remember to save'}
      InputProps={{
        endAdornment: description !== newDescription && (
          <InputAdornment position="end">
            <IconButton onClick={save}>
              <SaveIcon
                style={{
                  color: 'red',
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Description;

Description.propTypes = {
  workOrderID: PropTypes.number,
  description: PropTypes.string,
  editable: PropTypes.bool,
};
