import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid, Card, CardMedia, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, patch, post, remove } from '../../../../Functions/API';
import { createSnack } from '../../../../Stores/Snack/Actions';
import { fetchUsers } from '../../../../Stores/Users/Actions';

const Permission = ({ isEdit }) => {
  const { id } = useParams();
  const history = useHistory();
  const [permission, setPermission] = useState(null);
  const [groups, setGroups] = useState(null);
  const [users, setUsers] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isEdit) {
      get(`/permissions/${id}`).then(setPermission);
    } else {
      setPermission({});
    }
    fetchUsers().then(setUsers);
    get('/groups').then(setGroups);
  }, []);

  useEffect(() => {
    if (groups?.length && permission && users?.length) {
      setLoaded(true);
    }
  }, [groups, users, permission]);

  const usersChangeHandler = (e, values) => {
    setPermission({ ...permission, users: values });
  };

  const groupsChangeHandler = (e, values) => {
    setPermission({ ...permission, groups: values });
  };

  const savePermission = () => {
    patch(`/permissions/${id}`, permission).then(() => {
      createSnack('Success', 'Saved permission');
      history.push(`/developer-tools/permissions`);
    });
  };

  const updateLabel = (e) => {
    setPermission({ ...permission, label: e.target.value });
  };

  const createPermission = () => {
    post(`/permissions`, permission).then(() => {
      createSnack('Success', 'Created permission');
      history.push(`/developer-tools/permissions`);
    });
  };

  const removePermission = () => {
    remove(`/permissions/${id}`).then(() => {
      history.push(`/developer-tools/permissions`);
    });
  };

  return (
    <Grid style={{ marginTop: '10px', padding: '10px' }} container justify="center">
      <Grid item xs={12} sm={6}>
        <Card>
          <CardMedia style={{ padding: '10px' }}>
            {isEdit ? (
              <Typography gutterBottom variant="h5" component="h2">
                {permission?.label}
              </Typography>
            ) : (
              <TextField id="label-permission" onChange={updateLabel} style={{ width: '100%' }} label="Label" value={permission?.label} />
            )}
            {loaded && (
              <>
                <Autocomplete
                  multiple
                  id="users"
                  options={users}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  onChange={usersChangeHandler}
                  value={permission?.users}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Users" placeholder="Users" />}
                />
                <Autocomplete
                  multiple
                  id="groups"
                  options={groups}
                  getOptionLabel={(option) => option.name}
                  onChange={groupsChangeHandler}
                  value={permission?.groups}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Groups" placeholder="Groups" />}
                />
                <Grid style={{ marginTop: '20px' }} container justify="center" spacing={1}>
                  {isEdit ? (
                    <>
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={savePermission}>
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="secondary" onClick={removePermission}>
                          Delete
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={createPermission}>
                        Create
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

Permission.propTypes = {
  isEdit: PropTypes.bool,
};

Permission.displayName = 'Permission';

export default Permission;
