import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm.js';
import { get } from '../../Functions/API.js';

const stripePromise = loadStripe('pk_test_CaDLt7ZxVeV11RtBne1UFgSi');

const Stripe = () => {
  // const [clientSecret, setClientSecret] = useState();
  const [options, setOptions] = useState();
  const { customerId } = useParams();

  useEffect(() => {
    get('/integrations/stripe/create-checkout-session', { customerId }).then((clientSecret) => {
      setOptions({ clientSecret });
    });
  }, []);

  return (
    <div>
      {Boolean(options) && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm />
        </Elements>
      )}
    </div>
  );
};

export default Stripe;
