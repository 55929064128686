const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

exports.hasPermission = (user, label) => {
  const foundPermission = user.permissions.find((permission) => permission.label === label);
  return Boolean(foundPermission);
};

exports.tableToExcel = (table, name, filename) => {
  let uri = 'data:application/vnd.ms-excel;base64,',
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(decodeURIComponent(encodeURIComponent(s)));
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };

  if (!table.nodeType) table = document.getElementById(table);
  const ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
  const link = document.createElement('a');
  link.download = filename;
  link.href = uri + base64(format(template, ctx));
  link.click();
};

exports.getCurrency = (num) => {
  return formatter.format(num);
};

exports.useQuery = (useLocation) => {
  return new URLSearchParams(useLocation().search);
};

const deepClone = (source) => {
  // If the source isn't an Object or Array, throw an error.
  if (!(source instanceof Object) || source instanceof Date || source instanceof String) {
    throw 'Only Objects or Arrays are supported.';
  }

  // Set the target data type before copying.
  var target = source instanceof Array ? [] : {};

  for (let prop in source) {
    // Make sure the property isn't on the protoype
    if (source instanceof Object && !(source instanceof Array) && !source.hasOwnProperty(prop)) {
      continue;
    }

    // If the current property is an Array or Object, recursively clone it, else copy it's value
    if (source[prop] instanceof Object && !(source[prop] instanceof Date) && !(source[prop] instanceof String)) {
      target[prop] = deepClone(source[prop]);
    } else {
      target[prop] = source[prop];
    }
  }

  return target;
};

exports.generateUniqueId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 100)}`;
};

exports.onlyUniqueByKey = (value, index, self, key) => {
  return self.findIndex((v) => v[key] === value[key]) === index;
};

exports.deepClone = deepClone;
