/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint no-alert:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/display-name:0 */
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import { Checkbox } from '@material-ui/core';

const AssignedWorkOrders = (props) => {
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const whichMonth = (rawDate) => {
    const date = new Date(rawDate);
    const index = date.getMonth();
    const day = date.getDate();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[index];
    return `${month} ${day}`;
  };

  const columns = [
    { field: 'workOrderID', title: 'Work Order', cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center', fontSize: '10' } },
    {
      title: 'Status',
      sorting: false,
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center', fontSize: '10' },
      // eslint-disable-next-line consistent-return
      render: (rowData) => {
        const date = new Date();
        const dueDate = new Date(rowData.dueDate);
        date.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        if (rowData.onHold) {
          return <div style={{ color: 'orange' }}>On Hold</div>;
        }
        if (rowData.canceled) {
          return <div>Canceled</div>;
        }
        if (rowData.misprint && !rowData.productionComplete) {
          return <div style={{ color: 'red' }}>REPRINT</div>;
        }
        if (date > dueDate && !rowData.productionComplete) {
          return <div style={{ color: 'red' }}>Overdue</div>;
        }
        if (date.getTime() === dueDate.getTime() && !rowData.productionComplete) {
          return <div>Due Today</div>;
        }
        if (rowData.productionComplete) {
          return <div>Production Complete</div>;
        }
      },
    },
    {
      field: 'dueDate',
      title: 'Due Date',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center', fontSize: '10' },
      render: (rowData) => {
        return whichMonth(rowData.dueDate);
      },
    },
    { field: 'printTotals', title: 'Prints', cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center', fontSize: '10' } },
    {
      field: 'foldAndPolyBag',
      title: 'Fold & Poly Bag',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center', fontSize: '10' },
      render: (rowData) => <Checkbox checked={rowData.foldAndPolyBag} />,
    },
    {
      field: 'heatPress',
      title: 'Heat Press',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center', fontSize: '10' },
      render: (rowData) => <Checkbox checked={rowData.heatPress} />,
    },
    { field: 'description', title: 'Description', cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center', fontSize: '10' } },
  ];

  const getWorkOrders = () => {
    props
      .get('/getWorkOrdersByPrinter2')
      .then((results) => {
        setWorkOrders(results);
        setLoading(false);
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getWorkOrders();
  }, []);

  return (
    <Paper>
      <MaterialTable
        title="Assigned Work Orders"
        columns={columns}
        data={workOrders}
        options={{
          rowStyle: {
            fontSize: 14,
          },
          color: 'DimGray',
        }}
        onRowClick={(event, rowData) => {
          window.open(`Production/workorder/${rowData.id}`, '_blank');
        }}
        isLoading={loading}
      />
    </Paper>
  );
};

export default AssignedWorkOrders;
