/* eslint-disable react/display-name */
/* eslint no-console:0 */
/* eslint no-unused-vars:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import Modal from '@material-ui/core/Modal';
import { months, monthsWithNumber } from '../../../Utils/monthsOfTheYear';

const SalesGoalsByMonthIndividual = ({ get, post, user }) => {
  const date = new Date();
  const [data, setData] = useState([]);
  const [salesDetails, setSalesDetails] = useState([]);
  const [salesTotals, setSalesTotals] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const getData = () => {
    const year = date.getFullYear();
    get('/individualSalesDataByMonth', { uid: user.uid, year }, 'obj')
      .then((results) => {
        console.log(results);
        setData(results);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const getDetails = (uid, month, year) => {
    console.log(uid, month, year);
    get('/salesDetailsThatProbablyExist', { uid, month, year }, 'obj')
      .then((returned) => {
        const { results, totals } = returned;
        setSalesDetails(results);
        setSalesTotals(totals);
        toggleShowDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const columns = [
    { title: 'Month', field: 'month', render: (rowData) => months[rowData.month - 1] },
    {
      title: 'Sales',
      field: 'sales',
      render: (rowData) => (parseFloat(rowData.sales) ? `$${numberWithCommas(rowData.sales)}` : rowData.sales),
    },
    {
      title: 'Costs',
      field: 'costs',
      render: (rowData) => (parseFloat(rowData.costs) ? `$${numberWithCommas(rowData.costs)}` : rowData.costs),
    },
    {
      title: 'GP',
      field: 'gp',
      render: (rowData) => (parseFloat(rowData.gp) ? `$${numberWithCommas(rowData.gp)}` : rowData.gp),
    },
    { title: '% GP', field: 'gpPercent', render: (rowData) => `${rowData.gpPercent}%` },
    {
      title: 'Goal',
      field: 'goal',
      render: (rowData) => (parseFloat(rowData.goal) ? `$${numberWithCommas(rowData.goal)}` : rowData.goal),
    },
  ];

  return (
    <Paper>
      <Modal style={{ top: '10%' }} open={showDetails} onClose={toggleShowDetails}>
        <div
          style={{
            width: '100%',
            height: '100%',
            outline: 'none',
            backgroundColor: 'white',
            overflowY: 'scroll',
          }}
        >
          <MaterialTable
            title={`${
              salesDetails.length && salesDetails[0] && salesDetails[0].firstName
                ? `${salesDetails[0].firstName}'s ${monthsWithNumber[salesDetails[0].month - 1][0]} Sales Details`
                : 'Sales Details'
            }`}
            columns={[
              { title: 'ID', field: 'workOrderID' },
              { title: 'Customer', field: 'customerName' },
              {
                title: 'Sales',
                field: 'bill',
                render: (rowData) => `$${rowData.bill.toFixed(2) ? rowData.bill.toFixed(2) : rowData.bill}`,
              },
              {
                title: 'Costs',
                field: 'cost',
                render: (rowData) => `$${rowData.cost.toFixed(2) ? rowData.cost.toFixed(2) : rowData.cost}`,
              },
              {
                title: 'Profit',
                field: 'profit',
                render: (rowData) => `$${rowData.profit.toFixed(2) ? rowData.profit.toFixed(2) : rowData.profit}`,
              },
              {
                title: 'Closed',
                field: 'accountingApproved',
                render: (rowData) => (
                  <Checkbox size="small" color="primary" value={Boolean(rowData.accountingApproved)} checked={Boolean(rowData.accountingApproved)} />
                ),
              },
              { title: 'Closed Date', field: 'closedDate', type: 'date' },
              {
                title: 'Paid',
                field: 'paidDate',
                render: (rowData) => (
                  <Checkbox size="small" color="secondary" checked={Boolean(rowData.paidDate)} value={Boolean(rowData.paidDate)} />
                ),
              },
              { title: 'Paid Date', field: 'paidDate', type: 'date' },
              {
                title: 'Written Off',
                field: 'writtenOff',
                render: (rowData) => (
                  <Checkbox size="small" color="secondary" checked={Boolean(rowData.writtenOff)} value={Boolean(rowData.writtenOff)} />
                ),
              },
            ]}
            data={salesDetails}
            onRowClick={(event, rowData) => {
              window.open(`/workorder/${rowData.id}`, '_blank');
            }}
            options={{
              search: false,
              paging: false,
              padding: 'dense',
              exportButton: true,
            }}
          />
        </div>
      </Modal>
      <MaterialTable
        title="Sales Details"
        columns={columns}
        data={data}
        options={{ paging: false, padding: 'dense', search: false }}
        onRowClick={(event, rowData) => getDetails(rowData.uid, rowData.month, rowData.year)}
      />
    </Paper>
  );
};

export default SalesGoalsByMonthIndividual;
