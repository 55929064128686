import dispatcher from './dispatcher';
import { get } from '../../Functions/API';
import StatesStore from './Store';
import { createSnack } from '../Snack/Actions';

export const fetchStates = async () => {
  const currentStates = StatesStore.getStates();
  if (currentStates?.length) {
    return currentStates;
  } else if (StatesStore.isFetching()) {
    const { states } = await StatesStore.getStatesPromise();
    return states;
  } else {
    try {
      const statesPromise = get(`/contacts/states`);
      dispatcher.dispatch({
        type: 'SET_FETCHING',
        statesPromise,
      });
      const { states } = await statesPromise;
      dispatcher.dispatch({
        type: 'SET_STATES',
        states,
      });
      return states;
    } catch (err) {
      createSnack('Error', 'Problem loading states');
      return currentStates;
    }
  }
};
