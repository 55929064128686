/* eslint react/display-name:0 */
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Checkbox } from '@material-ui/core';
import MaterialTable from 'material-table';
import { get } from '../../../Functions/API';
import { whichMonth } from '../../Utils/date';

const Upcoming = ({}) => {
  const columns = [
    { title: 'ID', field: 'workOrderID' },
    { title: 'Customer', field: 'customerName' },
    { title: 'Due Date', field: 'dueDate', render: (rowData) => (rowData.dueDate ? whichMonth(rowData.dueDate) : 'None') },
    { title: 'Prints', field: 'quantity' },
    { title: 'Heat Press', field: 'heatPress', render: (rowData) => <Checkbox checked={rowData.heatPress} style={{ padding: 0 }} size={'small'} /> },
    { title: 'Fold and PB', field: 'foldAndPB', render: (rowData) => <Checkbox checked={rowData.foldAndPB} style={{ padding: 0 }} size={'small'} /> },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    get('/art/upcoming').then((results) => {
      setData(results);
    });
  }, []);

  return (
    <Card>
      <CardHeader title={'Upcoming Heat Press or Fold and Poly Bag'} />
      <CardContent>
        <MaterialTable
          columns={columns}
          data={data}
          options={{
            toolbar: false,
            padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [10, 20],
            rowStyle: {
              fontSize: 14,
            },
          }}
          onRowClick={(event, rowData) => window.open(`/workorder/${rowData.id}`)}
        />
      </CardContent>
    </Card>
  );
};

export default Upcoming;

Upcoming.propTypes = {};
