/* eslint react/no-array-index-key:0 */
/* eslint react/no-children-prop:0 */
/* eslint no-plusplus:0 */
/* eslint radix:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-alert:0 */
/* eslint no-await-in-loop:0 */
/* eslint no-unused-expressions:0 */
/* eslint max-len:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ClockInAndOut from './ClockInAndOut';
import PackagingLine from './PackagingLine';
import Count from './Count';
import Numbers from '../Generate/Numbers';
import CountLine from './CountLine';
import PageToolbar from '../Generate/PageToolbar';
import Grouping from './Grouping';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import UserStore from '../../../../../Stores/User/Store';
import { addNotification } from '../../../../../Stores/Notifications/Actions';

class Packaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
      numOfLines: [],
      numbers: [],
      numberColumns: [],
      viewNumbers: false,
      page: 0,
      pageSize: 10,
      total: 0,
      filters: {},
      orderOptions: {},
      loading: true,
      groupBy: [
        { title: 'Type', field: 'type', selected: true },
        { title: 'Vendor', field: 'vendorID', selected: true },
        { title: 'Inventory', field: 'inventoryId', selected: true },
        { title: 'Use Inventory', field: 'useInventory', selected: true },
        { title: 'Add SKU', field: 'addSKU', selected: true },
        { title: 'Remove SKU', field: 'removeSKU', selected: true },
        { title: 'Adult Style', field: 'adultStyle', selected: true },
        { title: 'Youth Style', field: 'youthStyle', selected: true },
        { title: 'Color', field: 'color', selected: true },
        { title: 'Team', field: 'team', selected: true },
        { title: 'Notes', field: 'notes', selected: true },
        { title: 'Art', field: 'art', selected: true },
        { title: 'Number', field: 'number', selected: false },
        { title: 'Contact', field: 'contactId', selected: true },
      ],
    };
  }

  componentDidMount() {
    this.setup().then((results) => {
      this.setState(results);
    });
  }

  setup = async () => {
    let numOfLines;
    let orderOptions;
    let filters;
    let total;
    let potentialSizes;
    const loading = false;
    await get('/allPotentialSizes', [{ name: 'type', value: 'type' }])
      .then((results) => {
        potentialSizes = results;
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    await get('/getOrdersByVendorAndColor', [
      { name: 'workOrderID', value: this.props.workOrder.id },
      {
        name: 'page',
        value: 0,
      },
      {
        name: 'packaging',
        value: true,
      },
      { name: 'groupBy', value: JSON.stringify(this.state.groupBy) },
    ])
      .then(async (results) => {
        let ordersIDs = [];
        await get('/getCurrentClockIn', [
          { name: 'workOrderID', value: this.props.workOrder.id },
          {
            name: 'uid',
            value: this.state.user.uid,
          },
          {
            name: 'type',
            value: 'packaging',
          },
        ])
          .then((tempOrdersIDs) => {
            ordersIDs = tempOrdersIDs;
          })
          .catch((err) => {
            console.log(err);
          });
        numOfLines = results.data;
        orderOptions = results.options;
        total = results.total;
        filters = {};
        for (const option of Object.keys(orderOptions)) {
          filters[option] = '';
        }
        for (const result of numOfLines) {
          const checkClockIn = ordersIDs.find((ordersID) => {
            if (result.number) {
              return ordersID.ordersIDs === result.ordersIDs && ordersID.number === result.number;
            }
            return ordersID.ordersIDs === result.ordersIDs;
          });
          if (checkClockIn) {
            const date = new Date(checkClockIn.clockIn);
            result.clockIn = true;
            result.clockInTime = `${date.getHours()}:${date.getMinutes()}`;
            result.returnedId = checkClockIn.id;
          }
          result.sizes = this.sortSizesStart(result.sizes, potentialSizes);
          result.showArt = false;
          result.newPackagingSizes = [];
          for (const packagingSize of result.sizes) {
            packagingSize.errorModal = false;
            result.newPackagingSizes.push({
              size: packagingSize.size,
              quantity: 0,
            });
          }
          if (this.props.shipMethod === 'splitShip') {
            if (result.splitShipId) {
              if (result.splitShipId === -1) {
                result.shippingName = this.props.workOrder.customer;
                result.organization = this.props.workOrder.organization;
                result.co = this.props.workOrder.co;
                this.props.workOrder.shippingStreet
                  ? (result.shippingStreet = this.props.workOrder.shippingStreet)
                  : (result.shippingStreet = this.props.workOrder.billingStreet);
                this.props.workOrder.shippingCity
                  ? (result.shippingCity = this.props.workOrder.shippingCity)
                  : (result.shippingCity = this.props.workOrder.billingCity);
                this.props.workOrder.shippingState
                  ? (result.shippingState = this.props.workOrder.shippingState)
                  : (result.shippingState = this.props.workOrder.billingState);
                this.props.workOrder.shippingZip
                  ? (result.shippingZip = this.props.workOrder.shippingZip)
                  : (result.shippingZip = this.props.workOrder.billingZip);
              } else {
                await get('/getSplitShipById', [
                  {
                    name: 'splitShipId',
                    value: result.splitShipId,
                  },
                ])
                  .then((address) => {
                    result.shippingName = address.name;
                    result.organization = address.organization;
                    result.co = address.co;
                    result.shippingStreet = address.street;
                    result.shippingCity = address.city;
                    result.shippingState = address.state;
                    result.shippingZip = address.zip;
                  })
                  .catch((err) => {
                    window.alert(err.message);
                  });
              }
            }
          }
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
    return {
      numOfLines,
      orderOptions,
      filters,
      total,
      loading,
      potentialSizes,
    };
  };

  sortSizes = (sizes) => {
    return sizes.sort((a, b) => {
      const indexA = this.state.potentialSizes.findIndex((row) => row.size.toLowerCase() === a.size.toLowerCase());
      const indexB = this.state.potentialSizes.findIndex((row) => row.size.toLowerCase() === b.size.toLowerCase());
      return indexA - indexB;
    });
  };

  sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const indexA = potentialSizes.findIndex((row) => row.size.toLowerCase() === a.size.toLowerCase());
      const indexB = potentialSizes.findIndex((row) => row.size.toLowerCase() === b.size.toLowerCase());
      return indexA - indexB;
    });
  };

  getOrders = (page) => {
    this.setState({
      loading: true,
    });
    get('/getOrdersByVendorAndColor', [
      { name: 'workOrderID', value: this.props.workOrder.id },
      {
        name: 'page',
        value: page,
      },
      {
        name: 'filters',
        value: JSON.stringify(this.state.filters),
      },
      {
        name: 'packaging',
        value: true,
      },
      { name: 'groupBy', value: JSON.stringify(this.state.groupBy) },
    ])
      .then(async (results) => {
        let ordersIDs = [];
        await get('/getCurrentClockIn', [
          { name: 'workOrderID', value: this.props.workOrder.id },
          {
            name: 'uid',
            value: this.state.user.uid,
          },
          {
            name: 'type',
            value: 'packaging',
          },
        ])
          .then((tempOrdersIDs) => {
            ordersIDs = tempOrdersIDs;
          })
          .catch((err) => {
            console.log(err);
          });
        const numOfLines = results.data;
        const orderOptions = results.options;
        const { total } = results;
        for (const result of numOfLines) {
          const checkClockIn = ordersIDs.find((ordersID) => {
            if (result.number) {
              return ordersID.ordersIDs === result.ordersIDs && ordersID.number === result.number;
            }
            return ordersID.ordersIDs === result.ordersIDs;
          });
          if (checkClockIn) {
            const date = new Date(checkClockIn.clockIn);
            result.clockIn = true;
            result.clockInTime = `${date.getHours()}:${date.getMinutes()}`;
            result.returnedId = checkClockIn.id;
          }
          result.sizes = this.sortSizes(result.sizes);
          result.showArt = false;
          result.newPackagingSizes = [];
          for (const packagingSize of result.sizes) {
            packagingSize.errorModal = false;
            result.newPackagingSizes.push({
              size: packagingSize.size,
              quantity: 0,
            });
          }
          if (this.props.shipMethod === 'splitShip') {
            if (result.splitShipId) {
              if (result.splitShipId === -1) {
                result.shippingName = this.props.workOrder.customer;
                result.organization = this.props.workOrder.organization;
                result.co = this.props.workOrder.co;
                this.props.workOrder.shippingStreet
                  ? (result.shippingStreet = this.props.workOrder.shippingStreet)
                  : (result.shippingStreet = this.props.workOrder.billingStreet);
                this.props.workOrder.shippingCity
                  ? (result.shippingCity = this.props.workOrder.shippingCity)
                  : (result.shippingCity = this.props.workOrder.billingCity);
                this.props.workOrder.shippingState
                  ? (result.shippingState = this.props.workOrder.shippingState)
                  : (result.shippingState = this.props.workOrder.billingState);
                this.props.workOrder.shippingZip
                  ? (result.shippingZip = this.props.workOrder.shippingZip)
                  : (result.shippingZip = this.props.workOrder.billingZip);
              } else {
                await get('/getSplitShipById', [
                  {
                    name: 'splitShipId',
                    value: result.splitShipId,
                  },
                ])
                  .then((address) => {
                    result.shippingName = address.name;
                    result.organization = address.organization;
                    result.co = address.co;
                    result.shippingStreet = address.street;
                    result.shippingCity = address.city;
                    result.shippingState = address.state;
                    result.shippingZip = address.zip;
                  })
                  .catch((err) => {
                    window.alert(err.message);
                  });
              }
            }
          }
        }
        console.log(numOfLines);
        this.setState({
          numOfLines,
          orderOptions,
          total,
          loading: false,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  setPage = (page) => {
    this.setState(
      {
        page,
        numOfLines: [],
        loading: true,
      },
      () => this.getOrders(page),
    );
  };

  updateFilter = (filter, value) => {
    const { filters } = this.state;
    filters[filter] = value;
    this.setState(
      {
        page: 0,
        filters,
        loading: true,
      },
      () => this.getOrders(this.state.page),
    );
  };

  clearFilters = () => {
    const { filters } = this.state;
    for (const key of Object.keys(filters)) {
      filters[key] = '';
    }
    this.setState(
      {
        page: 0,
        filters,
        loading: true,
      },
      () => this.getOrders(this.state.page),
    );
  };

  updateQuantity = (quantity, size, position, type) => {
    const newLines = this.state.numOfLines;
    const { sizes } = newLines[position];
    sizes[sizes.indexOf(size)][type] = parseInt(quantity);
    this.setState({
      numOfLines: newLines,
    });
  };

  resetPackaging = (line) => {
    const { numOfLines } = this.state;
    const index = numOfLines.indexOf(line);
    const { sizes } = numOfLines[index];
    for (const size of sizes) {
      size.packageNum = 0;
    }
    const json = { line: numOfLines[index], type: 'packaging' };
    post('/resetProductionSizes', json).then((results) => {
      this.setState({
        numOfLines,
      });
    });
  };

  clockInLine = (line, status, finished) => {
    const { numOfLines } = this.state;
    const index = numOfLines.indexOf(line);
    numOfLines[index].clockIn = status;
    if (finished) {
      numOfLines[index].packagingFinished = 1;
      let check = false;
      for (const size of line.sizes) {
        if (size.quantity !== size.packageNum) {
          size.wrong = true;
          check = true;
        }
      }
      if (check) {
        const inventory = this.props.workOrder.orderType === 'inventory';
        let message = `Unexpected ${inventory ? 'amount added to inventory for' : 'count for'} ${line.color} ${line.vendor?.name?.toUpperCase()} ${
          line.youthStyle && line.youthStyle
        }${line.youthStyle && line.adultStyle && ','} ${line.adultStyle && line.adultStyle} ${inventory ? '.' : 'on the back end.'} `;
        for (const size of line.sizes) {
          if (size.wrong) {
            message += `Expected ${size.quantity} ${size.size.toUpperCase()}, received ${size.packageNum} ${size.size}. `;
          }
        }
        message += 'Automatically created a new line with: ';
        for (const size of line.sizes) {
          if (size.wrong) {
            message += ` ${size.quantity - size.packageNum} ${size.size.toUpperCase()}. `;
          }
        }
        addNotification('Error', message, '', this.state.user, this.props.workOrder);
      }
    }
    this.setState({
      numOfLines,
    });
  };

  toggleArt = (line) => {
    const { numOfLines } = this.state;
    const index = numOfLines.indexOf(line);
    numOfLines[index].showArt = !numOfLines[index].showArt;
    this.setState({
      numOfLines,
    });
  };

  viewNumbers = () => {
    this.setState({
      viewNumbers: true,
    });
  };

  closeNumbers = () => {
    this.setState({
      viewNumbers: false,
    });
  };

  createNumbers = (sizes, numbers) => {
    const numberColumns = [{ title: 'Number', field: 'number' }];
    let j = 0;
    for (const size of sizes) {
      for (let i = 0; i < size.quantity; i++) {
        if (!numberColumns.find((column) => column.field === size.size)) {
          numberColumns.push({ title: size.size.toUpperCase(), field: size.size });
        }
        j++;
        if (j > numbers.length - 1) {
          console.log(numbers.length);
          j = 0;
        }
      }
    }
    this.setState(
      {
        numbers,
        numberColumns,
      },
      this.viewNumbers,
    );
  };

  openErrorModal = (line, size) => {
    const { numOfLines } = this.state;
    const lineIndex = numOfLines.indexOf(line);
    const sizeIndex = numOfLines[lineIndex].sizes.indexOf(size);
    numOfLines[lineIndex].sizes[sizeIndex].errorModal = !numOfLines[lineIndex].sizes[sizeIndex].errorModal;
    this.setState({
      numOfLines,
    });
  };

  updateFinishedInDB = (line, type) => {
    post('/updateFinished', { type, line }, 'obj').then((newLine) => {
      const { numOfLines } = this.state;
      const index = numOfLines.indexOf(line);
      type === 'count'
        ? (numOfLines[index].countFinished = !numOfLines[index].countFinished)
        : (numOfLines[index].packagingFinished = !numOfLines[index].packagingFinished);
      this.setState({
        numOfLines,
      });
    });
  };

  toggleSelected = (group) => {
    const { groupBy } = this.state;
    const index = groupBy.indexOf(group);
    groupBy[index].selected = !groupBy[index].selected;
    this.setState({
      groupBy,
    });
  };

  render() {
    return (
      <div
        style={{
          paddingBottom: '80px',
        }}
      >
        <Modal children="div" open={this.state.viewNumbers} onClose={this.closeNumbers} style={{ top: '5%', left: '10%' }}>
          <Numbers numbers={this.state.numbers} columns={this.state.numberColumns} />
        </Modal>
        <Grouping groupBy={this.state.groupBy} update={() => this.getOrders(0)} loading={this.state.loading} toggleSelected={this.toggleSelected} />
        {this.state.numOfLines.map((line, key) => (
          <PackagingLine
            workOrderID={this.props.workOrder.id}
            inventoryStyles={this.props.inventoryStyles}
            SKUs={this.props.SKUs}
            vendors={this.props.vendors}
            line={line}
            groupBy={this.state.groupBy}
            toggleArt={this.toggleArt}
            createNumbers={this.createNumbers}
            updateFinishedInDB={this.updateFinishedInDB}
            key={key}
            workOrder={this.props.workOrder}
            shipMethod={this.props.shipMethod}
            openErrorModal={this.openErrorModal}
            clockInLine={this.clockInLine}
            resetPackaging={this.resetPackaging}
            updateQuantity={this.updateQuantity}
            num={key}
          />
        ))}
        <PageToolbar
          workOrder={this.props.workOrder}
          filters={this.state.filters}
          orderOptions={this.state.orderOptions}
          page={this.state.page}
          setPage={this.setPage}
          pageSize={this.state.pageSize}
          total={this.state.total}
          updateFilter={this.updateFilter}
          clearFilters={this.clearFilters}
          firstPage={this.firstPage}
          prevPage={this.prevPage}
          nextPage={this.nextPage}
          lastPage={this.lastPage}
          loading={this.state.loading}
          setNumbersModal={this.props.numbersModal}
        />
      </div>
    );
  }
}

export default Packaging;
