/* eslint no-plusplus:0 */
/* eslint array-callback-return:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Toolbar,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import commaNumber from 'comma-number';
import { monthsWithNumber } from '../../../Utils/monthsOfTheYear';
import { getCurrency } from '../../../../Functions';

const SalesGoalsReportTeam = ({ salesGoalData, getDetails, goalYear }) => {
  const hasDataForMonth = (month) => {
    let hasData = false;
    salesGoalData.map((data) => {
      if (data.month === month) hasData = true;
    });
    return hasData;
  };

  return monthsWithNumber.map((month) => {
    let monthRev = 0;
    let monthProf = 0;
    let monthGoal = 0;
    let monthGP = 0;
    let numItemsGP = 0;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return (
      <div className={`${month[0].toLowerCase()}-goal-container`} key={month[1]}>
        <Grid container>
          <Grid item xs={12}>
            {hasDataForMonth(month[1]) && (
              <div
                style={{
                  margin: '5px 0',
                }}
              >
                <Accordion defaultExpanded={currentMonth === month[1]}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="h6">
                      {month[0]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Sales</TableCell>
                            <TableCell align="left">Gross Profit</TableCell>
                            <TableCell align="left">GP Goal</TableCell>
                            <TableCell align="left">% GP</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {salesGoalData.map((row) => {
                            if (row.month === month[1]) {
                              monthRev += row.revenue ? row.revenue : 0;
                              monthProf += row.profit ? row.profit : 0;
                              monthGoal += row.goalAmount ? row.goalAmount : 0;
                              let gp;
                              if (row.revenue !== null && row.revenue !== 0 && row.profit !== null) {
                                gp = (row.profit / row.revenue) * 100;
                                monthGP += gp;
                                numItemsGP++;
                              } else {
                                gp = 'No Data';
                              }

                              return (
                                <TableRow key={row.userID} hover onClick={() => getDetails(row.userID, row.month, goalYear.format('YYYY'))}>
                                  <TableCell component="th" scope="row">
                                    {row.firstName}
                                  </TableCell>
                                  <TableCell align="left">{row.revenue !== null ? `${getCurrency(row.revenue)}` : 'No Data'}</TableCell>
                                  <TableCell align="left" style={{ color: row.profit >= 0 ? '#4caf50' : '#f44336' }}>
                                    {row.profit !== null ? `${getCurrency(row.profit)}` : 'No Data'}
                                  </TableCell>
                                  <TableCell align="left">{row.goalAmount !== null ? `${getCurrency(row.goalAmount)}` : 'No Data'}</TableCell>
                                  <TableCell align="left">{gp === 'No Data' ? gp : `${gp.toFixed(1)}%`}</TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Totals</strong>
                            </TableCell>
                            <TableCell align="left">
                              <strong>${commaNumber(monthRev.toFixed(2))}</strong>
                            </TableCell>
                            <TableCell align="left" style={{ color: monthProf >= 0 ? '#4caf50' : '#f44336' }}>
                              <strong>${commaNumber(monthProf.toFixed(2))}</strong>
                            </TableCell>
                            <TableCell align="left">
                              <strong>${commaNumber(monthGoal.toFixed(2))}</strong>
                            </TableCell>
                            <TableCell align="left">
                              <strong>{((monthProf / monthRev) * 100).toFixed(1)}%</strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  });
};

export default SalesGoalsReportTeam;
