import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10 MB
const FulfillmentDropZone = ({ acceptedFiles, label, handleDrop, filesLimit, id }) => {
  const handleChange = async (files) => {
    handleDrop(files);
  };

  return (
    <div style={{ width: '100%' }}>
      <FormLabel>{label}</FormLabel>
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        filesLimit={filesLimit}
        id={id}
        onChange={handleChange}
        showAlerts={['error']}
        maxFileSize={MAX_FILE_SIZE}
      />
    </div>
  );
};

FulfillmentDropZone.propTypes = {
  acceptedFiles: PropTypes.array,
  label: PropTypes.string,
  filesLimit: PropTypes.number,
  type: PropTypes.string,
  handleDrop: PropTypes.func,
  id: PropTypes.string,
};

FulfillmentDropZone.defaultProps = {
  filesLimit: 10,
  acceptedFiles: ['image/*'],
};

export default FulfillmentDropZone;
