import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Delete, Star, FileCopy } from '@material-ui/icons';
import { Badge, TextField, Grid, Card, Typography, IconButton } from '@material-ui/core';
import PlacesSearch from './PlacesSearch';
import StatesSearch from './StatesSearch';
import CountriesSearch from './CountriesSearch';

const ContactForm = ({ getValues, control, contact, index, key, copyContact, removeContact, changeContact }) => {
  const loadSearchAddress = (searchAddress) => {
    console.log(searchAddress);
    changeContact(index, { ...contact, ...searchAddress });
  };

  return (
    <Grid key={key} xs={12} sm={6} md={4} item>
      <Badge
        color="secondary"
        style={{ width: '100%' }}
        badgeContent={
          <IconButton style={{ color: 'white' }} onClick={() => removeContact(index)}>
            <Delete fontSize="small" />
          </IconButton>
        }
      >
        <Card style={{ width: '100%', backgroundColor: '#eaeaea', padding: '25px' }}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                {contact.type === 'shipping' ? 'Shipping Contact' : 'Billing Contact'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => copyContact(contact.type === 'shipping' ? 'billing' : 'shipping', index)}>
                <FileCopy />
              </IconButton>
              <Controller
                name={`contacts[${index}].favorite`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.favorite ?? contact.favorite}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <IconButton color={value ? 'primary' : ''} onClick={() => onChange(!value)}>
                    <Star />
                  </IconButton>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].organization`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.organization ?? contact.organization}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Organization"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].co`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.co ?? contact.co}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField label="CO" fullWidth value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].phone`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.phone ?? contact.phone}
                rules={{
                  pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: 'Phone number is not a valid format' },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField label="Phone" fullWidth value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].email`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.email ?? contact.email}
                rules={{ required: false, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' } }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField label="Email" fullWidth value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PlacesSearch loadSearchAddress={loadSearchAddress} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name={`contacts[${index}].address1`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.address1 ?? contact.address1}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={`contacts[${index}].address2`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.address2 ?? contact.address2}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].city`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.city ?? contact.city}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField fullWidth label="City" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`contacts[${index}].zip`}
                control={control}
                defaultValue={getValues()['contacts'][index]?.zip ?? contact.zip}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField fullWidth label="Zip" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StatesSearch getValues={getValues} control={control} index={index} state={contact.state} />
            </Grid>
            <Grid item xs={6}>
              <CountriesSearch control={control} getValues={getValues} index={index} country={contact.country} />
            </Grid>
          </Grid>
        </Card>
      </Badge>
    </Grid>
  );
};

ContactForm.propTypes = {
  getValues: PropTypes.any,
  control: PropTypes.any,
  contact: PropTypes.object,
  index: PropTypes.number,
  key: PropTypes.any,
  copyContact: PropTypes.func,
  removeContact: PropTypes.func,
  changeContact: PropTypes.func,
};

export default ContactForm;
