/* eslint react/no-unescaped-entities:0 */
/* eslint no-console:0 */
/* eslint array-callback-return:0 */
/* eslint no-unused-vars:0 */
/* eslint no-shadow:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Collapse, Divider, IconButton, Link, Popover, Typography } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import materialColors from '../../Utils/MaterialColors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  chartContainer: {
    position: 'relative',
    height: '500px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
}));

const PrintTypeChart = ({ get, refresh }) => {
  const [pieData, setPieData] = useState([]);
  const [data, setData] = useState();
  const [open, setOpen] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState('all');
  const classes = useStyles();
  const theme = useTheme();

  const options = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20,
      },
    },
    responsive: true,
    onClick: (e, item) => {
      if (item[0] && type === 'all') {
        const { label } = item[0]._model;
        if (label !== 'Unassigned') setType(label);
      }
    },
    maintainAspectRatio: false,
    cutoutPercentage: 60,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const formatPieData = () => {
    const newData = [];
    const newLabels = [];
    let heatPressQuantity = 0;
    let type;
    pieData.map((x, i) => {
      if (x.type === 'EasyWeed' || x.type === 'Heat Press Patch' || x.type === 'Color Soft & Sublithin') {
        type = 'all';
        heatPressQuantity += x.quantity;
      } else {
        newData.push(x.quantity);
        newLabels.push(x.type === '' ? 'Unassigned' : x.type);
      }
    });
    if (type === 'all') {
      newData.push(heatPressQuantity);
      newLabels.push('Heat Press');
    }

    const newDataStuff = {
      datasets: [
        {
          data: newData,
          backgroundColor: materialColors,
          borderWidth: 0,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white,
        },
      ],
      labels: newLabels,
    };
    setData(newDataStuff);
  };

  const getPrintTypeTotals = () => {
    get('/getPrintTypeTotals', [
      {
        name: 'type',
        value: type,
      },
    ])
      .then((res) => {
        setPieData(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getPrintTypeTotals();
  }, [type, refresh]);

  useEffect(() => {
    formatPieData();
  }, [pieData]);

  return (
    <Card style={{ height: open ? '100%' : 'auto' }}>
      <CardHeader
        action={
          <>
            <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px' }}>
              <InfoIcon />
            </IconButton>
            <Popover
              open={infoOpen}
              anchorEl={anchorEl}
              onClose={openInfo}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                This pie chart shows you the different types of prints that are currently in progress.
                <br />
                <br />
                You can click/tap each category to see the different print types for each type. Unassigned will not work however. Make sure you
                include a type so we don't see anymore 'Unassigned'.
                <br />
                <br />
                Hover/Tap on a section to see specific quanities. Also, you can limit your selection by clicking on the labels at the bottom to
                exclude them from the graph.
              </Typography>
            </Popover>
          </>
        }
        title={
          <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">Print's in Progress Info</Typography>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        }
      />
      <Divider />
      <Collapse in={open} timeout="auto">
        <CardContent style={{ paddingBottom: '16px' }}>
          {data && (
            <div className={classes.chartContainer}>
              {type !== 'all' && (
                <div>
                  <Link onClick={() => setType('all')} component="button" variant="body1" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <ArrowBackIosIcon />
                    Back to Print Types
                  </Link>
                </div>
              )}
              <Doughnut data={data} options={options} />
            </div>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default PrintTypeChart;
