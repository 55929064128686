/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { get, post } from '../../Functions/API.js';
import MaterialTable from 'material-table';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded.js';
import ShopifyInfoPlus from './shopify-infoPlus.js';
import { createDialog } from '../../Stores/Dialog/Actions.js';
import { useHistory } from 'react-router-dom';

const Uploads = () => {
  const history = useHistory();
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    get('/uploads').then(setUploads);
  }, []);
  return (
    <MaterialTable
      title={'InfoPlus Uploads'}
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Created At', field: 'createdAt', type: 'datetime', editable: 'never' },
        { title: 'Type', field: 'type' },
      ]}
      data={uploads}
      actions={[
        {
          icon: () => <WorkOutlineRoundedIcon />,
          tooltip: `Upload File`,
          onClick: (event, rowData) => createDialog({ title: 'Upload', content: <ShopifyInfoPlus uploadId={rowData.id} />, size: 'LG' }),
        },
      ]}
      editable={{
        onRowAdd: async (newData) => {
          return post('/uploads', newData).then((row) => {
            const rows = [...uploads, row];
            setUploads(rows);
          });
        },
      }}
      onRowClick={(e, rowData) => {
        history.push(`/uploads/${rowData.id}`);
      }}
    />
  );
};

export default Uploads;
