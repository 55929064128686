/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint no-shadow:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import palette from '../../Utils/theme/palette';
import materialColors from '../../Utils/MaterialColors';

const MiniWorkOrderStatusGraph = ({ get, user, refresh }) => {
  const [data, setData] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [singleCount, setSingleCount] = useState(null);
  const [labels, setLabels] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // TODO seeViewAllMiniWorkOrderStatusGraphButton
    legend: { display: user.group !== 'Admin' },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            fontSize: 10,
            minRotation: 0,
            maxRotation: 45,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
        },
      ],
    },
  };

  const getGraphData = () => {
    get('/getDashboardFilters', [
      {
        name: 'uid',
        value: user.uid,
      },
    ])
      .then(async (res) => {
        const promises = [];
        for (const filter of res) {
          if (filter.title !== 'All') {
            if (!labels.length) {
              setLabels((labels) => [...labels, filter.title]);
            }
            const promise = getFilterDetails(filter.dashboardFilterID).then(({ countAll, countSingle }) => {
              filter.countAll = countAll;
              filter.countSingle = countSingle;
            });
            promises.push(promise);
          }
        }
        await Promise.all(promises);
        setTotalCount(res.map((f) => f.countAll));
        setSingleCount(res.map((f) => f.countSingle));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFilterDetails = (ID) => {
    return get('/filterDetailsForGraph', [{ name: 'dashboardFilterID', value: ID }])
      .then((res) => {
        return { countAll: res.countAll, countSingle: res.countSingle };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatGraphData = () => {
    const data = {
      labels,
      datasets: [],
    };
    // TODO seeViewAllMiniWorkOrderStatusGraphButton
    if (user.group === 'Admin') {
      data.datasets.push({
        label: 'All',
        backgroundColor: materialColors[2],
        borderColor: materialColors[2],
        data: totalCount,
      });
    } else {
      data.datasets.push(
        {
          label: 'Me',
          backgroundColor: materialColors[4],
          borderColor: materialColors[4],
          data: singleCount,
        },
        {
          label: 'All',
          backgroundColor: materialColors[2],
          borderColor: materialColors[2],
          data: totalCount,
        },
      );
    }
    setData(data);
  };

  useEffect(() => {
    if (totalCount && singleCount) {
      formatGraphData();
    }
  }, [totalCount, singleCount]);

  useEffect(() => {
    getGraphData();
  }, [refresh]);

  return (
    <Card style={{ height: '100%' }}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography style={{ fontWeight: 700 }} color="textSecondary" gutterBottom variant="body2">
          WORK ORDER STATUS
        </Typography>
        {data === null ? (
          <div style={{ textAlign: 'center' }}>
            <Skeleton variant="rect" width="100%" height={200} />
          </div>
        ) : (
          <div className="workorder-status-graph" style={{ height: '200px' }}>
            <Bar data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MiniWorkOrderStatusGraph;
