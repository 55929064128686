/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, AccordionDetails, AccordionSummary, Accordion, TextField, CardContent, Card } from '@material-ui/core';
import { get } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { ExpandMore } from '@material-ui/icons';
import InboundPackages from '../../../../Shared/InboundPackages';
import { fetchVendors } from '../../../../../Stores/Vendors/Actions';
import { VendorsStore } from '../../../../../Stores';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const Receiving = ({ workOrder }) => {
  const [costs, setCosts] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    getCouriers();
    getCosting();
    fetchVendors();
    return () => {
      VendorsStore.removeListener('change', getVendors);
    };
  }, []);

  const getVendors = () => {
    setVendors(VendorsStore.getVendors());
  };

  const getCosting = () => {
    get('/costing', {
      filter: {
        eager: {
          $aggregations: [
            {
              type: 'count',
              field: 'id',
              relation: 'inboundPackages',
              alias: 'inboundPackagesLength',
            },
          ],
          inboundPackages: {},
          $where: { workOrderID: workOrder.id, inboundPackagesLength: { $gt: 0 } },
        },
      },
    })
      .then((results) => {
        // TODO need to change once I merge with breg
        setCosts(results.filter((row) => row.vendor !== 'CSP'));
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const getCouriers = () => {
    get(`/couriers`)
      .then(setCouriers)
      .catch((err) => createSnack('Error', err.message));
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      {Boolean(costs.length) ? (
        costs.map((cost, index) => (
          <Grid key={index} item>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <TextField label={'Vendor'} value={vendors.find((v) => v.id === cost.vendorID)?.name} />
                  </Grid>
                  <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        // inputVariant="outlined"
                        // inputProps={{
                        //   style: { textAlign: 'center' },
                        // }}
                        views={['day', 'month', 'year']}
                        label="Arrival Date"
                        value={cost.arrivalDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <InboundPackages couriers={couriers} costingID={cost.id} WorkOrderId={workOrder?.id} allowAdd={false} />
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <div style={{ marginLeft: '20px' }}>No packages created yet</div>
      )}
    </div>
  );
};

Receiving.propTypes = {
  workOrder: PropTypes.object,
};

Receiving.displayName = 'Receiving';

export default Receiving;
