import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function ProtectedRoute({ location, user, permission, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        if (!user) return <Redirect to={`/login?next=${location.pathname}`} />;
        if (!user?.hasPermission(permission)) return <Redirect to="/unauthorized" />;
        if (!user?.active) return <Redirect to={`/inactive?next=${location.pathname}`} />;
        return children;
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  location: PropTypes.any,
  user: PropTypes.any,
  permission: PropTypes.any,
  children: PropTypes.any,
};

export default ProtectedRoute;
