import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions.js';
import { convertDateToSQLDate } from '../../../../Utils/SqlDate.js';

const ReprintDate = ({ workOrder }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disablePast
        error={false}
        helperText={false}
        label="Reprint Date"
        value={workOrder.reprintDate}
        style={{ margin: '8px', width: '100%' }}
        onChange={(date) => updateWorkOrder(workOrder.id, { reprintDate: convertDateToSQLDate(date) })}
      />
    </MuiPickersUtilsProvider>
  );
};

export default ReprintDate;

ReprintDate.propTypes = {
  workOrder: PropTypes.object,
};
