/* eslint-disable react/prop-types */
// import './styles.css';
import React, { useState, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'Jan',
    sales: 5000,
    cost: 2400,
    profit: 3400,
  },
  {
    name: 'Feb',
    sales: 3000,
    cost: 1398,
    profit: 2210,
  },
  {
    name: 'Mar',
    sales: 2000,
    cost: 9800,
    profit: 6290,
  },
  {
    name: 'Apr',
    sales: 2780,
    cost: 3908,
    profit: 1000,
  },
  {
    name: 'May',
    sales: 1890,
    cost: 4800,
    profit: 2181,
  },
  {
    name: 'Jun',
    sales: 2390,
    cost: 3800,
    profit: 4500,
  },
  {
    name: 'July',
    sales: 3490,
    cost: 4300,
    profit: 2100,
  },
];

const Graph = ({ height, width }) => {
  const [opacity, setOpacity] = useState({
    sales: 1,
    cost: 1,
    profit: 1,
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    const newOpacity = { ...opacity };
    for (const key in newOpacity) {
      if (dataKey === key) continue;
      newOpacity[key] = 0.25;
    }
    setOpacity(newOpacity);
  };

  const handleMouseLeave = (o) => {
    const newOpacity = { ...opacity };
    for (const key in newOpacity) {
      newOpacity[key] = 1;
    }
    setOpacity(newOpacity);
  };

  return (
    <div>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        <Line type="monotone" dataKey="sales" strokeOpacity={opacity.sales} stroke="#3389FF" />
        <Line type="monotone" dataKey="cost" strokeOpacity={opacity.cost} stroke="#FF5733" />
        <Line type="monotone" dataKey="profit" strokeOpacity={opacity.profit} stroke="#82ca9d" activeDot={{ r: 8 }} />
      </LineChart>
    </div>
  );
};

export default Graph;
