import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, Day } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { convertDateToSQLDate, sqlDateString } from '../../../../Utils/SqlDate';
import { Badge, makeStyles } from '@material-ui/core';
import { createDialog } from '../../../../../Stores/Dialog/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import { UserStore } from '../../../../../Stores';
import { get } from '../../../../../Functions/API';

const useStyles = makeStyles({
  root: {},
  colorPrimary: {
    backgroundColor: '#eaeaea',
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

const DueDate = ({ workOrder, editable = true }) => {
  const user = UserStore.getUser();
  const [dates, setDates] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    get('/prints/per-day').then(setDates);
  }, [workOrder.dueDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        id="dueDate"
        style={{ margin: '8px', width: '100%' }}
        disablePast
        disabled={!editable}
        error={false}
        helperText={false}
        margin="normal"
        label="Due Date"
        value={workOrder.dueDate}
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          const found = dates.find((d) => d.dueDate === sqlDateString(day));
          const tooltip = found ? commaNumber(found.prints) : 'NA';
          const limit = new Date();
          limit.setDate(limit.getDate() + 13);
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          if (day > yesterday && day < limit && isInCurrentMonth)
            return (
              <Badge
                color={'secondary'}
                badgeContent={<span style={{ fontSize: '0.6rem' }}>{tooltip}</span>}
                max={10000}
                variant={'standard'}
                overlap="circle"
              >
                {dayComponent}
              </Badge>
            );
          if (day < yesterday && isInCurrentMonth) {
            return (
              <Badge
                classes={{ colorPrimary: classes.colorPrimary }}
                color={'primary'}
                badgeContent={<span style={{ fontSize: '0.6rem' }}>{tooltip}</span>}
                max={10000}
                variant={'standard'}
                overlap="circle"
              >
                {dayComponent}
              </Badge>
            );
          }
          if (isInCurrentMonth) {
            return (
              <Badge
                color={'primary'}
                badgeContent={<span style={{ fontSize: '0.6rem' }}>{tooltip}</span>}
                max={10000}
                variant={'standard'}
                overlap="circle"
              >
                {dayComponent}
              </Badge>
            );
          }
          return dayComponent;
        }}
        onChange={(date) => {
          const limit = new Date();
          date.setHours(limit.getHours());
          console.log(typeof date);
          console.log('1', date);
          limit.setDate(limit.getDate() + 13);
          if (date < limit) {
            createDialog({
              content:
                "You've selected a date that is within the 10 business day limit. If you proceed this work order will be marked as a rush and production will be notified.",
              title: 'Create Rush Job?',
              actions: [
                { title: 'Hecka No', color: 'secondary', callback: null },
                {
                  title: 'Proceed',
                  color: 'primary',
                  callback: () => {
                    addNotification('Production', 'This work order has been marked as a rush.', null, user, workOrder, null);
                    console.log('2', date);
                    return updateWorkOrder(workOrder.id, { dueDate: convertDateToSQLDate(date), rushJob: true });
                  },
                },
              ],
            });
          } else {
            console.log('2.5', date);
            return updateWorkOrder(workOrder.id, { dueDate: convertDateToSQLDate(date) });
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DueDate;

DueDate.propTypes = {
  workOrder: PropTypes.object,
  editable: PropTypes.bool,
};
