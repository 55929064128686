/* eslint-disable react/no-children-prop */
import { React, useState, useEffect } from 'react';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import NumberSummary from '../Production/Numbers/NumberSummary';
import WebRoster from './WebRoster';
import NewGenerateLine from './NewGenerateLine';
import AllSizes from './AllSizes';
import CSVTable from '../../../../CustomViews/Testing/CSVTable';
import PageToolbar from './PageToolbar';
import UserStore from '../../../../../Stores/User/Store';
import OrdersStore from '../../../../../Stores/Orders/Store';
import InventoryStore from '../../../../../Stores/Inventory/Store';
import VendorsStore from '../../../../../Stores/Vendors/Store';
import OptionsStore from '../../../../../Stores/Options/Store';
import { loadInventory } from '../../../../../Stores/Inventory/Actions';
import { fetchVendors } from '../../../../../Stores/Vendors/Actions';
import { loadOptions } from '../../../../../Stores/Options/Actions';
import { loadOrders, submitOrders } from '../../../../../Stores/Orders/Actions';
import { loadNotifications } from '../../../../../Stores/Notifications/Actions';
import { WorkOrderStore } from '../../../../../Stores';

const GenerateOrder = () => {
  const [orders, setOrders] = useState([]);
  const [numOfLines, setNumOfLines] = useState([]);
  const [potentialSizes, setPotentialSizes] = useState([]);
  const [viewRoster, setViewRoster] = useState(false);
  const [sizesModal, setSizesModal] = useState(false);
  const [numbersModal, setNumbersModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [webOrderID, setWebOrderID] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderOptions, setOrderOptions] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [chips, setChips] = useState([]);
  const user = UserStore.getUser();

  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    OrdersStore.on('change', getOrders);
    if (OrdersStore.getOrders().length === 0) {
      fetchOrders();
    } else {
      getOrders();
    }
    if (OptionsStore.getOptions().length === 0) {
      loadOptions();
    }
    if (InventoryStore.getInventory().length === 0) {
      loadInventory();
    }
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    }
    return () => {
      OrdersStore.loadOrders({
        data: [],
        options: {
          type: [],
        },
        count: null,
      });
      OrdersStore.removeListener('change', getOrders);
    };
  }, [workOrder.id]);

  useEffect(() => {
    if (workOrder.id) {
      const getAllPotentialSizes = get('/allPotentialSizes', [{ name: 'type', value: 'type' }])
        .then((results) => {
          setPotentialSizes(results);
        })
        .catch((err) => {
          console.log('Error', err.message);
          createSnack('Error', err.message);
        });
      const getChips = get('/workOrderArt', { filter: { eager: { $where: { workOrderId: workOrder.id }, printGroup: {}, printType: {} } } })
        .then((results) => {
          setChips(results);
        })
        .catch((err) => {
          console.log('Error', err.message);
          createSnack('Error', err.message);
        });

      Promise.all([getAllPotentialSizes, getChips]);
    }
  }, [workOrder.id]);

  const getOrders = () => {
    const savedOrders = [...OrdersStore.getOrders()];
    savedOrders.forEach((order) => {
      order.saved = true;
    });
    setOrders(savedOrders);
    setNumOfLines(savedOrders);
    setTotal(OrdersStore.getCount());
    setOrderOptions({ ...OrdersStore.getOptions() });
    setLoading(false);
  };

  const fetchOrders = () => {
    setLoading(true);
    if (workOrder?.id) {
      loadOrders(workOrder.id, page, selectedFilters)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          createSnack('Error', err.message);
        });
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [page]);

  const blankLine = (line) => {
    const blank = line.sizes.every((size) => !size.quantity);
    return blank;
  };

  const submit = async () => {
    const errorLines = numOfLines.some((line) => blankLine(line));
    if (errorLines) {
      createSnack('Error', `Looks like there were no sizes or quantities input when you tried to save on the following numOfLines: ${errorLines}`);
    } else {
      const containsOldInfo = numOfLines.some((line) => {
        if (line.useInventory) {
          return Boolean(line.vendorID || line.youthStyle || line.adultStyle || line.color || line.type);
        } else {
          return Boolean(line.inventoryId || line.removeSKU);
        }
      });
      if (containsOldInfo) {
        const c = window.confirm(
          'One or more rows contains info not visible because Use Inventory was changed after data already existed. Are you sure you would like to proceed and override this data??',
        );
        if (c) {
          setLoading(true);
          for (const line of numOfLines) {
            if (line.useInventory) {
              line.vendorID = null;
              line.youthStyle = '';
              line.adultStyle = '';
              line.color = null;
              line.type = null;
            } else {
              line.inventoryId = null;
              line.removeSKU = null;
            }
            if (workOrder.orderType !== 'inventory') line.addSKU = null;
          }
        } else {
          return;
        }
      }
      setLoading(true);
      await submitOrders({ workOrderID: workOrder.id, page, numOfLines, selectedFilters, uid: user.uid });
      loadNotifications(workOrder.id);
    }
  };

  const updateFilter = (filter, value) => {
    const newFilters = { ...selectedFilters, [filter]: value };
    setSelectedFilters(newFilters);
    if (page > 0) {
      setPage(0);
    } else {
      loadOrders(workOrder.id, page, newFilters);
    }
  };

  const clearFilters = () => {
    const newSelectedFilters = selectedFilters;
    for (const key of Object.keys(newSelectedFilters)) {
      newSelectedFilters[key] = '';
    }
    setSelectedFilters({});
    if (page > 0) {
      setPage(0);
    } else {
      fetchOrders();
    }
  };

  return (
    <div
      style={{
        paddingTop: '20px',
        paddingBottom: '80px',
        overflow: 'hidden',
        backgroundColor: 'rgba(0,0,0,0.025)',
      }}
    >
      <Modal children="div" open={viewRoster} onClose={() => setViewRoster(false)} style={{ top: '25%', left: '10%' }}>
        <WebRoster webOrderID={webOrderID} />
      </Modal>
      <Modal children="div" open={sizesModal} onClose={() => setSizesModal(false)} style={{ top: '10%', left: '10%' }}>
        <AllSizes workOrderID={workOrder.id} filters={selectedFilters} post={post} />
      </Modal>
      <Modal children="div" open={importModal} onClose={() => setImportModal(false)} style={{ top: '10%' }}>
        <CSVTable
          workOrderID={workOrder.id}
          createSnack={createSnack}
          post={post}
          close={() => setImportModal(false)}
          getOrders={fetchOrders}
          get={get}
        />
      </Modal>
      <Grid container justify="center" spacing={2}>
        {numOfLines.map((line, key) => (
          <Grid item xs={11} key={key}>
            <NewGenerateLine
              load={line}
              num={key}
              user={user}
              workOrder={workOrder}
              viewRoster={viewRoster}
              filters={selectedFilters}
              fetchOrders={fetchOrders}
              potentialSizes={potentialSizes}
              setNumOfLines={setNumOfLines}
              numOfLines={numOfLines}
              chips={chips}
            />
          </Grid>
        ))}
      </Grid>
      <PageToolbar
        workOrderID={workOrder.id}
        workOrder={workOrder}
        setNumOfLines={setNumOfLines}
        filters={selectedFilters}
        orderOptions={orderOptions}
        page={page}
        pageSize={pageSize}
        total={total}
        updateFilter={updateFilter}
        loading={loading}
        clearFilters={clearFilters}
        setPage={setPage}
        submit={submit}
        deleteAll={true}
        setSizesModal={setSizesModal}
        setImportModal={setImportModal}
        setNumbersModal={setNumbersModal}
        addRowEnabled={true}
      />
    </div>
  );
};

GenerateOrder.propTypes = {
  workOrder: PropTypes.object.isRequired,
};

export default GenerateOrder;
