/* eslint react/no-unescaped-entities:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { LinearProgress, Typography, Fab, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@material-ui/icons/Refresh';
import ProductionHome from '../Components/Home/Production';
import AccountingHome from '../Components/Home/Accounting';
import OrderProcessorHome from '../Components/Home/OrderProcessor';
import SalesHome from '../Components/Home/Sales';
import AllPowerfulAdminHome from '../Components/Home/Admin';
import FulfillmentHome from '../Components/Home/Fulfillment';
import PrinterHome from '../Components/Home/Printer';
import ArtHome from '../Components/Home/Art';
import DepositPhotos from '../Components/DepositPhotos';
import CustomerInventory from '../Components/CustomerInventory';

const Home = (props) => {
  const location = useLocation();
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [fabDisabled, setFabDisabled] = useState(false);

  useEffect(() => {
    const { group } = props.user;
    if (group.includes('Production') && group.includes('Printer')) {
      setSelected('Printer');
    } else if (group.includes('Production') && group.includes('Art')) {
      setSelected('Art');
    } else if (group.includes('Production') && group.includes('Fulfillment')) {
      setSelected('Fulfillment');
    } else if (group.includes('Production')) {
      setSelected('Production');
    } else if (group.includes('Accounting')) {
      setSelected('Accounting');
    } else if (group.includes('Order Processor')) {
      setSelected('Order Processor');
    } else if (group.includes('Sales') || group.includes('Owner')) {
      setSelected('Sales');
    } else if (group === 'Admin') {
      setSelected('Admin');
    } else if (group === 'Customer - Inventory') {
      setSelected('Customer - Inventory');
    } else if (group === 'Marketing') {
      setSelected('Marketing');
    }
    setIsLoading(false);
  }, []);

  const refreshEverything = () => {
    setRefresh(!refresh);
    setFabDisabled(true);
    setTimeout(() => setFabDisabled(false), 1500);
  };

  return (
    <div className="home-container" style={{ position: 'relative', backgroundColor: '#f5f5f5' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div style={{ margin: '0', backgroundColor: '#f5f5f5' }}>
          {selected === 'Marketing' && <DepositPhotos />}
          {selected === 'Customer - Inventory' && <CustomerInventory />}
          {selected === 'Printer' && <PrinterHome {...props} location={location} refresh={refresh} />}
          {selected === 'Production' && <ProductionHome {...props} location={location} refresh={refresh} />}
          {selected === 'Fulfillment' && <FulfillmentHome {...props} location={location} refresh={refresh} />}
          {selected === 'Art' && <ArtHome {...props} location={location} refresh={refresh} />}
          {selected === 'Accounting' && <AccountingHome {...props} location={location} refresh={refresh} />}
          {selected === 'Order Processor' && <OrderProcessorHome {...props} location={location} refresh={refresh} />}
          {selected === 'Sales' && <SalesHome {...props} location={location} refresh={refresh} />}
          {selected === 'Admin' && <AllPowerfulAdminHome {...props} location={location} refresh={refresh} />}
          {selected === null && (
            <div style={{ textAlign: 'center', paddingTop: '50px' }}>
              <Typography variant="h4">Looks like you don't have access to this page yet...</Typography>
              <div style={{ marginTop: '80px' }}>
                <img src="https://media0.giphy.com/media/l22ysLe54hZP0wubek/giphy.gif" alt="sad-gif" />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="fab-container" style={{ position: 'fixed', bottom: '8px', right: '12px', zIndex: '1000' }}>
        <Tooltip title="Refresh All Content" placement="left">
          <Fab size="small" disabled={fabDisabled} style={{ backgroundColor: fabDisabled ? 'grey' : '#43a047' }} onClick={refreshEverything}>
            <RefreshIcon style={{ color: 'white' }} />
          </Fab>
        </Tooltip>
      </div>
    </div>
  );
};

export default Home;
