/* eslint-disable react/display-name */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint no-alert:0 */
/* eslint react/prop-types:0 */
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import { Checkbox, Modal } from '@material-ui/core';
import WhatsNew from './WhatsNew';

const VersionControll = ({ get, post }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [versionId, setVersionId] = useState(null);
  const [data, setData] = useState([]);

  const getFeatureVersions = () => {
    get('/getNewFeatureVersions').then((results) => {
      setData(results.reverse());
    });
  };

  useEffect(() => {
    getFeatureVersions();
  }, []);

  const addVersion = (newData) => {
    post('/addVersion', { newData }).then((results) => {
      const id = results.insertId;
      const features = [...data];
      features.unshift({ ...newData, id });
      setData(features);
    });
  };

  const editVersion = (newData, oldData) => {
    post('/editVersion', { newData }).then(() => {
      const features = [...data];
      const index = features.indexOf(oldData);
      features[index] = newData;
      features[index].visible = Number(features[index].visible);
      setData(features);
    });
  };

  const deleteVersion = (oldData) => {
    const { id } = oldData;
    post('/deleteVersion', { id }).then(() => {
      const features = [...data];
      const index = features.indexOf(oldData);
      features.splice(index, 1);
      setData(features);
    });
  };

  const updateVisible = (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();
    post('/updateVersionVisibility', { rowData }).then(() => {
      const features = [...data];
      const index = features.indexOf(rowData);
      features[index].visible = !rowData.visible;
      setData(features);
    });
  };

  const handleClick = (row) => {
    setVersionId(row.id);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const columns = [
    { field: 'version', title: 'Version', headerStyle: { textAlign: 'center', fontSize: '10' }, cellStyle: { textAlign: 'center' } },
    { field: 'date', type: 'date', title: 'Date', headerStyle: { fontSize: '10' } },
    { field: 'description', title: 'Description', headerStyle: { fontSize: '10' } },
    {
      field: 'visible',
      title: 'Visible',
      lookup: { 0: 'False', 1: 'True' },
      headerStyle: { fontSize: '10' },
      render: (rowData) => (
        <Checkbox
          checked={Boolean(rowData.visible)}
          value={Boolean(rowData.visible)}
          onClick={(e) => {
            updateVisible(e, rowData);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <Paper>
        <MaterialTable
          title="Whats New To MSAS"
          columns={columns}
          data={data}
          editable={{
            onRowAdd: async (newData) => {
              await addVersion(newData);
            },
            onRowUpdate: async (newData, oldData) => {
              await editVersion(newData, oldData);
            },
            onRowDelete: async (oldData) => {
              await deleteVersion(oldData);
            },
          }}
          onRowClick={(e, row) => {
            handleClick(row);
          }}
        />
      </Paper>

      <Modal open={modalOpen} onClose={handleClose}>
        <div
          style={{
            margin: 'auto',
            marginTop: '4%',
            width: '75%',
            maxHeight: '75%',
            position: 'center',
            borderRadius: '4px',
            alignItems: 'center',
            paddingBottom: '1px',
            backgroundColor: 'white',
            overflow: 'scroll',
          }}
        >
          <WhatsNew get={get} post={post} versionId={versionId} />
        </div>
      </Modal>
    </div>
  );
};

export default VersionControll;
