/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { newSKU } from '../../../../../../Stores/Inventory/Actions';
import { createSnack } from '../../../../../../Stores/Snack/Actions';

const filter = createFilterOptions();

const SKU = ({ callback, SKU, SKUs, inventoryId, title, disabled = false, pullSizes }) => {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    sku: '',
  });

  useEffect(() => {
    setValue(SKU);
  }, [SKU]);

  const handleClose = () => {
    setDialogValue({
      name: '',
      sku: '',
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (dialogValue.sku && dialogValue.name) {
      newSKU(inventoryId, dialogValue.sku, dialogValue.name)
        .then((SKU) => {
          handleChange(SKU);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      createSnack('Error', 'SKU not created. Fields left blank');
    }
  };

  const handleChange = (newSKU) => {
    callback(newSKU).catch((err) => {
      console.log('ERR', err);
    });
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                sku: null,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              sku: null,
            });
          } else {
            handleChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={SKUs}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.sku !== undefined ? `${option.sku} - ${option.name}` : option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disabled={disabled}
        renderOption={(option) => (option.sku !== undefined ? `${option.sku} - ${option.name}` : option.name)}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            style={{ margin: '8px' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: value && (
                <InputAdornment position="end">
                  <IconButton style={{ marginRight: '-25px' }} onClick={() => pullSizes(value)} href="">
                    <AddIcon cursor="pointer" color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new SKU</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
              label="Name"
              type="text"
            />
            <TextField
              margin="dense"
              id="sku"
              value={dialogValue.sku}
              onChange={(event) => setDialogValue({ ...dialogValue, sku: event.target.value })}
              label="sku"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

SKU.propTypes = {
  callback: PropTypes.func.isRequired,
  SKU: PropTypes.object.isRequired,
  SKUs: PropTypes.array.isRequired,
  inventoryId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  pullSizes: PropTypes.func.isRequired,
};

export default SKU;
