/* eslint react/no-children-prop:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-console:0 */
/* eslint consistent-return:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable, {
  // {MTableToolbar, MTablePagination}
  MTableToolbar,
} from 'material-table';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import DetailModal from './DetailModal';
import { get } from '../../../../../Functions/API';
import UserStore from '../../../../../Stores/User/Store';

class WorkHistory extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.state = {
      workOrderID: 0,
      numOfLines: [],
      arrivalDateModal: false,
      submitted: false,
      countTotal: date,
      mixInkTotal: date,
      burnScreensTotal: date,
      printTotal: date,
      packagingTotal: date,
      totalTime: 0,
      openHistoryDetail: false,
      detailsID: 0,
      users: [],
      options: [],
      // todo: add notes and estimated cost and actual cost the actual cost column should only be edited by steve or jordana
      columns: [
        {
          title: 'Employee',
          field: 'userID',
          editable: 'never',
          render: (dataRow) => {
            let employee = [];
            this.state.users.forEach((user) => {
              if (user.uid === dataRow.userID) {
                employee = user;
              }
            });

            return (
              <div>
                {employee.firstName} {employee.lastName}
              </div>
            );
          },
        },
        {
          title: 'Type',
          field: 'type',
          editable: this.props.user.group === 'Accounting Admin' || this.props.user.group === 'Admin' ? 'always' : 'never',
        },
        //  TODO editWorkHistoryType
        {
          title: 'Timestamp',
          field: 'timestamp',
          type: 'datetime',
          render: (rowData) => {
            const date = new Date(rowData.timestamp);
            return `${date.toDateString()} ${date.getHours().toString().length > 1 ? date.getHours() : `0${date.getHours()}`}:${
              date.getMinutes().toString().length > 1 ? date.getMinutes() : `0${date.getMinutes()}`
            }:${date.getSeconds().toString().length > 1 ? date.getSeconds() : `0${date.getSeconds()}`}`;
          },
        },
        {
          title: 'Message',
          field: 'message',
          type: 'string',
        },
        {
          title: 'Details',
          field: 'id',
          render: (rowData) => {
            if (rowData.id) {
              return (
                <Button variant="contained" color="primary" onClick={() => this.openHistoryDetails(rowData.id)}>
                  View Details
                </Button>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    const filter = {
      eager: {
        $where: {
          type: 'type',
        },
      },
    };
    get('/options', { filter })
      .then((results) => {
        this.setState({
          options: { type: results },
        });
      })
      .then(() => {
        get(`/getHistory?workOrderID=${this.props.workOrder.id}`, [])
          .then((results) => {
            const numOfLines = results.rows;
            const { submitted } = results;
            console.log(results);
            this.setState({
              workOrderID: this.props.workOrder.id,
              numOfLines,
              submitted,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .then(() => {
        get('/allUsers', []).then((usersGet) => {
          this.setState({
            users: usersGet,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  openHistoryDetails = (detailsID) => {
    this.setState({
      openHistoryDetail: true,
      detailsID,
    });
  };

  closeHistoryDetails = () => {
    this.setState({
      openHistoryDetail: false,
    });
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Work Order History"
            columns={this.state.columns}
            data={this.state.numOfLines}
            options={{
              rowStyle: {
                fontSize: 14,
              },
              pageSizeOptions: [10, 15, 25, 50],
              pageSize: 5,
            }}
          />
        </Paper>
        <Modal open={this.state.openHistoryDetail} children="div" onClose={this.closeHistoryDetails}>
          <DetailModal detailID={this.state.detailsID} />
        </Modal>
      </div>
    );
  }
}

export default WorkHistory;
