/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
/* eslint no-unused-vars:0 */
/* eslint react/no-children-prop:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Checkbox, Snackbar, SnackbarContent } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Menu from '../../../../Components/Menu';
import notificationStyles from '../../../../Components/Utils/NotificationStyles.js';

const Data = ({ get, post, user, match }) => {
  const [uploads, setUploads] = useState([]);
  const [columns, setColumns] = useState([]);
  const [uploadId, setUploadId] = useState(0);
  const [errorModalOpen, setOpenErrorModal] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '', variant: '' });
  const [refresh, setRefresh] = useState(0);
  const [errors, setErrors] = useState([
    {
      errMessage: 'Loading...',
      error_id: 'Loading...',
      repNum: 'Loading...',
      table_name: 'Loading...',
      uploadId: 'Loading...',
    },
  ]);

  useEffect(() => {
    const { uploadId } = match.params;
    get('/getExternalCompanyData', { uploadId }, 'obj').then((results) => {
      setUploads(results.results);
      for (const column of results.columns) {
        if (column.field === 'emailSent') {
          column.editable = 'never';
          column.render = (rowData) => (rowData ? <Checkbox checked={rowData.emailSent} value={rowData.emailSent} color="secondary" /> : <div />);
        } else if (column.field === 'rankUpdated') {
          column.editable = 'never';
          column.render = (rowData) => (rowData ? <Checkbox checked={rowData.rankUpdated} value={rowData.rankUpdated} color="primary" /> : <div />);
        } else if (column.title === 'Errors') {
          column.editable = 'never';
          column.render = (rowData) =>
            rowData ? (
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                disabled={rowData.errors !== 1}
                onClick={() => {
                  getErrors(rowData);
                  setOpenErrorModal(!errorModalOpen);
                }}
              >
                View
              </Button>
            ) : (
              <div />
            );
        } else if (column.field === 'postRank') {
          let postRanks = [];
          get('/getPostRanks').then((res) => {
            postRanks = res;
          });
          column.editComponent = (t) => {
            return (
              <Select value={t.rowData.postRank} onChange={(e) => t.onChange(e.target.value)}>
                {postRanks.map((rank) => (
                  <MenuItem value={rank.wpRole}>{rank.wpRole}</MenuItem>
                ))}
              </Select>
            );
          };
          column.render = (rowData) => <div>{rowData.postRank}</div>;
        } else if (column.field === 'roleId' || column.field === 'wordPressId') {
          column.editable = 'never';
        }
      }
      setColumns(results.columns);
      setUploadId(uploadId);
    });
  }, [refresh]);

  const createSnack = (variant, message) => {
    setSnackBar({ open: true, variant, message });
  };

  const getErrors = (rowData) => {
    get(`/getErrors/${rowData.id}/${rowData.uploadId}`)
      .then((results) => {
        if (results.length > 0) {
          setErrors(results);
        }
      })
      .catch((err) => {
        createSnack('Error', `Could not get errors!: ${err.message}`);
        console.error(err.message);
      });
  };

  const createWorkOrder = () => {
    post('/createWorkOrderFromExternalData', { uploadId })
      .then(({ workOrderId }) => {
        window.open(`/workorder/${workOrderId}`, '_blank');
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const ErrItem = (props) => (
    <tr>
      <td>
        <ul>
          Upload-Id: <b>{props.err.uploadId}</b>
        </ul>
      </td>
      <td>
        <ul>
          Error Message: <b>{props.err.errMessage}</b>
        </ul>
      </td>
    </tr>
  );

  const handleClose = () => {
    setSnackBar({ open: false, message: '', variant: '' });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent onClose={handleClose} style={{ backgroundColor: notificationStyles[snackBar.variant] }} message={snackBar.message} />
      </Snackbar>
      <Modal
        open={errorModalOpen}
        children="div"
        style={{ overflow: 'auto' }}
        onClose={() => {
          setOpenErrorModal(!errorModalOpen);
          setErrors([
            {
              errMessage: 'Loading...',
              error_id: 'Loading...',
              repNum: 'Loading...',
              table_name: 'Loading...',
              uploadId: 'Loading...',
            },
          ]);
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '80%',
            margin: 'auto',
            top: '25%',
            backgroundColor: 'white',
            padding: '15px',
            outline: 'none',
          }}
        >
          <table>
            {errors.map((err) => (
              <ErrItem err={err} />
            ))}
          </table>
        </div>
      </Modal>
      <Button onClick={createWorkOrder}>Create</Button>
      <MaterialTable
        title="Data"
        columns={columns}
        data={uploads}
        options={{ exportButton: true, paging: true, pageSizeOptions: [10, 25, 50], pageSize: 10 }}
        editable={{
          onRowAdd: async (newData) => {
            await post('/addUploadRow', { uploadId, newData })
              .then((results) => {
                setRefresh(refresh + 1);
                createSnack('Success', 'Added Successfully!');
              })
              .catch((err) => {
                console.error(err);
                createSnack('Error', `Could not be added!: ${err.message}`);
              });
          },
          onRowUpdate: async (newData, oldData) => {
            await post('/editUploadRow', { newData })
              .then((results) => {
                setRefresh(refresh + 1);
                createSnack('Success', 'Changes were saved successfully!');
              })
              .catch((err) => {
                console.error(err);
                createSnack('Error', `Changes not saved!: ${err.message}`);
              });
          },
          onRowDelete: async (oldData) => {
            await post('/deleteUploadRow', { oldData })
              .then((results) => {
                setRefresh(refresh + 1);
                createSnack('Success', 'Deleted!');
              })
              .catch((err) => {
                console.error(err);
                createSnack('Error', `Could not be deleted!: ${err.message}`);
              });
          },
        }}
      />
    </div>
  );
};

export default Data;
