/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { get } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import PropTypes from 'prop-types';
import InboundPackage from './InboundPackage';

const InboundPackages = ({ costingID, WorkOrderId, couriers, allowAdd }) => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getInboundPackages();
  }, []);

  const getInboundPackages = () => {
    get(`/workorder/${WorkOrderId}/costing/${costingID}/inbound-packages`)
      .then((rawPackages) => {
        setPackages(rawPackages.reverse());
      })
      .catch((err) => createSnack('Error', err.message));
  };

  return (
    <div>
      {allowAdd && (
        <Grid container spacing={1} direction="row" alignItems="center" justify="center">
          <InboundPackage
            WorkOrderId={WorkOrderId}
            costingID={costingID}
            couriers={couriers}
            shipPackage={{ expectedDelivery: new Date() }}
            getInboundPackages={getInboundPackages}
          />
        </Grid>
      )}
      {Boolean(couriers?.length) &&
        Boolean(packages?.length) &&
        packages.map((pack) => (
          <div key={pack.id}>
            <InboundPackage
              viewOnly={true}
              WorkOrderId={WorkOrderId}
              costingID={costingID}
              couriers={couriers}
              shipPackage={pack}
              getInboundPackages={getInboundPackages}
            />
          </div>
        ))}
    </div>
  );
};

InboundPackages.propTypes = {
  costingID: PropTypes.number,
  WorkOrderId: PropTypes.string,
  couriers: PropTypes.array,
  allowAdd: PropTypes.bool,
};

InboundPackages.displayName = 'InboundPackages';

export default InboundPackages;
