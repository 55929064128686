/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';

function Privacy({ logout }) {
  return (
    <section>
      <div>
        <div>
          <div>
            <div>
              <div>
                <h1>Privacy Policy</h1>
              </div>
              <Grid container justify="center">
                <Grid item>
                  <p> YBA Connect Privacy Policy </p>
                  <p>
                    {' '}
                    "YBA Connect” provides "YBA Shirts order plugin services" to merchants who use Shopify to power their stores. This Privacy Policy
                    describes how personal information is collected, used, and shared when you install or use the App in connection with your
                    Shopify-supported store.{' '}
                  </p>
                  <p> Personal Information the App Collects </p>
                  <p>
                    {' '}
                    When you install the App, we are automatically able to access certain types of information from your Shopify account:
                    write_products, read_products, read_orders, write_orders, read_fulfillments, write_fulfillments, read_shipping, write_shipping,
                    read_inventory,write_inventory, etc.{' '}
                  </p>
                  <p>
                    {' '}
                    Additionally, we collect the following types of personal information from you and/or your customers once you have installed the
                    App: Information about you and others who may access the App on behalf of your store, such as your name, address, email address,
                    phone number, and billing information; Information about individuals who visit your store, such as their IP address, web browser
                    details, time zone, and information about the cookies installed on the particular device.{' '}
                  </p>

                  <p>
                    {' '}
                    We collect personal information directly from the relevant individual, through your Shopify account, or using the following
                    technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique
                    identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. “Log files”
                    track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider,
                    referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information
                    about how you browse the Site.{' '}
                  </p>

                  <p> How Do We Use Your Personal Information? </p>

                  <p>
                    {' '}
                    We use the personal information we collect from you and your customers in order to provide the Service and to operate the App.
                    Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with
                    information or advertising relating to our products or services.{' '}
                  </p>

                  <p> Sharing Your Personal Information </p>

                  <p> We may also share your Personal Information with any of our current or potential third party associates </p>

                  <p>
                    {' '}
                    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena,
                    search warrant or other lawful request for information we receive, or to otherwise protect our rights.{' '}
                  </p>

                  <p>
                    {' '}
                    Behavioural Advertising As described above, we use your Personal Information to provide you with targeted advertisements or
                    marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can
                    visit the Network Advertising Initiative’s (“NAI”) educational page at
                    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.{' '}
                  </p>

                  <p>
                    {' '}
                    You can opt out of targeted advertising by: Contacting YBA Shirts directly by using the contact information below. Additionally,
                    you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:
                    http://optout.aboutads.info/.{' '}
                  </p>

                  <p>
                    {' '}
                    Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that
                    your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through
                    the contact information below.{' '}
                  </p>

                  <p>
                    {' '}
                    Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we
                    might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests
                    listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the
                    United States.{' '}
                  </p>

                  <p>
                    {' '}
                    Data Retention When you place an order through the Site, we will maintain your Order Information for our records unless and until
                    you ask us to delete this information.{' '}
                  </p>

                  <p>
                    {' '}
                    Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for
                    other operational, legal or regulatory reasons.{' '}
                  </p>

                  <p>
                    {' '}
                    Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint,
                    please contact us by e-mail at support@ybashirts.com or by mail using the details provided below:{' '}
                  </p>

                  <p>YBA Shirts 1184 Industrial Park Rd, Orem, UT 84057</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Privacy.propTypes = {
  logout: PropTypes.func,
};

export default Privacy;
