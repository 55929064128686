import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

const CustomTextField = ({ label, value, callback, variant = 'standard', multiline }) => {
  const camelCase = (str) => {
    str = str
      .replace(/\-/g, ' ')
      .replace(/\s[a-z]/g, (str) => str.toUpperCase())
      .replace(/\s+/g, '')
      .replace(/^[A-Z]/g, (str) => str.toLowerCase());
    return str;
  };

  return (
    <Grid item xs={12} md="auto">
      <TextField
        id={camelCase(label)}
        label={label}
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ margin: '8px' }}
        margin="normal"
        variant={variant}
        onChange={callback}
        autoComplete="off"
        component="div"
        multiline={multiline}
      />
    </Grid>
  );
};

export default CustomTextField;

CustomTextField.propTypes = {
  callback: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  adornment: PropTypes.func,
  multiline: PropTypes.bool,
};
