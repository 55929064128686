/* eslint prefer-destructuring:0 */
/* eslint array-callback-return:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import { Button, Tab, Tabs } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import StatusFilters from './StatusFilters';
import SalesPersonFilters from './SalesPersonFilters';
import TabPanel from './TabPanel';
import OrderProcessorFilters from './OrderProcessorsFilters';
import AdvancedFilters from './AdvancedFilters';
import '../../../Utils/styles/Filters.css';

export default class DashboardFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  determineAdvancedColor = () => {
    let color = '';
    this.props.advancedCheckBoxData.map((x) => {
      if (x.isChecked) color = green[500];
    });
    if (this.props.filterByDate) color = green[500];
    return color;
  };

  render() {
    const {
      state: { value },
      props: {
        get,
        post,
        loadDashBoardFilter,
        user,
        completedFilters,
        uncompletedFilters,
        salesPersons,
        orderProcessors,
        salesOnClick,
        csrOnClick,
        salesFilter,
        csrFilter,
        advancedCheckBoxData,
        handleCheckBoxChange,
        clearAllFilters,
        isLoading,
        startDate,
        endDate,
        handleDatePickerChange,
        handleFilterByDateChange,
        filterByDate,
        dateFilterType,
        changeDateFilterType,
      },
      handleChange,
    } = this;
    return (
      <div className="status-filter-container">
        <div className="status-filter-toolbar">
          <div className="green-sqaure-container">
            <span className="green-square" />
            <p className="green-square-text">= Filter applied</p>
          </div>
          <Button color="primary" size="small" onClick={clearAllFilters}>
            Clear all Filters
          </Button>
        </div>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab
            className="filter-tab"
            style={{
              minWidth: '25%',
              width: '25%',
              color: !completedFilters.length && !uncompletedFilters.length ? '' : green[500],
            }}
            label="Status"
          />
          <Tab
            className="filter-tab"
            style={{
              minWidth: '25%',
              width: '25%',
              color: salesFilter ? green[500] : '',
            }}
            label="Sales"
          />
          <Tab
            className="filter-tab"
            style={{
              minWidth: '25%',
              width: '25%',
              color: csrFilter ? green[500] : '',
            }}
            label="SA"
          />
          <Tab
            className="filter-tab"
            style={{
              minWidth: '25%',
              width: '25%',
              color: this.determineAdvancedColor(),
            }}
            label="MISC"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <StatusFilters
            user={user}
            completedFilters={completedFilters}
            uncompletedFilters={uncompletedFilters}
            loadDashBoardFilter={loadDashBoardFilter}
            get={get}
            post={post}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SalesPersonFilters salesPersons={salesPersons} salesOnClick={salesOnClick} salesFilter={salesFilter} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OrderProcessorFilters orderProcessors={orderProcessors} csrOnClick={csrOnClick} csrFilter={csrFilter} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AdvancedFilters
            advancedCheckBoxData={advancedCheckBoxData}
            handleCheckBoxChange={handleCheckBoxChange}
            isLoading={isLoading}
            startDate={startDate}
            endDate={endDate}
            handleDatePickerChange={handleDatePickerChange}
            handleFilterByDateChange={handleFilterByDateChange}
            filterByDate={filterByDate}
            dateFilterType={dateFilterType}
            changeDateFilterType={changeDateFilterType}
          />
        </TabPanel>
      </div>
    );
  }
}
