/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import FiltersStore from '../../../Stores/Fulfillment/Filters/Store';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import OrdersStore from '../../../Stores/Fulfillment/Orders/Store';
import { loadOrders } from '../../../Stores/Fulfillment/Orders/Actions';
import { Grid, TablePagination } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { get } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';

const useStyles = makeStyles({
  list: {
    width: '300px',
  },
  fullList: {
    width: 'auto',
  },
});

function useQuery() {
  return queryString.parse(useLocation().search);
}

export default function TemporaryDrawer({ customers, stores }) {
  const queryParams = useQuery();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({ ...FiltersStore.getFilters() });
  const [statuses, setStatuses] = useState([]);
  const [countries, setCountries] = useState([]);

  const handleFilterChange = () => {
    setFilters({ ...FiltersStore.getFilters() });
  };

  useEffect(() => {
    get('/contacts/countries').then(({ countries }) => setCountries(countries));
    updateFilters({ storeDisabled: false, customerDisabled: false, page: 0 });
    FiltersStore.on('change', handleFilterChange);
    return () => {
      FiltersStore.removeListener('change', handleFilterChange);
    };
  }, []);

  const addQueryParam = (label, value) => {
    const params = new URLSearchParams();
    console.log(queryParams);
    if (Object.keys(queryParams).length) {
      for (const key of Object.keys(queryParams)) {
        if (key !== label) {
          params.append(key, queryParams[key]);
        }
      }
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        params.append(label, val);
      });
    } else if (label === 'startDate' || label === 'endDate') {
      const month = value.getMonth() + 1;
      const day = value.getDate();
      const year = value.getFullYear();
      params.append(label, `${month}-${day}-${year}`);
    } else {
      params.append(label, value);
    }
    history.push({ search: params.toString() });
  };

  const removeQueryParam = (label) => {
    const params = new URLSearchParams();
    if (Object.keys(queryParams).length) {
      for (const key of Object.keys(queryParams)) {
        if (key !== label) {
          params.append(key, queryParams[key]);
        }
      }
    }
    params.delete(label);
    history.push({ search: params.toString() });
  };

  const loadStatuses = () => {
    const filter = {
      eager: {
        $where: {
          fulfillment: true,
        },
      },
    };
    get('/statuses', { filter }, 'obj')
      .then((results) => {
        setStatuses(results);
      })
      .catch((err) => {
        createSnack('Error', err?.message);
      });
  };

  useEffect(() => {
    loadStatuses();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const DateFilter = ({ label, value, filter }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="endDate"
        label={label}
        size={'small'}
        value={value}
        onChange={(newDate) => {
          updateFilters({ [filter]: newDate });
        }}
        style={{ width: '250px' }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );

  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer}>Filters</Button>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer}>
          <div role="presentation" className={classes.list}>
            <Grid container spacing={1} alignItems="center" justify="space-evenly" direction="column">
              <Grid item xs={12}>
                <h1>Filters</h1>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="customers"
                  value={filters.customer}
                  size={'small'}
                  options={customers}
                  getOptionLabel={(option) => option.name}
                  disabled={filters.customerDisabled}
                  style={{ width: '250px' }}
                  onChange={(e, v) => {
                    if (v) {
                      addQueryParam('customer', v.id);
                    } else {
                      removeQueryParam('customer');
                    }
                    updateFilters({
                      customer: v,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="stores"
                  value={filters.store}
                  size={'small'}
                  options={stores?.filter((s) => (!filters.customer ? true : s.customerId === filters.customer.id))}
                  getOptionLabel={(option) => option.name}
                  disabled={filters.storeDisabled}
                  style={{ width: '250px' }}
                  onChange={(e, v) => {
                    if (v) {
                      addQueryParam('store', v.id);
                    } else {
                      removeQueryParam('store');
                    }
                    updateFilters({
                      store: v,
                    });
                  }}
                  // style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Store" variant="outlined" />}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="status"
                  value={filters.statuses}
                  size={'small'}
                  multiple
                  options={statuses}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, v) => {
                    const statusIds = v.map((v) => v.id);
                    if (v) {
                      addQueryParam('status', statusIds);
                    } else {
                      removeQueryParam('store');
                    }
                    updateFilters({
                      statuses: v,
                    });
                  }}
                  style={{ width: '250px' }}
                  renderInput={(params) => <TextField {...params} label="Status" />}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="ship-to-country"
                  value={filters.country}
                  size={'small'}
                  options={countries}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, v) => {
                    if (v) {
                      addQueryParam('countryId', v.id);
                    } else {
                      removeQueryParam('countryId');
                    }
                    updateFilters({
                      country: v,
                    });
                  }}
                  style={{ width: '250px' }}
                  renderInput={(params) => <TextField {...params} label="Ship to Country" />}
                />
              </Grid>
              <Grid item>
                <h3>Dates</h3>
              </Grid>
              <Grid item>
                <DateFilter label="Order Placed Start" value={filters.startDate} filter="startDate" />
              </Grid>
              <Grid item>
                <DateFilter label="Order Placed End" value={filters.endDate} filter="endDate" />
              </Grid>
              <Grid item>
                <DateFilter label="Ship By Start" value={filters.shipByDateStart} filter="shipByDateStart" />
              </Grid>
              <Grid item>
                <DateFilter label="Ship by End" value={filters.shipByDateEnd} filter="shipByDateEnd" />
              </Grid>
              <Grid item>
                <DateFilter label="Expected Arrival Start" value={filters.expectedArrivalDateStart} filter="expectedArrivalDateStart" />
              </Grid>
              <Grid item>
                <DateFilter label="Expected Arrival End" value={filters.expectedArrivalDateEnd} filter="expectedArrivalDateEnd" />
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    const params = new URLSearchParams();
                    history.push({ search: params.toString() });
                    updateFilters({
                      customer: null,
                      store: null,
                      statuses: [],
                      startDate: null,
                      endDate: null,
                      shipByDateStart: null,
                      shipByDateEnd: null,
                      expectedArrivalDateStart: null,
                      expectedArrivalDateEnd: null,
                    });
                  }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
