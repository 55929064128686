import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const ContactAutocomplete = ({ callback, contacts, contact, variant }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(contact);
  }, [contact]);

  const optionLabel = (option) => {
    const { address1, address2, city, zip, state, country, co, organization } = option;
    let result = `${organization}, \n${co}, \n${address1}`;
    if (address2) result += `,\n${address2}`;
    return result;
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (newValue === null || newValue.id) {
            callback(newValue);
          }
        }}
        id="contactsAutocomplete"
        options={contacts}
        getOptionLabel={(option) => (option ? optionLabel(option) : null)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => (option ? optionLabel(option) : null)}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            multiline
            size="small"
            variant={variant ? variant : 'outlined'}
            label="Contact"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default ContactAutocomplete;

ContactAutocomplete.propTypes = {
  callback: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
};
