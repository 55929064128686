import { get, post, remove } from '../../../../Functions/API';
import WorkOrderStore from '../../../WorkOrder/Store';
import { checkQBURL } from '../index';
import { createDialog } from '../../../Dialog/Actions';
import { createSnack } from '../../../Snack/Actions';
import { updateWorkOrder } from '../../../WorkOrder/Actions';

export const createInvoice = (body) => {
  return post(`/quickbooks/invoices`, body)
    .then(({ qbInvoiceCreated, qbInvoiceId, invoicePDF }) => {
      WorkOrderStore.updateObj({ qbInvoiceCreated, qbInvoiceId });
      createSnack('Success', 'Successfully Created Invoice');
      return { invoicePDF };
    })
    .catch((err) => {
      console.log(err);
      if (err?.code === '6140') {
        createDialog({
          content: 'Invoice already exists with this Doc Number. Would you like to overwrite it?',
          title: 'Overwrite Invoice?',
          actions: [
            { title: 'no', color: 'secondary', callback: null },
            { title: 'yes', color: 'primary', callback: () => overwriteInvoice(body.workOrderID) },
          ],
        });
        throw err;
      } else {
        checkQBURL(err);
      }
    });
};
export const deleteInvoice = (id) => {
  return remove(`/quickbooks/invoices/${id}`)
    .then((results) => {
      WorkOrderStore.updateObj({ qbInvoiceCreated: 0, qbInvoiceId: null });
      createSnack('Success', 'Successfully Deleted Invoice');
      return results;
    })
    .catch((err) => {
      if (err?.code === '610') {
        const { id: workOrderId } = WorkOrderStore.getWorkOrder();
        createDialog({
          content: 'Invoice has been deleted in QuickBooks would you like to delete it in MSAS as well?',
          title: 'Overwrite Invoice?',
          actions: [
            { title: 'no', color: 'secondary', callback: null },
            { title: 'yes', color: 'primary', callback: () => updateWorkOrder(workOrderId, { qbInvoiceCreated: 0, qbInvoiceId: null }) },
          ],
        });
        throw err;
      } else {
        checkQBURL(err);
      }
    });
};

const overwriteInvoice = (workOrderID) => {
  return createInvoice({ workOrderID, overwrite: true });
};
