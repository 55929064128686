/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import AccountingReport from '../Accounting/AccountingReport';
import WorkOrderTotals from '../Common/WorkOrderTotals';
import TotalProfit from '../Common/TotalProfit';
import FuturePrints from '../Production/FuturePrints';
import TodaysPrints from '../Production/TodaysPrints';
import WaitingOrders from '../Common/WaitingOrders';
import MiniWorkOrderStatusGraph from '../Common/MiniWorkOrderStatusGraph';
import WorkOrderFinancialGraph from '../Accounting/WorkOrderFinancialGraph';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import SalesFinancialChart from '../Accounting/SalesFinancialChart';
import CurrentlyClockedIn from '../Production/CurrentlyClockedIn';
import PrintTypeChart from '../Production/PrintTypeChart';
import PrintsGraph from '../Production/PrintsGraph';
import SalesGoalsTeam from '../Sales/SalesGoals/SalesGoalsOverall';
import SalesGoalsOverall from '../Sales/SalesGoals/SalesGoalsTeam';
import SalesmanComparison from '../Accounting/SalesmanComparison';
import CustomerComparison from '../Accounting/CustomerComparison/index';
import FinancialGraphBySalesType from '../Accounting/FinancialGraphBySalesType';
import OverDueWorkOrders from '../Fulfillment/OverdueWorkOrders';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Upcoming from '../Art/Upcoming';
import CSPIncome from '../Production/CSPIncome';
import InventoryPull from '../Fulfillment/InventoryPull';
import InventoryChecks from '../Production/InventoryChecks';

const AdminHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          <Grid item md={4} sm={6} xs={12}>
            <WaitingOrders get={props.get} user={props.user} type="Order Processor" startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <CSPIncome />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <MiniWorkOrderStatusGraph get={props.get} user={props.user} refresh={props.refresh} />
          </Grid>
          <Grid item xs={12}>
            <LatestWorkOrders {...props} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Upcoming />
          </Grid>
          <Grid item xs={12} md={6}>
            <OverDueWorkOrders refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TotalProfit get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <WorkOrderTotals get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <AccountingReport get={props.get} startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InventoryPull />
          </Grid>
          <Grid item xs={12} md={6}>
            <SalesGoalsTeam get={props.get} refresh={props.refresh} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SalesGoalsOverall get={props.get} refresh={props.refresh} />
          </Grid>
          {/* <Grid item xl={9} md={8} xs={12}> */}
          {/*    <WorkOrderFinancialGraph get={props.get} refresh={props.refresh}/> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <SalesmanComparison refresh={props.refresh} />
          </Grid>
          {/*<Grid item md={4} xl={3} xs={12}>*/}
          {/*  <SalesFinancialChart get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <FinancialGraphBySalesType get={props.get} post={props.post} user={props.user} />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <CustomerComparison get={props.get} post={props.post} user={props.user} />
          </Grid>
          <Grid item xs={12}>
            <InventoryChecks />
          </Grid>
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <FuturePrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <TodaysPrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item md={4} xl={3} xs={12}>*/}
          {/*  <PrintTypeChart get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={8} xl={9}>*/}
          {/*  <CurrentlyClockedIn get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <PrintsGraph get={props.get} post={props.post} refresh={props.refresh} />*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default AdminHome;
