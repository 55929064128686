import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { get } from '../../../../../Functions/API';
import {
  Button,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Checkbox,
  ListItemIcon,
  Typography,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { newInvoiceLines } from '../../../../../Stores/Billing/Actions';
import { KeyboardArrowUp, KeyboardArrowDown, Delete, FileCopy, Add } from '@material-ui/icons';

const ProductToBilling = ({ workOrderID, close }) => {
  const [products, setProducts] = useState([]);
  const [descriptionTooltip, setDescriptionTooltip] = useState(false);
  const [descriptionOptions, setDescriptionOptions] = useState([
    { title: 'Color', field: 'color', selected: true },
    { title: 'Type', field: 'type', selected: true },
    { title: 'Vendor', field: 'vendor', selected: false },
    { title: 'Youth Style', field: 'youthStyle', selected: false },
    { title: 'Adult Style', field: 'adultStyle', selected: false },
    { title: 'Team', field: 'team', selected: false },
    { title: 'Add SKU', field: 'addSku', selected: false },
    { title: 'Add SKU Name', field: 'addSkuName', selected: false },
    { title: 'Remove SKU', field: 'rmSku', selected: false },
    { title: 'Remove SKU Name', field: 'rmSkuName', selected: false },
    { title: 'Notes', field: 'notes', selected: false },
    { title: 'Art', selected: true },
  ]);

  const [artOptions, setArtOptions] = useState([
    { type: 'art', title: 'Name', field: 'name', selected: false },
    { type: 'art', title: 'Color', field: 'color', selected: true },
    { type: 'art', title: 'Print Type', field: 'printType', selected: true },
    { type: 'art', title: 'Position', field: 'position', selected: true },
    { type: 'art', title: 'Price List', field: 'type', selected: false },
    { type: 'art', title: 'Reversible', field: 'reversible', selected: false },
  ]);

  const [tableRef, setTableRef] = useState(React.createRef());
  const [height, setHeight] = useState(500);

  const [betweenOptions, setBetweenOptions] = useState([{ type: 'custom', title: 'with', selected: true }]);

  const columns = [
    {
      title: 'Description',
      render: (rowData) => getDescription(rowData),
    },
    { title: 'Sizes', render: (rowData) => rowData.sizes.map((s) => `${s.size}: ${s.quantity}`).join(', ') },
    { title: 'Quantity', field: 'quantity' },
    {
      title: 'Unit Price',
      field: 'unitPrice',
    },
    { title: 'Bill', field: 'bill', render: (rowData) => (rowData.quantity * rowData.unitPrice).toFixed(2) },
  ];

  useEffect(() => {
    get(`/getOrdersForNewBillingReset`, { workOrderID }, 'obj').then((results) => {
      console.log(results);
      results.map((r) => {
        const firstBill = r.sizes[0]?.bill;
        const equalBills = r.sizes.map((s) => s.bill === firstBill);
        if (equalBills.every((b) => b === true)) {
          r.unitPrice = firstBill;
        } else {
          r.unitPrice = null;
        }
      });
      setProducts(results);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHeight(tableRef.current?.offsetHeight);
    }, 500);
  }, [products]);

  // something here
  const submit = () => {
    const invoices = [];
    products.map((p) =>
      invoices.push({
        type: getDescription(p),
        sizes: p.sizes,
        unitPrice: p.unitPrice,
        quantity: p.quantity,
        bill: p.quantity * p.unitPrice,
        orderId: p.ordersID,
        saveType: 'generated',
        workOrderID,
      }),
    );
    newInvoiceLines(invoices);
    close();
  };

  const getDescription = (rowData) => {
    const dO = descriptionOptions.map((d) => {
      if (d.selected) {
        if (d.title === 'Art' && rowData.art.length) {
          return `${betweenOptions.map((o) => o.title).join(' ')}
            ${getArtDescription(rowData)}`;
        } else {
          if (rowData[d.field] && d.type !== 'custom' && d.type !== 'art') {
            return rowData[d.field];
          } else {
            if (d.type === 'custom') {
              return d.title;
            } else if (d.type === 'art') {
              return rowData.art.map((a) => a[d.field]).join(' ');
            } else {
              return '';
            }
          }
        }
      } else {
        return '';
      }
    });
    return dO.join(' ').replace(/\s+/g, ' ').trim();
  };

  const getArtDescription = (rowData) => {
    console.log(rowData.art);
    return rowData.art
      .map((a) =>
        artOptions
          .filter((o) => o.selected)
          .map((o) => (o.field ? a[o.field] : o.title))
          .join(' '),
      )
      .join(' + ');
  };

  const updateDescriptionOptions = (d, type) => {
    switch (type) {
      case 'product': {
        const options = [...descriptionOptions];
        const index = descriptionOptions.indexOf(d);
        options[index].selected = !d.selected;
        setDescriptionOptions(options);
        break;
      }
      case 'art': {
        const options = [...artOptions];
        const index = artOptions.indexOf(d);
        options[index].selected = !d.selected;
        setArtOptions(options);
        break;
      }
      case 'between': {
        const options = [...betweenOptions];
        const index = betweenOptions.indexOf(d);
        options[index].selected = !d.selected;
        setBetweenOptions(options);
        break;
      }
      default:
        console.log('lol');
    }
  };

  const addCustom = (type) => {
    switch (type) {
      case 'product': {
        const p = window.prompt('Add custom product field');
        if (p) {
          const options = [...descriptionOptions];
          options.push({ type: 'custom', title: p, selected: true });
          setDescriptionOptions(options);
        }
        break;
      }
      case 'art': {
        const p = window.prompt('Add custom art field');
        if (p) {
          const options = [...artOptions];
          options.push({ type: 'custom', title: p, selected: true });
          setArtOptions(options);
        }
        break;
      }
      case 'between': {
        const p = window.prompt('Add custom between field');
        if (p) {
          const options = [...betweenOptions];
          options.push({ type: 'custom', title: p, selected: true });
          setBetweenOptions(options);
        }
        break;
      }
      default:
        console.log('lol');
    }
  };

  const removeCustom = (type, d) => {
    switch (type) {
      case 'product': {
        const options = [...descriptionOptions];
        options.splice(descriptionOptions.indexOf(d), 1);
        setDescriptionOptions(options);
        break;
      }
      case 'art': {
        const options = [...artOptions];
        options.splice(artOptions.indexOf(d), 1);
        setArtOptions(options);
        break;
      }
      case 'between': {
        const options = [...betweenOptions];
        options.splice(betweenOptions.indexOf(d), 1);
        setBetweenOptions(options);
        break;
      }
      default:
        console.log('lol');
    }
  };

  const reorder = (type, option, move) => {
    switch (type) {
      case 'product': {
        const index = descriptionOptions.indexOf(option);
        const newIndex = index - move;
        const options = [...descriptionOptions];
        options.splice(index, 1);
        options.splice(newIndex, 0, option);
        setDescriptionOptions(options);
        break;
      }
      case 'art': {
        const index = artOptions.indexOf(option);
        const newIndex = index - move;
        const options = [...artOptions];
        options.splice(index, 1);
        options.splice(newIndex, 0, option);
        setArtOptions(options);
        break;
      }
      case 'between': {
        const index = betweenOptions.indexOf(option);
        const newIndex = index - move;
        const options = [...betweenOptions];
        options.splice(index, 1);
        options.splice(newIndex, 0, option);
        setBetweenOptions(options);
        break;
      }
      default:
        console.log('lol');
    }
  };

  const copyToProduct = (d) => {
    const options = [...descriptionOptions];
    options.push({ ...d });
    setDescriptionOptions(options);
  };

  const filterOption = (type, d, k) => {
    return (
      <ListItem>
        <ListItemIcon>
          <Checkbox
            color={'primary'}
            edge={'start'}
            checked={d.selected}
            tabIndex={-1}
            disableRipple
            onClick={() => updateDescriptionOptions(d, type)}
          />
        </ListItemIcon>
        <ListItemText primary={d.title} secondary={d.type && d.type.toUpperCase()} />
        <ListItemSecondaryAction>
          {type === 'art' && (
            <IconButton size={'small'} onClick={() => copyToProduct(d)}>
              <FileCopy color={'secondary'} fontSize={'small'} />
            </IconButton>
          )}
          <IconButton size={'small'} onClick={() => reorder(type, d, 1)}>
            <KeyboardArrowUp fontSize={'small'} />
          </IconButton>
          <IconButton size={'small'} onClick={() => reorder(type, d, -1)}>
            <KeyboardArrowDown fontSize={'small'} />
          </IconButton>
          {(d.type === 'custom' || (d.type === 'art' && type === 'product')) && (
            <IconButton size={'small'} onClick={() => removeCustom(type, d)}>
              <Delete fontSize={'small'} />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={2} style={{ overflow: 'scroll', height: `${height}px` }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justify={'space-between'}>
                <Grid item xs={6}>
                  <Typography style={{ margin: '10px', fontSize: '1.5rem' }}>Product</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <IconButton color={'primary'} onClick={() => addCustom('product')}>
                    <Add />
                  </IconButton>
                  <Button variant={'contained'} size={'small'} onClick={submit}>
                    Save
                  </Button>
                </Grid>
              </Grid>
              <List dense>{descriptionOptions.map((d, k) => filterOption('product', d, k))}</List>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify={'space-between'}>
                <Grid item xs={6}>
                  <Typography style={{ margin: '10px', fontSize: '1.5rem' }}>Between</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <IconButton color={'primary'} onClick={() => addCustom('between')}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
              <List dense>{betweenOptions.map((d, k) => filterOption('between', d, k))}</List>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify={'space-between'}>
                <Grid item xs={6}>
                  <Typography style={{ margin: '10px', fontSize: '1.5rem' }}>Art</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <IconButton color={'primary'} onClick={() => addCustom('art')}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
              <List dense>{artOptions.map((d, k) => filterOption('art', d, k))}</List>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <div ref={tableRef}>
            <MaterialTable
              title={'Product Data'}
              columns={columns}
              data={products}
              editable={{
                onRowDelete: async (data) => {
                  const p = [...products];
                  p.splice(products.indexOf(data), 1);
                  setProducts(p);
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductToBilling;

ProductToBilling.propTypes = {
  workOrderID: PropTypes.number,
  close: PropTypes.func,
};
