/* eslint no-console:0 */
/* eslint array-callback-return:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
  Modal,
  ButtonGroup,
  LinearProgress,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import DateFilter from '../Common/DateFilter';
import materialColors from '../../Utils/MaterialColors';
import palette from '../../Utils/theme/palette';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: true },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider,
        },
      },
    ],
  },
};

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  actions: {
    justifyContent: 'space-between',
  },
}));

const PrintsGraph = ({ get, refresh }) => {
  const classes = useStyles();
  const [printData, setPrintData] = useState(null);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(6, 'days').format('YYYY-MM-DD'));
  const [selectedOption, setSelectedOption] = useState('Next 7 Days');
  const [open, setOpen] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [graphType, setGraphType] = useState('Bar');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const formatDataSet = (data, dates) => {
    const dataSet = [];

    dates.map((date) => {
      let found = false;
      data.map((x) => {
        const dueDate = moment(x.dueDate).format('D MMM');
        if (date === dueDate) {
          dataSet.push(x.totalPrintsToday);
          found = true;
        }
      });
      if (!found) dataSet.push(0);
    });
    return dataSet;
  };

  const handleDatePickerChange = (date, start) => {
    if (start) {
      setStartDate(moment(date).format('YYYY/MM/DD'));
    } else {
      setEndDate(moment(date).format('YYYY/MM/DD'));
    }
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const formatPrintData = () => {
    const date = moment(endDate);
    const dates = [];
    const data = {
      labels: [],
      datasets: [],
    };

    while (date >= moment(startDate)) {
      dates.push(date.format('D MMM'));
      data.labels.push(date.format('MMM D'));
      date.subtract(1, 'days');
    }

    data.labels.reverse();
    dates.reverse();

    printData.map((x, i) => {
      data.datasets.push({
        label: x.type,
        backgroundColor: materialColors[i],
        borderColor: materialColors[i],
        data: formatDataSet(x.data, dates),
        fill: false,
        pointRadius: 1,
        borderWidth: 2,
        lineTension: 0.2,
      });
    });

    setData(data);
  };

  const getPrintData = async () => {
    setIsLoading(true);
    await get('/getPrintTotals', [
      { name: 'startDate', value: startDate },
      { name: 'endDate', value: endDate },
    ])
      .then((res) => {
        setPrintData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changePrintOption = (e) => {
    const newSelection = e.target.value;
    setSelectedOption(newSelection);
    if (newSelection === 'Last 7 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Last 14 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(13, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Last 30 Days') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Next 7 Days') {
      setStartDate(moment().format('YYYY-MM-DD'));
      setEndDate(moment().add(6, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Next 14 Days') {
      setStartDate(moment().format('YYYY-MM-DD'));
      setEndDate(moment().add(13, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Next 30 Days') {
      setStartDate(moment().format('YYYY-MM-DD'));
      setEndDate(moment().add(29, 'days').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Custom Range') {
      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    getPrintData();
  }, [startDate, endDate, refresh]);

  useEffect(() => {
    if (printData) formatPrintData();
  }, [printData]);

  return (
    <>
      <Modal open={showDatePicker} onClose={() => setShowDatePicker(false)}>
        <div className="date-picker-container">
          <DateFilter startDate={startDate} endDate={endDate} handleDatePickerChange={handleDatePickerChange} />
          <div className="date-picker-button-container">
            <Button style={{ margin: '8px 0' }} variant="contained" size="small" color="primary" onClick={() => setShowDatePicker(false)}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Card className={classes.root}>
        <CardHeader
          action={
            <>
              <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                <InfoIcon />
              </IconButton>
              <Popover
                open={infoOpen}
                anchorEl={anchorEl}
                onClose={openInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                  This graph shows you how many prints {`(and what types)`} are due at a given day
                  <br />
                  <br />
                  Hover/Tap on the bar to see specific quanities. Also, you can limit your selection by clicking on the labels at the top to exclude
                  them from the graph.
                </Typography>
              </Popover>
              <FormControl>
                <Select
                  labelId="printOptionsLabel"
                  id="printOptions"
                  value={selectedOption}
                  onChange={(e) => changePrintOption(e)}
                  style={{ marginTop: '6px' }}
                  onOpen={() => setDropDownOpen(true)}
                  onClose={() => setDropDownOpen(false)}
                >
                  <MenuItem value="Next 7 Days">Next 7 Days</MenuItem>;<MenuItem value="Next 14 Days">Next 14 Days</MenuItem>;
                  <MenuItem value="Next 30 Days">Next 30 Days</MenuItem>;<MenuItem value="Last 7 Days">Last 7 Days</MenuItem>;
                  <MenuItem value="Last 14 Days">Last 14 Days</MenuItem>;<MenuItem value="Last 30 Days">Last 30 Days</MenuItem>;
                  <MenuItem value="Custom Range">
                    {selectedOption === 'Custom Range' && !dropDownOpen
                      ? `${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`
                      : 'Custom Range'}
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          }
          title={
            <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Future/Past Prints</Typography>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <>
            <CardContent style={{ paddingBottom: '16px' }}>
              <div className={classes.chartContainer}>
                {graphType === 'Line' ? data && <Line data={data} options={options} /> : data && <Bar data={data} options={options} />}
                {isLoading && <LinearProgress />}
              </div>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <>
                <ButtonGroup size="small" color="primary" style={{ marginRight: '10px' }}>
                  <Button variant={graphType === 'Bar' ? 'contained' : 'outlined'} onClick={() => setGraphType('Bar')}>
                    Bar
                  </Button>
                  <Button variant={graphType === 'Line' ? 'contained' : 'outlined'} onClick={() => setGraphType('Line')}>
                    Line
                  </Button>
                </ButtonGroup>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => {
                    window.location.href = '/Production/Calendar';
                  }}
                >
                  Overview <ArrowRightIcon />
                </Button>
              </>
            </CardActions>
          </>
        </Collapse>
      </Card>
    </>
  );
};

export default PrintsGraph;
