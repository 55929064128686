/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Store from '../../../../../Stores/Billing/Store';
import { deleteInvoiceLine, loadInvoice, newInvoiceLine, updateInvoiceLine, deleteAll } from '../../../../../Stores/Billing/Actions';
import { Checkbox, Paper, Slide, TextField, Tooltip } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import InvoiceDragModal from './Modals/InvoiceDragModal';
import Steve from './Modals/Steve';
import MaterialTable from 'material-table';
import InvoiceHeader from './InvoiceHeader';
import { get, post, patch } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomersStore from '../../../../../Stores/Customers/Store';
import { loadCustomers } from '../../../../../Stores/Customers/Actions';
import UserStore from '../../../../../Stores/User/Store';
import CreditMemoHeader from './CreditMemoHeader';
import ContactAutocomplete from '../../../../Contacts/ContactAutocomplete';
import CustomPopper from './Modals/CustomPopper';
import UpdateBoth from './Modals/UpdateBoth';
import { closeDialog, createDialog } from '../../../../../Stores/Dialog/Actions';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SizesCell from './SizesCell';
import SizesModal from './Modals/SizesModal';
import { openQuickBooksPopUp } from '../../../../QuickBooks/functions';
import { checkQBAuth } from '../../../../../Stores/QuickBooks/Actions';
import { WorkOrderStore } from '../../../../../Stores';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const Billing = () => {
  const [columns, setColumns] = useState([]);
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0);
  const [totalActualCost, setTotalActualCost] = useState(0);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({ type: [] });
  const [activeInvoice, setActiveInvoice] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState({});
  const [needUpdate, setNeedUpdate] = useState(false);
  const [steve, setSteve] = useState(false);
  const [steveDirection, setSteveDirection] = useState('right');
  const [showDragModal, setShowDragModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [splitOrders, setSplitOrders] = useState([]);
  const [creditMemoId, setCreditMemoId] = useState(null);
  const [creditMemoQBId, setCreditMemoQBId] = useState(null);
  const [invoicePDF, setInvoicePDF] = useState('');
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [splitInvoiceDisabled, setSplitInvoiceDisabled] = useState(true);
  const user = UserStore.getUser();
  const [customers, setCustomers] = useState(CustomersStore.getCustomers());

  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    CustomersStore.on('change', getCustomers);

    if (CustomersStore.getCustomers().length === 0) {
      loadCustomers();
    } else {
      getCustomers();
    }
    return () => {
      CustomersStore.removeListener('change', getCustomers);
    };
  }, []);

  const getCustomers = () => {
    setCustomers([...CustomersStore.getCustomers()]);
  };

  useEffect(() => {
    Store.on('change', getInvoice);
    if (workOrder.id) {
      console.log(workOrder.id);
      loadInvoice(workOrder.id);

      get('/totalCosting', [{ name: 'workOrderID', value: workOrder.id }]).then(({ totalEstimatedCost, totalActualCost }) => {
        setTotalEstimatedCost(totalEstimatedCost);
        setTotalActualCost(totalActualCost);
      });
      get('/getCreditMemo', [{ name: 'workOrderID', value: workOrder.id }]).then(({ id, quickBooksId }) => {
        if (id) {
          setCreditMemoId(id);
          setCreditMemoQBId(quickBooksId);
        }
      });
      const filter = {
        eager: {
          $where: {
            type: 'type',
          },
        },
      };
      get('/options', { filter }).then((results) => {
        setOptions({ type: results });
      });
      get('/qbInvoiceCreated', [{ name: 'workOrderID', value: workOrder.id }]).then(({ message }) => {
        setActiveInvoice(message === 1);
      });
      get('/invoicePDF', [{ name: 'workOrderID', value: workOrder.id }]).then((results) => {
        setInvoicePDF(results);
      });
      const customer = customers.find((c) => c.id === workOrder.customerID);
      setActiveCustomer(customer);
      // TODO connectToQBOnInvoiceTab
      if (user.group === 'Admin' || user.group.includes('Accounting')) {
        checkQBAuth();
      }
    }

    return () => {
      Store.removeListener('change', getInvoice);
    };
  }, [workOrder.id]);

  useEffect(() => {
    setColumns(getColumns(data));
  }, [data, workOrder.splitInvoice]);

  useEffect(() => {
    if (workOrder.id) {
      loadInvoice(workOrder.id);
    }
  }, [workOrder.usePackagedSizes]);

  useEffect(() => {
    let split = data.some((item) => item.qbInvoiceId !== null);
    setSplitInvoiceDisabled(split || activeInvoice);
  }, [data, activeInvoice]);

  useEffect(() => {
    setActiveInvoice(Boolean(workOrder.qbInvoiceCreated));
  }, [workOrder.qbInvoiceCreated]);

  const getInvoice = () => {
    setData([...Store.getInvoice()]);
  };

  const toggleSteve = () => {
    setSteveDirection('right');
    setSteve(true);
    setTimeout(() => {
      setSteveDirection('left');
      setSteve(false);
    }, 2000);
  };

  const getColumns = (rows) => {
    const options2 = [
      {
        title: 'Type',
        field: 'type',
        sorting: false,
        render: (dataRow) => {
          const stateOptions = options.type;
          const index = stateOptions.findIndex((option) => option.value === dataRow.type);
          if (index > -1) {
            const type = stateOptions[index].name;
            return <div>{type}</div>;
          }
          return <div>{dataRow.type}</div>;
        },
      },
      {
        title: 'Sizes',
        field: 'sizes',
        sorting: false,
        editable: 'never',
        render: (dataRow) => <SizesCell dataRow={dataRow} usePackagedSizes={workOrder.usePackagedSizes} />,
      },
      {
        title: 'Quantity',
        field: 'quantity',
        sorting: false,
        render: (rowData) =>
          rowData.quantity === rowData.sizes.reduce((total, s) => s.quantity + total, 0) ? (
            <span>{rowData.quantity}</span>
          ) : (
            <Tooltip title={'Sizes contain quantities that do not match this line'}>
              <span style={{ color: 'red' }}>{rowData.quantity}</span>
            </Tooltip>
          ),
        editComponent: (props) => (
          <>
            {Boolean(props?.rowData?.orderId) ? (
              <>{props?.rowData?.quantity}</>
            ) : (
              <TextField
                type="number"
                label="Quantity"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value ? parseInt(e.target.value) : 0)}
                variant="filled"
              />
            )}
          </>
        ),
      },
      {
        title: 'Unit Price',
        field: 'unitPrice',
        sorting: false,
        render: (dataRow) => {
          const uniqueUnitPrices = [...new Set(dataRow?.sizes.map((s) => s.bill))];
          if (uniqueUnitPrices.length > 1) {
            return (
              <Tooltip title={'Sizes contain bill amounts that do not match this line'}>
                <span style={{ color: 'red' }}>{formatter.format(dataRow.unitPrice)}</span>
              </Tooltip>
            );
          }
          return <>{formatter.format(dataRow.unitPrice)}</>;
        },
        editComponent: (props) => (
          <>
            {Boolean(props?.rowData?.orderId) ? (
              <>{props?.rowData?.unitPrice}</>
            ) : (
              <TextField
                label="Unit Price"
                value={props.value}
                type="number"
                onChange={(e) => props.onChange(e.target.value ? e.target.value : 0)}
                variant="filled"
              />
            )}
          </>
        ),
      },
      {
        title: 'Bill',
        field: 'bill',
        editable: 'never',
        sorting: false,
        render: (dataRow) => <>{formatter.format(dataRow.bill)}</>,
      },
      {
        title: 'Product',
        field: 'orderId',
        editable: 'never',
        sorting: false,
        render: (dataRow) => <Checkbox disabled={true} checked={Boolean(dataRow.orderId)} value={Boolean(dataRow.orderId)} />,
      },
    ];
    // TODO editTaxExemptInvoiceTab
    if (user.group === 'Accounting Admin' || user.group === 'Admin') {
      options2.push({
        title: 'Exempt',
        field: 'taxExempt',
        sorting: false,
        editComponent: (props) => (
          <Checkbox checked={Boolean(props.value)} value={Boolean(props.value)} onChange={() => props.onChange(props.value ? 0 : 1)} />
        ),
        render: (dataRow) => <Checkbox disabled={true} checked={Boolean(dataRow.taxExempt)} value={Boolean(dataRow.taxExempt)} />,
      });
    }
    let groupAsSet = false;
    for (const row of rows) {
      if (row.groupAsSet) {
        groupAsSet = true;
      }
    }
    if (groupAsSet) {
      options2.push({
        title: 'Set',
        field: 'groupAsSet',
        editable: 'onUpdate',
        render: (rowData) =>
          rowData &&
          rowData.groupAsSet !== null && (
            <FormControlLabel
              control={<Switch checked={Boolean(rowData.groupAsSet)} value={Boolean(rowData.groupAsSet)} color="primary" />}
              label="Set"
            />
          ),
      });
    }
    if (workOrder.splitInvoice) {
      options2.push({
        title: 'Contact',
        field: 'contactId',
        editComponent: (t) => {
          const contacts = workOrder.contacts.filter((c) => c.type === 'billing');
          const contact = workOrder.contacts.find((c) => t.value === c.id);
          const onChange = (contactObj) => {
            t.onChange(contactObj?.id);
          };
          return <ContactAutocomplete callback={onChange} contact={contact} contacts={contacts} variant="standard" />;
        },
        render: (rowData) => {
          const contact = workOrder.contacts.find((c) => rowData.contactId === c.id);
          if (!contact) return '';
          const { address1, address2, city, zip, state, country, co } = contact;
          let result = `${co}, \n${address1}`;
          if (address2) result += `,\n${address2}`;
          return result;
        },
      });
      options2.push({
        title: 'Invoice Number',
        field: 'qbInvoiceDocNumber',
      });
      // TODO invoiceInQBInvoiceTab
      if (user.group.includes('Accounting') || user.group === 'Admin') {
        options2.push({
          title: 'Invoice in QB',
          field: 'qbInvoiceId',
          render: (rowData) => {
            return <Checkbox value={Boolean(rowData.qbInvoiceId)} checked={Boolean(rowData.qbInvoiceId)} />;
          },
        });
      }
    }
    return options2;
  };

  // looks like this is no longer being used??
  const findCustomer = (customerArray) => {
    for (let i = 0; i < customerArray.length; i++) {
      if (workOrder.customerID === customerArray[i].id) {
        if (customerArray[i].quickbooksId) {
          return true;
        }
      }
    }
    if (user.group.includes('Accounting') || user.group.match('Admin')) {
      createSnack('Accounting', 'This customer has not been added to Quickbooks yet.');
    }
    return false;
  };

  //todo: move where it gets called and instead of set data update invoice store
  const submitAutomaticSplitInvoice = () => {
    setInvoicesLoading(true);
    createSnack('', 'Please Wait while your invoices are being created');
    post('/createAutomaticSplitInvoice', { workOrderID: workOrder.id })
      .then((results) => {
        let { message, errorMessages, data } = results;
        setData(data);
        createSnack(errorMessages ? 'Error' : 'Success', errorMessages ? errorMessages : message);
      })
      .catch((err) => {
        console.log(err);
        createSnack('Error', `${err?.message} ${err?.response?.data?.detail?.join(', ')}`);
      })
      .finally(() => setInvoicesLoading(false));
  };

  //todo: make a way to bulk update invoices and do that instead of set data
  const submitContact = (contact, orders) => {
    post('/addContactToInvoice', { orders, contact })
      .then((results) => {
        let { message, data } = results;
        setData(data);
        createSnack('Success', message);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  //todo: make a way to bulk update invoices and do that instead of set data
  const submitDocNum = (docNumber, orders) => {
    post('/addDocNumToInvoice', { orders, docNumber })
      .then((results) => {
        let { message, data } = results;
        setData(data);
        createSnack('Success', message);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  //todo: make a way to delete through workorderid and replace this with that
  const deleteAllRows = () => {
    let c1, c2, c3;
    c1 = window.confirm('Are you sure you know what you are doing??');
    if (c1) {
      c2 = window.confirm(`You are about to delete ALL invoice rows! Are you sure you want to proceed???`);
      if (c2) {
        c3 = window.confirm('You actually want to delete ALL of them??? this CAN NOT be undone!!! Proceed at your own risk.....');
      }
    }
    if (c1 && c2 && c3) {
      deleteAll(workOrder.id);
      checkSentToQuickBooks();
    }
  };

  const deleteSplitInvoices = (splitOrders) => {
    let found = false;
    if (Array.isArray(splitOrders)) {
      const invoiceId = splitOrders[0].qbInvoiceId;
      for (const order of splitOrders) {
        if (order.qbInvoiceId !== invoiceId) {
          found = true;
        }
      }
    }

    let c = !found
      ? true
      : window.confirm('Not all of the selected lines are part of the same invoice, are you sure you want to delete them all from quickbooks?');

    if (c) {
      setInvoicesLoading(true);
      createSnack('', 'Please wait while these invoices are deleted');
      post('/deleteSplitInvoices', { splitOrders })
        .then((results) => {
          setData(results.data);
          createSnack('Success', results.message);
          updateWorkOrder(workOrder.id, { balance: null, paidDate: null });
        })
        .catch((err) => {
          createSnack('Error', err.message);
        })
        .finally(() => setInvoicesLoading(false));
    }
  };

  //todo: credit memo stuff needs to be its own component updates can use the invoice store
  const createCreditMemo = () => {
    post('/createCreditMemo', { workOrderID: workOrder.id })
      .then(({ Id }) => {
        setCreditMemoQBId(Id);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const createMSASCreditMemo = () => {
    post('/createMSASCreditMemo', { workOrderID: workOrder.id })
      .then((results) => {
        setCreditMemoId(results.insertId);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const deleteCreditMemo = () => {
    post('/deleteCreditMemo', { workOrderID: workOrder.id })
      .then(() => {
        setCreditMemoQBId(null);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  //todo: arranging the table needs to be its own component updates can use the invoice store
  const saveOrdering = (orderedInvoices) => {
    const indexedInvoices = orderedInvoices.map((invoice, index) => {
      return { id: invoice.id, index, workOrderID: workOrder.id };
    });
    patch('/invoice', indexedInvoices)
      .then((results) => {
        results.sort((a, b) => a.index - b.index);
        setData(results);
        setShowDragModal(false);
        createSnack('Success', 'Invoice Ordering Updated');
        checkSentToQuickBooks();
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  let actions = [
    (rowData) => ({
      icon: () => <FormatListBulletedIcon />,
      tooltip: 'View Old Sizes',
      disabled: !rowData?.oldSizes && rowData?.orderId,
      onClick: (event, rowData) => {
        let jsonSizes = [];
        if (typeof rowData.oldSizes === 'string') jsonSizes = JSON.parse(rowData.oldSizes);
        createDialog({
          content: <SizesModal origSizes={jsonSizes} invoiceId={rowData.id} workOrderID={workOrder.id} />,
          title: 'Unlinked Old Sizes',
          actions: null,
        });
      },
    }),
  ];
  if (workOrder.splitInvoice) {
    // actions.push((rowData) => ({
    //   icon: () => {
    //     return <CustomPopper callback={submitDocNum} type="DocNum" orders={rowData} workOrder={workOrder} />;
    //   },
    // }));
    // actions.push((rowData) => ({
    //   icon: () => {
    //     return <CustomPopper callback={submitContact} type="Contact" orders={rowData} workOrder={workOrder} />;
    //   },
    // }));
    actions.push((rowData) => ({
      icon: () => {
        return (
          <UpdateBoth
            callback={async (docNum, contact, orders) => {
              if (docNum) {
                submitDocNum(docNum, orders);
              }
              if (contact) {
                submitContact(contact, orders);
              }
            }}
            orders={rowData}
            workOrder={workOrder}
          />
        );
      },
    }));
    // TODO deleteInvoiceButtonInvoiceTab
    if (user.group === 'Admin' || user.group.includes('Accounting')) {
      actions.push({
        tooltip: 'Delete Invoice',
        icon: () => {
          return <Button variant="contained">Delete Invoice</Button>;
        },
        onClick: (event, data) => deleteSplitInvoices(data),
      });
    }
  }

  const checkCreditMemoData = (newData) => {
    if (newData.unitPrice > 0) {
      createSnack('Error', 'Rate must be negative. Changing rate to negative.');
      newData.unitPrice = newData.unitPrice * -1;
    }
    if (newData.quantity < 0) {
      createSnack('Error', 'Quantity must be positive. Changing quantity to positive.');
      newData.quantity = newData.quantity * -1;
    }
  };

  let editable = {};
  if (!creditMemoQBId) {
    editable = {
      onRowAdd: async (newData) => {
        checkCreditMemoData(newData);
        newData.workOrderID = workOrder.id;
        newData.bill = (newData.unitPrice * newData.quantity).toFixed(2);
        newData.creditMemo = 1;
        newInvoiceLine(newData);
        checkSentToQuickBooks();
      },
      onRowUpdate: async (newData, oldData) => {
        checkCreditMemoData(newData);
        newData.bill = (newData.unitPrice * newData.quantity).toFixed(2);
        updateInvoiceLine(newData);
        checkSentToQuickBooks();
      },
      onRowDelete: async (oldData) => {
        deleteInvoiceLine(oldData);
        checkSentToQuickBooks();
      },
    };
  }

  const checkSentToQuickBooks = () => {
    if (workOrder.sentToQuickBooks) {
      addNotification('Accounting', `Invoice needs revision, altered by ${user.firstName} ${user.lastName}`, null, user, workOrder);
      updateWorkOrder(workOrder.id, { sentToQuickBooks: !workOrder.sentToQuickBooks });
    }
  };

  return (
    <div>
      <Paper>
        <Modal open={showDragModal} onClose={() => setShowDragModal(false)} style={{ top: '10%' }}>
          <Slide direction="up" in={showDragModal}>
            <div>
              <InvoiceDragModal save={saveOrdering} close={() => setShowDragModal(false)} invoices={data} headers={columns} />
            </div>
          </Slide>
        </Modal>
        <Modal open={steve} hideBackdrop style={{ top: '40%', left: '15%' }}>
          <Steve steve={steve} direction={steveDirection} />
        </Modal>
        <MaterialTable
          title={'Invoice'}
          columns={columns}
          data={data.filter((row) => !row.creditMemo)}
          options={{
            selection: Boolean(workOrder.splitInvoice),
            search: false,
            paging: false,
          }}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <InvoiceHeader
                needUpdate={needUpdate}
                invoicePDF={invoicePDF}
                deleteAllRows={deleteAllRows}
                workOrder={workOrder}
                toggleSteve={toggleSteve}
                waitInvoice={workOrder.waitInvoice}
                lockInvoice={workOrder.lockInvoice}
                submitAutomaticSplitInvoice={submitAutomaticSplitInvoice}
                createMSASCreditMemo={createMSASCreditMemo}
                creditMemoId={creditMemoId}
                activeInvoice={activeInvoice}
                activeCustomer={activeCustomer}
                splitInvoice={workOrder.splitInvoice}
                props={props}
                setActiveInvoice={setActiveInvoice}
                setShowDragModal={setShowDragModal}
                setInvoicePDF={setInvoicePDF}
                splitInvoiceDisabled={splitInvoiceDisabled}
              />
            ),
          }}
          editable={{
            onRowAdd: async (newData) => {
              newData.bill = parseFloat(newData.quantity * newData.unitPrice);
              newData.workOrderID = workOrder.id;
              newInvoiceLine(newData);
              checkSentToQuickBooks();
            },
            onRowUpdate: async (newData, oldData) => {
              newData.bill = newData.quantity * newData.unitPrice;
              updateInvoiceLine(newData);
              checkSentToQuickBooks();
            },
            onRowDelete: async (oldData) => {
              deleteInvoiceLine(oldData);
              checkSentToQuickBooks();
            },
          }}
          isLoading={invoicesLoading}
        />
        {creditMemoId && (
          <MaterialTable
            title="Credit Memo (Positive Quantity, Negative Rate)"
            style={{ marginTop: '25px' }}
            columns={[
              { title: 'Description', field: 'type' },
              { title: 'Quantity', field: 'quantity' },
              {
                title: 'Rate',
                field: 'unitPrice',
                render: (rowData) => `${formatter.format(rowData.unitPrice)}`,
              },
              {
                title: 'Amount',
                field: 'bill',
                editable: 'never',
                render: (dataRow) => {
                  if (dataRow && (dataRow.bill || dataRow.bill === 0)) {
                    return <div>{formatter.format(dataRow.bill)}</div>;
                  }
                  if (dataRow && dataRow.unitPrice !== undefined && dataRow.quantity !== undefined) {
                    return <div>{formatter.format(dataRow.unitPrice * dataRow.quantity)}</div>;
                  }
                },
              },
            ]}
            data={data.filter((row) => row.creditMemo)}
            options={{
              search: false,
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <CreditMemoHeader
                  props={props}
                  workOrderID={workOrder.id}
                  createCreditMemo={createCreditMemo}
                  deleteCreditMemo={deleteCreditMemo}
                  creditMemoQBId={creditMemoQBId}
                />
              ),
            }}
            editable={editable}
          />
        )}
        {/*todo: make this its own component*/}
        {workOrder.orderType !== 'csp' && totalEstimatedCost === 0 && (
          <div
            style={{
              textAlign: 'right',
              paddingTop: '25px',
              paddingBottom: '10px',
              paddingRight: '10px',
            }}
          >
            Check to make sure the costs are saved.
          </div>
        )}
        <div
          style={{
            textAlign: 'right',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Invoice Total:
          {formatter.format(
            data
              .reduce((total, line) => {
                if (line.quantity !== undefined && line.unitPrice !== undefined) {
                  return total + line.quantity * line.unitPrice;
                }
                return total;
              }, 0)
              .toFixed(2),
          )}
        </div>
        <div
          style={{
            textAlign: 'right',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Estimated Cost: {formatter.format(totalEstimatedCost)}
        </div>
        <div
          style={{
            textAlign: 'right',
            paddingTop: '25px',
            paddingBottom: '10px',
            paddingRight: '10px',
          }}
        >
          Estimated Profit:
          {` ${formatter.format(
            data.reduce((total, line) => {
              if (line.bill !== undefined) {
                return total + line.bill;
              }
              if (line.quantity !== undefined && line.unitPrice !== undefined) {
                return total + line.quantity * line.unitPrice;
              }
              return total;
            }, 0) - totalEstimatedCost,
          )}`}
        </div>
        {Boolean(workOrder.accountingApproved) && (
          <div>
            <div
              style={{
                textAlign: 'right',
                paddingTop: '25px',
                paddingBottom: '10px',
                paddingRight: '10px',
              }}
            >
              Actual Cost: {formatter.format(totalActualCost)}
            </div>
            <div
              style={{
                textAlign: 'right',
                paddingTop: '25px',
                paddingBottom: '10px',
                paddingRight: '10px',
              }}
            >
              Actual Profit:
              {`${formatter.format(
                data.reduce((total, line) => {
                  if (line.bill !== undefined) {
                    return total + line.bill;
                  }
                  if (line.quantity !== undefined && line.unitPrice !== undefined) {
                    return total + line.quantity * line.unitPrice;
                  }
                  return total;
                }, 0) - totalActualCost,
              )}`}
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Billing;

Billing.displayName = 'Billing';

Billing.propTypes = {
  workOrder: PropTypes.object,
};
