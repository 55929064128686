import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button, DialogActions, LinearProgress, TextField } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { post } from '../../Functions/API';
import { closeDialog } from '../../Stores/Dialog/Actions';
import { createSnack } from '../../Stores/Snack/Actions';

const EditSizes = ({ rowData, updateRow, index }) => {
  const [newSizes, setNewSizes] = useState(cloneDeep(rowData.sizes));
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);

  const updateSize = (e, size) => {
    const sizes = [...newSizes];
    const index = newSizes.indexOf(size);
    sizes[index].quantity = e.target.value;
    setNewSizes(sizes);
  };

  const updateSizes = async () => {
    if (notes) {
      setLoading(true);
      await post('/manualSkuAdjustment', { ...rowData, sizes: newSizes, notes }).then((results) => {
        updateRow(index, results);
        closeDialog();
      });
      setLoading(false);
    } else {
      createSnack('Error', 'Please add a reason for the adjustment');
    }
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField fullWidth label={'Reason for Adjustment'} value={notes} onChange={(e) => setNotes(e.target.value)} />
        </Grid>
        {newSizes.map((size, key) => {
          const oldSize = rowData.sizes.find((s) => s.id === size.id);
          const difference = size.quantity - oldSize.quantity;
          const helperText = `${difference === 0 ? '' : difference > 0 ? '+' : ''}${difference !== 0 ? difference : ''}`;
          return (
            <Grid item key={key}>
              <TextField label={size.size} value={size.quantity} onChange={(e) => updateSize(e, size)} helperText={helperText} />
            </Grid>
          );
        })}
      </Grid>
      <DialogActions>
        <Button size={'small'} color={'secondary'} disabled={loading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button size={'small'} color={'primary'} disabled={loading} onClick={updateSizes}>
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default EditSizes;

EditSizes.propTypes = {
  rowData: PropTypes.object,
  updateRow: PropTypes.func,
  index: PropTypes.number,
};
