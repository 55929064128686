import dispatcher from './dispatcher';
import Store from './Store';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';

export const loadCustomer = (id) => {
  get(`/customers/${id}`)
    .then((customer) => {
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMER',
        customer,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const fetchCustomer = async (id) => {
  const currentCustomer = Store.getCustomer();
  if (currentCustomer?.contacts) {
    return currentCustomer;
  } else {
    try {
      const customer = await get(`/customers/${id}`);
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMER',
        customer,
      });
      return customer;
    } catch (err) {
      createSnack('Error', 'Problem fetching customer');
      return currentCustomer;
    }
  }
};

export const updateCustomerContacts = async (customerId, data, workOrderId) => {
  await patch(`/customers/${customerId}?workOrderId=${workOrderId}`, data)
    .then(async (res) => {
      const customer = await get(`/customers/${customerId}`);
      dispatcher.dispatch({
        type: 'LOAD_CUSTOMER',
        customer,
      });
      createSnack('Success', 'Updated Contacts');
    })
    .catch((err) => {
      console.log(err);
      createSnack('Error', err.message);
    });
};

export const setCustomerManually = (customer) => {
  dispatcher.dispatch({
    type: 'LOAD_CUSTOMER',
    customer,
  });
};
