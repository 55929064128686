/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Paper, Button, Select, MenuItem } from '@material-ui/core';
import { getUsersByGroup } from '../../Stores/Users/Actions';

export default class Mistakes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRef: React.createRef(),
      uid: 'none',
      users: [],
      rows: [],
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState({ users: results });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }

  setup = async () => {
    return getUsersByGroup('Order Processor');
  };

  getMistakes = async (uid, page, pageSize) => {
    if (uid !== 'none') {
      if (!uid) {
        uid = this.state.uid;
      }
      let rows;
      const props = [
        { name: 'uid', value: uid },
        { name: 'page', value: page },
        { name: 'pageSize', value: pageSize },
      ];
      await this.props
        .get('/mistakes', props)
        .then((results) => {
          rows = results;
        })
        .catch((err) => {
          window.alert(err.message);
        });
      if (rows) {
        return rows;
      }
      return {
        rows: [],
        count: 0,
      };
    }
    return {
      rows: [],
      count: 0,
    };
  };

  updateUid = (uid) => {
    this.setState(
      {
        uid,
      },
      this.state.tableRef.current && this.state.tableRef.current.onQueryChange(),
    );
  };

  goToWorkOrder = (workOrderID) => {
    this.props.get('/checkId', [{ name: 'id', value: workOrderID }]).then((results) => {
      window.open(`/workorder/${results.id}`, '_blank');
    });
  };

  render() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const columns = [
      {
        title: 'Work Orders',
        field: 'workOrders',
        render: (rowData) => {
          if (rowData.workOrders) {
            const workOrders = rowData.workOrders.split(',');
            return (
              <div>
                {workOrders.map((workOrder, key) => (
                  <Button key={key} variant="contained" color="primary" onClick={() => this.goToWorkOrder(workOrder)}>
                    {workOrder}
                  </Button>
                ))}
              </div>
            );
          }
          return <div>None</div>;
        },
      },
      { title: 'Month', field: 'month', render: (rowData) => months[rowData.month - 1] },
      { title: 'Year', field: 'year' },
      {
        title: 'Mistake Cost',
        field: 'mistakes',
        render: (rowData) => rowData.mistakes.toFixed(2),
      },
      {
        title: 'Total Cost',
        field: 'totalCost',
        render: (rowData) => {
          if (rowData && rowData.totalCost !== null) {
            return rowData.totalCost.toFixed(2);
          }
          return 'None';
        },
      },
      {
        title: 'Percentage',
        field: 'mistakePercent',
        render: (rowData) => {
          const percent = rowData.mistakePercent * 100;
          return `${percent.toFixed(2)}%`;
        },
      },
    ];
    const { users, uid } = this.state;
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Cost By Order Processor"
            columns={columns}
            tableRef={this.state.tableRef}
            data={async (query) => {
              const returned = await this.getMistakes(this.state.uid, query.page, query.pageSize);
              return {
                data: returned.rows,
                page: query.page,
                totalCount: returned.count,
              };
            }}
            options={{
              pageSize: 20,
              search: false,
            }}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <Select value={uid} style={{ margin: '10px' }} onChange={(event) => this.updateUid(event.target.value)}>
                    <MenuItem value="none">Chose an Order Processor</MenuItem>
                    {users.map((user, key) => (
                      <MenuItem key={key} value={user.uid}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ),
            }}
          />
        </Paper>
      </div>
    );
  }
}
