import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const CustomTextField = ({ att, dialogValue, setDialogValue }) => {
  return (
    <TextField
      autoFocus
      style={{ marginLeft: '5px', marginRight: '5px' }}
      id={att}
      value={dialogValue[att] || ''}
      onChange={(event) => {
        setDialogValue({ ...dialogValue, [att]: event.target.value });
      }}
      label={`${att}`}
      type={att === 'zip' ? 'number' : 'text'}
    />
  );
};

export default CustomTextField;

CustomTextField.propTypes = {
  att: PropTypes.string,
  dialogValue: PropTypes.string,
  setDialogValue: PropTypes.func,
};
