import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.customers = [];
  }

  // customersArray
  getCustomers() {
    return this.customers;
  }

  loadCustomers = (customers) => {
    this.customers = customers;
    this.emit('change');
  };

  lockCustomer = (customer) => {
    let i = this.customers.findIndex((c) => c.id === customer.id);
    this.customers[i] = customer;
    this.emit('change');
  };

  newCustomer = (customer) => {
    this.customers.push(customer);
    this.emit('change');
  };

  updateCustomer = (customer) => {
    const index = this.customers.findIndex((c) => c.id === customer.id);
    this.customers[index] = customer;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_CUSTOMERS': {
        this.loadCustomers(action.customers);
        break;
      }
      case 'GET_CUSTOMERS': {
        this.getCustomers();
        break;
      }
      case 'NEW_CUSTOMER': {
        this.newCustomer(action.customer);
        break;
      }
      case 'UPDATE_CUSTOMER': {
        this.updateCustomer(action.customer);
        break;
      }
      case 'LOCK_CUSTOMER': {
        this.lockCustomer(action.customer);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
