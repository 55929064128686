/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-return-await:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Card, CardContent, Grid, CardHeader, ListItemText } from '@material-ui/core';
import QuoteHeader from '../../../../Components/Quote/QuoteHeader';
import QuoteFooter from '../../../../Components/Quote/QuoteFooter';
import '../../../../Components/Utils/styles/QuotePDF.css';

export default class QuotePDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalog: 0,
      category: 0,
      styleId: 0,
      selectedImage: 0,
      bill: 0,
      artModal: false,
      newArt: {
        type: 'T-Shirt',
        printType: '1 Color',
        location: 'Back',
        cost: 3,
      },
      style: {
        style: '',
        options: [{ image: '', swatch: '' }],
        features: [],
        quantity: 0,
        art: [],
      },
      salesman: {},
      styles: [],
      categories: [],
      rows: [],
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState(results);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  setup = async () => {
    let rows;
    let salesman;
    const id = this.props.match.params.quoteId;
    await fetch(`${this.props.homepage}/quoteRows?quotesId=${id}`)
      .then(async (results) => await results.json())
      .then((results) => {
        rows = results;
      })
      .catch((err) => {
        throw err;
      });
    await fetch(`${this.props.homepage}/getQuoteInfo?id=${id}`)
      .then(async (results) => await results.json())
      .then((results) => {
        salesman = results;
      })
      .catch((err) => {
        throw err;
      });
    if (rows !== undefined && salesman !== undefined) {
      return {
        rows,
        salesman,
      };
    }
    throw new Error('Something was undefined');
  };

  updateImage = (selectedImage) => {
    this.setState({
      selectedImage,
    });
  };

  updateBill = (bill) => {
    this.setState({
      bill,
    });
  };

  toggleArtModal = () => {
    this.setState({
      artModal: !this.state.artModal,
    });
  };

  updateArt = (type, value) => {
    const { newArt } = this.state;
    newArt[type] = value;
    this.setState({
      newArt,
    });
  };

  updateQuantity = (event) => {
    const { style } = this.state;
    style.quantity = event.target.value;
    this.setState({
      style,
    });
  };

  updateProductImage = (row, product, optionId) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const { products } = rows[rowIndex];
    const productIndex = products.indexOf(product);
    products[productIndex].optionId = optionId;
    this.setState({
      rows,
    });
  };

  render() {
    return (
      <div id="quotePage">
        <QuoteHeader />
        {this.state.rows.map((row, key) => (
          <Card
            key={key}
            style={{
              width: '100%',
              marginTop: '15px',
              backgroundColor: '#f5f5f5',
            }}
          >
            <CardHeader title={row.title} />
            <CardContent style={{ marginBottom: '45px' }}>
              <Grid container spacing={4} justify="center">
                {row.products.map((product, key) => (
                  <Grid key={key} item xs={12} md={6} lg={3}>
                    <Card style={{ height: '100%' }}>
                      <CardHeader
                        title={product.description}
                        style={{
                          backgroundColor: '#eaeaea',
                          textAlign: 'center',
                        }}
                        subheader={<div>{product.art.map((art) => `- ${art.printType} ${art.position} `)}-</div>}
                      />
                      <CardHeader
                        title={
                          <div style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: 'xx-large' }}>${product.bill}</span>
                            <span
                              style={{
                                // fontWeight: '100',
                                fontSize: 'small',
                                color: 'rgba(0, 0, 0, 0.54)',
                              }}
                            >
                              /piece
                            </span>
                          </div>
                        }
                        subheader={<div style={{ textAlign: 'center' }}>@ {product.quantity} total pieces</div>}
                      />

                      <Grid container spacing={1} justify="space-around">
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                          <img
                            src={product.options[product.options.indexOf(product.options.find((row) => row.id === product.optionId))].image}
                            alt=""
                            style={{
                              maxWidth: '100%',
                              // height: '317px'
                            }}
                          />
                        </Grid>
                      </Grid>
                      <CardContent>
                        <ListItemText
                          primary="Available Colors"
                          secondary={
                            <>
                              {product.options.map((swatch, key) => (
                                <img
                                  src={swatch.swatch}
                                  onClick={() => this.updateProductImage(row, product, swatch.id)}
                                  style={{
                                    maxWidth: '30px',
                                    border:
                                      product.options.indexOf(product.options.find((row) => row.id === product.optionId)) !== key
                                        ? '2px solid #f5f5f5'
                                        : '2px solid #f50057',
                                  }}
                                  key={key}
                                  alt=""
                                />
                              ))}
                            </>
                          }
                        />
                        <ul>
                          {product.features.map((feature, key) => (
                            <li key={key}>{feature.feature}</li>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        ))}
        <QuoteFooter salesman={this.state.salesman} />
      </div>
    );
  }
}
