/* eslint no-restricted-syntax:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Checkbox, Tooltip, Button } from '@material-ui/core';
import MaterialTable from 'material-table';

class Day extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      workOrders: [],
      columns: [
        {
          title: 'ID',
          field: 'workOrderID',
          render: (rowData) => (
            <Button variant="contained" color="primary" target="_blank" href={`/workorder/${rowData.id}`}>
              {rowData.workOrderID}
            </Button>
          ),
        },
        { title: 'Customer', field: 'customerName' },
        {
          title: 'Total Prints',
          field: 'totalPrints',
        },
        { title: 'Remaining Prints', field: 'prints' },
        // {
        //   title: 'Production Complete',
        //   field: 'productionComplete',
        //   render: (rowData) => (
        //     <Checkbox
        //       style={rowData.productionComplete ? { color: 'green' } : {}}
        //       value={Boolean(rowData.productionComplete)}
        //       checked={Boolean(rowData.productionComplete)}
        //     />
        //   ),
        // },
      ],
    };
  }

  componentDidMount() {
    this.getWorkOrdersByDay();
  }

  getWorkOrdersByDay = () => {
    const { date } = this.props;
    this.props.get('/getWorkOrdersByDay', [{ name: 'date', value: date }]).then((workOrders) => {
      let found = false;
      for (const workOrder of workOrders) {
        if (workOrder.misprint) {
          found = true;
        }
      }
      const { columns } = this.state;
      if (found) {
        columns.splice(2, 0, {
          title: 'Reprint',
          field: 'misprint',
          render: (rowData) => <Checkbox value={Boolean(rowData.misprint)} checked={Boolean(rowData.misprint)} />,
        });
      }
      this.setState({
        loading: false,
        columns,
        workOrders,
      });
    });
  };

  render() {
    return (
      <Card
        style={{
          margin: '20px',
        }}
      >
        <CardHeader
          title={this.props.date}
          subheader={`${this.state.workOrders.reduce((total, workOrder) => total + workOrder.totalPrints, 0)} Total Prints`}
        />
        <CardContent style={{ margin: '0 0 0 16px', padding: 0 }}>
          {`${this.state.workOrders.reduce((total, workOrder) => total + workOrder.prints, 0)} Total Remaining Prints`}
        </CardContent>
        <CardContent>
          <MaterialTable
            data={this.state.workOrders}
            columns={this.state.columns}
            isLoading={this.state.loading}
            options={{
              loadingType: 'linear',
              toolbar: false,
              paging: false,
            }}
          />
        </CardContent>
      </Card>
    );
  }
}

export default Day;
