import { Route, Switch } from 'react-router-dom';
import FulfillmentTabs from '../../Components/Fulfillment/FulfillmentTabs';

import React, { useState, useEffect } from 'react';
import Stores from './stores';
import Shipments from './shipments';
import Customers from './customers';
import Orders from './orders';
import Home from './home';
import Products from './products';
import Art from './art';
import Ordering from './ordering';
import Billing from './billing';
import RawMaterials from './raw-materials';
import { loadPaths } from '../../Stores/ProductPaths/Actions';

const Fulfillment = () => {
  useEffect(() => {
    loadPaths();
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <title>Fulfillment</title>
      <FulfillmentTabs />
      <Switch>
        <Route path={`/fulfillment/shipments/:jobId`}>
          <Shipments />
        </Route>
        <Route path={`/fulfillment/customers`}>
          <Customers />
        </Route>
        <Route path={`/fulfillment/stores`}>
          <Stores />
        </Route>
        <Route path={`/fulfillment/orders`}>
          <Orders />
        </Route>
        <Route path={`/fulfillment/products`}>
          <Products />
        </Route>
        <Route path={`/fulfillment/art/:step`}>
          <Art />
        </Route>
        <Route path={`/fulfillment/ordering`}>
          <Ordering />
        </Route>
        <Route path={`/fulfillment/line-items`}>
          <Billing />
        </Route>
        <Route path={`/fulfillment/raw-materials`}>
          <RawMaterials />
        </Route>
        <Route path={`/fulfillment`}>
          <Home />
        </Route>
      </Switch>
    </div>
  );
};

export default Fulfillment;
