/* eslint-disable react/display-name */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';

const WhatsNewFeatureDetails = ({ get, featureId, createSnack }) => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getNewDetailsByFeature = () => {
    if (featureId) {
      get('/getNewDetailsByFeature', { featureId }, 'obj')
        .then((res) => {
          setDetails(res);
          setLoading(false);
        })
        .catch((err) => {
          createSnack('Error', err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getNewDetailsByFeature();
  }, []);

  return (
    <MaterialTable
      columns={[
        { cellStyle: { width: '11%' } },
        {
          field: 'detail',
          type: 'text',
          render: (rowData) => (
            <ul style={{ padding: 0, margin: 0 }}>
              <li style={{ padding: 0, margin: 0 }}>{rowData.detail}</li>
            </ul>
          ),
        },
      ]}
      data={details}
      options={{
        toolbar: false,
        header: false,
        maxBodyHeight: '80vh',
        paging: false,
        headerStyle: {
          backgroundColor: 'AliceBlue',
          color: 'DimGray',
        },
      }}
      isLoading={loading}
    />
  );
};

export default WhatsNewFeatureDetails;
