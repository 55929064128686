import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Grid, TextField, Typography } from '@material-ui/core';
import { createSnack } from '../../Stores/Snack/Actions';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';
import useStyles from './styles';
import { setLoading } from '../../Stores/Loading/Actions';

const Upload = ({ uploadTypeOptions }) => {
  const [previewData, setPreviewData] = useState([]);
  const [uploadType, setUploadType] = useState({});
  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const parseData = async ({ data, errors }) => {
    const noBlanks = data.filter((line) => {
      for (const key in line) {
        if (line[key]) return true;
      }
      return false;
    });
    if (errors.length) {
      createSnack('Error', 'One or more errors occurred while uploading, check logs for details');
    }
    if (noBlanks.length) {
      console.log(noBlanks);
      setPreviewData(noBlanks);
    } else {
      createSnack('Error', 'File contains no data');
    }
  };

  const handleDrop = (files) => {
    if (!uploadType?.name) {
      createSnack('Error', 'You must select an upload type first!');
      return;
    }
    for (const file of files) {
      if (!file) continue;
      Papa.parse(file, { complete: parseData, header: true, skipEmptyLines: true });
    }
  };

  const handleUploadTypeChange = (e, v) => {
    setUploadType(v);
  };

  const getPageSizeInfo = () => {
    const options = [10, 20, 35, 50, 75, 100, 200];
    const newOptions = [];
    for (const option of options) {
      if (previewData.length < option) {
        newOptions.push(previewData.length);
        break;
      }
      newOptions.push(option);
    }
    setPageSizeOptions(newOptions);
  };

  const downloadTemplate = async () => {
    if (!uploadType) {
      createSnack('Error', 'You must select an uploadType first!');
      return;
    }
    if (!uploadType?.template) {
      createSnack('Error', 'No template exists for this type!');
      return;
    }
    const csvData = Papa.unparse(uploadType.template, {
      skipEmptyLines: true,
    });

    const csv = 'data:text/csv;charset=utf-8' + encodeURI(csvData);

    saveAs(csv, `${uploadType.name}.csv`);
  };

  useEffect(getPageSizeInfo, [previewData]);

  return (
    <React.Fragment>
      <Button className={classes.uploadButton} size={'small'} variant={'outlined'} onClick={() => setOpen(true)}>
        UPLOAD
      </Button>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={'bottom'}
        open={open}
        onClose={() => {
          setOpen(false);
          setUploadType({});
          setPreviewData([]);
        }}
      >
        <div className={classes.root}>
          {/* Title */}
          <Typography className={classes.title} variant={'h4'} component={'h4'}>
            BREGSAS Importer
          </Typography>

          <Grid container alignItems={'center'}>
            {/* Area to select the type of upload */}
            <Grid item xs={11}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label={'Upload Type'} variant={'outlined'} />}
                options={uploadTypeOptions}
                value={uploadType}
                onChange={handleUploadTypeChange}
                getOptionLabel={(option) => (option?.name ? option.name : null)}
                className={classes.autocomplete}
                fullWidth
              />
            </Grid>

            {/* Button to download a CSV template for the selected uploadType */}
            <Grid item xs={1}>
              <Button className={classes.downloadButton} size={'small'} variant={'outlined'} disabled={!uploadType?.name} onClick={downloadTemplate}>
                Download Template
              </Button>
            </Grid>
          </Grid>

          <div className={classes.dropZone}>
            <DropZone handleDrop={handleDrop} acceptedFiles={['.csv']} />
          </div>

          {/* Preview of the data to be uploaded */}
          <MaterialTable
            title={'Preview'}
            columns={uploadType?.columns}
            data={previewData}
            actions={[
              {
                // eslint-disable-next-line react/display-name
                icon: () => (
                  <Button className={classes.uploadButton} size={'small'} variant={'outlined'} disabled={!previewData.length}>
                    UPLOAD
                  </Button>
                ),
                disabled: !previewData.length,
                tooltip: !previewData.length ? 'You need to add a file first!' : 'upload data',
                isFreeAction: true,
                onClick: () => {
                  if (!uploadType) {
                    createSnack('Error', 'No upload type selected!');
                    return;
                  }
                  if (!uploadType.callback) {
                    createSnack('Error', 'No callback provided!');
                    return;
                  }
                  setLoading(true);
                  uploadType
                    .callback(previewData)
                    .then(() => {
                      setPreviewData([]);
                      setLoading(false);
                    })
                    .catch(() => setLoading(false));
                },
              },
            ]}
            editable={{
              onRowAddCancelled: (rowData) => createSnack('', 'Row add cancelled'),
              onRowUpdateCancelled: (rowData) => createSnack('', 'Row update Cancelled'),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  const newPreviewData = [...previewData];
                  newPreviewData.unshift(newData);
                  setPreviewData(newPreviewData);
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const newPreviewData = [...previewData];
                  const index = previewData.indexOf(oldData);
                  newPreviewData[index] = newData;
                  setPreviewData(newPreviewData);
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  const newPreviewData = [...previewData];
                  const index = previewData.indexOf(oldData);
                  newPreviewData.splice(index, 1);
                  setPreviewData(newPreviewData);
                  resolve();
                }),
            }}
            options={{
              padding: 'dense',
              pageSize: 10,
              pageSizeOptions: pageSizeOptions,
            }}
          />
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Upload;

Upload.propTypes = {
  uploadTypeOptions: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
};
