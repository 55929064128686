import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import OrderSetLine from './OrderSetLine';
import OrderLine from './OrderLine';
import SizeDetails from './SizeDetails';
import PageToolbar from '../Generate/PageToolbar';
import Grouping from '../Production/Grouping';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import UserStore from '../../../../../Stores/User/Store';
import PropTypes from 'prop-types';
import VendorsStore from '../../../../../Stores/Vendors/Store';
import InventoryStore from '../../../../../Stores/Inventory/Store';
import { loadInventory } from '../../../../../Stores/Inventory/Actions';
import { fetchVendors } from '../../../../../Stores/Vendors/Actions';
import { WorkOrderStore } from '../../../../../Stores';

const Order = () => {
  const width = 0;
  const pageSize = 10;
  const user = UserStore.getUser();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sizeDetailsModal, setSizeDetailsModal] = useState(false);
  const [sizeDetailLine, setSizeDetailLine] = useState({});
  const [numOfLines, setNumOfLines] = useState([]);
  const [filters, setFilters] = useState({});
  const [options, setOptions] = useState({});
  const [potentialSizes, setPotentialSizes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [groupBy, setGroupBy] = useState([
    { title: 'Type', field: 'type', selected: true },
    { title: 'Vendor', field: 'vendorID', selected: true },
    { title: 'Inventory', field: 'inventoryId', selected: true },
    { title: 'Use Inventory', field: 'useInventory', selected: true },
    { title: 'Add SKU', field: 'addSKU', selected: true },
    { title: 'Remove SKU', field: 'removeSKU', selected: true },
    { title: 'Adult Style', field: 'adultStyle', selected: true },
    { title: 'Youth Style', field: 'youthStyle', selected: true },
    { title: 'Color', field: 'color', selected: true },
    { title: 'Team', field: 'team', selected: true },
    { title: 'Notes', field: 'notes', selected: true },
    { title: 'Art', field: 'art', selected: true },
    { title: 'Number', field: 'number', selected: false },
  ]);

  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    if (workOrder.id) {
      setup();
    }
  }, [workOrder.id]);

  useEffect(() => {
    if (workOrder.id) {
      getOrders(page);
    }
  }, [filters, workOrder.id, page]);

  useEffect(() => {
    if (VendorsStore.getVendors().length === 0) {
      fetchVendors();
    }
    if (InventoryStore.getInventory().length === 0) {
      loadInventory();
    }
  }, []);

  const setup = async () => {
    let newPotentialSizes;
    await get('/allPotentialSizes', [{ name: 'type', value: 'type' }])
      .then((results) => {
        newPotentialSizes = results;
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });

    await get('/getOrdersByVendorAndColor', [
      {
        name: 'workOrderID',
        value: workOrder.id,
      },
      { name: 'page', value: 0 },
      { name: 'filters', value: JSON.stringify(filters) },
      { name: 'groupBy', value: JSON.stringify(groupBy) },
    ])
      .then((results) => {
        let { data, total, options } = results;
        let filters = {};

        for (const option of Object.keys(options)) {
          filters[option] = '';
        }
        for (const result of data) {
          result.sizes = sortSizesStart(result.sizes, newPotentialSizes);
          for (const size of result.sizes) {
            try {
              size.bill = parseFloat(size.bill).toFixed(2);
            } catch {
              size.bill = 0.0;
            }
            try {
              size.cost = parseFloat(size.cost).toFixed(2);
            } catch {
              size.cost = 0.0;
            }
          }
        }
        setPotentialSizes(newPotentialSizes);
        setNumOfLines(data);
        setTotal(total);
        setFilters(filters);
        setOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const getOrders = (page) => {
    setLoading(true);

    get('/getOrdersByVendorAndColor', [
      {
        name: 'workOrderID',
        value: workOrder.id,
      },
      { name: 'page', value: page },
      { name: 'filters', value: JSON.stringify(filters) },
      { name: 'groupBy', value: JSON.stringify(groupBy) },
    ])
      .then((results) => {
        let { data, total, options } = results;
        for (const result of data) {
          result.sizes = sortSizesStart(result.sizes, potentialSizes);
          for (const size of result.sizes) {
            try {
              size.bill = parseFloat(size.bill).toFixed(2);
            } catch {
              size.bill = 0.0;
            }
            try {
              size.cost = parseFloat(size.cost).toFixed(2);
            } catch {
              size.cost = 0.0;
            }
          }
        }
        setNumOfLines(data);
        setTotal(total);
        setOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.size);
      const indexA = potentialSizes.indexOf(potentialSizeA);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.size);
      const indexB = potentialSizes.indexOf(potentialSizeB);
      return indexA - indexB;
    });
  };

  const nextPage = () => {
    const newPage = page + 1;
    setPage(newPage);
    setNumOfLines([]);
    setLoading(true);
    getOrders(newPage);
  };

  const prevPage = () => {
    const newPage = page - 1;
    setPage(newPage);
    setNumOfLines([]);
    setLoading(true);
    getOrders(newPage);
  };

  const firstPage = () => {
    setPage(0);
    setNumOfLines([]);
    setLoading(true);
    getOrders(0);
  };

  const lastPage = () => {
    const newPage = Math.floor(total / pageSize);
    setPage(newPage);
    setNumOfLines([]);
    setLoading(true);
    getOrders(newPage);
  };

  const updatePrice = (event, size, position, type) => {
    const newLines = [...numOfLines];
    const { sizes } = newLines[position];
    sizes[sizes.indexOf(size)][type] = parseFloat(event.target.value);
    setNumOfLines(newLines);
  };

  const submit = () => {
    setLoading(true);
    post('/submitOrderProductReal', { numOfLines, workOrderID: workOrder.id, uid: user.uid })
      .then(({ message }) => {
        createSnack('Success', message);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const changeAll = (event, position, type) => {
    const newLines = [...numOfLines];
    const { sizes } = newLines[position];
    for (const size of sizes) {
      size[type] = parseFloat(event.target.value);
    }
    setNumOfLines(newLines);
  };

  const changeAllSet = (event, index, type) => {
    const newLines = [...numOfLines];
    const filtered = newLines.filter((line) => line.groupAsSet);
    const { sizes } = filtered[index];
    for (const size of sizes) {
      size[type] = parseFloat(event.target.value);
    }
    setNumOfLines(newLines);
  };

  const updatePriceSet = (event, line, size, type) => {
    const newLines = [...numOfLines];
    const index = newLines.indexOf(line);
    const { sizes } = newLines[index];
    // todo: figure out how to step .01
    sizes[sizes.indexOf(size)][type] = parseFloat(event.target.value);
    setNumOfLines(newLines);
  };

  const updateAllBillSet = (event) => {
    const newLines = [...numOfLines];
    const filtered = newLines.filter((line) => line.groupAsSet);
    for (const line of filtered) {
      for (const newSize of line.sizes) {
        newSize.bill = event.target.value;
      }
    }
    setNumOfLines(newLines);
  };

  const updateBillSet = (event, size) => {
    const newLines = [...numOfLines];
    const filtered = newLines.filter((line) => line.groupAsSet);
    for (const line of filtered) {
      for (const newSize of line.sizes) {
        if (newSize.size === size.size) {
          newSize.bill = event.target.value;
        }
      }
    }
    setNumOfLines(newLines);
  };

  const toggleSizeDetailModal = (sizeDetailLine) => {
    setSizeDetailLine(sizeDetailLine);
    setSizeDetailsModal(!sizeDetailsModal);
  };

  const updateFilter = (filter, value) => {
    let newFilters = { ...filters };
    newFilters[filter] = value;
    setPage(0);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    let newFilters = { ...filters };
    for (const key of Object.keys(newFilters)) {
      newFilters[key] = '';
    }
    setPage(0);
    setFilters(newFilters);
  };

  const toggleSelected = (group) => {
    const newGroupBy = [...groupBy];
    const index = groupBy.indexOf(group);
    newGroupBy[index].selected = !groupBy[index].selected;
    console.log(newGroupBy[index]);
    setGroupBy(newGroupBy);
  };

  return (
    <div style={{ marginBottom: '80px' }}>
      <Modal style={{ top: '10%' }} open={sizeDetailsModal} onClose={() => toggleSizeDetailModal(null)}>
        <SizeDetails line={sizeDetailLine} />
      </Modal>
      <Grouping groupBy={groupBy} loading={loading} update={() => getOrders(0)} toggleSelected={toggleSelected} />
      {numOfLines.filter((line) => line.groupAsSet).length > 0 && (
        <OrderSetLine
          updatePriceSet={updatePriceSet}
          changeAllSet={changeAllSet}
          updateAllBillSet={updateAllBillSet}
          updateBillSet={updateBillSet}
          lines={numOfLines.filter((line) => line.groupAsSet)}
          line={numOfLines.filter((line) => line.groupAsSet)[0]}
          width={width}
        />
      )}
      {numOfLines
        .filter((line) => !line.groupAsSet)
        .map((line, key) => (
          <OrderLine
            num={numOfLines.indexOf(line)}
            page={page}
            key={key}
            toggleSizeDetailModal={toggleSizeDetailModal}
            updatePrice={updatePrice}
            changeAll={changeAll}
            groupBy={groupBy}
            load={line}
            misprint={workOrder.misprint}
            width={width}
            workOrder={workOrder}
            filters={filters}
          />
        ))}
      <PageToolbar
        workOrder={workOrder}
        filters={filters}
        orderOptions={options}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        total={total}
        updateFilter={updateFilter}
        clearFilters={clearFilters}
        loading={loading}
        firstPage={firstPage}
        prevPage={prevPage}
        nextPage={nextPage}
        lastPage={lastPage}
        submit={submit}
      />
    </div>
  );
};

export default Order;

Order.propTypes = {
  workOrder: PropTypes.object.isRequired,
};
