/* eslint react/jsx-props-no-spreading:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../Utils/theme';
import FuturePrints from '../Production/FuturePrints';
import TodaysPrints from '../Production/TodaysPrints';
import PrintsGraph from '../Production/PrintsGraph';
import LatestWorkOrders from '../Common/LatestWorkOrders';
import PrintTypeChart from '../Production/PrintTypeChart';
import CurrentlyClockedIn from '../Production/CurrentlyClockedIn';
import WaitingOrders from '../Common/WaitingOrders';
import OverDueWorkOrders from './OverdueWorkOrders';
import InventoryPull from './InventoryPull';
import InventoryChecks from '../Production/InventoryChecks';

const FulfillmentHome = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="dashboard-container">
        <Grid className="dashboard" container spacing={3} style={{ width: '100%', margin: '0 auto' }} xl={10}>
          <Grid item sm={6} xs={12}>
            <WaitingOrders get={props.get} user={props.user} type="Order Processor" startPage={props.startPage} refresh={props.refresh} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <OverDueWorkOrders refresh={props.refresh} />
          </Grid>
          <Grid item xs={12}>
            <InventoryPull />
          </Grid>
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <FuturePrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item sm={6} xs={12}>*/}
          {/*  <TodaysPrints get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          <LatestWorkOrders {...props} refresh={props.refresh} />
          <Grid item xs={12}>
            <InventoryChecks />
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <CurrentlyClockedIn get={props.get} refresh={props.refresh} />
          </Grid>
          {/*<Grid item md={4} xl={3} xs={12}>*/}
          {/*  <PrintTypeChart get={props.get} refresh={props.refresh} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <PrintsGraph get={props.get} post={props.post} refresh={props.refresh} />*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default FulfillmentHome;
