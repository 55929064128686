import React, { useEffect, useState } from 'react';
import { get } from '../../../Functions/API.js';
import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';
import { monthsAbbrev } from '../../../Components/Utils/monthsOfTheYear.js';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Breadcrumbs, Button, FormControl, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { getUsersByGroup } from '../../../Stores/Users/Actions.js';
import Papa from 'papaparse';
import WorkOrdersByWeek from '../sales-weekly-report/WorkOrdersByWeek.js';
import { createDialog } from '../../../Stores/Dialog/Actions.js';
import CSPWorkOrdersByWeek from './CSPWorkOrdersByWeek.js';

const CSPWeeklyReport = () => {
  const [date, setDate] = useState(new Date());
  const [year, setYear] = useState(date.getFullYear());
  const [yearData, setYearData] = useState([]);
  const [prevYearData, setPrevYearData] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersByGroup('Sales').then(setUsers);
  }, []);

  useEffect(() => {
    setYear(date.getFullYear());
  }, [date]);

  useEffect(() => {
    getReport(year).then((results) => console.log(results));
  }, [year]);

  const getReport = async (year) => {
    try {
      const results = await get('/workorder/csp-weekly-report', { year });
      const allYearData = [];
      const yearData = results.filter(({ year: y }) => y === year);
      for (let i = 0; i < 53; i++) {
        const row = yearData.find(({ week }) => week === i);
        if (row) {
          allYearData.push(row);
        } else {
          allYearData.push({ week: i, invoice: 0, cost: 0, profit: 0, year });
        }
      }
      const allPrevYearData = [];
      const prevYearData = results.filter(({ year: y }) => y === year - 1);
      for (let i = 0; i < 53; i++) {
        const row = prevYearData.find(({ week }) => week === i);
        if (row) {
          allPrevYearData.push(row);
        } else {
          allPrevYearData.push({ week: i, invoice: 0, cost: 0, profit: 0, year: year - 1 });
        }
      }
      setYearData(allYearData);
      setPrevYearData(allPrevYearData);
      return results;
    } catch (e) {
      console.error(e);
    }
  };

  const getSunday = (date) => {
    const first = date.getDate() - date.getDay() + 1;
    const last = first + 6;
    return new Date(date.setDate(last));
  };

  const getSaturday = (date) => {
    const first = date.getDate() - date.getDay() + 1;
    const last = first + 12;
    return new Date(date.setDate(last));
  };

  const columns = [
    {
      title: 'Week',
      field: 'week',
    },
    {
      title: 'Dates',
      field: 'week',
      render: (rowData) => {
        const { year, week } = rowData;
        const d = 1 + (week - 1) * 7;
        let start = getSunday(new Date(year, 0, d));
        if (week === 0) {
          start = new Date(year, 0, 1);
        }
        const end = getSaturday(new Date(year, 0, d));
        const startMonth = start.getMonth();
        const endMonth = end.getMonth();
        const visualStartMonth = monthsAbbrev[startMonth];
        const visualEndMonth = monthsAbbrev[endMonth];
        return `${visualStartMonth} ${start.getDate()} - ${startMonth === endMonth ? end.getDate() : `${visualEndMonth} ${end.getDate()}`}`;
      },
    },
    { title: 'CSP Costs', field: 'cspCosts', type: 'currency' },
    // { title: 'Cost', field: 'cost', type: 'currency' },
    // { title: 'Profit', field: 'profit', type: 'currency' },
    // {
    //   title: 'PM',
    //   field: 'profitMargin',
    //   type: 'numeric',
    // },
  ];

  const download = () => {
    const data = [...prevYearData, ...yearData];
    const sendData = data.map(({ week, year, cspCosts }) => ({
      year,
      week,
      dates: columns[1].render({ year, week }),
      cspCosts,
    }));
    const csvContent = Papa.unparse(sendData, { header: true });
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `CSP Weekly Export.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const tableOptions = {
    padding: 'dense',
    paging: false,
    search: false,
  };

  return (
    <div style={{ padding: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <Link underline={'hover'} href={'/accounting'}>
              Accounting Home
            </Link>
            <Typography color="text.primary"> CSP Weekly Report</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h4'}>CSP Weekly Summary</Typography>
        </Grid>
        <Grid item xs={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DatePicker
                views={['year']}
                label="Year"
                value={date}
                onChange={(d) => {
                  setDate(d);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant={'contained'} onClick={download}>
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title={`Weekly Summary for ${year - 1}`}
            columns={columns}
            data={prevYearData}
            options={tableOptions}
            onRowClick={(e, { week }) =>
              createDialog({
                title: `Work orders for week ${week}`,
                content: <CSPWorkOrdersByWeek week={week} year={year - 1} />,
                size: 'xl',
                actions: [{ title: 'Close' }],
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MaterialTable
            title={`Weekly Summary for ${year}`}
            columns={columns}
            data={yearData}
            options={tableOptions}
            onRowClick={(e, { week }) =>
              createDialog({
                title: `Work orders for week ${week}`,
                content: <CSPWorkOrdersByWeek week={week} year={year} />,
                size: 'xl',
                actions: [{ title: 'Close' }],
              })
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CSPWeeklyReport;
