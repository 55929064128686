/* eslint react/no-array-index-key:0 */
/* eslint consistent-return:0 */
/* eslint react/prop-types:0 */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InventoryStore from '../../../../../../Stores/Inventory/Store';
import VendorsStore from '../../../../../../Stores/Vendors/Store';

const Inventory = ({ groupBy, line, inventoryStyles, workOrder, SKUs, vendors }) => {
  const vendor = VendorsStore.getVendorById(line.vendorID);
  const inventory = InventoryStore.getInventoryById(line.inventoryId);
  const addSKU = InventoryStore.getSKUById(line.addSKU);
  const removeSKU = InventoryStore.getSKUById(line.removeSKU);

  return (
    <div>
      {groupBy
        .filter((row) => row.selected)
        .map((group, key) => {
          let { title } = group;
          let value = line[group.field];
          switch (title) {
            case 'Type':
              return;
            case 'Color':
              return;
            case 'Team':
              return;
            case 'Art':
              return;
            case 'Use Inventory':
              return;
            case 'Vendor':
              if (line.useInventory) return;
              value = vendor?.name;
              break;
            case 'Inventory':
              if (!line.useInventory) return;
              value = inventory?.name;
              break;
            case 'Remove SKU':
              if (!line.useInventory) return;
              value = removeSKU ? `${removeSKU.sku} - ${removeSKU.name}` : null;
              break;
            case 'Add SKU':
              value = addSKU ? `${addSKU.sku} - ${addSKU.name}` : null;
              break;
            case 'Adult Style':
              return;
            case 'Youth Style':
              return;
          }
          return (
            <TextField
              key={key}
              InputLabelProps={{
                shrink: true,
              }}
              label={title}
              value={value}
              style={{ margin: '5px' }}
              margin="normal"
              component="div"
            />
          );
        })}
    </div>
  );
};

export default Inventory;
