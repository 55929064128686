import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/messaging';
import { forwardMessage } from './FirebaseMessaging/Actions';

const firebaseConfig = {
  apiKey: 'AIzaSyB4uRjbyaKQyUbJFR7tTKfViqvPMRwaDfo',
  authDomain: 'yba-shirts.firebaseapp.com',
  databaseURL: 'https://yba-shirts.firebaseio.com',
  projectId: 'yba-shirts',
  storageBucket: 'yba-shirts.appspot.com',
  messagingSenderId: '645050793300',
  appId: '1:645050793300:web:9d453d3e2e15b96a',
};
// Initialize Firebase
if (process.env.NODE_ENV !== 'development') {
  firebaseConfig.measurementId = 'G-683EL0G92B';
}
firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firebaseMessaging = messaging;

if (firebaseMessaging) {
  firebaseMessaging.onMessage((payload) => {
    forwardMessage(payload);
  });
}
