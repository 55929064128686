import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from '../../../../../Functions/API';
import MaterialTable from 'material-table';

const WorkOrderChildren = () => {
  const { WorkOrderId, option } = useParams();
  const attributeMap = [
    { option: 'Notification', field: 'notifications' },
    { option: 'Invoice', field: 'invoices' },
    { option: 'Cost', field: 'costs' },
  ];
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const filter = {
      eager: {
        $where: {
          id: WorkOrderId,
        },
      },
    };
    const attribute = attributeMap.find((a) => a.option === option);
    if (attribute) {
      filter.eager[attribute.field] = {};
      get(`/workorder`, { filter }).then(({ results }) => {
        const [workOrder] = results;
        const relevantInfo = workOrder[attribute.field];
        const [first] = relevantInfo;
        setColumns(Object.keys(first).map((k) => ({ title: k.toUpperCase(), field: k })));
        setData(relevantInfo);
      });
    }
  }, []);

  return (
    <div>
      <MaterialTable
        title={option}
        data={data}
        columns={columns}
        onRowClick={(e, rowData) => {
          history.push(`/workorder/${WorkOrderId}/history/${option}/${rowData.id}`);
        }}
      />
    </div>
  );
};

export default WorkOrderChildren;
