import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import NewVendorDialog from '../../../../../Vendors/NewVendorDialog';

const filter = createFilterOptions();

const VendorAutocomplete = ({ callback, vendors, vendor }) => {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(null);

  useEffect(() => {
    setValue(vendor);
  }, [vendor]);

  const handleValueChange = (newValue) => {
    callback(newValue ? newValue : null);
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue(newValue.inputValue);
          } else {
            handleValueChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={vendors}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name ?? 'No Name :/';
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name ?? 'No Name :/'}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Vendor"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '8px', width: '100%' }}
            error={vendor && !vendor.quickbooksId}
            helperText={vendor && !vendor.quickbooksId && 'This vendor is not in QuickBooks'}
          />
        )}
      />
      <NewVendorDialog open={open} toggleOpen={toggleOpen} callback={handleValueChange} initialValue={dialogValue} />
    </React.Fragment>
  );
};

export default VendorAutocomplete;

VendorAutocomplete.propTypes = {
  callback: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  vendor: PropTypes.object.isRequired,
};
