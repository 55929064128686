import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { fetchUsers } from '../../../Stores/Users/Actions';

const Users = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const columns = [
    { field: 'id', title: 'Id' },
    { field: 'firstName', title: 'First Name' },
    { field: 'lastName', title: 'Last Name' },
  ];

  useEffect(() => {
    fetchUsers().then(setUsers);
  }, []);

  const handleRowClick = (e, data) => {
    history.push(`/developer-tools/users/${data.id}`);
  };

  return <MaterialTable title="Users" columns={columns} data={users} onRowClick={handleRowClick} />;
};

export default Users;
