/* eslint-disable react/jsx-key */
/* eslint react/no-array-index-key:0 */
/* eslint no-await-in-loop:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { storage } from '../../fire';
import Menu from '../Menu';
import Summary from '../Accounting/Summary';

class OldInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'Type', field: 'type' },
        { title: 'Description', field: 'description' },
        { title: 'Price', field: 'price', render: (rowData) => <div>${rowData.price}</div> },
        { title: 'Quantity', field: 'quantity' },
        { title: 'Total', field: 'total', render: (rowData) => <div>${rowData.total}</div> },
      ],
      oldProductsColumns: [
        { title: 'Style', field: 'style' },
        { title: 'Color', field: 'color' },
        { title: 'Quantity', field: 'quantity' },
        {
          title: 'Sizes',
          render: (rowData) => (
            <div>
              {rowData.sizes.map((size) => (
                <div>
                  {size.name}:{size.quantity}
                </div>
              ))}
            </div>
          ),
        },
      ],
      addressColumns: [
        { title: 'Send To', field: 'send_to' },
        { title: 'Attn', field: 'attn' },
        { title: 'Line 1', field: 'line_1' },
        { title: 'Line 2', field: 'line_2' },
        { title: 'Line 3', field: 'line_3' },
        { title: 'City', field: 'city' },
        { title: 'State', field: 'state' },
        { title: 'Zip', field: 'zip' },
      ],
      data: [],
      attachments: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.invoiceableId;
    this.props.get(`/getOldInvoicing?id=${id}`, []).then((data) => {
      this.setState({
        id,
        data,
      });
    });
    this.props.get(`/getOldProducts?id=${id}`, []).then((oldProducts) => {
      this.setState({
        oldProducts,
      });
    });
    this.props.get(`/getOldAddress?id=${id}`, []).then((addressData) => {
      this.setState({
        addressData,
      });
    });
    this.props.get(`/getOldAttachments?id=${id}`, []).then(async (attachments) => {
      for (const attachment of attachments) {
        const storageRef = storage.ref(`oldSystem/${attachment.filename}`);
        attachment.downloadURL = await storageRef.getDownloadURL();
      }
      this.setState({
        attachments,
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.id && (
          <div>
            <div>
              {this.state.attachments.map((attachment, key) =>
                attachment.extension === 'xlsx' ||
                attachment.extension === 'xls' ||
                attachment.extension === 'csv' ||
                attachment.extension === 'msg' ? (
                  <div>
                    <a href={attachment.downloadURL}>{attachment.alias}</a>
                  </div>
                ) : (
                  <iframe src={attachment.downloadURL} key={key} style={{ width: '600px', height: '500px' }} />
                ),
              )}
            </div>
            <MaterialTable columns={this.state.columns} data={this.state.data} title="Invoice" options={{ paging: false }} />
            <br />
            <br />
            <MaterialTable columns={this.state.oldProductsColumns} data={this.state.oldProducts} title="Products" options={{ paging: false }} />
            <br />
            <br />
            <MaterialTable columns={this.state.addressColumns} data={this.state.addressData} title="Address" options={{ paging: false }} />
          </div>
        )}
      </div>
    );
  }
}

export default OldInvoices;
