/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, post } from '../../../../Functions/API';
import { TextField, Grid, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { closeDialog } from '../../../../Stores/Dialog/Actions';

const Shipping = ({ history, orders }) => {
  const [courier, setCourier] = useState({});
  const [method, setMethod] = useState({});
  const [couriers, setCouriers] = useState([]);
  const [shippingServiceCodes, setShippingServiceCodes] = useState([]);
  const [filteredServiceCodes, setFilteredServiceCodes] = useState([]);

  useEffect(() => {
    get('/couriers/favorites').then(setCouriers);
    get('/shippingServices').then((serviceCodes) => {
      setShippingServiceCodes(serviceCodes);
      setFilteredServiceCodes(filteredServiceCodes);
    });
  }, []);

  useEffect(() => {
    setFilteredServiceCodes(shippingServiceCodes.filter((serviceCode) => serviceCode.courierId === courier.id));
  }, [courier]);

  const handleCourierChange = (e, v) => {
    setCourier(v);
  };

  const handleMethodChange = (e, v) => {
    setMethod(v);
  };

  const shipFulfillmentOrders = () => {
    post('/fulfillment/orders/shipments', {
      courier,
      shippingServiceCode: method,
      orders,
    }).then((results) => {
      history.push(`/fulfillment/shipments/${results.id}`);
      closeDialog();
    });
  };

  return (
    <>
      <Autocomplete
        options={couriers}
        getOptionLabel={(option, value) => {
          return option.name;
        }}
        onChange={handleCourierChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Courier"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
            margin="normal"
            component="div"
          />
        )}
      />
      <Autocomplete
        options={filteredServiceCodes}
        getOptionLabel={(option, value) => {
          return option.label;
        }}
        onChange={handleMethodChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Shipping Method"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
            margin="normal"
            component="div"
          />
        )}
      />
      <Grid container justify="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={shipFulfillmentOrders}>
            Ship
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

Shipping.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
};

Shipping.displayName = 'Shipping';

export default Shipping;
