import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { fetchCountries } from '../../../Stores/Countries/Actions';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

function CountriesSearch({ index, getValues, control, country }) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  return (
    <Controller
      name={`contacts[${index}].country`}
      control={control}
      defaultValue={getValues()['contacts'][index]?.country ?? country}
      onChange={([, data]) => data}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        // <TextField fullWidth label="City" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
        <Autocomplete
          options={countries}
          value={value}
          onChange={(e, data) => onChange(data)}
          getOptionLabel={(option) => (option?.name ? option.name : '')}
          renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} label="Country" />}
        />
      )}
    />
  );
}

CountriesSearch.propTypes = {
  getValues: PropTypes.any,
  control: PropTypes.any,
  index: PropTypes.any,
  country: PropTypes.any,
};

export default CountriesSearch;
