/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Menu from '../../Components/Menu';
import Summary from '../../Components/Accounting/Summary';
import { Route, Switch } from 'react-router-dom';
import OldWorkOrders from '../../Components/OldSystem/OldWorkOrders';
import { get, post } from '../../Functions/API';
import OldInvoices from '../../Components/OldSystem/OldInvoices';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'Name', field: 'name' },
        { title: 'Legal Name', field: 'legal_name' },
        {
          title: 'Created At',
          field: 'created_at',
          render: (rowData) => {
            const date = new Date(rowData.created_at);
            return <div>{date.toDateString()}</div>;
          },
        },
        { title: 'Email', field: 'email' },
      ],
      data: [],
    };
  }

  componentDidMount() {
    this.props.get('/getOldCustomers', []).then((data) => {
      this.setState({
        data,
      });
    });
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/OldSystem/Invoice/:invoiceableId" component={(props) => <OldInvoices {...props} get={get} post={post} />} />
          <Route exact path="/OldSystem/:customerId" component={(props) => <OldWorkOrders {...props} get={get} post={post} />} />
          <Route exact path="/OldSystem">
            <MaterialTable
              columns={this.state.columns}
              data={this.state.data}
              title="Customers From the Old Old System"
              options={{ paging: false }}
              onRowClick={(event, rowData) => {
                console.log(rowData.id);
                // this.props.setCustomer(rowData.id, rowData.name);
                window.location.href = `/OldSystem/${rowData.id}/`;
              }}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default Customers;
