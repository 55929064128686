import React, { Component } from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import { newFile, updateFile } from '../../../../../Functions/Files';
import { storage } from '../../../../../fire';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { CircularProgress } from '@material-ui/core';

const MAX_FILE_SIZE = 40 * 1024 * 1024; // 20 MB
class DropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  uploadToGoogle = async (file) => {
    const bFile = await newFile(this.props.type, '', '', this.props.workOrderID);
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref(`${this.props.workOrderID}/${bFile.id}_${file.file.name}`);
      const task = storageRef.put(file.file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        function error(err) {
          reject(err);
        },
        function complete() {
          task.snapshot.ref.getDownloadURL().then(async (results) => {
            const newFile = await updateFile(bFile.id, { url: results, fileName: `${this.props.workOrderID}/${bFile.id}_${file.file.name}` });
            resolve(newFile);
          });
        }.bind(this),
      );
    });
  };

  handleChange = async (files) => {
    // upload then return objects
    if (this.props.autoUpload) {
      this.setState({ uploading: true });
      try {
        const newFiles = await Promise.all(files.map((file) => this.uploadToGoogle(file)));
        this.props.handleDrop(newFiles);
      } catch (err) {
        console.error(err);
        createSnack('Error', err.message);
      } finally {
        this.setState({ uploading: false });
      }
    } else {
      // push raw files to handleDrop for parent to handle
      this.props.handleDrop(files.map((file) => file.file));
    }
  };

  render() {
    return (
      <div>
        {this.state.uploading ? (
          <CircularProgress />
        ) : (
          <DropzoneAreaBase
            acceptedFiles={this.props.acceptedFiles ? this.props.acceptedFiles : []}
            filesLimit={this.props.filesLimit}
            id={this.props.id}
            onAdd={this.handleChange}
            showPreviewsInDropzone={false}
            showAlerts={['error']}
            maxFileSize={MAX_FILE_SIZE}
          />
        )}
      </div>
    );
  }
}

DropZone.propTypes = {
  filesLimit: PropTypes.number,
  type: PropTypes.string,
  workOrderID: PropTypes.number,
  handleDrop: PropTypes.func,
  id: PropTypes.string,
  autoUpload: PropTypes.bool,
  acceptedFiles: PropTypes.array,
};

DropZone.defaultProps = {
  // if left as false then handleDrop method will receive files
  // if set to true then handleDrop will receive url, filename and id saved in mysql
  autoUpload: false,
  filesLimit: 10,
};

export default DropZone;
