import axios from 'axios';
import { auth } from '../fire';

export const getHomePage = () => {
  if (process.env.NODE_ENV === 'development') return 'http://localhost:3001/api';
  if (process.env.REACT_APP_LOCATION === 'live') return 'https://yba-live-v5py6hh2tq-uc.a.run.app/api';
  if (process.env.REACT_APP_LOCATION === 'beta') return 'https://yba-dev-v5py6hh2tq-uc.a.run.app/api';
  console.error('WRONG HOMEPAGE URL');
};

export const get = async (url, params, type) => {
  const token = await auth.currentUser.getIdToken(false);
  let finalUrl = new URL(getHomePage() + url);
  if (params) {
    const isArray = params instanceof Array;
    if (type === 'obj' || !isArray) {
      Object.keys(params).map((key) => {
        if (typeof params[key] === 'object') {
          params[key] = JSON.stringify(params[key]);
        }
        finalUrl.searchParams.append(key, params[key]);
      });
    } else {
      for (const param of params) {
        finalUrl.searchParams.append(param.name, param.value);
      }
    }
  }
  const res = await axios({
    url: finalUrl.href,
    method: 'GET',
    headers: {
      token,
    },
  });
  return res.data;
};

export const post = async (url, json) => {
  const token = await auth.currentUser.getIdToken(false);
  const finalUrl = getHomePage() + url;
  const res = await axios({
    url: finalUrl,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token,
    },
    data: json,
  });
  return res.data;
};

export const patch = async (purl, data) => {
  const token = await auth.currentUser.getIdToken(false);
  const url = getHomePage() + purl;
  const res = await axios({
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      token,
    },
    data,
  });
  return res.data;
};

export const remove = async (purl, data) => {
  const token = await auth.currentUser.getIdToken(false);
  const url = getHomePage() + purl;
  const res = await axios({
    url,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      token,
    },
    data,
  });
  return res.data;
};
