import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Switch, Typography, withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { updateWorkOrder, updateInvoiceDate } from '../../../../../Stores/WorkOrder/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import UserStore from '../../../../../Stores/User/Store';
import UsersStore from '../../../../../Stores/Users/Store';
import SalesStore from '../../../../../Stores/Sales/Store';
import { convertDateToSQLDate } from '../../../../Utils/SqlDate';

const WOSwitch = ({ workOrder, type, access, title }) => {
  const user = UserStore.getUser();
  const [salesmanName, setSalesmanName] = useState('');
  useEffect(() => {
    getSales();
    SalesStore.on('change', getSales);
    return () => {
      SalesStore.removeListener('change', getSales);
    };
  }, []);

  const getSales = () => {
    const sales = SalesStore.getSales();
    if (sales.length) {
      const salesman = UsersStore.getUser(sales[0].userID);
      setSalesmanName(`${salesman.firstName} ${salesman.lastName}`);
    }
  };

  const CustomSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: blue[800],
      },
      '&$checked + $track': {
        backgroundColor: blue[800],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <FormControl component="div" size={'small'}>
      <FormControlLabel
        control={
          <CustomSwitch
            value={Boolean(workOrder[type])}
            size={'small'}
            checked={Boolean(workOrder[type])}
            onChange={() => {
              const updateBody = { [type]: !Boolean(workOrder[type]) };
              if (workOrder?.waitInvoice && type === 'productionComplete' && !workOrder[type]) {
                addNotification('Sales', 'Unchecked Sales Approved due to wait to invoice', '', user, workOrder);
                updateBody.salesApproved = false;
              }
              updateWorkOrder(workOrder.id, updateBody);
              switch (type) {
                case 'costAndInvoiceComplete': {
                  if (!Boolean(workOrder[type])) {
                    addNotification('Sales', `Cost and Invoice are now ready for ${salesmanName}'s approval`, '', user, workOrder);
                    if (!workOrder.invoiceDate) {
                      updateWorkOrder(workOrder.id, { invoiceDate: convertDateToSQLDate(new Date()) });
                    }
                  }
                  break;
                }
                case 'salesApproved': {
                  // addNotification('Sales', '', '', user, workOrder);
                  break;
                }
                case 'cspCostReviewed': {
                  // addNotification('Sales', '', '', user, workOrder);
                  break;
                }
                case 'productionComplete': {
                  if (!Boolean(workOrder[type])) {
                    addNotification('Sales', 'Production is now Complete', '', user, workOrder);
                  }
                  break;
                }
                case 'onHold': {
                  if (!Boolean(workOrder[type])) {
                    addNotification('Production', 'This work order has been put on hold', '', user, workOrder);
                  } else {
                    addNotification('Production', 'This work order has been taken off hold', '', user, workOrder);
                  }
                  break;
                }
                default: {
                  console.log(type);
                }
              }
            }}
            disabled={!access}
          />
        }
        label={<Typography style={{ fontSize: 'small' }}>{title ? title : type.toUpperCase()}</Typography>}
        labelPlacement="top"
      />
    </FormControl>
  );
};

export default WOSwitch;

WOSwitch.propTypes = {
  workOrder: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  access: PropTypes.bool,
};
