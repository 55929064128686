/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import blocked from '../../images/blocked_meme.jpeg';

function UnauthorizedPage({ logout }) {
  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <img src={blocked} alt="Blocked" />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item>
          <Typography variant="h3">You don't have permission to view this page</Typography>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '15px' }} container justify="center">
        <Grid item>
          <Button href="/" color="primary" variant="contained">
            Go Home
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '15px' }} container justify="center">
        <Grid item>
          <Button onClick={logout} color="secondary" variant="contained">
            Log Out
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

UnauthorizedPage.propTypes = { logout: PropTypes.func };

export default UnauthorizedPage;
