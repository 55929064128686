import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Store from '../../Stores/WorkOrder/Store';
import { loadWorkOrder } from '../../Stores/WorkOrder/Actions';
import Header from '../../Components/WorkOrder/Header';
import Footer from '../../Components/WorkOrder/Footer';
import CustomerStore from '../../Stores/Customer/Store';
import { useParams } from 'react-router-dom';

const WorkOrder = () => {
  const [workOrder, setWorkOrder] = useState({});
  const [customer, setCustomer] = useState({});
  const { WorkOrderId } = useParams();

  useEffect(() => {
    Store.on('change', getWorkOrder);
    CustomerStore.on('change', getCustomer);
    getCustomer();
    loadWorkOrder(WorkOrderId);
    return () => {
      Store.removeListener('change', getWorkOrder);
      CustomerStore.removeListener('change', getCustomer);
    };
  }, [WorkOrderId]);

  const getWorkOrder = () => {
    setWorkOrder({ ...Store.getWorkOrder() });
  };

  const getCustomer = () => {
    setCustomer({ ...CustomerStore.getCustomer() });
  };

  return (
    <div style={{ backgroundColor: '#eaeaea' }}>
      <title>{`MSAS-${workOrder.workOrderID}-${customer?.name}`}</title>
      <Header workOrder={workOrder} />
      {Boolean(customer && (!customer.locked || workOrder.overruled)) && <Footer workOrder={workOrder} />}
    </div>
  );
};

export default WorkOrder;

WorkOrder.propTypes = {
  match: PropTypes.object,
};
