import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.notifications = [];
  }

  getAll() {
    return this.notifications;
  }

  addNotification = (notification) => {
    this.notifications.push(notification);
    this.emit('change');
  };

  editNotification = (notification) => {
    if (notification.parentId) {
      const parentIndex = this.notifications.findIndex((n) => n.id === notification.parentId);
      const index = this.notifications[parentIndex].children.findIndex((n) => n.id === notification.id);
      this.notifications[parentIndex].children[index] = notification;
    } else {
      const index = this.notifications.findIndex((n) => n.id === notification.id);
      this.notifications[index] = notification;
    }
    this.emit('change');
  };

  deleteNotification = (notification) => {
    const index = this.notifications.findIndex((n) => n.id === notification.id);
    this.notifications.splice(index, 1);
    this.emit('change');
  };

  loadNotifications(notifications) {
    this.notifications = notifications;
    this.emit('change');
  }

  handleActions(action) {
    switch (action.type) {
      case 'ADD_NOTIFICATION': {
        this.addNotification(action.newNotification);
        break;
      }
      case 'EDIT_NOTIFICATION': {
        this.editNotification(action.newNotification);
        break;
      }
      case 'DELETE_NOTIFICATION': {
        this.deleteNotification(action.notification);
        break;
      }
      case 'RECEIVE_NOTIFICATIONS': {
        this.loadNotifications(action.notifications);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
