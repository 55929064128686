/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prefer-stateless-function:0 */
import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Customers from './Customers';
import Invoices from './Invoices';
import Vendors from './Vendors';
import Bills from './Bills';

class ATabs extends Component {
  render() {
    return (
      <div>
        <AppBar position="sticky" color="default">
          <Tabs value={this.props.active} onChange={this.props.handleChange} variant={window.innerWidth < 1200 ? 'scrollable' : 'fullWidth'} centered>
            <Tab label="Customers" />
            <Tab label="Invoices" />
            <Tab label="Vendors" />
            <Tab label="Bills" />
          </Tabs>
        </AppBar>
        <div>
          {(() => {
            switch (this.props.active) {
              case 0:
                return (
                  <Customers
                    homepage={this.props.homepage}
                    lockCustomer={this.props.lockCustomer}
                    get={this.props.get}
                    post={this.props.post}
                    createSnack={this.props.createSnack}
                  />
                );
              case 1:
                return <Invoices homepage={this.props.homepage} get={this.props.get} post={this.props.post} createSnack={this.props.createSnack} />;
              case 2:
                return <Vendors homepage={this.props.homepage} get={this.props.get} post={this.props.post} createSnack={this.props.createSnack} />;
              case 3:
                return <Bills homepage={this.props.homepage} get={this.props.get} post={this.props.post} createSnack={this.props.createSnack} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

export default ATabs;
