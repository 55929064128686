import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  title: {
    padding: '5px',
    textAlign: 'center',
    width: '100%',
  },
  autocomplete: {
    padding: '5px',
  },
  dropZone: {
    padding: '9px',
  },
  loadingBar: {
    width: '100%',
  },
  downloadButton: {
    margin: '5px',
    color: 'darkgreen',
    borderColor: 'darkgreen',
  },
  uploadButton: {
    margin: '5px',
    color: 'green',
    borderColor: 'green',
  },
  drawerPaper: {
    height: '90%',
  },
});

export default useStyles;
