/* eslint react/no-unescaped-entities:0 */
/* eslint react/prop-types:0 */
/* eslint no-console:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-useless-escape:0 */
/* eslint react/state-in-constructor:0 */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  LinearProgress,
  Link,
  Paper,
  Grid,
  Typography,
  Modal,
  SnackbarContent,
  Snackbar,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { auth } from '../../fire';
import '../../Components/Authentication/styles/index.css';
import ybaLogo from '../../images/yba-logo.png';
import cspLogoOnly from '../../images/CSP-logo-only.png';
import queryString from 'query-string';
import UserStore from '../../Stores/User/Store';

function useQuery() {
  return queryString.parse(useLocation().search);
}

export default function LoginNew({ isInternal }) {
  const queryParams = useQuery();
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [emailErr, setEmailErr] = useState(null);
  const [emailForgot, setEmailForgot] = useState(null);
  const [emailForgotErr, setEmailForgotErr] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordErr, setPasswordErr] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingForgot, setIsLoadingForgot] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const [displayError, setDisplayError] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: null, variant: null });

  useEffect(() => {
    UserStore.on('change', forward);
    return () => {
      UserStore.removeListener('change', forward);
    };
  }, []);

  const forward = () => {
    if (queryParams?.next && queryParams?.next !== '/') {
      history.push(queryParams.next);
    } else {
      history.push('/');
    }
  };

  const validate = () => {
    let validationPassed = true;

    setEmailErr(null);
    setPasswordErr(null);
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      validationPassed = false;
      setEmailErr('Not a valid email');
    }
    if (!password) {
      validationPassed = false;
      setPasswordErr('Please enter a password');
    }
    return validationPassed;
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClose = () => {
    setSnackBar({ open: false, message: null, variant: null });
  };

  const createSnack = (variant, message) => {
    setSnackBar({ open: true, variant, message });
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailForgot)) {
      setIsLoadingForgot(true);
      auth
        .sendPasswordResetEmail(emailForgot)
        .then(() => {
          createSnack('Success', 'Success! Check your email to change your password!');
        })
        .catch((err) => {
          console.log(err);
          createSnack('Error', `${err.code}! Please contact support!`);
        })
        .finally(() => {
          setIsLoadingForgot(false);
          setShowForgotPassword(false);
        });
    } else {
      setEmailForgotErr('Not a valid email');
    }
  };

  const login = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (validate()) {
      setIsLoading(true);
      setDisplayError(false);
      auth
        .signInWithEmailAndPassword(email, password)
        .catch((err) => {
          createSnack('Error', `${err.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const notificationStyles = {
    Accounting: '#43a047',
    Success: '#43a047',
    Sales: '#ffa000',
    Warning: '#ffa000',
    Error: '#ed1313',
    Info: '#3fd51b5',
    Production: '#979797',
    File: '#979797',
    Temp: '#c7c7c7',
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={6000}
        // TransitionComponent={<Slide direction={'right'}/>}
        onClose={handleClose}
      >
        <SnackbarContent
          onClose={handleClose}
          style={{
            backgroundColor: notificationStyles[snackBar.variant],
          }}
          message={snackBar.message}
        />
      </Snackbar>
      {isLoading && <LinearProgress style={{ height: '5px' }} />}
      <Modal open={showForgotPassword} onClose={closeForgotPassword}>
        <div className="forget-password-container">
          <Grid container component={Paper} elevation={6} alignContent="center" spacing={6}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmailForgot(e.target.value)}
                helperText={emailForgotErr}
                fullWidth
                error={!!emailForgotErr}
                autoFocus
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  margin: '16px 0px',
                }}
                disabled={isLoadingForgot}
                onClick={(e) => forgotPassword(e)}
              >
                Send Forgot Password Email
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Grid container component="main" style={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          style={{
            backgroundImage: 'url(https://wallpapercave.com/wp/wp3061462.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'grey',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div
            style={{
              margin: '50px 15px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={isInternal ? ybaLogo : cspLogoOnly} className="yba-logo" />
            <Avatar
              style={{
                margin: '10px',
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {isInternal ? 'Sign into MSAS' : 'Sign into CSP'}
            </Typography>
            {!auth.currentUser ? (
              <>
                <form
                  style={{
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => changeEmail(e)}
                    helperText={emailErr}
                    error={!!emailErr}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={(e) => changePassword(e)}
                    autoComplete="current-password"
                    error={!!passwordErr}
                    helperText={passwordErr}
                  />
                  {displayError && (
                    <Typography variant="body2" style={{ paddingLeft: '10px', color: '#f44335' }}>
                      It looks like that didn't work. Try again, click Forgot Password, or Sign Up.
                    </Typography>
                  )}
                  <Button
                    id="signIn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      margin: '16px 0px',
                    }}
                    disabled={isLoading}
                    onClick={(e) => login(e)}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link onClick={() => setShowForgotPassword(true)} variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href={`${isInternal ? '/createUser' : '/csp/createUser'}`} variant="body2">
                        Don't have an account? Sign Up
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : (
              <>
                <div>You're already logged in.</div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '16px 0px',
                  }}
                  disabled={isLoading}
                  onClick={forward}
                >
                  Go to a better place!
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
