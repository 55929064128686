import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { get } from '../../Functions/API';

const DepositPhotos = () => {
  const [pictures, setPictures] = useState([]);
  const [dpAuth, setDPAuth] = useState(null);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState(0);

  useEffect(() => {
    placeSearch(page);
  }, [page, pageSize]);

  const placeSearch = (page) => {
    if (search) {
      setPictures([]);
      setLoading(true);
      const params = { search, page, pageSize };
      if (dpAuth) {
        params.dpAuth = dpAuth;
      }
      get('/integrations/deposit-photos/search', params)
        .then(({ data, dpAuth }) => {
          setLoading(false);
          if (data) {
            const { result, count } = data;
            if (result) {
              setPictures(result);
              setCount(count);
            }
          }
          setDPAuth(dpAuth);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const checkKey = (e) => {
    if (e.key === 'Enter') {
      placeSearch(page);
    }
  };

  const downloadPhoto = (id) => {
    const params = { dp_item_id: id, dp_option: 'xl-2015' };
    if (dpAuth) {
      params.dpAuth = dpAuth;
    }
    get('/integrations/deposit-photos/get-photo', params).then(({ data }) => {
      window.open(data.downloadLink);
    });
  };

  return (
    <div>
      <AppBar position={'relative'} style={{ backgroundColor: '#eaeaea', color: 'black' }}>
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant={'h5'}>Deposit Photos</Typography>
            </Grid>
            <Grid item>
              <TextField
                placeholder={'Search'}
                fullWidth
                onKeyUp={checkKey}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <Grid container spacing={1} style={{ marginBottom: '70px' }}>
        {pictures.map((p, k) => (
          <Grid item key={k} xl={2}>
            <Card style={{ height: '100%' }}>
              <CardHeader title={p.id} />
              <CardActionArea onClick={() => downloadPhoto(p.id)}>
                <img src={p.thumb_huge} style={{ width: '100%', border: '2px solid black' }} />
              </CardActionArea>
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemText primary={`${p.width}x${p.height}`} secondary={'Size'} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <AppBar position={'fixed'} style={{ bottom: 0, top: 'auto', backgroundColor: '#eaeaea' }}>
        <Toolbar>
          <Grid container justify={'flex-end'}>
            <Grid item>
              <TablePagination
                count={count}
                onChangePage={(e, p) => setPage(p)}
                page={page}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default DepositPhotos;
