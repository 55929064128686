import React, { useState } from 'react';
import { Button, DialogActions, Switch, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { closeDialog } from '../../../Stores/Dialog/Actions';
import { post } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';

function CreateStoreDialog({ salesmen, customerId, getStores }) {
  const [name, setName] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [storeId, setStoreId] = useState(null);
  // const [url, setURL] = useState(null);
  const [salesman, setSalesman] = useState(null);
  // const [shipThroughShopify, setShipThroughShopify] = useState(null);

  const addStore = () => {
    const salesId = salesman.id;
    post(`/integrations/order-desk/store`, { name, customerId, storeId, apiKey, salesId })
      .then(() => {
        getStores();
        closeDialog();
      })
      .catch((err) => {
        createSnack('Error', err.message);
        console.error(err);
      });
  };

  return (
    <>
      <TextField
        label="NAME"
        style={{ marginBottom: '10px', width: '100%' }}
        variant="outlined"
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      {/*<TextField*/}
      {/*  label="URL"*/}
      {/*  style={{ marginBottom: '10px', width: '100%' }}*/}
      {/*  variant="outlined"*/}
      {/*  onChange={(e) => {*/}
      {/*    setURL(e.target.value);*/}
      {/*  }}*/}
      {/*/>*/}
      <TextField
        label="API KEY"
        style={{ marginBottom: '10px', width: '100%' }}
        variant="outlined"
        onChange={(e) => {
          setApiKey(e.target.value);
        }}
      />
      <TextField
        label="Store ID"
        style={{ marginBottom: '10px', width: '100%' }}
        variant="outlined"
        onChange={(e) => {
          setStoreId(e.target.value);
        }}
      />
      <Autocomplete
        value={salesman}
        onChange={(event, newValue) => {
          if (newValue === null || newValue.id) {
            setSalesman(newValue);
          }
        }}
        id="contactsAutocomplete"
        options={salesmen}
        getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}` : null)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            multiline
            variant="outlined"
            label="Salesman"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
          />
        )}
      />
      {/*<FormControlLabel*/}
      {/*  control={*/}
      {/*    <Switch*/}
      {/*      id="ship-through-shopify"*/}
      {/*      checked={Boolean(shipThroughShopify)}*/}
      {/*      onChange={(event) => {*/}
      {/*        setShipThroughShopify(event.target.checked);*/}
      {/*      }}*/}
      {/*      value={Boolean(shipThroughShopify)}*/}
      {/*      color="primary"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  label="Ship Through Shopify"*/}
      {/*  labelPlacement="top"*/}
      {/*/>*/}
      <DialogActions>
        <Button autoFocus onClick={closeDialog} color={'secondary'}>
          Cancel
        </Button>
        <Button autoFocus onClick={addStore} color={'primary'}>
          Create
        </Button>
      </DialogActions>
    </>
  );
}

CreateStoreDialog.propTypes = {
  salesmen: PropTypes.array,
  customerId: PropTypes.any,
  getStores: PropTypes.func,
};

export default CreateStoreDialog;
