/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { get, post, remove } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import PropTypes from 'prop-types';
import { UsersStore, WorkOrderStore } from '../../../../../Stores';
import { ExpandMore, OpenInNew, Delete, Add, Refresh } from '@material-ui/icons';
import dhl from '../../../../../images/shipping/dhl.svg';
import fedex from '../../../../../images/shipping/fedex.svg';
import ups from '../../../../../images/shipping/ups.svg';
import pending from '../../../../../images/shipping/pending.svg';
import usps from '../../../../../images/shipping/usps.png';
import ViewContact from '../../../../Shared/Contacts/ViewContact';
import { createDialog } from '../../../../../Stores/Dialog/Actions';

const DEFAULT_TOOLTIP = 'Creates shipment in ecommparcel';
const ADDRESS_REQUIRED_TOOLTIP = 'Shipping contact required';
const ADDRESS_INVALID_TOOLTIP = 'Shipping contact is invalid';
const SPLITSHIP_MISSING_TOOLTIP = 'Some products are missing shipping contacts';
const ECOMPARCEL_INTEGRATION_ID = '8438';

const Shipments = () => {
  const [loading, setLoading] = useState(false);
  const [tooltip, setTooltip] = useState(ADDRESS_REQUIRED_TOOLTIP);
  const [shipments, setShipments] = useState([]);
  const [dueByDate, setDueByDate] = useState(null);
  const [shippingContacts, setShippingContacts] = useState(null);
  const [orders, setOrders] = useState(null);

  const [workOrder, setWorkOrder] = useState({});
  const [email, setEmail] = useState(null);

  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);

  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    if (workOrder.id) {
      setDueByDate(workOrder.dueDate);
      setupShipping();
    }
  }, [workOrder.id]);

  const getWorkOrderOrders = async () => {
    // TODO eventually we should convert all orders endpoints to use new strategy
    const orders = await get(`/workorder/${workOrder?.id}/orders`);
    setOrders(orders.results);
    return orders.results;
  };

  const contactIsValid = (contact) => {
    if (!contact?.address1) return false;
    if (!contact?.city) return false;
    if (!contact?.zip) return false;
    if (!contact?.state) return false;
    if (!contact?.country) return false;
    return true;
  };

  const setupShipping = async () => {
    try {
      setLoading(true);
      if (workOrder?.id) {
        await getShipments();
      }
      let user;
      const [sales] = await get(`/sales/${workOrder?.id}`);
      if (sales) {
        user = UsersStore.getUser(sales.userID);
      }
      //if user is Travis
      if (user.id === 22) {
        const [salesAssistant] = await get('/salesAssistants', { workOrderID: workOrder.id });
        if (salesAssistant) {
          user = UsersStore.getUser(salesAssistant.uid);
        }
      }
      if (user) {
        setEmail(user.email);
      }
      const shippingContacts = workOrder.contacts.filter((contact) => contact.type === 'shipping');
      if (shippingContacts.length === 0) {
        setTooltip(ADDRESS_REQUIRED_TOOLTIP);
      } else if (shippingContacts.length === 1) {
        if (contactIsValid(shippingContacts[0])) {
          setTooltip(DEFAULT_TOOLTIP);
        } else {
          setTooltip(ADDRESS_INVALID_TOOLTIP);
        }
      } else {
        const localOrders = await getWorkOrderOrders();
        const ordersWithContacts = localOrders.filter((order) => order?.contactId && contactIsValid(order?.contact));
        if (ordersWithContacts.length === localOrders.length) {
          setTooltip(DEFAULT_TOOLTIP);
        } else {
          setTooltip(SPLITSHIP_MISSING_TOOLTIP);
        }
      }
      setShippingContacts(shippingContacts);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getShipments = () => {
    return get(`/workorder/${workOrder?.id}/shipments`)
      .then(setShipments)
      .catch((err) => createSnack('Error', err.message));
  };

  const recreateShipments = () => {
    createDialog({
      content: 'Are you sure you want to delete all shipments and recreate them?',
      title: 'Recreate Shipments',
      actions: [
        { title: 'no', color: 'secondary', callback: null },
        { title: 'yes', color: 'primary', callback: () => createShipment(true) },
      ],
    });
  };

  const createShipment = (recreate) => {
    setLoading(true);
    const shipmentInfo = {
      dueByDate: dueByDate ? dueByDate.substring(0, 10) : null,
      viewableID: workOrder.workOrderID,
    };

    if (shippingContacts.length === 1) {
      const shipContact = shippingContacts[0];
      shipmentInfo.receiver = {
        name: shipContact.co ? shipContact.co : workOrder.customer,
        company: shipContact.organization ? shipContact.organization : workOrder.customer ? workOrder.customer : '',
        address1: shipContact.address1,
        address2: shipContact.address2 ? shipContact.address2 : '',
        city: shipContact.city,
        state: shipContact?.state?.name,
        zip: shipContact.zip,
        country: shipContact.country.code,
        email,
        phone: '0000000000',
        contactId: shippingContacts[0].id,
      };
    } else {
      const receivers = shippingContacts.map((shipContact) => {
        const foundOrders = orders.filter((order) => order.contactId === shipContact.id);

        return {
          name: shipContact.co ? shipContact.co : workOrder.customer,
          company: shipContact.organization ? shipContact.organization : workOrder.customer ? workOrder.customer : '',
          address1: shipContact.address1,
          address2: shipContact.address2 ? shipContact.address2 : '',
          city: shipContact.city,
          state: shipContact?.state?.name,
          zip: shipContact.zip,
          country: shipContact.country.code,
          email: null,
          phone: '0000000000',
          orders: foundOrders.map((order) => order.ordersID),
          contactId: shipContact.id,
        };
      });
      shipmentInfo.receivers = receivers;
    }

    const shipmentUrl = recreate ? `/workorder/${workOrder?.id}/shipments?recreate=true` : `/workorder/${workOrder?.id}/shipments`;

    post(shipmentUrl, shipmentInfo)
      .then(getShipments)
      .catch((err) => createSnack('Error', err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const openDeleteConfirmation = (shipmentID) => {
    createDialog({
      content: 'Are you sure you want to delete this shipment?',
      title: 'Delete Shipment',
      actions: [
        { title: 'no', color: 'secondary', callback: null },
        { title: 'yes', color: 'primary', callback: () => deleteShipment(shipmentID) },
      ],
    });
  };

  const deleteShipment = (shipmentID) => {
    remove(`/workorder/${workOrder?.id}/shipments/${shipmentID}`)
      .then(getShipments)
      .catch((err) => createSnack('Error', err.message));
  };

  const ShippingLogo = ({ shipment }) => {
    let logo = pending;
    if (shipment.carrier?.toLowerCase() === 'ups') logo = ups;
    else if (shipment.carrier?.toLowerCase() === 'usps') logo = usps;
    else if (shipment.carrier?.toLowerCase() === 'fedex') logo = fedex;
    else if (shipment.carrier?.toLowerCase() === 'dhl') logo = dhl;
    return <img style={{ maxHeight: '100px', maxWidth: '100%' }} src={logo} />;
  };
  ShippingLogo.propTypes = { shipment: PropTypes.object };

  const TrackingLink = ({ carrier, trackingNumber }) => {
    if (carrier?.toLowerCase() === 'ups')
      return (
        <a rel="noreferrer" target="_blank" href={`http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`}>
          {trackingNumber}
        </a>
      );
    if (carrier?.toLowerCase() === 'usps')
      return (
        <a rel="noreferrer" target="_blank" href={`https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`}>
          {trackingNumber}
        </a>
      );
    if (carrier?.toLowerCase() === 'fedex')
      return (
        <a rel="noreferrer" target="_blank" href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}>
          {trackingNumber}
        </a>
      );
    if (carrier?.toLowerCase() === 'dhl')
      return (
        <a rel="noreferrer" target="_blank" href={`http://www.dhl.com/content/g0/en/express/tracking.shtml?brand=DHL&AWB=${trackingNumber}`}>
          {trackingNumber}
        </a>
      );
    return <></>;
  };
  TrackingLink.propTypes = { carrier: PropTypes.string, trackingNumber: PropTypes.string };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Grid container direction="row" justify="space-between" alignItems="baseline">
        <Grid style={{ marginLeft: '15px', fontSize: '20px' }} item>
          Shipments
        </Grid>
        <Grid item>
          <Grid container alignItems="baseline">
            {shipments?.length ? (
              <Tooltip title="Delete and recreate shipments for this workorder">
                <span>
                  <IconButton color="primary" onClick={recreateShipments}>
                    <Refresh />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={tooltip}>
                <span>
                  <IconButton color="primary" disabled={Boolean(tooltip !== DEFAULT_TOOLTIP)} onClick={() => createShipment()}>
                    <Add />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress size={100} />
        </Grid>
      ) : (
        <Grid container spacing="1" direction="row" alignItems="flex-start">
          {Boolean(shipments.length) ? (
            shipments.map((shipment, index) => (
              <Grid key={index} xs={12} sm={6} md={4} item>
                <Card>
                  <CardContent>
                    <Grid container direction="column">
                      <Grid container direction="row">
                        <Typography component="h5" variant="h5">
                          {`${shipment.viewableID}-${shipment.number}`}
                        </Typography>
                      </Grid>
                      <Grid container spacing="1" direction="row" justify="space-between" alignItems="center">
                        <Grid xs={4} item>
                          <ShippingLogo shipment={shipment} />
                        </Grid>
                        <Grid xs={8} item>
                          <ViewContact contact={shipment?.contact} />
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid style={{ fontSize: '24px' }} xs={12} item>
                          <strong>Status</strong> {shipment.status}
                        </Grid>
                      </Grid>
                      {Boolean(shipment?.packages?.length) && (
                        <Grid container direction="row">
                          <Grid xs={12} item>
                            <em>Tracking Numbers</em>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container direction="row">
                        {shipment.packages.map((shipPackage) => (
                          <Grid xs={12} item key={shipPackage.trackingNumber}>
                            <TrackingLink carrier={shipment.carrier} trackingNumber={shipPackage.trackingNumber} />
                          </Grid>
                        ))}
                      </Grid>
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMore />}>Shipped Items</AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="row">
                            <Grid xs={12} item>
                              {
                                <span>
                                  {shipment?.invoices
                                    .map((invoice) => {
                                      return invoice?.sizes?.map((size) => {
                                        return `${invoice.type} ${size.packageNum} ${size.size}`;
                                      });
                                    })
                                    .flat()
                                    .join(', ')}
                                </span>
                              }
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Grid container direction="row">
                        <Grid xs={12} item>
                          {Boolean(shipment.status === 'Pending') && (
                            <IconButton
                              style={{ marginTop: '5px' }}
                              color="primary"
                              target="_blank"
                              href={`https://bfsship.rocksolidinternet.com/ec/#/ship/order/${ECOMPARCEL_INTEGRATION_ID}/${shipment.viewableID}-${shipment.number}`}
                            >
                              <OpenInNew />
                            </IconButton>
                          )}
                          <IconButton
                            style={{ marginTop: '5px' }}
                            color="secondary"
                            variant="contained"
                            onClick={() => openDeleteConfirmation(shipment.id)}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <div style={{ marginLeft: '20px' }}>No shipments yet</div>
          )}
        </Grid>
      )}
    </div>
  );
};

Shipments.propTypes = {
  workOrder: PropTypes.object,
};

Shipments.displayName = 'Shipments';

export default Shipments;
