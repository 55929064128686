import { withStyles, Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    background: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: 'none',
  },
  popper: {
    zIndex: 1250,
  },
}))(Tooltip);

export default CustomTooltip;
