import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Notifications from './Notifications';

const Right = ({ workOrder }) => {
  return (
    <Card
      style={{
        paddingTop: 0,
        maxHeight: '550px',
        height: '550px',
        overflowY: 'scroll',
      }}
    >
      <CardHeader title={'Notifications'} />
      <CardContent>
        <Notifications workOrder={workOrder} />
      </CardContent>
    </Card>
  );
};

export default Right;

Right.propTypes = {
  workOrder: PropTypes.object,
};
