import React, { useState } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';

const Home = () => {
  const { path, url } = useRouteMatch();
  console.log(url);
  const [options, setOptions] = useState(['WorkOrder', 'Notification', 'Product', 'Cost', 'Invoice', 'Production', 'Old']);

  return (
    <div style={{ padding: '15px' }}>
      <Grid container spacing={1} justify={'space-around'}>
        {options.map((o, k) => (
          <Grid item lg={4} key={k}>
            <Card>
              <CardHeader title={o} />
              <CardContent>Click here to view history</CardContent>
              <CardActions>
                <Button component={Link} to={`${url}/${o}`}>
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
