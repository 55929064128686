import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import React from 'react';

class Store extends EventEmitter {
  constructor() {
    super();
    this.orders = [];
  }

  getOrders() {
    return this.orders;
  }

  loadOrders = (orders) => {
    this.orders = orders;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_ORDERS': {
        this.loadOrders(action.results);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
