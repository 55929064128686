import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, CardHeader, Checkbox, Grid, List, ListItem, ListItemText } from '@material-ui/core';

const SkuSelector = ({ skus, callback }) => {
  const [selected, setSelected] = useState(null);
  const select = ({ id, inventory }) => {
    setSelected(id);
    callback({ selected: id, inventory });
  };

  return (
    <div>
      <Grid container spacing={1}>
        {skus.map((sku, key) => (
          <Grid item key={key}>
            <Card>
              <CardActionArea onClick={() => select(sku)}>
                <CardHeader
                  title={
                    <Grid container justify={'space-between'}>
                      <Grid item>{sku.sku}</Grid>
                      <Grid item>
                        <Checkbox size={'small'} checked={sku.id === selected} />
                      </Grid>
                    </Grid>
                  }
                  subheader={'SKU'}
                />
                <CardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary={sku.inventory.name} secondary={'Inventory'} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={sku.name} secondary={'Name'} />
                    </ListItem>
                  </List>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SkuSelector;

SkuSelector.propTypes = {
  skus: PropTypes.array,
  callback: PropTypes.func,
  selected: PropTypes.number,
};
