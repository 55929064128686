/* eslint react/no-this-in-sfc:0 */
/* eslint max-len:0 */
/* eslint no-nested-ternary:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
import React, { forwardRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import FullScreen from '@material-ui/icons/Fullscreen';
import { CardActions } from '@material-ui/core';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';
import VendorsStore from '../../Stores/Vendors/Store';
import { fetchVendors } from '../../Stores/Vendors/Actions';

const ArtRequestProduct = forwardRef((props, ref) => {
  const { WorkOrderId } = useParams();
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    VendorsStore.on('change', getVendors);
    if (VendorsStore.getVendors().length === 0) {
      console.log('gettingVendors');
      fetchVendors();
    } else {
      getVendors();
    }
    return () => VendorsStore.removeListener('change', getVendors);
  }, []);

  const getVendors = () => {
    setVendors([...VendorsStore.getVendors()]);
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        width: '70%',
        margin: 'auto',
        top: '10%',
        backgroundColor: 'white',
        padding: '15px',
        outline: 'none',
      }}
    >
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container justify="center" spacing={3}>
        <Grid item xl={12} style={{ textAlign: 'center' }}>
          <h2>Add Product to Request</h2>
        </Grid>
        <Grid item xl={4}>
          <FormControl style={{ margin: '8px', width: '100%' }} component="div">
            <InputLabel shrink htmlFor="vendor">
              Vendor
            </InputLabel>
            <NativeSelect
              onChange={(event) => props.updateArtRequestProductVendor(event)}
              value={props.artRequestProductVendor}
              input={<Input name="vendor" id="vendor" />}
            >
              <option>None</option>
              {vendors.map((vendor, key) => {
                return (
                  <option value={vendor.name} key={key}>
                    {vendor.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xl={3} style={{ marginTop: '8px' }}>
          <TextField
            id="styleNum"
            type="text"
            label="Style Number"
            value={props.artRequestProductStyleNumber}
            style={props.descriptionFieldStyle}
            onChange={props.updateArtRequestProductStyleNumber}
          />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container spacing={1}>
        <Grid item xl={12}>
          <h3>Images</h3>
        </Grid>
        {props.artRequestProductImages.map((item, key) => (
          <Grid key={key} item>
            <Card style={{ width: '170px', position: 'relative', height: '300px' }}>
              <CardContent>
                {item?.name?.substr(item?.name?.length - 4, item?.name?.length) === 'xlsx' ||
                item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'xls' ||
                item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'csv' ? (
                  <a href={item?.url}>{item?.name}</a>
                ) : !item?.url ? (
                  <div
                    style={{
                      width: '100%',
                      height: '300px',
                    }}
                  >
                    <img
                      style={{ width: '100%', marginTop: '50px' }}
                      src="https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08"
                    />
                  </div>
                ) : item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'jpg' ||
                  item?.name?.substr(item?.name?.length - 3, item?.name?.length) === 'png' ? (
                  <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <img style={{ width: '100%', marginTop: '50px' }} src={item?.url} />
                  </div>
                ) : (
                  <div style={{ position: 'relative', height: '100%' }}>
                    <IconButton
                      size="medium"
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: '#f3f2f7',
                      }}
                      onClick={() => this.openModal(item)}
                      color="secondary"
                      href=""
                    >
                      <FullScreen />
                    </IconButton>
                    <iframe
                      src={
                        item.url ||
                        'https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08'
                      }
                      style={{
                        width: '100%',
                        height: '300px',
                      }}
                    />
                  </div>
                )}
                <CardActions style={{ position: 'absolute', right: 0, bottom: 0, fontSize: '5px' }}>
                  <Button variant="contained" size="small" onClick={(event) => props.deleteArtRequestProductImage(event, item, key)}>
                    Delete
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xl={12}>
          <DropZone autoUpload={true} handleDrop={props.addArtRequestProductImages} id="artFile2" workOrderID={WorkOrderId} />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container justify="flex-end" spacing={1}>
        <Grid item xs={3} xl={1}>
          <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={props.updateArtRequestProduct}>
            Submit
          </Button>
        </Grid>
        <Grid item xs={3} xl={1}>
          <Button variant="contained" style={{ width: '100%' }} onClick={props.toggleArtRequestProduct}>
            Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

ArtRequestProduct.displayName = 'ArtRequestProductModal';

export default ArtRequestProduct;
