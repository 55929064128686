/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardHeader, Grid, Button } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import Uploads from './uploads';
import Data from './uploads/data';

const Companies = ({ get, post, user }) => {
  const [companies, setCompanies] = useState([]);
  console.log('hello');

  useEffect(() => {
    get('/getExternalTables').then((results) => {
      console.log(results);
      setCompanies(results);
    });
  }, []);

  return (
    <div>
      <Switch>
        <Route path="/external-data/:tableId/:uploadId" component={(props) => <Data {...props} get={get} post={post} user={user} />} />
        <Route path="/external-data/:tableId" component={(props) => <Uploads {...props} get={get} post={post} user={user} />} />
        <Route path="/external-data">
          <Grid container>
            {companies.map((company, key) => (
              <Grid item key={key}>
                <Card>
                  <CardHeader title={company.companyName} />
                  <CardActions style={{ float: 'right' }}>
                    <Button href={`/external-data/${company.id}`}>View</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Route>
      </Switch>
    </div>
  );
};

export default Companies;
