/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Options from '../../Components/Admin/Options';
import PriceList from '../../Components/Admin/PriceList';
import Users from '../../Components/Admin/Users';
import Filters from '../../Components/Admin/Filters';
import Bugs from '../../Components/Admin/Bugs';
import VersionControl from '../../Components/Admin/VersionControl';
import User from './user';
import { Route, Switch } from 'react-router-dom';
import { get, post } from '../../Functions/API';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/admin/user/:id" component={(props) => <User {...props} get={get} post={post} active={4} user={this.props.user} />} />
        <Route path="/admin">
          <div>
            <Grid container spacing={2} direction="column" alignItems="stretch" justify="space-evenly">
              <Grid item xs>
                <Users homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
              <Grid item xs>
                <Filters homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
              <Grid item xs>
                <Options homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
              <Grid item xs>
                <PriceList homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
              <Grid item xs>
                <Bugs homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
              <Grid item xs>
                <VersionControl homepage={this.props.homepage} createSnack={this.props.createSnack} get={this.props.get} post={this.props.post} />
              </Grid>
            </Grid>
          </div>
        </Route>
      </Switch>
    );
  }
}

export default Admin;
