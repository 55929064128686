/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadOrder } from '../../../../Stores/Orders/Actions';
import { Card, CardContent, CardHeader, Grid, LinearProgress, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import ContactForm from '../../../../Components/Shared/Contacts/ContactForm';
import MaterialTable from 'material-table';
import { useFieldArray, useForm } from 'react-hook-form';

const Order = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const { handleSubmit, control, reset, getValues, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  useEffect(() => {
    loadOrder(orderId).then((results) => {
      console.log(results);
      setOrder(results);
    });
  }, [orderId]);

  const { externalId, name, contact, createdAt, store, status, lineItems } = order;
  return order.id ? (
    <Grid container justify={'center'}>
      <Grid item xs={12} md={10}>
        <Paper style={{ marginTop: '10px', padding: '15px', backgroundColor: '#fff' }}>
          <Grid container spacing={1} justify={'center'}>
            <Grid item xs={12} lg={6} xl={'auto'}>
              <Card style={{ height: '100%', backgroundColor: '#eaeaea' }}>
                <CardHeader title={`${name}'s Order`} subheader={externalId} />
                <CardContent>
                  <List dense>
                    <ListItem>
                      <ListItemText primary={status?.name} secondary={'Status'} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={new Date(createdAt).toDateString()} secondary={'Order Placed'} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={store?.name} secondary={'Store'} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <ContactForm style={{ width: '100%' }} contact={contact} getValues={getValues} control={control} />
            <Grid item xs={12} xl={8}>
              <MaterialTable
                title={'Line Items'}
                style={{ height: '100%', backgroundColor: '#eaeaea' }}
                columns={[
                  {
                    title: 'Image',
                    render: (rowData) => <img src={rowData.ybaSku.ybaProduct.frontImage} alt={''} style={{ maxHeight: '50px', maxWidth: '50px' }} />,
                  },
                  { field: 'ybaSku?.ybaProduct?.name', title: 'Name', render: (rowData) => rowData?.ybaSku?.ybaProduct?.name },
                  { field: 'ybaSku?.sku', title: 'SKU', render: (rowData) => rowData?.ybaSku?.sku },
                  { field: 'quantity', title: 'Quantity' },
                  { field: 'status?.name', title: 'Status', render: (rowData) => rowData?.status?.name },
                ]}
                data={lineItems}
                options={{ padding: 'dense', paging: false, search: false, maxBodyHeight: '800px' }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <LinearProgress />
  );
};

export default Order;
