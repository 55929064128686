import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import OptionsStore from '../../../../../../Stores/Options/Store';
import { loadOptions, addOption } from '../../../../../../Stores/Options/Actions';
import { createSnack } from '../../../../../../Stores/Snack/Actions';
import { get, post } from '../../../../../../Functions/API';

const filter = createFilterOptions();

const TypeAutocomplete = ({ options, option, callback }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(option?.name);
  }, [option]);

  const handleValueChange = (newValue) => {
    if (newValue && typeof newValue === 'string') callback(newValue);
    callback(newValue ? newValue.name : null);
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            addOption(newValue)
              .then(() => {
                handleValueChange(newValue);
              })
              .catch((err) => {
                createSnack('Error', err.message);
              });
          } else if (newValue && newValue.inputValue) {
            addOption(newValue.inputValue)
              .then(() => {
                handleValueChange(newValue.inputValue);
              })
              .catch((err) => {
                createSnack('Error', err.message);
              });
          } else {
            handleValueChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // if (params.inputValue !== '' && !options.find((o) => o.name === params.inputValue)) {
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="type"
        options={options}
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.inputValue ? option.inputValue : option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Type"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: '8px', width: '100%' }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default TypeAutocomplete;

TypeAutocomplete.propTypes = {
  callback: PropTypes.func.isRequired,
  option: PropTypes.string,
  options: PropTypes.array,
};
