import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { get } from '../../../Functions/API';
import { Button, Grid } from '@material-ui/core';

const Permissions = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const columns = [
    { field: 'id', title: 'Id' },
    { field: 'label', title: 'Label' },
  ];

  useEffect(() => {
    get('/permissions').then(setPermissions);
  }, []);

  const handleRowClick = (e, data) => {
    history.push(`/developer-tools/permissions/${data.id}`);
  };

  return (
    <>
      <MaterialTable title="Permissions" columns={columns} data={permissions} onRowClick={handleRowClick} />
      <Grid container justify="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" href="/developer-tools/permissions/new">
            New
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Permissions;
