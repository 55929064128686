/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Production from './workorder';
import Calendar from './calendar';
import CheckIns from './check-ins';
import { get, post } from '../../Functions/API';
import Summary from './workorder/summary';

function ProductionRouter({ user }) {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/production/check-ins" component={(props) => <CheckIns {...props} get={get} post={post} user={user} />} />
          <Route exact path="/production/calendar" component={(props) => <Calendar {...props} get={get} post={post} user={user} />} />
          <Route
            exact
            path="/production/workorder/:WorkOrderId/summary"
            component={(props) => <Summary WorkOrderId={parseInt(props.match.params.WorkOrderId)} />}
          />
          <Route path="/production/workorder/:WorkOrderId" component={(props) => <Production {...props} get={get} post={post} user={user} />} />
        </Switch>
      </Router>
    </div>
  );
}

ProductionRouter.propTypes = {
  user: PropTypes.object,
};

export default ProductionRouter;
