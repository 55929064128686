export const save = (name, data, ttl) => {
  if (!ttl) {
    //12 hours
    ttl = 12 * 60 * 60 * 1000;
  }
  const date = new Date();
  const timeOut = date.getTime() + ttl;
  localStorage.setItem(name, JSON.stringify({ data, timeOut, created: date.getTime() }));
};

export const retrieve = (name) => {
  try {
    const strJSON = localStorage.getItem(name);
    if (strJSON) {
      const { data, timeOut } = JSON.parse(strJSON);
      const date = new Date();
      if (date.getTime() < timeOut) {
        return data;
      } else {
        localStorage.removeItem(name);
        return null;
      }
    }
  } catch (err) {
    console.error('Issue accessing local storage');
  }
};

export const getCreatedTime = (name) => {
  const strJSON = localStorage.getItem(name);
  if (strJSON) {
    const { created } = JSON.parse(strJSON);
    return new Date(created);
  }
  return null;
};
