import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../../../../../../Functions/API';
import { Card, CardContent, CardHeader, Grid, TextField, withStyles, makeStyles, Checkbox, FormControlLabel, FormControl } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const StyledCardHeader = withStyles({
  title: {
    fontSize: '2.5rem',
  },
})(CardHeader);

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      fontSize: '1.5rem',
    },
    '& .MuiInputBase-input': { fontSize: '2rem' },
  },
}));

const Header = ({ workOrder, product }) => {
  const classes = useStyles();

  const { customer, description, dueDate, shipMethod, shippingStreet, shippingCity, shippingState, shippingZip } = workOrder;
  const itemStyle = { width: '100%' };
  return (
    <div style={{ padding: '25px' }}>
      {workOrder.id && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <StyledCardHeader title={`${workOrder.workOrderID} - ${customer}`} />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker className={classes.root} style={itemStyle} label="Due Date" value={dueDate} />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.root}
                          InputLabelProps={{ shrink: true }}
                          style={itemStyle}
                          label={'Ship Method'}
                          value={shipMethod}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.root}
                          InputLabelProps={{ shrink: true }}
                          style={itemStyle}
                          label={'Type'}
                          value={product.type}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.root}
                          InputLabelProps={{ shrink: true }}
                          style={itemStyle}
                          label={'Color'}
                          value={product.color}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems={'flex-end'} justify={'flex-end'}>
                          <Grid item style={{ textAlign: 'right', fontSize: '1.5rem' }}>
                            Fold and Poly Bag
                          </Grid>
                          <Grid item style={{ textAlign: 'left' }}>
                            <FormControlLabel
                              control={<Checkbox style={itemStyle} checked={product.foldAndPolyBag} value={product.foldAndPolyBag} />}
                              labelPlacement={'top'}
                            />
                          </Grid>
                        </Grid>
                        {/*<FormControl>*/}
                        {/*  <FormControlLabel*/}
                        {/*    control={<Checkbox style={itemStyle} checked={product.foldAndPolyBag} value={product.foldAndPolyBag} />}*/}
                        {/*    labelPlacement={'start'}*/}
                        {/*    label={'Fold And Poly Bag'}*/}
                        {/*  />*/}
                        {/*</FormControl>*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Header;

Header.propTypes = {
  workOrder: PropTypes.object,
  product: PropTypes.object,
};
