/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Customer from '../../../Components/Fulfillment/Customers/Customer';
import CustomerAutocomplete from '../../../Components/Fulfillment/Customers/CustomerAutocomplete';
import { createDialog } from '../../../Stores/Dialog/Actions';
import { loadCustomers, updateCustomerFulfillment } from '../../../Stores/Fulfillment/Customers/Actions';
import { createSnack } from '../../../Stores/Snack/Actions';
import { AppBar, Toolbar } from '@material-ui/core';
import CustomersStore from '../../../Stores/Fulfillment/Customers/Store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

const customerDialog = () => {
  let customer = null;
  createDialog({
    content: (
      <CustomerAutocomplete
        callback={(newVal) => {
          customer = newVal;
        }}
      />
    ),
    title: 'Select new fulfillment customer',
    actions: [
      { title: 'Cancel', color: 'secondary', callback: () => {} },
      {
        title: 'Add',
        color: 'primary',
        callback: () => {
          console.log('New Customer:', customer);
          if (customer) {
            updateCustomerFulfillment(customer);
          } else {
            createSnack('Error', 'You need to select a customer first');
          }
        },
      },
    ],
    size: 'xl',
    disableBackdropClick: true,
  });
};

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const classes = useStyles();

  const getCustomers = () => {
    setCustomers([...CustomersStore.getCustomers()]);
  };

  useEffect(() => {
    CustomersStore.on('change', getCustomers);
    if (CustomersStore.getCustomers().length) {
      getCustomers();
    } else {
      loadCustomers();
    }
    return () => {
      CustomersStore.removeListener('change', getCustomers);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="center" alignItems="center">
        {customers.map((c, key) => (
          <Customer key={key} customer={c} />
        ))}
      </Grid>
      <AppBar position={'fixed'} style={{ top: 'auto', bottom: 0, backgroundColor: '#eaeaea' }}>
        <Toolbar>
          <Grid container justify={'flex-end'}>
            <Grid item>
              <Button size={'small'} onClick={customerDialog}>
                Add Customer
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Customers;
