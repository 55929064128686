/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */
/* eslint no-alert:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { ExpansionPanelDetails, Button, Grid, IconButton, Badge } from '@material-ui/core';
import { Add, ArrowForward, ArrowBack, Cancel } from '@material-ui/icons';
import UserStore from '../../Stores/User/Store';

const Sizes = ({ get, post, groupId, product, removeSize, addSize, num }) => {
  const [sizes, setSizes] = useState([]);
  const [refresh, setRefresh] = useState([]);
  const user = UserStore.getUser();

  useEffect(() => {
    get('/potentialSizesByGroupId', { groupId }, 'obj').then((sizes) => {
      setSizes(sizes);
    });
  }, [refresh, groupId]);

  const addPotentialSize = () => {
    const size = window.prompt('What is the name of the new size?');
    if (size) {
      post('/newPotentialSize', { size, groupId }).then(() => {
        setRefresh(refresh + 1);
      });
    }
  };

  const moveSizeDown = async (size) => {
    let stateSizes = JSON.stringify(sizes);
    stateSizes = JSON.parse(stateSizes);
    const replace = stateSizes.find((row) => row.sort === size.sort - 1);
    if (replace) {
      const replaceIndex = stateSizes.indexOf(replace);
      const index = sizes.indexOf(size);
      replace.sort += 1;
      size.sort -= 1;
      await post('/updateSizeSort', { size }).then((results) => {
        stateSizes[index] = results;
      });
      await post('/updateSizeSort', { size: replace }).then((results) => {
        stateSizes[replaceIndex] = results;
      });
      setSizes(stateSizes);
    }
  };

  const moveSizeUp = async (size) => {
    let stateSizes = JSON.stringify(sizes);
    stateSizes = JSON.parse(stateSizes);
    const replace = stateSizes.find((row) => row.sort === size.sort + 1);
    if (replace) {
      const replaceIndex = stateSizes.indexOf(replace);
      const index = sizes.indexOf(size);
      replace.sort -= 1;
      size.sort += 1;
      await post('/updateSizeSort', { size }).then((results) => {
        stateSizes[index] = results;
      });
      await post('/updateSizeSort', { size: replace }).then((results) => {
        stateSizes[replaceIndex] = results;
      });
      setSizes(stateSizes);
    }
  };

  const deleteSize = async (size) => {
    const c = window.confirm('Are you sure you want to delete this size?');
    if (c) {
      let stateSizes = JSON.stringify(sizes);
      stateSizes = JSON.parse(stateSizes);
      const index = sizes.indexOf(size);
      post('/deletePotentialSize', { size }).then(() => {
        stateSizes.splice(index, 1);
        setSizes(stateSizes);
      });
    }
  };

  return (
    <ExpansionPanelDetails>
      <div style={{ width: '100%' }}>
        <Grid container justify="flex-start" spacing={2}>
          {sizes
            .sort((a, b) => a.sort - b.sort)
            .map((size, key) => (
              <Grid key={key} item style={{ textAlign: 'center' }}>
                <div>
                  {/* TODO deleteSizeButton */}
                  <Badge
                    badgeContent={
                      user.group === 'Admin' && (
                        <IconButton size="small" style={{ top: '-2px', right: '-2px' }} onClick={() => deleteSize(size)}>
                          <Cancel fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <Button
                      size="small"
                      variant="contained"
                      disabled={
                        product.sizes.find((row) => row.size === size.size) &&
                        (product.sizes.find((row) => row.size === size.size).countNum > 0 ||
                          product.sizes.find((row) => row.size === size.size).packageNum > 0)
                      }
                      onClick={
                        product.sizes.find((row) => row.size === size.size)
                          ? (e) =>
                              removeSize(
                                e,
                                num,
                                product.sizes.find((row) => row.size === size.size),
                              )
                          : (e) => addSize(e, num, size.size)
                      }
                      color={product.sizes.find((row) => row.size === size.size) && 'primary'}
                    >
                      {size.size}
                    </Button>
                  </Badge>
                </div>
                <div>
                  <IconButton size="small" onClick={() => moveSizeDown(size)}>
                    <ArrowBack fontSize="small" />
                  </IconButton>
                  <IconButton size="small">
                    <ArrowForward fontSize="small" onClick={() => moveSizeUp(size)} />
                  </IconButton>
                </div>
              </Grid>
            ))}
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            {/* TODO addSizeButton */}
            <IconButton disabled={user.group !== 'Admin'} size="small" color="secondary" onClick={addPotentialSize}>
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </ExpansionPanelDetails>
  );
};

export default Sizes;
