import React, { useEffect, useState } from 'react';
import { get, post } from '../../../Functions/API.js';
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import { closeDialog, createDialog } from '../../../Stores/Dialog/Actions.js';
import Papa from 'papaparse';
import UploadDialog from '../../../Components/ShippingUploads/UploadDialog.js';
import { Search } from '@material-ui/icons';
import { Link, Route, Switch } from 'react-router-dom';
import ShippingUpload from '../shipping-upload/index.js';
import { Breadcrumbs } from '@mui/material';

const ShippingUploads = () => {
  const [shippingUploads, setShippingUploads] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadShippingUploads(page, pageSize, search);
  }, [page, pageSize, search]);

  const loadShippingUploads = (page, pageSize, search) => {
    const params = { page, pageSize };
    if (search) params.search = search;
    get('/shippingUploads', params).then(({ results, total }) => {
      setShippingUploads(results);
      setTotal(total);
    });
  };

  const upload = (data, other) => {
    const [file] = data;
    const { company } = other;
    switch (company) {
      case 'Ecom Parcel': {
        Papa.parse(file.file, {
          header: true,
          download: true,
          skipEmptyLines: true,
          complete: (results) => parseEcomBillData(results, other),
        });
        break;
      }
      case 'UPS': {
        Papa.parse(file.file, {
          header: false,
          download: true,
          skipEmptyLines: true,
          complete: (results) => parseUPSBillData(results, other),
        });
        break;
      }
      default: {
        console.log('Not correct company');
      }
    }
  };

  const getVendor = (company) => {
    if (company === 'Ecom Parcel') return 710;
    if (company === 'UPS') return 509;
    if (company === 'Mail Innovations') return 510;
  };

  const parseEcomBillData = ({ data }, { company, name }) => {
    const vendorId = getVendor(company);
    const body = { name, type: company, invoiceNumber: data[0]['INVOICE #'], costs: [] };
    data.map((d) => {
      body.costs.push({
        invoiceNumber: d['INVOICE #'],
        invoiceDate: d['INVOICE DATE'],
        totalActualCost: d['SHIPMENT TOTAL'],
        trackingNumber: d['AIRBILL #'],
        vendorId,
        type: 'shipping',
        notes: 'Shipping',
        shippingDetail: {
          body: JSON.stringify(d),
          referenceOne: d['SHIPPER REFERENCE'],
          referenceTwo: d['CONSIGNEE NAME'],
        },
      });
    });
    post('/shippingUploads', body).then((results) => {
      closeDialog();
      loadShippingUploads(page, pageSize);
    });
  };

  const parseUPSBillData = ({ data }, { company, name }) => {
    const vendorId = getVendor(company);
    const body = { name, type: company, invoiceNumber: data[0][5], costs: [] };
    data.map((d) => {
      const costExists = body.costs.find((c) => c.trackingNumber === d[13]);
      if (costExists) {
        costExists.totalActualCost = parseFloat((parseFloat(d[52]) + parseFloat(costExists.totalActualCost)).toFixed(2));
      } else {
        body.costs.push({
          invoiceNumber: d[5],
          invoiceDate: d[4],
          totalActualCost: parseFloat(d[52]),
          trackingNumber: d[13] === '' ? 'None' : d[13],
          vendorId,
          type: 'shipping',
          notes: 'Shipping',
          shippingDetail: {
            body: JSON.stringify(d),
            referenceOne: d[15],
            referenceTwo: d[16],
          },
        });
      }
    });
    post('/shippingUploads', body).then((results) => {
      closeDialog();
      loadShippingUploads(page, pageSize);
    });
  };

  return (
    <div style={{ margin: '10px' }}>
      <Switch>
        <Route path="/accounting/shipping-uploads/:id" component={(props) => <ShippingUpload {...props} />} />
        <Route path="/accounting/shipping-uploads">
          <Breadcrumbs>
            <MuiLink underline={'hover'} href={'/accounting'}>
              Accounting Home
            </MuiLink>
            <Typography color="text.primary">Shipping Uploads</Typography>
          </Breadcrumbs>
          <AppBar position={'sticky'} style={{ backgroundColor: '#eaeaea' }}>
            <Toolbar>
              <Grid container alignItems={'center'}>
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography variant={'h5'} style={{ color: 'black' }}>
                    Shipping Uploads
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    placeholder={'Search'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={1} style={{ marginTop: '10px', paddingBottom: '60px' }}>
            {shippingUploads.map((up, key) => (
              <Grid item key={key}>
                <Card>
                  <CardHeader title={up.name} subheader={up.type} />
                  <CardContent>
                    <List dense>
                      <ListItem>
                        <ListItemText primary={up.invoiceNumber} secondary={'Invoice Number'} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={new Date(up.createdAt)?.toLocaleDateString()} secondary={'Created At'} />
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button size={'small'} color={'primary'} component={Link} to={`/accounting/shipping-uploads/${up.id}`}>
                      View Upload
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <AppBar style={{ position: 'fixed', bottom: 0, top: 'auto', backgroundColor: '#eaeaea' }}>
            <Toolbar>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    size={'small'}
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() =>
                      createDialog({
                        title: 'Upload New Shipping Bill',
                        content: <UploadDialog upload={upload} />,
                        size: 'xl',
                      })
                    }
                  >
                    New Upload
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    count={total}
                    onChangePage={(e, p) => setPage(p)}
                    page={page}
                    rowsPerPage={pageSize}
                    onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Route>
      </Switch>
    </div>
  );
};

export default ShippingUploads;
