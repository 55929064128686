import React from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';

const DeveloperTabs = () => {
  const location = useLocation();

  return (
    <Paper square>
      <Tabs value={location.pathname}>
        <Tab label="Home" value="/developer-tools" component={Link} to="/developer-tools" />
        <Tab label="Users" value="/developer-tools/users" component={Link} to="/developer-tools/users" />
        <Tab label="Groups" value="/developer-tools/groups" component={Link} to="/developer-tools/groups" />
        <Tab label="Permissions" value="/developer-tools/permissions" component={Link} to="/developer-tools/permissions" />
        <Tab label="Jobs" value="/developer-tools/jobs" component={Link} to="/developer-tools/jobs" />
        <Tab label="PubSubs" value="/developer-tools/pub-subs" component={Link} to="/developer-tools/pub-subs" />
      </Tabs>
    </Paper>
  );
};

export default DeveloperTabs;
