import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { get } from '../../../../../../../Functions/API';
import MaterialTable, { MTableCell, MTableHeader } from 'material-table';
import Sizes from '../Sizes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      fontSize: '1.3rem',
    },
  },
  table: {
    minWidth: 650,
    border: '3px solid black',
  },
  cell: {
    '& .MuiTableCell-root': {
      color: 'red',
    },
  },
}));

const Art = ({ product, workOrderArt }) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '25px' }}>
      <Grid container spacing={1}>
        {product.chips.map((a, k) => {
          const art = workOrderArt.find((w) => w.id === a.artID);
          if (art) {
            return (
              <Grid item xs={12} key={k}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <div style={{ marginBottom: '8px' }}>
                          <span style={{ fontSize: '2rem' }}>{art.position}</span>
                        </div>
                        <div>
                          <img src={art.url} style={{ maxWidth: '100%' }} />
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item>
                                <TextField className={classes.root} InputLabelProps={{ shrink: true }} label={'Name'} value={art.name} />
                              </Grid>
                              <Grid item>
                                <TextField className={classes.root} InputLabelProps={{ shrink: true }} label={'Print Type'} value={art.printType} />
                              </Grid>
                              <Grid item style={{ flexGrow: 1 }}>
                                <TextField className={classes.root} InputLabelProps={{ shrink: true }} label={'Ink Color'} value={art.color} />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField style={{ width: '100%' }} className={classes.root} InputLabelProps={{ shrink: true }} label={'Printer'} />
                              </Grid>
                            </Grid>
                            <br />
                            <Sizes sizes={product.sizes} />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default Art;

Art.propTypes = {
  product: PropTypes.object,
  workOrderArt: PropTypes.array,
};
