import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, NativeSelect, Input } from '@material-ui/core';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const ShipMethod = ({ workOrder, editable }) => {
  const [shipMethods, setShipMethods] = useState([
    { name: 'Will Call', value: 'willCall' },
    { name: 'Drop Ship', value: 'dropShip' },
    { name: 'Ground', value: 'ground' },
    { name: 'Next Day Air', value: 'nextDayAir' },
    { name: '2 Day Air', value: '2DayAir' },
    { name: '3 Day Air', value: '3DayAir' },
    { name: 'J&R Shipping', value: 'J&RShipping' },
    { name: 'Split Ship', value: 'splitShip' },
    { name: '3rd Party', value: '3rdParty' },
    { name: 'USPS Priority', value: 'uspsPriority' },
    { name: 'Inventory', value: 'inventory' },
  ]);
  return (
    <FormControl style={{ margin: '10px', width: '100%' }} component="div">
      <InputLabel shrink htmlFor="shipMethod">
        Ship Method
      </InputLabel>
      <NativeSelect
        value={workOrder.shipMethod ? workOrder.shipMethod : ''}
        disabled={!editable}
        onChange={(e) => updateWorkOrder(workOrder.id, { shipMethod: e.target.value })}
        input={<Input name="shipMethod" id="shipMethod" />}
      >
        <option>None</option>
        {shipMethods.map((method, key) => (
          <option key={key} value={method.value}>
            {method.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default ShipMethod;

ShipMethod.propTypes = {
  workOrder: PropTypes.object,
  editable: PropTypes.bool,
};
