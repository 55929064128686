/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { get, patch } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import { Done, Search, Send } from '@material-ui/icons';
import { FormControl, InputLabel, Input, Grid, Typography, InputAdornment, IconButton, AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Receiving from '../../../Components/WorkOrder/Footer/Components/Production/Receiving';
import * as NotificationsActions from '../../../Stores/Notifications/Actions';
import { createDialog, closeDialog } from '../../../Stores/Dialog/Actions';

const useStyles = makeStyles({
  errMessage: {
    color: 'red',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white',
    paddingBottom: '12px',
    paddingTop: '10px',
  },
  trackingNumberFormControl: {
    marginTop: 10,
    marginBottom: 25,
  },
  history: {
    textDecoration: 'underline',
    textAlign: 'center',
    fontSize: 'large',
  },
});

const CheckIns = ({ user }) => {
  const [localHistory, setLocalHistory] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState([]);
  const [workOrderNumber, setWorkOrderNumber] = useState([]);
  const classes = useStyles();

  const checkIn = () => {
    patch(`/inbound-shipments/packages/${trackingNumber}`, {
      received: 1,
    })
      .then(() => {
        setLocalHistory([{ message: `Successfully checked in ${trackingNumber}`, type: 'success', time: Date.now() }, ...localHistory]);
        setTrackingNumber('');
      })
      .catch(handleUnrecognizedTrackingNumber);
  };

  const focusOnTrackingNumberSearch = () => {
    document.getElementById('tracking-number').focus();
  };

  const handleWorkOrderSearchAndSalesNotification = (searchValue) => {
    const filter = {
      eager: {
        $where: {
          workOrderID: searchValue.trim(),
        },
      },
    };
    get(`/workorder`, { filter })
      .then((res) => {
        const { results } = res;
        const [workOrder] = results;
        console.log(workOrder);
        if (workOrder) {
          NotificationsActions.addNotification(
            'Sales',
            `Package with trackingNumber: ${trackingNumber} was checked in, but wasn't assigned to this Work Order`,
            null,
            user,
            workOrder,
          );
          setLocalHistory([
            { message: `${trackingNumber} => Sales notification sent to WorkOrder: ${searchValue}`, type: 'success', time: Date.now() },
            ...localHistory,
          ]);
          createSnack('Success', 'Notified Sales Assistants!');
          closeDialog();
          focusOnTrackingNumberSearch();
        } else {
          setLocalHistory([
            { message: `${trackingNumber} => Work Order: ${searchValue} not found, could not send notification!`, type: 'error', time: Date.now() },
            ...localHistory,
          ]);
          createSnack('Error', 'Work Order not found! Try another, or close.');
        }
      })
      .catch((err) => {
        createSnack('Error', 'Failed to get Work Order! Check logs for details');
      })
      .finally(() => {
        setTrackingNumber('');
      });
  };

  const handleUnrecognizedTrackingNumber = (err) => {
    if (err.message.indexOf('has already been checked in') >= 0) return;
    let searchValue = null;

    const content = (
      <Grid container spacing={2} direction={'column'}>
        <Grid>
          <span className={classes.errMessage}>Tracking number: {trackingNumber} was not found in system please please assign it to a WorkOrder</span>
        </Grid>
        <Grid>
          <FormControl style={{ width: '100%' }}>
            <InputLabel>Work Order Number</InputLabel>
            <Input
              id="work-order-notification-search"
              value={searchValue}
              autofocus
              onKeyDown={(event) => {
                // keyCode 13 is "enter"
                if (event.keyCode === 13) {
                  handleWorkOrderSearchAndSalesNotification(searchValue);
                } else {
                  return;
                }
              }}
              onChange={(e) => {
                searchValue = e.target.value;
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="wO-search"
                    onClick={() => {
                      handleWorkOrderSearchAndSalesNotification(searchValue);
                    }}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    );

    createDialog({
      content,
      title: 'Send Work Order Notification',
      actions: [
        {
          title: 'Cancel',
          color: 'secondary',
          callback: () => {
            setLocalHistory([
              {
                message: `${trackingNumber} not found in system!`,
                type: 'error',
                time: Date.now(),
              },
              ...localHistory,
            ]);
            closeDialog();
            setTrackingNumber('');
          },
        },
      ],
      size: 'lg',
      disableBackdropClick: true,
    });
    document.getElementById('work-order-notification-search').focus();
  };

  const asyncCloseDialog = async () => {
    closeDialog();
  };

  const closeReceiving = () => {
    asyncCloseDialog().then(() => {
      setWorkOrderNumber('');
      setTrackingNumber('');
      focusOnTrackingNumberSearch();
    });
  };

  const handleWorkOrderSearch = () => {
    const filter = {
      eager: {
        $where: {
          workOrderID: workOrderNumber,
        },
      },
    };
    try {
      get(`/workorder`, { filter }).then((res) => {
        const { results } = res;
        const [workOrder] = results;
        if (workOrder) {
          createDialog({
            content: <Receiving workOrder={workOrder} />,
            title: `Work Order #: ${workOrder.workOrderID} -- ${workOrder.customerName}`,
            actions: [
              {
                title: 'Close',
                color: 'secondary',
                callback: closeReceiving,
              },
            ],
            size: 'lg',
            disableBackdropClick: true,
          });
        } else {
          createSnack('Error', 'Work Order not found!');
        }
      });
    } catch (err) {
      console.error(err);
      createSnack('Error', err ? err.message : 'Work Order not found!');
    }
  };

  const submitTrackingNumberOnEnter = (event) => {
    // 13 = "enter" and 32 = "space"
    if (event.keyCode && event.keyCode !== 13 && event.keyCode !== 32) return;
    checkIn();
  };

  return (
    <React.Fragment>
      <title>Check Ins</title>
      <Grid container justify="center" alignContent="center" style={{ padding: '10px' }}>
        <Grid xs={12} item>
          <FormControl fullWidth className={classes.trackingNumberFormControl}>
            <InputLabel>Tracking Number</InputLabel>
            <Input
              fullWidth
              autoFocus
              id="tracking-number"
              value={trackingNumber}
              onKeyDown={submitTrackingNumberOnEnter}
              onChange={(e) => setTrackingNumber(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="check-in-package" onClick={checkIn}>
                    <Done />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.history}>History</div>
        </Grid>
      </Grid>
      {Boolean(localHistory?.length) &&
        localHistory.map((historyEntry, index) => (
          <Grid container key={index} spacing={1} justify="center" alignContent="center">
            <Grid xs={11} item>
              <Typography style={{ color: `${historyEntry.type === 'success' ? 'green' : 'red'}`, textAlign: 'center' }}>
                {new Date(historyEntry.time).toUTCString()} - {historyEntry.message}
              </Typography>
            </Grid>
          </Grid>
        ))}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <FormControl fullWidth={true} style={{ alignContent: 'center' }}>
            <InputLabel>Work Order</InputLabel>
            <Input
              id="work-order-search"
              value={workOrderNumber}
              onKeyDown={(event) => {
                // keyCode 13 is "enter"
                if (event.keyCode === 13) {
                  handleWorkOrderSearch();
                } else {
                  return;
                }
              }}
              onChange={(e) => setWorkOrderNumber(e.target.value)}
            />
          </FormControl>
          <IconButton edge="end" onClick={handleWorkOrderSearch}>
            <Search />
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

CheckIns.displayName = 'CheckIns';

export default CheckIns;
