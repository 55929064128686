/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import { patch, post, remove } from '../../../Functions/API';
import { createSnack } from '../../../Stores/Snack/Actions';
import PropTypes from 'prop-types';
import { Save, Delete, CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertDateToSQLDate } from '../../Utils/SqlDate';

const InboundPackage = ({ costingID, WorkOrderId, shipPackage, couriers, getInboundPackages, viewOnly }) => {
  const defaultCourier = {};
  const [activeCourier, setActiveCourier] = useState(defaultCourier);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [expectedDate, setExpectedDate] = useState(shipPackage.expectedDelivery);

  const checkInPackage = async () => {
    await patch(`/inbound-shipments/packages/${shipPackage?.trackingNumber}`, {
      received: true,
    });
    getInboundPackages();
  };

  const checkOutPackage = async () => {
    await patch(`/inbound-shipments/packages/${shipPackage?.trackingNumber}`, {
      received: false,
    });
    getInboundPackages();
  };

  useEffect(() => {
    if (shipPackage && couriers?.length) {
      const courier = couriers.find((cour) => cour.id === shipPackage.courierID);

      setActiveCourier(courier);
      setTrackingNumber(shipPackage.trackingNumber);
    }
  }, []);

  // useEffect(() => {
  //   if (shipPackage && activeCourier !== defaultCourier && trackingNumber !== '') {
  //     setViewOnly(true);
  //   }
  // }, [activeCourier, trackingNumber]);

  const handleChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const createPackage = async () => {
    try {
      await post(`/workorder/${WorkOrderId}/costing/${costingID}/inbound-packages`, {
        trackingNumber,
        courierID: activeCourier?.id,
        courierSlug: activeCourier?.slug,
        expectedDelivery: convertDateToSQLDate(expectedDate),
      });
      setActiveCourier({});
      setTrackingNumber('');
      setExpectedDate(shipPackage.expectedDelivery);
      getInboundPackages();
    } catch (err) {
      createSnack('Error', err.message);
    }
  };

  const deletePackage = async () => {
    try {
      await remove(`/workorder/${WorkOrderId}/costing/${costingID}/inbound-packages/${shipPackage.id}`);
      getInboundPackages();
    } catch (err) {
      createSnack('Error', err.message);
    }
  };

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Grid item xs={11} sm={shipPackage ? 2 : 3}>
        <Autocomplete
          disabled={viewOnly}
          options={couriers}
          getOptionLabel={(option, value) => {
            if (option?.name) return option.name;
            return '';
          }}
          style={{ width: '100%' }}
          value={activeCourier}
          onChange={(event, value) => setActiveCourier(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Courier"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
              margin="normal"
              component="div"
            />
          )}
        />
      </Grid>
      <Grid item xs={11} sm={viewOnly ? 3 : 5}>
        <TextField
          disabled={viewOnly}
          label={viewOnly ? 'Tracking Number' : 'Tracking Number(s)'}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '100%' }}
          placeholder="single or multiple separated by comma"
          value={trackingNumber}
          onChange={handleChange}
          margin="normal"
          component="div"
        />
      </Grid>
      {Boolean(viewOnly) && (
        <Grid item xs={11} sm={3}>
          <TextField
            disabled={viewOnly}
            label="Status"
            InputLabelProps={{
              shrink: true,
            }}
            value={shipPackage.name}
            margin="normal"
            component="div"
          />
        </Grid>
      )}
      <Grid item xs={11} sm={2}>
        {shipPackage.expectedDelivery ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              style={{ margin: '8px', width: '100%' }}
              disabled={viewOnly}
              value={expectedDate}
              onChange={setExpectedDate}
              label={'Expected Delivery'}
            />
          </MuiPickersUtilsProvider>
        ) : (
          <TextField
            disabled={viewOnly}
            label="Expected Delivery"
            InputLabelProps={{
              shrink: true,
            }}
            value={shipPackage.expectedDelivery ? shipPackage.expectedDelivery : 'Unknown'}
            margin="normal"
            style={{ margin: '8px', width: '100%' }}
            component="div"
          />
        )}
      </Grid>
      <Grid item>
        {viewOnly ? (
          <>
            {shipPackage?.received ? (
              <IconButton color="primary" onClick={checkOutPackage}>
                <CheckBox />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={checkInPackage}>
                <CheckBoxOutlineBlank />
              </IconButton>
            )}
            <IconButton color="secondary" onClick={deletePackage}>
              <Delete />
            </IconButton>
          </>
        ) : (
          <IconButton color="primary" onClick={createPackage}>
            <Save />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

InboundPackage.propTypes = {
  costingID: PropTypes.number,
  WorkOrderId: PropTypes.string,
  shipPackage: PropTypes.object,
  couriers: PropTypes.array,
  getInboundPackages: PropTypes.func,
};

InboundPackage.displayName = 'InboundPackage';

export default InboundPackage;
