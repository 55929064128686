/* eslint react/no-unused-prop-types:0 */
/* eslint react/forbid-prop-types:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-alert:0 */
/* eslint no-shadow:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { NativeSelect, Grid, FormControl, InputLabel, Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default class QuoteArt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Price List',
          field: 'type',
          editComponent: (t) => (
            <Select value={t.value} onChange={(e) => t.onChange(e.target.value)}>
              {this.state.priceLists.map((priceList, key) => (
                <MenuItem key={key} value={priceList.type}>
                  {priceList.type}
                </MenuItem>
              ))}
            </Select>
          ),
        },
        {
          title: 'Print Type',
          field: 'printType',
          editComponent: (t) => (
            <Select value={t.value} onChange={(e) => t.onChange(e.target.value)}>
              {this.state.printOptions
                .filter((row) => row.type === t.rowData.type)
                .map((priceList, key) => {
                  return (
                    <MenuItem key={key} value={priceList.printType}>
                      {priceList.printType}
                    </MenuItem>
                  );
                })}
            </Select>
          ),
        },
        { title: 'Position', field: 'position' },
      ],
      priceLists: [],
      printOptions: [],
      art: [],
    };
  }

  componentDidMount() {
    this.props.get('/getPrintOptions', []).then((results) => {
      const { priceLists, printOptions } = results;
      let art = JSON.stringify(this.props.art);
      art = JSON.parse(art);
      this.setState({
        priceLists,
        printOptions,
        art,
      });
    });
  }

  render() {
    const { columns, art } = this.state;
    const { updateArt, addArt, deleteArt, row, product } = this.props;
    console.log(product.art);
    return (
      <div
        style={{
          position: 'absolute',
          width: '90%',
          backgroundColor: '#ffffff',
          padding: '15px',
          outline: 'none',
          height: '75%',
          overflowX: 'auto',
        }}
      >
        <MaterialTable
          title="Edit Art"
          columns={columns}
          data={art}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              this.props
                .post('/updateQuoteArt', { newData, product })
                .then((results) => {
                  updateArt(row, product, oldData, newData);
                  const { art } = this.state;
                  const index = art.indexOf(oldData);
                  art[index] = newData;
                  this.setState(
                    {
                      art,
                    },
                    () => console.log('state update'),
                  );
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
            onRowAdd: async (newData) => {
              this.props
                .post('/addQuoteArt', { newData, product })
                .then(async (results) => {
                  await addArt(row, product, results);
                  const { art } = this.state;
                  art.push(newData);
                  this.setState({
                    art,
                  });
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
            onRowDelete: async (art) => {
              this.props
                .post('/deleteQuoteArt', { art })
                .then(async (results) => {
                  await deleteArt(row, product, art);
                  const newArt = this.state.art;
                  const index = newArt.indexOf(art);
                  newArt.splice(index, 1);
                  this.setState({
                    art: newArt,
                  });
                })
                .catch((err) => {
                  window.alert(err.message);
                });
            },
          }}
          options={{
            search: false,
          }}
        />
      </div>
    );
  }
}

QuoteArt.propTypes = {
  art: PropTypes.array.isRequired,
  updateArt: PropTypes.func.isRequired,
  addArt: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
