/* eslint react/no-array-index-key:0 */
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint prefer-destructuring:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CreateStoreDialog from '../../../Components/Fulfillment/Stores/CreateStoreDialog';
import { createDialog } from '../../../Stores/Dialog/Actions';
import { createSnack } from '../../../Stores/Snack/Actions';
import { addStore } from '../../../Stores/Fulfillment/Stores/Actions';
import Index from './store';
import Store from '../../../Components/Fulfillment/Stores/Store';
import { get } from '../../../Functions/API';
import { AppBar, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '98.5%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  selectPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Stores = () => {
  const query = useQuery();
  const customerId = query.get('customerId');
  const classes = useStyles();
  const [stores, setStores] = useState();
  const [salesmen, setSalesmen] = useState([]);

  const storeDialog = (customerId) => {
    createDialog({
      content: <CreateStoreDialog customerId={customerId} salesmen={salesmen} getStores={getStores} />,
      title: 'Create new store',
      actions: null,
      size: 'xl',
      disableBackdropClick: true,
    });
  };

  const getStores = () => {
    const filter = customerId
      ? {
          eager: {
            $where: {
              customerId,
            },
          },
        }
      : {};
    get(`/integrations/order-desk/store`, { filter }, 'obj')
      .then((stores) => {
        setStores(stores);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const getSalesmen = () => {
    get('/user/group', { group: 'Sales' }, 'obj').then(setSalesmen);
  };

  useEffect(() => {
    getStores();
    getSalesmen();
  }, []);

  const StoresList = () => (
    <>
      {Boolean(stores.length) ? (
        <>
          {stores.map((s, key) => (
            <Store key={key} store={s} />
          ))}
        </>
      ) : (
        <>No stores found!</>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      <Switch>
        <Route path={`/fulfillment/stores/:id`}>
          <Index />
        </Route>
        <Route path={`/fulfillment/stores`}>
          <Grid style={{ marginTop: '20px' }} container justify="center">
            <Grid item xs={12}>
              <Grid container spacing={3} justify="center">
                {stores ? <StoresList /> : <h4>Loading....</h4>}
              </Grid>
            </Grid>
          </Grid>
        </Route>
      </Switch>
      <AppBar position={'fixed'} style={{ top: 'auto', bottom: 0, backgroundColor: '#eaeaea' }}>
        <Toolbar>
          <Grid container justify={'flex-end'}>
            <Grid item>
              <Button size={'small'} variant={'outlined'} onClick={() => storeDialog(customerId)}>
                Add Store
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Stores.displayName = 'Stores';

export default Stores;
