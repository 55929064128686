import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, LinearProgress, Switch, TextField } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { closeDialog } from '../../../../Stores/Dialog/Actions.js';
import { storage } from '../../../../fire.js';
import { createSnack } from '../../../../Stores/Snack/Actions.js';
import DragAndDrop from '../../../../Components/WorkOrder/Footer/Components/Generate/DragAndDrop.js';
import DropZone from '../../../../Components/WorkOrder/Footer/Components/Generate/DropZone.js';
import { post } from '../../../../Functions/API.js';

const AddArt = ({ customerId }) => {
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const [position, setPosition] = useState(null);
  const [color, setColor] = useState(null);
  const [medWeed, setMedWeed] = useState(false);
  const [hardWeed, setHardWeed] = useState(false);
  const [underlay, setUnderlay] = useState(false);
  const [progress, setProgress] = useState(0);

  const submit = async () => {
    const art = {
      name,
      url,
      position,
      color,
      medWeed,
      hardWeed,
      underlay,
      customerId,
    };
    await post('/workOrderArt', art);
    createSnack('Success', 'Successfully Added Art');
    closeDialog();
  };

  const dropIt = (files) => {
    const [file] = files;
    if (!file) return createSnack('Error', 'No File');
    const storageRef = storage.ref(`customer-art/${customerId}/${file.name}`);
    const task = storageRef.put(file);
    task.on(
      'state_changed',
      function progress(snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percentage);
        setProgress(percentage);
      }.bind(this),
      function error(err) {
        console.log(err);
        setProgress(0);
        createSnack('Error', err.message);
      },

      function complete() {
        task.snapshot.ref.getDownloadURL().then(setUrl);
        setProgress(0);
      }.bind(this),
    );
  };

  const close = () => {
    const c = confirm('Are you sure you want to quit adding this art?');
    if (c) closeDialog();
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField fullWidth label={'Name'} value={name} onChange={(e) => setName(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          {url ? (
            <Grid container justify={'center'} spacing={1}>
              <Grid item>
                <img style={{ maxHeight: '200px' }} src={url} alt={'Your mom'} />
              </Grid>
              <Grid item>
                <Button color={'secondary'} size={'small'} onClick={() => setUrl(null)}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div>
              <DropZone handleDrop={dropIt} />
              <LinearProgress value={progress} variant={'determinate'} />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth label={'Position'} value={position} onChange={(e) => setPosition(e.target.value)} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth label={'Color'} value={color} onChange={(e) => setColor(e.target.value)} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch checked={medWeed} value={medWeed} color="primary" onChange={(e) => setMedWeed(e.target.checked)} />}
            label="Med Weed"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch checked={hardWeed} value={hardWeed} color="primary" onChange={(e) => setHardWeed(e.target.checked)} />}
            label="Hard Weed"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch checked={underlay} value={underlay} color="primary" onChange={(e) => setUnderlay(e.target.checked)} />}
            label="Underlay"
          />
        </Grid>
      </Grid>
      <div style={{ paddingTop: '10px' }}>
        <Divider />
        <Grid container justifyContent={'flex-end'} alignItems={'flex-end'}>
          <Grid item>
            <Button onClick={submit}>Submit</Button>
          </Grid>
          <Grid item>
            <Button color={'secondary'} onClick={close}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AddArt.propTypes = {
  customerId: PropTypes.number,
};

export default AddArt;
