/* eslint no-alert:0 */
/* eslint no-shadow:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
import React, { forwardRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Delete, Edit } from '@material-ui/icons';
import {
  List,
  ListItemText,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  TextField,
  Grid,
  ListItemSecondaryAction,
  ListItem,
  IconButton,
} from '@material-ui/core';
import logo from '../../images/CSP-logo-only.png';

const ArtRequest = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        marginTop: '5%',
        backgroundColor: 'white',
        padding: '15px',
        outline: 'none',
      }}
    >
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container spacing={1}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h2>Art Request</h2>
        </Grid>
        <Grid item xs={2}>
          <img src={logo} alt="CSP Logo" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="description"
            type="text"
            multiline
            label="Comment"
            variant="filled"
            rows="5"
            value={props.artRequestComment}
            style={props.descriptionFieldStyle}
            onChange={props.updateComment}
          />
          <Button
            style={{ float: 'right', marginTop: '15px' }}
            color="primary"
            variant="contained"
            disabled={!props.artRequestComment}
            onClick={props.addComment}
          >
            Add Comment
          </Button>
          <List style={{ clear: 'both' }}>
            {props.comments.map((item, key) => (
              <React.Fragment key={key}>
                <ListItem>
                  <ListItemText primary={item.comment} secondary={`${item.time} - ${item.user}`} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => props.editComment(item)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => props.removeComment(item)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {props.comments.indexOf(item) !== props.comments.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container spacing={1}>
        <Grid item xs={12}>
          <h3>Products</h3>
        </Grid>
        {props.products.map((item, key) => (
          <Grid key={key} item>
            <Card
              style={{
                minWidth: '170px',
                position: 'relative',
                height: '300px',
                backgroundColor: 'rgba(0, 0, 0, 0.09)',
              }}
            >
              <CardHeader title={item.vendor || 'No Vendor'} subheader={item.styleNumber || 'No Style Number'} />
              <CardContent>
                <Grid container spacing={1} justify="center">
                  {item.images.map((image, key) => (
                    <Grid item key={key}>
                      <img src={image.url} style={{ width: '100px' }} />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <CardActions style={{ position: 'absolute', right: 0, bottom: 0, fontSize: '5px' }}>
                <Button variant="contained" color="primary" size="small" onClick={(event) => props.editArtRequestProduct(event, item, key)}>
                  View
                </Button>
                <Button variant="contained" size="small" onClick={(event) => props.deleteArtRequestProduct(event, item, key)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Grid item xl={12}>
          <Button variant="contained" color="primary" style={{ float: 'right', marginRight: '10px' }} onClick={props.newArtRequestProduct}>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container spacing={1}>
        <Grid item xs={12}>
          <h3>Art</h3>
        </Grid>
        {props.art.map((item, key) => (
          <Grid key={key} item>
            <Card
              style={{
                minWidth: '170px',
                position: 'relative',
                height: '300px',
                backgroundColor: 'rgba(0, 0, 0, 0.09)',
              }}
            >
              <CardHeader title={item.priceList || 'No Price List'} subheader={`${item.printType} ${item.position}`} />
              <CardContent>
                <Grid container spacing={1} justify="center">
                  {item.images.map((image, key) => (
                    <Grid item key={key}>
                      <img src={image.url} style={{ width: '100px' }} />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <CardActions style={{ position: 'absolute', right: 0, bottom: 0, fontSize: '5px' }}>
                <Button variant="contained" color="primary" size="small" onClick={(event) => props.editArtRequestArt(event, item, key)}>
                  View
                </Button>
                <Button variant="contained" size="small" onClick={(event) => props.deleteArtRequestArt(event, item, key)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Grid item xl={12}>
          <Button variant="contained" color="primary" style={{ float: 'right', marginRight: '10px' }} onClick={props.newArtRequestArt}>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid style={{ marginTop: '10px', marginBottom: '15px' }} container justify="flex-end" spacing={1}>
        <Grid item xs={3} lg={1}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%' }}
            disabled={Boolean(props.approved)}
            onClick={() => {
              if (props.artRequestComment) {
                const confirmation = window.confirm('Do you want to save your comment before submitting?');
                if (confirmation) {
                  props.addComment();
                }
              }
              props.submitArtRequest();
            }}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={3} lg={1}>
          <Button
            variant="contained"
            style={{ width: '100%' }}
            onClick={() => {
              if (props.artRequestComment) {
                const confirmation = window.confirm('Do you want to save your comment before exiting?');
                if (confirmation) {
                  props.addComment();
                }
              }
              props.toggleArtRequest();
            }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

ArtRequest.displayName = 'ArtRequestModal';

export default ArtRequest;
