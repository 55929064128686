import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { get } from '../../../../../Functions/API';
import { Link } from 'react-router-dom';

// todo: Figure out why all the vendors show the same line items from the route.

const SelectVendorPopup = ({ status, closeDialog, store }) => {
  const [stores, setStores] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(0);

  const date = new Date();
  date.setDate(date.getDate() + 14);

  useEffect(() => {
    getStepIds().then((stepIds) => {
      get('/fulfillment/vendors/openLineItems', { stepIds, storeId: store.id }).then((results) => {
        setVendors(results);
      });
    });
  }, [page]);

  const getStepIds = async () => {
    const steps = await get('/fulfillment/products/steps', { statusId: status.id });
    return steps.map((step) => step.id);
  };

  const getQuantities = (v) => {
    let count = 0;
    v.lineItems.forEach((item) => {
      count += item.quantity;
    });
    return count;
  };

  return (
    <div style={{ width: '400px' }}>
      {vendors.map((vendor, key) => (
        <div key={key}>
          <List dense>
            <ListItem
              button
              component={Link}
              style={{ color: 'black' }}
              onClick={() => {
                closeDialog();
              }}
              to={`/fulfillment/ordering?statusId=54&vendorId=${vendor.vendorId}&storeId=${store.id}`}
            >
              <Badge
                color={'primary'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={getQuantities(vendor)}
                showZero
              >
                <ListItemText style={{ paddingLeft: '15px' }} primary={vendor.name} />
              </Badge>
            </ListItem>
          </List>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default SelectVendorPopup;

SelectVendorPopup.propTypes = { status: PropTypes.object, store: PropTypes.object, statusFilter: PropTypes.number, closeDialog: PropTypes.func };
