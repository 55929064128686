import { get, post } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';
import { loadCustomers } from '../../Stores/Customers/Actions';
import { fetchVendors } from '../../Stores/Vendors/Actions';

export const openQuickBooksPopUp = (url) => {
  let parameters = 'location=1,width=800,height=650';
  parameters += `,left=${(window.screen.width - 800) / 2},top=${(window.screen.height - 650) / 2}`;
  window.open(url, 'connectPopup', parameters);
};

export const addCustomerToQB = async (customerID) => {
  get('/qbCreateCustomer', [{ name: 'customerID', value: customerID }])
    .then((results) => {
      const { url, message } = results;
      if (url) {
        openQuickBooksPopUp(url);
      } else if (message === 'success') {
        createSnack('Success', 'Customer Created in Quickbooks.');
        loadCustomers();
      } else {
        createSnack('Error', `Customer unable to be created: ${message}`);
      }
      return true;
    })
    .catch((err) => {
      createSnack('Error', err.message);
      return false;
    });
};

export const addVendorToQB = (vendorID) => {
  get('/qbCreateVendor', [{ name: 'vendorID', value: vendorID }])
    .then((results) => {
      const { url, message } = results;
      if (url) {
        openQuickBooksPopUp(url);
      } else if (message === 'success') {
        createSnack('Success', 'Vendor Created in Quickbooks.');
        fetchVendors();
      } else {
        createSnack('Error', 'Vendor unable to be created.');
      }
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};
