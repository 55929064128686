import React from 'react';
import { AppBar, Toolbar, Typography, Grid, Button } from '@material-ui/core';
import UserStore from '../../../Stores/User/Store';
import { logout } from '../../../Functions/Firebase';

const Menu = () => {
  const user = UserStore.getUser();

  return (
    <AppBar position="static" color={process.env.NODE_ENV === 'development' || process.env.REACT_APP_LOCATION === 'beta' ? 'secondary' : 'primary'}>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="h6" style={{ cursor: 'pointer' }} onClick={() => (window.location.href = '/')}>
              YBA Inventory Portal
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {user.firstName} {user.lastName}
            </Typography>
          </Grid>
          <Grid item>
            <Button href="" variant="contained" color="secondary" onClick={logout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Menu;
