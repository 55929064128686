/* eslint-disable react/display-name */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
/* eslint no-plusplus:0 */
/* eslint no-alert:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, Checkbox, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import DropZone from '../../../Components/WorkOrder/Footer/Components/Generate/DropZone';
import Menu from '../../../Components/Menu';

const Uploads = ({ get, post, user, match }) => {
  const [uploads, setUploads] = useState([]);
  const [tableId, setTableId] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const { tableId } = match.params;
    setTableId(tableId);
    get('/getExternalUploads', { tableId }, 'obj').then((results) => {
      setUploads(results);
      // let id;
      // if (results.filter((r) => r.readyForRankAdvancement).length) {
      //   id = setInterval(() => getUploads(id), 3000);
      //   console.log(id);
      // }
      // return () => {
      //   clearInterval(id);
      // };
    });
  }, [refresh]);

  // const getUploads = (id) => {
  //   get('/getExternalUploads', { tableId: match.params.tableId }, 'obj').then((results) => {
  //     setUploads(results);
  //     console.log(!results.filter((r) => r.readyForRankAdvancement).length);
  //     console.log(id);
  //     if (!results.filter((r) => r.readyForRankAdvancement).length) {
  //       console.log('clear!');
  //       clearInterval(id);
  //     }
  //   });
  // };

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Upload Date', field: 'uploadDate', type: 'date', editable: 'never' },
    {
      title: 'Progress',
      field: 'progress',
      render: (rowData) => {
        return (
          <Box position="relative">
            <LinearProgress variant="determinate" value={`${(rowData.done / rowData.total) * 100}`} />
            <Box top={20} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="caption" component="div" color="textSecondary">
                {`${rowData.done}/${rowData.total}`}
              </Typography>
            </Box>
          </Box>
        );
      },
      editable: 'never',
    },
    {
      title: 'Ready For Rank Advancement',
      field: 'readyForRankAdvancement',
      render: (rowData) => {
        return (
          <Checkbox
            checked={rowData.readyForRankAdvancement === 1}
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              updateReadyForRankAdvancement(rowData);
            }}
          />
        );
      },
    },
    // {
    //   title: 'Send Updates',
    //   render: (rowData) => {
    //     return (
    //       <Button
    //         color="primary"
    //         variant={'contained'}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           post(`/executeRankAdvancementWorkflow/${rowData.id}/${rowData.tableId}`).then((results) => {
    //             console.log(results);
    //           });
    //         }}
    //       >
    //         Send
    //       </Button>
    //     );
    //   },
    // },
  ];

  const updateReadyForRankAdvancement = (rowData) => {
    // post(`/executeRankAdvancementWorkflow/${rowData.id}/${rowData.tableId}`)
    post(`/updateReadyForRankAdvancement/${rowData.id}/${rowData.tableId}`)
      .then((results) => {
        setRefresh(refresh + 1);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleDrop = (files) => {
    for (const file of files) {
      console.log(file);
      // file.type = "text/csv"
      // only allow uploads of .csv files
      const lastFour = file.name.substr(file.name.length - 4);
      console.log(lastFour);
      if (file.type !== 'text/csv' && lastFour !== '.csv') {
        alert(`You are trying to upload a ${file.type}! \n Please only upload csv files!`);
      } else if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          let results = reader.result;
          const data = [];
          results = results.split('\n');
          const headers = results[0].split(',');
          console.log('headers', headers);
          for (let i = 1; i < results.length; i++) {
            // splits on a comma unless the comma has quotation marks on both sides of it or more specifically :
            /* will match:
                        ,               ','
                (?=             look ahead to see if there is:
                (?:             group, but do not capture (0 or more times):
                (?:             group, but do not capture (2 times):
                [^"]*          any character except: '"' (0 or more times)
                "              '"'
                ){2}            end of grouping
                )*              end of grouping
                [^"]*          any character except: '"' (0 or more times)
                $               before an optional \n, and the end of the string
                )               end of look-ahead

                see https://stackoverflow.com/questions/23582276/split-string-by-comma-but-ignore-commas-inside-quotes/23582323 for more details
            */
            const row = results[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            // let row = results[i].split(',');
            const obj = {};
            for (let j = 0; j < row.length; j++) {
              try {
                obj[headers[j].trim()] = row[j].trim();
              } catch (err) {
                console.error(err.message);
              }
            }
            data.push(obj);
          }
          const { name } = file;
          post('/newUpload', { data, tableId, name })
            .then((newUpload) => {
              setRefresh(refresh + 1);
            })
            .catch((err) => {
              console.error('ERROR', err.message);
              setRefresh(refresh + 1);
            });
        };
      }
    }
  };

  return (
    <div>
      <MaterialTable
        title="Uploads"
        columns={columns}
        data={uploads}
        options={{ paging: false }}
        components={{
          Toolbar: (props) => (
            <div>
              <Grid container spacing={2}>
                <Grid item style={{ margin: '25px' }}>
                  <h3>{props.title}</h3>
                </Grid>
                <Grid item style={{ flexGrow: 1, margin: '25px' }}>
                  {/* <Button variant={'contained'} color={'secondary'} */}
                  {/*        onClick={() => functions[tableId - 1]([{test: 1}])}>Upload</Button> */}
                  <DropZone handleDrop={handleDrop} id="dropzone-table" />
                </Grid>
              </Grid>
            </div>
          ),
        }}
        onRowClick={(event, rowData) => {
          window.open(`/external-data/${tableId}/${rowData.id}`);
        }}
      />
    </div>
  );
};

export default Uploads;
