import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Checkbox,
  Tooltip,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Switch,
  Table,
  Grid,
  ListItemText,
  Button,
  TableContainer,
} from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { RemoveCircleOutline } from '@material-ui/icons';
import { get, post } from '../../../../../Functions/API';

const PrintShopModal = ({ chips, workOrderID, load, close, num, setModal, fetchOrders, numOfLines, setNumOfLines, filters }) => {
  const [header, setHeader] = useState([
    { id: 'name', numeric: false, disablePadding: true, label: 'File' },
    { id: 'printGroup', numeric: false, disablePadding: true, label: 'Price List' },
    { id: 'printType', numeric: false, disablePadding: true, label: 'Print Type' },
    { id: 'numbers', numeric: false, disablePadding: true, label: 'Numbers' },
    { id: 'position', numeric: false, disablePadding: true, label: 'Position' },
    { id: 'color', numeric: false, disablePadding: true, label: 'Ink Color' },
    { id: 'reversible', numeric: true, disablePadding: true, label: 'Reversible' },
    { id: 'underlay', numeric: true, disablePadding: true, label: 'Underlay' },
  ]);
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    get(`/vendors/${load.vendorID}`)
      .then((res) => {
        setVendor(res ? res : null);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const containsChip = (chip) => {
    const { chips } = numOfLines[num];
    let found = false;
    for (const chipper of chips) {
      if (chipper.artID === chip.id) {
        found = true;
      }
    }
    return found;
  };

  const removeChipsFromAll = () => {
    post('/removeAllArtToAllBasedOnFilters', { workOrderID: workOrder.id, filters })
      .then(() => {
        setModal(false);
        fetchOrders();
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const removeChipFromAll = (chip) => {
    post('/removeArtToAllBasedOnFilters', {
      workOrderID,
      filters,
      artID: chip.id,
    })
      .then(() => {
        setModal(false);
        fetchOrders();
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const addChip = (chip) => {
    const newLines = [...numOfLines];
    const newChips = newLines[num].chips;
    const orderID = newLines[num].ordersID;
    const deleteChip = newChips.find((row) => row.artID === chip.id);
    if (deleteChip) {
      post('/removeArtFromOrder', deleteChip)
        .then(() => {
          newChips.splice(newChips.indexOf(deleteChip), 1);
          setNumOfLines(newLines);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    } else {
      post('/newArtForOrder', { orderID, artID: chip.id })
        .then((results) => {
          newChips.push({ id: results.insertId, orderID, artID: chip.id });
          setNumOfLines(newLines);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const addChips = () => {
    for (const chip of chips) {
      if (!containsChip(chip)) {
        addChip(chip);
      }
    }
  };

  const addChipToAll = (chip) => {
    post('/attachArtToAllBasedOnFilters', {
      workOrderID,
      filters,
      artID: chip.id,
    })
      .then(() => {
        setModal(false);
        fetchOrders();
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const addChipsToAll = () => {
    post('/attachAllArtToAllBasedOnFilters', { workOrderID, filters })
      .then(() => {
        setModal(false);
        fetchOrders();
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        backgroundColor: '#ffffff',
        padding: '15px',
        height: '100%',
        overflowX: 'auto',
        outline: 'none',
      }}
    >
      <Grid container justify="flex-end">
        <Grid item xs={10}>
          <ListItemText
            primary={`${load.chips.length} ${load.chips.length !== 1 ? 'pieces' : 'piece'} of art for ${load.color} ${vendor?.name} ${load.type} ${
              load.youthStyle
            } ${load.adultStyle}`}
            secondary={load.notes}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <Button variant="contained" style={{ marginRight: '25px' }} onClick={close}>
            Close
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title="Attach All Art For This Line Item">
                  <IconButton onClick={() => addChips()} style={{ marginLeft: '8px' }}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Apply All to All">
                  <IconButton href="" onClick={() => addChipsToAll()}>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Unapply All">
                  <IconButton href="" onClick={() => removeChipsFromAll()}>
                    <RemoveCircleOutline />
                  </IconButton>
                </Tooltip>
              </TableCell>
              {header.map((row, key) => (
                <TableCell key={key}>{row.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chips.map((chip, key1) => (
              <TableRow key={key1}>
                <TableCell>
                  <Tooltip title="Check For This Line Item">
                    <Switch onChange={() => addChip(chip)} color="primary" checked={containsChip(chip) !== false} value={containsChip(chip)} />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Apply to All">
                    <IconButton href="" onClick={() => addChipToAll(chip)}>
                      <FilterList />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Remove this from All">
                    <IconButton href="" onClick={() => removeChipFromAll(chip)}>
                      <RemoveCircleOutline />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {header.map((row, key2) =>
                  row.id !== 'underlay' && row.id !== 'reversible' ? (
                    row.id === 'name' ? (
                      <TableCell key={key2}>
                        <a target="__blank" href={chip.url} style={{ color: 'black' }}>
                          <img
                            style={{
                              maxHeight: '300px',
                              verticalAlign: 'middle',
                              maxWidth: '300px',
                            }}
                            src={chip.url}
                          />
                        </a>
                      </TableCell>
                    ) : (
                      <TableCell key={key2}>{typeof chip[row.id] === 'object' ? chip[row.id]?.name : chip[row.id]}</TableCell>
                    )
                  ) : (
                    <TableCell key={key2}>
                      <Checkbox checked={Boolean(chip[row.id])} value={Boolean(chip[row.id])} color="primary" />
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PrintShopModal;

PrintShopModal.propTypes = {
  chips: PropTypes.array,
  workOrderID: PropTypes.number,
  load: PropTypes.object,
  close: PropTypes.func,
  num: PropTypes.number,
  setModal: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  filters: PropTypes.array,
  numOfLines: PropTypes.array,
  setNumOfLines: PropTypes.func,
};
