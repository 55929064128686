/* eslint no-restricted-syntax:0 */
/* eslint no-param-reassign:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-console:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint max-len:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable, {
  // {MTableToolbar, MTablePagination}
  MTableToolbar,
} from 'material-table';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab';
import { Checkbox, FormControlLabel, InputLabel, Paper, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

class UserWorkHistory extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.state = {
      workOrderID: 0,
      numOfLines: [],
      arrivalDateModal: false,
      submitted: false,
      countTotal: date,
      mixInkTotal: date,
      burnScreensTotal: date,
      printTotal: date,
      packagingTotal: date,
      totalTime: 0,
      users: [],
      options: [],
      // todo: add notes and estimated cost and actual cost the actual cost column should only be edited by steve or jordana
      columns: [
        {
          title: 'Work Order',
          field: 'workOrderID',
        },
        {
          title: 'Type',
          field: 'type',
          editable: this.props.user.group === 'Accounting Admin' || this.props.user.group === 'Admin' ? 'always' : 'never',
        },
        {
          title: 'Timestamp',
          field: 'timestamp',
          type: 'datetime',
          render: (rowData) => {
            const date = new Date(rowData.timestamp);
            return `${date.toDateString()} ${date.getHours().toString().length > 1 ? date.getHours() : `0${date.getHours()}`}:${
              date.getMinutes().toString().length > 1 ? date.getMinutes() : `0${date.getMinutes()}`
            }:${date.getSeconds().toString().length > 1 ? date.getSeconds() : `0${date.getSeconds()}`}`;
          },
          // editable: (this.props.user.group === 'Accounting Admin' || this.props.user.group === 'Admin') ? 'always' : 'never',
        },
        {
          title: 'Message',
          field: 'message',
          type: 'string',
        },
      ],
    };
  }

  componentDidMount() {
    const filter = {
      eager: {
        $where: {
          type: 'type',
        },
      },
    };
    this.props
      .get('/options', { filter })
      .then((results) => {
        this.setState({
          options: { type: results },
        });
      })
      .then(() => {
        this.props
          .get('/getUserHistory', [{ name: 'userID', value: this.props.userID }])
          .then((results) => {
            const numOfLines = results;
            const { submitted } = results;
            this.setState({
              workOrderID: this.props.id,
              numOfLines,
              submitted,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .then(() => {
        this.props.get('/allUsers', []).then((usersGet) => {
          this.setState({
            users: usersGet,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  getUserHistoryOptions = async (page, pageSize, orderBy, orderDirection, search) => {
    if (!orderBy) {
      orderBy = { field: 'date' };
    }
    let histories = {};
    const parameters = [
      { name: 'uid', value: this.props.userID },
      { name: 'page', value: page },
      { name: 'pageSize', value: pageSize },
      { name: 'orderBy', value: orderBy.field },
      { name: 'orderDirection', value: orderDirection },
      { name: 'search', value: search },
    ];
    await this.props.get(`/getUserHistoryOptions`, parameters).then((results) => {
      histories = results;
      for (const history of histories.data) {
        history.date = new Date(histories.date);
      }
    });
    return histories;
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title={`${this.props.currentUser.firstName} ${this.props.currentUser.lastName}'s Work Order History`}
            columns={this.state.columns}
            data={async (query) => {
              console.log(query);
              const { page, pageSize, orderBy, orderDirection, search } = query;
              const returned = await this.getUserHistoryOptions(page, pageSize, orderBy, orderDirection, search);
              console.log(returned);
              return {
                data: returned.data,
                page,
                totalCount: returned.totalCount.count,
              };
            }}
            options={{
              rowStyle: {
                fontSize: 14,
              },
              search: false,
              sorting: false,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50],
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                // todo: add row update
              },
              onRowAdd: async (newData) => {
                // todo: add row add
              },
              onRowDelete: async (oldData) => {
                // todo: add row delete
              },
            }}
          />
        </Paper>
      </div>
    );
  }
}

export default UserWorkHistory;
