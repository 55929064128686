import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { rewindLineItem } from '../../../Stores/Fulfillment/LineItems/Actions';
import { closeDialog } from '../../../Stores/Dialog/Actions';

const ErrorDialog = ({ steps, statuses, line, reload, lines }) => {
  const [toStep, setToStep] = useState({});
  return (
    <div>
      <Grid container>
        <Grid item md={2}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel>Move to Step</InputLabel>
            <Select fullWidth value={toStep} onChange={(e) => setToStep(e.target.value)}>
              {steps
                .sort((a, b) => a.id - b.id)
                .map((step, key) => (
                  <MenuItem value={step} key={key}>
                    {statuses.find((s) => s.id === step.statusId)?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          color={'primary'}
          onClick={async () => {
            if (line) {
              rewindLineItem(line, toStep).then(() => {
                closeDialog();
                reload();
              });
            } else if (lines) {
              await Promise.all(lines.map((line) => rewindLineItem(line, toStep)));
              closeDialog();
              reload();
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
};

export default ErrorDialog;

ErrorDialog.propTypes = {
  steps: PropTypes.array,
  statuses: PropTypes.array,
  line: PropTypes.object,
  reload: PropTypes.func,
  lines: PropTypes.array,
};
