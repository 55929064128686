import React, { useState } from 'react';
import { createDialog } from '../../Stores/Dialog/Actions';
import CustomerEditDialog from '../Shared/Customers/CustomerEditDialog';
import { Grid, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { post } from '../../Functions/API';

function CustomerAddToolbar() {
  const [customerName, setCustomerName] = useState('');

  const openNewCustomerModal = () => {
    post('/addCustomer', { name: customerName }).then((result) => {
      createDialog({
        content: <CustomerEditDialog customerID={result.customer} />,
        title: 'Edit Customer',
        actions: null,
        size: 'xl',
        disableBackdropClick: true,
      });
    });
  };

  return (
    <Grid spacing={1} container justify="center" alignItems="baseline">
      <Grid item xs={12} sm={6}>
        <TextField
          style={{ width: '100%' }}
          onChange={(e) => setCustomerName(e?.target?.value)}
          value={customerName ? customerName : ''}
          label="New Customer"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ height: '100%' }} color="primary" disabled={!customerName} variant="contained" onClick={openNewCustomerModal}>
                  <Add />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default CustomerAddToolbar;
