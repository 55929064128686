/* eslint react/require-default-props:0 */
/* eslint react/forbid-prop-types:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/no-children-prop:0 */
/* eslint react/prop-types:0 */
/* eslint max-len:0 */
/* eslint no-param-reassign:0 */
/* eslint no-shadow:0 */
/* eslint no-underscore-dangle:0 */
/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-unused-expressions:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Accordion, AccordionSummary, AccordionDetails, ButtonGroup, Select, MenuItem } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { ExpandMore } from '@material-ui/icons';
import ArtRequestModal from './ArtRequestModal';
import ArtRequestProductModal from './ArtRequestProductModal';
import ArtRequestArtModal from './ArtRequestArtModal';
import OrderArtSummary from '../Modals/OrderArtSummary';
import MockUpDetails from './MockUpDetails';
import ArtCosts from './ArtCosts';
import * as NotificationsActions from '../../Stores/Notifications/Actions';
import { getHomePage } from '../../Functions/API';
import { updateWorkOrder } from '../../Stores/WorkOrder/Actions';
import { get, post, patch } from '../../Functions/API';
import { createSnack } from '../../Stores/Snack/Actions';
import IndividualArt from './IndividualArt';
import UserStore from '../../Stores/User/Store';

const Art = ({ workOrder, csp }) => {
  const user = UserStore.getUser();
  const { WorkOrderId } = useParams();
  const [priceGroups, setPriceGroups] = useState([]);
  const [chips, setChips] = useState([]);
  const [options, setOptions] = useState({});
  const [artForOrderArtSummaryModal, setArtForOrderArtSummaryModal] = useState({});
  const [printOptions, setPrintOptions] = useState([]);
  const [priceLists, setPriceLists] = useState([]);
  const [artists, setArtists] = useState([]);
  const [ordersByArt, setOrdersByArt] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [openAddArt, setOpenAddArt] = useState(false);
  const [openArtRequest, setOpenArtRequest] = useState(false);
  const [openArtRequestProduct, setOpenArtRequestProduct] = useState(false);
  const [openArtRequestArt, setOpenArtRequestArt] = useState(false);
  const [mockUps, setMockUps] = useState([]);
  const [modalChip, setModalChip] = useState({});
  const [artRequestChip, setArtRequestChip] = useState({
    printType: '',
    side: '',
    position: '',
    type: '',
    ink: '',
    color: '',
    numbers: '',
    numbersArr: [],
    medWeed: false,
    hardWeed: false,
    reversible: false,
    specialInk: false,
    underlay: false,
  });
  const [progress, setProgress] = useState(0);
  const [artRequestID, setArtRequestID] = useState(0);
  const [artRequestApproval, setArtRequestApproval] = useState(0);
  const [artRequestApproved, setArtRequestApproved] = useState(0);
  const [artRequestComment, setArtRequestComment] = useState('');
  const [comments, setComments] = useState([]);
  const [products, setProducts] = useState([]);
  const [art, setArt] = useState([]);
  const [artist, setArtist] = useState(0); // 0 is not valid id. unassigned
  const [artRequestProductVendor, setArtRequestProductVendor] = useState('');
  const [artRequestProductStyleNumber, setArtRequestProductStyleNumber] = useState('');
  const [artRequestProductImage, setArtRequestProductImage] = useState('');
  const [artRequestProductImages, setArtRequestProductImages] = useState('');
  const [artRequestArtPriceList, setArtRequestArtPriceList] = useState('');
  const [artRequestArtPrintType, setArtRequestArtPrintType] = useState('');
  const [artRequestArtPosition, setArtRequestArtPosition] = useState('');
  const [artRequestArtImage, setArtRequestArtImage] = useState('');
  const [artRequestArtImages, setArtRequestArtImages] = useState([]);
  const [artRequestProductKey, setArtRequestProductKey] = useState(-1);
  const [artRequestArtKey, setArtRequestArtKey] = useState(-1);
  const [drag, setDrag] = useState(false);
  const [ready, setReady] = useState(false);
  const [NESPDFDisabled, setNESPDFDisabled] = useState(false);
  const [NESPDFId, setNESPDFId] = useState(null);
  const [isArtist, setIsArtist] = useState(false);
  const [availableVendors, setAvailableVendors] = useState([]);

  useEffect(() => {
    if (WorkOrderId) {
      setup()
        .then(() => {
          setReady(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [WorkOrderId]);

  const fetchOptions = async () => {
    const filter = {
      eager: {
        $where: {
          type: {
            $in: ['ink', 'type'],
          },
        },
      },
    };
    const results = await get('/options', { filter });
    const ink = results.filter((result) => result.type === 'ink');
    const type = results.filter((result) => result.type === 'type');
    setOptions({ ink, type });
  };

  const fetchPrintOptions = async () => {
    // const results = await get('/getPrintOptions', []);
    // setPrintOptions(results.printOptions);
    // setPriceLists(results.priceLists);
    const results = await get('/print-groups');
    setPriceGroups(results);
  };

  const fetchWorkOrderArt = async () => {
    const filter = {
      eager: {
        $where: {
          workOrderID: WorkOrderId,
        },
        printGroup: {
          printTypes: {},
        },
        printType: {},
      },
    };
    const results = await get('/workOrderArt', { filter });
    for (const row of results) {
      row.saved = true;
    }
    setChips(results);
  };

  const fetchMockUps = async () => {
    const results = await get('/mockUps', [{ name: 'workOrderID', value: WorkOrderId }]);
    setMockUps(results);
  };

  const fetchArtists = async () => {
    const results = await get('/artists');
    setAvailableVendors(
      results.map(({ vendorID, name }) => {
        return {
          vendorID,
          name,
        };
      }),
    );
    setArtists(results);
    const workOrderArtist = await get(`/workorder/${WorkOrderId}/artists`);
    if (workOrderArtist.length > 0) {
      setArtist(results.find((artist) => artist.id === workOrderArtist[0].artistID));
    } else {
      setArtist({ id: 0 });
    }
  };

  const fetchArtRequest = async () => {
    const results = await get('/getArtRequest', [{ name: 'workOrderID', value: WorkOrderId }]);
    results.id ? setArtRequestID(results.id) : setArtRequestID(0);
    results.ryanReview === 0 ? setArtRequestApproval(1) : setArtRequestApproval(0);
    setArtRequestApproved(results.approved);
    results.comments ? setComments(results.comments) : setComments([]);
    results.products ? setProducts(results.products) : setProducts([]);
    results.art ? setArt(results.art) : setArt([]);
  };

  const setWorkOrderArtist = async (event) => {
    patch(`/workorder/${WorkOrderId}/artists`, { artistID: event.target.value })
      .then(() => {
        const currentArtist = artists.find((artist) => artist.id === event.target.value);
        setArtist(currentArtist);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const setup = async () => {
    getNESPDF(WorkOrderId);
    try {
      await Promise.all([fetchArtRequest(), fetchMockUps(), fetchWorkOrderArt(), fetchPrintOptions(), fetchOptions(), fetchArtists()]);
    } catch (err) {
      createSnack('Error', err.message);
    }
  };

  const updateChip = (event, chip, option) => {
    const items = [...chips];
    const index = items.indexOf(chip);
    items[index][option] = event.target.value;
    items[index].saved = false;
    setChips(items);
  };

  const updateArtRequestChip = (event, option) => {
    const localArtRequestChip = { ...artRequestChip };
    localArtRequestChip[option] = event.target.value;
    setArtRequestChip(localArtRequestChip);
  };

  const toggleUnderLay = (event, item) => {
    const items = [...chips];
    const index = items.indexOf(item);
    items[index].underlay = !items[index].underlay;
    items[index].saved = false;
    setChips(items);
  };

  const toggleReversible = (event, item) => {
    const items = [...chips];
    const index = items.indexOf(item);
    items[index].reversible = !items[index].reversible;
    items[index].saved = false;
    setChips(items);
  };

  const toggleWeed = (event, item, type) => {
    const items = [...chips];
    const index = items.indexOf(item);
    items[index][type] = !items[index][type];
    items[index].saved = false;
    setChips(items);
  };

  const openModal = (chip) => {
    setModalOpen(true);
    setModalChip(chip);
  };

  const toggleAddArt = () => {
    setOpenAddArt(!openAddArt);
  };

  const toggleArtRequest = () => {
    setOpenArtRequest(!openArtRequest);
  };

  const toggleArtRequestProduct = () => {
    setOpenArtRequestProduct(!openArtRequestProduct);
  };

  const toggleArtRequestArt = () => {
    setOpenArtRequestArt(!openArtRequestArt);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalChip({});
  };

  const updateComment = (event) => {
    setArtRequestComment(event.target.value);
  };

  const addComment = async () => {
    const localArtRequestComment = artRequestComment;
    const localArtRequestID = artRequestID;
    const { uid } = user;
    const data = {
      artRequestID: localArtRequestID,
      comment: localArtRequestComment,
      uid,
    };
    post('/addArtRequestComment', data)
      .then((response) => {
        const localComments = comments;
        localComments.push(response);
        setArtRequestComment('');
        setComments(localComments);
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const editComment = (comment) => {
    const newComment = window.prompt('Please edit your comment', comment.comment);
    if (newComment) {
      const localComments = comments;
      const index = localComments.indexOf(comment);
      comment.comment = newComment;
      post('/editArtRequestComment', comment)
        .then(() => {
          localComments[index] = comment;
          setComments(localComments);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const removeComment = (comment) => {
    post('/removeArtRequestComment', comment)
      .then(() => {
        const localComments = comments;
        const index = localComments.indexOf(comment);
        localComments.splice(index, 1);
        setComments(localComments);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateArtRequestProductVendor = (event) => {
    if (event.target.value === 'None') {
      setArtRequestProductVendor('');
    } else {
      setArtRequestProductVendor(event.target.value);
    }
  };

  const updateArtRequestProductStyleNumber = (event) => {
    setArtRequestProductStyleNumber(event.target.value);
  };

  const addArtRequestProductImages = async (files) => {
    const items = [...artRequestProductImages];
    for (const file of files) {
      const localProducts = [...products];
      const key = artRequestProductKey;
      const artRequestProductID = localProducts[key].id;
      const newImage = { name: file.fileName, url: file.url };
      const data = {
        artRequestProductID,
        image: newImage,
      };
      await post('/addArtRequestProductImage', data)
        .then(() => {
          items.push(newImage);
          createSnack('Success', 'Image Uploaded');
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }
    setArtRequestProductImages(items);
  };

  const updateArtRequestArtPriceList = (event, option) => {
    const localArtRequestChip = artRequestChip;
    localArtRequestChip[option] = event.target.value;
    setArtRequestArtPriceList(event.target.value);
    setArtRequestChip(localArtRequestChip);
  };

  const updateArtRequestArtPrintType = (event, option) => {
    const localArtRequestChip = artRequestChip;
    localArtRequestChip[option] = event.target.value;
    setArtRequestArtPrintType(event.target.value);
    setArtRequestChip(localArtRequestChip);
  };

  const updateArtRequestArtPosition = (event) => {
    setArtRequestArtPosition(event.target.value);
  };

  const updateArtRequestArtImages = async (files) => {
    const items = [...artRequestArtImages];
    for (const file of files) {
      const localArt = [...art];
      const key = artRequestArtKey;
      const artRequestArtID = localArt[key].id;
      const newImage = { name: file.fileName, url: file.url };
      const data = {
        artRequestArtID,
        image: newImage,
      };
      await post('/addArtRequestArtImage', data)
        .then(() => {
          items.push(newImage);
          createSnack('Success', 'Image Uploaded');
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }
    setArtRequestArtImages(items);
  };

  const updateArtRequestProduct = async () => {
    const localProducts = products;
    const key = artRequestProductKey;
    const { id } = localProducts[key];
    const vendor = artRequestProductVendor;
    const styleNumber = artRequestProductStyleNumber;
    localProducts[key].vendor = vendor;
    localProducts[key].styleNumber = styleNumber;
    localProducts[key].images = artRequestProductImages;
    const data = {
      id,
      vendor,
      styleNumber,
    };
    await post('/updateArtRequestProduct', data)
      .then(() => {
        setProducts(localProducts);
        setArtRequestProductVendor('');
        setArtRequestProductStyleNumber('');
        setArtRequestProductImages([]);
        toggleArtRequestProduct();
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const updateArtRequestArt = async () => {
    const localArt = art;
    const key = artRequestArtKey;
    const { id } = localArt[key];
    const priceList = artRequestArtPriceList;
    const printType = artRequestArtPrintType;
    const position = artRequestArtPosition;
    localArt[key].priceList = priceList;
    localArt[key].printType = printType;
    localArt[key].position = position;
    localArt[key].images = artRequestArtImages;
    const localArtRequestChip = artRequestChip;
    localArtRequestChip.type = '';
    const data = {
      id,
      priceList,
      printType,
      position,
    };
    await post('/updateArtRequestArt', data)
      .then(() => {
        setArt(localArt);
        setArtRequestArtPriceList('');
        setArtRequestArtPrintType('');
        setArtRequestArtPosition('');
        setArtRequestArtImages([]);
        setArtRequestChip(localArtRequestChip);
        toggleArtRequestArt();
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const newArtRequest = async () => {
    if (!artRequestID) {
      await post('/addArtRequest', { workOrderID: WorkOrderId })
        .then((result) => {
          setArtRequestID(result.insertId);
          toggleArtRequest();
        })
        .catch((err) => {
          window.alert(err);
        });
    } else {
      toggleArtRequest();
    }
  };

  const newArtRequestProduct = async () => {
    await post('/addArtRequestProduct', { artRequestID: artRequestID })
      .then((result) => {
        const localProducts = products;
        localProducts.push({
          id: result.insertId,
          vendor: '',
          styleNumber: '',
          images: [],
        });
        const key = localProducts.length - 1;
        setArtRequestProductKey(key);
        setArtRequestProductVendor('');
        setArtRequestProductStyleNumber('');
        setArtRequestProductImages([]);
        setProducts(localProducts);
        toggleArtRequestProduct();
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const newArtRequestArt = async () => {
    await post('/addArtRequestArt', { artRequestID })
      .then((result) => {
        const localArtRequestChip = artRequestChip;
        localArtRequestChip.type = '';
        localArtRequestChip.printType = '';
        const localArt = art;
        localArt.push({
          id: result.insertId,
          priceList: '',
          printType: '',
          position: '',
          images: [],
        });
        setArtRequestArtPriceList('');
        setArtRequestArtPrintType('');
        setArtRequestArtPosition('');
        setArtRequestArtImages([]);
        setArtRequestArtKey(localArt.length - 1);
        setArt(localArt);
        toggleArtRequestArt();
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const editArtRequestProduct = (event, item, key) => {
    const localProducts = products;
    let vendor;
    let styleNumber;
    let images;
    localProducts[key].vendor ? (vendor = products[key].vendor) : (vendor = '');
    localProducts[key].styleNumber ? (styleNumber = products[key].styleNumber) : (styleNumber = '');
    localProducts[key].images ? (images = products[key].images) : (images = []);
    setArtRequestProductVendor(vendor);
    setArtRequestProductStyleNumber(styleNumber);
    setArtRequestProductImages(images);
    setArtRequestProductKey(key);
    toggleArtRequestProduct();
  };

  const editArtRequestArt = (event, item, key) => {
    const localArt = art;
    let priceList;
    let printType;
    let position;
    let images;
    localArt[key].priceList ? (priceList = art[key].priceList) : (priceList = '');
    localArt[key].printType ? (printType = art[key].printType) : (printType = '');
    localArt[key].position ? (position = art[key].position) : (position = '');
    localArt[key].images ? (images = art[key].images) : (images = []);
    const localArtRequestChip = artRequestChip;
    localArtRequestChip.type = priceList;
    localArtRequestChip.printType = printType;
    setArtRequestArtPriceList(priceList);
    setArtRequestArtPrintType(printType);
    setArtRequestArtPosition(position);
    setArtRequestArtImages(images);
    setArtRequestArtKey(key);
    setArtRequestChip(localArtRequestChip);
    toggleArtRequestArt();
  };

  const deleteArtRequestProduct = async (event, item, key) => {
    const c = window.confirm('Are you sure you want to delete this art request product?');
    if (c) {
      const localProducts = [...products];
      await post('/removeArtRequestProduct', { id: localProducts[key].id })
        .then((response) => {
          localProducts.splice(key, 1);
          setProducts(localProducts);
        })
        .catch((err) => {
          window.alert(err);
        });
    }
  };

  const deleteArtRequestProductImage = async (event, item, key) => {
    const c = window.confirm('Are you sure you want to delete this image?');
    if (c) {
      const images = [...artRequestProductImages];
      const data = {
        id: images[key].id,
      };
      await post('/removeArtRequestProductImage', data)
        .then(() => {
          images.splice(key, 1);
          setArtRequestProductImages(images);
        })
        .catch((err) => {
          window.alert(err);
        });
    }
  };

  const deleteArtRequestArt = async (event, item, key) => {
    const c = window.confirm('Are you sure you want to delete this art request art?');
    if (c) {
      const localArt = [...art];
      await post('/removeArtRequestArt', { id: localArt[key].id })
        .then((response) => {
          localArt.splice(key, 1);
          setArt(localArt);
        })
        .catch((err) => {
          window.alert(err);
        });
    }
  };

  const deleteArtRequestArtImage = async (event, item, key) => {
    const c = window.confirm('Are you sure you want to delete this image?');
    if (c) {
      const images = [...artRequestArtImages];
      const data = {
        id: images[key].id,
      };
      await post('/removeArtRequestArtImage', data)
        .then((response) => {
          images.splice(key, 1);
          setArtRequestArtImages(images);
        })
        .catch((err) => {
          window.alert(err);
        });
    }
  };

  const updateArt = (chip) => {
    const localChips = [...chips];
    const updateChip = { ...chip };
    delete updateChip['saved'];
    const index = localChips.indexOf(chip);
    patch(`/workOrderArt/${chip.id}`, updateChip)
      .then(() => {
        createSnack('Success', 'Art updated');
        localChips[index].saved = true;
        setChips(localChips);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const submitArtRequest = () => {
    post('/submitArtRequestToRyan', { workOrderID: WorkOrderId })
      .then(async () => {
        createSnack('Success', 'Request Submitted Successfully');
        await setup();
        setReady(true);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    NotificationsActions.addNotification('Art', 'An Art Request has been submitted!', null, user, workOrder);

    toggleArtRequest();
  };

  const artRequestSendForApproval = () => {
    post('/artRequestPendingApproval', {
      workOrderID: WorkOrderId,
      uid: user.uid,
    })
      .then(async () => {
        createSnack('Success', 'Art Request is now pending approval');
        await setup();
        setReady(true);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    NotificationsActions.addNotification('Sales', 'Art is pending approval.', null, user, workOrder);
  };

  const approveArt = () => {
    post('/approveArt', {
      workOrderID: WorkOrderId,
      uid: user.uid,
    })
      .then(async (results) => {
        createSnack('Success', 'Art has been approved');
        NotificationsActions.addNotification('Sales', 'Art has been approved', null, user, workOrder);
        await setup();
        setReady(true);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const unApproveArt = () => {
    post('/unApproveArt', {
      workOrderID: WorkOrderId,
      uid: user.uid,
    })
      .then(async (results) => {
        createSnack('Success', 'Art has been Un-Approved');
        setArtRequestApproved(results.approved);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    if (workOrder.salesApproved) {
      NotificationsActions.addNotification('Error', 'Art Un-Approved after sales approved was checked!', null, user, workOrder);
    } else {
      NotificationsActions.addNotification('Sales', 'Art has been Un-Approved', null, user, workOrder);
    }
  };

  const downloadArtRequest = () => {
    window.open(`${getHomePage()}/downloadArtRequest?workOrderID=${WorkOrderId}`, '_blank');
  };

  const toggleOrderModal = () => {
    setOrderModal(!orderModal);
    setArtForOrderArtSummaryModal({});
  };

  const createNESPDF = (id) => {
    setNESPDFDisabled(true);
    post('/NESPDF', { id }, 'obj')
      .then((results) => {
        setNESPDFDisabled(false);
        getNESPDF(id);
      })
      .then(() => {
        createSnack('Success', 'PDF Created!');
      })
      .catch((err) => {
        console.error('ERROR:', err);
        setNESPDFDisabled(false);
        createSnack('Error', 'Could not create PDF!');
      });
  };

  const getNESPDF = (id, type) => {
    get('/NESPDF', { id }, 'obj')
      .then((result) => {
        setNESPDFId(result.id);
        if (type === 'copy') {
          navigator.clipboard
            .writeText(result.url)
            .then(() => {
              createSnack('Success', 'Copied url to clipboard');
            })
            .catch((err) => {
              throw err;
            });
        } else if (type === 'download') {
          window.open(result.url);
        }
      })
      .catch((err) => {
        console.error(err);
        createSnack('Error', 'Could not get PDF!');
      });
  };

  const deleteNESPDF = (id) => {
    setNESPDFDisabled(true);
    post('/deleteNESPDF', { id }, 'obj')
      .then((result) => {
        setNESPDFId(null);
        setNESPDFDisabled(false);
        createSnack('Success', 'Deleted NES pdf!');
      })
      .catch((err) => {
        setNESPDFDisabled(false);
        console.error(err);
        createSnack('Error', 'Could not delete PDF!');
      });
  };

  useEffect(() => {
    determineIfArtistOrAdmin();
  }, [artists]);

  const determineIfArtistOrAdmin = () => {
    // TODO editArtistOnWorkOrder
    if (user.group === 'Admin') {
      setIsArtist(true);
      return;
    }
    for (const artist of artists) {
      if (artist.uid === user.uid) {
        setIsArtist(true);
        return;
      }
    }
    setIsArtist(false);
  };

  const textFieldStyle = {
    margin: '8px',
  };
  const descriptionFieldStyle = {
    width: '100%',
  };
  return (
    <div>
      {ready && (
        <div style={{ paddingBottom: '15px', margin: '30px', overflowX: 'hidden', backgroundColor: '#eaeaea' }}>
          <Modal open={modalOpen} children="div" onClose={closeModal}>
            <div style={{ position: 'relative', height: '100%' }}>
              <IconButton
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  backgroundColor: '#f3f2f7',
                }}
                onClick={closeModal}
                color="primary"
                href=""
              >
                <Cancel />
              </IconButton>
              <iframe src={modalChip.url} style={{ margin: 'auto', width: '100%', height: '100%' }} />
            </div>
          </Modal>
          <Modal open={openArtRequest} children="div" style={{ overflow: 'auto' }} onClose={toggleArtRequest}>
            <ArtRequestModal
              descriptionFieldStyle={descriptionFieldStyle}
              submitArtRequest={submitArtRequest}
              toggleArtRequest={toggleArtRequest}
              editComment={editComment}
              removeComment={removeComment}
              products={products}
              artRequestComment={artRequestComment}
              art={art}
              approved={artRequestApproved}
              updateComment={updateComment}
              comments={comments}
              addComment={addComment}
              editArtRequestProduct={editArtRequestProduct}
              editArtRequestArt={editArtRequestArt}
              deleteArtRequestProduct={deleteArtRequestProduct}
              deleteArtRequestArt={deleteArtRequestArt}
              newArtRequestProduct={newArtRequestProduct}
              newArtRequestArt={newArtRequestArt}
              toggleArtRequestProduct={toggleArtRequestProduct}
              toggleArtRequestArt={toggleArtRequestArt}
            />
          </Modal>
          <Modal open={openArtRequestProduct} children="div" onClose={toggleArtRequestProduct}>
            <ArtRequestProductModal
              toggleArtRequestProduct={toggleArtRequestProduct}
              descriptionFieldStyle={descriptionFieldStyle}
              updateArtRequestProduct={updateArtRequestProduct}
              artRequestProductVendor={artRequestProductVendor}
              artRequestProductStyleNumber={artRequestProductStyleNumber}
              artRequestProductImage={artRequestProductImage}
              artRequestProductImages={artRequestProductImages}
              updateArtRequestProductVendor={updateArtRequestProductVendor}
              updateArtRequestProductStyleNumber={updateArtRequestProductStyleNumber}
              addArtRequestProductImages={addArtRequestProductImages}
              deleteArtRequestProductImage={deleteArtRequestProductImage}
            />
          </Modal>
          <Modal open={openArtRequestArt} children="div" onClose={toggleArtRequestArt}>
            <ArtRequestArtModal
              options={options}
              artRequestChip={artRequestChip}
              priceLists={priceLists}
              printOptions={printOptions}
              updateArtRequestChip={updateArtRequestChip}
              descriptionFieldStyle={descriptionFieldStyle}
              toggleArtRequestArt={toggleArtRequestArt}
              csp={csp}
              updateArtRequestArt={updateArtRequestArt}
              updateArtRequestArtPriceList={updateArtRequestArtPriceList}
              updateArtRequestArtPrintType={updateArtRequestArtPrintType}
              updateArtRequestArtPosition={updateArtRequestArtPosition}
              updateArtRequestArtImages={updateArtRequestArtImages}
              deleteArtRequestArtImage={deleteArtRequestArtImage}
              artRequestArtPriceList={artRequestArtPriceList}
              artRequestArtPrintType={artRequestArtPrintType}
              artRequestArtPosition={artRequestArtPosition}
              artRequestArtImage={artRequestArtImage}
              artRequestArtImages={artRequestArtImages}
            />
          </Modal>
          <Modal open={orderModal} onClose={toggleOrderModal} style={{ top: '10%' }}>
            <OrderArtSummary orders={ordersByArt} art={artForOrderArtSummaryModal} />
          </Modal>
          {progress !== 0 && <LinearProgress variant="determinate" color="secondary" value={progress} />}
          <Grid container spacing={1}>
            <Grid item>
              <FormControl style={{ width: '100%', paddingBottom: '8px' }}>
                {artists && (
                  <Select disabled={!isArtist} displayEmpty onChange={setWorkOrderArtist} value={artist?.id}>
                    <MenuItem key={0} value="0" disabled>
                      Unassigned
                    </MenuItem>
                    {artists.map((artist, key) => (
                      <MenuItem key={key + 1} value={artist.id}>
                        {artist.firstName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            {artRequestID === 0 ? (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => newArtRequest()}>
                  Add Art Request
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => toggleArtRequest()}>
                  View Art Request
                </Button>
              </Grid>
            )}
            {artRequestID !== 0 && (
              <Grid item style={{ flexGrow: 1 }}>
                <Button variant="contained" onClick={() => downloadArtRequest()}>
                  Download Art Request
                </Button>
              </Grid>
            )}
            {artRequestApproval === 0 && (user?.group?.includes('Art') || user?.group === 'Admin' || user?.uid === 'fB8Obow7AoWG88YHrNLAUO0j9FV2') && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={artRequestSendForApproval}>
                  Send to Sales for Approval
                </Button>
              </Grid>
            )}
            {
              // removed artRequestApproved === 0 in order to keep the button visible after approving
              artRequestApproval === 1 &&
                (user?.group?.includes('Sales') ||
                  user?.group === 'Order Processor' ||
                  user?.group?.includes('Owner') ||
                  user?.group === 'Admin') && (
                  <>
                    {artRequestApproved === 0 ? (
                      <>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={approveArt}>
                            Approve Art
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="secondary" style={{ width: '100%' }} onClick={toggleArtRequest}>
                            Alter Art
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={unApproveArt}>
                          Un-Approve
                        </Button>
                      </Grid>
                    )}
                  </>
                )
            }
            {/* TODO setArtSent */}
            {user.group === 'Production Admin' && (
              <Grid item>
                <FormControlLabel
                  style={{ margin: '10px' }}
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(workOrder.artSent)}
                      value={Boolean(workOrder.artSent)}
                      onChange={() => updateWorkOrder(WorkOrderId, { artSent: !workOrder.artSent })}
                    />
                  }
                  label="Art Sent"
                />
              </Grid>
            )}
          </Grid>
          {chips.some((chip) => chip.type === 'NES') && (
            <Grid container spacing={1}>
              <FormControl component="div">
                <FormControlLabel
                  component="div"
                  label="NES pdf:"
                  labelPlacement="top"
                  control={
                    <ButtonGroup size="small" color="primary" style={{ margin: 'auto' }}>
                      <Button
                        onClick={() => {
                          createNESPDF(WorkOrderId);
                        }}
                        disabled={NESPDFDisabled || NESPDFId}
                      >
                        Create
                      </Button>
                      {NESPDFId && (
                        <Button
                          onClick={() => {
                            getNESPDF(WorkOrderId, 'download');
                          }}
                          disabled={NESPDFDisabled}
                        >
                          Download
                        </Button>
                      )}

                      {NESPDFId && (
                        <Button
                          onClick={() => {
                            getNESPDF(WorkOrderId, 'copy');
                          }}
                          disabled={NESPDFDisabled}
                        >
                          Copy url
                        </Button>
                      )}

                      {NESPDFId && (
                        <Button
                          onClick={() => {
                            deleteNESPDF(WorkOrderId);
                          }}
                          disabled={NESPDFDisabled}
                        >
                          Delete
                        </Button>
                      )}
                    </ButtonGroup>
                  }
                />
              </FormControl>
            </Grid>
          )}
          <div>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              defaultExpanded={!artRequestApproved && !chips.length}
              style={{
                marginTop: '10px',
                ...(drag
                  ? { border: '3px dashed red' }
                  : {
                      border: '3px solid rgba(0,0,0,0)',
                    }),
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>Mock up (drop files here)</AccordionSummary>
              <AccordionDetails>
                <MockUpDetails mockUps={mockUps} setMockUps={setMockUps} openModal={openModal} />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={Boolean(artRequestApproved) || Boolean(chips.length)}>
              <AccordionSummary expandIcon={<ExpandMore />}>Individual Art</AccordionSummary>
              <AccordionDetails>
                <IndividualArt
                  workOrder={workOrder}
                  csp={csp}
                  chips={chips}
                  priceGroups={priceGroups}
                  priceLists={priceLists}
                  printOptions={printOptions}
                  setChips={setChips}
                  openModal={openModal}
                  toggleUnderLay={toggleUnderLay}
                  toggleReversible={toggleReversible}
                  setArtForOrderArtSummaryModal={setArtForOrderArtSummaryModal}
                  setOrdersByArt={setOrdersByArt}
                  setOrderModal={setOrderModal}
                  toggleWeed={toggleWeed}
                  updateArt={updateArt}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>Art Costs</AccordionSummary>
              <AccordionDetails>
                <ArtCosts workOrder={workOrder} availableVendors={availableVendors} currentArtist={artist} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default Art;

Art.displayName = 'Art';

Art.propTypes = {
  workOrder: PropTypes.object,
  csp: PropTypes.object,
};
