import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { TextField, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import ContactAutocomplete from '../../../../../Contacts/ContactAutocomplete';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    width: '225px',
  },
}));

const updatePopper = ({ orders, type, callback, workOrder }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [docNumber, setDocNumber] = useState(null);
  const [customer, setCustomer] = useState('');
  const [contact, setContact] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCustomer('');
    setDocNumber(null);
  };

  const updateDocNumber = (event) => {
    setDocNumber(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Add contact / docNum
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <Grid container spacing={1} direction="column" justify="center" alignItems="center">
            <Grid item>
              <TextField
                id="docNum"
                label="Doc Num"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                style={{ width: '190px' }}
                margin="normal"
                value={docNumber}
                onChange={updateDocNumber}
                component="div"
              />
            </Grid>
            <Grid item style={{ width: '200px' }}>
              <ContactAutocomplete contacts={workOrder.contacts.filter((c) => c.type === 'billing')} contact={contact} callback={setContact} />
            </Grid>
            <Grid item>
              <Button
                size="small"
                style={{ backgroundColor: 'green' }}
                variant="contained"
                onClick={() => {
                  console.log('customer', customer);
                  callback(docNumber, contact, orders);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Typography>
      </Popover>
    </div>
  );
};

export default updatePopper;

updatePopper.propTypes = {
  callback: PropTypes.func,
  orders: PropTypes.array,
  type: PropTypes.string,
  workOrder: PropTypes.object,
};
