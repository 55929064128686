/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { LineChart, Line } from 'recharts';
import Graph from '../Components/Graph';
import { makeStyles } from '@material-ui/core/styles';
import { updateFilters } from '../../../Stores/Fulfillment/Filters/Actions';
import CustomersStore from '../../../Stores/Fulfillment/Customers/Store';
import { Link } from 'react-router-dom';
import { Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '98.5%',
  },
  storePaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'AliceBlue',
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  graph: {
    fontFamily: 'sans-serif',
    textAlign: 'center',
  },
}));

const Store = ({ store, customers }) => {
  const classes = useStyles();

  const handleOrdersClick = () => {
    const customer = CustomersStore.getCustomers().find((c) => c.id === store.customerId);
    updateFilters({ store, customer });
  };

  const handleEditClick = () => {
    const customer = CustomersStore.getCustomers().find((c) => c.id === store.customerId);
    updateFilters({ store, customer });
  };

  useEffect(() => {
    updateFilters({ store: null, storeDisabled: true });
  }, []);

  return (
    <Grid item>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {store.name}
          </Typography>
          <Graph />
          <br />
          <Typography variant="body2" color="textSecondary" component="p">
            Some random description?
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Button size="small" color="primary" onClick={handleOrdersClick} component={Link} to={`/fulfillment/orders?storeId=${store.id}`}>
                Orders
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button size="small" color="primary" onClick={handleOrdersClick} component={Link} to={`/fulfillment/products?storeId=${store.id}`}>
                Products
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button size="small" color="secondary" onClick={handleEditClick} component={Link} to={`/fulfillment/stores/${store.id}`}>
                View / Edit
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Store;
