import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.options = {
      type: [],
    };
  }

  getOptions() {
    return this.options.type;
  }

  loadOptions = (results) => {
    this.options = results;
    this.emit('change');
  };

  addOption = (option) => {
    this.options.type.push({ name: option, value: option });
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_OPTIONS': {
        this.loadOptions(action.results);
        break;
      }
      case 'ADD_OPTION': {
        this.addOption(action.option);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
