import React, { useEffect, useState } from 'react';
import { get } from '../../../Functions/API.js';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const PendingVendors = () => {
  const [vendors, setVendors] = useState([]);

  const history = useHistory();

  useEffect(() => {
    get('/vendors/pending').then(setVendors);
  }, []);

  const columns = [
    { title: 'Vendor', field: 'name' },
    { title: 'Pending Costs', field: 'costs' },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link underline={'hover'} href={'/accounting'}>
          Accounting Home
        </Link>
        <Typography color="text.primary">Pending Vendors</Typography>
      </Breadcrumbs>
      <MaterialTable
        title={'Pending Vendors'}
        columns={columns}
        data={vendors}
        options={{ paging: false }}
        onRowClick={(e, rowData) => history.push(`/accounting/pending-vendors/${rowData.id}`)}
      />
    </div>
  );
};

export default PendingVendors;
