/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { CardHeader, TextField, Card, Grid, FormControlLabel, Switch, Typography, IconButton, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { fetchPrintGroups } from '../../../../../Stores/PrintGroups/Actions';
import { Delete, Save } from '@material-ui/icons';

const inputStyle = {
  padding: '10px',
};

const PrintReadyArtCard = ({ index, printReadyArt, printReadyImages, updatePrintReadyArt, deleteArt, saveArt }) => {
  const [printGroups, setPrintGroups] = useState([]);
  const [displayUrl, setDisplayUrl] = useState(null);

  useEffect(() => {
    fetchPrintGroups().then(setPrintGroups);
  }, []);

  useEffect(() => {
    if (printReadyArt?.file) {
      setDisplayUrl(URL.createObjectURL(printReadyArt.file));
    }
  }, [printReadyArt]);

  return (
    <Card
      style={{
        width: '345px',
        height: '800px',
        position: 'relative',
        border: '2px solid rgba(0,0,0,0)',
      }}
    >
      <CardHeader
        title={
          <>
            {printGroups && (
              <Autocomplete
                id="print-group"
                style={{ paddingBottom: '8px' }}
                value={printReadyArt?.printGroup}
                options={printGroups}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updatePrintReadyArt(index, 'printGroup', v);
                }}
                renderInput={(params) => <TextField {...params} label="Price List" variant="outlined" />}
              />
            )}
          </>
        }
        subheader={
          <>
            {printReadyArt?.printGroup?.printTypes && (
              <Autocomplete
                id="print-type"
                value={printReadyArt?.printType}
                options={printReadyArt?.printGroup?.printTypes}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) => {
                  updatePrintReadyArt(index, 'printType', v);
                }}
                renderInput={(params) => <TextField {...params} label="Print Type" variant="outlined" />}
              />
            )}
          </>
        }
      />
      <Grid container style={inputStyle}>
        <Autocomplete
          fullWidth
          value={printReadyArt?.file}
          style={{ padding: '8px' }}
          onChange={(e, v) => updatePrintReadyArt(index, 'file', v)}
          getOptionLabel={(o) => o?.name}
          renderOption={(o) => {
            const url = URL.createObjectURL(o);
            return (
              <div>
                <Grid>{o?.name}</Grid>
                {o?.type !== 'application/pdf' && (
                  <Grid>
                    <img height="200px" id={`${o?.name}`} src={url} />
                  </Grid>
                )}
              </div>
            );
          }}
          options={printReadyImages}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              style={{ width: '100%' }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label="Print Ready Art"
            />
          )}
        />
      </Grid>
      {printReadyArt?.file?.type?.includes('image') ? (
        <Grid
          style={{
            height: '300px',
            display: 'inline-block',
            textAlign: 'center',
            verticalAlign: 'middle',
          }}
          container
          justify="center"
        >
          <Grid item>
            <img
              style={{
                maxHeight: '300px',
                verticalAlign: 'middle',
                maxWidth: '300px',
              }}
              src={displayUrl}
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            width: '100%',
            height: '350px',
          }}
        >
          <img
            style={{
              width: '100%',
              marginTop: '50px',
            }}
            src="https://firebasestorage.googleapis.com/v0/b/yba-shirts.appspot.com/o/Screen%20Shot%202019-10-24%20at%201.45.48%20PM.png?alt=media&token=8c2a2860-3387-4c2b-a194-b814c2206a08"
          />
        </div>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Name"
            onChange={(event) => updatePrintReadyArt(index, 'name', event.target.value)}
            value={printReadyArt.name}
          />
        </Grid>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Position"
            onChange={(event) => updatePrintReadyArt(index, 'position', event.target.value)}
            value={printReadyArt?.position ? printReadyArt?.position : ''}
          />
        </Grid>
        <Grid item xs={12} style={inputStyle}>
          <TextField
            style={{ width: '100%' }}
            label="Ink Color"
            onChange={(event) => updatePrintReadyArt(index, 'color', event.target.value)}
            value={printReadyArt?.color ? printReadyArt?.color : ''}
          />
        </Grid>
        {Boolean(printReadyArt?.printType?.number) && (
          <Grid item xs={12} style={inputStyle}>
            <TextField
              style={{ width: '100%' }}
              label="1-15 or 1,2,3"
              onChange={(event) => updatePrintReadyArt(index, 'numbers', event.target.value)}
              value={printReadyArt?.numbers ? printReadyArt?.numbers : ''}
            />
          </Grid>
        )}
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                id="reversible"
                size="small"
                checked={Boolean(printReadyArt?.reversible)}
                onChange={(event) => updatePrintReadyArt(index, 'reversible', !printReadyArt?.reversible)}
                value={Boolean(printReadyArt?.reversible)}
                color="primary"
              />
            }
            label={<Typography style={{ fontSize: 'smaller' }}>Reversible</Typography>}
            labelPlacement="bottom"
          />
        </Grid>
        {Boolean(printReadyArt?.printGroup?.type === 'screenPrint') && (
          <Grid individualArt xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="underlay"
                  size="small"
                  checked={Boolean(printReadyArt?.underlay)}
                  onChange={(event) => updatePrintReadyArt(index, 'underlay', !printReadyArt?.underlay)}
                  value={Boolean(printReadyArt?.underlay)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>Underlay</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
        {Boolean(printReadyArt?.printGroup?.type === 'heatPress') && (
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="medWeed"
                  size="small"
                  checked={Boolean(printReadyArt?.medWeed)}
                  onChange={(event) => updatePrintReadyArt(index, 'medWeed', !printReadyArt?.medWeed)}
                  value={Boolean(printReadyArt?.medWeed)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>M-Weed</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
        {Boolean(printReadyArt?.printGroup?.type === 'heatPress') && (
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  id="hardWeed"
                  size="small"
                  checked={Boolean(printReadyArt?.hardWeed)}
                  onChange={(event) => updatePrintReadyArt(index, 'hardWeed', !printReadyArt?.hardWeed)}
                  value={Boolean(printReadyArt?.hardWeed)}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: 'smaller' }}>H-Weed</Typography>}
              labelPlacement="bottom"
            />
          </Grid>
        )}
        <Grid item justify="flex-end">
          <Tooltip title="Delete">
            <IconButton color="secondary" size="small" onClick={() => deleteArt(printReadyArt.uid)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item justify="flex-end">
          <Tooltip title="Save">
            <IconButton color="secondary" size="small" onClick={() => saveArt(printReadyArt.uid)}>
              <Save fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
};

PropTypes.propTypes = {
  index: PropTypes.number,
  printReadyArt: PropTypes.object,
  printReadyImages: PropTypes.object,
  updatePrintReadyArt: PropTypes.func,
  deleteArt: PropTypes.func,
};

export default PrintReadyArtCard;
