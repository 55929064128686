/* eslint no-plusplus:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import Day from '../../../Components/WorkOrder/Footer/Components/Production/Calendar/Day';
import Menu from '../../../Components/Menu';
import PastDueWorkOrders from '../../../Components/WorkOrder/Footer/Components/Production/Calendar/PastDueWorkOrders';

class Calendar extends Component {
  constructor(props) {
    super(props);
    const date = new Date();

    this.state = {
      today: date,
    };
  }

  render() {
    const days = [];
    days.push(<PastDueWorkOrders homepage={this.props.homepage} get={this.props.get} post={this.props.post} date="Overdue Work Orders" />);
    const date = this.state.today;
    for (let i = 0; i < 32; i++) {
      days.push(<Day homepage={this.props.homepage} get={this.props.get} post={this.props.post} date={date.toDateString()} />);
      date.setDate(date.getDate() + 1);
    }
    return (
      <div>
        <div style={{ backgroundColor: '#f5f5f5' }}>{days}</div>
      </div>
    );
  }
}

export default Calendar;
