import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from '../../../../Components/WorkOrder/Footer/Components/Production/Summary/Header';
import Art from '../../../../Components/WorkOrder/Footer/Components/Production/Summary/Art';
import Fold from '../../../../Components/WorkOrder/Footer/Components/Production/Summary/Fold';
import Bag from '../../../../Components/WorkOrder/Footer/Components/Production/Summary/Bag';
import { get } from '../../../../Functions/API';
import '../../../../Components/WorkOrder/Footer/Components/Production/Summary/print.css';

const Summary = ({ WorkOrderId }) => {
  const [workOrder, setWorkOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [workOrderArt, setWorkOrderArt] = useState([]);

  useEffect(() => {
    get(`/workorder/${WorkOrderId}`)
      .then((results) => {
        setWorkOrder(results);
      })
      .catch((err) => {
        console.error(err);
      });
    get('/getOrders', [
      { name: 'workOrderID', value: WorkOrderId },
      { name: 'page', value: 0 },
    ])
      .then((returned) => {
        setProducts(returned.data);
      })
      .catch((err) => {
        console.log('Error', err.message);
      });

    get(`/workOrderArt`, { WorkOrderId }, 'obj').then((results) => {
      setWorkOrderArt(results);
    });
  }, []);
  return (
    <div>
      {workOrder.id &&
        products.length &&
        products
          .filter((p) => p.chips.length)
          .map((product, key) => (
            <div key={key} className={'print-page-break'}>
              <Header workOrder={workOrder} product={product} />
              <Art workOrderArt={workOrderArt} product={product} />
              {/*<Fold />*/}
              {/*<Bag />*/}
            </div>
          ))}
    </div>
  );
};

export default Summary;

Summary.propTypes = {
  WorkOrderId: PropTypes.number,
};
