/* eslint-disable */
/* eslint no-unused-vars:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
import React, { Component, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Checkbox } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const Bugs = ({ post, get }) => {
  const [bugs, setBugs] = useState([]);
  // const [columns, setColumns] = useState();
  const columns = [
    {
      title: 'Completed',
      field: 'completed',
      render: (rowData) => (
          <Checkbox checked={Boolean(rowData.completed)} value={Boolean(rowData.completed)} onChange={() => completeBug(rowData)} />
      ),
    },
    {
      title: 'User',
      render: (rowData) => (
          <div>
            {rowData.firstName} {rowData.lastName}
          </div>
      ),
    },
    { title: 'Bug', field: 'bug' },
    {
      title: 'Submitted @',
      field: 'timestamp',
      render: (rowData) => {
        const date = new Date(rowData.timestamp);
        return date.toDateString();
      },
    },
  ];

  useEffect(() => {
    get('/bugs').then((results) => {
      setBugs(results);
    });
  }, []);

  const completeBug = (bug) => {
    post('/completeBug', bug).then((results) => {
      const newBugs = [...bugs];
      const b = newBugs.find((b) => b.id === bug.id);
      b.completed = results.completed;
      setBugs(newBugs);
    });
  };

  return (
    <Paper>
      <MaterialTable title="Bugs" columns={columns} data={bugs} />
    </Paper>
  );
};

export default Bugs;
