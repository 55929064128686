import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';
import Store from './Store';

export const loadWorkOrder = (id) => {
  get(`/workorder/${id}`)
    .then((workOrder) => {
      dispatcher.dispatch({
        type: 'LOAD_WORK_ORDER',
        workOrder,
      });
    })
    .catch((err) => {
      createSnack('Error', err?.message);
    });
};

export const fetchWorkOrder = async (id) => {
  const currentWorkOrder = Store.getWorkOrder();
  if (currentWorkOrder?.id) {
    return currentWorkOrder;
  } else {
    try {
      const workOrder = await get(`/workorder/${id}`);
      dispatcher.dispatch({
        type: 'LOAD_WORK_ORDER',
        workOrder,
      });
      return workOrder;
    } catch (err) {
      createSnack('Error', err.message);
      return currentWorkOrder;
    }
  }
};

export const updateWorkOrder = (id, body) => {
  return patch(`/workorder/${id}`, body)
    .then((workOrder) => {
      createSnack('Success', `Updated ${Object.keys(body).join(' and ')}`);
      dispatcher.dispatch({
        type: 'LOAD_WORK_ORDER',
        workOrder,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateDueDate = (id, body) => {
  patch(`/workorder/${id}/dueDate`, body)
    .then((workOrder) => {
      createSnack('Success', `Updated ${Object.keys(body).join(' and ')}`);
      dispatcher.dispatch({
        type: 'LOAD_WORK_ORDER',
        workOrder,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateInvoiceDate = (id, body) => {
  console.log(id, body);
  patch(`/workorder/${id}/invoiceDate`, body)
    .then((workOrder) => {
      createSnack('Success', `Updated ${Object.keys(body).join(' and ')}`);
      dispatcher.dispatch({
        type: 'LOAD_WORK_ORDER',
        workOrder,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateCustomer = async (id, customerID) => {
  try {
    const workOrder = await patch(`/workorder/${id}/customer`, { customerID });
    createSnack('Success', `Updated Customer`);
    dispatcher.dispatch({
      type: 'LOAD_WORK_ORDER',
      workOrder,
    });
    return workOrder;
  } catch (err) {
    createSnack('Error', err.message);
    return err;
  }
};

export const getWorkOrders = (filter) => {
  return get(`/workorder`, { filter });
};
