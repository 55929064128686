import { get, post, remove } from '../../../../Functions/API';
import WorkOrderStore from '../../../WorkOrder/Store';
import { checkQBURL } from '../index';
import { createSnack } from '../../../Snack/Actions';

export const createBill = (body) => {
  return post(`/quickbooks/bills`, body)
    .then((results) => {
      createSnack('Success', 'Bill Created in Quickbooks.');
      return { results };
    })
    .catch(checkQBURL);
};
export const deleteBill = (id) => {
  return remove(`/quickbooks/bills/${id}`)
    .then((results) => {
      if (results.message === 'paid') {
        createSnack('Warning', "This bill has a payment connected to it and can't be deleted.");
      } else {
        createSnack('Success', 'The bill has been deleted from Quickbooks.');
      }
      return results;
    })
    .catch(checkQBURL);
};
