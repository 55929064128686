module.exports = {
  Accounting: '#87C38F',
  Success: '#87C38F',
  Sales: '#F9C80E',
  Warning: '#F9C80E',
  Error: '#EA3546',
  Info: '#899878',
  Picture: '#EAEAEA',
  Art: '#F86624',
  Fulfillment: '#BB91F2',
  Shipping: '#43BCCD',
  Production: '#979797',
  File: '#EAEAEA',
  Temp: '#F2E9DC',
  Admin: '#CFD11A',
};
