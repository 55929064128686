/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { get } from '../../Functions/API';

const NES = ({ user, match }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [workOrder, setWorkOrder] = useState({});
  const { workOrderID } = match.params;
  const [columns, setColumns] = useState([
    {
      title: 'Adult Style',
      field: 'adultStyle',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      title: 'Youth Style',
      field: 'youthStyle',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      title: 'Color',
      field: 'productColor',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      title: 'Type',
      field: 'type',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      title: 'Position',
      field: 'position',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    {
      title: 'Thread Color',
      field: 'threadColor',
      cellStyle: {
        fontSize: '15px',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
      headerStyle: {
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  ]);

  useEffect(() => {
    get('/getSizeOptions', { workOrderID }, 'obj')
      .then((results) => {
        const sizes = results.map((size) => {
          return {
            title: `${size}`,
            field: `${size}`,
            cellStyle: {
              fontSize: '15px',
              textAlign: 'center',
              paddingLeft: 0,
              paddingRight: 0,
            },
            headerStyle: {
              fontSize: '15px',
              textAlign: 'center',
              paddingLeft: 0,
              paddingRight: 0,
            },
          };
        });
        setColumns([
          ...columns,
          ...sizes,
          {
            title: 'Total',
            field: 'total',
            cellStyle: {
              fontSize: '15px',
              backgroundColor: '#EEE',
              textAlign: 'center',
              paddingLeft: 0,
              paddingRight: 0,
            },
            headerStyle: {
              fontSize: '15px',
              fontWeight: 'bold',
              textAlign: 'center',
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        ]);
      })
      .catch((err) => {
        console.error('Error:', err);
      });

    get(`/workorder/${workOrderID}`)
      .then((results) => {
        setWorkOrder(results);
      })
      .catch((err) => {
        console.error('Error:', err);
      });

    get('/getNESData', { workOrderID }, 'obj')
      .then((results) => {
        console.log('NES DATA:', results);
        const totals = {
          threadColor: 'Total:',
        };
        const dontSum = ['ordersID', 'type', 'youthStyle', 'adultStyle', 'productionColor', 'position', 'printType', 'threadColor'];
        results.forEach((result) => {
          for (const key in result) {
            if (Number(result[key]) && dontSum.indexOf(key) === -1) {
              if (!totals[key]) {
                totals[key] = 0;
              }
              totals[key] += result[key];
            }
          }
        });
        setData([...results, totals]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error', err);
      });
  }, []);

  const convertDate = (date) => {
    var newDate = new Date(date);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var result = new Date(newDate).toLocaleTimeString('en-US', options);
    result = result.split(',');
    result.pop();
    result = result.join(',');
    return result;
  };

  return (
    <div style={{ padding: '5px', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Card style={{ margin: 'auto', width: '99%' }}>
        <CardHeader title={`Work Order: ${workOrder.workOrderID}`} />
        <CardContent>
          <Grid container spacing={2} justify={'space-evenly'} alignItems={'stretch'}>
            <Grid item xs>
              <Card style={{ height: '100%' }}>
                <CardHeader title={'Customer'} />
                <CardContent>
                  <div>{`${workOrder.customer}`}</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card style={{ height: '100%' }}>
                <CardHeader title={'Shipping'} />
                <CardContent>
                  <div>{`${workOrder.shippingStreet}, ${workOrder.shippingCity}, ${workOrder.shippingState}, ${workOrder.shippingZip}`}</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card style={{ height: '100%' }}>
                <CardHeader title={'DUE DATE'} />
                <CardContent>
                  <div>{`${convertDate(workOrder.dueDate)}`}</div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <MaterialTable
            title={`PO: ${workOrder.workOrderID}`}
            columns={columns}
            data={data}
            isLoading={loading}
            options={{
              exportButton: true,
              padding: 'dense',
              paging: false,
              search: false,
              sorting: false,
              rowStyle: (rowData) => ({
                backgroundColor: rowData.threadColor === 'Total:' ? '#EEE' : 'default',
              }),
            }}
            style={{ width: '100%' }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default NES;
