import React, { useState, useEffect } from 'react';
import { MTableToolbar } from 'material-table';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import UserStore from '../../../../../Stores/User/Store';
import { get, post } from '../../../../../Functions/API.js';
import PropTypes from 'prop-types';

const CreditMemoHeader = ({ workOrderID, props, creditMemoQBId, createCreditMemo, deleteCreditMemo }) => {
  const [creditMemoId, setCreditMemoId] = useState(0);
  const [privateNote, setPrivateNote] = useState('');
  const [customerMemo, setCustomerMemo] = useState('');
  const [saved, setSaved] = useState(true);
  const user = UserStore.getUser();

  useEffect(() => {
    get('/getCreditMemo', [{ name: 'workOrderID', value: workOrderID }])
      .then((results) => {
        if (results) {
          setCreditMemoId(results.id);
          setPrivateNote(results.privateNote);
          setCustomerMemo(results.customerMemo);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  const updateMSASCreditMemo = () => {
    const params = {
      workOrderID,
      creditMemoId,
      privateNote,
      customerMemo,
    };
    post('/updateMSASCreditMemo', params)
      .then(() => {
        setSaved(true);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div style={{ width: 'calc(100% - 8px)' }}>
      <MTableToolbar {...props} />
      {/* TODO editCreditMemoCustomerViewedFields */}
      {(user.group === 'Admin' || user.group.includes('Accounting')) && (
        <Grid container spacing={1} style={{ paddingLeft: '25px' }}>
          <Grid item>
            <TextField
              label="Private Note"
              value={privateNote}
              multiline
              error={!saved}
              onChange={(e) => {
                if (!creditMemoQBId) {
                  setPrivateNote(e.target.value);
                  setSaved(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Customer Memo"
              value={customerMemo}
              multiline
              error={!saved}
              onChange={(e) => {
                if (!creditMemoQBId) {
                  setCustomerMemo(e.target.value);
                  setSaved(false);
                }
              }}
            />
          </Grid>
          {creditMemoQBId ? (
            <Grid item>
              <Button size="small" variant="contained" onClick={deleteCreditMemo}>
                Delete QB CM
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Button variant="contained" color="primary" size="small" onClick={updateMSASCreditMemo}>
                  Update MSAS CM
                </Button>
              </Grid>
              <Grid item>
                <Button size="small" variant="contained" color="secondary" onClick={createCreditMemo}>
                  Create QB CM
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </div>
  );
};

export default CreditMemoHeader;

CreditMemoHeader.propTypes = {
  workOrderID: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  creditMemoQBId: PropTypes.object.isRequired,
  createCreditMemo: PropTypes.object.isRequired,
  deleteCreditMemo: PropTypes.object.isRequired,
};
