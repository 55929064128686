/* eslint react/display-name:0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, IconButton, TextField } from '@material-ui/core';
import { get } from '../../Functions/API';
import { convertDateToSQLDate } from '../Utils/SqlDate';

const AtTime = ({ match }) => {
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [inventory, setInventory] = useState({});
  const { inventoryId } = match.params;

  useEffect(() => {
    get(`/inventory/${inventoryId}`).then((results) => setInventory(results));
  }, []);

  useEffect(() => {
    getData();
  }, [date]);

  const getData = async () => {
    let ps;
    await get('/allPotentialSizes').then((results) => {
      ps = results;
    });
    get('/inventory/atTime', { inventoryId, date: convertDateToSQLDate(date) }, 'obj').then((results) => {
      let sizes = [];
      const columns = [
        {
          title: 'SKU',
          field: 'sku',
          editable: 'never',
        },
        { title: 'Name', field: 'name', editable: 'never' },
      ];
      for (const result of results) {
        result.sizes = JSON.parse(result.sizes);
        for (const size of result.sizes) {
          const found = sizes.find((row) => row.title === size.size);
          if (!found) {
            sizes.push({
              title: size.size,
              field: size.size,
              render: (rowData) => {
                const found = rowData.sizes.find((row) => row.size === size.size);
                if (found) {
                  return found.quantity;
                }
                return '';
              },
              editComponent: (t) => {
                const found = t.rowData.sizes.find((row) => row.size === size.size);
                return found ? (
                  <TextField
                    type="number"
                    value={found.quantity}
                    onChange={(e) => t.onRowDataChange(editQuantity(e.target.value, t.rowData, found))}
                  />
                ) : (
                  <TextField value="N/A" disabled helperText="Size not included in SKU" />
                );
              },
            });
          }
        }
      }
      sizes = sortSizesStart(sizes, ps);
      columns.push({
        title: 'Total',
        render: (rowData) => {
          return rowData.sizes.reduce((total, row) => total + row.quantity, 0);
        },
      });
      setColumns([...columns, ...sizes]);
      setData(results);
    });
  };

  const sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.title);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.title);
      if (potentialSizeA && potentialSizeB) {
        const indexA = potentialSizes.indexOf(potentialSizeA);
        const indexB = potentialSizes.indexOf(potentialSizeB);
        return indexA - indexB;
      }
      return 1;
    });
  };

  return (
    <div>
      <MaterialTable
        title={inventory?.name}
        columns={columns}
        data={data}
        options={{
          pageSize: 20,
          exportButton: true,
          exportCsv: (columns, data) => {
            let csvContent = 'data:text/csv;charset=utf-8,';
            let headers = 'SKU,Name,';
            const sizeHeaders = columns.reduce((arr, row) => {
              if (columns.indexOf(row) > 2) {
                arr.push(row.title);
              }
              return arr;
            }, []);
            headers += sizeHeaders.join(',');
            csvContent += `${headers}\r\n`;
            for (const row of data) {
              csvContent += `${row.sku},`;
              csvContent += `${row.name},`;
              const sizes = [];
              for (const sizeHeader of sizeHeaders) {
                const size = row.sizes.find((row) => row.size === sizeHeader);
                if (size) {
                  sizes.push(size.quantity);
                } else if (sizeHeader === 'Total') {
                  sizes.push(row.sizes.reduce((total, row) => total + row.quantity, 0));
                } else {
                  sizes.push('');
                }
              }
              csvContent += `${sizes.join(',')}\r\n`;
            }
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${inventory.name} at ${date.toDateString()} ${date.toTimeString()}.csv`);
            document.body.appendChild(link);
            link.click();
          },
        }}
        components={{
          // eslint-disable-next-line react/display-name
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ paddingLeft: '10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker value={date} onChange={setDate} />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default AtTime;

AtTime.propTypes = {
  match: PropTypes.object,
};
