import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { get } from '../../../../../../Functions/API';
import { createSnack } from '../../../../../../Stores/Snack/Actions';
import { AttachMoney, Save } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Store from '../../../../../../Stores/Sales/Store';
import { getSales, updateSales } from '../../../../../../Stores/Sales/Actions';
import { createDialog } from '../../../../../../Stores/Dialog/Actions';
import AssignCommissions from './AssignCommissions';

const Sales = ({ workOrder, editable = true }) => {
  const [salesmen, setSalesmen] = useState([]);
  const [sales, setSales] = useState([]);
  const [savedSales, setSavedSales] = useState([]);
  const [assignedCommissionSales, setAssignedCommissionSales] = useState([]);

  useEffect(() => {
    if (workOrder.id) {
      Store.on('change', update);
      getSales(workOrder.id);
      get('/user/group', { group: 'Sales' }, 'obj')
        .then((results) => {
          setSalesmen(results);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
    return () => {
      Store.removeListener('change', update);
    };
  }, [workOrder.id]);

  const update = () => {
    const s = Store.getSales();
    setAssignedCommissionSales([...s]);
    const uids = s.map((r) => r.userID);
    setSales(uids);
    setSavedSales(uids);
  };

  const checkSales = () => {
    if (sales === savedSales) return true;
    if (sales == null || savedSales == null) return false;
    if (sales.length !== savedSales.length) return false;
    for (let i = 0; i < sales.length; ++i) {
      if (!savedSales.find((c) => c === sales[i])) return false;
    }
    return true;
  };

  const openCommissionDialog = (s, sacs, sm) => {
    createDialog({
      title: 'Assign Commissions',
      content: <AssignCommissions sales={s} setAssignedCommissionSales={sacs} salesmen={sm} />,
      size: 'md',
    });
  };

  return (
    <FormControl style={{ width: '100%' }} component="div">
      <InputLabel shrink htmlFor="sales">
        Sales
      </InputLabel>
      <Select
        onChange={(e) => setSales(e.target.value)}
        multiple
        disabled={!editable}
        value={sales}
        renderValue={(selected) => {
          const returnValue = [];
          for (const uid of selected) {
            const salesman = salesmen.find((salesman) => uid === salesman.uid);
            if (salesman) {
              returnValue.push(`${salesman.firstName} ${salesman.lastName}`);
            }
          }
          return returnValue.join(', ');
        }}
        input={
          <Input
            name="sales"
            id="sales"
            error={!checkSales()}
            startAdornment={
              !checkSales() ? (
                <IconButton
                  size={'small'}
                  onClick={() => {
                    updateSales(workOrder.id, sales).then((results) => {
                      if (sales.length > 1) openCommissionDialog(sales, setAssignedCommissionSales, salesmen);
                    });
                  }}
                >
                  <Save fontSize={'small'} color={'secondary'} />
                </IconButton>
              ) : (
                checkSales() &&
                sales.length > 1 && (
                  <IconButton size={'small'} onClick={() => openCommissionDialog(sales, setAssignedCommissionSales, salesmen)}>
                    <AttachMoney fontSize={'small'} color={assignedCommissionSales.find((s) => s.assignedCommission) ? 'primary' : 'secondary'} />
                  </IconButton>
                )
              )
            }
          />
        }
      >
        {salesmen.map((salesman, key) => (
          <MenuItem key={key} value={salesman.uid}>
            <ListItemText primary={`${salesman.firstName} ${salesman.lastName}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Sales;

Sales.propTypes = {
  workOrder: PropTypes.object,
  editable: PropTypes.bool,
};
