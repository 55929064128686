/* eslint no-nested-ternary:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    elevation: 2,
  },
}));

const EcomParcelTotals = ({ invoiceNumber, costTotals, addCostToQuickBooks, compsAlreadyWithBill, isCSVData }) => {
  const classes = useStyles();
  return (
    <div className={`ups-totals ${classes.root}`}>
      <Grid className="ups-totals-grid-container" container spacing={2}>
        <Grid item xs={12}>
          <p className="ups-totals-header">
            {'EcomParcel Cost Home for Invoice: '}
            {invoiceNumber}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total EcomParcel Cost:</p>
            <p className="ups-total-money-total">${costTotals.EcomParcelCost}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total YBA Cost:</p>
            <p className="ups-total-money">${costTotals.YBACost}</p>
            {isCSVData ? (
              compsAlreadyWithBill.includes('YBA') ? (
                <p>Already added to QuickBooks</p>
              ) : (
                <Button onClick={() => addCostToQuickBooks('YBA', costTotals.YBACost)} variant="contained" color="primary">
                  Add Cost to QuickBooks
                </Button>
              )
            ) : (
              <p> Data selected is not for EcomParcel </p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total Elite Cost:</p>
            <p className="ups-total-money">${costTotals.EliteCost}</p>
            {isCSVData ? (
              compsAlreadyWithBill.includes('Elite') ? (
                <p>Already added to QuickBooks</p>
              ) : (
                <Button onClick={() => addCostToQuickBooks('Elite', costTotals.EliteCost)} variant="contained" color="primary">
                  Add Cost to QuickBooks
                </Button>
              )
            ) : (
              <p> Data selected is not for EcomParcel </p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total R&S Cost:</p>
            <p className="ups-total-money">${costTotals.RSCost}</p>
            {isCSVData ? (
              compsAlreadyWithBill.includes('R&S') ? (
                <p>Already added to QuickBooks</p>
              ) : (
                <Button onClick={() => addCostToQuickBooks('R&S', costTotals.RSCost)} variant="contained" color="primary">
                  Add Cost to QuickBooks
                </Button>
              )
            ) : (
              <p> Data selected is not for EcomParcel </p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total CSP Cost:</p>
            <p className="ups-total-money">${costTotals.CSPCost}</p>
            {isCSVData ? (
              compsAlreadyWithBill.includes('CSP') ? (
                <p>Already added to QuickBooks</p>
              ) : (
                <Button onClick={() => addCostToQuickBooks('CSP', costTotals.CSPCost)} variant="contained" color="primary">
                  Add Cost to QuickBooks
                </Button>
              )
            ) : (
              <p> Data selected is not for EcomParcel </p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Total SMTT Cost:</p>
            <p className="ups-total-money">${costTotals.SMTTCost}</p>
            {isCSVData ? (
              compsAlreadyWithBill.includes('SMTT') ? (
                <p>Already added to QuickBooks</p>
              ) : (
                <Button onClick={() => addCostToQuickBooks('SMTT', costTotals.SMTTCost)} variant="contained" color="primary">
                  Add Cost to QuickBooks
                </Button>
              )
            ) : (
              <p> Data selected is not for EcomParcel </p>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <p className="ups-totals-sub-header">Difference</p>
            <p className="ups-total-money" style={{ color: 'red' }}>
              $
              {(
                costTotals.EcomParcelCost -
                costTotals.YBACost -
                costTotals.EliteCost -
                costTotals.RSCost -
                costTotals.CSPCost -
                costTotals.SMTTCost
              )?.toFixed(2)}
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EcomParcelTotals;
