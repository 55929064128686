const materialColors = [
  '#f44336',
  '#9c27b0',
  '#3f51b5',
  '#03a9f4',
  '#009688',
  '#8bc34a',
  '#ffeb3b',
  '#ff9800',
  '#e91e63',
  '#673ab7',
  '#2196f3',
  '#00bcd4',
  '#4caf50',
  '#cddc39',
  '#ffc107',
  '#ff5722',
  '#e57373',
  '#ba68c8',
  '#7986cb',
  '#4fc3f7',
  '#4db6ac',
  '#aed581',
  '#fff176',
  '#ffb74d',
  '#f06292',
  '#9575cd',
  '#64b5f6',
  '#4dd0e1',
  '#81c784',
  '#dce775',
  '#ffd54f',
  '#ff8a65',
];

export default materialColors;
