import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FormControl, InputLabel, NativeSelect, Input } from '@material-ui/core';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';

const SalesType = ({ workOrder }) => {
  const [salesTypes, setSalesTypes] = useState([
    { name: 'Basketball', value: 'Basketball' },
    { name: 'Football', value: 'Football' },
    { name: 'Baseball', value: 'Baseball' },
    { name: 'Soccer', value: 'Soccer' },
    { name: 'Volleyball', value: 'Volleyball' },
    { name: 'Lacrosse', value: 'Lacrosse' },
    { name: 'Multi-Sport', value: 'Multi-Sport' },
    { name: 'Camps', value: 'Camps' },
    { name: 'Corporate', value: 'Corporate' },
    { name: 'School', value: 'School' },
    { name: 'E-Commerce', value: 'E-Commerce' },
    { name: 'Other', value: 'Other' },
  ]);
  return (
    <FormControl style={{ margin: '10px', width: '100%' }} component="div">
      <InputLabel shrink htmlFor="salesType">
        Sales Type
      </InputLabel>
      <NativeSelect
        value={workOrder.salesType || ''}
        onChange={(e) => updateWorkOrder(workOrder.id, { salesType: e.target.value })}
        input={<Input name="salesType" id="salesType" />}
      >
        <option value="">None</option>
        {salesTypes.map((method, key) => (
          <option key={key} value={method.value}>
            {method.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default SalesType;

SalesType.propTypes = {
  workOrder: PropTypes.object,
};
