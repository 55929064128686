import React, { useState, useEffect, createRef } from 'react';
import MaterialTable from 'material-table';
import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import { get } from '../../../Functions/API.js';
import { useParams } from 'react-router-dom';
import { createDialog } from '../../../Stores/Dialog/Actions.js';
import { updateCost } from '../../../Stores/Costing/Actions.js';
import EcomParcelTotals from '../../../Components/CustomViews/EcomParcel/EcomParcelTotals.js';
import { getWorkOrders } from '../../../Stores/WorkOrder/Actions.js';
import { createSnack } from '../../../Stores/Snack/Actions.js';
import { Breadcrumbs, Typography, Link } from '@mui/material';

const ShippingUpload = () => {
  const { id } = useParams();
  const tableRef = createRef();
  const [costTotals, setCostTotals] = useState({});
  const [title, setTitle] = useState('');

  const showDetails = (body) => {
    createDialog({
      title: 'Line Details',
      content: (
        <Grid container spacing={1}>
          {Object.keys(body)
            .filter((k) => body[k])
            .map((k, key) => (
              <Grid item key={key} xs={2}>
                <TextField fullWidth label={k} value={body[k]} />
              </Grid>
            ))}
        </Grid>
      ),
    });
  };

  useEffect(() => {
    loadTotals(id);
  }, [id]);

  const loadTotals = (id) => {
    get(`/shippingUploads/costSummary/${id}`).then((results) => {
      const EcomParcelCost = results.reduce((total, r) => total + r.sum, 0)?.toFixed(2);
      const totals = { EcomParcelCost, YBACost: 0, EliteCost: 0, RSCost: 0, CSPCost: 0, SMTTCost: 0 };
      const match = { YBACost: 'YBA', EliteCost: 'Elite', RSCost: 'R&S', CSPCost: 'CSP', SMTTCost: 'SMTT' };
      results.map((r) => {
        const key = Object.keys(match).find((key) => match[key] === r.company);
        if (key) totals[key] = r.sum?.toFixed(2);
      });
      setCostTotals(totals);
    });
  };

  const refreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange();
    loadTotals(id);
  };

  return (
    <div>
      <Breadcrumbs>
        <Link underline={'hover'} href={'/accounting'}>
          Accounting Home
        </Link>
        <Link underline={'hover'} href={'/accounting/shipping-uploads'}>
          Shipping Uploads
        </Link>
        <Typography color="text.primary">{id}</Typography>
      </Breadcrumbs>
      <MaterialTable
        tableRef={tableRef}
        title={title}
        columns={[
          {
            title: 'Work Order ID',
            field: 'workOrder.workOrderID',
          },
          {
            title: 'Customer',
            field: 'workOrder.customerName',
          },
          {
            title: 'Company',
            field: 'company',
            lookup: {
              YBA: 'YBA',
              Elite: 'Elite',
              'R&S': 'R&S',
              CSP: 'CSP',
              SMTT: 'SMTT',
            },
          },
          {
            title: 'Tracking Number',
            field: 'trackingNumber',
            editable: 'never',
            render: (rowData) => {
              if (rowData && rowData.trackingNumber) {
                return (
                  <div className="tracking-number-container">
                    {rowData.trackingNumber}
                    {rowData.shippingDetail?.body && (
                      <Tooltip title="View More Details">
                        <IconButton size={'small'} onClick={() => showDetails(rowData.shippingDetail.body)}>
                          <SubjectIcon fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                );
              }
            },
          },
          {
            title: 'Shipping Cost',
            field: 'totalActualCost',
            align: 'left',
            type: 'currency',
          },
        ]}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            const { id, company, trackingNumber, totalActualCost, workOrder } = newData;
            console.log(workOrder);
            const update = { id, company, trackingNumber, totalActualCost };
            const filter = {
              eager: {
                $where: {
                  workOrderID: workOrder.workOrderID,
                },
              },
            };
            const [wo] = await getWorkOrders(filter).then(({ results }) => results);
            console.log(wo);
            if (wo) {
              update.workOrderID = wo.id;
              updateCost(update).then((results) => {
                refreshTable();
                createSnack('Success', 'Updated Cost');
              });
            } else {
              createSnack('Error', 'Work order not found');
            }
          },
        }}
        data={async (query) => {
          console.log(query);
          const { page, pageSize, orderBy: oB, orderDirection } = query;
          let orderBy = oB?.field;
          if (orderBy === 'workOrder.workOrderID') orderBy = 'workOrderID';
          const { results, total: totalCount } = await get(`/shippingUploads/${id}`, { page, pageSize, orderBy, orderDirection });
          const { costs: data, name, type } = results;
          setTitle(`${name} - ${type} Bill`);
          data.map((d) => (d.shippingDetail?.body ? (d.shippingDetail.body = JSON.parse(d.shippingDetail.body)) : console.log('No Body')));
          return {
            data,
            page,
            totalCount,
          };
        }}
        options={{ pageSize: 20, padding: 'dense', pageSizeOptions: [10, 20, 50], search: false }}
      />
      <EcomParcelTotals costTotals={costTotals} />
    </div>
  );
};

export default ShippingUpload;
