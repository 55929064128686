/* eslint-disable react/display-name */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unescaped-entities:0 */
/* eslint no-shadow:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Card, CardContent, CardHeader, Collapse, Divider, IconButton, Popover, Typography } from '@material-ui/core';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';

const CurrentlyClockedIn = ({ get, refresh }) => {
  const [clockInData, setClockInData] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(true);
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Clock In Time',
      field: 'clockIn',
      render: (rowData) => {
        return moment(rowData.clockIn).format('MMM D YYYY, h:MM A');
      },
    },
    {
      title: 'Time Elapsed',
      render: (rowData) => {
        const timeElasped = moment.duration(moment() - moment(rowData.clockIn));
        const hours = Math.floor(timeElasped.asHours());
        const minutes = Math.floor(timeElasped.asMinutes() - hours * 60);
        const seconds = Math.floor(timeElasped.asSeconds() - minutes * 60 - hours * 3600);
        return `${hours} Hours, ${minutes} Mins, ${seconds} Secs`;
      },
      sorting: false,
    },
  ];

  const getClockInData = () => {
    get('/getAllClockedInTimes')
      .then((rows) => {
        setClockInData(rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  useEffect(() => {
    getClockInData();
  }, [refresh]);

  return (
    <Card style={{ height: open ? '' : 'auto' }}>
      <CardHeader
        title={
          <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">Currently Clocked In</Typography>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        }
        action={
          <>
            <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
              <InfoIcon id="infoIcon" />
            </IconButton>
            <Popover
              open={infoOpen}
              anchorEl={anchorEl}
              onClose={openInfo}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                This table keeps track of all things that are clocked in, but haven't clocked out yet.
                <br />
                <br />
                Make sure you clock out when you are done with your task. If you forget, you can come to this list, find it, and click the suitcase to
                go to that work order and make your correction.
              </Typography>
            </Popover>
          </>
        }
      />
      <Divider />
      <Collapse in={open} timeout="auto">
        <CardContent style={{ paddingBottom: '16px' }}>
          {clockInData && (
            <MaterialTable
              data={clockInData}
              columns={columns}
              options={{
                search: false,
                showTitle: false,
                toolbar: false,
                loadingType: 'linear',
                rowStyle: {
                  fontSize: 14,
                },
                padding: 'dense',
                pageSizeOptions: [15],
                pageSize: 15,
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
              components={{
                Container: (props) => <div style={{ background: 'white' }}>{props.children}</div>,
              }}
              actions={[
                {
                  icon: () => <WorkOutlineRoundedIcon />,
                  tooltip: `View Work Order`,
                  onClick: (event, rowData) => (window.location.href = `workorder/${rowData.workOrderID}`),
                },
              ]}
            />
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CurrentlyClockedIn;
