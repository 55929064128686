import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.costs = [];
  }

  getCosts() {
    return this.costs;
  }

  loadCosts = (costs) => {
    this.costs = costs;
    this.emit('change');
  };

  newCost = (cost) => {
    this.costs.push(cost);
    this.emit('change');
  };

  updateCost = (cost) => {
    const index = this.costs.findIndex((c) => c.id === cost.id);
    this.costs[index] = cost;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_COSTS': {
        this.loadCosts(action.costs);
        break;
      }
      case 'NEW_COST': {
        this.newCost(action.cost);
        break;
      }
      case 'UPDATE_COST': {
        this.updateCost(action.cost);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
