/* eslint-disable react/jsx-key */
/* eslint react/no-array-index-key:0 */
/* eslint max-len:0 */
/* eslint no-plusplus:0 */
/* eslint no-shadow:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-return-await:0 */
/* eslint no-param-reassign:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Read from '@material-ui/icons/Mail';
import Unread from '@material-ui/icons/Drafts';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Launch } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      page: 0,
      pageSize: 50,
      total: 0,
      users: [],
      loaded: false,
      unread: false,
    };
  }

  componentDidMount() {
    this.props
      .get('/allUsers', [])
      .then((users) => {
        this.setState({
          users,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getNotifications(0, 50, null, 'desc', null, null).then((results) => {
      const notifications = results.data;
      const total = results.totalCount.count;
      this.setState({
        loaded: true,
        notifications,
        total,
      });
    });
  }

  getNotifications = async (page, pageSize, orderBy, orderDirection, search) => {
    if (!orderBy) {
      orderBy = { field: 'date' };
    }
    const parameters = [
      { name: 'uid', value: this.props.user.uid },
      { name: 'page', value: page },
      { name: 'pageSize', value: pageSize },
      { name: 'orderBy', value: orderBy.field },
      { name: 'orderDirection', value: orderDirection },
      { name: 'search', value: search },
      { name: 'unread', value: this.state.unread },
    ];
    this.setState({
      loaded: false,
    });
    return await this.props.get(`/getUserNotifications`, parameters).then((notifications) => {
      for (const notification of notifications.data) {
        notification.created_at = new Date(notification.created_at);
      }
      return notifications;
    });
  };

  nextPage = () => {
    const page = this.state.page + 1;
    this.setState({
      page,
      notifications: [],
    });
    this.getNotifications(page, this.state.pageSize, null, 'desc', null).then((results) => {
      const notifications = results.data;
      const total = results.totalCount.count;
      this.setState({
        loaded: true,
        notifications,
        total,
        page,
      });
    });
  };

  prevPage = () => {
    const page = this.state.page - 1;
    this.setState({
      page,
      notifications: [],
    });
    this.getNotifications(page, this.state.pageSize, null, 'desc', null).then((results) => {
      const notifications = results.data;
      const total = results.totalCount.count;
      this.setState({
        loaded: true,
        notifications,
        total,
        page,
      });
    });
  };

  firstPage = () => {
    const page = 0;
    this.setState({
      page,
      notifications: [],
    });
    this.getNotifications(page, this.state.pageSize, null, 'desc', null).then((results) => {
      const notifications = results.data;
      const total = results.totalCount.count;
      this.setState({
        loaded: true,
        notifications,
        total,
        page,
      });
    });
  };

  lastPage = () => {
    const page = Math.floor(this.state.total / this.state.pageSize);
    this.setState({
      page,
      notifications: [],
    });
    this.getNotifications(page, this.state.pageSize, null, 'desc', null).then((results) => {
      const notifications = results.data;
      const total = results.totalCount.count;
      this.setState({
        loaded: true,
        notifications,
        total,
        page,
      });
    });
  };

  getName = (uid) => {
    const user = this.state.users.find((user) => user.uid === uid);
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return '';
  };

  readNotification = (notification, url) => {
    const { notifications } = this.state;
    const index = notifications.indexOf(notification);
    this.props
      .post('/markAsRead', { notification, uid: this.props.user.uid, read: true })
      .then(() => {
        notifications[index].read = true;
        if (this.props.notificationCount) {
          let { notificationCount } = this.props;
          notificationCount--;
          this.props.setNotificationCount(notificationCount);
        }
        this.setState(
          {
            notifications,
          },
          () => {
            if (url) {
              console.log(notification);
              window.location.href = `/workorder/${notification.workOrderID}`;
            }
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  unreadNotification = (notification) => {
    const { notifications } = this.state;
    const index = notifications.indexOf(notification);
    this.props
      .post('/markAsRead', { notification, uid: this.props.user.uid, read: false })
      .then(() => {
        notifications[index].read = false;
        if (this.props.notificationCount) {
          let { notificationCount } = this.props;
          notificationCount++;
          this.props.setNotificationCount(notificationCount);
        }
        this.setState({
          notifications,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  markAllAsRead = () => {
    this.props
      .post('/markAllNotificationsAsRead', { uid: this.props.user.uid })
      .then(() => {
        this.props.setNotificationCount(0);
        this.getNotifications(this.state.page, this.state.pageSize, null, 'desc', null).then((results) => {
          const notifications = results.data;
          const total = results.totalCount.count;
          this.setState({
            loaded: true,
            notifications,
            total,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  viewUnread = () => {
    const { pageSize, unread } = this.state;
    this.setState(
      {
        unread: !unread,
      },
      () =>
        this.getNotifications(0, pageSize, null, 'desc', null).then((results) => {
          const notifications = results.data;
          const total = results.totalCount.count;
          this.setState({
            loaded: true,
            notifications,
            total,
          });
        }),
    );
  };

  goToWorkOrder = (notification) => {
    console.log(notification);
    if (!notification.read) {
      this.readNotification(notification, true);
    } else {
      window.location.href = `/workorder/${notification.workOrderID}`;
    }
  };

  render() {
    const buttonStyle = {
      margin: '8px',
      // float: 'right',
      zIndex: 2,
    };
    const columns = [
      { field: 'viewableID', title: 'ID' },
      { field: 'customerName', title: 'Customer' },
      {
        title: 'Notification',
        field: 'message',
        render: (rowData) => (
          <ListItem>
            <ListItemText
              primary={
                <div>
                  {/* <div style={{fontSize: 'large'}}>{rowData.customer}</div> */}
                  <div>{rowData.message}</div>
                </div>
              }
              secondary={`From: ${this.getName(rowData.createdBy)} on ${rowData.created_at.toDateString()}`}
            />
          </ListItem>
        ),
      },
      {
        title: 'View',
        render: (rowData) => (
          <Button style={buttonStyle} variant="contained" color="primary" onClick={() => this.goToWorkOrder(rowData)}>
            View
          </Button>
        ),
      },
      {
        title: 'Read',
        field: 'read',
        render: (rowData) =>
          !rowData.readArr.includes(this.props.user.uid) ? (
            <Button style={buttonStyle} variant="contained" color="secondary" onClick={(event) => this.readNotification(event, rowData)}>
              <Read />
            </Button>
          ) : (
            <Button style={buttonStyle} variant="contained" onClick={(event) => this.unreadNotification(event, rowData)}>
              <Unread />
            </Button>
          ),
      },
    ];
    // TODO viewNotificationsOrderProcessorName
    if (this.props.user.uid === 'IdEdk5CfRFPBu3HnuJIM9cj6dyJ2' || this.props.user.group === 'Admin') {
      columns.splice(2, 0, { field: 'orderProcessorName', title: 'Order Processor' });
    }
    return (
      <>
        <Paper
          style={{
            width: '1000px',
            height: '1000px',
            maxWidth: '100%',
            maxHeight: '100%',
            // overflowY: 'scroll'
          }}
        >
          <AppBar position="sticky" color="default">
            <Grid container alignItems="center" spacing={1} justify="space-around">
              <Grid item>
                {this.state.pageSize * this.state.page + 1}-
                {this.state.pageSize * (this.state.page + 1) < this.state.total ? this.state.pageSize * (this.state.page + 1) : this.state.total} of{' '}
                {this.state.total}
              </Grid>
              <Grid item>
                <IconButton disabled={this.state.page === 0} onClick={this.firstPage}>
                  <FirstPage />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton disabled={this.state.page === 0} onClick={this.prevPage}>
                  <KeyboardArrowLeft />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={this.nextPage} disabled={this.state.page === Math.floor(this.state.total / this.state.pageSize)}>
                  <KeyboardArrowRight />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={this.lastPage} disabled={this.state.page === Math.floor(this.state.total / this.state.pageSize)}>
                  <LastPage />
                </IconButton>
              </Grid>
              {this.state.unread ? (
                <Grid item>
                  <Button style={buttonStyle} size="small" color="primary" variant="contained" onClick={this.viewUnread}>
                    View All
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button style={buttonStyle} size="small" color="primary" variant="contained" onClick={this.viewUnread}>
                    View unread
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button style={buttonStyle} size="small" color="primary" variant="contained" onClick={this.markAllAsRead}>
                  Mark all as read
                </Button>
              </Grid>
              <Grid item>
                <Button style={buttonStyle} variant="contained" onClick={this.props.close}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </AppBar>
          {!this.state.loaded && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}
          <List style={{ height: '948px', overflowY: 'scroll' }}>
            {this.state.notifications.map((notification, key) => (
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  <ListItem button key={key}>
                    <ListItemText
                      primary={
                        <ListItemText primary={`${notification.viewableID} - ${notification.customerName}`} secondary={notification.message} />
                      }
                      secondary={`- ${this.getName(notification.createdBy)} - ${notification.created_at.toDateString()}`}
                    />
                    <ListItemIcon>
                      <Tooltip title="View">
                        <IconButton
                          style={buttonStyle}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.goToWorkOrder(notification);
                          }}
                        >
                          <Launch fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemIcon>
                      {!notification.read ? (
                        <Tooltip title="Mark as Read">
                          <IconButton
                            style={buttonStyle}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              this.readNotification(notification);
                            }}
                          >
                            <Read fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Mark as Unread">
                          <IconButton
                            style={buttonStyle}
                            variant="contained"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              this.unreadNotification(notification);
                            }}
                          >
                            <Unread fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemIcon>
                  </ListItem>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={2}>
                    {notification.orderProcessor &&
                      notification.orderProcessor.split(',').map((op, key) => {
                        const user = this.state.users.find((row) => row.uid === op);
                        if (user) {
                          const name = `${user.firstName} ${user.lastName}`;
                          return (
                            <Grid item key={key}>
                              <TextField label={`Sales Assistant ${key + 1}`} value={name} />
                            </Grid>
                          );
                        }
                        return (
                          <Grid item key={key}>
                            <TextField label="Sales Assistant" value="" error helperText="None Added" />
                          </Grid>
                        );
                      })}
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </List>
        </Paper>
      </>
    );
  }
}

export default Notifications;
