/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import VendorAutocomplete from '../../../../Vendors/VendorAutocomplete';
import VendorNugget from '../../../../Vendors/VendorNugget';

import { Switch, TextField, InputAdornment, IconButton, Button, Select, MenuItem } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import UserStore from '../../../../../Stores/User/Store';
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';

const ShippingCosts = ({
  costs,
  newRow,
  updateRow,
  removeRow,
  workOrder,
  removeAllCostingRows,
  actions,
  submit,
  submitted,
  createBill,
  updateBill,
  deleteBill,
}) => {
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const user = UserStore.getUser();
  const columns = [
    {
      title: 'Saved',
      render: (rowData) =>
        rowData && <Switch size={'small'} onClick={submit} checked={Boolean(rowData.id)} value={Boolean(rowData.id)} color="primary" />,
    },
    {
      title: 'Vendor',
      field: 'vendorID',
      editComponent: (t) => {
        const onChange = (newValue) => {
          t.onChange(newValue);
        };
        return <VendorAutocomplete callback={onChange} initValue={t.value} />;
      },
      render: (rowData) => {
        return <VendorNugget rowData={rowData} />;
      },
    },
    {
      title: 'Invoice Number',
      field: 'invoiceNumber',
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
    },
    {
      title: 'Invoice Date',
      field: 'invoiceDate',
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
      render: (rowData) => {
        if (rowData && rowData.invoiceDate) {
          const date = new Date(rowData.invoiceDate);
          return <div>{date.toDateString()}</div>;
        }
        return <div />;
      },
    },
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric' },
    {
      title: 'per Unit',
      field: 'perUnit',
      type: 'numeric',
      render: (rowData) => (parseFloat(rowData.perUnit) ? parseFloat(rowData.perUnit).toFixed(2) : 'N/A'),
    },
    {
      title: 'Estimated Cost',
      field: 'totalEstimatedCost',
      type: 'numeric',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return (
            <div>${parseFloat(rowData.totalEstimatedCost) ? parseFloat(rowData.totalEstimatedCost).toFixed(2) : rowData.totalEstimatedCost}</div>
          );
        }
      },
    },
    {
      title: 'Actual Cost',
      field: 'totalActualCost',
      type: 'numeric',
      ...(user.group === 'Accounting Admin' || user.group === 'Admin' ? { editable: 'always' } : { editable: 'never' }),
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
    {
      title: 'Notes',
      field: 'notes',
      type: 'string',
    },
  ];

  if (user.group.includes('Accounting') || user.group === 'Admin') {
    columns.push({
      title: 'Bill Status',
      field: 'qbBillCreated',
      editable: 'never',
      render: (rowData) => {
        if (
          rowData && //todo: go back and rethink active customer
          rowData.invoiceNumber &&
          rowData.invoiceDate &&
          rowData.totalActualCost &&
          rowData.qbBillCreated === 0 &&
          (user.group.includes('Accounting') || user.group.match('Admin'))
        ) {
          {
            return (
              <div style={{ padding: '0px 10px' }}>
                {submitted && (
                  <Button size="small" color="secondary" variant="contained" onClick={() => createBill(rowData.id, rowData.tableData.id)}>
                    Create
                  </Button>
                )}
              </div>
            );
          }
        } else if (rowData && rowData.qbBillCreated === 1 && (user.group.includes('Accounting') || user.group.match('Admin'))) {
          {
            return (
              <div>
                <div style={{ padding: '0px 10px' }}>
                  {submitted && (
                    <Button size="small" color="secondary" variant="contained" onClick={() => updateBill(rowData.id)}>
                      Update
                    </Button>
                  )}
                </div>
                <br />
                <div style={{ padding: '0px 10px' }}>
                  {submitted && (
                    <Button size="small" color="secondary" variant="contained" onClick={() => deleteBill(rowData.id, rowData.tableData.id)}>
                      Delete
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        } else {
        }
      },
    });
  }

  if (user.group.includes('Accounting') || (workOrder.misprint && user.group === 'Admin')) {
    columns.splice(1, 0, {
      title: 'Cost Assignment',
      field: 'costAssignment',
      editable: 'never',
      render: (rowData) => {
        return (
          <Select value={rowData?.costAssignment || ''} onChange={(event) => updateRow({ ...rowData, costAssignment: event.target.value }, rowData)}>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="CSP">CSP</MenuItem>
            <MenuItem value="Sales Assistant">Sales Assistant</MenuItem>
          </Select>
        );
      },
    });
  }

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable
        title={'Shipping Costs'}
        columns={columns}
        data={costs.filter((row) => row.type === 'shipping')}
        options={{
          search: false,
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ margin: '10px' }}>
              <MTableToolbar {...props} />
              <div style={{ padding: '0px 10px' }}>
                <div style={{ float: 'right' }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Estimated Cost"
                    value={costs
                      .filter((row) => row.type === 'shipping')
                      .reduce((total, row) => total + row.totalEstimatedCost, 0)
                      .toFixed(2)}
                  />
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Actual Cost"
                    value={costs
                      .filter((row) => row.type === 'shipping')
                      .reduce((total, row) => total + row.totalActualCost, 0)
                      .toFixed(2)}
                  />
                </div>

                {(user.group === 'Admin' || user.group === 'Order Processor') && (
                  <span style={{ float: 'right' }}>
                    <IconButton
                      onClick={() => setDeleteAllDisabled(!deleteAllDisabled)}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <Lock /> : <LockOpen />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={() => {
                        removeAllCostingRows('shipping');
                        setDeleteAllDisabled(true);
                      }}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </span>
                )}
              </div>
              <br />
              <br />
              <br />
            </div>
          ),
        }}
        actions={actions}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            updateRow(newData, oldData);
          },
          onRowAdd: async (newData) => {
            newData.type = 'shipping';
            newRow(newData);
          },
          onRowDelete: async (oldData) => {
            removeRow(oldData);
          },
        }}
      />
    </div>
  );
};

export default ShippingCosts;
