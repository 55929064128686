import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';

const UploadDialog = ({ upload }) => {
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: '10px' }}>
        <Grid item>
          <TextField label={'Name'} value={name} onChange={(e) => setName(e.target.value)} />
        </Grid>
        <Grid item>
          <FormControl style={{ width: '200px' }}>
            <InputLabel>Shipping Company</InputLabel>
            <Select fullWidth value={company} onChange={(e) => setCompany(e.target.value)}>
              <MenuItem value={'Ecom Parcel'}>Ecom Parcel</MenuItem>
              <MenuItem value={'UPS'}>UPS</MenuItem>
              <MenuItem value={'Mail Innovations'} disabled>
                Mail Innovations
              </MenuItem>
              <MenuItem value={'Mail Innovations Int'} disabled>
                Mail Innovations Int
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DropzoneAreaBase onAdd={(data) => upload(data, { company, name })} showPreviewsInDropzone={false} showAlerts={['error']} fileObjects={''} />
    </div>
  );
};

export default UploadDialog;

UploadDialog.propTypes = {
  upload: PropTypes.func,
};
