/* eslint react/no-unescaped-entities:0 */
/* eslint react/button-has-type:0 */
/* eslint react/jsx-props-no-spreading:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-alert:0 */
/* eslint no-return-await:0 */
/* eslint consistent-return:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-vars:0 */
/* eslint no-console:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/no-unused-state:0 */
/* eslint max-len:0 */
import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { auth, firebaseMessaging } from './fire';
import Routes from './Routes';
import './index.css';
import axios from 'axios';
import { getHomePage } from './Functions/API';
import { loadUser } from './Stores/User/Actions';
import { fetchUsers } from './Stores/Users/Actions';
import UserStore from './Stores/User/Store';
import { createSnack } from './Stores/Snack/Actions';
import { LinearProgress, Backdrop, CircularProgress } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import Snack from './Stores/Snack';
import LoadingStore from './Stores/Loading/Store';

const baseTheme = createMuiTheme({
  palette: {
    background: {
      default: '#e2e1e0',
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      active: 0,
      clicked: false,
      loaded: false,
      loading: false,
      customerId: 0,
      customerName: '',
      bugs: [],
      headers: [],
      invoiceableId: 0,
      poNumber: 0,
      WorkOrderId: 0,
      maintenance: false,
    };
  }

  getLoading = () => {
    this.setState({
      loading: LoadingStore.getLoading(),
    });
  };

  componentDidMount() {
    LoadingStore.on('change', this.getLoading);
    UserStore.on('change', this.getUser);
    // Add a response interceptor to handle 401
    axios.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        if (error?.response?.data?.message === 'Daily timeout') {
          this.setState(
            {
              user: null,
              loaded: true,
            },
            this.logout(),
          );
        } else if (error?.response?.data?.message && !error?.response?.data?.hideSnack) {
          createSnack('Error', error.response.data.message);
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error?.response?.data);
      },
    );
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (UserStore.getUser()) {
          this.getUser();
        } else {
          loadUser(user.uid);
        }
        if (firebaseMessaging) {
          await user.getIdToken(true).then((idToken) => {
            user.token = idToken;
          });
          try {
            const permission = await Notification.requestPermission();
            if (!permission) throw new Error('no permission');

            const token = await firebaseMessaging
              .getToken({
                vapidKey: 'BLHrVmxWJH20NawY3Ion-AuzuSRXFUWW2vrmT6DFEkMWY-WqijtND36KMNKbuxqsyc2O5Pwyx-3RPWoARtkYApk',
              })
              .catch((err) => {
                console.error(err);
              });
            const { uid } = user;

            await axios({
              url: `${getHomePage()}/user/${uid}`,
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                token: user.token,
              },
              data: { messageToken: token },
            });
            fetchUsers();
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        try {
          localStorage.clear();
        } catch (err) {
          console.error('Issue clearing local storage');
        }
        this.setState({
          user: null,
          loaded: true,
        });
      }
    });
  }

  componentWillUnmount() {
    LoadingStore.removeListener('change', this.getLoading);
    UserStore.removeListener('change', this.getUser);
  }

  getUser = () => {
    this.setState({
      user: UserStore.getUser(),
      loaded: true,
    });
  };

  logout = () => {
    auth.signOut().then(() => {
      try {
        localStorage.clear();
      } catch (err) {
        console.error('Issue clearing local storage');
      }
    });
  };

  render() {
    return (
      <MuiThemeProvider theme={baseTheme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={6} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <div>
            {!this.state.maintenance ? (
              this.state.loaded ? (
                <div>
                  <Routes user={this.state.user} logout={this.logout} />
                </div>
              ) : (
                <LinearProgress />
              )
            ) : (
              <div>I'm sorry we're down for maintenance</div>
            )}
            <Snack />
            <Backdrop style={{ zIndex: 999999, color: '#fff' }} open={this.state.loading ? true : false} transitionDuration={250}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
