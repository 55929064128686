/* eslint-disable react/display-name */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Checkbox, MenuItem, Select } from '@material-ui/core';
import ContactsIcon from '@material-ui/icons/Contacts';
import CustomerAddToolbar from '../../Components/CustomerManager/CustomerAddToolbar';
import { createDialog } from '../../Stores/Dialog/Actions';
import CustomerEditDialog from '../../Components/Shared/Customers/CustomerEditDialog';
import Customer from './customer';
import { patch } from '../../Functions/API';

const Customers = (props) => {
  const { get, post, user } = props;
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const actions = [
    {
      icon: () => <ContactsIcon />,
      tooltip: 'Edit Contacts',
      onClick: (event, rowData) => {
        createDialog({
          content: <CustomerEditDialog customerID={rowData.id} />,
          title: 'Edit Customer',
          actions: null,
          size: 'xl',
          disableBackdropClick: true,
        });
      },
    },
  ];

  const columns = [
    { title: 'Name', field: 'name' },
    {
      title: 'Locked',
      field: 'locked',
      editable: 'never',
      render: (rowData) => rowData && <Checkbox checked={Boolean(rowData.locked)} />,
    },
    {
      title: 'In QB',
      field: 'quickbooksId',
      editable: 'never',
      render: (rowData) => rowData && <Checkbox color="primary" checked={Boolean(rowData.quickbooksId)} />,
    },
    {
      title: 'Balance',
      field: 'balance',
      editable: 'never',
      render: (rowData) => rowData && `$${parseFloat(rowData.balance) ? parseFloat(rowData.balance).toFixed(2) : '0.00'}`,
    },
    {
      title: 'Pending QB Update',
      field: 'toUpdate',
      editable: 'never',
      render: (rowData) => rowData && <Checkbox checked={Boolean(rowData.toUpdate)} />,
    },
    {
      title: 'Terms',
      field: 'terms',
      editable: 'never',
      render: (rowData) =>
        rowData && (
          <Select onChange={(e) => changeTerms(rowData, e.target.value)} value={rowData.terms}>
            <MenuItem value={1}>Due on Receipt</MenuItem>
            <MenuItem value={8}>Net 10</MenuItem>
            <MenuItem value={2}>Net 15</MenuItem>
            <MenuItem value={3}>Net 30</MenuItem>
            <MenuItem value={4}>Net 60</MenuItem>
          </Select>
        ),
    },
    {
      title: 'Lock',
      field: 'lockTerms',
      editable: 'never',
      render: (rowData) =>
        rowData && (
          <Select
            onChange={(e) => changeLockTerms(rowData, e.target.value)}
            value={rowData.lockTerms}
            disabled={!user.hasPermission('updateLockingTerms')}
          >
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={60}>60 Days</MenuItem>
            <MenuItem value={99}>{`Don't Lock`}</MenuItem>
          </Select>
        ),
    },
  ];

  const changeTerms = async (rowData, terms) => {
    await patch(`/customers/${rowData.id}`, { terms });
    const d = [...data];
    d[data.indexOf(rowData)].terms = terms;
    setData(d);
  };

  const changeLockTerms = async (rowData, lockTerms) => {
    await patch(`/customers/${rowData.id}`, { lockTerms }).then(({ lockTerms, locked }) => {
      const d = [...data];
      d[data.indexOf(rowData)].lockTerms = lockTerms;
      d[data.indexOf(rowData)].locked = locked;
      setData(d);
    });
  };

  const getCustomers = () => {
    get('/customers').then((results) => setData(results));
  };

  useEffect(() => {
    getCustomers();
  }, [refresh]);

  return (
    <div>
      <Switch>
        <Route exact path="/customers/:customerId" component={(props) => <Customer {...props} get={get} post={post} user={user} />} />
        <Route exact path="/customers">
          <Paper>
            <MaterialTable
              title="Customers"
              actions={actions}
              columns={columns}
              data={data}
              options={{ pageSizeOptions: [25, 50, 100], pageSize: 25 }}
              editable={{
                onRowAdd: async (newData) => {
                  post('/addCustomer', newData).then(() => {
                    setRefresh(refresh + 1);
                  });
                },
              }}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <CustomerAddToolbar />
                  </div>
                ),
              }}
            />
          </Paper>
        </Route>
      </Switch>
    </div>
  );
};

Customers.displayName = 'Customers';

export default Customers;
