import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, TextField, Typography } from '@mui/material';
import Barcode from 'react-barcode';
import { createSnack } from '../../../Stores/Snack/Actions.js';

const Asns = () => {
  const [input, setInput] = useState('');

  useEffect(() => {
    document.getElementById('asn-lookup').focus();
  }, []);

  const sanitizeInput = async (value) => {
    const int = parseInt(value, 10);
    let newValue = value;
    if (int) {
      newValue = int;
      setInput(`${int}`);
    } else {
      setInput(value);
    }
    await navigator.clipboard.writeText(newValue);
    createSnack('Success', 'ASN copied to clipboard');
  };

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Typography>Asns</Typography>
      </Breadcrumbs>
      <Grid container spacing={1} direction={'column'} alignItems={'center'} justifyContent={'center'}>
        <Grid item>
          <TextField
            id={'asn-lookup'}
            label={'ASN'}
            value={input}
            onKeyUp={async (e) => {
              if (e.key === 'Enter') {
                await sanitizeInput(input);
              }
            }}
            onChange={(e) => setInput(e.target.value)}
          />
        </Grid>
        {Boolean(input.length) && (
          <Grid item>
            <Barcode value={input} width={4} displayValue={false} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Asns;
