import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { PermContactCalendar } from '@material-ui/icons';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { loadCustomers, newCustomer } from '../../../../../Stores/Customers/Actions';
import { updateCustomer, updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { addCustomerToQB } from '../../../../QuickBooks/functions';
import CustomerEditDialog from '../../../../Shared/Customers/CustomerEditDialog';
import { createDialog } from '../../../../../Stores/Dialog/Actions';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { WorkOrderStore, UserStore, CustomersStore, CustomerStore } from '../../../../../Stores';

const filter = createFilterOptions();

const Customers = ({ editable = true }) => {
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerInput, setCustomerInput] = useState('');
  const user = UserStore.getUser();

  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
    return () => {
      WorkOrderStore.removeListener('change', getWorkOrder);
    };
  }, []);
  const getWorkOrder = () => {
    setWorkOrder({ ...WorkOrderStore.getWorkOrder() });
  };

  useEffect(() => {
    CustomersStore.on('change', getCustomers);
    CustomerStore.on('change', getCustomer);
    if (!CustomersStore.getCustomers().length) {
      loadCustomers();
    } else {
      getCustomers();
    }
    return () => {
      CustomersStore.removeListener('change', getCustomers);
      CustomerStore.removeListener('change', getCustomer);
    };
  }, []);

  useEffect(() => {
    const c = customers.find((c) => c.id === workOrder.customerID);
    if (c) {
      CustomerStore.loadCustomer(c);
    }
  }, [workOrder.customerID, customers]);

  const getCustomers = () => {
    setCustomers([...CustomersStore.getCustomers()]);
  };

  const getCustomer = () => {
    const currentCustomer = CustomerStore.getCustomer();
    setCustomer({ ...currentCustomer });
    setCustomerInput(currentCustomer?.name);
  };

  const updateWorkOrderCustomer = async (newCustomer) => {
    if (newCustomer) {
      const { name, id } = newCustomer;
      await updateCustomer(workOrder.id, newCustomer.id);
      const updateObj = {
        customerName: name,
        customerID: id,
      };
      if (newCustomer.locked) {
        updateObj.overruled = 0;
      }
      updateWorkOrder(workOrder.id, updateObj);
    }
  };

  return (
    <>
      <Autocomplete
        value={customer}
        inputValue={customerInput}
        disabled={Boolean(customer?.locked && !workOrder.overruled) || !editable}
        onInputChange={(event, value) => {
          setCustomerInput(value);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            createSnack('Error', 'Please click Add button in options to create customer');
          } else if (newValue && newValue.inputValue) {
            newCustomer({ name: newValue.inputValue.trim() }).then((result) => {
              updateWorkOrderCustomer(result);
              createDialog({
                content: <CustomerEditDialog customerID={result.id} workOrder={workOrder} />,
                title: 'Edit Customer',
                actions: null,
                size: 'xl',
                disableBackdropClick: true,
              });
            });
          } else {
            updateWorkOrderCustomer(newValue ? newValue : null);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={customers}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name ? `${option.name}${option?.locked === 1 ? ' (LOCKED)' : ''}` : '';
        }}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(option) => (option.name ? `${option.name}${option.locked === 1 ? ' (LOCKED)' : ''}` : '')}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Customer"
            variant="outlined"
            disabled={!editable}
            error={customer?.id && !customer?.quickbooksId}
            helperText={customer?.id && !customer?.quickbooksId ? 'Customer not in QuickBooks' : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {Boolean(customer) && customer?.id && editable && (
                    <InputAdornment position="end">
                      <IconButton
                        variant="contained"
                        href=""
                        onClick={() =>
                          createDialog({
                            content: <CustomerEditDialog customerID={customer.id} workOrder={workOrder} />,
                            title: 'Edit Customer',
                            actions: null,
                            size: 'xl',
                            disableBackdropClick: true,
                          })
                        }
                        style={workOrder.overruled ? { marginRight: '-30px' } : { marginRight: '0' }}
                      >
                        <PermContactCalendar />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
              // TODO addCustomerToQuickbooks
              startAdornment: Object.keys(customer).length !== 0 && !customer?.quickbooksId && user.hasPermission('addCustomerToQuickBooks') && (
                <InputAdornment position="start">
                  <Tooltip title="Add Customer to Quickbooks" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <IconButton
                      id="customerAdd"
                      variant="contained"
                      style={{ color: 'blue' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addCustomerToQB(customer.id);
                      }}
                    >
                      <PersonAddIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default Customers;

Customers.propTypes = {
  workOrder: PropTypes.object,
  editable: PropTypes.bool,
};
