import dispatcher from './dispatcher';

export const createSnack = (variant, message) => {
  if (variant === 'Error') {
    console.error(message);
  }
  dispatcher.dispatch({
    type: 'NEW_SNACK',
    snack: { open: true, variant, message },
  });
};

export const closeSnack = () => {
  dispatcher.dispatch({
    type: 'NEW_SNACK',
    snack: { open: false, variant: '', message: '' },
  });
};
