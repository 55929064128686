/* eslint no-console:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import commaNumber from 'comma-number';
import Skeleton from '@material-ui/lab/Skeleton';
import DateFilter from './DateFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const TotalProfit = ({ get, refresh }) => {
  const classes = useStyles();
  const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
  const [startDate, setStartDate] = useState(moment().format('YYYY/MM/DD'));
  const [profit, setProfit] = useState(null);
  const [selected, setSelected] = useState('Today');

  const getProfit = () => {
    get('/getWorkOrderProfits', [
      {
        name: 'startDate',
        value: startDate,
      },
      {
        name: 'endDate',
        value: endDate,
      },
    ])
      .then((res) => {
        const { profit } = res[0];
        setProfit(profit === null ? 0 : profit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDates = () => {
    if (selected === 'Today') {
      setStartDate(moment().format('YYYY/MM/DD'));
      setEndDate(moment().format('YYYY/MM/DD'));
    } else if (selected === 'This Week') {
      setStartDate(moment().startOf('week').format('YYYY/MM/DD'));
      setEndDate(moment().format('YYYY/MM/DD'));
    } else if (selected === 'Last Week') {
      const endOfWeek = moment().startOf('week').subtract(1, 'days');
      const startOfWeek = moment().startOf('week').subtract(7, 'days');
      setStartDate(startOfWeek.format('YYYY/MM/DD'));
      setEndDate(endOfWeek.format('YYYY/MM/DD'));
    }
  };

  const handleDatePickerChange = (date, start) => {
    if (start) {
      setStartDate(moment(date).format('YYYY/MM/DD'));
    } else {
      setEndDate(moment(date).format('YYYY/MM/DD'));
    }
    if (selected) setSelected(null);
  };

  useEffect(() => {
    getProfit();
  }, [startDate, endDate, refresh]);

  useEffect(() => {
    setDates();
  }, [selected]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
          TOTAL PROFIT
        </Typography>
        {profit === null ? (
          <div style={{ textAlign: 'center' }}>
            <Skeleton variant="rect" width={20} height={30} style={{ margin: '10px 0' }} />
            <Skeleton variant="rect" width={250} height={20} style={{ marginBottom: '10px' }} />
            <Skeleton variant="rect" width="100%" height={50} />
          </div>
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                <Typography variant="h3">${commaNumber(profit)}</Typography>
              </Grid>
              <Grid item>
                <ButtonGroup
                  size="small"
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ margin: '10px 0', marginRight: '10px' }}
                >
                  <Button
                    variant={selected === 'Today' ? 'contained' : 'outlined'}
                    onClick={() => setSelected('Today')}
                    style={{ lineHeight: '18px' }}
                  >
                    Today
                  </Button>
                  <Button
                    variant={selected === 'This Week' ? 'contained' : 'outlined'}
                    onClick={() => setSelected('This Week')}
                    style={{ lineHeight: '18px' }}
                  >
                    This Week
                  </Button>
                  <Button
                    variant={selected === 'Last Week' ? 'contained' : 'outlined'}
                    onClick={() => setSelected('Last Week')}
                    style={{ lineHeight: '18px' }}
                  >
                    Last Week
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <DateFilter startDate={startDate} endDate={endDate} handleDatePickerChange={handleDatePickerChange} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TotalProfit;
