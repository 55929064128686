/* eslint-disable react/display-name */
/* eslint react/destructuring-assignment:0 */
/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint consistent-return:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';

const MiniWorkOrderTable = ({ group, orderType, get, uid }) => {
  const mobileSize = useMediaQuery('(min-width: 6000px)');
  const [tableData, setTableData] = useState(null);
  const skeletonRows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const columns = [
    {
      title: 'ID',
      field: 'workOrderID',
    },
    {
      title: 'Status',
      sorting: false,
      render: (rowData) => {
        const date = new Date();
        const dueDate = new Date(rowData.dueDate);
        date.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        if (rowData.onHold) {
          return <div style={{ color: 'orange' }}>On Hold</div>;
        }
        if (rowData.canceled) {
          return <div>Canceled</div>;
        }
        if (rowData.misprint && !rowData.productionComplete) {
          return <div style={{ color: 'red' }}>REPRINT</div>;
        }
        if (date > dueDate && !rowData.productionComplete) {
          return <div style={{ color: 'red' }}>Overdue</div>;
        }
        if (date.getTime() === dueDate.getTime() && !rowData.productionComplete) {
          return <div>Due Today</div>;
        }
        if (rowData.productionComplete) {
          return <div>Production Complete</div>;
        }
      },
    },
    {
      title: 'Due Date',
      field: 'dueDate',
      searchable: false,
      render: (rowData) => {
        return <div>{whichMonth(rowData.dueDate)}</div>;
      },
    },
    { title: 'Customer', field: 'customerName' },
  ];

  const whichMonth = (rawDate) => {
    const date = new Date(rawDate);
    const index = date.getMonth();
    const day = date.getDate();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[index];
    return `${month} ${day}`;
  };

  const getOrders = async () => {
    await get('/getWaitingWorkOrders', [
      {
        name: 'uid',
        value: uid,
      },
      {
        name: 'group',
        value: group,
      },
      {
        name: 'type',
        value: orderType,
      },
    ])
      .then((res) => {
        setTableData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrders();
  }, [orderType]);

  return (
    <div className="mini-workorder-table" style={{ margin: '8px', minWidth: '300px' }}>
      {tableData ? (
        <MaterialTable
          components={{
            Container: (props) => <div style={{ background: 'white' }}>{props.children}</div>,
          }}
          columns={columns}
          data={tableData}
          options={{
            search: false,
            showTitle: false,
            toolbar: false,
            padding: 'dense',
            pageSizeOptions: [mobileSize ? 10 : 5],
            pageSize: mobileSize ? 10 : 5,
            loadingType: 'linear',
            headerStyle: {
              padding: '6px 16px',
            },
          }}
          onRowClick={(e, rowData) => {
            window.open(`/workorder/${rowData.id}`, '_blank');
          }}
        />
      ) : (
        skeletonRows.map((x) => {
          return <Skeleton key={x} variant="rect" width="100%" height={35} style={{ margin: '4px' }} />;
        })
      )}
    </div>
  );
};

export default MiniWorkOrderTable;
