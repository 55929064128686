/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createDialog } from '../../../../../Stores/Dialog/Actions';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { FormControlLabel, Switch, TextField, InputAdornment, IconButton, Select, MenuItem, Button } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import DateFnsUtils from '@date-io/date-fns';
import VendorAutocomplete from '../../../../Vendors/VendorAutocomplete';
import VendorNugget from '../../../../Vendors/VendorNugget';
import UserStore from '../../../../../Stores/User/Store';
import { get, patch } from '../../../../../Functions/API';

const RACosts = ({
  costs,
  setCosts,
  submitted,
  setSubmitted,
  newRow,
  updateRow,
  removeRow,
  workOrder,
  convertDateToSQLDate,
  removeAllCostingRows,
  actions,
  submit,
  cspVendor,
  resetTable,
  checkAccountingApproved,
}) => {
  const [deleteAllDisabled, setDeleteAllDisabled] = useState(true);
  const user = UserStore.getUser();
  const columns = [
    {
      title: 'Saved',
      render: (rowData) =>
        rowData && <Switch size={'small'} onClick={submit} checked={Boolean(rowData.id)} value={Boolean(rowData.id)} color="primary" />,
    },
    {
      title: 'Vendor',
      field: 'vendorID',
      editComponent: (t) => {
        const onChange = (newValue) => {
          t.onChange(newValue);
        };
        return <VendorAutocomplete callback={onChange} initValue={t.value} />;
      },
      render: (rowData) => {
        return <VendorNugget rowData={rowData} />;
      },
    },
    {
      title: 'Invoice Number',
      field: 'invoiceNumber',
      // TODO editInvoiceNumberRACosts
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
    },
    {
      title: 'Invoice Date',
      field: 'invoiceDate',
      // TODO editInvoiceDateRACosts
      editable: user.group === 'Accounting Admin' || user.group === 'Admin' ? 'always' : 'never',
      render: (rowData) => {
        if (rowData && rowData.invoiceDate) {
          const date = new Date(rowData.invoiceDate);
          return <div>{date.toDateString()}</div>;
        }
        return <div />;
      },
    },
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric' },
    {
      title: 'per Unit',
      field: 'perUnit',
      type: 'numeric',
      render: (rowData) => (parseFloat(rowData.perUnit) ? parseFloat(rowData.perUnit).toFixed(2) : 'N/A'),
    },
    {
      title: 'Shipping Cost',
      field: 'shippingCost',
      type: 'numeric',
      // render: rowData => '$' + rowData.shippingCost ? rowData.shippingCost : 0
    },
    {
      title: 'Estimated Cost',
      field: 'totalEstimatedCost',
      type: 'numeric',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return (
            <div>${parseFloat(rowData.totalEstimatedCost) ? parseFloat(rowData.totalEstimatedCost).toFixed(2) : rowData.totalEstimatedCost}</div>
          );
        }
      },
    },
    {
      title: 'Actual Cost',
      field: 'totalActualCost',
      type: 'numeric',
      // TODO editActualCostRACosts
      ...(user.group === 'Accounting Admin' || user.group === 'Admin' ? { editable: 'always' } : { editable: 'never' }),
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
    {
      title: 'Notes',
      field: 'notes',
      type: 'string',
    },
  ];

  // TODO viewBillStatusRACosts
  if (user.group.includes('Accounting') || user.group === 'Admin') {
    columns.push({
      title: 'Bill Status',
      field: 'qbBillCreated',
      editable: 'never',
      render: (rowData) => {
        if (
          rowData && //todo: go back and rethink active customer
          rowData.invoiceNumber &&
          rowData.invoiceDate &&
          rowData.totalActualCost &&
          rowData.qbBillCreated === 0 &&
          (user.group.includes('Accounting') || user.group.match('Admin'))
        ) {
          {
            return (
              <div style={{ padding: '0px 10px' }}>
                {submitted && (
                  <Button size="small" color="secondary" variant="contained" onClick={() => createBill(rowData.id, rowData.tableData.id)}>
                    Create
                  </Button>
                )}
              </div>
            );
          }
        } else if (rowData && rowData.qbBillCreated === 1 && (user.group.includes('Accounting') || user.group.match('Admin'))) {
          {
            return (
              <div>
                <div style={{ padding: '0px 10px' }}>
                  {submitted && (
                    <Button size="small" color="secondary" variant="contained" onClick={() => updateBill(rowData.id)}>
                      Update
                    </Button>
                  )}
                </div>
                <br />
                <div style={{ padding: '0px 10px' }}>
                  {submitted && (
                    <Button size="small" color="secondary" variant="contained" onClick={() => deleteBill(rowData.id, rowData.tableData.id)}>
                      Delete
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        } else {
        }
      },
    });
  }

  // TODO showArrivalDateRACosts
  if (user.group !== 'Accounting Admin') {
    columns.splice(1, 0, {
      title: 'Arrival Date',
      field: 'arrivalDate',
      type: 'date',
      // TODO editArrivalDateRACosts
      editable: user.group === 'Accounting Admin' ? 'never' : 'always',
      render: (rowData) => {
        if (rowData && rowData.arrivalDate) {
          const date = new Date(rowData.arrivalDate);
          return <div>{date.toDateString()}</div>;
        }
        return <div />;
      },
    });
  }

  // TODO showCostAssignmentRACosts
  if (workOrder.misprint && (user.group.includes('Accounting') || user.group === 'Admin')) {
    columns.splice(1, 0, {
      title: 'Cost Assignment',
      field: 'costAssignment',
      editable: 'never',
      render: (rowData) => {
        return (
          <Select
            value={rowData?.costAssignment || ''}
            onChange={(event) => updateCost(rowData.id, { costAssignment: event.target.value })}
            disabled={Boolean(!rowData?.misprint)}
          >
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="CSP">CSP</MenuItem>
            <MenuItem value="Sales Assistant">Sales Assistant</MenuItem>
          </Select>
        );
      },
    });
  }

  // TODO showReprintRACosts
  if (workOrder.misprint && (user.group === 'Order Processor' || user.group === 'Admin' || user.group.includes('Sales'))) {
    columns.splice(1, 0, {
      title: 'Reprint',
      field: 'misprint',
      editable: 'never',
      render: (rowData) => {
        return (
          <Switch
            checked={rowData.misprint}
            size={'small'}
            onChange={() => updateCost(rowData.id, { misprint: !rowData.misprint })}
            value={rowData.misprint}
            color="secondary"
          />
        );
      },
    });
  }

  // TODO showGeneratedRACosts
  if (user.group === 'Admin') {
    columns.splice(1, 0, {
      title: 'Generated',
      editable: 'never',
      render: (rowData) =>
        rowData && <Switch size={'small'} checked={Boolean(rowData.type === 'generated')} value={Boolean(rowData.type === 'generated')} />,
    });
  }

  const createBill = async (costId, rowIndex) => {
    let vendor;
    let url;
    let message;
    await get('/getVendorbyCost', [{ name: 'costId', value: costId }])
      .then((results) => {
        vendor = results.vendor;
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    if (vendor) {
      const { quickbooksId } = vendor;
      if (quickbooksId) {
        await get('/qbCheckDuplicateBill', [{ name: 'costId', value: costId }])
          .then((results) => {
            url = results.url;
            message = results.message;
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
        if (url) {
          openQuickBooksPopUp(url);
        } else {
          if (message === 'duplicate') {
            createDialog({
              content: 'A different bill already has this invoice number. Do you still want to create this bill?',
              title: 'Invoice Number Conflict',
              actions: [
                { title: 'no', color: 'secondary', callback: null },
                { title: 'yes', color: 'primary', callback: () => qbCreateBill(costId) },
              ],
            });
          } else {
            qbCreateBill(costId);
          }
        }
      }
    } else {
      createSnack('Error', 'Vendor Incorrect');
    }
  };

  const qbCreateBill = (costId) => {
    get('/qbCreateBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url } = results;
        const { message } = results;

        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'Bill Created in Quickbooks.');
          getCosts();
        } else {
          createSnack('Error', message.message);
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const deleteBill = (costId, rowIndex) => {
    get('/qbDeleteBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'The bill has been deleted from Quickbooks.');
          getCosts();
        } else if (message === 'paid') {
          createSnack('Warning', "This bill has a payment connected to it and can't be deleted.");
        } else {
          createSnack('Error', 'Error. The bill was not deleted from Quickbooks.');
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const openQuickBooksPopUp = (url) => {
    let parameters = 'location=1,width=800,height=650';
    parameters += `,left=${(window.screen.width - 800) / 2},top=${(window.screen.height - 650) / 2}`;
    window.open(url, 'connectPopup', parameters);
  };

  const updateBill = (costId) => {
    console.log('Update Bills');
    get('/qbUpdateBill', [{ name: 'costId', value: costId }])
      .then((results) => {
        const { url, message } = results;
        if (url) {
          openQuickBooksPopUp(url);
        } else if (message === 'success') {
          createSnack('Success', 'The bill has been updated in Quickbooks successfully!');
        } else {
          createSnack('Error', 'Error. The bill was not updated in Quickbooks.');
        }
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateCost = (id, body) => {
    console.log('Body', body);
    patch(`/costing/${id}`, body)
      .then((results) => {
        const numOfLines = [...costs];
        const i = numOfLines.findIndex((line) => line.id === id);
        numOfLines[i] = results;
        createSnack('Success', 'Updated Cost');
        setCosts(numOfLines);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const getCosts = () => {
    get('/getCosting', [{ name: 'workOrderID', value: workOrder.id }])
      .then(({ rows, submitted }) => {
        setCosts(rows);
        setSubmitted(submitted);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const buttonStyles = {
    margin: '8px',
    paddingBottom: '5px',
  };

  const bringOverMisprints = () => {
    get('/bringOverMisprints', [
      { name: 'workOrderID', value: workOrder.id },
      {
        name: 'uid',
        value: user.uid,
      },
    ])
      .then(({ rows, submitted }) => {
        const numOfLines = [...[...costs], ...rows];
        checkAccountingApproved();
        setCosts(numOfLines);
        setSubmitted(submitted);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable
        title={'RA Costs'}
        columns={columns}
        data={costs.filter((row) => row.type === 'RA')}
        options={{
          search: false,
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ margin: '10px' }}>
              <MTableToolbar {...props} />
              <div style={{ padding: '0px 10px' }}>
                {/* TODO editActualCostRACosts */}
                {(user.group.includes('Accounting') || user.group === 'Admin') && (
                  <TextField
                    style={{ float: 'right' }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    label="Total Actual Cost"
                    value={costs
                      .filter((row) => row.type === 'RA')
                      .reduce((total, row) => total + row.totalActualCost, 0)
                      .toFixed(2)}
                  />
                )}
                {/* TODO deleteAllRACosts */}
                {(user.group === 'Admin' || user.group === 'Order Processor') && (
                  <span style={{ float: 'right' }}>
                    <IconButton
                      onClick={() => setDeleteAllDisabled(!deleteAllDisabled)}
                      style={{
                        color: deleteAllDisabled ? 'green' : 'red',
                      }}
                      href=""
                    >
                      {deleteAllDisabled ? <Lock /> : <LockOpen />}
                    </IconButton>
                    <Button
                      size="small"
                      onClick={() => {
                        removeAllCostingRows('cost');
                        setDeleteAllDisabled(true);
                      }}
                      style={{
                        margin: '10px',
                        backgroundColor: !deleteAllDisabled ? 'red' : 'LightGray',
                        color: deleteAllDisabled ? 'grey' : 'black',
                        border: deleteAllDisabled ? 'grey solid 2px' : 'black solid 2px',
                        fontWeight: 'bold',
                      }}
                      color="secondary"
                      disabled={deleteAllDisabled}
                      variant="contained"
                    >
                      DELETE ALL!
                    </Button>
                  </span>
                )}
              </div>
              <br />
              <br />
              <br />
            </div>
          ),
        }}
        actions={actions}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            updateRow(newData, oldData);
          },
          onRowAdd: async (newData) => {
            newData.type = 'RA';
            newRow(newData);
          },
          onRowDelete: async (oldData) => {
            removeRow(oldData);
          },
        }}
      />
    </div>
  );
};

export default RACosts;
