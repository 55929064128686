/* eslint react/no-array-index-key:0 */
/* eslint react/no-children-prop:0 */
/* eslint no-unused-expressions:0 */
/* eslint no-unused-vars:0 */
/* eslint no-plusplus:0 */
/* eslint radix:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint max-len:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
import React, { Component, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import CountLine from './CountLine';
import Numbers from '../Generate/Numbers';
import PageToolbar from '../Generate/PageToolbar';
import Grouping from './Grouping';
import { get, post } from '../../../../../Functions/API';
import { createSnack } from '../../../../../Stores/Snack/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import UserStore from '../../../../../Stores/User/Store';

const Count = ({ workOrder, inventoryStyles, SKUs, vendors, numbersModal }) => {
  const user = UserStore.getUser();
  const [numOfLines, setNumOfLines] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [numberColumns, setNumberColumns] = useState([]);
  const [viewNumbers, setViewNumbers] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [orderOptions, setOrderOptions] = useState({});
  const [potentialSizes, setPotentialSizes] = useState([]);
  const [groupBy, setGroupBy] = useState([
    { title: 'Type', field: 'type', selected: true },
    { title: 'Vendor', field: 'vendorID', selected: true },
    { title: 'Inventory', field: 'inventoryId', selected: true },
    { title: 'Use Inventory', field: 'useInventory', selected: true },
    { title: 'Add SKU', field: 'addSKU', selected: true },
    { title: 'Remove SKU', field: 'removeSKU', selected: true },
    { title: 'Adult Style', field: 'adultStyle', selected: true },
    { title: 'Youth Style', field: 'youthStyle', selected: true },
    { title: 'Color', field: 'color', selected: true },
    { title: 'Team', field: 'team', selected: true },
    { title: 'Notes', field: 'notes', selected: true },
    { title: 'Art', field: 'art', selected: true },
    { title: 'Number', field: 'number', selected: false },
  ]);

  useEffect(() => {
    if (workOrder.id) {
      setup();
    }
  }, [workOrder.id]);

  const setup = async () => {
    let numOfLines;
    let orderOptions;
    let total;
    let potentialSizes;
    await get('/allPotentialSizes', [{ name: 'type', value: 'type' }])
      .then((results) => {
        potentialSizes = results;
        setPotentialSizes(results);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    await get('/getOrdersByVendorAndColor', [
      { name: 'workOrderID', value: workOrder.id },
      { name: 'page', value: 0 },
      { name: 'groupBy', value: JSON.stringify(groupBy) },
    ])
      .then(async ({ data, options, total }) => {
        let ordersIDs = [];
        await get('/getCurrentClockIn', [
          { name: 'workOrderID', value: workOrder.id },
          {
            name: 'uid',
            value: user.id,
          },
          { name: 'type', value: 'count' },
        ])
          .then((tempOrderIDs) => {
            ordersIDs = tempOrderIDs;
          })
          .catch((err) => {
            console.log(err);
          });
        setOrderOptions(options);
        setTotal(total);
        const filters = {};
        for (const option of Object.keys(options)) {
          filters[option] = '';
        }
        setFilters(filters);
        for (const result of data) {
          const checkClockIn = ordersIDs.find((ordersID) => {
            if (result.number) {
              return ordersID.ordersIDs === result.ordersIDs && ordersID.number === result.number;
            }
            return ordersID.ordersIDs === result.ordersIDs;
          });
          if (checkClockIn) {
            const date = new Date(checkClockIn.clockIn);
            result.clockIn = true;
            result.clockInTime = `${date.getHours()}:${date.getMinutes()}`;
            result.returnedId = checkClockIn.id;
          }
          result.sizes = sortSizesStart(result.sizes, potentialSizes);
          result.showArt = false;
          result.newCountSizes = [];
          for (const countSize of result.sizes) {
            result.newCountSizes.push({
              size: countSize.size,
              quantity: 0,
            });
          }
        }
        setNumOfLines(data);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });

    return {
      numOfLines,
      orderOptions,
      filters,
      total,
      potentialSizes,
      loading,
    };
  };
  const sortSizes = (sizes) => {
    return sizes.sort((a, b) => {
      const indexA = potentialSizes.findIndex((row) => row.size.toLowerCase() === a.size.toLowerCase());
      const indexB = potentialSizes.findIndex((row) => row.size.toLowerCase() === b.size.toLowerCase());
      return indexA - indexB;
    });
  };

  const sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const indexA = potentialSizes.findIndex((row) => row.size.toLowerCase() === a.size.toLowerCase());
      const indexB = potentialSizes.findIndex((row) => row.size.toLowerCase() === b.size.toLowerCase());
      return indexA - indexB;
    });
  };

  const getOrders = (page) => {
    setLoading(true);
    get('/getOrdersByVendorAndColor', [
      { name: 'workOrderID', value: workOrder.id },
      { name: 'page', value: page },
      { name: 'filters', value: JSON.stringify(filters) },
      { name: 'groupBy', value: JSON.stringify(groupBy) },
    ])
      .then(async ({ data, options, total }) => {
        let ordersIDs = [];
        await get('/getCurrentClockIn', [
          { name: 'workOrderID', value: workOrder.id },
          {
            name: 'uid',
            value: user.uid,
          },
          { name: 'type', value: 'count' },
        ])
          .then((tempOrderIDs) => {
            ordersIDs = tempOrderIDs;
          })
          .catch((err) => {
            console.log(err);
          });
        for (const result of data) {
          const checkClockIn = ordersIDs.find((ordersID) => {
            if (result.number) {
              return ordersID.ordersIDs === result.ordersIDs && ordersID.number === result.number;
            }
            return ordersID.ordersIDs === result.ordersIDs;
          });
          if (checkClockIn) {
            const date = new Date(checkClockIn.clockIn);
            result.clockIn = true;
            result.clockInTime = `${date.getHours()}:${date.getMinutes()}`;
            result.returnedId = checkClockIn.id;
          }
          result.sizes = sortSizes(result.sizes);
          result.showArt = false;
          result.newCountSizes = [];
          for (const countSize of result.sizes) {
            result.newCountSizes.push({
              size: countSize.size,
              quantity: 0,
            });
          }
        }
        setNumOfLines(data);
        setOrderOptions(options);
        setTotal(total);
        setLoading(false);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const turnPage = (p) => {
    setPage(p);
    setNumOfLines([]);
    setLoading(true);
    getOrders(p);
  };

  const updateFilter = (filter, value) => {
    const f = { ...filters };
    f[filter] = value;
    setFilters(f);
    setPage(0);
    setLoading(true);
    getOrders(0);
  };

  const clearFilters = () => {
    const f = { ...filters };
    for (const key of Object.keys(f)) {
      f[key] = '';
    }
    setFilters(f);
    setPage(0);
    setLoading(true);
    getOrders(0);
  };

  const updateQuantity = (quantity, size, position, type) => {
    const newLines = [...numOfLines];
    // let position = event.target.getAttribute('position');
    const { sizes } = newLines[position];
    sizes[sizes.indexOf(size)][type] = parseInt(quantity);
    setNumOfLines(newLines);
  };

  const clockInLine = (line, status, finished) => {
    const lines = [...numOfLines];
    const index = lines.indexOf(line);
    lines[index].clockIn = status;
    const { sizes } = lines[index];
    if (finished) {
      lines[index].countFinished = 1;
      let check = false;
      for (let i = 0; i < sizes.length; i++) {
        if (sizes[i].quantity !== sizes[i].countNum) {
          check = true;
          sizes[i].wrong = true;
        }
      }
      if (check) {
        const inventory = workOrder.orderType === 'inventory';
        // vendor info needs to be fixed here!
        let message = `Unexpected ${inventory ? 'amount removed from inventory for' : 'count for'} ${line.color} ${line.vendor?.name.toUpperCase()} ${
          line.youthStyle && line.youthStyle
        }${line.youthStyle && line.adultStyle && ','} ${line.adultStyle && line.adultStyle} ${inventory ? '.' : 'on the front end.'} `;
        for (let i = 0; i < sizes.length; i++) {
          if (sizes[i].wrong) {
            message += `Expected ${sizes[i].quantity} ${sizes[i].size.toUpperCase()}, received ${sizes[i].countNum} ${sizes[i].size.toUpperCase()}. `;
          }
        }
        addNotification('Error', message, '', user, workOrder);
      }
    } else if (!status) {
      // for (let i = 0; i < sizes.length; i++) {
      //     countSizes[i].quantity += newCountSizes[i].quantity;
      //     newCountSizes[i].quantity = 0;
      // }
    }
    setNumOfLines(lines);
  };

  const toggleArt = (line) => {
    const lines = [...numOfLines];
    const index = numOfLines.indexOf(line);
    lines[index].showArt = !numOfLines[index].showArt;
    setNumOfLines(lines);
  };

  const resetCount = (line) => {
    const lines = [...numOfLines];
    const index = numOfLines.indexOf(line);
    const { sizes } = lines[index];
    for (const size of sizes) {
      size.countNum = 0;
    }
    const json = { line: numOfLines[index], type: 'count' };
    post('/resetProductionSizes', json).then((results) => {
      setNumOfLines(lines);
    });
  };

  const showNumbers = () => {
    setViewNumbers(true);
  };

  const closeNumbers = () => {
    setViewNumbers(false);
  };

  const createNumbers = (sizes, numbers) => {
    const numberColumns = [{ title: 'Number', field: 'number' }];
    let j = 0;
    for (const size of sizes) {
      for (let i = 0; i < size.quantity; i++) {
        if (!numberColumns.find((column) => column.field === size.size)) {
          numberColumns.push({ title: size.size.toUpperCase(), field: size.size });
        }
        j++;
        if (j > numbers.length - 1) {
          j = 0;
        }
      }
    }
    setNumbers(numbers);
    setNumberColumns(numberColumns);
    showNumbers();
  };

  const updateFinishedInDB = (line, type) => {
    post('/updateFinished', { type, line }, 'obj').then((newLine) => {
      const lines = [...numOfLines];
      const index = numOfLines.indexOf(line);
      type === 'count'
        ? (lines[index].countFinished = !lines[index].countFinished)
        : (lines[index].packagingFinished = !lines[index].packagingFinished);
      setNumOfLines(lines);
    });
  };

  const toggleSelected = (group) => {
    const gb = [...groupBy];
    const index = groupBy.indexOf(group);
    gb[index].selected = !gb[index].selected;
    setGroupBy(gb);
  };

  return (
    <div
      style={{
        paddingBottom: '80px',
      }}
    >
      <Modal children="div" open={viewNumbers} onClose={closeNumbers} style={{ top: '5%', left: '10%' }}>
        <Numbers numbers={numbers} columns={numberColumns} />
      </Modal>
      <Grouping groupBy={groupBy} update={() => getOrders(0)} loading={loading} toggleSelected={toggleSelected} />
      {numOfLines.map((line, key) => (
        <CountLine
          line={line}
          inventoryStyles={inventoryStyles}
          SKUs={SKUs}
          vendors={vendors}
          workOrder={workOrder}
          groupBy={groupBy}
          toggleArt={toggleArt}
          updateFinishedInDB={updateFinishedInDB}
          clockInLine={clockInLine}
          createNumbers={createNumbers}
          resetCount={resetCount}
          key={key}
          updateQuantity={updateQuantity}
          num={key}
        />
      ))}
      <PageToolbar
        filters={filters}
        workOrder={workOrder}
        orderOptions={orderOptions}
        setNumbersModal={numbersModal}
        page={page}
        setPage={turnPage}
        pageSize={pageSize}
        total={total}
        updateFilter={updateFilter}
        clearFilters={clearFilters}
        loading={loading}
      />
    </div>
  );
};

export default Count;
