import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';

export const loadOrders = async (workOrderID, page, filters) => {
  await get('/getOrders', { workOrderID, page, filters })
    .then((results) => {
      // console.log('results', results);
      dispatcher.dispatch({
        type: 'LOAD_ORDERS',
        results,
      });
    })
    .catch((err) => {
      console.log('ERROR', err);
      createSnack('Error', err.message);
    });
};

export const submitOrders = async ({ workOrderID, page, numOfLines, selectedFilters, uid }) => {
  return post(`/submitOrders`, { workOrderID, page, numOfLines, selectedFilters, uid })
    .then(({ results, messages }) => {
      messages.map(({ type, message }) => {
        createSnack(type, message);
      });
      dispatcher.dispatch({
        type: 'LOAD_ORDERS',
        results,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateNumOfLines = (index, newLine) => {
  dispatcher.dispatch({
    type: 'UPDATE_NUMOFLINES',
    index,
    newLine,
  });
};

export const loadOrder = (id) => {
  return get(`/fulfillment/orders/${id}`);
};
