import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { get } from '../../../../../../../Functions/API';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      fontSize: '1.3rem',
    },
  },
  table: {
    minWidth: 650,
    border: '3px solid black',
  },
  cell: {
    '& .MuiTableCell-root': {
      color: 'red',
    },
  },
}));
const Sizes = ({ sizes }) => {
  // const [sizes, setSizes] = useState([]);

  const classes = useStyles();

  // useEffect(() => {
  //   get();
  // }, []);

  const columns = [
    { title: 'Size', field: 'size' },
    { title: 'Received', field: 'countNum' },
    { title: 'Printed', field: 'stacked' },
    { title: 'Initials', field: 'initials' },
    { title: 'Folded', field: 'stacked' },
    { title: 'Initials', field: 'initials' },
    { title: 'Bag/Box', field: 'stacked' },
    { title: 'Initials', field: 'initials' },
  ];
  return (
    <Table className={classes.table} size={'small'}>
      <TableHead>
        <TableRow style={{ borderBottom: '1px solid black' }}>
          {columns.map((row, key) => (
            <TableCell key={key} style={key % 2 === 1 ? { borderRight: '3px solid black' } : { borderRight: '1px solid black' }}>
              {row.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sizes.map((row, key) => (
          <TableRow key={key} style={{ borderBottom: '1px solid black' }}>
            {columns.map((column, key) => (
              <TableCell key={key} style={key % 2 === 1 ? { borderRight: '3px solid black' } : { borderRight: '1px solid black' }}>
                {row[column.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Sizes;

Sizes.propTypes = {
  sizes: PropTypes.array,
};
