export function convertDateToSQLDate(date) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString().slice(0, 19).replace('T', ' ');
}

export function sqlDateString(date) {
  return convertDateToSQLDate(date).split(' ')[0];
}

export function sqlDateTimeString(date) {
  const [d, t] = convertDateToSQLDate(date).split(' ');
  return `${d} ${t}`;
}
