/* eslint react/prop-types:0 */
import React from 'react';
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import DateFilter from '../DateFilter';

const AdvancedFilters = ({
  advancedCheckBoxData,
  handleCheckBoxChange,
  isLoading,
  startDate,
  endDate,
  handleDatePickerChange,
  handleFilterByDateChange,
  filterByDate,
  dateFilterType,
  changeDateFilterType,
}) => {
  return (
    <div className="advanced-fitlers-container">
      <Typography variant="body1" style={{ fontWeight: 500, margin: '12px 0' }}>
        Additional Filters
      </Typography>
      <FormControl component="fieldset">
        <FormGroup>
          {advancedCheckBoxData.map((x) => {
            return (
              <FormControlLabel
                // This is disabled to avoid async issues with fast consecutive clicks
                disabled={isLoading}
                control={<Checkbox checked={x.isChecked} onChange={() => handleCheckBoxChange(x.id)} value={x.name} color="primary" />}
                label={x.name}
                key={x.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <div className="filter-by-date-header" style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" display="inline" style={{ fontWeight: 500, margin: '12px 0', marginRight: '10px' }}>
          Filter By Date
        </Typography>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              // This is disabled to avoid async issues with fast consecutive clicks
              disabled={isLoading}
              control={<Checkbox checked={filterByDate} onChange={() => handleFilterByDateChange()} color="primary" />}
            />
          </FormGroup>
        </FormControl>
      </div>
      <ButtonGroup
        disabled={isLoading || !filterByDate}
        size="small"
        color="primary"
        aria-label="outlined primary button group"
        style={{ margin: '10px 0', marginRight: '10px' }}
      >
        <Button
          variant={dateFilterType === 'Due' ? 'contained' : 'outlined'}
          onClick={() => changeDateFilterType('Due')}
          style={{ lineHeight: '18px' }}
        >
          Due Date
        </Button>
        <Button
          variant={dateFilterType === 'Create' ? 'contained' : 'outlined'}
          onClick={() => changeDateFilterType('Create')}
          style={{ lineHeight: '18px' }}
        >
          Creation Date
        </Button>

        <Button
          variant={dateFilterType === 'Invoice' ? 'contained' : 'outlined'}
          onClick={() => changeDateFilterType('Invoice')}
          style={{ lineHeight: '18px' }}
        >
          Invoice Date
        </Button>
      </ButtonGroup>
      <DateFilter startDate={startDate} endDate={endDate} handleDatePickerChange={handleDatePickerChange} disabled={isLoading || !filterByDate} />
    </div>
  );
};

export default AdvancedFilters;
