/* eslint react/prop-types:0 */
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

const OrderProcessorFilters = ({ orderProcessors, csrFilter, csrOnClick }) => {
  return (
    <div className="order-processor-filter-container">
      <FormControl component="fieldset">
        <FormGroup>
          {orderProcessors.map((x) => {
            return (
              <FormControlLabel
                control={<Checkbox checked={csrFilter === x.uid} onChange={() => csrOnClick(x.uid)} value={x.name} color="primary" />}
                label={`${x.firstName} ${x.lastName}`}
                key={`${x.firstName} ${x.lastName}`}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default OrderProcessorFilters;
