/* eslint-disable react/display-name,react/jsx-key,react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Filter, CallMade } from '@material-ui/icons';
import { get } from '../../../Functions/API.js';
import { infoPlusUrl } from '../../../Constants/info-plus.js';
import Barcode from 'react-barcode';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [height, setHeight] = useState(window.innerHeight - 110);

  const history = useHistory();

  useEffect(() => {
    get('/integrations/info-plus/jobs', { filter: 'status in ("Started", "In Progress") and custom.type eq Art' }).then(setJobs);
  }, []);

  const download = async (id) => {
    const { outputs } = await get(`/integrations/info-plus/jobs/${id}`);
    outputs.map(({ customFields }) => {
      const { printUrl } = customFields;
      saveAs(printUrl);
    });
  };

  const columns = [
    { field: 'id', headerName: 'Job Id' },
    { field: 'createDate', headerName: 'Date Created', type: 'date', valueGetter: ({ value }) => value && new Date(value) },
    { field: 'status', headerName: 'Status' },
    {
      field: 'outputs',
      headerName: 'Quantity',
      valueGetter: ({ value }) => value?.length,
    },
    {
      field: 'jobBarcode',
      headerName: 'Job Barcode',
      renderCell: ({ row }) => <Barcode value={row.id} width={5} displayValue={false} />,
      width: 400,
    },
    {
      field: 'receivingBarcode',
      headerName: 'Receiving Barcode',
      renderCell: ({ row }) => <Barcode value={`JOB-${row.id}`} width={5} displayValue={false} />,
      width: 500,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ row }) => [
        <GridActionsCellItem icon={<Filter />} onClick={() => download(row.id)} label="Download" />,
        <GridActionsCellItem icon={<CallMade />} onClick={() => window.open(`${infoPlusUrl}/jobs/job/${row.id}`)} label="Go to InfoPlus" />,
      ],
    },
  ];

  return (
    <div style={{ padding: '10px', height: `${height}px` }}>
      <Breadcrumbs>
        <Link href={'/info-plus'}>InfoPlus Home</Link>
        <Typography>Jobs</Typography>
      </Breadcrumbs>
      <DataGrid
        rows={jobs}
        columns={columns}
        rowHeight={200}
        components={{ Toolbar: GridToolbar }}
        onRowDoubleClick={(params) => history.push(`/info-plus/jobs/${params.id}`)}
      />
    </div>
  );
};

export default Jobs;
