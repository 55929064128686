import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import Contacts from './Contacts';
import UserStore from '../../../../../Stores/User/Store';
import ShipMethod from './ShipMethod';
import SalesType from './SalesType';
import LeadType from './LeadType';
import { updateWorkOrder } from '../../../../../Stores/WorkOrder/Actions';
import { addNotification } from '../../../../../Stores/Notifications/Actions';
import CustomerStore from '../../../../../Stores/Customer/Store';
import WOSwitch from '../Left/WOSwitch';
import { post } from '../../../../../Functions/API.js';
import { createSnack } from '../../../../../Stores/Snack/Actions.js';
import { Tooltip } from '@mui/material';

const Middle = ({ workOrder }) => {
  const user = UserStore.getUser();
  const [customer, setCustomer] = useState({});
  const { group } = user;
  const [viewBillingAddress, setViewBillingAddress] = useState(false);

  useEffect(() => {
    CustomerStore.on('change', getCustomer);
    getCustomer();
    return () => {
      CustomerStore.removeListener('change', getCustomer);
    };
  }, []);

  const getCustomer = () => {
    const newCustomer = { ...CustomerStore.getCustomer() };
    setCustomer(newCustomer);
  };

  const cancelWorkOrder = (type) => {
    const canceled = !workOrder.canceled;
    let permission = type === 'Admin' || type === 'Accounting Admin';
    if (permission) {
      const c = window.confirm(
        canceled
          ? 'Are you sure you want to cancel this work order? It will be saved for records but will no longer appear on the homepage.'
          : 'Are you sure you want to reopen this work order?',
      );
      if (c) {
        updateWorkOrder(workOrder.id, { canceled });
        addNotification('Success', `Work order ${workOrder.workOrderID} has been ${canceled ? 'canceled' : 'reopened'}`, null, user, workOrder);
      }
    } else {
      addNotification(
        'Admin',
        `${user.firstName} ${user.lastName} requested for work order ${workOrder.workOrderID} be ${canceled ? 'canceled.' : 'reopened.'}`,
        null,
        user,
        workOrder,
      );
    }
  };

  const sendToInfoPlus = async () => {
    const c = confirm('Are you sure you want to send this to InfoPlus?');
    if (c) {
      const { id } = workOrder;
      await post(`/workorder/sendToInfoPlus/${id}`);
      createSnack('Success', 'Successfully sent work order to InfoPlus');
    } else {
      createSnack('Error', 'Sending work order to InfoPlus canceled');
    }
  };

  return (
    <Card style={{ height: '550px', overflowY: 'scroll' }}>
      <CardContent>
        {group && (
          <Grid container spacing={2} justify="space-evenly" alignItems="stretch">
            <Grid item container>
              <Contacts viewBillingAddress={true} />
            </Grid>

            <Grid item container spacing={1} justify="space-evenly" alignItems="center">
              <Grid item>
                <ShipMethod workOrder={workOrder} editable />
              </Grid>
              <Grid item>
                <SalesType workOrder={workOrder} />
              </Grid>
              <Grid item>
                <LeadType workOrder={workOrder} />
              </Grid>
            </Grid>

            <Grid item container spacing={1} justify="space-evenly" alignItems="center">
              <Grid item>
                <WOSwitch workOrder={workOrder} title={'Rush Job'} type={'rushJob'} access />
              </Grid>
              <Grid item>
                <WOSwitch workOrder={workOrder} type={'onHold'} title={'On Hold'} access={true} />
              </Grid>
              <Grid item>
                <WOSwitch workOrder={workOrder} type={'bagByTeam'} title={'Bag By Team'} access={true} />
              </Grid>
              {Boolean(customer.locked) && (
                <Grid item>
                  <WOSwitch workOrder={workOrder} title={'Unlock'} access={['Accounting Admin', 'Admin'].includes(group)} type={'overruled'} />
                </Grid>
              )}

              {['Accounting Admin', 'Admin'].includes(group) && (
                <Grid item>
                  <FormControl component="div">
                    <FormControlLabel
                      control={
                        <Switch
                          id="viewBillingAddress"
                          size={'small'}
                          checked={Boolean(viewBillingAddress)}
                          onChange={() => setViewBillingAddress(!viewBillingAddress)}
                          value={Boolean(viewBillingAddress)}
                          color="primary"
                        />
                      }
                      label={<Typography style={{ fontSize: 'small' }}>Billing</Typography>}
                      labelPlacement="top"
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <>
              {workOrder.canceled ? (
                <Grid item>
                  <Button variant="contained" href="" onClick={() => cancelWorkOrder(group)}>
                    {group !== 'Admin' && group !== 'Accounting Admin' ? 'Request Reopen Work Order' : 'Reopen Work Order'}
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button variant="contained" href="" onClick={() => cancelWorkOrder(group)}>
                    {group !== 'Admin' && group !== 'Accounting Admin' ? 'Request Cancel Work Order' : 'Cancel Work Order'}
                  </Button>
                </Grid>
              )}
            </>
            {user.hasPermission('sendWorkOrderToInfoPlus') && !customer?.lobId ? (
              <Grid item>
                <Tooltip title={'Customer not linked to InfoPlus'}>
                  <span>
                    <Button variant={'contained'} color={'primary'} disabled onClick={sendToInfoPlus}>
                      Send to InfoPlus
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Button variant={'contained'} color={'primary'} onClick={sendToInfoPlus}>
                  Send to InfoPlus
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Middle;

Middle.propTypes = {
  workOrder: PropTypes.object,
};
