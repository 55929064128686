/* eslint max-len:0 */
/* eslint no-console:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable, { MTableBodyRow, MTableToolbar } from 'material-table';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

class WebRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roster: [],
      image: '',
    };
  }

  componentDidMount() {
    const headers = new Headers();
    const username = 'ck_ab24ff55f106f3644ddd10e6fea45a23636cefbe';
    const password = 'cs_5465a9ee25991b32e29eb58aa858309d15e2de1f';

    headers.set('Authorization', `Basic ${btoa(`${username}:${password}`)}`);
    fetch(`https://www.ybashirts.com/wp-json/wc/v2/orders/${this.props.webOrderID}`, {
      headers,
    }).then(async (results) => {
      const returnedData = await results.json();
      console.log(returnedData);
      const roster = returnedData.meta_data.find((data) => data.key === 'roster').value;
      const { image } = returnedData.meta_data.find((data) => data.key === 'design_customization').value;
      this.setState({
        roster,
        image,
      });
    });
  }

  render() {
    const columns = [
      { title: 'Type', field: 'variation_name' },
      { title: 'Name', field: 'player_name' },
      { title: 'Number', field: 'player_number' },
    ];
    return (
      <Paper
        style={{
          position: 'absolute',
          width: '90%',
          padding: '15px',
          height: '50%',
          overflowX: 'auto',
        }}
      >
        <Grid container spacing={10} justify="center">
          <Grid item xs={3}>
            <img style={{ width: '300px' }} src={this.state.image} alt="" />
          </Grid>
          <Grid item xs={6}>
            <MaterialTable title="Roster" columns={columns} data={this.state.roster} options={{ paging: false }} />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default WebRoster;
