import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import React from 'react';

class Store extends EventEmitter {
  constructor() {
    super();
    this.products = [];
  }

  getProducts() {
    return this.products;
  }

  loadProducts = (products) => {
    this.products = products;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_PRODUCTS': {
        this.loadProducts(action.results);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
