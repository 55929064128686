/* eslint-disable react/display-name,react/prop-types */
import { closeDialog } from '../../../Stores/Dialog/Actions';
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { AppBar, Button, DialogActions, Grid, Toolbar } from '@material-ui/core';
import { createSnack } from '../../../Stores/Snack/Actions';
import VendorAutocomplete from '../../Vendors/VendorAutocomplete';
import VendorNugget from '../../Vendors/VendorNugget';
import { post } from '../../../Functions/API';
import { updateLineItems } from '../../../Stores/Fulfillment/LineItems/Actions';
import { getEstimatedCost } from '../../../Functions/fulfillment';

// todo: thoughts * do we need to force passing in the statusId??

const CostingTable = ({
  storeId,
  selected,
  refresh,
  type,
  statusId,
  lineItemColumns = [
    { title: 'brand', field: 'brand' },
    { title: 'color', field: 'color' },
    { title: 'style', field: 'style' },
    { title: 'sku', field: 'sku' },
    { title: 'description', field: 'description' },
    { title: 'size', field: 'size' },
    { title: 'Quantity', field: 'quantity' },
  ],
}) => {
  const [data, setData] = useState({});
  const [costs, setCosts] = useState([]);
  const [summary, setSumary] = useState([]);

  const getGroupData = () => {
    post('/fulfillment/lineItems/group', { lineItems: selected, type })
      .then((res) => {
        console.log(res);
        setData(res);
      })
      .catch((err) => {
        const message = err?.message ? err.message : 'Could not load';
        createSnack('Error', message);
      });
  };

  useEffect(() => {
    getGroupData();
  }, []);

  useEffect(() => {
    setCosts(data.costs);
    setSumary(data.results);
  }, [data]);

  const costColumns = [
    { title: 'Quantity', field: 'totalQuantity', type: 'numeric' },
    {
      title: 'Vendor',
      field: 'vendorID',
      editComponent: (t) => {
        const onChange = (newValue) => {
          t.onChange(newValue);
        };
        return <VendorAutocomplete callback={onChange} initValue={t.value} />;
      },
      render: (rowData) => {
        return <VendorNugget rowData={rowData} />;
      },
    },
    {
      title: 'per Unit',
      field: 'perUnit',
      type: 'numeric',
      render: (rowData) => (parseFloat(rowData.perUnit) ? parseFloat(rowData.perUnit).toFixed(2) : 'N/A'),
    },
    {
      title: 'Shipping Cost',
      field: 'shippingCost',
      type: 'numeric',
      render: (rowData) => ('$' + rowData.shippingCost ? rowData.shippingCost : 0),
    },
    {
      title: 'Estimated Cost',
      field: 'totalEstimatedCost',
      type: 'currency',
      editable: 'never',
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(getEstimatedCost(rowData))}</div>;
        }
      },
    },
    {
      title: 'Actual Cost',
      field: 'totalActualCost',
      type: 'numeric',
      editable: 'always',
      render: (rowData) => {
        if (rowData) {
          return <div>${parseFloat(rowData.totalActualCost) ? parseFloat(rowData.totalActualCost).toFixed(2) : rowData.totalActualCost}</div>;
        }
      },
    },
    {
      title: 'Notes',
      field: 'notes',
      type: 'string',
    },
  ];

  const addToGroup = () => {
    const costGroupName = window.prompt('What would you like to name this Cost Group?');
    if (!costGroupName) return;
    const costsCopy = [...costs];
    const body = {
      items: selected.map((lineItem) => {
        return {
          entityType: 'FulfillmentOrderLineItem',
          entityId: lineItem.id,
        };
      }),
      name: costGroupName,
      storeId: storeId,
      // todo: ¿MUST have a statusId ?
      statusId,
      costs: costsCopy.map((cost) => {
        delete cost.tableData;
        return cost;
      }),
    };
    console.log('body', body);
    post('/fulfillment/costGroups', body)
      .then((res) => {
        console.log('GROUP RES:', res);
        updateLineItems(selected).then(refresh);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
    closeDialog();
  };

  return (
    <div style={{ margin: '8px' }}>
      <MaterialTable title="Line Item Summary" options={{ padding: 'dense', pageSize: 10 }} columns={lineItemColumns} data={summary} />
      <MaterialTable
        title="Costs"
        columns={costColumns}
        data={costs}
        options={{ padding: 'dense' }}
        editable={{
          onRowAdd: async (newData) => {
            setCosts([...costs, newData]);
          },
          onRowUpdate: async (newData, oldData) => {
            const costCopy = [...costs];
            const index = costs.indexOf(oldData);
            costCopy[index] = newData;
            setCosts(costCopy);
          },
          onRowDelete: async (oldData) => {
            console.log(oldData);
          },
        }}
      />
      <br />
      <AppBar position={'sticky'} style={{ backgroundColor: '#eaeaea', bottom: 0 }}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <DialogActions>
                <Button autoFocus onClick={closeDialog} color={'secondary'}>
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
            <Grid item>
              <DialogActions>
                <Button autoFocus onClick={addToGroup} color={'primary'}>
                  Add
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CostingTable;
