import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { get } from '../../../../../Functions/API';
import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { Avatar, Typography, Paper, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { statuses } from '../../../../../Constants/statuses';

const HistoryOption = ({ optionOverride }) => {
  const { option, WorkOrderId, entityId } = useParams();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const filter = {
      eager: {
        user: {},
        $where: {
          entityType: optionOverride ?? option,
          entityId: entityId ?? WorkOrderId,
        },
      },
      order: 'createdAt desc',
    };
    get('/audit-logs', { filter }).then((results) => {
      const h = results.map((h) => {
        const temp = JSON.parse(h.body);
        delete temp.lastModifiedBy;
        delete temp.deliverTo;
        if (temp.statusId) {
          const status = statuses.find((s) => s.id === temp.statusId);
          delete temp.statusId;
          temp.status = status.name;
        }
        let json = Object.keys(temp).map((key) => {
          if (typeof temp[key] === 'boolean') {
            temp[key] = temp[key] ? 'True' : 'False';
          }
          const result = key.replace(/([A-Z])/g, ' $1');
          const newKey = result.charAt(0).toUpperCase() + result.slice(1);
          return { [newKey]: temp[key] };
        });
        json = json.reduce((r, c) => Object.assign(r, c), {});
        return { ...h, createdAt: new Date(h.createdAt), json };
      });
      console.log(h);
      setHistory(h);
    });
  }, [option, WorkOrderId, optionOverride]);

  return (
    <div style={{ maxWidth: '700px', margin: 'auto' }}>
      <Timeline align={'alternate'}>
        {history.map(
          (h, key) =>
            Object.keys(h.json).length > 0 && (
              <TimelineItem key={key}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {h.createdAt.toDateString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {h.createdAt.toLocaleTimeString()}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>
                    <Tooltip title={h.user ? `${h.user.firstName} ${h.user.lastName}` : 'None'}>
                      <Avatar>{h.user ? `${h.user.firstName[0]}${h.user.lastName[0]}` : 'NA'}</Avatar>
                    </Tooltip>
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ padding: '6px 16px' }}>
                    <List dense>
                      {Object.keys(h.json).map((k, key) => (
                        <ListItem key={key}>
                          <ListItemText primary={h.json[k]} secondary={k} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ),
        )}
      </Timeline>
    </div>
  );
};

export default HistoryOption;

HistoryOption.propTypes = {
  optionOverride: PropTypes.string,
};
