/* eslint-disable react/display-name,react/jsx-key,react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { CloudDownload } from '@material-ui/icons';
import { get } from '../../../../Functions/API.js';
import { storage } from '../../../../fire';
import { saveAs } from 'file-saver';

const Job = () => {
  const [data, setData] = useState([]);
  const [height, setHeight] = useState(window.innerHeight - 110);

  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 110);
    };

    get(`/integrations/info-plus/jobs/${id}`).then(({ outputs }) => {
      const d = outputs.map((output, id) => ({ id, ...output }));
      console.log(d);
      setData(d);
    });

    window.addEventListener('resize', handleResize);
  }, [id]);

  const download = async (name, url) => {
    console.log(name);
    const decoded = decodeURIComponent(url);
    if (decoded.includes('firebasestorage')) {
      const attributes = decoded.split('/');
      console.log(attributes);
      const bucketIndex = attributes.findIndex((att) => att === 'b') + 1;
      const pathIndex = attributes.findIndex((att) => att === 'o') + 1;
      const bucket = attributes[bucketIndex];
      const path = attributes[pathIndex];
      const ref = storage.refFromURL(url);
      console.log(ref);
      ref.getDownloadURL().then((url) => saveAs(url, `${name}.png`));
    }
  };

  const columns = [
    { field: 'sku', headerName: 'Sku' },
    { field: 'quantity', headerName: 'Quantity' },
    {
      field: 'image',
      headerName: 'Image',
      valueGetter: ({ row }) => row.customFields.printUrl,
      renderCell: (params) => <img style={{ height: '200px' }} src={params.value} />,
      width: 400,
    },
    {
      field: 'customFields',
      headerName: 'Link',
      valueGetter: ({ value }) => value.printUrl,
      renderCell: (params) => {
        return (
          <Link href={params.value} download="Logo.png">
            View
          </Link>
        );
      },
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'Download',
    //   getActions: ({ row }) => [
    //     <GridActionsCellItem icon={<CloudDownload />} onClick={() => download(row.sku, row.customFields.printUrl)} label="Download" />,
    //   ],
    // },
  ];

  return (
    <div style={{ height: `${height}px`, padding: '10px', width: '100%' }}>
      <Breadcrumbs>
        <Link href="/info-plus">InfoPlus Home</Link>
        <Link href="/info-plus/jobs">Jobs</Link>
        <Typography>{id}</Typography>
      </Breadcrumbs>
      <DataGrid rows={data} columns={columns} rowHeight={200} />
    </div>
  );
};

export default Job;
