import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import Customer from '../Customers';
import Description from './Description';
import OrderType from './OrderType';
import DueDate from './DueDate';
import Inventory from './Inventory';
import Printers from './Printers';
import Sales from './Sales';
import SalesAssistants from './SalesAssistants';
import WOSwitch from './WOSwitch';
import UserStore from '../../../../../Stores/User/Store';
import WorkOrderStore from '../../../../../Stores/WorkOrder/Store';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReprintDate from './ReprintDate.js';

const Left = () => {
  const user = UserStore.getUser();
  const [workOrder, setWorkOrder] = useState({});
  useEffect(() => {
    WorkOrderStore.on('change', getWorkOrder);
    getWorkOrder();
  }, []);
  const getWorkOrder = () => {
    setWorkOrder(WorkOrderStore.getWorkOrder());
  };
  return (
    <Card style={{ height: '550px', overflowY: 'scroll' }}>
      <CardHeader title={workOrder.workOrderID} subheader={'Work Order ID'} />
      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Customer workOrder={workOrder} />
          </Grid>
          <Grid item xs={6} xl={6}>
            <OrderType workOrder={workOrder} />
          </Grid>
          <Grid item xs={6} xl={6}>
            <DueDate workOrder={workOrder} />
          </Grid>
          {Boolean(workOrder.misprint) && (
            <Grid item xs={12}>
              <ReprintDate workOrder={workOrder} />
            </Grid>
          )}
          {workOrder.orderType === 'inventory' && (
            <Grid item xs={12}>
              <Inventory workOrder={workOrder} />
            </Grid>
          )}
          <Grid item xs={12} xl={12}>
            <Description workOrderID={workOrder.id} description={workOrder.description} editable />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <Sales workOrder={workOrder} />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <SalesAssistants workOrder={workOrder} />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <Printers workOrder={workOrder} />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <WOSwitch workOrder={workOrder} type={'misprint'} title={'Misprint'} access={true} />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <WOSwitch
              workOrder={workOrder}
              type={'costAndInvoiceComplete'}
              title={'Cost And Invoice Ready'}
              access={user.hasPermission('costAndInvoiceCompleteButton')}
            />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <WOSwitch workOrder={workOrder} type={'salesApproved'} title={'Sales Approved'} access={user.hasPermission('salesApprovedButton')} />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <WOSwitch
              workOrder={workOrder}
              type={'cspCostReviewed'}
              title={'CSP Costs Reviewed'}
              access={user.hasPermission('cspCostsReviewedButton')}
            />
          </Grid>
          <Grid item xs={6} xl={3} style={{ textAlign: 'center' }}>
            <WOSwitch
              workOrder={workOrder}
              type={'productionComplete'}
              title={'Production Complete'}
              access={
                user.hasPermission('productionCompleteButton') ||
                (workOrder.productionComplete && user.hasPermission('uncheckProductionComplete')) ||
                workOrder.orderType === 'fulfillment' ||
                workOrder.shipMethod === 'dropShip' ||
                workOrder.orderType === 'embroidery' ||
                workOrder.orderType === 'sublimation'
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Left;

Left.propTypes = {
  workOrder: PropTypes.object,
};
