import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  LinearProgress,
  ListItemText,
  List,
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { get, post, patch, remove } from '../../Functions/API';
import DropZone from '../WorkOrder/Footer/Components/Generate/DropZone';
import UserStore from '../../Stores/User/Store';
import { createSnack } from '../../Stores/Snack/Actions';
import IndividualArtCard from './IndividualArtCard';
import { fetchPrintGroups } from '../../Stores/PrintGroups/Actions';
import { Autocomplete } from '@material-ui/lab';

const IndividualArt = ({
  workOrder,
  csp,
  chips,
  setChips,
  setOrdersByArt,
  setOrderModal,
  setArtForOrderArtSummaryModal,
  openModal,
  toggleReversible,
  toggleUnderLay,
  toggleWeed,
  updateArt,
}) => {
  const { WorkOrderId } = useParams();
  const [updateArtModal, setUpdateArtModal] = useState(false);
  const [updatingChip, setUpdatingChip] = useState({});
  const user = UserStore.getUser();
  const [artLoading, setArtLoading] = useState([]);
  const [newChip, setNewChip] = useState({
    printType: '',
    position: '',
    type: '',
    ink: '',
    color: '',
    numbers: '',
    numbersArr: [],
    medWeed: false,
    hardWeed: false,
    reversible: false,
    specialInk: false,
    underlay: false,
  });
  const [printGroups, setPrintGroups] = useState([]);

  useEffect(() => {
    fetchPrintGroups().then(setPrintGroups);
  });

  const toggleNewUnderLay = () => {
    setNewChip({ ...newChip, underlay: !newChip.underlay });
  };

  const toggleNewReversible = () => {
    setNewChip({ ...newChip, reversible: !newChip.reversible });
  };

  const toggleNewMedWeed = () => {
    setNewChip({ ...newChip, medWeed: !newChip.medWeed });
  };

  const toggleNewHardWeed = () => {
    setNewChip({ ...newChip, hardWeed: !newChip.hardWeed });
  };

  const deleteArt = (event, item) => {
    const c = window.confirm('Are you sure you want to delete this art?');
    if (c) {
      remove(`/workOrderArt/${item.id}`)
        .then(() => {
          const localChips = [...chips];
          localChips.splice(localChips.indexOf(item), 1);
          setChips(localChips);
        })
        .catch((err) => {
          createSnack('Error', err.message);
        });
    }
  };

  const copyArt = (chip) => {
    const localChips = [...chips];
    const updateChip = { ...chip };
    delete updateChip['saved'];
    delete updateChip['id'];
    post('/workOrderArt', {
      workOrderID: WorkOrderId,
      ...updateChip,
    })
      .then((results) => {
        localChips.push({ ...results, saved: true });
        setChips(localChips);
        createSnack('Success', 'Artwork Copied');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const viewOrders = (chip) => {
    get(`/workOrderArt/${chip.id}`)
      .then((updatedChip) => {
        setArtForOrderArtSummaryModal(updatedChip);
        setOrdersByArt(updatedChip.orders);
        setOrderModal(true);
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const addArt = (event, chip) => {
    const localChips = [...chips];
    const chipData = { ...chip };
    delete chipData['numbersArr'];
    post('/workOrderArt', {
      workOrderID: workOrder.id,
      ...chipData,
    })
      .then((results) => {
        localChips.push({ ...results, saved: true });
        setChips(localChips);
        createSnack('Success', 'Artwork Uploaded');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const updateNewChip = (newValue, option) => {
    const localNewChip = { ...newChip };
    localNewChip[option] = newValue;
    if (option === 'printGroup') {
      localNewChip.printGroupId = newValue.id;
    }
    if (option === 'printType') {
      localNewChip.printTypeId = newValue.id;
    }
    setNewChip(localNewChip);
  };

  const updateIndividualArt = (newValue, chip, option) => {
    const items = [...chips];
    const index = items.indexOf(chip);
    items[index][option] = newValue;
    if (newValue?.id) {
      if (option === 'printGroup') {
        items[index].printGroupId = newValue.id;
      }
      if (option === 'printType') {
        items[index].printTypeId = newValue.id;
      }
    }

    items[index].saved = false;
    setChips(items);
  };

  const toggleArtToUpdate = (chip) => {
    if (!chip) {
      chip = {};
    }
    setUpdateArtModal(!updateArtModal);
    setUpdatingChip(chip);
  };

  const updateFile = (files) => {
    const localUpdatingChip = updatingChip;
    for (const file of files) {
      if (file) {
        const items = [...chips];
        const newChip = { name: file.fileName, ...localUpdatingChip, url: file.url };
        items.splice(items.indexOf(localUpdatingChip), 1, newChip);
        const chipToSend = { ...newChip };
        delete chipToSend['saved'];
        patch(`/workOrderArt/${localUpdatingChip.id}`, {
          workOrderID: workOrder.id,
          ...chipToSend,
        })
          .then((results) => {
            setChips(items);
            toggleArtToUpdate();
            createSnack('Success', 'Artwork Updated');
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
      }
    }
  };

  const dropFiles = async (files) => {
    const art = chips;
    const newArt = await Promise.all(
      files.map(async (file) => {
        return post('/workOrderArt', {
          workOrderID: workOrder.id,
          name: file.fileName,
          url: file.url,
        })
          .then((results) => {
            createSnack('Success', 'Artwork Uploaded');
            return results;
          })
          .catch((err) => {
            createSnack('Error', err.message);
          });
      }),
    );
    setChips([...art, ...newArt]);
  };

  return (
    <div>
      <div>
        <List>
          {artLoading.map((load, key) => (
            <ListItemText key={key} primary={<LinearProgress variant="determinate" value={load.progress} />} secondary={load.name.split('/')[1]} />
          ))}
        </List>
      </div>
      <Grid container spacing={3} justify="center" style={{ margin: 0 }}>
        {chips &&
          chips.map((item, key) => (
            <Grid key={key} item>
              <IndividualArtCard
                key={key}
                individualArt={item}
                updateIndividualArt={updateIndividualArt}
                updateArt={updateArt}
                updatingChip={updatingChip}
                csp={csp}
                toggleWeed={toggleWeed}
                toggleUnderLay={toggleUnderLay}
                openModal={openModal}
                toggleReversible={toggleReversible}
                deleteArt={deleteArt}
                updateFile={updateFile}
                copyArt={copyArt}
                viewOrders={viewOrders}
                toggleArtToUpdate={toggleArtToUpdate}
                WorkOrderId={WorkOrderId}
              />
            </Grid>
          ))}
        <Grid item>
          <Card
            style={{
              width: '345px',
              height: '750px',
              position: 'relative',
              border: '2px solid #ebebeb',
              backgroundColor: '#ebebeb',
            }}
          >
            <CardHeader
              title={
                <>
                  {printGroups && (
                    <Autocomplete
                      id="print-group"
                      style={{ paddingBottom: '8px' }}
                      value={newChip?.printGroup}
                      options={printGroups}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, v) => {
                        updateNewChip(v, 'printGroup');
                      }}
                      renderInput={(params) => <TextField {...params} label="Price List" variant="outlined" />}
                    />
                  )}
                </>
              }
              subheader={
                <>
                  {newChip?.printGroup?.printTypes && (
                    <Autocomplete
                      id="print-type"
                      value={newChip?.printType}
                      options={newChip?.printGroup?.printTypes}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, v) => {
                        updateNewChip(v, 'printType');
                      }}
                      renderInput={(params) => <TextField {...params} label="Print Type" variant="outlined" />}
                    />
                  )}
                </>
              }
            />
            <CardContent>
              <div style={{ height: '300px' }}>
                <DropZone autoUpload={true} type={'WorkOrderArt'} workOrderID={WorkOrderId} handleDrop={dropFiles} id="artFile1" />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: '100%' }}
                    label="Position"
                    onChange={(event) => updateNewChip(event?.target?.value, 'position')}
                    value={newChip.position}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: '100%' }}
                    label="Ink Color"
                    onChange={(event) => updateNewChip(event?.target?.value, 'color')}
                    value={newChip.color}
                  />
                </Grid>
                {Boolean(newChip?.printType?.number) && (
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      label="1-15 or 1,2,3"
                      onChange={(event) => updateNewChip(event?.target?.value, 'numbers')}
                      value={newChip.numbers}
                    />
                  </Grid>
                )}
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="reversible"
                        size="small"
                        checked={Boolean(newChip.reversible)}
                        onChange={toggleNewReversible}
                        value={Boolean(newChip.reversible)}
                        color="primary"
                      />
                    }
                    label={<Typography style={{ fontSize: 'smaller' }}>Reversible</Typography>}
                    labelPlacement="bottom"
                  />
                </Grid>
                {Boolean(newChip?.printGroup?.type === 'screenPrint') && (
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="underlay"
                          size="small"
                          checked={Boolean(newChip.underlay)}
                          onChange={toggleNewUnderLay}
                          value={Boolean(newChip.underlay)}
                          color="primary"
                        />
                      }
                      label={<Typography style={{ fontSize: 'smaller' }}>Underlay</Typography>}
                      labelPlacement="bottom"
                    />
                  </Grid>
                )}
                {Boolean(newChip?.printGroup?.type === 'heatPress') && (
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="medWeed"
                          size="small"
                          checked={Boolean(newChip.medWeed)}
                          onChange={toggleNewMedWeed}
                          value={Boolean(newChip.medWeed)}
                          color="primary"
                        />
                      }
                      label={<Typography style={{ fontSize: 'smaller' }}>M-Weed</Typography>}
                      labelPlacement="bottom"
                    />
                  </Grid>
                )}
                {Boolean(newChip?.printGroup?.type === 'heatPress') && (
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="hardWeed"
                          size="small"
                          checked={Boolean(newChip.hardWeed)}
                          onChange={toggleNewHardWeed}
                          value={Boolean(newChip.hardWeed)}
                          color="primary"
                        />
                      }
                      label={<Typography style={{ fontSize: 'smaller' }}>H-Weed</Typography>}
                      labelPlacement="bottom"
                    />
                  </Grid>
                )}
              </Grid>
              <CardActions style={{ position: 'absolute', right: 0, bottom: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={csp && workOrder.costAndInvoiceComplete}
                  onClick={(event) => addArt(event, newChip)}
                >
                  <AddIcon />
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

IndividualArt.propTypes = {
  workOrder: PropTypes.object,
  csp: PropTypes.object,
  chips: PropTypes.array,
  setChips: PropTypes.func,
  setArtForOrderArtSummaryModal: PropTypes.func,
  setOrdersByArt: PropTypes.func,
  setOrderModal: PropTypes.func,
  openModal: PropTypes.func,
  toggleReversible: PropTypes.func,
  toggleUnderLay: PropTypes.func,
  toggleWeed: PropTypes.func,
  updateArt: PropTypes.func,
};

IndividualArt.displayName = 'IndividualArt';

export default IndividualArt;
