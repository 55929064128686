import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

const OrderSetLine = ({ changeAllSet, updatePriceSet, updateAllBillSet, updateBillSet, width, line, lines }) => {
  const lineStyle = {
    margin: 'auto',
    marginBottom: '10px',
  };
  const flexContainer = {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.025)',
    justifyContent: 'center',
  };
  const flexItem = {
    width: '110px',
    margin: '10px',
    textAlign: 'center',
  };

  const largerTextSize =
    width > 1200
      ? { color: 'rgb(67, 160, 71)', fontSize: 'x-large', transformOriginY: '-20px' }
      : { color: 'rgb(67, 160, 71)', fontSize: 'medium', transformOriginY: '-20px' };
  return (
    <div style={lineStyle}>
      {lines.map((line, key) => (
        <div key={key}>
          <div style={flexContainer}>
            <div style={flexItem}>
              <TextField
                label="Type"
                value={line.type}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
            <div style={flexItem}>
              <TextField
                label="Vendor"
                value={line.vendor}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
            <div style={flexItem}>
              <TextField
                label="Color"
                value={line.color}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
            {line.adultStyle && (
              <div style={flexItem}>
                <TextField
                  label="AdultStyle"
                  value={line.adultStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  autoComplete="off"
                  component="div"
                />
              </div>
            )}
            {line.youthStyle && (
              <div style={flexItem}>
                <TextField
                  label="Youth Style"
                  value={line.youthStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  autoComplete="off"
                  component="div"
                />
              </div>
            )}
          </div>
          <div style={flexContainer}>
            <div style={flexItem}>
              <div style={{ padding: '9px' }}>
                <h3>Cost:</h3>
              </div>
            </div>
            <div style={flexItem}>
              <TextField
                label="Edit All"
                variant="outlined"
                type="number"
                onChange={(event) => changeAllSet(event, key, 'cost')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
            {line.sizes.map((size, key) => (
              <div key={key} style={flexItem}>
                <TextField
                  label={`${size.quantity} ${size.size.toUpperCase()}`}
                  variant="outlined"
                  value={size.cost}
                  onChange={(event) => updatePriceSet(event, line, size, 'cost')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                    style: largerTextSize,
                  }}
                  margin="normal"
                  autoComplete="off"
                  component="div"
                />
              </div>
            ))}
            <div style={flexItem}>
              <TextField
                id="standard-number"
                label="Total"
                value={line.sizes.reduce((total, size) => total + size.cost * size.quantity, 0).toFixed(2)}
                InputLabelProps={{
                  shrink: true,
                  style: largerTextSize,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
          </div>
        </div>
      ))}
      <div style={flexContainer}>
        <div style={flexItem}>
          <div style={{ padding: '9px' }}>
            <h3>Bill:</h3>
          </div>
        </div>
        <div style={flexItem}>
          <TextField
            label="Edit All"
            variant="outlined"
            type="number"
            onChange={updateAllBillSet}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            autoComplete="off"
            component="div"
          />
        </div>
        {line &&
          line.sizes.map((size, key) => (
            <div key={key} style={flexItem}>
              <TextField
                variant="outlined"
                value={size.bill}
                onChange={(event) => updateBillSet(event, size)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                  style: largerTextSize,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </div>
          ))}
        <div style={flexItem}>
          <TextField
            id="standard-number"
            value={line.sizes.reduce((total, size) => total + size.bill * size.quantity, 0).toFixed(2)}
            InputLabelProps={{
              shrink: true,
              style: largerTextSize,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="outlined"
            margin="normal"
            autoComplete="off"
            component="div"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSetLine;

OrderSetLine.propTypes = {
  changeAllSet: PropTypes.func.isRequired,
  updatePriceSet: PropTypes.func.isRequired,
  updateAllBillSet: PropTypes.func.isRequired,
  updateBillSet: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  line: PropTypes.object.isRequired,
  lines: PropTypes.object.isRequired,
};
