import dispatcher from './dispatcher';
import { get, patch } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';

export const getSales = (id) => {
  get(`/sales/${id}`)
    .then((sales) => {
      dispatcher.dispatch({
        type: 'LOAD_SALES',
        sales,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const updateSales = (id, sales) => {
  return patch(`/sales/${id}`, sales)
    .then((results) => {
      createSnack('Success', `Updated Sales`);
      dispatcher.dispatch({
        type: 'LOAD_SALES',
        sales: results,
      });
      return results;
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};
