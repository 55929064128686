import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import Jobs from './jobs';
import Job from './jobs/job';
import Asns from './asns';
import Lobs from './lobs';
import Items from './lobs/items';
import Products from './lobs/products';
import Skus from './lobs/products/skus';
import Art from './lobs/art';

const InfoPlus = () => {
  const history = useHistory();

  const locations = [
    {
      title: 'Asns',
      url: '/info-plus/asns',
      image: 'http://www.trekglobal.com/wp-content/uploads/shipping-and-receiving-department-warehouse.jpg',
    },
    {
      title: 'Art Jobs',
      url: '/info-plus/jobs',
      image: 'https://meprinter.com/wp-content/uploads/2021/07/Brother-DTF-2-2048x1363.jpg',
    },
    {
      title: 'Lines of Business',
      url: '/info-plus/lobs',
      image: 'https://meprinter.com/wp-content/uploads/2021/07/Brother-DTF-2-2048x1363.jpg',
    },
  ];

  return (
    <div>
      <Switch>
        <Route path="/info-plus/jobs/:id" component={(props) => <Job {...props} />} />
        <Route path="/info-plus/jobs" component={(props) => <Jobs {...props} />} />
        <Route path="/info-plus/asns" component={(props) => <Asns {...props} />} />
        <Route path="/info-plus/lobs/:lobId/items" component={(props) => <Items {...props} />} />
        <Route path="/info-plus/lobs/:lobId/art" component={(props) => <Art {...props} />} />
        <Route path="/info-plus/lobs/:lobId/products/:productId/skus" component={(props) => <Skus {...props} />} />
        <Route path="/info-plus/lobs/:lobId/products" component={(props) => <Products {...props} />} />
        <Route path="/info-plus/lobs" component={(props) => <Lobs {...props} />} />
        <Route path="/info-plus">
          <div style={{ padding: '10px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h3'}>InfoPlus Center</Typography>
              </Grid>
              {locations.map(({ title, url, image }, key) => (
                <Grid item xs={12} sm={6} lg={4} key={key}>
                  <Card>
                    <CardActionArea onClick={() => history.push(url)}>
                      <CardHeader title={title} />
                      <CardContent style={{ height: '300px' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={image} />
                      </CardContent>
                      <CardActions>
                        <Button variant={'contained'}>View</Button>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default InfoPlus;
