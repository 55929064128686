/* eslint-disable react/display-name */
/* eslint no-shadow:0 */
/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Checkbox, Snackbar, SnackbarContent } from '@material-ui/core';
import MaterialTable from 'material-table';
import Menu from '../../../Components/Menu';
import BetterFinancialGraphByCustomer from '../../../Components/Home/Accounting/CustomerComparison/BetterFinancialGraphByCustomer';

const Index = ({ get, post, user, match }) => {
  const id = match.params.customerId;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [co, setCo] = useState('');
  const [shippingStreet, setShippingStreet] = useState('');
  const [shippingCity, setShippingCity] = useState('');
  const [shippingState, setShippingState] = useState('');
  const [shippingZip, setShippingZip] = useState('');
  const [billingStreet, setBillingStreet] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '', variant: '' });

  const [workOrders, setWorkOrders] = useState([]);

  const setup = () => {
    get('/getCustomerInfo', { id }, 'obj')
      .then((state) => {
        setWholeState(state);
      })
      .catch((err) => {
        console.error(err.message);
      });
    get('/getWorkOrdersByCustomerId', { customerId: id }, 'obj')
      .then((workOrders) => {
        setWorkOrders(workOrders);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    setup();
  }, []);

  const setWholeState = (state) => {
    const {
      name,
      email,
      organization,
      co,
      shippingStreet,
      shippingCity,
      shippingState,
      shippingZip,
      billingStreet,
      billingCity,
      billingState,
      billingZip,
    } = state;
    setName(name);
    setEmail(email);
    setOrganization(organization);
    setCo(co);
    setShippingStreet(shippingStreet);
    setShippingCity(shippingCity);
    setShippingState(shippingState);
    setShippingZip(shippingZip);
    setBillingStreet(billingStreet);
    setBillingCity(billingCity);
    setBillingState(billingState);
    setBillingZip(billingZip);
  };

  const createSnack = (variant, message) => {
    const snackBar = { open: true, variant, message };
    setSnackBar(snackBar);
  };

  const handleClose = () => {
    const snackBar = { open: false, variant: '', message: '' };
    setSnackBar(snackBar);
  };

  const submit = () => {
    post(
      '/updateDefaultCustomer',
      {
        customerID: id,
        name,
        email,
        organization,
        co,
        shippingStreet,
        shippingCity,
        shippingState,
        shippingZip,
        billingStreet,
        billingCity,
        billingState,
        billingZip,
      },
      'obj',
    )
      .then(({ customer }) => {
        setWholeState(customer);
        createSnack('Success', 'Customer Successfully Updated');
      })
      .catch((err) => {
        createSnack('Error', err.message);
      });
  };

  const notificationStyles = {
    Accounting: '#43a047',
    Success: '#43a047',
    Sales: '#ffa000',
    Warning: '#ffa000',
    Error: '#d32f2f',
    Info: '#3f51b5',
    Art: '#ff5e00',
    Fulfillment: '#785589',
    Production: '#979797',
    File: '#979797',
    Temp: '#c7c7c7',
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent onClose={handleClose} style={{ backgroundColor: notificationStyles[snackBar.variant] }} message={snackBar.message} />
      </Snackbar>
      {/* <br /> */}
      {/* this is where the issue is? */}
      {/* <BetterFinancialGraphByCustomer get={get} customerIds={[{ id }]} name={name} /> */}
      <br />
      <MaterialTable
        title={`${name}'s Work Orders`}
        data={workOrders}
        columns={[
          { title: 'ID', field: 'workOrderID' },
          { field: 'createdDate', title: 'Created Date', type: 'date' },
          { title: 'Description', field: 'description' },
          {
            title: 'Balance',
            field: 'balance',
            render: (rowData) => `$${parseFloat(rowData.balance) ? parseFloat(rowData.balance).toFixed(2) : '0.00'}`,
          },
          { field: 'invoiceDate', title: 'Invoice Date', type: 'date' },
          { field: 'paidDate', title: 'Paid Date', type: 'date' },
          {
            title: 'Production Complete',
            field: 'productionComplete',
            render: (rowData) => <Checkbox color="primary" checked={Boolean(rowData.productionComplete)} />,
          },
          {
            title: 'Accounting Approved',
            field: 'accountingApproved',
            render: (rowData) => <Checkbox checked={Boolean(rowData.accountingApproved)} />,
          },
        ]}
        options={{ pageSizeOptions: [25, 50, 100], pageSize: 25 }}
        onRowClick={(event, rowData) => {
          window.open(`/workorder/${rowData.id}`, '_blank');
        }}
      />
    </div>
  );
};

export default Index;
