/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { AppBar, Badge, Tabs, Tab, Grid, IconButton, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { AddCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ContactForm from './ContactForm';
import { generateUniqueId } from '../../../Functions';
import { fetchStates } from '../../../Stores/States/Actions';
import { fetchCountries } from '../../../Stores/Countries/Actions';

const ContactsCreate = ({ getValues, append, remove, fields, control, contacts, setContacts, setValue }) => {
  const [shippingContacts, setShippingContacts] = useState([]);
  const [billingContacts, setBillingContacts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const removeContact = (contactIndex) => {
    remove(contactIndex);
  };

  const addContact = (type) => {
    append({
      id: generateUniqueId(),
      favorite: false,
      type,
      co: '',
      organization: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      state: { code: null, countryID: null, id: null, name: null },
      country: { code: null, id: null, name: null },
    });
  };

  const copyContact = (type, index) => {
    append({
      ...getValues()['contacts'][index],
      id: generateUniqueId(),
      type,
      contactsJoinId: null,
    });
  };

  const changeContact = async (index, updatedContact) => {
    setValue(`contacts[${index}].address1`, updatedContact.address1);
    setValue(`contacts[${index}].address2`, updatedContact.address2);
    setValue(`contacts[${index}].city`, updatedContact.city);
    setValue(`contacts[${index}].zip`, updatedContact.zip);
    const states = await fetchStates();
    const foundState = states.find((fState) => fState.name === updatedContact.state);

    setValue(`contacts[${index}].state`, foundState);
    const countries = await fetchCountries();
    const foundCountry = countries.find((fCountry) => fCountry.code === updatedContact.country);

    setValue(`contacts[${index}].country`, foundCountry);
  };

  useEffect(() => {
    setShippingContacts(
      fields
        .map((contact, index) => {
          contact.index = index;
          return contact;
        })
        .filter((contact) => contact.type === 'shipping'),
    );
    setBillingContacts(
      fields
        .map((contact, index) => {
          contact.index = index;
          return contact;
        })
        .filter((contact) => contact.type === 'billing'),
    );
  }, [fields]);

  return (
    <form autoComplete="off" style={{ width: '100%' }}>
      <TabContext value={activeTab}>
        <AppBar position="static">
          <Tabs centered value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)} indicatorColor="primary">
            <Tab
              style={{ padding: '15px' }}
              label={
                <Badge badgeContent={shippingContacts?.length}>
                  <Typography variant="h5">Shipping</Typography>
                </Badge>
              }
            />
            <Tab
              style={{ padding: '15px' }}
              label={
                <Badge badgeContent={billingContacts?.length}>
                  <Typography variant="h5">Billing</Typography>
                </Badge>
              }
            />
          </Tabs>
        </AppBar>
        <TabPanel value={0}>
          <Grid container spacing={4}>
            {Boolean(shippingContacts?.length) &&
              shippingContacts.map((contact) => (
                <ContactForm
                  getValues={getValues}
                  control={control}
                  contact={contact}
                  key={contact?.id}
                  index={contact?.index}
                  copyContact={copyContact}
                  removeContact={removeContact}
                  changeContact={changeContact}
                />
              ))}
            <Grid xs={12} sm={6} md={4} item>
              <Grid container justify="center" style={{ height: '100%' }} alignItems="center">
                <Grid item>
                  <IconButton>
                    <AddCircle onClick={() => addContact('shipping')} style={{ fontSize: 80 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={1}>
          <Grid container spacing={4}>
            {Boolean(billingContacts?.length) &&
              billingContacts.map((contact) => (
                <ContactForm
                  getValues={getValues}
                  control={control}
                  contact={contact}
                  key={contact?.id}
                  index={contact?.index}
                  copyContact={copyContact}
                  removeContact={removeContact}
                  changeContact={changeContact}
                />
              ))}
            <Grid xs={12} sm={6} md={4} item>
              <Grid container justify="center" style={{ height: '100%' }} alignItems="center">
                <Grid item>
                  <IconButton>
                    <AddCircle onClick={() => addContact('billing')} style={{ fontSize: 80 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </form>
  );
};

ContactsCreate.propTypes = {
  setValue: PropTypes.any,
  fields: PropTypes.any,
  control: PropTypes.any,
  contacts: PropTypes.array,
  setContacts: PropTypes.func,
};

ContactsCreate.displayName = 'ContactsCreate';

export default ContactsCreate;
