import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Checkbox, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography, withStyles } from '@material-ui/core';
import notificationStyles from '../../../../../Utils/NotificationStyles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Send } from '@material-ui/icons';
import * as NotificationsActions from '../../../../../../Stores/Notifications/Actions';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: 'none',
  },
}))(Tooltip);

const Notification = ({ notification, user, users, createdBy, workOrder }) => {
  notification.date = new Date(notification.date);
  return (
    <ListItem
      style={{
        paddingLeft: notification.parentId && createdBy && createdBy.uid !== user.uid ? '35px' : '0',
        marginTop: notification.parentId ? '-8px' : '0',
        justifyContent: createdBy && createdBy.uid === user.uid ? 'flex-end' : 'flex-start',
      }}
    >
      {createdBy && createdBy.uid !== user.uid && (
        <ListItemAvatar>
          <Tooltip placement="left" title={createdBy && `${createdBy.firstName} ${createdBy.lastName}`}>
            <Avatar>{createdBy && `${createdBy.firstName[0]}${createdBy.lastName[0]}`}</Avatar>
          </Tooltip>
        </ListItemAvatar>
      )}
      <Box>
        {createdBy && createdBy.uid !== user.uid && (
          <Typography style={{ fontSize: '0.75rem', lineHeight: 0.5, textAlign: createdBy && createdBy.uid === user.uid ? 'right' : 'left' }}>
            {createdBy && `${createdBy.firstName} ${createdBy.lastName}`}
          </Typography>
        )}
        <ListItemText
          style={{
            backgroundColor: notificationStyles[notification.type],
            borderRadius: '6px',
            padding: '8px 16px',
          }}
          disableTypography
          primary={<Typography variant={'body2'}>{notification.message}</Typography>}
          secondary={
            <Grid container spacing={1} alignItems="flex-end">
              {notification.type === 'Picture' && notification.url && (
                <Grid item xs={12}>
                  <img
                    src={notification.url}
                    onClick={() => window.open(notification.url, '_blank')}
                    style={{
                      margin: 'auto',
                      display: 'block',
                      maxWidth: '100%',
                      maxHeight: '200px',
                    }}
                  />
                </Grid>
              )}
              {createdBy && createdBy.uid === user.uid && (
                <Grid item>
                  <Link
                    component="button"
                    onClick={() => NotificationsActions.editNotification(notification, workOrder, user)}
                    style={{
                      fontSize: 'small',
                    }}
                    underline="hover"
                  >
                    Edit
                  </Link>
                </Grid>
              )}
              {notification.url && (
                <Grid item>
                  <Link
                    component="button"
                    onClick={() => window.open(notification.url, '_blank')}
                    style={{
                      fontSize: 'small',
                    }}
                  >
                    View
                  </Link>
                </Grid>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <Link
                  component="button"
                  onClick={() => NotificationsActions.addReplyNotification(notification, user, workOrder)}
                  style={{
                    fontSize: 'small',
                  }}
                  underline="hover"
                >
                  Reply
                </Link>
              </Grid>
              <Grid item>
                <Grid container>
                  {notification.deliverTo
                    .filter((r) => r.read)
                    .filter((r, i) => i < 3)
                    .map((u, key) => {
                      const user = users.find((user) => user.uid === u.uid);
                      return (
                        <Grid item key={key} style={{ marginRight: '2px' }}>
                          {user && (
                            <Tooltip placement="bottom" title={`${user.firstName} ${user.lastName}`}>
                              <Avatar
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  fontSize: 'xx-small',
                                }}
                              >
                                {`${user.firstName[0]}${user.lastName[0]}`}
                              </Avatar>
                            </Tooltip>
                          )}
                        </Grid>
                      );
                    })}
                  <HtmlTooltip
                    placement="bottom"
                    style={{ backgroundColor: 'transparent' }}
                    title={
                      <List dense>
                        {notification.deliverTo?.map((u, key) => {
                          const user = users.find((user) => user.uid === u.uid);
                          if (u.readAt) {
                            u.readAt = new Date(u.readAt);
                          }
                          return (
                            <ListItem key={key}>
                              <ListItemIcon>
                                <Checkbox size="small" edge="start" checked={u.read} tabIndex={-1} disableRipple />
                              </ListItemIcon>
                              <ListItemText
                                secondary={
                                  <>
                                    {user && <>{`${user.firstName} ${user.lastName}`}</>}
                                    {u.readAt && (
                                      <span
                                        style={{ fontSize: 'x-small' }}
                                      >{`${u.readAt.toLocaleDateString()} ${u.readAt.toLocaleTimeString()}`}</span>
                                    )}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    }
                  >
                    <Avatar
                      style={{
                        width: '20px',
                        height: '20px',
                        fontSize: 'xx-small',
                      }}
                    >
                      <Send style={{ fontSize: 'xx-small' }} />
                    </Avatar>
                  </HtmlTooltip>
                </Grid>
              </Grid>
              <Grid item>
                <HtmlTooltip title={new Date(notification.created_at)?.toLocaleTimeString()} placement="bottom">
                  <Typography variant={'body2'}>{new Date(notification.created_at)?.toDateString()}</Typography>
                </HtmlTooltip>
              </Grid>
              {(notification.createdBy === user.uid || user.hasPermission('deleteNotifications')) && (
                <Grid item>
                  <Link
                    component="button"
                    onClick={() => NotificationsActions.deleteNotification(notification, user, workOrder)}
                    style={{
                      fontSize: 'small',
                      color: 'black',
                    }}
                    underline="always"
                  >
                    Delete
                  </Link>
                </Grid>
              )}
            </Grid>
          }
        />
      </Box>
      {createdBy && createdBy.uid === user.uid && (
        <ListItemAvatar style={{ minWidth: 0, paddingLeft: '8px' }}>
          <Tooltip placement="left" title={createdBy && `${createdBy.firstName} ${createdBy.lastName}`}>
            <Avatar>{createdBy && `${createdBy.firstName[0]}${createdBy.lastName[0]}`}</Avatar>
          </Tooltip>
        </ListItemAvatar>
      )}
    </ListItem>
  );
};

export default Notification;

Notification.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  notification: PropTypes.object,
  workOrder: PropTypes.object,
  createdBy: PropTypes.object,
};
