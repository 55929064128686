/* eslint max-len:0 */
/* eslint react/no-array-index-key:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Badge, IconButton, Modal, Paper } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import ClockInAndOut from './ClockInAndOut';
import ErrorTable from './ErrorTable';
import Count from './Count';
import Inventory from './LineHeaders/inventory';
import Normal from './LineHeaders/normal';
import ViewContact from '../../../../Shared/Contacts/ViewContact';
const divStyle = { textAlign: 'left' };
const paperStyle = { width: '250px', backgroundColor: 'MediumSeaGreen' };

const PackagingLine = ({
  line,
  workOrder,
  workOrderID,
  updateInventory,
  num,
  openErrorModal,
  shipMethod,
  groupBy,
  toggleArt,
  updateFinishedInDB,
  updateQuantity,
  clockInLine,
  resetPackaging,
}) => {
  const [contact, setContact] = useState({});

  useEffect(() => {
    let newContact = workOrder.contacts.find((c) => c.id === line.contactId);
    setContact(newContact);
  }, [line, workOrder]);

  const getAddress2 = (contact) => {
    if (!contact) {
      return (
        <Paper elevation={3} style={{ ...paperStyle, backgroundColor: 'Tomato' }}>
          <Grid container direction="column" style={{ padding: '20px' }}>
            <div style={{ fontWeight: 'bolder' }}>No Address Selected</div>
          </Grid>
        </Paper>
      );
    }
    return (
      <Paper elevation={3} style={paperStyle}>
        <Grid container direction="column" style={{ padding: '20px' }}>
          <Grid>
            <div style={{ textAlign: 'left', paddingBottom: '15px', fontWeight: 'bolder', fontSize: 'large' }}>Ship To:</div>
          </Grid>
          <ViewContact contact={contact} />
        </Grid>
      </Paper>
    );
  };

  return (
    <div style={{ borderTop: '5px solid grey' }}>
      <div style={{ maxWidth: '1200px', margin: 'auto' }}>
        <div style={{ marginTop: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            {Boolean(line.misprint) && <h2 style={{ color: 'red' }}>Replacement Item</h2>}
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={8}>
                {workOrder.orderType === 'inventory' || workOrder.orderType === 'fulfillment' ? (
                  <Inventory groupBy={groupBy} line={line} workOrder={workOrder} />
                ) : (
                  <Normal groupBy={groupBy} line={line} workOrder={workOrder} />
                )}
              </Grid>
              {workOrder.contacts.filter((c) => c.type === 'shipping').length > 1 && <Grid item>{getAddress2(contact)}</Grid>}
            </Grid>
            <Badge badgeContent={line.artIDs ? line.art.length : '0'} color="secondary" style={{ marginTop: '10px', marginRight: '10px' }}>
              {line.showArt ? (
                <Button variant="contained" color="primary" href="" onClick={() => toggleArt(line)}>
                  Hide Art
                </Button>
              ) : (
                <Button variant="contained" color="primary" href="" onClick={() => toggleArt(line)}>
                  View Art
                </Button>
              )}
            </Badge>
          </div>
        </div>
        {line.showArt && (
          <div style={{ textAlign: 'center' }}>
            {line.artIDs &&
              line.art.map((chip, key) => (
                <div key={key} style={{ margin: '8px' }}>
                  <Button href={chip.url} target="_blank" color="primary" variant="contained">
                    {chip.name}
                  </Button>
                </div>
              ))}
          </div>
        )}
        {
          <div style={{ textAlign: 'center' }}>
            <ClockInAndOut
              type="packaging"
              // passes updateInventory to clockinAndOut then never uses it
              updateInventory={updateInventory}
              workOrder={workOrder}
              num={num}
              updateFinishedInDB={updateFinishedInDB}
              openErrorModal={openErrorModal}
              updateQuantity={updateQuantity}
              returnedId={line.returnedId}
              clockInLine={clockInLine}
              workOrderID={workOrderID}
              line={line}
              resetPackaging={resetPackaging}
            />
          </div>
        }
        <div>
          <Grid container justify="center" spacing={1}>
            {line.sizes.map((size, key) => (
              <Grid item xs={3} md={2} key={key}>
                <Badge
                  badgeContent={
                    size.quantity !== size.packageNum && (
                      <IconButton size="small" onClick={() => openErrorModal(line, size)} style={{ right: '5px', top: '15px' }}>
                        <Error style={{ color: 'red' }} fontSize="small" />
                      </IconButton>
                    )
                  }
                >
                  <TextField
                    label={`${size.quantity} x ${size.size.toUpperCase()}`}
                    error={size.quantity !== size.packageNum}
                    helperText={
                      size.quantity > size.packageNum
                        ? `Missing ${size.quantity - size.packageNum}`
                        : size.quantity < size.packageNum && `Extra ${size.packageNum - size.quantity}`
                    }
                    variant="outlined"
                    value={size.packageNum}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    autoComplete="off"
                    component="div"
                  />
                </Badge>
                <Modal open={size.errorModal} onClose={() => openErrorModal(line, size)} style={{ top: '10%' }}>
                  <ErrorTable size={size} close={() => openErrorModal(line, size)} />
                </Modal>
              </Grid>
            ))}
            <Grid item xs={3} md={2}>
              <TextField
                label={`Total: ${line.sizes.reduce((total, size) => total + size.quantity, 0)}`}
                error={
                  line.sizes.reduce((total, size) => total + size.quantity, 0) !== line.sizes.reduce((total, size) => total + size.packageNum, 0)
                }
                variant="outlined"
                value={line.sizes.reduce((total, size) => total + size.packageNum, 0)}
                // helperText={
                //   line.sizes.reduce((total, size) => total + size.quantity, 0) !== line.sizes.reduce((total, size) => total + size.packageNum, 0) &&
                //   `Missing ${
                //     line.sizes.reduce((total, size) => total + size.quantity, 0) - line.sizes.reduce((total, size) => total + size.packageNum, 0)
                //   }`
                // }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                autoComplete="off"
                component="div"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default PackagingLine;
