/* eslint no-console:0 */
/* eslint no-shadow:0 */
/* eslint array-callback-return:0 */
/* eslint no-underscore-dangle:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Popover,
  Select,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import commaNumber from 'comma-number';
import DateFilter from '../Common/DateFilter';
import materialColors from '../../Utils/MaterialColors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  chartContainer: {
    position: 'relative',
    height: '500px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
}));

const SalesFinancialChart = ({ get, refresh }) => {
  const [pieData, setPieData] = useState([]);
  const [salesData, setSalesData] = useState(null);
  const [data, setData] = useState();
  const [open, setOpen] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [selectedOption, setSelectedOption] = useState('This Month');
  const [showDatePicker, setShowDatePicker] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const options = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20,
      },
    },
    responsive: true,
    onClick: (e, item) => {
      if (item[0] && !salesData) {
        const { label } = item[0]._model;
        pieData.map((x) => {
          if (`${x.firstName} ${x.lastName}` === label) {
            setSalesData(x);
          }
        });
      }
    },
    maintainAspectRatio: false,
    cutoutPercentage: 60,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };

  const openInfo = (event) => {
    const open = !infoOpen;
    const anchorElThing = open ? event.target : null;
    setInfoOpen(open);
    setAnchorEl(anchorElThing);
  };

  const formatSalesData = () => {
    const newDataStuff = {
      datasets: [
        {
          data: [salesData.revenue, salesData.cost],
          backgroundColor: materialColors,
          borderWidth: 0,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white,
        },
      ],
      labels: ['Revenue', 'Cost'],
    };
    setData(newDataStuff);
  };

  const formatPieData = () => {
    const newData = [];
    const newLabels = [];
    pieData.map((x, i) => {
      newData.push(x.revenue);
      newLabels.push(`${x.firstName} ${x.lastName}`);
    });

    const newDataStuff = {
      datasets: [
        {
          data: newData,
          backgroundColor: materialColors,
          borderWidth: 0,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white,
        },
      ],
      labels: newLabels,
    };
    setData(newDataStuff);
  };

  const getSalesFinancials = () => {
    get('/getWorkOrderFinancialsBySalesPerson', [
      {
        name: 'startDate',
        value: moment(startDate).format('YYYY-MM-DD'),
      },
      {
        name: 'endDate',
        value: moment(endDate).format('YYYY-MM-DD'),
      },
      {
        name: 'month',
        value: moment(startDate).month() + 1,
      },
      {
        name: 'year',
        value: moment(startDate).year(),
      },
    ])
      .then((res) => {
        setPieData(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDatePickerChange = (date, start) => {
    if (start) {
      setStartDate(moment(date).format('YYYY/MM/DD'));
    } else {
      setEndDate(moment(date).format('YYYY/MM/DD'));
    }
  };

  const setDates = (e) => {
    const newSelection = e.target.value;
    setSelectedOption(newSelection);

    if (newSelection === 'This Month') {
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    }
    if (newSelection === 'Last Month') {
      setEndDate(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
      setStartDate(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
    }
    if (newSelection === 'All Time') {
      setEndDate(moment().format('YYYY-MM-DD'));
      setStartDate('2018-01-01');
    }
    if (newSelection === 'Custom Range') {
      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    getSalesFinancials();
  }, [startDate, endDate, refresh]);

  useEffect(() => {
    if (pieData) formatPieData();
  }, [pieData]);

  useEffect(() => {
    if (salesData) {
      formatSalesData();
    } else {
      formatPieData();
    }
  }, [salesData]);

  return (
    <>
      <Modal open={showDatePicker} onClose={() => setShowDatePicker(false)}>
        <div className="date-picker-container">
          <DateFilter startDate={startDate} endDate={endDate} handleDatePickerChange={handleDatePickerChange} />
          <div className="date-picker-button-container">
            <Button style={{ margin: '8px 0' }} variant="contained" size="small" color="primary" onClick={() => setShowDatePicker(false)}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <Card style={{ height: open ? '100%' : 'auto' }}>
        <CardHeader
          action={
            <>
              <IconButton size="small" onClick={openInfo} style={{ marginTop: '8px', marginRight: '8px' }}>
                <InfoIcon />
              </IconButton>
              <Popover
                open={infoOpen}
                anchorEl={anchorEl}
                onClose={openInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography style={{ padding: '16px', maxWidth: '300px' }}>
                  This pie chart shows you revenue among the different sales persons.
                  <br />
                  <br />
                  You can click/tap each sales person to see more information about them. Hover/Tap on a section to see specific revenues. Also, you
                  can limit your selection by clicking on the labels at the bottom to exclude them from the graph.
                </Typography>
              </Popover>
              <FormControl>
                <Select
                  labelId="salesLabel"
                  id="salesPie"
                  value={selectedOption}
                  onChange={(e) => setDates(e)}
                  style={{ marginTop: '6px' }}
                  onOpen={() => setDropDownOpen(true)}
                  onClose={() => setDropDownOpen(false)}
                >
                  <MenuItem value="This Month">This Month</MenuItem>;<MenuItem value="Last Month">Last Month</MenuItem>;
                  <MenuItem value="All Time">All Time</MenuItem>;
                  <MenuItem value="Custom Range">
                    {selectedOption === 'Custom Range' && !dropDownOpen
                      ? `${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`
                      : 'Custom Range'}
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          }
          title={
            <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Sales Revenue</Typography>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <CardContent style={{ paddingBottom: '16px' }}>
            {pieData.length ? (
              <>
                <div className={classes.chartContainer}>
                  {salesData && (
                    <div>
                      <Link
                        onClick={() => setSalesData(null)}
                        component="button"
                        variant="body1"
                        style={{ display: 'inline-flex', alignItems: 'center' }}
                      >
                        <ArrowBackIosIcon />
                        Back to Sales Persons
                      </Link>
                    </div>
                  )}
                  <Doughnut data={data} options={options} />
                </div>
              </>
            ) : (
              'No data available for the current selection'
            )}
            {salesData && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginTop: '30px',
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant="h4" style={{ marginRight: '8px', marginBottom: '8px' }}>
                  Profit: ${commaNumber(salesData.profit)}
                </Typography>
                <Typography variant="h4">
                  Total Work Orders:
                  {salesData.totalWorkOrders}
                </Typography>
              </div>
            )}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default SalesFinancialChart;
