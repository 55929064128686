import { EventEmitter } from 'events';
import dispatcher from './dispatcher';

class Store extends EventEmitter {
  constructor() {
    super();
    this.workOrder = {};
  }

  getWorkOrder() {
    return this.workOrder;
  }

  updateWorkOrder = (name, value) => {
    this.workOrder[name] = value;
    this.emit('change');
  };

  updateObj = (obj) => {
    Object.keys(obj).map((k) => (this.workOrder[k] = obj[k]));
    this.emit('change');
  };

  loadWorkOrder = (workOrder) => {
    this.workOrder = workOrder;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'GET_WORK_ORDER': {
        this.getWorkOrder();
        break;
      }
      case 'LOAD_WORK_ORDER': {
        this.loadWorkOrder(action.workOrder);
        break;
      }
      case 'UPDATE_WORK_ORDER': {
        this.updateWorkOrder(action.name, action.value);
        break;
      }
      case 'UPDATE_OBJ': {
        this.updateObj(action.obj);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
