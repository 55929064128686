import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
const divStyle = { textAlign: 'left', marginBottom: '5px' };

function ViewContact({ contact }) {
  const { address1, address2, city, co, country, organization, state, zip } = contact;
  return (
    <>
      <Grid>{co && <div style={divStyle}>{co}</div>}</Grid>
      <Grid>{organization && <div style={divStyle}>{organization}</div>}</Grid>
      <Grid>{address1 && <div style={divStyle}>{address1}</div>}</Grid>
      <Grid>{address2 && <div style={divStyle}>{address2}</div>}</Grid>
      <Grid>
        {city && (
          <div style={divStyle}>
            {city}, {state?.code}, {zip}, {country?.code}
          </div>
        )}
      </Grid>
    </>
  );
}

ViewContact.propTypes = {
  contact: PropTypes.object,
};

export default ViewContact;
