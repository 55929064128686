import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';

export const loadUser = (uid) => {
  get(`/user/${uid}`).then((user) => {
    dispatcher.dispatch({
      type: 'LOAD_USER',
      user,
    });
  });
};

export const updateUser = (uid, update) => {
  console.log('update', update);
  patch(`/user/${uid}`, update).then((user) => {
    dispatcher.dispatch({
      type: 'UPDATE_USER',
      user,
    });
  });
};
