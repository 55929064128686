/* eslint react/prop-types:0 */
/* eslint max-len:0 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// If you are looking for examples of what kind of props you should use/function to create, look at Home/Common/TotalProfits.js.
// OR Home/Common/LatestWorkOrders.js for a class style implementation.
// That should give you a pretty good example and you can probably copy that.

const DateFilter = ({ startDate, endDate, handleDatePickerChange, disabled = false }) => {
  // Dates MUST BE FORMATTED as YYYY/MM/DD for this component to work correctly. It wasn't implemented as such, it is just how it works.
  return (
    <div className="date-filter-container">
      <Grid container spacing={1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              disableToolbar
              disabled={disabled}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start Date"
              minDate={new Date('2015/12/31')}
              value={new Date(startDate)}
              onChange={(date) => handleDatePickerChange(date, true)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                size: 'small',
              }}
              style={{ margin: 0, width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              disableToolbar
              disabled={disabled}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End Date"
              minDate={new Date('2015/12/31')}
              value={new Date(endDate)}
              onChange={(date) => handleDatePickerChange(date, false)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                size: 'small',
              }}
              style={{ margin: 0, width: '100%' }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </div>
  );
};

export default DateFilter;
