/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, DialogActions, TextField, Grid, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { get, patch } from '../../../Functions/API';
import { closeDialog } from '../../../Stores/Dialog/Actions';
import ContactsCreate from '../Contacts/ContactsCreate';
import { updateCustomerContacts } from '../../../Stores/Customer/Actions';
import { loadWorkOrder } from '../../../Stores/WorkOrder/Actions';

const CustomerEditDialog = ({ customerID, workOrder }) => {
  const { handleSubmit, control, reset, getValues, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const setContacts = (newContacts) => {
    setData({ ...data, contacts: newContacts });
  };

  const fetchData = () => {
    setLoading(true);
    get(`/customers/${customerID}`)
      .then((result) => {
        reset(result);
        setData(result);
      })
      .finally(() => setLoading(false));
  };

  const save = async (d) => {
    const contacts = d.contacts
      .map((c) => {
        const copy = { ...c };
        if (copy?.id && typeof copy?.id === 'string') {
          delete copy.id;
        }
        return copy;
      })
      .filter((c) => {
        // for some reason objects remain when deleted with react hook form
        // but it clears all their fields
        return c?.type;
      });
    const updateData = { ...data, ...d, contacts };
    setLoading(true);
    if (workOrder) {
      await updateCustomerContacts(customerID, updateData, workOrder.id).finally(() => {
        setLoading(false);
        loadWorkOrder(workOrder.id);
        closeDialog();
      });
    } else {
      await patch(`/customers/${customerID}`, updateData).finally(() => {
        setLoading(false);
        closeDialog();
      });
      closeDialog();
    }
  };

  const handleCancelKeyDown = (event) => {
    if (event.keyCode && event.keyCode !== 13 && event.keyCode !== 32) return;
    closeDialog();
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <div>
        {Boolean(data) && Boolean(data?.name) && (
          <Grid spacing={1} container style={{ marginBottom: '30px' }}>
            {/*<TextField onChange={(e) => setName(e.target.value)} value={data.name ? data.name : ''} fullWidth label="Name" />           */}
            <Controller
              name="name"
              control={control}
              defaultValue={data.name ? data.name : ''}
              rules={{ required: 'Customer Name required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Customer Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            {/*<TextField onChange={(e) => setName(e.target.value)} value={} fullWidth label="Name" />*/}
          </Grid>
        )}
        {loading ? (
          <Grid container justify="center">
            <CircularProgress size={200} />
          </Grid>
        ) : (
          <>
            {Boolean(data) && Boolean(data?.contacts) && (
              <Grid spacing={1} container style={{ height: '600px', overflow: 'scroll' }}>
                <ContactsCreate
                  setValue={setValue}
                  getValues={getValues}
                  remove={remove}
                  append={append}
                  fields={fields}
                  control={control}
                  contacts={data.contacts}
                  setContacts={setContacts}
                />
              </Grid>
            )}
          </>
        )}
        <DialogActions>
          <Button onClick={closeDialog} onKeyDown={handleCancelKeyDown} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </div>
    </form>
  );
};

CustomerEditDialog.propTypes = {
  customerID: PropTypes.any,
};

CustomerEditDialog.displayName = 'CustomerEditDialog';

export default CustomerEditDialog;
