/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import QBReports from '../../../Components/Accounting/QBReports/index.js';
import Summary from '../../../Components/Accounting/Summary.js';
import { createSnack } from '../../../Stores/Snack/Actions.js';
import ATabs from '../../../Components/Accounting/ATabs.js';
import { useHistory } from 'react-router-dom';

const Old = ({ homepage, get, post }) => {
  const [active, setActive] = useState(0);
  const history = useHistory();

  const handleChange = (event, change) => {
    this.setState({
      active: change,
    });
  };

  const incrementActive = (event) => {
    const newActive = this.state.active + 1;
    setActive(newActive);
  };

  const changeActive = (event) => {
    document.querySelectorAll('.tab').forEach((tab) => {
      tab.style.backgroundColor = '#0f213c';
      tab.style.color = '#dcdcdc';
    });
    event.target.style.backgroundColor = '#dcdcdc';
    event.target.style.color = '#0f213c';
    setActive(event.target.id);
  };

  const lockCustomer = (customer) => {
    this.props.get('/lockCustomer', []).catch((err) => {
      this.props.createSnack('Error', err.message);
    });
  };
  return (
    <div>
      <Breadcrumbs>
        <Link underline={'hover'} href={'/accounting'}>
          Accounting Home
        </Link>
        <Typography color="text.primary">Old</Typography>
      </Breadcrumbs>
      <div>
        <h1>Accounting Center</h1>
      </div>
      <QBReports />
      <Summary homepage={homepage} get={get} post={post} createSnack={createSnack} />
      <ATabs
        changeActive={changeActive}
        handleChange={handleChange}
        get={get}
        post={post}
        createSnack={createSnack}
        active={active}
        incrementActive={incrementActive}
        lockCustomer={lockCustomer}
        homepage={homepage}
      />
    </div>
  );
};

export default Old;
