import { EventEmitter } from 'events';
import { updateSelected } from './Actions';
import dispatcher from './dispatcher';
import { TablePagination } from '@material-ui/core';
import React from 'react';

class Store extends EventEmitter {
  constructor() {
    super();
    this.customers = [];
    this.fetching = false;
    this.promise = null;
  }

  setFetching(promise) {
    this.fetching = true;
    this.promise = promise;
  }

  getCustomers() {
    return this.customers;
  }

  getPromise() {
    return this.promise;
  }

  isFetching() {
    return this.fetching;
  }

  loadCustomers = (customers) => {
    this.customers = customers;
    this.emit('change');
  };

  updateCustomer = (index, newCustomer) => {
    this.customers[index] = newCustomer;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_CUSTOMERS': {
        this.loadCustomers(action.customers);
        break;
      }
      case 'SET_FETCHING': {
        this.setFetching(action.promise);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
