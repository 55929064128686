/* eslint no-nested-ternary:0 */
/* eslint max-len:0 */
/* eslint no-shadow:0 */
/* eslint no-console:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-plusplus:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Select,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel,
  FormControl,
  IconButton,
  CircularProgress,
  LinearProgress,
  InputLabel,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import { months } from '../../../Utils/monthsOfTheYear';
import colors from '../../../Utils/MaterialColors';
import palette from '../../../Utils/theme/palette';
import Store from './Store';
import { loadData, newData } from './Actions';

const BetterFinancialGraphByCustomer = ({ get, customerIds, name, year, ytdTotal, setYtdTotal }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [who, setWho] = useState('Both');
  const [stacked, setStacked] = useState(false);
  const [orientation, setOrientation] = useState('vertical');
  const [table, setTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider,
          },
        },
      ],
    },
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      yAxes: [
        {
          stacked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    Store.on('change', getData);
    console.log(Store.getData());
    if (Store.getData()?.length) {
      getData();
    } else {
      loadData(customerIds, year, ytdTotal);
    }
    return () => {
      Store.removeListener('change', getData);
    };
  }, []);

  const getData = () => {
    const results = Store.getData();
    const { data, tableData } = formatData(results);
    setData(data);
    setTableData(tableData);
  };

  const formatData = (results) => {
    const date = new Date();
    const datasets = [];
    for (let i = 0; i < results.length; i++) {
      const row = {};
      row.label = results[i].label;
      row.backgroundColor = 'rgba(255,255,255,0)';
      if (!ytdTotal) {
        row.backgroundColor = [];
      }
      row.borderColor = colors[i];
      if (!ytdTotal) {
        row.borderColor = [];
      }
      if (ytdTotal) {
        row.pointRadius = [];
        row.pointHoverRadius = 10;
        row.pointHitRadius = 10;
        row.pointStyle = [];
        row.pointBackgroundColor = colors[i];
      }
      row.data = [];
      for (const d of results[i].data) {
        if (ytdTotal) {
          row.data.push(d.ytdProfit.toFixed(2));
          if (results[i].data.indexOf(d) === date.getMonth()) {
            row.pointRadius.push(5);
            row.pointStyle.push('circle');
          } else {
            row.pointRadius.push(5);
            row.pointStyle.push('circle');
          }
        } else {
          row.backgroundColor.push(colors[i]);
          row.borderColor.push(colors[i]);
          row.data.push(d.profit.toFixed(2));
        }
      }
      datasets.push(row);
    }
    if (ytdTotal) {
      datasets.sort((a, b) => {
        return b.data[b.data.length - 1] - a.data[a.data.length - 1];
      });
    }
    const data = { labels: months, datasets };
    const tableData = [];
    for (const row of results) {
      for (const nextRow of row.data) {
        tableData.push(nextRow);
      }
    }
    return { data, tableData };
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Grid container justify="flex-start" spacing={1}>
              <Grid item style={{ flexGrow: 1 }}>
                {name && (
                  <div className="toolbar-header-area" style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5">
                      Yearly Profit for
                      {name}
                    </Typography>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </div>
                )}
              </Grid>
              {!ytdTotal && (
                <>
                  <Grid item>
                    <FormControl component="div">
                      <FormControlLabel
                        control={
                          <Switch
                            value={orientation === 'vertical'}
                            checked={orientation === 'vertical'}
                            size="small"
                            disabled={loading}
                            color="primary"
                            onChange={() => setOrientation(orientation === 'vertical' ? 'horizontal' : 'vertical')}
                          />
                        }
                        label={<span style={{ fontSize: 'smaller' }}>Vertical</span>}
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl component="div">
                      <FormControlLabel
                        control={
                          <Switch
                            value={stacked}
                            checked={stacked}
                            size="small"
                            disabled={loading}
                            color="secondary"
                            onChange={() => setStacked(!stacked)}
                          />
                        }
                        label={<span style={{ fontSize: 'smaller' }}>Stacked</span>}
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item>
                <FormControl component="div">
                  <FormControlLabel
                    control={
                      <Switch value={table} checked={table} size="small" disabled={loading} color="primary" onChange={() => setTable(!table)} />
                    }
                    label={<span style={{ fontSize: 'smaller' }}>Table</span>}
                    labelPlacement="top"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="div">
                  <FormControlLabel
                    control={
                      <Switch
                        value={ytdTotal}
                        checked={ytdTotal}
                        size="small"
                        disabled={loading}
                        color="primary"
                        onChange={() => {
                          newData(customerIds, year, !ytdTotal);
                          setYtdTotal(!ytdTotal);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 'smaller' }}>YTD</span>}
                    labelPlacement="top"
                  />
                </FormControl>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <Collapse in={open} timeout="auto">
          <>
            <CardContent style={{ paddingBottom: '16px' }}>
              <div
                style={{
                  height: '650px',
                  position: 'relative',
                }}
              >
                {data && loading ? (
                  <LinearProgress />
                ) : table ? (
                  <MaterialTable
                    data={tableData}
                    title={`Financial Info for ${name}`}
                    columns={[
                      { title: 'Customer', field: 'name' },
                      {
                        title: 'Month',
                        field: 'month',
                        render: (rowData) => months[rowData.month - 1],
                      },
                      { title: 'Invoice', field: 'bill' },
                      { title: 'Cost', field: 'cost' },
                      { title: 'Profit', field: 'profit' },
                    ]}
                    options={{
                      paging: false,
                      showTitle: false,
                      search: false,
                      exportButton: true,
                      maxBodyHeight: '550px',
                    }}
                  />
                ) : ytdTotal ? (
                  <Line data={data} options={options} />
                ) : orientation === 'horizontal' ? (
                  <Bar data={data} options={{ ...barOptions, indexAxis: 'y' }} />
                ) : (
                  <Bar data={data} options={barOptions} />
                )}
              </div>
            </CardContent>
            <Divider />
          </>
        </Collapse>
      </Card>
    </>
  );
};

export default BetterFinancialGraphByCustomer;
