/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

const SalesPersonFilters = ({ salesPersons, salesOnClick, salesFilter }) => {
  return (
    <div className="sales-filter-container">
      <FormControl component="fieldset">
        <FormGroup>
          {salesPersons.map((x) => {
            return (
              <FormControlLabel
                control={<Checkbox checked={salesFilter === x.uid} onChange={() => salesOnClick(x.uid)} value={x.name} color="primary" />}
                label={`${x.firstName} ${x.lastName}`}
                key={`${x.firstName} ${x.lastName}`}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default SalesPersonFilters;
