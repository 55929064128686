import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { MobileStepper, Grid, Paper, Typography, makeStyles, Button } from '@material-ui/core';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { useQuery } from '../../../../Functions';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    width: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const Product = ({ product, adjustPageForProduct, removeProduct }) => {
  const history = useHistory();
  const { name, description, frontImage, backImage } = product;
  const [skus, setSkus] = useState([]);
  const query = useQuery(useLocation);
  const [storeId] = useState(query.get('storeId'));
  const [productImages, setProductImages] = useState([]);
  const [productImageIndex, setProductImageIndex] = useState(0);

  useEffect(() => {
    setProductImages(
      product?.ybaSkus
        ?.map((ySku) => {
          return ySku.productImages.map((pImage) => pImage.url);
        })
        .flat()
        .filter(onlyUnique),
    );
  }, [product]);

  const handleNext = () => {
    setProductImageIndex(productImageIndex + 1);
  };
  const handleBack = () => {
    setProductImageIndex(productImageIndex - 1);
  };

  const classes = useStyles();
  return (
    <div className={classes.root} onClick={() => history.push(`/fulfillment/products/${product.id}`)}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <img className={classes.img} src={productImages[productImageIndex]} alt="Product Images" />
            <MobileStepper
              steps={productImages.length}
              position="static"
              variant="text"
              activeStep={productImageIndex}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={productImageIndex === productImages.length - 1}>
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={productImageIndex === 0}>
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Product;

Product.propTypes = {
  product: propTypes.object,
  adjustPageForProduct: propTypes.func,
  removeProduct: propTypes.func,
};
