/* eslint no-console:0 */
/* eslint no-lone-blocks:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export default class MonthCollectedDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Work Order ID',
          field: 'workOrderID',
        },
        { title: 'Customer', field: 'customerName' },
        { title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
        {
          title: 'Closed',
          field: 'accountingApproved',
          render: (rowData) =>
            rowData.accountingApproved !== undefined && <Checkbox size="small" checked={Boolean(rowData.accountingApproved)} color="primary" />,
        },
        { title: 'Closed Date', field: 'closedDate', type: 'date' },
        {
          title: 'Paid',
          field: 'balance',
          render: (rowData) => rowData.balance !== undefined && <Checkbox size="small" checked={rowData.balance === 0} color="secondary" />,
        },
        { title: 'Paid Date', field: 'paidDate', type: 'date' },
      ],
      data: [],
    };
  }

  componentDidMount() {
    const { button, uid, month, year } = this.props;
    this.props
      .get('/salesSheetDetails', { button, uid, month, year }, 'obj')
      .then((results) => {
        const { data } = results;
        const totals = {
          paidDate: 'Total:',
          commissionEligible: 0,
          monthCollected: 0,
          monthCollectedPrevYear: 0,
        };
        for (const row of data) {
          if (row.monthCollected) {
            totals.monthCollected += row.monthCollected;
          }
          if (row.commissionEligible) {
            totals.commissionEligible += row.commissionEligible;
          }
          if (row.monthCollectedPrevYear) {
            totals.monthCollectedPrevYear += row.monthCollectedPrevYear;
          }
        }
        data.push(totals);
        const { columns } = this.state;
        switch (button) {
          case 'monthCollected':
            {
              columns.push({
                title: 'Month Collected',
                field: 'monthCollected',
                render: (rowData) => (parseFloat(rowData.monthCollected) ? `$${parseFloat(rowData.monthCollected).toFixed(2)}` : ''),
              });
            }
            break;
          case 'collected':
            {
              columns.push({
                title: 'Collected',
                field: 'commissionEligible',
                render: (rowData) => (parseFloat(rowData.commissionEligible) ? `$${parseFloat(rowData.commissionEligible).toFixed(2)}` : ''),
              });
            }
            break;
          case 'monthCollectedPrevYear':
            {
              columns.push({
                title: 'Month Collected (Previous Year)',
                field: 'monthCollectedPrevYear',
                render: (rowData) => (parseFloat(rowData.monthCollectedPrevYear) ? `$${parseFloat(rowData.monthCollectedPrevYear).toFixed(2)}` : ''),
              });
            }
            break;
          default: {
            console.log('something is wrong');
          }
        }
        this.setState({
          data,
          columns,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  render() {
    return (
      <Paper style={{ height: '100%', width: '100%', outline: 'none', overflowY: 'scroll' }}>
        <MaterialTable
          title={`${this.props.button} Details`}
          columns={this.state.columns}
          data={this.state.data}
          options={{ paging: false, exportButton: true }}
          onRowClick={(event, rowData) => {
            window.open(`/workorder/${rowData.id}`, '_blank');
          }}
        />
      </Paper>
    );
  }
}
