import { get, post } from '../../Functions/API';
import dispatcher from './dispatcher';
import { createSnack } from '../../Stores/Snack/Actions';

export const loadInventory = async () => {
  get('/inventory', {
    filter: {
      eager: {
        skus: {
          sizes: {},
        },
        $where: {
          hidden: 0,
        },
      },
    },
  })
    .then((inventory) => {
      dispatcher.dispatch({
        type: 'LOAD_INVENTORY',
        inventory,
      });
    })
    .catch((err) => {
      createSnack('Error', err.message);
    });
};

export const newSKU = (inventoryId, sku, name) => {
  return post('/newSKU', { inventoryId, sku, name })
    .then((data) => {
      const { inventory, newSKU } = data;
      dispatcher.dispatch({
        type: 'LOAD_INVENTORY',
        inventory: inventory,
      });
      createSnack('Success', 'Created New SKU!');
      let newSKUs = [];
      inventory.forEach((i) => {
        newSKUs = newSKUs.concat(i.skus);
      });
      return newSKUs.find((s) => s.id === newSKU.insertId);
    })
    .catch((err) => {
      createSnack('Error', `${err.message}`);
      throw err;
    });
};
