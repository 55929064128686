import React, { useState } from 'react';
import { IconButton, MobileStepper, Card, CardHeader, CardActions, CardContent, Grid } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ViewContact from '../../../../../Shared/Contacts/ViewContact';
import { createDialog } from '../../../../../../Stores/Dialog/Actions';
import WorkOrderContactsDialog from '../../../../../Shared/WorkOrder/WorkOrderContactsDialog';

const ContactStepper = ({ workOrder, contacts, type, editable }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const openEdit = () => {
    createDialog({
      content: <WorkOrderContactsDialog workOrder={workOrder} type={type} />,
      title: `Edit WorkOrder ${type} Contacts`,
      actions: null,
      size: 'xl',
    });
  };

  return (
    <Card style={{ height: '100%', backgroundColor: '#eaeaea' }}>
      <CardHeader
        style={{ paddingBottom: '0px', textTransform: 'capitalize' }}
        title={type}
        action={
          <>
            {editable && (
              <IconButton onClick={openEdit}>
                <Edit />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        {Boolean(contacts?.length) ? <>{contacts[activeStep] && <ViewContact contact={contacts[activeStep]} />}</> : <>No contacts</>}
      </CardContent>
      <CardActions>
        {Boolean(contacts?.length) && (
          <MobileStepper
            steps={contacts?.length}
            style={{ padding: '4px', width: '100%', backgroundColor: 'inherit' }}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <IconButton size="small" onClick={handleNext} disabled={activeStep === contacts?.length - 1}>
                <KeyboardArrowRight />
              </IconButton>
            }
            backButton={
              <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </IconButton>
            }
          />
        )}
      </CardActions>
    </Card>
  );
};

ContactStepper.propTypes = {
  contacts: PropTypes.array,
  workOrder: PropTypes.object,
  type: PropTypes.string,
  editable: PropTypes.bool,
};

ContactStepper.defaultProps = {
  editable: true,
};

ContactStepper.displayName = 'ContactStepper';

export default ContactStepper;
