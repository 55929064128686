/* eslint-disable react/display-name */
/* eslint no-new:0 */
/* eslint max-len:0 */
/* eslint radix:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-expressions:0 */
/* eslint no-alert:0 */
/* eslint no-console:0 */
/* eslint no-restricted-syntax:0 */
/* eslint no-shadow:0 */
/* eslint no-use-before-define:0 */
/* eslint react/prop-types:0 */
/* eslint no-unused-vars:0 */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, IconButton, Paper, TextField, Modal } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import MaterialTable from 'material-table';
import SKU from '../../Inventory/SKU';
import { get, post } from '../../../Functions/API';
import UserStore from '../../../Stores/User/Store';

const Inventory = () => {
  const { inventoryId } = useParams();
  const user = UserStore.getUser();
  const [tableRef, setTableRef] = useState(React.createRef());
  const [data, setData] = useState([]);
  const [activeSKU, setActiveSKU] = useState(null);
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [potentialSizes, setPotentialSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inventoryName, setInventoryName] = useState('');

  useEffect(() => {
    setup();
  }, [inventoryId]);

  const setup = async () => {
    setLoading(true);
    let ps = [];
    const columns = [
      {
        title: 'SKU',
        field: 'sku',
        render: (rowData) => (
          <Button color="primary" variant="contained" size="small" component={Link} to={`/inventory/${inventoryId}/skus/${rowData.id}`}>
            {rowData.sku}
          </Button>
        ),
        editable: 'never',
      },
      { title: 'Name', field: 'name', editable: 'never' },
    ];
    get(`/inventory/${inventoryId}`).then(({ name }) => {
      setInventoryName(name);
    });
    await get('/allPotentialSizes').then((results) => {
      ps = results;
      console.log(ps);
      setPotentialSizes(results);
    });
    await get('/getSKUs', [{ name: 'inventoryId', value: inventoryId }])
      .then((results) => {
        let sizes = [];
        for (const result of results) {
          for (const size of result.sizes) {
            const found = sizes.find((row) => row.title === size.size);
            if (!found) {
              sizes.push({
                title: size.size,
                field: size.size,
                render: (rowData) => {
                  const found = rowData.sizes.find((row) => row.size === size.size);
                  if (found) {
                    return found.quantity;
                  }
                  return '';
                },
                editComponent: (t) => {
                  const found = t.rowData.sizes.find((row) => row.size === size.size);
                  return found ? (
                    <TextField
                      type="number"
                      value={found.quantity}
                      onChange={(e) => t.onRowDataChange(editQuantity(e.target.value, t.rowData, found))}
                    />
                  ) : (
                    <TextField value="N/A" disabled helperText="Size not included in SKU" />
                  );
                },
              });
            }
          }
        }
        sizes = sortSizesStart(sizes, ps);
        columns.push({
          title: 'Total',
          render: (rowData) => {
            return rowData.sizes.reduce((total, row) => total + row.quantity, 0);
          },
        });
        setColumns([...columns, ...sizes]);
        console.log(results);
        setData(results);
      })
      .catch((err) => {
        window.alert(err.message);
      });
    setLoading(false);
  };

  const editQuantity = (quantity, newRow, found) => {
    const sizeIndex = newRow.sizes.indexOf(found);
    newRow.sizes[sizeIndex].quantity = parseInt(quantity);
    return newRow;
  };

  const sortSizesStart = (sizes, potentialSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = potentialSizes.find((row) => row.size === a.title);
      const potentialSizeB = potentialSizes.find((row) => row.size === b.title);
      if (potentialSizeA && potentialSizeB) {
        const indexA = potentialSizes.indexOf(potentialSizeA);
        const indexB = potentialSizes.indexOf(potentialSizeB);
        return indexA - indexB;
      }
      return 1;
    });
  };

  const toggleModal = (activeSKU) => {
    setActiveSKU(activeSKU);
    setModal(!modal);
  };

  const updateSize = (newSize, oldSize, sku) => {
    console.log('update size');
    const newData = [...data];
    const index = newData.indexOf(sku);
    const sizeIndex = newData[index].sizes.indexOf(oldSize);
    post('/updateSize', newSize)
      .then((size) => {
        newData[index].sizes[sizeIndex] = size;
        setData(newData);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  const updateRow = async (newData, oldData) => {
    const notes = window.prompt('You are making a manual adjustment. Please give us an explanation.');
    if (notes) {
      await post('/manualSkuAdjustment', { ...newData, notes })
        .then((results) => {
          const index = data.indexOf(oldData);
          data[index].sizes = results;
          console.log(data);
          setData(data);
        })
        .catch((err) => {
          throw err;
        });
    }
    return 'done';
  };

  return (
    <div>
      <Paper>
        <MaterialTable
          title={`${inventoryName} Inventory`}
          isLoading={loading}
          columns={columns}
          data={data}
          tableRef={tableRef}
          options={{
            paging: false,
            exportButton: true,
            exportCsv: (columns, data) => {
              let csvContent = 'data:text/csv;charset=utf-8,';
              let headers = 'SKU,Name,';
              const sizeHeaders = columns.reduce((arr, row) => {
                if (columns.indexOf(row) > 2) {
                  arr.push(row.title);
                }
                return arr;
              }, []);
              headers += sizeHeaders.join(',');
              csvContent += `${headers}\r\n`;
              for (const row of data) {
                csvContent += `${row.sku},`;
                csvContent += `${row.name},`;
                const sizes = [];
                for (const sizeHeader of sizeHeaders) {
                  const size = row.sizes.find((row) => row.size === sizeHeader);
                  if (size) {
                    sizes.push(size.quantity);
                  } else if (sizeHeader === 'Total') {
                    sizes.push(row.sizes.reduce((total, row) => total + row.quantity, 0));
                  } else {
                    sizes.push('');
                  }
                }
                csvContent += `${sizes.join(',')}\r\n`;
              }
              const encodedUri = encodeURI(csvContent);
              const link = document.createElement('a');
              link.setAttribute('href', encodedUri);
              link.setAttribute('download', `${inventoryName} Inventory.csv`);
              document.body.appendChild(link);
              link.click();
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default Inventory;
