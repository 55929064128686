/* eslint no-console:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-vars:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint consistent-return:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfLines: [],
      submitted: false,
      vendors: [],
      columns: [
        {
          title: 'Vendor',
          field: 'DisplayName',
          editable: 'always',
        },
        {
          title: 'Email',
          field: 'PrimaryEmailAddr.Address',
          editable: 'always',
        },
        {
          title: 'Street Address',
          field: 'BillAddr.Line1',
          editable: 'always',
        },
        {
          title: 'City',
          field: 'BillAddr.City',
          editable: 'always',
        },
        {
          title: 'State',
          field: 'BillAddr.CountrySubDivisionCode',
          editable: 'always',
        },
        {
          title: 'Zip Code',
          field: 'BillAddr.PostalCode',
          editable: 'always',
        },
        {
          title: 'Bill Term',
          field: 'TermRef.value',
          editable: 'always',
          render: (rowData) => {
            if (rowData.TermRef) {
              switch (rowData.TermRef.value) {
                case '1':
                  return <span>Due on Receipt</span>;
                case '2':
                  return <span>Net 15</span>;
                case '3':
                  return <span>Net 30</span>;
                case '4':
                  return <span>Net 60</span>;
                case '5':
                  return <span>Net 10</span>;
                default:
              }
            } else {
              return <span>No Term Set</span>;
            }
          },
        },
        {
          title: 'Open Balance',
          field: 'Balance',
          editable: 'never',
          type: 'currency',
        },
      ],
    };
  }

  componentDidMount() {
    this.getVendors();
  }

  getVendors = () => {
    this.props
      .get('/getQBVendors', [])
      .then((results) => {
        const { url } = results;
        if (url) {
          let parameters = 'location=1,width=800,height=650';
          parameters += `,left=${(window.screen.width - 800) / 2},top=${(window.screen.height - 650) / 2}`;
          window.open(url, 'connectPopup', parameters);
        } else {
          const vendors = results.message;
          this.setState({
            vendors,
          });
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  };

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Quickbooks Vendors"
            columns={this.state.columns}
            data={this.state.vendors}
            options={{
              pageSizeOptions: [10, 15, 25, 50],
              pageSize: 10,
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                switch (newData.TermRef.value) {
                  case 'Due on Receipt':
                    newData.TermRef.value = 1;
                    break;
                  case 'Net 15':
                    newData.TermRef.value = 2;
                    break;
                  case 'Net 30':
                    newData.TermRef.value = 3;
                    break;
                  case 'Net 60':
                    newData.TermRef.value = 4;
                    break;
                  case 'Net 10':
                    newData.TermRef.value = 5;
                    break;
                  default:
                }
                console.log(newData);
                await this.props
                  .post('/updateQBVendor', newData)
                  .then(() => {
                    this.getVendors();
                  })
                  .catch((err) => {
                    this.props.createSnack('Error', err.message);
                  });
              },
            }}
          />
        </Paper>
      </div>
    );
  }
}

export default Vendors;
