/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-restricted-syntax:0 */
/* eslint max-len:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint eqeqeq:0 */
/* eslint react/no-unused-state:0 */
import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfLines: [],
      submitted: false,
      bills: [],
      columns: [
        {
          title: 'Vendor',
          field: 'VendorRef.name',
          editable: 'never',
        },
        {
          title: 'Bill Number',
          field: 'DocNumber',
          editable: 'never',
        },
        {
          title: 'Category',
          field: 'Line.AccountRef.name',
          editable: 'never',
          render: (rowData) => {
            if (rowData.Line[0].DetailType == 'AccountBasedExpenseLineDetail') {
              const category = rowData.Line[0].AccountBasedExpenseLineDetail.AccountRef.name;
              return category;
            }
            return '';
          },
        },
        {
          title: 'Customer',
          editable: 'never',
          render: (rowData) => {
            if (rowData.Line[0].DetailType == 'AccountBasedExpenseLineDetail' && rowData.Line[0].AccountBasedExpenseLineDetail.CustomerRef.name) {
              const category = rowData.Line[0].AccountBasedExpenseLineDetail.CustomerRef.name;
              return category;
            }
            return '';
          },
        },
        {
          title: 'Due Date',
          field: 'DueDate',
          type: 'date',
          editable: 'never',
          render: (rowData) => {
            const date = new Date(rowData.DueDate);
            date.setHours(date.getHours() + 7);
            return `${date.toDateString()}`;
          },
        },
        {
          title: 'Balance',
          field: 'Balance',
          type: 'currency',
          editable: 'never',
          render: (rowData) => {
            const dueDate = new Date(rowData.DueDate);
            const today = new Date();
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            });
            if (today > dueDate && rowData.Balance > 0) {
              return <span style={{ color: 'red' }}>{formatter.format(rowData.Balance)}</span>;
            }
            return formatter.format(rowData.Balance);
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props
      .get('/getQBBills', [])
      .then((results) => {
        const { url } = results;
        if (url) {
          let parameters = 'location=1,width=800,height=650';
          parameters += `,left=${(window.screen.width - 800) / 2},top=${(window.screen.height - 650) / 2}`;
          window.open(url, 'connectPopup', parameters);
        } else {
          const allBills = results.message;
          const bills = [];
          for (const bill of allBills) {
            if (bill.Balance > 0) {
              bills.push(bill);
            }
          }
          this.setState({
            bills,
          });
        }
      })
      .catch((err) => {
        this.props.createSnack('Error', err.message);
      });
  }

  change = (event) => {
    const newLines = this.state.numOfLines;
    const num = event.target.getAttribute('num');
    const option = event.target.id;
    newLines[num][option] = parseFloat(event.target.value);
    this.setState({
      numOfLines: newLines,
    });
  };

  render() {
    return (
      <div>
        <Paper>
          <MaterialTable
            title="Quickbooks Bills"
            columns={this.state.columns}
            data={this.state.bills}
            options={{
              pageSizeOptions: [10, 15, 25, 50],
              pageSize: 10,
            }}
          />
        </Paper>
      </div>
    );
  }
}

export default Bills;
