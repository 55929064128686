import React from 'react';
import PropTypes from 'prop-types';
import steve4 from '../../../../../../images/steve4.png';
import BlankBubble from '../../../../../../images/BlankBubble.png';
import { Slide } from '@material-ui/core';

const Steve = ({ steve, direction }) => {
  return (
    <Slide direction={direction} in={steve}>
      <div
        style={{
          position: 'absolute',
          outline: 'none',
        }}
      >
        <img
          style={{
            position: 'relative',
            height: '300px',
            zIndex: 3,
            width: '300px',
            padding: '10px',
          }}
          src={steve4}
        />
        <img
          style={{
            position: 'relative',
            zIndex: 1,
            left: '-100px',
            top: '-100px',
            height: '150px',
            width: '200px',
            padding: '10px',
          }}
          src={BlankBubble}
        />
        <div
          style={{
            position: 'relative',
            fontSize: '20px',
            textAlign: 'center',
            zIndex: 2,
            left: '262px',
            top: '-220px',
            maxWidth: '155px',
            maxHeight: '100px',
          }}
        >
          You’re doing a GREAT job!
        </div>
      </div>
    </Slide>
  );
};

export default Steve;

Steve.propTypes = {
  steve: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
};
