import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from '../../../Functions/API.js';
import MaterialTable from 'material-table';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const PendingCosts = () => {
  const [vendor, setVendor] = useState({});
  const [costs, setCosts] = useState([]);

  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    get(`/vendors/${id}/pending`).then(({ vendor, costs }) => {
      setVendor(vendor);
      setCosts(costs);
    });
  }, [id]);

  const columns = [
    { title: 'WO', field: 'workOrderID', render: (rowData) => rowData.workOrder?.workOrderID },
    { title: 'Customer', render: (rowData) => rowData.workOrder?.customerName },
    { title: 'Invoice Number', field: 'invoiceNumber' },
    { title: 'Invoice Date', field: 'invoiceDate' },
    { title: 'Quantity', field: 'totalQuantity' },
    { title: 'Per Unit', field: 'perUnit', type: 'currency' },
    { title: 'Estimated Costs', field: 'totalEstimatedCost', type: 'currency' },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <Breadcrumbs>
        <Link underline={'hover'} href={'/accounting'}>
          Accounting Home
        </Link>
        <Link underline={'hover'} href={'/accounting/pending-vendors'}>
          Pending Vendors
        </Link>
        <Typography color="text.primary">Pending Costs</Typography>
      </Breadcrumbs>
      <MaterialTable
        title={`Pending Costs for ${vendor?.name}`}
        columns={columns}
        data={costs}
        options={{ paging: false }}
        onRowClick={(e, rowData) => history.push(`/workorder/${rowData.workOrder.id}/cost`)}
      />
    </div>
  );
};

export default PendingCosts;
