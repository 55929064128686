/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */
/* eslint max-len:0 */
/* eslint react/no-access-state-in-setstate:0 */
/* eslint no-console:0 */
/* eslint no-shadow:0 */
/* eslint no-restricted-syntax:0 */
/* eslint react/prop-types:0 */
/* eslint react/destructuring-assignment:0 */
/* eslint no-alert:0 */
/* eslint react/no-unused-state:0 */
/* eslint no-unused-vars:0 */
import React, { Component } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, TextField } from '@material-ui/core';
import { Add, Delete, FileCopy, CloudDownload } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import QuoteHeader from '../../../Components/Quote/QuoteHeader';
import QuoteFooter from '../../../Components/Quote/QuoteFooter';
import QuoteProduct from '../../../Components/Quote/QuoteProduct';
import { Route, Switch } from 'react-router-dom';
import QuotePDF from './pdf';

export default class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalog: 0,
      category: 0,
      id: 0,
      styleId: 0,
      selectedImage: 0,
      bill: 0,
      artModal: false,
      newArt: {
        type: 'tshirt',
        printType: '1 Color',
        location: 'Front',
        cost: 3,
      },
      style: {
        style: '',
        options: [{ image: '', swatch: '' }],
        features: [],
        quantity: 0,
        art: [],
      },
      catalogs: [],
      styles: [],
      categories: [],
      rows: [],
    };
  }

  componentDidMount() {
    this.setup()
      .then((results) => {
        this.setState(results);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }

  setup = async () => {
    const id = this.props.match.params.quoteId;
    let catalogs;
    let rows;
    let salesman;
    await this.props
      .get('/getCatalogs')
      .then((results) => {
        catalogs = results;
      })
      .catch((err) => {
        throw err;
      });
    await this.props
      .get('/quoteRows', { quotesId: id }, 'obj')
      .then((results) => {
        rows = results;
        for (const row of rows) {
          row.saved = true;
          for (const product of row.products) {
            product.editProduct = false;
            product.editArt = false;
            product.showCosts = false;
            product.showFeatures = false;
            product.saved = true;
            product.bill = product.bill.toFixed(2);
            let price = product.options.find((row) => row.id === product.optionId);
            if (price) {
              price = price.price;
            } else {
              price = 0;
            }
            product.margin = (
              ((product.bill -
                product.art.reduce((total, art) => total + art.cost, 0) -
                product.costs.reduce((total, cost) => total + cost.cost, 0) -
                price) /
                product.bill) *
              100
            ).toFixed(2);
          }
        }
      })
      .catch((err) => {
        throw err;
      });
    await this.props
      .get(`/getQuoteInfo`, { id }, 'obj')
      .then((results) => {
        salesman = results;
      })
      .catch((err) => {
        throw err;
      });
    console.log(rows);
    if (catalogs !== undefined && rows !== undefined && salesman !== undefined) {
      return {
        catalogs,
        rows,
        salesman,
        id,
      };
    }
    throw new Error('Something was undefined');
  };

  updateCatalog = (event) => {
    this.props.get('/getCategories', [{ name: 'id', value: event.target.value }]).then((categories) => {
      this.setState({
        catalog: event.target.value,
        categories,
      });
    });
  };

  downloadPDF = () => {
    window.open(`${this.props.homepage}/quotes/${this.state.id}/pdf`, '_blank');
  };

  updateStyles = (event) => {
    this.props
      .get('/getStyles', [
        { name: 'id', value: this.state.catalog },
        {
          name: 'category',
          value: event.target.value,
        },
      ])
      .then((styles) => {
        this.setState({
          styleId: event.target.value,
          styles,
        });
      });
  };

  updateStyle = (event) => {
    this.props.get('/getStyle', [{ name: 'id', value: event.target.value }]).then((style) => {
      this.setState({
        style,
      });
    });
  };

  updateImage = (selectedImage) => {
    this.setState({
      selectedImage,
    });
  };

  updateBill = (bill) => {
    this.setState({
      bill,
    });
  };

  toggleArtModal = () => {
    this.setState({
      artModal: !this.state.artModal,
    });
  };

  toggleShowCosts = (row, product) => {
    console.log('show costs');
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].showCosts = !rows[rowIndex].products[productIndex].showCosts;
    this.setState({
      rows,
    });
  };

  toggleEditProduct = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].editProduct = !rows[rowIndex].products[productIndex].editProduct;
    console.log(row, product);
    this.setState({
      rows,
    });
  };

  toggleEditArt = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].editArt = !rows[rowIndex].products[productIndex].editArt;
    console.log(row, product);
    this.setState({
      rows,
    });
  };

  updateCatalogName = (row, product, catalogId) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].catalogId = catalogId;
    console.log(row, product, catalogId);
    this.setState({
      rows,
    });
  };

  updateArt = (row, product, oldArt, newArt) => {
    const { rows } = this.state;
    const newRow = rows[rows.indexOf(row)];
    const newProduct = newRow.products[newRow.products.indexOf(product)];
    const { art } = newProduct;
    const tempArt = art.find((nug) => nug.id === oldArt.id);
    art[art.indexOf(tempArt)] = newArt;
    this.setState(
      {
        rows,
      },
      () => console.log('update art ran'),
    );
  };

  deleteArt = (row, product, deleteArt) => {
    const { rows } = this.state;
    const newRow = rows[rows.indexOf(row)];
    const newProduct = newRow.products[newRow.products.indexOf(product)];
    const { art } = newProduct;
    const index = art.indexOf(deleteArt);
    art.splice(index, 1);
    this.setState(
      {
        rows,
      },
      () => console.log('update art ran'),
    );
  };

  addArt = async (row, product, newArt) => {
    const { rows } = this.state;
    const newRow = rows[rows.indexOf(row)];
    const newProduct = newRow.products[newRow.products.indexOf(product)];
    const { art } = newProduct;
    art.push(newArt);
    await this.setState({
      rows,
    });
    return art;
  };

  updateQuantity = (event) => {
    const { style } = this.state;
    style.quantity = event.target.value;
    this.setState({
      style,
    });
  };

  updateProduct = (row, product, type, value) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const { products } = rows[rowIndex];
    const productIndex = products.indexOf(product);
    let price;
    if (type === 'optionId') {
      price = product.options.find((row) => row.id === value);
    } else {
      price = product.options.find((row) => row.id === product.optionId);
    }
    if (price) {
      price = price.price;
    } else {
      price = 0;
    }
    if (type === 'marginSubmit') {
      value /= 100;
      const cost = product.art.reduce((total, art) => total + art.cost, 0) + product.costs.reduce((total, cost) => total + cost.cost, 0) + price;
      products[productIndex].bill = (cost / (1 - value)).toFixed(2);
    } else {
      products[productIndex][type] = value;
    }
    if (type === 'optionId') {
      const option = products[productIndex].options.find((row) => row.id === value);
      if (option.price) {
        products[productIndex].price = option.price;
      }
    }
    const artCost = products[productIndex].art.reduce((total, art) => total + art.cost, 0);
    const customCost = products[productIndex].costs.reduce((total, cost) => total + cost.cost, 0);
    const productCost = price;
    // console.log(artCost, customCost, productCost, products[productIndex].bill);
    if (type !== 'margin') {
      products[productIndex].margin = (
        ((products[productIndex].bill - artCost - customCost - productCost) / products[productIndex].bill) *
        100
      ).toFixed(2);
    }
    products[productIndex].saved = false;
    this.setState({
      rows,
    });
  };

  updateProductImage = (row, product, optionId) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const { products } = rows[rowIndex];
    const productIndex = products.indexOf(product);
    products[productIndex].optionId = optionId;
    this.setState({
      rows,
    });
  };

  updateRowTitle = (row, title) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    rows[rowIndex].title = title;
    rows[rowIndex].saved = false;
    this.setState({
      rows,
    });
  };

  addProduct = (row) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const product = {
      art: [],
      bill: 0,
      catalogId: null,
      category: '',
      cost: null,
      costs: [],
      description: '',
      editProduct: false,
      features: [],
      optionId: null,
      options: [],
      price: null,
      quantity: 0,
      quoteRowId: row.id,
      showCosts: false,
      showFeatures: false,
      style: '',
      styleId: null,
      title: null,
    };
    rows[rowIndex].products.push(product);
    this.setState({
      rows,
    });
  };

  copyRow = (row) => {
    const { rows } = this.state;
    this.props.post('/copyRow', row).then((newRow) => {
      newRow.saved = true;
      for (const product of newRow.products) {
        product.editProduct = false;
        product.editArt = false;
        product.showFeatures = false;
        product.showCosts = false;
        product.saved = true;
      }
      rows.splice(rows.indexOf(row), 0, newRow);
      this.setState({
        rows,
      });
    });
  };

  copyProduct = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    this.props.post('/copyProduct', product).then((newProduct) => {
      newProduct.editProduct = false;
      newProduct.editArt = false;
      newProduct.showCosts = false;
      newProduct.showFeatures = false;
      newProduct.saved = true;
      rows[rowIndex].products.push(newProduct);
      this.setState({
        rows,
      });
    });
  };

  deleteProduct = (row, product) => {
    this.props
      .post('/deleteProduct', product)
      .then((results) => {
        const { rows } = this.state;
        const rowIndex = rows.indexOf(row);
        const productIndex = rows[rowIndex].products.indexOf(product);
        rows[rowIndex].products.splice(productIndex, 1);
        this.setState({
          rows,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  saveProduct = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    this.props
      .post('/saveProduct', { product })
      .then((product) => {
        product.editProduct = false;
        product.editArt = false;
        product.showCosts = false;
        product.showFeatures = false;
        product.saved = true;
        rows[rowIndex].products[productIndex] = product;
        this.setState({
          rows,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  addRow = () => {
    const { rows } = this.state;
    this.props
      .post('/addQuoteRow', { quotesId: this.state.id })
      .then((row) => {
        rows.push(row);
        this.setState({
          rows,
        });
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  saveRowTitle = (row) => {
    this.props.post('/updateRowTitle', row).then((results) => {
      const { rows } = this.state;
      const index = rows.indexOf(row);
      rows[index].saved = true;
      this.setState({
        rows,
      });
    });
  };

  deleteRow = (row) => {
    this.props.post('/deleteRow', row).then((results) => {
      const { rows } = this.state;
      const index = rows.indexOf(row);
      rows.splice(index, 1);
      this.setState({
        rows,
      });
    });
  };

  updateArtCosts = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    this.props
      .post('/updateArtCosts', { productId: product.id, quantity: product.quantity })
      .then((art) => {
        rows[rowIndex].products[productIndex].art = art;
        rows[rowIndex].products[productIndex].margin = (
          ((rows[rowIndex].products[productIndex].bill -
            rows[rowIndex].products[productIndex].art.reduce((total, art) => total + art.cost, 0) -
            rows[rowIndex].products[productIndex].costs.reduce((total, cost) => total + cost.cost, 0) -
            rows[rowIndex].products[productIndex].price) /
            rows[rowIndex].products[productIndex].bill) *
          100
        ).toFixed(2);
        this.setState({
          rows,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateFeature = (row, product, feature, value) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const featureIndex = rows[rowIndex].products[productIndex].features.indexOf(feature);
    rows[rowIndex].products[productIndex].features[featureIndex].feature = value;
    this.setState({
      rows,
    });
  };

  editFeature = (row, product, feature) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const featureIndex = rows[rowIndex].products[productIndex].features.indexOf(feature);
    rows[rowIndex].products[productIndex].features[featureIndex].editing = true;
    this.setState({
      rows,
    });
  };

  newFeature = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].features.push({
      feature: '',
      editing: true,
      quoteProductId: product.id,
    });
    this.setState({
      rows,
    });
  };

  newFeatureWithFeature = (row, product, feature) => {
    this.props
      .post('/newFeature', { feature, quoteProductId: product.id })
      .then((results) => {
        const { rows } = this.state;
        const rowIndex = rows.indexOf(row);
        const productIndex = rows[rowIndex].products.indexOf(product);
        rows[rowIndex].products[productIndex].features.push(results);
        this.setState({
          rows,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addQuoteCost = (row, product, cost) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].costs.push(cost);
    rows[rowIndex].products[productIndex].margin = (
      ((rows[rowIndex].products[productIndex].bill -
        rows[rowIndex].products[productIndex].art.reduce((total, art) => total + art.cost, 0) -
        rows[rowIndex].products[productIndex].costs.reduce((total, cost) => total + cost.cost, 0) -
        rows[rowIndex].products[productIndex].price) /
        rows[rowIndex].products[productIndex].bill) *
      100
    ).toFixed(2);
    this.setState({
      rows,
    });
  };

  updateQuoteCost = (row, product, oldCost, newCost) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const costIndex = rows[rowIndex].products[productIndex].costs.indexOf(oldCost);
    rows[rowIndex].products[productIndex].costs[costIndex] = newCost;
    rows[rowIndex].products[productIndex].margin = (
      ((rows[rowIndex].products[productIndex].bill -
        rows[rowIndex].products[productIndex].art.reduce((total, art) => total + art.cost, 0) -
        rows[rowIndex].products[productIndex].costs.reduce((total, cost) => total + cost.cost, 0) -
        rows[rowIndex].products[productIndex].price) /
        rows[rowIndex].products[productIndex].bill) *
      100
    ).toFixed(2);
    this.setState({
      rows,
    });
  };

  removeQuoteCost = (row, product, cost) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const costIndex = rows[rowIndex].products[productIndex].costs.indexOf(cost);
    rows[rowIndex].products[productIndex].costs.splice(costIndex, 1);
    rows[rowIndex].products[productIndex].margin = (
      ((rows[rowIndex].products[productIndex].bill -
        rows[rowIndex].products[productIndex].art.reduce((total, art) => total + art.cost, 0) -
        rows[rowIndex].products[productIndex].costs.reduce((total, cost) => total + cost.cost, 0) -
        rows[rowIndex].products[productIndex].price) /
        rows[rowIndex].products[productIndex].bill) *
      100
    ).toFixed(2);
    this.setState({
      rows,
    });
  };

  saveFeature = (row, product, feature) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const featureIndex = rows[rowIndex].products[productIndex].features.indexOf(feature);
    if (rows[rowIndex].products[productIndex].features[featureIndex].id) {
      this.props
        .post('/updateFeature', feature)
        .then((results) => {
          rows[rowIndex].products[productIndex].features[featureIndex] = results;
          this.setState({
            rows,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.props
        .post('/newFeature', feature)
        .then((results) => {
          rows[rowIndex].products[productIndex].features[featureIndex] = results;
          this.setState({
            rows,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  deleteFeature = (row, product, feature) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    const featureIndex = rows[rowIndex].products[productIndex].features.indexOf(feature);
    if (rows[rowIndex].products[productIndex].features[featureIndex].id) {
      this.props
        .post('/deleteFeature', feature)
        .then((results) => {
          rows[rowIndex].products[productIndex].features.splice(featureIndex, 1);
          this.setState({
            rows,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      rows[rowIndex].products[productIndex].features.splice(featureIndex, 1);
      this.setState({
        rows,
      });
    }
  };

  toggleShowFeatures = (row, product) => {
    const { rows } = this.state;
    const rowIndex = rows.indexOf(row);
    const productIndex = rows[rowIndex].products.indexOf(product);
    rows[rowIndex].products[productIndex].showFeatures = !rows[rowIndex].products[productIndex].showFeatures;
    this.setState({
      rows,
    });
  };

  render() {
    const { get, post } = this.props;
    return (
      <div>
        <Switch>
          <Route path="/quotes/:quoteId/pdf" component={(props) => <QuotePDF {...props} get={get} post={post} />} />
          <Route path="/quotes/:quoteId">
            <div id="actualQuote">
              <QuoteHeader />
              {this.state.rows.map((row, key) => (
                <Card
                  key={key}
                  style={{
                    width: '100%',
                    marginTop: '15px',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <CardHeader
                    title={
                      <div>
                        <TextField value={row.title} onChange={(event) => this.updateRowTitle(row, event.target.value)} />
                        <Button
                          style={{ margin: '10px' }}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => this.saveRowTitle(row)}
                          disabled={row.saved}
                        >
                          Update
                        </Button>
                      </div>
                    }
                  />
                  <CardContent style={{ marginBottom: '45px' }}>
                    <Grid container spacing={4} justify="center">
                      {row.products.map((product, key) => (
                        <Grid key={key} item xs={12} md={6} lg={3}>
                          <QuoteProduct
                            style={product.style}
                            art={product.art}
                            description={product.description}
                            options={product.options}
                            row={row}
                            get={this.props.get}
                            post={this.props.post}
                            title={product.title}
                            product={product}
                            editProduct={product.editProduct}
                            editArt={product.editArt}
                            costs={product.costs}
                            quantity={product.quantity}
                            showCosts={product.showCosts}
                            updateProduct={this.updateProduct}
                            toggleShowCosts={this.toggleShowCosts}
                            toggleEditProduct={this.toggleEditProduct}
                            toggleEditArt={this.toggleEditArt}
                            updateArt={this.updateArt}
                            deleteArt={this.deleteArt}
                            addArt={this.addArt}
                            features={product.features}
                            price={
                              product.options.find((row) => row.id === product.optionId)
                                ? product.options.find((row) => row.id === product.optionId).price
                                : 0
                            }
                            optionId={product.optionId}
                            catalogId={product.catalogId}
                            updateCatalogName={this.updateCatalogName}
                            category={product.category}
                            catalogs={this.state.catalogs}
                            categories={this.state.categories}
                            styles={this.state.styles}
                            bill={product.bill}
                            deleteProduct={this.deleteProduct}
                            saveProduct={this.saveProduct}
                            copyProduct={this.copyProduct}
                            updateArtCosts={this.updateArtCosts}
                            updateFeature={this.updateFeature}
                            editFeature={this.editFeature}
                            saveFeature={this.saveFeature}
                            newFeature={this.newFeature}
                            addQuoteCost={this.addQuoteCost}
                            updateQuoteCost={this.updateQuoteCost}
                            removeQuoteCost={this.removeQuoteCost}
                            showFeatures={product.showFeatures}
                            toggleShowFeatures={this.toggleShowFeatures}
                            newFeatureWithFeature={this.newFeatureWithFeature}
                            deleteFeature={this.deleteFeature}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                  <CardActions style={{ float: 'right' }}>
                    <Tooltip title="Delete Row">
                      <IconButton onClick={() => this.deleteRow(row)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy Row">
                      <IconButton onClick={() => this.copyRow(row)}>
                        <FileCopy color="secondary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Add a Product">
                      <IconButton onClick={() => this.addProduct(row)}>
                        <Add color="primary" />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              ))}
            </div>
            <Fab color="primary" onClick={this.addRow} size="large" style={{ position: 'fixed', right: '90px', bottom: '20px' }}>
              <Tooltip title="Add a Row" placement="top">
                <Add />
              </Tooltip>
            </Fab>
            <Fab color="secondary" onClick={this.downloadPDF} size="large" style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
              <Tooltip title="Save PDF" placement="top">
                <CloudDownload />
              </Tooltip>
            </Fab>
            <QuoteFooter salesman={this.state.salesman} />
          </Route>
        </Switch>
      </div>
    );
  }
}
