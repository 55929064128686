import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { UserStore } from '../../Stores';

const Pending = ({ addPending, removePending, sizes, ps, sortSizes }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const user = UserStore.getUser();

  useEffect(() => {
    const rm = removePending.map((r) => {
      const sizes = r.sizes.map((s) => ({ ...s, quantity: s.quantity * -1 }));
      return { ...r, sizes };
    });
    setData([...rm, ...addPending]);
    let c = [];
    if (!user.groups.find((g) => g.name.includes('Customer'))) {
      c.push({
        title: 'ID',
        field: 'workOrder.workOrderID',
      });
      c.push({
        title: 'Description',
        field: 'workOrder.description',
      });
    }
    c = [...c, ...sizes];
    c.push({ title: 'Total', render: (rowData) => rowData.sizes?.reduce((total, size) => total + size.quantity, 0) });
    c.push({ title: 'Notes', field: 'notes' });
    setColumns(c);
  }, []);

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        options={{ toolbar: false, padding: 'dense', pageSize: 10 }}
        onRowClick={(e, rowData) => {
          window.open(`/workorder/${rowData.workOrder.id}/product`);
        }}
      />
    </div>
  );
};

export default Pending;

Pending.propTypes = {
  addPending: PropTypes.array,
  removePending: PropTypes.array,
  sizes: PropTypes.array,
  ps: PropTypes.array,
  sortSizes: PropTypes.func,
};
