/* eslint no-param-reassign:0 */
/* eslint no-console:0 */
/* eslint no-use-before-define:0 */
/* eslint no-shadow:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Card, CardContent, ButtonBase, ButtonGroup, Button, Popover } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MiniWorkOrderTable from './MiniWorkOrderTable';
import { createSnack } from '../../../Stores/Snack/Actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const WaitingOrders = ({ get, user, type, startPage, refresh }) => {
  const classes = useStyles();
  const [orders, setOrders] = useState(null);
  const [selected, setSelected] = useState(user.group === 'Admin' ? 'All' : 'Just Me');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);

  const handleClick = (e, type) => {
    if (type) {
      setSelectedOrderType(type.toLowerCase());
      setAnchorEl(e.currentTarget);
    } else {
      createSnack('Error', "Sorry can't display unassigned work orders talk to McKay");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOrders = () => {
    get('/getWaitingWorkOrdersCount', [
      {
        name: 'uid',
        value: selected === 'All' ? '' : user.uid,
      },
      {
        name: 'group',
        value: type,
      },
    ])
      .then((res) => {
        formatOrders(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatOrders = (orders) => {
    orders.map((order) => {
      if (order.orderType === '' || order.orderType === null) {
        return (order.displayType = 'Not Assigned');
      }
      if (order.orderType.includes('csp')) {
        return (order.displayType = order.orderType.toUpperCase());
      }
      return (order.displayType = order.orderType.charAt(0).toUpperCase() + order.orderType.slice(1));
    });
    setOrders(orders);
  };

  useEffect(() => {
    setOrders(null);
    getOrders();
  }, [selected, refresh]);

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
          WORK ORDERS IN PROGRESS
        </Typography>
        {orders === null ? (
          <div style={{ textAlign: 'center' }}>
            <Skeleton variant="rect" width={150} height={20} style={{ marginBottom: '20px', marginTop: '20px' }} />
            <Skeleton variant="rect" width="100%" height={50} />
          </div>
        ) : (
          <>
            {/* TODO seeViewAllWaitingOrdersButton */}
            {user.group !== 'Admin' && (
              <ButtonGroup size="small" color="primary" aria-label="outlined primary button group" style={{ margin: '10px 0', marginRight: '10px' }}>
                <Button
                  variant={selected === 'Just Me' ? 'contained' : 'outlined'}
                  onClick={() => setSelected('Just Me')}
                  style={{ lineHeight: '18px' }}
                >
                  Just Me
                </Button>
                <Button variant={selected === 'All' ? 'contained' : 'outlined'} onClick={() => setSelected('All')} style={{ lineHeight: '18px' }}>
                  All
                </Button>
              </ButtonGroup>
            )}
            {orders.length ? (
              <Grid container justify="space-around" spacing={2}>
                {orders.map((x) => {
                  return (
                    <ButtonBase
                      key={`${x.displayType}-${x.count}`}
                      style={{ borderRadius: '4px', marginTop: '8px' }}
                      onClick={(e) => handleClick(e, x.orderType)}
                    >
                      <Grid item style={{ textAlign: 'center', margin: '8px', width: '75px' }}>
                        <Typography variant="h3">{x.count}</Typography>
                        <Typography variant="body2">{x.displayType}</Typography>
                      </Grid>
                    </ButtonBase>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body1" style={{ marginTop: '16px', display: 'block' }}>
                {`Looks like there aren't any ${selected === 'Just Me' ? 'for you' : ''} at the moment...`}
              </Typography>
            )}
          </>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginLeft: '16px' }}
        >
          <MiniWorkOrderTable group={type} orderType={selectedOrderType} get={get} uid={selected === 'All' ? '' : user.uid} startPage={startPage} />
        </Popover>
      </CardContent>
    </Card>
  );
};

export default WaitingOrders;
