/* eslint react/no-array-index-key:0 */
/* eslint no-param-reassign:0 */
/* eslint no-unused-vars:0 */
/* eslint no-alert:0 */
/* eslint react/prop-types:0 */
import React, { useEffect, useState } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, Button, Card, CardContent, CardActions, CardHeader, Grid, IconButton } from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Delete } from '@material-ui/icons';
import Sizes from './Sizes';
import { get, post } from '../../Functions/API';
import UserStore from '../../Stores/User/Store';

const SizeOptions = ({ product, addSize, removeSize, num, close }) => {
  const [potentialSizesGroups, setPotentialSizesGroups] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const user = UserStore.getUser();

  useEffect(() => {
    get('/potentialSizesGroups').then((results) => {
      setPotentialSizesGroups(results);
    });
  }, [refresh]);

  const addGroup = () => {
    const name = window.prompt('What is the name of the group?');
    if (name) {
      post('/newSizeGroup', { name }).then((row) => {
        setRefresh(refresh + 1);
      });
    }
  };

  const moveGroupUp = async (e, group) => {
    e.stopPropagation();
    let statePotentialSizesGroups = JSON.stringify(potentialSizesGroups);
    statePotentialSizesGroups = JSON.parse(statePotentialSizesGroups);
    const replace = statePotentialSizesGroups.find((row) => row.sort === group.sort - 1);
    if (replace) {
      const replaceIndex = statePotentialSizesGroups.indexOf(replace);
      const index = potentialSizesGroups.indexOf(group);
      replace.sort += 1;
      group.sort -= 1;
      await post('/updatePotentialSizesGroupSort', { group }).then((results) => {
        statePotentialSizesGroups[index] = results;
      });
      await post('/updatePotentialSizesGroupSort', { group: replace }).then((results) => {
        statePotentialSizesGroups[replaceIndex] = results;
      });
      setPotentialSizesGroups(statePotentialSizesGroups);
    }
  };

  const moveGroupDown = async (e, group) => {
    e.stopPropagation();
    let statePotentialSizesGroups = JSON.stringify(potentialSizesGroups);
    statePotentialSizesGroups = JSON.parse(statePotentialSizesGroups);
    const replace = statePotentialSizesGroups.find((row) => row.sort === group.sort + 1);
    if (replace) {
      const replaceIndex = statePotentialSizesGroups.indexOf(replace);
      const index = potentialSizesGroups.indexOf(group);
      replace.sort -= 1;
      group.sort += 1;
      await post('/updatePotentialSizesGroupSort', { group }).then((results) => {
        statePotentialSizesGroups[index] = results;
      });
      await post('/updatePotentialSizesGroupSort', { group: replace }).then((results) => {
        statePotentialSizesGroups[replaceIndex] = results;
      });
      setPotentialSizesGroups(statePotentialSizesGroups);
    }
  };

  const removeSizeGroup = async (e, group) => {
    e.stopPropagation();
    const c = window.confirm('Delete the group and the sizes in that group?');
    if (c) {
      const { id } = group;
      let statePotentialSizesGroups = JSON.stringify(potentialSizesGroups);
      statePotentialSizesGroups = JSON.parse(statePotentialSizesGroups);
      const index = potentialSizesGroups.indexOf(group);
      post('/deletePotentialSizeGroup', { id }).then(() => {
        statePotentialSizesGroups.splice(index, 1);
        setPotentialSizesGroups(statePotentialSizesGroups);
      });
    }
  };

  return (
    <Card
      style={{
        position: 'absolute',
        width: '100%',
        backgroundColor: '#ffffff',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'scroll',
        outline: 'none',
      }}
    >
      <CardHeader
        title="Available Sizes"
        subheader={
          <Button style={{ float: 'right' }} variant="contained" onClick={close}>
            Close
          </Button>
        }
      />
      <CardContent>
        {potentialSizesGroups
          .sort((a, b) => a.sort - b.sort)
          .map((group, key) => (
            <ExpansionPanel key={key} defaultExpanded>
              <ExpansionPanelSummary>
                <Grid container>
                  <Grid item style={{ flexGrow: 1 }}>
                    {group.name}
                  </Grid>
                  <Grid item>
                    <IconButton size="small">
                      <ArrowUpward fontSize="small" onClick={(e) => moveGroupUp(e, group)} />
                    </IconButton>
                    <IconButton size="small">
                      <ArrowDownward fontSize="small" onClick={(e) => moveGroupDown(e, group)} />
                    </IconButton>
                    {/* TODO deleteSizeOption */}
                    {user.group === 'Admin' && (
                      <IconButton size="small">
                        <Delete fontSize="small" onClick={(e) => removeSizeGroup(e, group)} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <Sizes get={get} post={post} product={product} num={num} groupId={group.id} addSize={addSize} user={user} removeSize={removeSize} />
            </ExpansionPanel>
          ))}
      </CardContent>
      <CardActions>
        {/* TODO addSizeGroup */}
        <Button variant="contained" color="secondary" onClick={addGroup} disabled={user.group !== 'Admin'}>
          Add Group
        </Button>
      </CardActions>
    </Card>
  );
};

export default SizeOptions;
