import dispatcher from './dispatcher';
import { get, patch, post } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';

export const loadCosts = (workOrderID) => {
  get('/costing', { workOrderID }, 'obj')
    .then((costs) => {
      dispatcher.dispatch({
        type: 'LOAD_COSTS',
        costs,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const newCost = async (newCost) => {
  post('/costing', newCost)
    .then((cost) => {
      dispatcher.dispatch({
        type: 'NEW_COST',
        cost,
      });
      createSnack('Success', 'Created Cost.');
    })
    .catch((err) => {
      console.error(err);
      createSnack('Error', `Could not create cost. ${err.message}`);
    });
};

export const updateCost = (cost) => {
  return patch(`/costing/${cost.id}`, cost)
    .then(async (cost) => {
      dispatcher.dispatch({
        type: 'UPDATE_COST',
        cost,
      });
      return cost;
    })
    .catch((err) => {
      console.error(err);
    });
};
