import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { post } from '../../../Functions/API.js';
import { createSnack } from '../../../Stores/Snack/Actions.js';

const PubSubs = () => {
  const pubsubs = [
    { type: 'getInvoiceNumbers', name: 'Get Invoice Numbers' },
    { type: 'placeSSOrders', name: 'Place SS Orders' },
    { type: 'chargeCustomers', name: 'Charge Customers' },
    { type: 'processNotionFulfillments', name: 'Process Notion Fulfillments' },
    { type: 'getDecoOrders', name: 'Get Deco Orders' },
    { type: 'processWixOrders', name: 'Process Wix Orders' },
    { type: 'processOrderDeskOrders', name: 'Process Order Desk Orders' },
    { type: 'getShippingCosts', name: 'Get Shipping Costs' },
    { type: 'wooCommerce', name: 'Woo Commerce' },
    { type: 'createProducts', name: 'Create Products' },
    { type: 'makeArtJob', name: 'Create Art Job' },
  ];

  const trigger = async (type) => {
    await post('/pub-subs/crons', { type });
    createSnack('Success', `Triggered ${type}`);
  };

  return (
    <div>
      <Grid container spacing={1}>
        {pubsubs.map(({ name, type }, key) => (
          <Grid item xs={12} key={key}>
            <Button variant={'contained'} color={'primary'} onClick={() => trigger(type)}>
              {name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PubSubs;
