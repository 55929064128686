import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import { save, retrieve } from '../../../../Functions/LocalStorage';

class Store extends EventEmitter {
  constructor() {
    super();
    this.data = retrieve('customerComparison');
  }

  getData() {
    return this.data;
  }

  loadData = (data) => {
    this.data = data;
    save('customerComparison', data);
    this.emit('change');
  };

  loadNewData = (data) => {
    this.data = data;
    this.emit('change');
  };

  handleActions(action) {
    switch (action.type) {
      case 'LOAD_DATA': {
        this.loadData(action.data);
        break;
      }
      case 'LOAD_NEW_DATA': {
        this.loadNewData(action.data);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
