import { EventEmitter } from 'events';
import dispatcher from './dispatcher';
import React from 'react';

class Store extends EventEmitter {
  constructor() {
    super();
    this.filters = {
      customer: null,
      store: null,
      startDate: null,
      endDate: null,
      shipByDateStart: null,
      shipByDateEnd: null,
      expectedArrivalDateStart: null,
      expectedArrivalDateEnd: null,
      customerDisabled: true,
      storeDisabled: true,
      datesDisabled: true,
      count: 0,
      page: 0,
      rowsPerPage: 10,
      labelRowsPerPage: 'Some Label',
      status: null,
    };
  }

  getFilters() {
    return this.filters;
  }

  updateFilters = (newFilters) => {
    this.filters = newFilters;
    this.emit('change');
  };

  updateCount = (newFilters) => {
    this.filters = newFilters;
    this.emit('countUpdated');
  };

  handleActions(action) {
    switch (action.type) {
      case 'UPDATE_FILTERS': {
        this.updateFilters(action.newFilters);
        break;
      }
      case 'UPDATE_COUNT': {
        this.updateCount(action.newFilters);
        break;
      }
      default: {
        console.log('error', action);
      }
    }
  }
}

const store = new Store();
dispatcher.register(store.handleActions.bind(store));
export default store;
