/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Button, IconButton, Paper, TextField, Modal, Badge, Checkbox, Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward, List, Assignment } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import Grid from '@material-ui/core/Grid';
import { get, patch, post } from '../../Functions/API';
import SKU from './SKU';
import { Link, useParams, useHistory } from 'react-router-dom';
import { createDialog } from '../../Stores/Dialog/Actions';
import EditSizes from './EditSizes';
import Papa from 'papaparse';
import { sqlDateString, sqlDateTimeString } from '../Utils/SqlDate';
import { UserStore } from '../../Stores';

const Inventory = () => {
  const { id } = useParams();
  const history = useHistory();
  const [inventory, setInventory] = useState({});
  const [tableRef, setTableRef] = useState(React.createRef());
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewAvailable, setViewAvailable] = useState(false);
  const [viewHidden, setViewHidden] = useState(false);
  const user = UserStore.getUser();

  useEffect(() => {
    setup(id);
  }, [id]);

  useEffect(() => {
    setUpColumns(data);
  }, [viewAvailable, data, viewHidden]);

  const setup = async (id) => {
    setLoading(true);

    await get(`/inventory/${id}`)
      .then((results) => {
        const { skus } = results;
        setInventory(results);
        setData(skus);
      })
      .catch((err) => {
        window.alert(err.message);
      });
    setLoading(false);
  };

  const setUpColumns = async (skus) => {
    let ps = await get('/allPotentialSizes', [{ name: 'type', value: 'type' }]);
    const columns = [
      {
        title: 'Sort',
        field: 'sort',
        type: 'number',
        export: false,
      },
      {
        title: 'SKU',
        field: 'sku',
        render: (rowData) => (
          <Badge variant={'dot'} color={'primary'} invisible={!rowData.sizes.filter((s) => Object.keys(s).includes('pending')).length}>
            {rowData.sku}
          </Badge>
        ),
        giveMeMore: (rowData) => rowData.sku,
      },
      { title: 'Name', field: 'name' },
    ];
    if (user.hasPermission('recentlyInventoried')) {
      columns.push({
        title: 'Last Inventoried',
        field: 'recentlyInventoried',
        render: (rowData) => {
          const check = (
            <Checkbox
              checked={Boolean(rowData.recentlyInventoried)}
              value={Boolean(rowData.recentlyInventoried)}
              size={'small'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const { id } = rowData;
                const recentlyInventoried = e.target.checked ? sqlDateTimeString(new Date()) : null;
                patch(`/sku/${id}`, { recentlyInventoried }).then((results) => {
                  const d = [...data];
                  const index = data.findIndex((d) => d.id === rowData.id);
                  d[index].recentlyInventoried = results.recentlyInventoried;
                  setData(d);
                });
              }}
            />
          );
          const date = new Date(rowData.recentlyInventoried);
          const title = `${date.toDateString()} ${date.toLocaleTimeString()}`;
          return rowData.recentlyInventoried ? (
            <Tooltip title={title} hidden={!rowData.recentlyInventoried}>
              {check}
            </Tooltip>
          ) : (
            check
          );
        },
        giveMeMore: (rowData) => rowData.recentlyInventoried,
      });
    }
    if (viewHidden) {
      columns.push({
        title: 'Hidden',
        field: 'hidden',
        render: (rowData) => (
          <Checkbox
            checked={rowData.hidden}
            value={rowData.hidden}
            size={'small'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const { id } = rowData;
              patch(`/sku/${id}`, { hidden: e.target.checked }).then((results) => {
                const d = [...data];
                const index = data.findIndex((d) => d.id === rowData.id);
                d[index].hidden = results.hidden;
                setData(d);
              });
            }}
          />
        ),
        giveMeMore: (rowData) => rowData.hidden,
      });
    }
    let sizes = [];
    for (const result of skus) {
      for (const size of result.sizes) {
        const found = sizes.find((row) => row.title === size.size);
        if (!found) {
          sizes.push({
            title: size.size,
            field: size.size,
            editable: 'never',
            render: (rowData) => {
              const found = rowData.sizes.find((row) => row.size === size.size);
              let quantity = '';
              if (found) {
                if (viewAvailable && parseInt(found?.pending)) {
                  quantity = found.quantity + found.pending;
                }
                quantity = found.quantity;
                return <span style={{ color: quantity < found.target ? 'red' : 'black' }}>{quantity}</span>;
              }
              return quantity;
            },
            giveMeMore: (rowData) => {
              const found = rowData.sizes.find((row) => row.size === size.size);
              let quantity = '';
              if (found) {
                if (viewAvailable && parseInt(found?.pending)) {
                  quantity = found.quantity + found.pending;
                }
                quantity = found.quantity;
              }
              return quantity;
            },
            editComponent: (t) => {
              const found = t.rowData.sizes.find((row) => row.size === size.size);
              return found ? (
                <TextField type="number" value={found.quantity} onChange={(e) => t.onRowDataChange(editQuantity(e.target.value, t.rowData, found))} />
              ) : (
                <TextField value="N/A" disabled helperText="Size not included in SKU" />
              );
            },
          });
        }
      }
    }
    sizes = sortSizesStart(sizes, ps);
    columns.push({
      title: 'Total',
      render: (rowData) => {
        if (viewAvailable) {
          return rowData.sizes.reduce((total, row) => {
            if (parseInt(row.pending)) return total + row.quantity + row.pending;
            return total + row.quantity;
          }, 0);
        }
        return rowData.sizes.reduce((total, row) => total + row.quantity, 0);
      },
      giveMeMore: (rowData) => {
        if (viewAvailable) {
          return rowData.sizes.reduce((total, row) => {
            if (parseInt(row.pending)) return total + row.quantity + row.pending;
            return total + row.quantity;
          }, 0);
        }
        return rowData.sizes.reduce((total, row) => total + row.quantity, 0);
      },
    });
    setColumns([...columns, ...sizes]);
  };

  const sortUp = (e, sku) => {
    e.preventDefault();
    e.stopPropagation();
    const replace = data.find((row) => row.sort === sku.sort + 1);
    if (replace) {
      replace.sort -= 1;
      const index = data.indexOf(sku);
      data[index].sort = sku.sort + 1;
      post('/updateSKUSort', sku)
        .then(() => {
          console.log('sortUp');
        })
        .catch((err) => {
          console.error(err.message);
        });
      post('/updateSKUSort', replace)
        .then(() => {
          console.log('sortUp2');
        })
        .catch((err) => {
          console.error(err.message);
        });
      setData(data);
      tableRef.current && tableRef.current.onChangeOrder(0, 'asc');
    }
  };

  const sortDown = (e, sku) => {
    e.preventDefault();
    e.stopPropagation();
    const replace = data.find((row) => row.sort === sku.sort - 1);
    if (replace) {
      replace.sort += 1;
      const index = data.indexOf(sku);
      data[index].sort = sku.sort - 1;
      post('/updateSKUSort', sku)
        .then(() => {
          console.log('sortDown');
        })
        .catch((err) => {
          console.error(err.message);
        });
      post('/updateSKUSort', replace)
        .then(() => {
          console.log('sortDown2');
        })
        .catch((err) => {
          console.error(err.messge);
        });
      setData(data);
      tableRef.current && tableRef.current.onChangeOrder(0, 'asc');
    }
  };

  const editQuantity = (quantity, newRow, found) => {
    const sizeIndex = newRow.sizes.indexOf(found);
    newRow.sizes[sizeIndex].quantity = parseInt(quantity);
    return newRow;
  };

  const sortSizesStart = (sizes, possibleSizes) => {
    return sizes.sort((a, b) => {
      const potentialSizeA = possibleSizes.find((row) => row.size === a.title);
      const potentialSizeB = possibleSizes.find((row) => row.size === b.title);
      if (potentialSizeA && potentialSizeB) {
        const indexA = possibleSizes.indexOf(potentialSizeA);
        const indexB = possibleSizes.indexOf(potentialSizeB);
        return indexA - indexB;
      }
      return 1;
    });
  };

  const updateRow = async (newData, oldData) => {
    patch(`/sku/${oldData.id}`, newData).then((results) => {
      const d = [...data];
      const index = data.indexOf(oldData);
      d[index] = results;
      setData(d);
    });
  };

  const updateRowSizes = (index, results) => {
    const d = [...data];
    d[index].sizes = results;
    setData(d);
  };

  return (
    <div>
      <MaterialTable
        title={`${inventory?.name} Inventory`}
        isLoading={loading}
        columns={columns}
        data={viewHidden ? data : data.filter((d) => !d.hidden)}
        tableRef={tableRef}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Grid container spacing={1} justify={'flex-end'} style={{ padding: '10px' }}>
                <Grid item>
                  <Button
                    size={'small'}
                    variant={'contained'}
                    color={viewAvailable ? 'primary' : ''}
                    onClick={() => setViewAvailable(!viewAvailable)}
                  >
                    Include Pending
                  </Button>
                </Grid>
                <Grid item>
                  <Button size={'small'} variant={'contained'} color={viewHidden ? 'primary' : ''} onClick={() => setViewHidden(!viewHidden)}>
                    View Hidden
                  </Button>
                </Grid>
                <Grid item>
                  <Button size={'small'} variant={'contained'} href={`/inventory/atTime/${id}`}>
                    History
                  </Button>
                </Grid>
              </Grid>
            </div>
          ),
        }}
        actions={[
          {
            icon: () => <Assignment />,
            tooltip: 'Update Quantities',
            onClick: (e, rowData) => {
              createDialog({
                title: `Update Quantities for ${rowData.sku}`,
                content: <EditSizes rowData={rowData} updateRow={updateRowSizes} index={data.indexOf(rowData)} />,
              });
            },
          },
        ]}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            new Promise((resolve, reject) => {
              updateRow(newData, oldData)
                .then((results) => {
                  console.log(results);
                  resolve(results);
                })
                .catch((err) => {
                  console.log(err);
                  reject(err);
                });
            });
          },
        }}
        options={{
          paging: false,
          exportButton: true,
          padding: 'dense',
          exportCsv: (columns, data) => {
            console.log(columns, data);
            const filteredColumns = columns.filter((c) => {
              if (Object.keys(c).includes('export')) {
                return c.export;
              }
              return true;
            });
            const newData = data.map((d) =>
              filteredColumns.map((c) => {
                if (c.giveMeMore) return c.giveMeMore(d);
                return d[c.field];
              }),
            );
            const exportData = newData.map((r) => {
              const row = {};
              filteredColumns.map(({ title }, i) => (row[title] = r[i]));
              return row;
            });
            const csvContent = Papa.unparse(exportData, { download: true });
            const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${inventory?.name}.csv`);
            document.body.appendChild(link);
            link.click();
          },
        }}
        onRowClick={(e, rowData) => {
          history.push(`/inventory/${id}/skus/${rowData.id}`);
        }}
      />
    </div>
  );
};

export default Inventory;
