import dispatcher from './dispatcher';
import { get, patch, post, remove } from '../../Functions/API';
import { createSnack } from '../Snack/Actions';
import BillingStore from './Store';

export const loadInvoice = (workOrderID) => {
  get(`/workorder/${workOrderID}/invoices`).then((invoice) => {
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE',
      invoice,
    });
  });
};

export const fetchInvoices = async (workOrderID) => {
  const currentInvoices = BillingStore.getInvoice();
  if (currentInvoices?.length) {
    return currentInvoices;
  } else {
    const invoices = await get('/invoice', { workOrderID }, 'obj');
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE',
      invoices,
    });
    return invoices;
  }
};

export const updateInvoiceLine = (line) => {
  patch(`/invoice/${line.id}`, line, 'obj').then((invoice) => {
    createSnack('Success', 'Updated Invoice');
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE_LINE',
      invoice,
    });
  });
};

export const newInvoiceLine = (line) => {
  post(`/invoice`, [line], 'obj').then((invoice) => {
    createSnack('Success', 'Created New Invoice');
    console.log(invoice);
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE',
      invoice,
    });
  });
};

export const newInvoiceLines = async (lines) => {
  return post(`/invoice`, lines, 'obj').then((invoice) => {
    createSnack('Success', 'Created New Invoices');
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE',
      invoice,
    });
  });
};

export const deleteInvoiceLine = (line) => {
  remove(`/invoice/${line.id}`, { workOrderID: line.workOrderID }).then(({ id }) => {
    createSnack('Success', 'Deleted Invoice');
    dispatcher.dispatch({
      type: 'DELETE_INVOICE_LINE',
      id,
    });
  });
};

export const deleteAll = (workOrderID) => {
  remove(`/invoice`, { workOrderID }).then(() => {
    createSnack('Success', 'Deleted Invoices');
    dispatcher.dispatch({
      type: 'DELETE_ALL',
    });
  });
};

export const invoiceAlphabet = (workOrderID) => {
  post('/invoice/alphabet', { workOrderID }, 'obj').then((invoice) => {
    createSnack('Success', 'Invoice Alphabet Success!');
    dispatcher.dispatch({
      type: 'UPDATE_INVOICE',
      invoice,
    });
  });
};
