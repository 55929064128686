import React, { useEffect, useState } from 'react';
import { get, post } from '../../../Functions/API.js';
import { DataGrid } from '@mui/x-data-grid';

const RawMaterials = () => {
  const [rawMaterials, setRawMaterials] = useState([]);

  useEffect(() => {
    post('/fulfillment/raw-materials', { search: {} }).then(setRawMaterials);
  }, []);

  const columns = [
    { field: 'brand', headerName: 'Brand', valueGetter: ({ value }) => value?.brand },
    { field: 'style', headerName: 'Style', valueGetter: ({ value }) => value?.style },
    { field: 'color', headerName: 'Color', valueGetter: ({ value }) => value?.color },
    { field: 'size', headerName: 'Size', valueGetter: ({ value }) => value?.size },
  ];

  return (
    <div>
      <DataGrid autoHeight columns={columns} rows={rawMaterials} checkboxSelection />
    </div>
  );
};

export default RawMaterials;
