/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Checkbox } from '@material-ui/core';

const SkuTableSelector = ({ allSkus, callback, cell }) => {
  const [selected, setSelected] = useState();
  const select = ({ id, inventory }) => {
    setSelected(id);
    callback({ selected: id, inventory });
  };

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  return (
    <MaterialTable
      title={`Value for ${cell}`}
      columns={[
        {
          title: 'Selected',
          render: (rowData) => {
            console.log(selected, rowData.id);
            return <Checkbox size={'small'} checked={selected === rowData.id} />;
          },
        },
        { title: 'Inventory', field: 'inventory.name' },
        { title: 'SKU', field: 'sku' },
        { title: 'Name', field: 'name' },
      ]}
      data={allSkus}
      onSelectionChange={(data) => {
        const [d] = data;
        select({ id: d?.id, inventory: d?.inventory });
      }}
      options={{ padding: 'dense' }}
      onRowClick={(e, { id, inventory }) => {
        select({ id, inventory });
      }}
    />
  );
};

export default SkuTableSelector;

SkuTableSelector.propTypes = {
  allSkus: PropTypes.array,
  callback: PropTypes.func,
  cell: PropTypes.string,
};
